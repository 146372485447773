import { Component, Input } from '@angular/core';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';
import { ControlContainer } from '@angular/forms';

export type ControlNames = 'selection';

@Component({
    selector: 'app-are-you-sure-radio-buttons',
    templateUrl: './are-you-sure-radio-buttons.component.html',
    styleUrls: [
        '../stylesheets/form-formatting.scss',
        './are-you-sure-radio-buttons.component.scss'
    ]
})
export class AreYouSureRadioButtonsComponent extends BaseFormGroupComponent<ControlNames> {
    @Input() displayMessage: string;
    @Input() submitted: boolean;
    @Input() shouldDisplayPaymentMethodsDropdown: boolean;
    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    get requiredErrorDisplayable(): boolean {
        return this.getControl('selection').getError('required');
    }
}
