import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';

export type ControlNames = 'zip';

@Component({
    selector: 'app-zip-code-form-control',
    templateUrl: './zip-code-form-control.component.html',
    styleUrls: [
        './zip-code-form-control.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class ZipCodeFormControlComponent extends BaseFormGroupComponent<ControlNames> {
    @Input() isInline: boolean | undefined;

    valueChangeSubscription: Subscription;

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    get zip(): AbstractControl {
        return this.getControl('zip');
    }

    get zipErrorsDisplayable(): boolean {
        return this.errorsDisplayable('zip');
    }
}
