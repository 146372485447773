import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { NamePipe } from './name.pipe';
import { BaseFormGroupComponent } from '../../../shared/payments/base-form-control/base-form-control.component';

export type ControlNames = 'firstName' | 'middleInitial' | 'lastName';

@Component({
    selector: 'app-name-field-set',
    templateUrl: './name-field-set.component.html',
    styleUrls: [
        './name-field-set.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class NameFieldSetComponent extends BaseFormGroupComponent<ControlNames> {
    @Input() header: string;
    @Input() nameAsOnCard: string;

    constructor(
        controlContainer: ControlContainer,
        private namePipe: NamePipe
    ) {
        super(controlContainer);
    }

    capitalizeFirstName(): void {
        const capitalized = this.namePipe.transform(this.firstName.value);
        this.firstName.setValue(capitalized);
    }

    capitalizeMiddleInitial(): void {
        const capitalized = this.namePipe.transform(this.middleInitial.value);
        this.middleInitial.setValue(capitalized);
    }

    capitalizeLastName(): void {
        const capitalized = this.namePipe.transform(this.lastName.value);
        this.lastName.setValue(capitalized);
    }

    get firstName(): AbstractControl {
        return this.getControl('firstName');
    }

    get firstNameErrorsDisplayable(): boolean {
        return this.errorsDisplayable('firstName');
    }

    get middleInitial(): AbstractControl {
        return this.getControl('middleInitial');
    }

    get middleInitialErrorsDisplayable(): boolean {
        return this.errorsDisplayable('middleInitial');
    }

    get lastName(): AbstractControl {
        return this.getControl('lastName');
    }

    get lastNameErrorsDisplayable(): boolean {
        return this.errorsDisplayable('lastName');
    }
}
