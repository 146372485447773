<div class="notification-container" [ngClass]="{'cursor-pointer': account.eligible}" [id]="account.accountNumber">

    <div class="bolt-space-bottom-xl">
        <div class="media-left">
            <bolt-checkbox ngDefaultControl [checked]="selected" [disabled]="!account.eligible" [id]="checkboxId">
            </bolt-checkbox>
        </div>

        <div class="media-body alert-box bolt-space-bottom-xl" [ngClass]="{'text-black': account.eligible, 'text-muted': !account.eligible}">
            <span>
                Billing account {{account.accountNumber}}: {{account.policies.join(' and ')}}
            </span>
            <span *ngIf="account.refundMethod && account.refundMethod.length > 0">
                (Current refund method: {{account.refundMethod}})
            </span>
        </div>
    </div>

    <bw-bolt-notification type="info" class="bolt-space-bottom-xl to-container" *ngIf="account.warningMessage as warningMessage">
        <is-interpolate [text]="warningMessage"></is-interpolate>
    </bw-bolt-notification>
</div>