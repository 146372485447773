<div class="bolt-container bolt-space-bottom-2xl">
    <bw-bolt-progress-bar appFocusOnInit arialabel="remove refund method progress value is 100%" class="progress-bar-container to-container" progressBarValue="100"></bw-bolt-progress-bar>
    <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Remove refund method: confirmation</h1>

    <div class="main-container">
        <ng-container *ngIf="!apiCallSuccessful">
            <bw-bolt-notification type="error">
                <span class="error-notification">{{ apiServiceDownMessage }}</span>
            </bw-bolt-notification>
        </ng-container>
        <ng-container *ngIf="apiCallSuccessful">
            <div class="bolt-space-bottom-2xl">
                <bw-bolt-notification type="success">
                    <span class="success-notification">Your request has been processed.</span>
                </bw-bolt-notification>
            </div>
            <app-payment-sub-header>Confirmation details</app-payment-sub-header>
            <div class="main-contents">
                <p><span class="font-weight-bold">Removed refund method: </span>{{confirmationMessage}}</p>
            </div>
        </ng-container>
    </div>
</div>
<bolt-button-bar>
    <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
    <bolt-button id="continue-button" slot="forward" (click)="onContinue()">
        Payment preferences
    </bolt-button>
</bolt-button-bar>