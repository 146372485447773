export enum PageName {
    PAY_BILL_LANDING = 'pay bill landing',
    PAY_BILL_REVIEW = 'pay bill review',
    PAY_BILL_CONFIRMATION = 'pay bill confirmation',

    CHANGE_DUE_DATE_LANDING = 'change due date landing',
    CHANGE_DUE_DATE_REVIEW = 'change due date review',
    CHANGE_DUE_DATE_CONFIRMATION = 'change due date confirmation',

    BILLS_AND_PAYMENTS = 'bills and payments',
    BILLING_DETAILS = 'billing details',
    QUESTIONS_AND_TIPS = 'questions and tips',
    PAYMENT_PREFERENCES = 'payment preferences',
    BILLING_TIMELINE_EXTERNAL = 'billing timeline external',
    ERROR_404 = 'error 404',
    ERROR_TRIDION_DOWN = 'error tridion down',

    STUB_DEV = 'stub dev',
    STUB_PROD = 'stub prod',
    STUB_MCR = 'stub mcr',

    AUTOMATIC_PAYMENTS_LANDING = 'automatic payments landing',
    AUTOMATIC_PAYMENTS_REVIEW = 'automatic payments review',

    QUICK_PAY_PICKER = 'picker page',

    EDIT_PAYMENT_PLAN_LANDING = 'edit payment plan landing',
    EDIT_PAYMENT_PLAN_CONFIRMATION = 'edit payment plan confirmation',
}
