<is-wait-message [loadComplete]="pageLoadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'" *ngIf="!pageLoadComplete">
</is-wait-message>

<form [formGroup]="formGroup.group" *ngIf="formGroup && pageLoadComplete" (ngSubmit)="onSubmit()">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="remove payment method progress value is 50%" progressBarValue="50" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Remove payment method</h1>

        <div class="bolt-row">
            <div class="bolt-col-12">
                <bw-bolt-notification type="error" [id]="'global-error-message'" *ngIf="globalErrorDisplayable" class="bolt-space-bottom-xl to-container">
                    Please check the information below and try again.
                </bw-bolt-notification>

                <app-payment-sub-header>Remove payment method</app-payment-sub-header>

                <app-are-you-sure-radio-buttons formGroupName="areYouSureFormGroup" [submitted]="submitted" [displayMessage]="displayMessageForForm"
                    [shouldDisplayPaymentMethodsDropdown]="shouldDisplayPaymentMethodsDropdown">
                </app-are-you-sure-radio-buttons>

                <div *ngIf="shouldDisplayPaymentMethodsDropdown" class="bolt-space-bottom-lg">
                    <app-payment-method-select [paymentMethods]="savedPaymentMethods" [(selectedPaymentMethod)]="selectedReplacementMethod" [isManagePaymentPreferencesFlow]="true"
                        labelText="Select new default payment method" [(displayErrors)]="displayPaymentMethodsDropdownError">
                    </app-payment-method-select>
                </div>
            </div>
        </div>
    </div>

    <bolt-button-bar>
        <bolt-button slot="cancel" aria-label="cancel" id="cancel-button" (click)="cancelRemovePaymentMethod()">Cancel</bolt-button>
        <bolt-button submit slot="forward" type="primary submit" aria-label="submit" id="submit-button">Submit</bolt-button>
    </bolt-button-bar>
</form>