import { Component, OnDestroy } from '@angular/core';
import { SetupAutomaticPaymentsFlowService } from './services/setup-automatic-payments-flow.service';

@Component({
    selector: 'app-setup-automatic-payments',
    templateUrl: './setup-automatic-payments.component.html',
    styleUrls: ['./setup-automatic-payments.component.scss']
})
export class SetupAutomaticPaymentsComponent implements OnDestroy {
    constructor(private setupPaymentFlow: SetupAutomaticPaymentsFlowService) { }

    ngOnDestroy(): void {
        this.setupPaymentFlow.clear();
    }
}
