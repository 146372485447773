import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { SessionService } from '../../../../../../shared/session/session.service';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { CancelAutomaticPaymentsFlowService } from '../shared/cancel-automatic-payments-flow.service';

@Injectable()
export class CancelAutomaticPaymentsBackFromConfirmationGuard implements CanActivate {
    // eslint-disable-next-line max-params
    constructor(
        private cancelAutomaticPaymentsFlowService: CancelAutomaticPaymentsFlowService,
        private router: Router,
        private paymentPreferencesState: PaymentPreferencesStateService,
        private session: SessionService) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const canActivateResponse = this.session.currentPage !== ManagePaymentPreferencesPageTitle.automaticPayments.cancel.confirmation;

        if (!canActivateResponse) {
            this.cancelAutomaticPaymentsFlowService.clear();
            this.paymentPreferencesState.resetState();
        }

        return canActivateResponse || this.router.parseUrl(environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences({
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId
        }));
    }
}
