import { Component, Input } from '@angular/core';
import { BillingAccountItem } from '../../models/billing-account-item.model';

@Component({
    selector: 'app-billing-account-for-refund',
    templateUrl: './billing-account-for-refund.component.html',
    styleUrls: ['./billing-account-for-refund.component.scss']
})
export class BillingAccountForRefundComponent {
    @Input() account: BillingAccountItem;
    @Input() selected: boolean;
    @Input() checkboxId: string;

    get policyLineLabel(): string {
        return this.account.policies.length > 1 ? 'Policies' : 'Policy';
    }
}
