import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';
import { environment } from '../../../../../../../environments/environment';
import { WARNING_MESSAGES } from '../../../../../../billing/payment-preferences/shared/constants/warning-messages';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { PaymentMethodConfirmationDetail } from '../../../shared/components/saved-payment-confimation-details/saved-payment-confimation-details.component';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { EditPaymentMethodFlowService } from '../edit-payment-method-flow.service';
import { EditPaymentMethodConfirmationService } from './edit-payment-method-confirmation.service';

import { MAKE_A_PAYMENT } from '../../../../../../shared/constants/common.constants';
import { PaperlessConfirmationMessage } from '../../../../shared/models/paperless-confirmation-message';

@Component({
    selector: 'app-edit-payment-method-confirmation',
    templateUrl: './edit-payment-method-confirmation.component.html',
    styleUrls: ['./edit-payment-method-confirmation.component.scss']
})
export class EditPaymentMethodConfirmationComponent extends BasePageComponent implements OnInit {
    pageName = ManagePaymentPreferencesPageTitle.savedPaymentMethods.edit.confirmation;
    loadComplete = false;
    saveVerb = 'Updated';
    apiCallSuccessful = true;
    formattedCurrentDate: string;
    paperlessConfirmationMessage: PaperlessConfirmationMessage;

    // eslint-disable-next-line max-params
    constructor(
        private paymentPreferencesState: PaymentPreferencesStateService,
        private editPaymentMethodFlow: EditPaymentMethodFlowService,
        private editPaymentMethodConfirmationService: EditPaymentMethodConfirmationService,
        private eventService: BillingEventService,
        @Inject('window') private window: Window,
        @Inject('logger') private logger: LoggerService,
        protected router: Router,
        private urlUtil: UrlUtil,
        session: SessionService,
        private easternTimeDatePipe: EasternTimeDatePipe
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        this.session.currentPage = this.pageName;
        this.apiCallSuccessful = this.editPaymentMethodFlow.paymentMethodSaved;
        this.setFormattedCurrentDate();
        this.paperlessConfirmationMessage = this.editPaymentMethodConfirmationService.getPaperlessFlowMessages(this.editPaymentMethodFlow.enrolledInPaperless,
            this.editPaymentMethodFlow.paperlessEnrollmentAttempted, this.editPaymentMethodFlow.savedPaperlessEsddaPrefs);
        if (this.flowInPlay) {
            this.sendEditPaymentMethodSuccessCtmEvent();
            this.loadComplete = true;
        }
        if (this.editPaymentMethodFlow.isPaperlessEligible) {
            let logMessage = 'Edit Payment method Confirmation - Page load';
            if (this.editPaymentMethodConfirmationService.paperlessEnrollmentError(this.paperlessConfirmationMessage)) {
                logMessage = 'Edit Payment method Confirmation- Page load with error message';
            }
            this.logger.info(logMessage, this.editPaymentMethodConfirmationService.logPageLoadForPaperless(this.editPaymentMethodFlow.editPaymentMethodForm.enrollInPaperless));
            this.logger.logCurrentContents();
        }
        this.sendOnPageLoadEbiEvent();
        this.logPaperlessSuccessfulEnrollment();
    }

    setFormattedCurrentDate(): void {
        const dateFormat = 'MM/dd/yyyy';
        const timeFormat = 'hh:mm aa';
        const date = this.easternTimeDatePipe.transform(new Date(), dateFormat);
        const time = this.easternTimeDatePipe.transform(new Date(), timeFormat);
        this.formattedCurrentDate = `${date}, ${time} ET`;
    }

    get wasEsddaEnrollmentSuccessful(): boolean {
        return this.selectedPaperless && this.editPaymentMethodFlow?.editPaymentMethodResponse?.esddaPreferences === true;
    }

    get isPaperlessEnrollmentUnsuccessful(): boolean {
        return this.editPaymentMethodConfirmationService.paperlessEnrollmentError(this.paperlessConfirmationMessage);
    }

    get errorMessage(): string {
        return this.editPaymentMethodConfirmationService.errorMessage;
    }

    onContinue(): void {
        if (this.selectedEasyPay) {
            this.navigateToEasyPay();
        } else {
            this.navigateToPaymentPreferences();
        }
        this.editPaymentMethodFlow.clear();
    }

    secondayNavigation(metaData: ButtonMetaData): void {
        if (metaData.label === 'Account Summary') {
            this.navigateToCAM();
        } else {
            this.router.navigateByUrl(metaData.navigation);
        }
    }

    sendEditPaymentMethodSuccessCtmEvent(): void {
        this.eventService.handleCtmEvent(
            CtmEventType.PP_EDIT_PAYMENT_METHOD, {
            productType: CtmProductType.BILLING
        });
    }

    showPrintDialog(): void {
        this.sendOnClickOfPrintButtonEbiEvent();
        this.window.print();
    }

    navigateToCAM(): void {
        this.window.location.href = environment.CAM_HOME;
    }
    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString));
    }

    navigateToPaperless(): void {
        this.window.location.href = environment.NW_HEADER.preferences;
    }

    navigateToEasyPay(): void {
        if (this.selectedPaperless) {
            this.paymentPreferencesState.autoCheckPaperless = true;
        }
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.SETUP.LANDING(this.urlUtil.hashParamsString));
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_LOAD_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PAGE);
    }

    sendOnClickOfPrintButtonEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PRINT_BUTTON);
    }

    private logPaperlessSuccessfulEnrollment(): void {
        if (this.wasEsddaEnrollmentSuccessful) {
            this.logger.info('user enrolled in paperless successfully through edit payment method flow');
        }
    }

    get secondaryButton(): ButtonMetaData {
        const buttonMetaData: ButtonMetaData = {
            label: 'Account Summary',
            navigation: environment.CAM_HOME
        };
        if (this.selectedEasyPay || this.selectedPaperless) {
            buttonMetaData.label = 'Payment Prefences';
            buttonMetaData.navigation = environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString);
        }
        return buttonMetaData;
    }

    get selectedEasyPay(): boolean {
        return this.editPaymentMethodFlow.selectedEasyPay === 'yes';
    }

    get selectedPaperless(): boolean {
        return this.editPaymentMethodFlow.selectedPaperless === 'yes';
    }

    get paymentMethodType(): string {
        return this.editPaymentMethodFlow.selectedPaymentMethod.methodType;
    }

    get maskedNumber(): string {
        return this.editPaymentMethodConfirmationService.maskedNumber;
    }

    get upNextLabel(): string {
        return this.editPaymentMethodConfirmationService.upNextLabel || MAKE_A_PAYMENT;
    }

    get flowInPlay(): boolean {
        return this.editPaymentMethodFlow.flowInPlay;
    }

    get apiServiceDownMessage(): string {
        return WARNING_MESSAGES.API_FAILURE;
    }

    get paymentMethodConfirmationDetail(): PaymentMethodConfirmationDetail {
        return {
            paymentType: this.paymentMethodType,
            maskedNumber: this.maskedNumber
        };
    }
}

export interface ButtonMetaData {
    label: string;
    navigation: string;
}
