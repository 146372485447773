import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { AreYouSureFormGroup } from '../../../../../../shared/payments/are-you-sure-radio-buttons/are-you-sure-form-group';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { SetAsDefaultFlow } from '../services/set-as-default-flow.service';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SessionService } from '../../../../../../shared/session/session.service';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { SetAsDefaultApiService } from '../services/set-as-default-api.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { timeout } from 'rxjs';

@Component({
    selector: 'app-set-as-default-landing',
    templateUrl: './set-as-default-landing.component.html',
    styleUrls: [
        './set-as-default-landing.component.scss',
        '../../../../../../shared/payments/stylesheets/form-formatting.scss'
    ]
})
export class SetAsDefaultLandingComponent extends BasePageComponent implements OnInit {
    pageLoadComplete = false;
    submitted = false;
    formGroup: AreYouSureFormGroup;
    pageName = ManagePaymentPreferencesPageTitle.savedPaymentMethods.setAsDefault.landing;

    // eslint-disable-next-line max-params
    constructor(
        router: Router,
        private formBuilder: ManagePaymentPrefsFormBuilder,
        private formUtil: FormUtil,
        private setAsDefaultFlow: SetAsDefaultFlow,
        private paymentPreferencesState: PaymentPreferencesStateService,
        session: SessionService,
        private apiService: SetAsDefaultApiService,
        @Inject('logger') private logger: LoggerService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        this.setupFormGroup();
        this.fetchPaymentMethod();
    }

    setupFormGroup(): void {
        this.formGroup = this.formBuilder.newAreYouSureFormGroup();
    }

    fetchPaymentMethod(): void {
        this.nextSub = this.apiService.fetchPaymentMethod().pipe(
            timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences)
        ).subscribe({
            next: () => this.pageLoadComplete = true,
            error: (error) => {
                this.logger.error('HARDFALL: SET AS DEFAULT PAYMENT LANDING API ERROR', error);
                this.navigateToHardfall();
            }
        });
    }

    onSubmit(): void {
        this.submitted = true;
        this.formUtil.touchAllFormControls(this.formGroup.group);

        if (this.formGroup.group.valid) {
            this.pageLoadComplete = false;
            if (this.formGroup.selection === 'yes') {
                this.sendUpdatePaymentMethodCall();
            } else if (this.formGroup.selection === 'no') {
                this.cancelSetAsDefault();
            }
        }
    }

    sendUpdatePaymentMethodCall(): void {
        this.nextSub = this.apiService.updateSavedPaymentMethod().subscribe({
            next: () => {
                this.setAsDefaultFlow.save({ apiCallSuccessful: true });
                this.navigateToConfirmation();
            },
            error: (error) => {
                this.logger.error('API ERROR: SET AS DEFAULT SUBMISSION', error);
                this.setAsDefaultFlow.save({ apiCallSuccessful: false });
                this.navigateToConfirmation();
            }
        });
    }

    cancelSetAsDefault(): void {
        this.paymentPreferencesState.resetState();
        this.setAsDefaultFlow.reset();
        this.navigateToPaymentPreferences();
    }

    get backFromConfirmationPage(): boolean {
        return this.setAsDefaultFlow.flowInPlay;
    }

    get displayName(): string {
        return this.setAsDefaultFlow.mappedPaymentMethod &&
            this.setAsDefaultFlow.mappedPaymentMethod.managePaymentPrefsDisplayName;
    }

    get currentRouteParams(): string {
        return window.location.href.split('?')[1];
    }

    get displayMessageForForm(): string {
        return `Are you sure you want to change the default payment method to ${this.displayName}?`;
    }

    get globalErrorDisplayable(): boolean {
        return !this.formGroup.group.valid && this.submitted;
    }

    navigateToHardfall(): void {
        this.router.navigateByUrl('/personal/error/system-down');
    }

    navigateToConfirmation(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.SET_AS_DEFAULT.CONFIRMATION(this.currentRouteParams));
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences({
                accountNumber: this.session.billingAccountNumber,
                sessionId: this.session.a2aSessionId
            })
        );
    }
}
