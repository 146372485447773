import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { CardType, submitPaymetCardTypeMap } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { cardUtils } from '../../../../../../shared/payments/card-utils/card-utils';
import { PaymentFormControls } from '../../../../../../shared/payments/payment-form-controls';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { UnitedStatesNameService } from '../../../../../../shared/payments/united-state-name.service';
import { AddPaymentMethodFlowService } from '../services/add-payment-method-flow.service';

@Injectable()
export class AddPaymentReview {
    considerThisDisplayable: boolean;

    // eslint-disable-next-line max-params
    constructor(
        private addPaymentFlow: AddPaymentMethodFlowService,
        private titlecasePipe: TitleCasePipe,
        private stateNames: UnitedStatesNameService,
        private paymentFormControls: PaymentFormControls
    ) { }

    initialize(): void {
        if (this.addPaymentFlow.flowInPlay) {
            this.considerThisDisplayable = this.addPaymentFlow.considerThisDisplayable;
            this.addEmailControlToForm();
        }
    }

    addEmailControlToForm(): void {
        this.addPaymentForm.group.addControl(
            'emailAddressGroup',
            this.paymentFormControls.newEmailFormGroup(this.addPaymentFlow.emailAddress)
        );
    }

    save(params: Partial<AddPaymentMethodFlowService>): void {
        this.addPaymentFlow.save(params);
    }

    get addPaymentForm(): PaymentFormGroup {
        return this.addPaymentFlow.addPaymentForm;
    }

    get savePayMethodAsDefault(): boolean {
        return this.addPaymentForm.makeDefaultPaymentMethod;
    }

    get firstName(): string {
        return this.addPaymentForm.firstName;
    }

    get middleInitial(): string {
        return this.addPaymentForm.middleInitial;
    }

    get lastName(): string {
        return this.addPaymentForm.lastName;
    }

    get emailAddress(): string {
        return this.enrollingInPaperless === 'Yes' && this.addPaymentFlow.paperlessEmail ? this.addPaymentFlow.paperlessEmail : this.addPaymentForm.emailAddress;
    }

    get displayName(): string {
        return this.middleInitial ?
            `${this.firstName} ${this.middleInitial} ${this.lastName}` :
            `${this.firstName} ${this.lastName}`;
    }

    get accountType(): string {
        return this.addPaymentForm.bankAccountType;
    }

    get street(): string {
        return this.addPaymentForm.street;
    }

    get apartmentNumber(): string {
        return this.addPaymentForm.apartmentNumber;
    }

    get city(): string {
        return this.addPaymentForm.city || this.addPaymentFlow.billingCity;
    }

    get stateName(): string {
        const abbreviation = this.addPaymentForm.state || this.addPaymentFlow.billingState;
        return this.stateNames.convertAbbreviationToName(abbreviation);
    }

    get stateAbbreviated(): string {
        let abbreviated = this.addPaymentFlow.billingState;
        if (this.isBankAccount) {
            abbreviated = this.addPaymentForm.state.toUpperCase();
        }
        return abbreviated;
    }

    get zip(): string {
        return this.addPaymentForm.zip;
    }

    get isBankCard(): boolean {
        return this.addPaymentFlow.selectedPaymentMethod.isBankCard;
    }

    get cardNumber(): string {
        return this.addPaymentForm.bankCardNumber;
    }

    get cardNumberLastFour(): string {
        const cardNumber = this.addPaymentForm.bankCardNumber;
        const CARD_LAST_FOUR = 4;
        return cardNumber.slice(cardNumber.length - CARD_LAST_FOUR);
    }

    get cardType(): CardType {
        const cardNumber = this.addPaymentForm.bankCardNumber;
        return cardUtils.parseType(cardNumber);
    }

    get cardBrand(): string {
        return submitPaymetCardTypeMap.get(this.cardType);
    }

    get savedCardType(): CardType {
        return <CardType> this.addPaymentFlow.selectedPaymentMethod.methodType;
    }

    get cardExpirationMonth(): string {
        return this.addPaymentForm.cardExpirationMonth;
    }

    get cardExpirationYear(): string {
        return this.addPaymentForm.cardExpirationYear;
    }

    get cardExpirationDate(): string {
        return `${this.cardExpirationMonth}/${this.cardExpirationYear}`;
    }

    get cvvLabel(): string {
        return this.isAmexCard ? 'CID' : 'CVV';
    }

    get maskedCvv(): string {
        return this.isAmexCard ? '****' : '***';
    }

    get cvv(): string {
        return this.addPaymentForm.bankCardCvv;
    }

    get isAmexCard(): boolean {
        const cardNumber = this.addPaymentForm.bankCardNumber;
        const cardType = cardUtils.parseType(cardNumber);
        return cardType === CardType.AMERICAN_EXPRESS;
    }

    get defaultPaymentMethod(): string {
        let isDefaultPaymentMethod = 'No';
        if (this.addPaymentForm.makeDefaultPaymentMethod) {
            isDefaultPaymentMethod = 'Yes';
        }
        return isDefaultPaymentMethod;
    }

    get isBankAccount(): boolean {
        return this.addPaymentFlow.selectedPaymentMethod.isBankAccount;
    }

    get bankName(): string {
        return this.addPaymentForm.bankName;
    }

    get accountNumberLastThree(): string {
        const bankAccountNumber = this.addPaymentForm.bankAccountNumber;
        const ACCOUNT_LAST_THREE = 3;
        return bankAccountNumber.slice(bankAccountNumber.length - ACCOUNT_LAST_THREE);
    }

    get routingNumber(): string {
        return this.addPaymentForm.bankRoutingNumber;
    }

    get accountNumber(): string {
        return this.addPaymentForm.bankAccountNumber;
    }

    get enrollingInEasyPay(): string {
        const enrollInEasyPay = this.addPaymentFlow.addPaymentForm.enrollInEasyPay;
        return this.titlecasePipe.transform(enrollInEasyPay) || 'No';
    }

    get enrollingInPaperless(): string {
        const enrollInPaperless = this.addPaymentFlow.addPaymentForm.enrollInPaperless;
        return this.titlecasePipe.transform(enrollInPaperless);
    }

    get savingAsRefundMethodRecapValue(): string {
        const saveAsRefundMethodAnswer = this.addPaymentForm.saveAsRefundMethodSelected;
        const accountsSelected = this.addPaymentForm.saveAsRefundMethodAccounts.join(', ');
        return saveAsRefundMethodAnswer === 'yes' && accountsSelected ? `${this.titlecasePipe.transform(saveAsRefundMethodAnswer)}. Applies to billing account(s): ${accountsSelected}` : 'No';
    }

    get saveAsRefundMethodAccounts(): string[] {
        return this.addPaymentForm.saveAsRefundMethodAccounts || [];
    }

    get isEnrollingInRefund(): boolean {
        return this.addPaymentFlow.isEligibleToSaveAsRefundMethod &&
            this.addPaymentForm.saveAsRefundMethodSelected === 'yes' &&
            this.saveAsRefundMethodAccounts.length > 0;
    }

    get payorInfo(): any {
        return {
            firstName: this.firstName,
            middleName: this.middleInitial,
            lastName: this.lastName,
            displayName: this.displayName,
            addressLine1: this.isBankAccount ? this.street : '',
            addressLine2: this.isBankAccount ? this.apartmentNumber : '',
            city: this.isBankAccount ? this.city : '',
            state: this.isBankAccount ? this.stateAbbreviated : '',
            postalCode: this.zip,
            emailAddress: this.emailAddress,
            phone: ''
        };
    }

    get makeDefaultPayment(): string {
        return 'Make this your default payment method';
    }

    get addPaymentDetailsReviewFields(): RecapFormField[] | BankcardRecapFormField[] {
        const fields = [];

        if (this.isBankCard) {
            fields.push({
                id: 'card-holder-name-recap',
                label: 'Card holder\'s name',
                value: this.displayName
            });

            fields.push({
                id: 'card-type',
                label: 'Card type',
                value: this.cardType
            });
            fields.push({
                id: 'card-number-recap',
                label: 'Card number',
                value: `************${this.cardNumberLastFour}`,
                cardType: this.cardType
            });

            fields.push({
                id: 'expiration-date-recap',
                label: 'Expiration date',
                value: this.cardExpirationDate
            });

            fields.push({
                id: 'zip-recap',
                label: 'ZIP',
                value: this.zip
            });

            fields.push({
                id: 'default-payment-method-recap',
                label: this.makeDefaultPayment,
                value: this.defaultPaymentMethod
            });
        } else if (this.isBankAccount) {
            fields.push({
                id: 'account-type-recap',
                label: 'Account type',
                value: this.titlecasePipe.transform(this.accountType)
            });

            fields.push({
                id: 'account-holders-name-recap',
                label: 'Account holder\'s name',
                value: this.displayName
            });

            fields.push({
                id: 'account-holders-address-recap',
                label: 'Account holder\'s address',
                value: `${this.street} ${this.apartmentNumber}`
            });

            fields.push({
                id: 'city-state-zip-recap',
                label: 'City, state and ZIP',
                value: `${this.city}, ${this.stateName} ${this.zip}`
            });

            fields.push({
                id: 'bank-routing-number-recap',
                label: 'Bank routing number',
                value: this.routingNumber
            });

            fields.push({
                id: 'bank-name-recap',
                label: 'Bank name',
                value: this.bankName
            });

            fields.push({
                id: 'account-number-recap',
                label: 'Account number',
                value: `*********${this.accountNumberLastThree}`
            });

            fields.push({
                id: 'default-payment-method-recap',
                label: this.makeDefaultPayment,
                value: this.defaultPaymentMethod
            });
        }
        return fields;
    }

    get considerThisReviewFields(): RecapFormField[] {
        const fieldsArray: RecapFormField[] = [];

        if (this.addPaymentFlow.isEligibleToSaveAsRefundMethod && this.isBankAccount) {
            fieldsArray.push({
                id: 'save-as-refund-method-recap',
                label: 'Use this account to receive refunds, credits and other payments due to you?',
                value: this.savingAsRefundMethodRecapValue
            });
        }

        if (this.addPaymentFlow.isEasyPayEligible) {
            fieldsArray.push({
                id: 'enroll-in-ez-pay-recap',
                label: 'Would you like to enroll in automatic payments?',
                value: this.enrollingInEasyPay
            });
        }

        if (this.addPaymentFlow.isPaperlessEligible) {
            fieldsArray.push({
                id: 'enroll-in-paperless-recap',
                label: 'Would you like to enroll in paperless for all of your accounts and policies?',
                value: this.enrollingInPaperless
            });
        }

        if (this.enrollingInPaperless === 'Yes') {
            fieldsArray.push({
                id: 'enroll-in-paperless-esdda',
                label: 'Payment confirmation and paperless email',
                value: this.addPaymentFlow.paperlessEmail || this.addPaymentFlow.emailAddress
            });
        }
        return fieldsArray;
    }
}
