import { AddPaymentMethodComponent } from './add/add-payment-method.component';
import { ManagePaymentPreferencesPageTitle } from '../shared/models/manage-payment-preferences-page-title.constant';
import { AddPaymentMethodLandingComponent } from './add/landing/add-payment-method-landing.component';
import { AddPaymentMethodReviewComponent } from './add/review/add-payment-method-review.component';
import { AddPaymentMethodConfirmationComponent } from './add/confirmation/add-payment-method-confirmation.component';
import { EditPaymentMethodLandingComponent } from './edit/landing/edit-payment-method-landing.component';
import { EditPaymentMethodReviewComponent } from './edit/review/edit-payment-method-review.component';
import { EditPaymentMethodConfirmationComponent } from './edit/confirmation/edit-payment-method-confirmation.component';
import { RemovePaymentMethodLandingComponent } from './remove/landing/remove-payment-method-landing.component';
import { RemovePaymentMethodConfirmationComponent } from './remove/confirmation/remove-payment-method-confirmation.component';
import { SetAsDefaultLandingComponent } from './set-as-default/landing/set-as-default-landing.component';
import { SetAsDefaultConfirmationComponent } from './set-as-default/confirmation/set-as-default-confirmation.component';
import { ManagePaymentPreferencesGuard } from '../shared/guards/manage-payment-preferences.guard';
import { SetAsDefaultConfirmationPageGuard } from './set-as-default/guards/set-as-default-confirmation-page.guard';
import { SetAsDefaultBackFromConfirmationGuard } from './set-as-default/guards/set-as-default-back-from-confirmation.guard';
import { RemovePaymentMethodConfirmationGuard } from './remove/guards/remove-payment-method-confirmation.guard';
import { RemovePaymentMethodBackFromConfirmationGuard } from './remove/guards/remove-payment-method-back-from-confirmation.guard';
import { EditPaymentMethodBackFromConfirmationGuard } from './edit/guards/edit-payment-method-back-from-confirmation-guard';
import { EditPaymentMethodConfirmationGuard } from './edit/guards/edit-payment-method-confirmation-guard';
import { StateClearingGuard } from '../../../../shared/service/guards/state-clearing-guard/state-clearing.guard';

export const SavedPaymentMethodRoutes = [
    {
        path: 'saved-payment-methods/add',
        component: AddPaymentMethodComponent,
        data: { title: ManagePaymentPreferencesPageTitle.savedPaymentMethods.add },
        children: [
            {
                path: 'landing',
                component: AddPaymentMethodLandingComponent
            },
            {
                path: 'review',
                component: AddPaymentMethodReviewComponent
            },
            {
                path: 'confirmation',
                component: AddPaymentMethodConfirmationComponent,
                canDeactivate: [StateClearingGuard]
            }
        ]
    },
    {
        path: 'saved-payment-methods/edit/landing',
        component: EditPaymentMethodLandingComponent,
        canActivate: [ManagePaymentPreferencesGuard],
        data: { title: ManagePaymentPreferencesPageTitle.savedPaymentMethods.edit.landing }
    },
    {
        path: 'saved-payment-methods/edit/review',
        component: EditPaymentMethodReviewComponent,
        canActivate: [ManagePaymentPreferencesGuard, EditPaymentMethodBackFromConfirmationGuard],
        data: { title: ManagePaymentPreferencesPageTitle.savedPaymentMethods.edit.review }
    },
    {
        path: 'saved-payment-methods/edit/confirmation',
        component: EditPaymentMethodConfirmationComponent,
        canActivate: [ManagePaymentPreferencesGuard, EditPaymentMethodConfirmationGuard],
        canDeactivate: [StateClearingGuard],
        data: { title: ManagePaymentPreferencesPageTitle.savedPaymentMethods.edit.confirmation }
    },
    {
        path: 'saved-payment-methods/remove/landing',
        component: RemovePaymentMethodLandingComponent,
        canActivate: [ManagePaymentPreferencesGuard, RemovePaymentMethodBackFromConfirmationGuard],
        data: { title: ManagePaymentPreferencesPageTitle.savedPaymentMethods.remove.landing }
    },
    {
        path: 'saved-payment-methods/remove/confirmation',
        component: RemovePaymentMethodConfirmationComponent,
        canActivate: [RemovePaymentMethodConfirmationGuard],
        canDeactivate: [StateClearingGuard],
        data: { title: ManagePaymentPreferencesPageTitle.savedPaymentMethods.remove.confirmation }
    },
    {
        path: 'saved-payment-methods/set-as-default/landing',
        component: SetAsDefaultLandingComponent,
        canActivate: [ManagePaymentPreferencesGuard, SetAsDefaultBackFromConfirmationGuard],
        data: { title: ManagePaymentPreferencesPageTitle.savedPaymentMethods.setAsDefault.landing }
    },
    {
        path: 'saved-payment-methods/set-as-default/confirmation',
        component: SetAsDefaultConfirmationComponent,
        canActivate: [SetAsDefaultConfirmationPageGuard],
        canDeactivate: [StateClearingGuard],
        data: { title: ManagePaymentPreferencesPageTitle.savedPaymentMethods.setAsDefault.confirmation }
    }
];
