import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoggerService } from '../../../logger/logger.service';
import { BaseFormGroupComponent } from '../../base-form-control/base-form-control.component';
import { FormUtil } from '../../form-util';
import { BillingAccountItem } from './models/billing-account-item.model';
import { SaveAsRefundMethodService } from './services/save-as-refund-method.service';

export type ControlNames = 'selection' | 'selectedAgreements';

@Component({
    selector: 'app-save-as-refund-method',
    templateUrl: './save-as-refund-method.component.html',
    styleUrls: [
        './save-as-refund-method.component.scss',
        '../../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../consider-this.component.scss'
    ]
})
export class SaveAsRefundMethodComponent extends BaseFormGroupComponent<ControlNames> implements OnInit, OnDestroy {
    @Output() billingAccountsForRefund = new EventEmitter<BillingAccountItem[]>();
    helpText = `You'll receive all policy refunds and other payments due to you from Nationwide using this payment method. In the event of a claim, you'll have the option to choose your claims payment method at that time. You can change this at any time by going to Billing details > Payment preferences.`;
    billingAccounts: BillingAccountItem[];
    subscriptions: Subscription[] = [];
    apiCallFailed = false;
    callInProgress = false;

    // eslint-disable-next-line max-params
    constructor(
        controlContainer: ControlContainer,
        private saveAsRefundMethodService: SaveAsRefundMethodService,
        @Inject('logger') private logger: LoggerService,
        private formUtil: FormUtil
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        this.getControl('selection').setValidators([Validators.required]);
        if (this.shouldDisplayAccounts) {
            this.fetchBillingAccounts();
        }

        this.subscriptions.push(this.getControl('selection').valueChanges.subscribe(() => {
            if (this.shouldDisplayAccounts) {
                this.getControl('selectedAgreements').markAsUntouched();
                this.fetchBillingAccounts();
            } else {
                this.billingAccounts = [];
                this.formUtil.emptyFormArray(this.selectedAgreements);
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    get selectionValue(): 'yes' | 'no' | '' {
        return this.getControl('selection').value;
    }

    get selectedAgreements(): UntypedFormArray {
        return <UntypedFormArray> this.getControl('selectedAgreements');
    }

    get shouldDisplayAccounts(): boolean {
        return this.selectionValue === 'yes';
    }

    get selectionRequiredErrorDisplayable(): boolean {
        return this.errorsDisplayable('selection') &&
            this.getControl('selection').getError('required');
    }

    get pleaseSelectAccountErrorDisplayable(): boolean {
        return this.shouldDisplayAccounts &&
            this.getControl('selectedAgreements').touched &&
            this.formGroup.getError('formArrayHasValue');
    }

    isAccountSelected(account: BillingAccountItem): boolean {
        return this.selectedAgreements.value.includes(account.accountNumber);
    }

    fetchBillingAccounts(): void {
        this.apiCallFailed = false;
        this.callInProgress = true;
        this.subscriptions.push(this.saveAsRefundMethodService.retrieveAccounts().subscribe({
            next: (res) => {
                this.callInProgress = false;
                this.billingAccounts = res;
                this.billingAccountsForRefund.emit(this.billingAccounts);
                if (res && (res.length === 0 || res.filter((acc) => acc.eligible).length === 0)) {
                    this.formGroup.setValidators(null);
                    this.formGroup.setErrors(null);
                } else if (res && res.length === 1 && res[0].eligible) {
                    const indexInForm = this.selectedAgreements.value.findIndex((agreement) => agreement === res[0].accountNumber);
                    if (indexInForm === -1) {
                        this.selectedAgreements.push(new UntypedFormControl(res[0].accountNumber));
                        this.selectedAgreements.updateValueAndValidity();
                    }
                }
            },
            error: (err) => {
                this.callInProgress = false;
                this.logger.error('Error fetching billing accounts for PL Default Saved Payment Method', err);
                this.apiCallFailed = true;
                setTimeout(() => this.getControl('selection').setValue('no'), 0);
            }
        }));
    }

    handleAccountClick(account: BillingAccountItem): void {
        if (account.eligible) {
            const indexInForm = this.selectedAgreements.value.findIndex((agreement) => agreement === account.accountNumber);
            if (indexInForm > -1) {
                this.selectedAgreements.removeAt(indexInForm);
            } else {
                this.selectedAgreements.push(new UntypedFormControl(account.accountNumber));
            }
        }
    }
}
