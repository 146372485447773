<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>
<form *ngIf="loadComplete" (ngSubmit)="submit()" [formGroup]="paymentFormGroup.group" class="manage-payment-form-container">
    <div class="bolt-container">


        <bw-bolt-progress-bar appFocusOnInit arialabel="setup automatic payments progress value is 20%" class="progress-bar-container to-container" progressBarValue="20"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Set up automatic payments</h1>

        <div class="bolt-row">
            <div class="bolt-col-12">
                <bw-bolt-notification type="info" class="bolt-space-bottom-2xl to-container" *ngIf="selectedPaymentMethod?.isBankCard">
                    <is-interpolate [text]="bankCardAutomaticPaymentsCapMessage"></is-interpolate>
                </bw-bolt-notification>

                <bw-bolt-notification type="error" [id]="'global-error-message'" *ngIf="(globalErrorDisplayable || paperlessEsddaValidationError) && !accountValidationMessage"
                    class="bolt-space-bottom-2xl to-container">
                    Please check the information below and try again.
                </bw-bolt-notification>

                <bw-bolt-notification type="error" *ngIf="showEFTAuthFormValidationMessage" id="eFTAuthFormValidationMessageId" class="bolt-space-bottom-2xl to-container">
                    {{eFTAuthFormValidationMessage}}
                </bw-bolt-notification>

                <bw-bolt-notification type="error" *ngIf="displayVulCreditCardErrorMessage" id="vulCreditCardErrorMessage" class="bolt-space-bottom-2xl to-container">
                    {{ vulCreditCardErrorMessage}}
                </bw-bolt-notification>

                <section id="payment-method-section" class="fluid-container">
                    <app-payment-sub-header>Payment information</app-payment-sub-header>
                    <bw-bolt-notification type="error" *ngIf="accountValidationMessage" id="accountValidationerrorMessageId" class="bolt-space-bottom-2xl to-container">
                        {{accountValidationMessage}}
                    </bw-bolt-notification>
                    <div class="section-content">
                        <div
                            [ngClass]="((selectedPaymentMethod?.isNewMethod && ((selectedPaymentMethod?.isBankCard) || (selectedPaymentMethod?.isBankAccount))) || (paymentDetailsReviewFields?.length))?'' : 'bolt-space-bottom-lg' ">
                            <app-payment-method-select [paymentMethods]="paymentMethods" [increaseReftAutoPayFlag]="increaseReftAutoPayFlag" [(selectedPaymentMethod)]="selectedPaymentMethod"
                                [isManagePaymentPreferencesFlow]="true" [reducedFees]="this.NBP && !this.isLifeOnlyAccount" [isFullPay]="this.isFullPay"
                                [(displayErrors)]="defaultPamentMethodSelectionError" [(isLifeOnlyAccount)]="this.isLifeOnlyAccount">
                            </app-payment-method-select>
                        </div>
                        <div *ngIf="selectedPaymentMethod?.isBankAccount && selectedPaymentMethod?.isNewMethod" [ngClass]="isEasyPayEligible?'bolt-space-bottom-xl': 'bolt-space-bottom-2xl'">
                            <app-bank-account-form-controls formGroupName="paymentMethodGroup" [isManagePaymentPreferencesFlow]="true">
                            </app-bank-account-form-controls>
                        </div>

                        <div *ngIf="selectedPaymentMethod?.isBankCard && selectedPaymentMethod?.isNewMethod" [ngClass]="isEasyPayEligible?'bolt-space-bottom-xl': 'bolt-space-bottom-2xl'">
                            <app-bank-card-form-controls formGroupName="paymentMethodGroup" [isManagePaymentPreferencesFlow]="true">
                            </app-bank-card-form-controls>
                        </div>

                        <fieldset class="section-content" *ngIf="paymentDetailsReviewFields?.length" [ngClass]="{'bolt-space-bottom-lg': !isEasyPayEligible}">
                            <legend class="fields-only"></legend>
                            <ng-container *ngFor='let field of paymentDetailsReviewFields'>
                                <app-recap-form-field *ngIf="!field.cardType" [field]="field" class="to-container bolt-space-bottom-xl">
                                </app-recap-form-field>
                                <app-bankcard-recap-form-field *ngIf="field.cardType" [field]="field" class="to-container bolt-space-bottom-xl">
                                </app-bankcard-recap-form-field>

                            </ng-container>
                        </fieldset>
                    </div>
                </section>

                <app-consider-this formGroupName="considerThisGroup" [canSelectPaperlessBilling]="isPaperlessEligible" (esddaCheckBoxChecked)="onEsddaCheckboxClick($event)"
                    [paperlessEsddaValidationError]="paperlessEsddaValidationError" [email]="getEmailAddress()" [paperlessEnrollmentOption]="paymentFormGroup.enrollInPaperless"
                    [isEligibleForEasyPay]="isEasyPayEligible" [isAutoPayment]="isAutoPayment">
                </app-consider-this>
            </div>
        </div>
        <bw-bolt-notification type="info" class="bolt-space-bottom-2xl to-container">
            If there is a change in your premium amount you’ll receive a notification before any automatic payment is
            processed.
        </bw-bolt-notification>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" (click)="cancelSetupAutomaticPayments()">Cancel</bolt-button>
        <bolt-button submit slot="forward" type="primary" id="continue-button">Continue</bolt-button>
    </bolt-button-bar>
</form>