import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { environment } from '../../../../../../../environments/environment';
import { SessionService } from '../../../../../../shared/session/session.service';

@Injectable()
export class EditAutomaticPaymentsConfirmationGuard implements CanActivate {
    constructor(
        private editPaymentMethodFlow: EditAutomaticPaymentFlowService,
        private router: Router,
        private session: SessionService
    ) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.editPaymentMethodFlow.flowInPlay || this.router.parseUrl(environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences({
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId
        }));
    }
}
