<nav class="tabs">
    <a *ngFor="let tab of tabs" [ngClass]="{ 'active' : tab.active }" role="tab" [attr.aria-selected]="tab.active ? 'true' : 'false'" (click)="tabClicked(tab)" (keydown.enter)="tabClicked(tab)"
        tabindex="0" id="{{ replaceWhiteSpaceWithDash(tab.text) | lowercase }}-tab">

        <svg class="dollar-sign-icon" *ngIf="showDollarSignIcon(tab)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path
                d="M29.19007,21.50034c-0.673-2.50259-2.83905-3.81386-7.05138-4.71651V13.096a16.5076,16.5076,0,0,1,4.08317,1.89067l2.49853-4.18387a17.58017,17.58017,0,0,0-6.5817-2.38671V6.46851H19.016v1.8866c-5.20442.33951-7.28619,2.85023-7.28619,6.47706v0.061A7.14963,7.14963,0,0,0,12.09572,17.29c0.79489,2.24644,2.94381,3.452,6.92032,4.32821v3.74881a13.74232,13.74232,0,0,1-5.7086-2.57172l-2.73232,4.12084A17.60537,17.60537,0,0,0,19.016,30.01648v3.515h3.12265V30.047c4.16354-.40253,7.28619-2.72622,7.28619-6.50756v-0.063A7.63535,7.63535,0,0,0,29.19007,21.50034ZM19.016,16.19427c-2.08177-.46352-2.08177-0.92907-2.08177-1.76666v-0.061c0-.80506,1.04088-1.42512,2.08177-1.51863v3.34628Zm5.20442,7.74768c0,0.89858-1.04088,1.45561-2.08177,1.58166V22.1143c1.04088,0.433,2.08177.92907,2.08177,1.76666v0.061Z" />
        </svg>

        <svg class="question-mark-icon" *ngIf="showQuestionMarkIcon(tab)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.88 74.88">
            <path
                d="M30.35,34.68,31,34.1c8.35-.72,11.3-2.74,11.3-5.76v-.15c0-2.44-2-4-5.47-4S29.77,26,26.32,29l-7.21-9.44A25.35,25.35,0,0,1,37.76,12c10.59,0,18,5.69,18,15.12v.15c0,9.07-6.05,12.89-14.05,14.9l-.86,4.61H32.22ZM29.2,51H43.38V63.19H29.2Z" />
        </svg>

        <svg class="gears-icon" *ngIf="showGearsIcon(tab)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 65">
            <path
                d="M42.91,39.26a11.72,11.72,0,0,0,0-3.24l4.16-2-1-2.42-.93-2.23L40.85,31a11.48,11.48,0,0,0-2.32-2.35l1.61-4.52-4.66-1.92-2,4.28a11.27,11.27,0,0,0-3.38,0l-2-4.3-4.65,1.94L25,28.48a11.61,11.61,0,0,0-2.45,2.45l-4.08-1.45-1.91,4.66L20.49,36a11.53,11.53,0,0,0,0,3.47l-4.15,2,.91,2.17,1,2.48,4.37-1.58a11.21,11.21,0,0,0,2.44,2.37L23.63,51l4.66,1.92,1.9-4.06a11.18,11.18,0,0,0,3.32,0l1.91,4,4.65-1.94-1.53-4.24a11.3,11.3,0,0,0,2.27-2.31L45.41,46l1.91-4.66ZM31.84,44a6.44,6.44,0,1,1,5.95-8.9,6.5,6.5,0,0,1,.49,2.46A6.44,6.44,0,0,1,31.84,44Z" />
            <path
                d="M31.84,44.37a6.84,6.84,0,1,1,6.85-6.85A6.83,6.83,0,0,1,31.84,44.37Zm0-12.88a6,6,0,0,0-6,6,6,6,0,1,0,12.06,0,5.87,5.87,0,0,0-.46-2.3h0A6,6,0,0,0,31.84,31.49Zm-.15,10.43a4.29,4.29,0,1,1,1.64-8.26,4.31,4.31,0,0,1,2.34,5.6h0A4.31,4.31,0,0,1,31.69,41.92Zm0-7.77a3.49,3.49,0,0,0-3.22,2.16,3.49,3.49,0,0,0,1.89,4.53A3.48,3.48,0,0,0,34.91,39a3.42,3.42,0,0,0,0-2.68A3.48,3.48,0,0,0,33,34.41,3.61,3.61,0,0,0,31.7,34.15Z" />
            <path
                d="M56.46,20.69A7.37,7.37,0,0,0,55.93,18l2.27-2.32L57,14.29l-1.59-1.8-2.47,1.93a7.44,7.44,0,0,0-1.43-.65,7,7,0,0,0-1.34-.3l-.82-3-4.19.87.43,3a7.23,7.23,0,0,0-2.23,2l-2.83-.74-1.34,4.06,2.75,1.09a7.29,7.29,0,0,0,.63,2.89l-2.2,2.22,1.28,1.43,1.57,1.76,2.51-2a7.21,7.21,0,0,0,1.2.54,8,8,0,0,0,1.5.32l.84,3.11,4.19-.87L52.93,27a7.37,7.37,0,0,0,2-1.8l3.34.87L59.63,22Zm-7.24,3.76A3.8,3.8,0,1,1,53,20.64,3.81,3.81,0,0,1,49.22,24.45Z" />
            <path
                d="M49.22,16.82a3.81,3.81,0,1,1-3.82,3.82,3.8,3.8,0,0,1,3.82-3.82m0,6.4a2.5,2.5,0,0,0,2-4A2.47,2.47,0,0,0,50,18.33a2.64,2.64,0,0,0-.78-.12,2.49,2.49,0,0,0-1.81,4.23,2.61,2.61,0,0,0,1,.66,2.71,2.71,0,0,0,.79.12m0-7.22a4.64,4.64,0,0,0-4.64,4.64,4.67,4.67,0,0,0,4.64,4.63,4.64,4.64,0,0,0,0-9.27Zm0,6.41A1.71,1.71,0,0,1,48,21.88a1.71,1.71,0,0,1-.39-1.69A1.69,1.69,0,0,1,49.18,19a2,2,0,0,1,.53.08,1.69,1.69,0,0,1,.81.59,1.72,1.72,0,0,1,.26,1.55,1.69,1.69,0,0,1-1.6,1.16Z" />
        </svg>

        <svg class="bill-icon" *ngIf="showBillIcon(tab)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 65">
            <path
                d="M54.76,56.6H24.43a2.92,2.92,0,0,1-2.91-2.91V51H44.27L52,42.35V12.56h2.72a2.91,2.91,0,0,1,2.91,2.91V53.69A2.91,2.91,0,0,1,54.76,56.6ZM22.48,52v1.73a2,2,0,0,0,1.95,2H54.76a2,2,0,0,0,2-2V15.47a2,2,0,0,0-2-2H53V42.71L44.69,52Z" />
            <path
                d="M50.09,8.4H19.76a2.43,2.43,0,0,0-2.43,2.43V49.06a2.42,2.42,0,0,0,2.43,2.42H44.48l8-8.95V10.83A2.43,2.43,0,0,0,50.09,8.4Zm-13.42,33H24.4V40.1H36.67ZM24.4,36.83V35.56H46.28v1.27ZM45.13,50V45.39A1.83,1.83,0,0,1,47,43.56h4.17Z" />
            <path class="dollar-sign"
                d="M41.5,24c-.46-1.67-1.91-2.55-4.73-3.16V18.41a11.15,11.15,0,0,1,2.74,1.27l1.67-2.8a11.81,11.81,0,0,0-4.41-1.6V14H34.68v1.27c-3.49.23-4.88,1.91-4.88,4.34v0a4.85,4.85,0,0,0,.24,1.6c.54,1.51,2,2.32,4.64,2.9v2.51a9.17,9.17,0,0,1-3.82-1.72L29,27.67a11.8,11.8,0,0,0,5.65,2.08V32.1h2.09V29.77c2.79-.27,4.88-1.83,4.88-4.36v0A5.47,5.47,0,0,0,41.5,24Zm-6.82-3.55c-1.39-.31-1.39-.62-1.39-1.18v0c0-.53.69-1,1.39-1Zm3.49,5.19c0,.6-.7,1-1.4,1.06V24.45c.7.29,1.4.63,1.4,1.19Z" />
        </svg>

        <span>{{ tab.text }}</span>
    </a>
</nav>