import { Component, DoCheck } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';
import { UnitedStatesNameService } from '../united-state-name.service';

export type ControlNames = 'street' | 'apartmentNumber' | 'city' | 'state' | 'zipGroup';

@Component({
    selector: 'app-address-form-control',
    templateUrl: './address-form-control.component.html',
    styleUrls: [
        './address-form-control.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class AddressFormControlComponent extends BaseFormGroupComponent<ControlNames> implements DoCheck {
    isStateError = false;

    constructor(
        controlContainer: ControlContainer,
        private stateNames: UnitedStatesNameService
    ) {
        super(controlContainer);
    }

    ngDoCheck(): void {
        if (this.state.value && this.states.length) {
            const selectedStateIndex = this.states.findIndex((state) =>
                state.toUpperCase() === this.state.value.toUpperCase()
            );
            if (selectedStateIndex > -1) {
                this.state.setValue(this.states[selectedStateIndex]);
            }
        }
    }

    get states(): string[] {
        return this.stateNames.abbreviations;
    }

    get street(): AbstractControl {
        return this.getControl('street');
    }

    get streetErrorsDisplayable(): boolean {
        return this.errorsDisplayable('street');
    }

    get state(): AbstractControl {
        return this.getControl('state');
    }

    get stateErrorsDisplayable(): boolean {
        return this.errorsDisplayable('state');
    }

    get city(): AbstractControl {
        return this.getControl('city');
    }

    get cityErrorsDisplayable(): boolean {
        return this.errorsDisplayable('city');
    }

    get aptNumber(): AbstractControl {
        return this.getControl('apartmentNumber');
    }

    get aptNumberErrorsDisplayable(): boolean {
        return this.errorsDisplayable('apartmentNumber');
    }

    onStateChange($evt): void {
        const newVal = $evt?.target?.value?.toLowerCase();
        if (newVal === '') {
            // which means the placeholder selected
            this.isStateError = true;
            this.state.setErrors({ required: true });
            this.state.markAsDirty();
            this.state.markAsTouched();
        } else {
            this.state.patchValue(newVal);
            // wait a tick until the form control value updated
            setTimeout(() => {
                this.isStateError = false;
                this.state.setErrors({ required: null });
                this.state.updateValueAndValidity();
            });
        }
    }
}
