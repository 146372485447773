import { Injectable } from '@angular/core';
@Injectable()
export class PayPlanFlow {
    minimumDueAmount: number;
    fullPayAmount: number;
    premiumAmount: number;
    billingAccountNumber: string;
    policyNumber: string;
    policyType: string;
    termExpirationDate: Date;
    currentDueDate: Date;
    nextDueDate: Date;
    isDirectBill: boolean;
    isAutoPayEnrolled: boolean;
    isPayPlanUpdateSuccess: boolean;
    payPlanChangeDate: Date;
    selectedPayPlan: string;
    canPayNow: boolean;
    isCurrentBillPaid: boolean;
    isEmptyBillResponse: boolean;
    flowInPlay: boolean;

    save(params: Partial<PayPlanFlow>): void {
        this.minimumDueAmount = params.minimumDueAmount;
        this.fullPayAmount = params.fullPayAmount;
        this.premiumAmount = params.premiumAmount;
        this.billingAccountNumber = params.billingAccountNumber;
        this.policyNumber = params.policyNumber;
        this.policyType = params.policyType;
        this.termExpirationDate = params.termExpirationDate;
        this.currentDueDate = params.currentDueDate;
        this.nextDueDate = params.nextDueDate;
        this.isDirectBill = params.isDirectBill;
        this.isAutoPayEnrolled = params.isAutoPayEnrolled;
        this.canPayNow = params.canPayNow;
        this.isCurrentBillPaid = params.isCurrentBillPaid;
        this.isEmptyBillResponse = params.isEmptyBillResponse;
    }

    savePayPlanResponse(isSuccess: boolean, selectedPayPlan: string, payplanChangeDate: Date): void {
        this.flowInPlay = true;
        this.payPlanChangeDate = payplanChangeDate;
        this.isPayPlanUpdateSuccess = isSuccess;
        this.selectedPayPlan = selectedPayPlan;
    }

    reset(): void {
        this.minimumDueAmount = null;
        this.fullPayAmount = null;
        this.premiumAmount = null;
        this.billingAccountNumber = null;
        this.policyNumber = null;
        this.policyType = null;
        this.termExpirationDate = null;
        this.currentDueDate = null;
        this.nextDueDate = null;
        this.isDirectBill = false;
        this.isAutoPayEnrolled = false;
        this.isCurrentBillPaid = false;
        this.isEmptyBillResponse = false;
        this.flowInPlay = false;
    }
}
