import { PaymentItem } from '../../../billing/payment-preferences/shared/models/payment-item.model';

export class PayMethodList {
    private _paymentMethods: PaymentItem[];
    private _defaultMethod: PaymentItem;
    private onFileMethods: PaymentItem[];
    private bankAccountMethods: PaymentItem[];
    private bankCardMethods: PaymentItem[];
    private masterpassMethods: PaymentItem[];

    constructor(
        methods: PaymentItem[]
    ) {
        this._paymentMethods = methods;
        this.bankAccountMethods = methods?.filter((method) => method.isBankAccount);
        this.bankCardMethods = methods?.filter((method) => method.isBankCard);
        this.masterpassMethods = methods?.filter((method) => method.isMasterPass);
        const defaultMethodList = methods?.filter((method) => method.isDefault).length ?
            methods?.filter((method) => method.isDefault) : undefined ||
                methods?.filter((method) => !!method.isBankAccount && method.isNewMethod).length ?
                methods?.filter((method) => method.isBankAccount && method.isNewMethod) : undefined ||
                methods?.filter((method) => method.isBankCard && method.isNewMethod);
        this._defaultMethod = defaultMethodList?.[0];
        this.onFileMethods = methods?.filter((method) => !method.isNewMethod);
    }

    get defaultMethod(): PaymentItem {
        return this._defaultMethod;
    }

    get defaultBankAccountMethod(): PaymentItem {
        return this.bankAccountMethods[0];
    }

    get asList(): PaymentItem[] {
        return this._paymentMethods;
    }

    get asBankAccountList(): PaymentItem[] {
        return this.bankAccountMethods;
    }

    get asBankCardList(): PaymentItem[] {
        return this.bankCardMethods;
    }

    get asMasterpassList(): PaymentItem[] {
        return this.masterpassMethods;
    }

    get hasSavedPayMethods(): boolean {
        return this.onFileMethods.length > 1 || this.onFileMethods.length > 0 && !this.onFileMethods[0].isPlaceholder;
    }
}
