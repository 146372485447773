<div class="bolt-row">
    <div class="bolt-col-md-12">
        <ng-container *ngIf="!bolt">
            <ng-content select="input[type='checkbox']"></ng-content>
            <label for="{{labelFor}}">
                <ng-content select="[label-text]"></ng-content>
            </label>
            <is-inline-help *ngIf="helpTextHtml" [labelInnerHTML]="''" [fieldId]="id + '-help-text'" [helpTextInnerHTML]="helpTextHtml">
            </is-inline-help>
        </ng-container>
        <ng-container *ngIf="bolt">
            <div class="checkbox-container">
                <ng-content></ng-content>
                <bolt-contextual-help *ngIf="helpTextHtml" [id]="id + '-help-text'">
                    <p>{{helpTextHtml}}</p>
                </bolt-contextual-help>
            </div>
        </ng-container>
    </div>
</div>