import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '../../logger/logger.service';
import { BillingApiCommonService, BillingEBIOptions } from '../billing-api-common.service';
import { EbiApiService } from './ebi-api.service';
import { SessionService } from '../../session/session.service';
import { GetIpService } from '@nationwide/dgs-angular-billing-common';
import { environment } from '../../../../environments/environment';
import { timeout } from 'rxjs';

@Injectable()
export class EbiService {
    // eslint-disable-next-line max-params
    constructor(
        private ebiApiService: EbiApiService,
        private apiCommonService: BillingApiCommonService,
        private session: SessionService,
        private getIpService: GetIpService,
        @Inject('logger') private LOGGER: LoggerService
    ) { }

    registerEbiEvent({ eventId, additionalFields, eventDescription, options }: { eventId: string; additionalFields: string[]; eventDescription: string; options?: BillingEBIOptions }): void {
        if (this.session.ipAddress) {
            this.setDataAndSend({ eventId, additionalFields, eventDescription, options });
        } else {
            this.getIpService.getClientIp().pipe(
                timeout(environment.TIMEOUT.apiCallDuration.getClientIp)
            ).subscribe({
                next: (res) => {
                    this.session.ipAddress = res.ip;
                    this.setDataAndSend({ eventId, additionalFields, eventDescription, options });
                },
                error: (err) => {
                    this.LOGGER.error('API ERROR: GET CLIENT IP ADDRESS', err);
                    this.setDataAndSend({ eventId, additionalFields, eventDescription, options });
                }
            });
        }
    }

    setDataAndSend(
        { eventId, additionalFields, eventDescription, options }: { eventId: string; additionalFields: string[]; eventDescription: string; options?: BillingEBIOptions }): void {
        try {
            if (eventDescription) {
                const data = this.apiCommonService.setBillingEbiData(
                    { additionalFields, eventDescription, eventId, options: { documentType: '', paymentMethod: [], ...options } });
                if (data && eventId) {
                    const callback = options?.callback || ((): void => { });
                    this.ebiApiService.sendEbiEvent(eventId, data, callback);
                }
            }
        } catch (error) {
            this.LOGGER.error('AN ERROR OCCURRED WHILE FIRING AN EBI EVENT', error);
        }
        this.LOGGER.trace('BillingEbiService - registerEbiEvent - END');
    }
}
