import { Injectable } from '@angular/core';

@Injectable()
export class UrlUtil {
    get hashPath(): string {
        const hash = window.location.hash.split('?');
        return hash[0];
    }

    get hashParams(): object {
        const hash = window.location.hash.split('?');
        const hashParams = {};
        hash[1]?.split('&').forEach((queryParam) => {
            const [key, value] = queryParam.split('=');
            hashParams[key] = value;
        });
        return hashParams;
    }

    get hashParamsString(): string {
        return window.location.hash.split('?')[1] || '';
    }

    get currentPage(): string {
        const hashPathParts = this.hashPath.split('/');
        return hashPathParts[hashPathParts.length - 1];
    }

    extractPageUrl(url: string): string {
        const pathParts = url ? url.split('/') : [];
        return pathParts && pathParts.length > 0 && pathParts[pathParts.length - 1];
    }
}
