import { Injectable } from '@angular/core';
import { PersonalBilling } from '@nationwide/dgs-angular-billing-common';

@Injectable()
export class RemoveRefundMethodFlowService {
    flowInPlay: boolean;
    apiCallSuccessful: boolean;
    refundMethod: PersonalBilling.RefundMethod;
    maskedBankAccountNumber: string;
    emailAddress: string;

    save(params: Partial<RemoveRefundMethodFlowService>): void {
        this.flowInPlay = true;
        this.apiCallSuccessful = params.apiCallSuccessful;
        this.refundMethod = params.refundMethod || this.refundMethod;
        this.maskedBankAccountNumber = params.maskedBankAccountNumber || this.maskedBankAccountNumber;
        this.emailAddress = params.emailAddress || this.emailAddress;
    }

    reset(): void {
        this.flowInPlay = null;
        this.apiCallSuccessful = null;
        this.refundMethod = null;
        this.maskedBankAccountNumber = null;
        this.emailAddress = null;
    }
}
