import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { SessionService } from '../../../../../../shared/session/session.service';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';

@Injectable()
export class EditAutomaticPaymentsBackFromConfirmationGuard implements CanActivate {
    // eslint-disable-next-line max-params
    constructor(
        private editPaymentMethodFlow: EditAutomaticPaymentFlowService,
        private router: Router,
        private paymentPreferencesState: PaymentPreferencesStateService,
        private session: SessionService) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const canActivateResponse = this.session.currentPage !== ManagePaymentPreferencesPageTitle.automaticPayments.edit.confirmation && this.editPaymentMethodFlow.flowInPlay;

        if (!canActivateResponse) {
            this.editPaymentMethodFlow.clear();
            this.paymentPreferencesState.resetState();
        }

        return canActivateResponse || this.router.parseUrl(environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences({
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId
        }));
    }
}
