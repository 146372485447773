<fieldset class="section-content bolt-space-bottom-lg" [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <div class="bolt-row">
        <div class="bolt-col-12">
            <label class="font-weight-bold">Preferred email address</label>
        </div>
        <div class="bolt-col-12">
            <input aria-readonly="true" readonly="" [value]="this.email.value" class="input-form-control-plain-text" id="email-address" type="text">
        </div>
    </div>
</fieldset>