import { NgModule } from '@angular/core';
import { TimeoutModalService } from './time-out/time-out.service';
import { SessionService } from './session.service';
import { UrlUtil } from '../url-util/url-util.service';
import { SessionNavigation } from './navigation/navigation.service';
import { CredentialManagerService } from './identifiers/credential-management.service';
import { SessionComponentsModule } from './session-components.module';
import { QuickPayFlowService } from '../../quick-pay/shared/services/quick-pay-flow/quick-pay-flow.service';

@NgModule({
    imports: [
        SessionComponentsModule
    ],

    providers: [
        TimeoutModalService,
        SessionService,
        SessionNavigation,
        UrlUtil,
        CredentialManagerService,
        QuickPayFlowService
    ]
})
export class SessionServicesModule { }
