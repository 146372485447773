import { ECIM as UnmappedECIM } from '@nationwide/dgs-angular-billing-common';
import { Agreement } from '@nationwide/dgs-angular-billing-common/api-response-types/portfolio-business-service';
import { Injectable } from '@angular/core';
import { PayBill } from '../../pay-bill/pay-bill-landing/pay-bill-landing-adpater.model';

@Injectable()
export class EcimAdapter {
    canSelectPaperless = true;
    getCanSelectPaperlessBilling(agreementRolePreferenceResponse: UnmappedECIM.EnterpriseCustomerInformationManagementResponse): boolean {
        if (!agreementRolePreferenceResponse) {
            this.canSelectPaperless = false;
        } else if (agreementRolePreferenceResponse?.contactPoints?.contactPointPreferences) {
            const election = agreementRolePreferenceResponse?.contactPoints?.contactPointPreferences?.find((preference) =>
                preference.option === 'eNotify Non-Policy Jacket Docs'
            )?.election;

            if (election?.toUpperCase() === 'CONSENT' || election?.toUpperCase() === 'REVOKE CONSENT') {
                this.canSelectPaperless = false;
            }
        }
        return this.canSelectPaperless;
    }

    getAgreementRole(
        agreementRolePreferenceResponse: UnmappedECIM.EnterpriseCustomerInformationManagementResponse,
        billingAccount: Agreement
    ): string {
        const agreementRoleIndex = 0; // #1356 Github Issue TODO change when multiple
        // roletypes scoped to be there for agreementRole when agreementType is Account

        for (const agreement of agreementRolePreferenceResponse.agreements) {
            if (agreement.agreementType && agreement.agreementReference.referenceNumber) {
                if (billingAccount.agreementNumber === agreement.agreementReference.referenceNumber && agreement.agreementType === 'Account') {
                    if (agreement.agreementRoles) {
                        return agreement.agreementRoles[agreementRoleIndex].roleType;
                    }
                }
            }
        }
    }

    hasPaperlessPreference(agreementRolePreferences: UnmappedECIM.AgreementRolePreference[]): boolean {
        if (agreementRolePreferences) {
            return agreementRolePreferences.some((preference) => preference.option === 'Individual Paperless Bill');
        }
        return false;
    }

    paperlessBillingSelected(agreementRolePreferences: UnmappedECIM.AgreementRolePreference[]): boolean {
        const paperlessBilling = agreementRolePreferences.filter((agreementRolePreference) =>
            agreementRolePreference.option === 'Individual Paperless Bill')[0];
        return !!paperlessBilling && paperlessBilling.election === 'Consent';
    }

    getAgreementRolePreferences(
        agreementRolePreferenceResponse: UnmappedECIM.EnterpriseCustomerInformationManagementResponse,
        billingAccount: Agreement
    ): UnmappedECIM.AgreementRolePreference[] {
        for (const agreement of agreementRolePreferenceResponse.agreements) {
            if (agreement?.agreementType && agreement?.agreementReference?.referenceNumber) {
                if (billingAccount.agreementNumber === agreement.agreementReference.referenceNumber &&
                    agreement.agreementType === 'Account' && agreement.agreementRoles) {
                    for (const agreementRole of agreement.agreementRoles) {
                        if (agreementRole.roleType && agreementRole.roleType.includes('Owner')) {
                            if (agreementRole.agreementRoleContactPoints) {
                                for (const agreementContactPoint of agreementRole.agreementRoleContactPoints) {
                                    if (agreementContactPoint && agreementContactPoint.roleLocationPurpose === 'Statement Delivery') {
                                        if (agreementContactPoint.agreementRolePreferences) {
                                            return agreementContactPoint.agreementRolePreferences;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    getBillingAccountHolderName(getAgreementResponse: UnmappedECIM.Agreement): string {
        let billingAccountHolderName = '';
        if (getAgreementResponse?.agreementRoles) {
            const billingOwnerAgreement = getAgreementResponse.agreementRoles.filter((agreementRole) =>
                agreementRole.roleType === 'Owner')[0];
            if (billingOwnerAgreement?.customerInRole) {
                billingAccountHolderName = billingOwnerAgreement.customerInRole.fullName;
            }
        }
        return billingAccountHolderName;
    }

    getRoleIdentifer(getAgreementResponse: UnmappedECIM.Agreement): string {
        let roleIdentifier = '';
        if (getAgreementResponse?.agreementRoles) {
            const billingOwnerAgreement = getAgreementResponse.agreementRoles.filter((agreementRole) =>
                agreementRole.roleType === 'Owner')[0];
            if (billingOwnerAgreement?.resourceURI) {
                roleIdentifier = billingOwnerAgreement.resourceURI.split('agreementroles/').pop();
            }
        }
        return roleIdentifier;
    }

    getUpdatedEmailAddress(internetRegistrationResponse:
        UnmappedECIM.EnterpriseCustomerInformationManagementResponse): string {
        return internetRegistrationResponse?.contactPoints?.electronicAddress?.emailAddress || PayBill.NULL;
    }
}
