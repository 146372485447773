import { PaymentItem } from '../../billing/payment-preferences/shared/models/payment-item.model';
import { PaymentAmountOption, PaymentSelection } from '../shared/payment-amount-form-controls/payment-amount.component';
import { LastPayment } from '../shared/your-last-payment/your-last-payment.component';
import { PayMethodList } from '../../shared/payments/model/pay-method-list.model';
import { PaymentHistoryItem } from '../../billing/bills-and-payments/payment-history/payment-history-item.model';
import { CardType } from '../../shared/payments/card-utils/card-type.enum';
import { MoneyProcessingRequestEnum as MPEnum } from '@nationwide/dgs-angular-billing-common';

export class PayBillLanding {
    private _payMethods: PayMethodList;

    // eslint-disable-next-line max-params
    constructor(
        readonly amountDueFromBilling: string,
        readonly lastPayment: LastPayment,
        readonly paymentAmountOptions: PaymentAmountOption[],
        payMethods: PaymentItem[],
        readonly canSelectPaperlessBilling: boolean,
        readonly isReinstatementEligible: boolean,
        readonly isEligibleForEasyPay: boolean,
        readonly isWarnedPolicy: boolean,
        readonly paymentDueDate: string,
        readonly maxPaymentDateAllowed: string,
        readonly emailAddress: string,
        readonly state: string,
        readonly city: string,
        readonly streetLine1: string,
        readonly displayPaymentDateSection: boolean,
        readonly sourceSystem: string,
        readonly paymentStatus: string,
        readonly agreementStatus: string,
        readonly partialPaymentHistory: PaymentHistoryItem[],
        readonly productName: MPEnum.ProductName,
        readonly statementAmount: number,
        readonly fullPayAmount: number,
        readonly billingAccountHolderName: string,
        readonly producerNumbers?: string[],
        readonly isPaymentCapExceeded?: boolean,
        readonly amountDue?: string,
        readonly hasCareMatters?: boolean,
        readonly isOipaPolicy?: boolean,
        readonly oipaPolicyPaymentCapAcheived?: boolean,
        readonly careMattersPolicyPaymentCapAcheived?: boolean,
        readonly nonOipaPolicyPaymentCapAcheived?: boolean,
        readonly nextDueDate?: Date,
        readonly nextPaymentAmount?: string,
        readonly reinstatePolicyNumber?: string,
        readonly isAutomaticPaymentSuspended?: boolean,
        readonly billingNextDueDate?: Date,
        readonly hasScheduledPaymentIndicator?: boolean,
        readonly scheduledPaymentAmount?: string,
        readonly scheduledPaymentDate?: Date,
        // Additional fields for Policy Level Overpayment Allocation
        readonly singlePolicyProductName?: MPEnum.ProductName,
        readonly billingAccountNumber?: string,
        readonly policyNumber?: string,
        readonly isEligibleForOverpaymentAllocation?: boolean,
        readonly roleIdentifier?: string,
        readonly lastDayToPayBeforeCancelled?: string,
        readonly cancellationDate?: string,
        readonly isShowSuspendAutomaticPaymentMessage?: boolean,
        readonly canManage?: boolean,
        readonly isCollectionPayment?: boolean,
        readonly canViewBilling?: boolean,
        readonly collectionPolicyNumber?: string,
        readonly automaticPayments?: boolean,
        readonly paymentPlan?: string,
        readonly agreementType?: string,
        readonly installmentFee?: number,
        readonly minimumAmountDue?: string,
        readonly isSingleActiveAutoPolicy?: boolean

    ) {
        this._payMethods = new PayMethodList(payMethods);
    }

    get defaultMethod(): PaymentItem {
        return this._payMethods.defaultMethod;
    }

    get defaultBankAccountMethod(): PaymentItem {
        return this._payMethods.defaultBankAccountMethod;
    }

    get payMethods(): PaymentItem[] {
        return this._payMethods.asList;
    }

    get bankAccountPayMethods(): PaymentItem[] {
        return this._payMethods.asBankAccountList;
    }

    get bankCardPayMethods(): PaymentItem[] {
        return this._payMethods.asBankCardList;
    }

    get hasPayMethods(): boolean {
        return !!(this._payMethods.asList && this._payMethods.asList.length);
    }

    get hasSavedPayMethods(): boolean {
        return this._payMethods.hasSavedPayMethods;
    }

    get defaultPaymentOption(): PaymentAmountOption {
        let defaultPaymentOption: PaymentAmountOption;

        if (this.paymentAmountOptions.length > 2) {
            defaultPaymentOption = this.minimumPaymentOption || this.paymentAmountOptions[0];
        } else {
            defaultPaymentOption = this.paymentAmountOptions[0];
        }

        return defaultPaymentOption;
    }

    get minimumPaymentOption(): PaymentAmountOption {
        return this.paymentAmountOptions.filter((option) => option.id === 'minimum-payment')[0];
    }

    isDuplicatePaymentAmountorMethod(paymentMethod: PaymentItem, selectedAmount: PaymentSelection): boolean {
        const currentPaymentMethod = paymentMethod.isBankCard ? 'Payment - Debit / Credit Card' : 'Payment - Electronic Funds Transfer (EFT)';
        let currentPaymentType = paymentMethod.methodType === 'Checking' || paymentMethod.methodType === 'Savings' ?
            'PAYMENT' : paymentMethod.methodType;

        switch (currentPaymentType) {
            case CardType.MASTERCARD: {
                currentPaymentType = 'MASTERCARD';

                break;
            }
            case CardType.AMERICAN_EXPRESS: {
                currentPaymentType = 'AMERICAN EXPRESS';

                break;
            }
            case CardType.DISCOVER: {
                currentPaymentType = 'DISCOVER';

                break;
            }
            case CardType.VISA: {
                currentPaymentType = 'VISA';

                break;
            }
            // No default
        }

        if (this.partialPaymentHistory && this.partialPaymentHistory.length) {
            return this.partialPaymentHistory
                .some((partialPayment) => partialPayment.amount.split('$')[1] === (selectedAmount ?
                    selectedAmount.paymentAmount : 0) ||
                    currentPaymentMethod === partialPayment.method && currentPaymentType === partialPayment.paymentType);
        }
        return false;
    }
}
