<ng-container *ngIf="updatingPaymentMethod" [formGroup]="formGroup">
    <p>Update account</p>

    <app-bank-account-type-form-control formGroupName="accountTypeGroup" [readonly]="true">
    </app-bank-account-type-form-control>

    <app-name-field-set formGroupName="nameGroup" class="bolt-space-bottom-xl" header="Account holder's name and address">
    </app-name-field-set>

    <div class="bolt-space-bottom-2xl">
        <app-address-form-control formGroupName="addressGroup">
        </app-address-form-control>
    </div>

    <app-bank-account-number-form-controls formGroupName="accountNumberGroup" [readonly]="true">
    </app-bank-account-number-form-controls>
</ng-container>

<ng-container *ngIf="!defaultPaymentMethodIsSelected" [formGroup]="formGroup">
    <bolt-checkbox ngDefaultControl id="save-as-default-pay-method" formControlName="makeDefaultPaymentMethod" arialabel="Make this your default payment method">
        Make this your default payment method
        <bolt-contextual-help>
            <p>This will be your preferred method of payment, even though you can add up to 10 credit card accounts and up to 10 bank accounts. You can also delete payment methods online anytime.</p>
        </bolt-contextual-help>
    </bolt-checkbox>
</ng-container>