import { AbandonmentSurvey } from '../service/abandonment-survey.service';
import { SessionService } from '../session/session.service';

export class SessionClearingComponent {
    session: SessionService;
    abandonmentSurvey: AbandonmentSurvey;

    onLinkClick(linkMap: { url: string }): void {
        if (document.URL.includes('pay-bill' || 'quickpay')) {
            this.abandonmentSurvey.triggerSurvey();
        } else if (isExternal(linkMap.url)) {
            this.session.logOutUser();
        }
    }
}

export function isExternal(url: string): boolean {
    return !!url && isAbsolute(url) && !url.includes(window.location.host);
}

// BELOW CODE TAKEN FROM https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
function isAbsolute(url: string): boolean {
    return /^https?:\/\//i.test(url);
}
