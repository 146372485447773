import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionService } from '../session/session.service';
import { AuthenticatedHeader, UnauthenticatedHeader } from '@nationwide/internet-servicing-angular-components';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { PaymentFlowType } from '../../pay-bill/shared/payment-flow-type.service';

@Injectable()
export class HeaderService {
    constructor(
        private contentfulService: ContentfulMapperService,
        private sessionService: SessionService,
        private paymentFlowType: PaymentFlowType
    ) { }

    fetchHeaderData(params: HeaderParams, setter: (header: AuthenticatedHeader) => void): void {
        const contentfulResponse = this.contentfulService.getContentFulHeader();
        if (params.isCyberLife) {
            setter({ ...this.headerWithoutNationwideLogoForCyberLife(params), ...this.emptyNationwideLogo });
            contentfulResponse.subscribe((contentfulData) => {
                setter({
                    ...this.headerWithoutNationwideLogoForCyberLife(params),
                    ...this.extractNationwideLogo(contentfulData.header)
                });
            });
        } else if (params.isNonOwner) {
            setter({ ...this.headerWithoutNationwideLogoForNonOwner(params), ...this.emptyNationwideLogo });
            contentfulResponse.subscribe((contentfulData) => {
                setter({
                    ...this.headerWithoutNationwideLogoForNonOwner(params),
                    ...this.extractNationwideLogo(contentfulData.header)
                });
            });
        } else if (params.isBillingTimelineNotEligible) {
            setter({ ...this.headerWithoutNationwideLogoForLife(params), ...this.emptyNationwideLogo });
            contentfulResponse.subscribe((contentfulData) => {
                setter({
                    ...this.headerWithoutNationwideLogoForLife(params),
                    ...this.extractNationwideLogo(contentfulData.header)
                });
            });
        } else {
            setter({ ...this.headerWithoutNationwideLogo(params), ...this.emptyNationwideLogo });
            contentfulResponse.subscribe((contentfulData) => {
                setter({
                    ...this.headerWithoutNationwideLogo(params),
                    ...this.extractNationwideLogo(contentfulData.header)
                });
            });
        }
    }

    fetchMAPHeaderData(params: HeaderParams, setter: (header: AuthenticatedHeader) => void): void {
        setter({ ...this.headerWithoutNationwideLogo(params), ...this.nationwideLabsLogo });
    }

    fetchUnauthenticatedHeader(setter: (header: UnauthenticatedHeader) => void): void {
        const contentfulResponse = this.contentfulService.getContentFulHeader();
        setter({ ...this.unauthenticatedHeaderWithoutNationwideLogo, ...this.emptyNationwideLogo });
        contentfulResponse.subscribe((contentfulData) => {
            setter({
                ...this.unauthenticatedHeaderWithoutNationwideLogo(),
                ...this.extractNationwideLogo(contentfulData.header)
            });
        });
    }

    private headerWithoutNationwideLogo(params: HeaderParams): any {
        return {
            username: params.username,
            lastLogin: params.lastLogin,
            personalInformation: environment.NW_HEADER.personal_information,
            preferences: environment.NW_HEADER.preferences,
            manageAuthorizedUsers: environment.NW_HEADER.manageAuthorizedUsers,
            contactUs: environment.NW_HEADER.contactUs,
            allAccountsUrl: environment.NW_HEADER.allAccounts,
            logout: environment.NW_HEADER.logout,
            navigationLinks: environment.NW_HEADER.navigationLinks({
                accountNumber: this.sessionService.billingAccountNumber,
                sessionId: this.sessionService.a2aSessionId
            })
        };
    }

    private headerWithoutNationwideLogoForLife(params: HeaderParams): any {
        return {
            username: params.username,
            lastLogin: params.lastLogin,
            personalInformation: environment.NW_HEADER.personal_information,
            preferences: environment.NW_HEADER.preferences,
            manageAuthorizedUsers: environment.NW_HEADER.manageAuthorizedUsers,
            contactUs: environment.NW_HEADER.contactUs,
            allAccountsUrl: environment.NW_HEADER.allAccounts,
            logout: environment.NW_HEADER.logout,
            navigationLinks: environment.NW_NAV_LIFE_LINK.navigationLinksForLife({
                accountNumber: this.sessionService.billingAccountNumber,
                sessionId: this.sessionService.a2aSessionId
            })
        };
    }

    private headerWithoutNationwideLogoForCyberLife(params: HeaderParams): any {
        return {
            username: params.username,
            lastLogin: params.lastLogin,
            personalInformation: environment.NW_HEADER.personal_information,
            preferences: environment.NW_HEADER.preferences,
            manageAuthorizedUsers: environment.NW_HEADER.manageAuthorizedUsers,
            contactUs: environment.NW_HEADER.contactUs,
            allAccountsUrl: environment.NW_HEADER.allAccounts,
            logout: environment.NW_HEADER.logout,
            navigationLinks: environment.NW_NAV_LIFE_LINK.navigationLinksForCyberLife({
                accountNumber: this.sessionService.policyNumber,
                sessionId: this.sessionService.a2aSessionId
            })
        };
    }

    private headerWithoutNationwideLogoForNonOwner(params: HeaderParams): any {
        return {
            username: params.username,
            lastLogin: params.lastLogin,
            personalInformation: environment.NW_HEADER.personal_information,
            preferences: environment.NW_HEADER.preferences,
            manageAuthorizedUsers: environment.NW_HEADER.manageAuthorizedUsers,
            contactUs: environment.NW_HEADER.contactUs,
            allAccountsUrl: environment.NW_HEADER.allAccounts,
            logout: environment.NW_HEADER.logout,
            navigationLinks: environment.NW_HEADER.navigationLinksNonOwner
        };
    }

    private unauthenticatedHeaderWithoutNationwideLogo(): any {
        return { contactUs: environment.NW_HEADER.unauthenticatedContactUs };
    }

    extractNationwideLogo(contentfulData: any): any {
        if (!contentfulData || !contentfulData.logo?.fields) {
            return this.backupNationwideLogo;
        }

        const nationwideLogoHeaderData = contentfulData.logo.fields;
        return {
            nationwideLogo: {
                title: nationwideLogoHeaderData.Name,
                url: this.isQuickPay ? environment.NATIONWIDE_HOMEPAGE : environment.CAM_HOME,
                altText: nationwideLogoHeaderData.Name,
                source: nationwideLogoHeaderData.URL.fields.file.url
            }
        };
    }

    private get nationwideLabsLogo(): any {
        return {
            nationwideLogo: {
                url: this.isQuickPay ? environment.NATIONWIDE_HOMEPAGE : environment.CAM_HOME,
                title: 'Nationwide Labs Logo',
                altText: 'Nationwide Labs Logo',
                source: '/assets/images/Nationwide-labs-logo.png'
            }
        };
    }

    private get emptyNationwideLogo(): any {
        return {
            nationwideLogo: {
                url: '',
                title: '',
                altText: '',
                source: ''
            }
        };
    }

    get isQuickPay(): boolean {
        return this.paymentFlowType.isQuickpay;
    }

    private get backupNationwideLogo(): any {
        return {
            nationwideLogo: {
                url: this.isQuickPay ? environment.NATIONWIDE_HOMEPAGE : environment.CAM_HOME,
                title: 'Nationwide Labs Logo',
                altText: 'Nationwide Labs Logo',
                source: '/assets/images/Nationwide-labs-logo.png'
            }
        };
    }
}

export interface HeaderParams {
    username: string;
    lastLogin: string;
    isBillingTimelineNotEligible: boolean;
    isCyberLife: boolean;
    isNonOwner?: boolean;
}
