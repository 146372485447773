import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { BillingTab, BillingTabIcons } from './billing-tab.model';
@Component({
    selector: 'app-billing-tabs',
    templateUrl: './billing-tabs.component.html',
    styleUrls: ['./billing-tabs.component.scss']
})
export class BillingTabsComponent implements OnChanges {
    @Input() selected: BillingTab;

    @Input() tabs: BillingTab[];

    @Output() tabClick: EventEmitter<BillingTab> = new EventEmitter<BillingTab>();

    currentTab: BillingTab;

    ngOnChanges(): void {
        if (this.tabs) {
            this.deactivateCurrentTab();
            this.tabs.forEach((tab) => {
                if (this.selected && tab.iconType !== this.selected.iconType) {
                    tab.active = false;
                }
            });
            if (this.selected) { // if override present
                this.currentTab = this.selected;
            } else { // default first tab
                this.currentTab = this.tabs[0];
            }

            this.activateCurrentTab();
        }
    }

    tabClicked(clickedTab: BillingTab): void {
        this.deactivateCurrentTab();

        this.currentTab = clickedTab;
        this.activateCurrentTab();

        this.tabClick.emit(this.currentTab);
    }

    deactivateCurrentTab(): void {
        if (this.currentTab) {
            this.currentTab.active = false;
        }
    }

    activateCurrentTab(): void {
        if (this.currentTab) {
            this.currentTab.active = true;
        }
    }

    showDollarSignIcon(tab: BillingTab): boolean {
        return tab.iconType === BillingTabIcons.DOLLAR_SIGN;
    }

    showGearsIcon(tab: BillingTab): boolean {
        return tab.iconType === BillingTabIcons.GEARS;
    }

    showQuestionMarkIcon(tab: BillingTab): boolean {
        return tab.iconType === BillingTabIcons.QUESTION_MARK;
    }

    showBillIcon(tab: BillingTab): boolean {
        return tab.iconType === BillingTabIcons.BILL;
    }

    replaceWhiteSpaceWithDash(text: string): string {
        let result: string;
        if (text) {
            result = text.replace(/\s/g, '-');
        }
        return result;
    }
}
