import { InjectionToken } from '@angular/core';

export interface TimeOutConfig {
    pollIntervalMilliseconds: number;
    sessionLogOutUrl: string;
    logOutUrl: string;
    logInUrl: string;
    inactivityThresholdMilliseconds: number;
    timeToTimeoutWarningMilliseconds: number;
    excludedPaths: string[];
    accessTokenKey: string;
}

export const timeOutConfigToken = new InjectionToken<TimeOutConfig>('timeoutConfig');
