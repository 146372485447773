import { PipeTransform, Injectable } from '@angular/core';

@Injectable()
export class NamePipe implements PipeTransform {
    transform(name: string): string {
        const firstLetterUppercase = name.slice(0, 1).toUpperCase();
        const remainingLetters = name.slice(1);
        return `${firstLetterUppercase}${remainingLetters}`;
    }
}
