/* eslint-disable no-extra-parens */

import { Injectable } from '@angular/core';
import { ContentfulISBData, ContentfulISBDataItem, ContentfulRawDataItem } from '@nationwide/dgs-angular-billing-common';

@Injectable({
    providedIn: 'root'
})
export class GlobalNavWrapperContent {
    constructor() { }

    mapBillingNavigationResponse(billingNavResponse: ContentfulISBData): ContentfulRawDataItem {
        return {
            id: billingNavResponse.billingNavigation.id,
            Name: billingNavResponse.billingNavigation.Name,
            Components: this.mapBillingNavigationComponents(billingNavResponse.billingNavigation.icons)

        };
    }

    mapFeatureToggle(): any {
        return {};
    }

    mapPolicyNavigationResponse(policyNavigationResponse: ContentfulISBData): ContentfulRawDataItem {
        return {
            id: policyNavigationResponse.policynavigation.id,
            Name: policyNavigationResponse.policynavigation.Name,
            Components: this.mapPolicyNavigationComponents(policyNavigationResponse.policynavigation.Subfolder)
        };
    }

    private mapPolicyNavigationComponents(policyNavigationResponse: ContentfulISBDataItem): ContentfulRawDataItem {
        return this.flattenData(policyNavigationResponse.map((pRep) => (
            {
                id: pRep.fields.id,
                Name: pRep.fields.Name,
                Images: this.flattenData(pRep.fields.Image.map((imageResp) => {
                    const { id, Name, AltText, Height, Width, URL } = imageResp?.fields;
                    return {
                        id, Name, AltText,
                        ...(Height && { Height }),
                        ...(Width && { Width }),
                        ...(URL?.fields) && { URL: URL?.fields?.file?.url }
                    };
                }))
            })));
    }

    private mapBillingNavigationComponents(icons: ContentfulRawDataItem): ContentfulRawDataItem {
        return this.flattenData(icons.map((iconAttributes) => {
            const { id, Name, AltText, Width, Height, URL } = iconAttributes.fields;
            return {
                id,
                Name,
                Images: {
                    icon: {
                        id: 'icon',
                        Name: AltText,
                        AltText,
                        Width,
                        Height,
                        URL: URL.fields.file.url
                    }
                }
            };
        }));
    }

    private flattenData(data: ContentfulRawDataItem): any {
        const flatData = {};
        data.forEach((element) => {
            flatData[element.id || element.ID] = element;
        });
        return flatData;
    }
}
