import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {
    PersonalBillingRequestParams, PersonalBilling as Unmapped
} from '@nationwide/dgs-angular-billing-common';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { environment } from '../../../../../../../environments/environment';
import { EditPaymentPlanService } from './edit-payment-plan.service';
import { PaymentPreferencesApiService } from '../../../../../payment-preferences/shared/services/payment-preferences-api/payment-preferences-api.service';
import { of, timeout, catchError, tap } from 'rxjs';
import { EditPaymentPlan } from '../../../../../payment-preferences/shared/services/payment-preferences-api/payment-preferences-api-calls.model';
import { EasyPayAdapter } from '../../../../../payment-preferences/shared/adapters/easy-pay.adpater';

import { SessionService } from '../../../../../../shared/session/session.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { PayPlanAdapter } from '../../../automatic-payments/pay-plan/shared/pay-plan.adapter';
import { PayPlanFlow } from '../../../automatic-payments/pay-plan/shared/pay-plan-flow.service';
import { PAYMENT_PLAN } from '../../../../../../shared/constants/common.constants';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { PageName } from '../../../../../../shared/page-name.enum';

@Component({
    selector: 'edit-payment-plan-landing',
    templateUrl: './edit-payment-plan-landing.component.html',
    styleUrls: ['./edit-payment-plan-landing.component.scss']
})
export class EditPaymentPlanLandingComponent extends BasePageComponent implements OnInit {
    payCardType = 'month';
    pageName = PageName.EDIT_PAYMENT_PLAN_LANDING;
    monthPayDescription = PAYMENT_PLAN.MONTH_PAY_DESC;
    fullPayDescription = PAYMENT_PLAN.FULL_PAY_DESC;
    agreementText = PAYMENT_PLAN.REMAINING_BAL_DUE;
    infoText = PAYMENT_PLAN.INFO_DESC;
    disAgreedError = false;
    isAgreed = false;
    billingAccountNumber = '';
    policyNumber = '';
    agreementType: string;
    amountDue: number;
    amountDueRCA: number;
    fullPayAmountRCA: number;
    premium: number;
    paymentMethod = '';
    payplan = 'Monthly';
    apiCallInProgress = false;
    showEmptyBillResponse = false;
    // eslint-disable-next-line max-params
    constructor(
        protected router: Router,
        private editPaymentPlanService: EditPaymentPlanService,
        private readonly paymentPreferencesApiService: PaymentPreferencesApiService,
        private easyPayAdapter: EasyPayAdapter,
        private payPlanAdapter: PayPlanAdapter,
        private payPlanFlow: PayPlanFlow,
        protected session: SessionService,
        protected eventService: BillingEventService,
        @Inject('logger') private logger: LoggerService) {
        super(session, router);
    }

    ngOnInit(): void {
        this.onMonthPaySelected();
        this.session.currentPage = this.pageName;
        const personalBillingRequestParams = this.getPersonalBillingRequestParams();
        this.paymentPreferencesApiService.editPaymentPlanLanding(personalBillingRequestParams).pipe(
            timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences),
            tap((response) => {
                this.logManager(response);
            }),
            catchError((error) => {
                this.logger.error('API ERROR: EDIT PAYMENT PLAN LANDING', error);
                return of({ retrieveCustomerAgreement: null, bills: null, relatedPolicies: null });
            })
        ).subscribe((responses: EditPaymentPlan) => {
            const { retrieveCustomerAgreement, bills, relatedPolicies } = responses;

            const billingAgreement = this.easyPayAdapter.getBillingAgreementFromRCA(this.easyPayAdapter.getAgreementsFromRCA(retrieveCustomerAgreement));
            this.billingAccountNumber = billingAgreement?.agreementNumber;
            const autoPolicy = this.easyPayAdapter.isAgreementAutoPolicy(this.easyPayAdapter.getNonCancelledPolicyAgreements(this.easyPayAdapter.getAgreementsFromRCA(retrieveCustomerAgreement)));
            const billingAccount = this.easyPayAdapter.isAgreementBilling(this.easyPayAdapter.getAgreementsFromRCA(retrieveCustomerAgreement), this.billingAccountNumber);
            this.policyNumber = autoPolicy?.displayAgreementNumber;
            const payMethods = billingAccount?.billingAccountDetail?.paymentMethod;
            const scheduledPaymentFlag = payMethods === 'CPPRP' || payMethods === 'CPPEP';
            if (billingAccount?.billingAccountDetail?.billingDetail?.amountDue) {
                this.amountDueRCA = Number(billingAccount.billingAccountDetail.billingDetail.amountDue);
            }
            this.fullPayAmountRCA = Number(billingAccount?.billingAccountDetail?.billingDetail?.fullPayAmount);
            const isDirectBill = this.easyPayAdapter.isDirectBill(billingAgreement);
            const canPayNow = this.easyPayAdapter.canPay(billingAgreement?.roleInAgreement?.permission);
            const isAutoPayEnrolled = this.easyPayAdapter.isAutoPayEnrolled(billingAgreement);
            this.agreementType = autoPolicy?.agreementLabel;
            this.paymentMethod = billingAgreement?.billingAccountDetail.paymentMethod;
            const relatedPolicy: Unmapped.Policy = this.payPlanAdapter.getRelatedPolicy(relatedPolicies, this.policyNumber);
            this.premium = this.payPlanAdapter.getPremiumFromPolicy(responses?.relatedPolicies);
            const termExpirationDate = relatedPolicy?.termExpirationDate;
            let fullPayAmount: number;
            let currentDueDate: Date;
            let nextDueDate: Date;
            let isCurrentBillPaid: boolean;
            this.showEmptyBillResponse = this.payPlanAdapter.isEmptyBillResponse(bills);
            this.paymentMethod = billingAgreement?.billingAccountDetail.paymentMethod;
            if (!this.showEmptyBillResponse) {
                const currentBill: Unmapped.Bill = this.payPlanAdapter.getCurrentBill(bills);
                const firstFutureBill: Unmapped.Bill = this.payPlanAdapter.getFirstFutureBill(bills);
                if (currentBill) {
                    this.amountDue = currentBill.minimumDue;
                    currentDueDate = currentBill.dueDate;
                    isCurrentBillPaid = this.payPlanAdapter.isCurrentBillPaid(currentBill);
                } else if (firstFutureBill) {
                    this.amountDue = firstFutureBill.minimumDue;
                }
                if (firstFutureBill) {
                    nextDueDate = firstFutureBill.dueDate;
                    fullPayAmount = firstFutureBill.fullPayAmount;
                }
            }
            this.payPlanFlow.save({
                minimumDueAmount: this.amountDue, fullPayAmount,
                premiumAmount: this.premium,
                billingAccountNumber: this.billingAccountNumber,
                policyNumber: this.policyNumber,
                policyType: this.agreementType,
                termExpirationDate,
                currentDueDate,
                nextDueDate,
                isDirectBill,
                isAutoPayEnrolled,
                canPayNow,
                isCurrentBillPaid,
                isEmptyBillResponse: this.showEmptyBillResponse
            });

            this.agreementText = this.editPaymentPlanService.getAgreementText(scheduledPaymentFlag, this.fullPayAmountRCA);
        });
    }

    private logManager(response: EditPaymentPlan): void {
        if (!response.retrieveCustomerAgreement) {
            this.logger.error('Edit PaymentPlan - RCA call error');
        }

        if (!response.bills || !response.relatedPolicies) {
            this.logger.error('Edit PaymentPlan - Errors while invoke the billing servicies');
        }
    }

    currentRouteParams(): string {
        return this.router.url.split('?')[1];
    }

    onMonthPaySelected(): void {
        this.payCardType = 'month';
    }

    onFullPaySelected(): void {
        this.payCardType = 'full';
    }

    onSubmit(): void {
        const submittedDate = new Date();
        this.disAgreedError = !this.isAgreed;
        if (this.isAgreed) {
            this.apiCallInProgress = true;
            this.editPaymentPlanService.patchPaymentPlan('FULL PAYMENT PLAN')
                .subscribe((response) => {
                    this.apiCallInProgress = false;
                    this.logger.info(`Response${response}`);
                    this.payPlanFlow.savePayPlanResponse(true, 'Full pay plan', submittedDate);
                    this.submitEbiEvent({ responseCode: response, isSuccess: true });
                    this.router.navigateByUrl(
                        environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.PAY_PLAN.EDIT.CONFIRMATION(this.currentRouteParams())
                    );
                },
                    (error: HttpErrorResponse) => {
                        this.apiCallInProgress = false;
                        this.logger.error(
                            'Update PayPlan Frequency',
                            error
                        );
                        this.payPlanFlow.savePayPlanResponse(false, 'Full pay plan', submittedDate);
                        this.submitEbiEvent({ responseCode: error.status, isSuccess: false });
                        this.router.navigateByUrl(
                            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.PAY_PLAN.EDIT.CONFIRMATION(this.currentRouteParams())
                        );
                    }
                );
        }
    }

    onAgreeChanged(event): void {
        this.isAgreed = event.target.checked;
    }

    cancelEditPaymentPlan(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(
                this.currentRouteParams()
            )
        );
    }

    private getPersonalBillingRequestParams(): PersonalBillingRequestParams {
        const personalBillingRequestParams: PersonalBillingRequestParams = {
            accountNumber: this.getBillingAccountNumber(), accessToken: this.session.accessToken,
            isZeroAmountDueServiceEnable: false
        };

        return personalBillingRequestParams;
    }

    getBillingAccountNumber(): string {
        return sessionStorage.getItem('BILLING_ACCOUNT_NUMBER');
    }

    get accessToken(): string {
        const tokenStorage: string = environment.OAUTH_CONFIG.tokenStorage;
        const accessToken = sessionStorage.getItem(tokenStorage);
        return accessToken ? accessToken.split(',')[0] : '';
    }

    submitEbiEvent(submitPayPlanResponse: { responseCode: number; isSuccess: boolean }): void {
        let methodType = '';
        if (this.paymentMethod === 'CPPDB') {
            methodType = 'Direct';
        } else if (this.paymentMethod === 'CPPRP') {
            methodType = 'RBC';
        } else if (this.paymentMethod === 'CPPEP') {
            methodType = 'EFT';
        }

        if (this.payCardType === 'full') {
            this.eventService.handleEvent(EBIEventType.ON_SUBMIT_OF_FULLPAY_PAYMENT_PLAN, {
                methodType,
                payplan: this.payplan,
                responseCode: submitPayPlanResponse.responseCode.toString(),
                responseStatus: submitPayPlanResponse.isSuccess ? 'SUCCESS' : 'ERROR'
            });
        }
    }
}
