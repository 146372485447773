import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-payment-sub-header',
    templateUrl: './payment-sub-header.component.html',
    styleUrls: ['./payment-sub-header.component.scss']
})
export class PaymentSubHeaderComponent {
    @Input() aside: string;
}
