import { CardType } from './card-type.enum';
import { cardConstants } from './card-constants';
export const cardUtils = {

    parseType(num: string): CardType {
        return digitsOnly(num) && passesLuhnCheck(num) ?
            parsePrefix(num) :
            CardType.INVALID;
    }

};

function digitsOnly(num: string): boolean {
    return !!num && /^[\d]+$/.test(num);
}

function parsePrefix(num: string): CardType {
    let typeByPrefix: CardType = CardType.INVALID;

    for (const card of Object.keys(cardConstants)) {
        const { type, patterns } = cardConstants[card];
        if (patterns.some((pattern) => pattern.test(num))) {
            typeByPrefix = type;
            break;
        }
    }

    return typeByPrefix;
}

function passesLuhnCheck(cardNumber: string): boolean {
    // COPIED FROM ICP (FUNCTION NAMED luhn_check)

    // Strip any non-digits (useful for credit card numbers with spaces and hyphens)
    const cleanNumber = cardNumber.replace(/\D/g, '');

    // Set the string length and parity
    const numberLength = cleanNumber.length;
    const parity = numberLength % 2;

    // Loop through each digit and do the maths
    let total = 0;
    for (let index = 0; index < numberLength; index++) {
        let digit = parseInt(cleanNumber.charAt(index), 10);
        // Multiply alternate digits by two
        if (index % 2 === parity) {
            digit *= 2;
            // If the sum is two digits, add them together (in effect)
            const MAX_DIGIT = 9;
            if (digit > MAX_DIGIT) {
                digit -= MAX_DIGIT;
            }
        }
        // Total up the digits
        total += digit;
    }

    // If the total mod 10 equals 0, the number is valid
    return total % 10 === 0;
}
