import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';

export type ControlNames = 'accountType';

@Component({
    selector: 'app-bank-account-type-form-control',
    templateUrl: './bank-account-type-form-control.component.html',
    styleUrls: [
        './bank-account-type-form-control.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class BankAccountTypeFormControlComponent extends BaseFormGroupComponent<ControlNames> implements OnInit, OnChanges {
    @Input() readonly: boolean;
    @Input() plaidAccountType: string;
    @Input() plaidResponse: boolean;
    @Input() accountTypeLabel: string;
    @Input() isHorizontal: boolean;

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }
    ngOnInit(): void {
        if (this.plaidAccountType) {
            this.formGroup['controls'].accountType.patchValue(this.plaidAccountType);
        }

        this.accountTypeLabel = this.accountTypeLabel || 'Select an account type';
        if (typeof this.isHorizontal === 'boolean') {
            if (this.isHorizontal) {
                this.isHorizontal = true;
            } else {
                this.isHorizontal = null;
            }
        } else {
            this.isHorizontal = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.plaidAccountType) {
            this.formGroup['controls'].accountType.patchValue(changes.plaidAccountType.currentValue);
        }
    }

    get accountType(): AbstractControl {
        return this.getControl('accountType');
    }

    get accountTypeErrorDisplayable(): boolean {
        return this.errorsDisplayable('accountType');
    }
}
