import { QUICK_PAY_CONSTANTS } from '../quick-pay.constants';
import { QuickPayAgreement } from '@nationwide/dgs-angular-billing-common/api-response-types/portfolio-business-service';
import { MoneyProcessingRequestEnum } from '@nationwide/dgs-angular-billing-common';

export class QuickPayResponseModel {
    responseCode: number;
    message: string;
    firstName: string;
    lastName: string;
    installmentFee: number;
    billingAccountNumber: string;
    billingAccountStatus: string;
    billingAccountSystem: string;
    policyNumber: string;
    fullPayAmount: number;
    installmentAmount: number;
    dueDate: string;
    paymentPlan: string;
    system: string;
    lineOfBusiness: string;
    guid: string;
    emailAddress: string;
    ecn: string;
    partyId: string;
    registrationStatus: string;
    state: string;
    cancellationDate: string;
    paymentDueDate: string;
    lifePolicy: boolean;
    oipaPolicy: boolean;
    emailAdress: string;
    zipCode: string;
    productType: string;
    agreementType: string;
    paymentMethod: string;
    billingAgreement: QuickPayAgreement;
    accountLevelIndicator: string;
    productNameForPayments: MoneyProcessingRequestEnum.ProductName;
    policiesOnBillingAgreement: string[];
    automaticPaymentSuspended: boolean;
    isCollectionStatusPolicy: boolean;
    status: string;
    pastDue: string;

    constructor(previousValues?: QuickPayResponseModel) {
        if (previousValues) {
            this.responseCode = previousValues.responseCode;
            this.message = previousValues.message;
            this.firstName = previousValues.firstName;
            this.lastName = previousValues.lastName;
            this.installmentFee = previousValues.installmentFee;
            this.billingAccountNumber = previousValues.billingAccountNumber;
            this.billingAccountStatus = previousValues.billingAccountStatus;
            this.policyNumber = previousValues.policyNumber;
            this.fullPayAmount = previousValues.fullPayAmount;
            this.installmentAmount = previousValues.installmentAmount;
            this.dueDate = previousValues.dueDate;
            this.paymentPlan = previousValues.paymentPlan;
            this.system = previousValues.system;
            this.lineOfBusiness = previousValues.lineOfBusiness;
            this.guid = previousValues.guid;
            this.emailAddress = previousValues.emailAddress;
            this.ecn = previousValues.ecn;
            this.partyId = previousValues.partyId;
            this.registrationStatus = previousValues.registrationStatus;
            this.state = previousValues.state;
            this.cancellationDate = previousValues.cancellationDate;
            this.paymentDueDate = previousValues.paymentDueDate;
            this.lifePolicy = previousValues.lifePolicy;
            this.oipaPolicy = previousValues.oipaPolicy;
            this.emailAdress = previousValues.emailAdress;
            this.zipCode = previousValues.zipCode;
            this.productType = previousValues.productType;
            this.agreementType = previousValues.agreementType;
            this.paymentMethod = previousValues.paymentMethod;
            this.billingAgreement = previousValues.billingAgreement;
            this.accountLevelIndicator = previousValues.accountLevelIndicator;
            this.productNameForPayments = previousValues.productNameForPayments;
            this.policiesOnBillingAgreement = previousValues.policiesOnBillingAgreement;
            this.automaticPaymentSuspended = previousValues.automaticPaymentSuspended;
            this.isCollectionStatusPolicy = previousValues.isCollectionStatusPolicy;
        }
    }
    get isBillingAgreement(): boolean {
        return this.agreementType?.equalsIgnoreCase(QUICK_PAY_CONSTANTS.BILLING);
    }
}
