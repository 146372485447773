import { Injectable } from '@angular/core';
import { CCAuthorizationAgreementParams, EFTAuthorizationAgreementParams } from '../../../../../../../app/shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { PaymentItem } from '../../../../../../billing/payment-preferences/shared/models/payment-item.model';
import { DistributedDocumentsResponseModel } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/model/distributed-documents-response.model';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaperlessFlow } from '../../../../shared/models/paperless-flow.model';
import { SetupPaymentResponse } from '../review/setup-automatic-payments-review.service';

@Injectable()
export class SetupAutomaticPaymentsFlowService implements PaperlessFlow {
    setUpAutomaticPaymentsForm: PaymentFormGroup;
    billingAccountHolderName: string;
    amountDue: string;
    currentBillDueDate: Date;
    nextBillDueDate: Date;
    isEligibleForEasyPay: boolean;
    isPaperlessEligible: boolean;
    considerThisDisplayable: boolean;
    emailAddress: string;
    selectedPayMethod: PaymentItem;
    billingAccountNumber: string;
    selectedPaperless: string;
    successfullyEnrolled: boolean;
    billedStatus: boolean;
    billingStreetLine1: string;
    billingCity: string;
    billingState: string;
    distributedDocumentsResponseModel: DistributedDocumentsResponseModel;
    authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
    isLifeOnlyAccount: boolean;
    paperlessEnrollmentAttempted = false;
    savedPaperlessEsddaPrefs = false;
    enrolledInPaperless = false;
    setupPaymentResponse: SetupPaymentResponse;
    paperlessEmail: string;
    hasVULCreditCardError: boolean;
    paymentPlan: string;
    errorDetails: {
        errorMessage: string;
        errorCode: number;
    };

    save(params: Partial<SetupAutomaticPaymentsFlowService>): void {
        this.setUpAutomaticPaymentsForm = params.setUpAutomaticPaymentsForm || this.setUpAutomaticPaymentsForm;
        this.billingAccountHolderName = params.billingAccountHolderName || this.billingAccountHolderName;
        this.amountDue = params.amountDue || this.amountDue;
        this.currentBillDueDate = params.currentBillDueDate || this.currentBillDueDate;
        this.nextBillDueDate = params.nextBillDueDate || this.nextBillDueDate;
        this.isEligibleForEasyPay = params.isEligibleForEasyPay || this.isEligibleForEasyPay;
        this.isPaperlessEligible = params.isPaperlessEligible || this.isPaperlessEligible;
        this.considerThisDisplayable = params.isEligibleForEasyPay || params.isPaperlessEligible;
        this.emailAddress = params.emailAddress || this.emailAddress;
        this.selectedPayMethod = params.selectedPayMethod || this.selectedPayMethod;
        this.billingAccountNumber = params.billingAccountNumber || this.billingAccountNumber;
        this.selectedPaperless = params.selectedPaperless || this.selectedPaperless;
        // eslint-disable-next-line no-negated-condition
        this.successfullyEnrolled = params.successfullyEnrolled != null ? params.successfullyEnrolled : this.successfullyEnrolled;
        this.billedStatus = params.billedStatus || this.billedStatus;
        this.billingStreetLine1 = params.billingStreetLine1 || this.billingStreetLine1;
        this.billingCity = params.billingCity || this.billingCity;
        this.billingState = params.billingState || this.billingState;
        this.distributedDocumentsResponseModel = params.distributedDocumentsResponseModel || this.distributedDocumentsResponseModel;
        this.authorizationAgreementParams = params.authorizationAgreementParams || this.authorizationAgreementParams;
        this.isLifeOnlyAccount = params.isLifeOnlyAccount;
        this.paperlessEnrollmentAttempted = params.paperlessEnrollmentAttempted || this.paperlessEnrollmentAttempted;
        this.savedPaperlessEsddaPrefs = params.savedPaperlessEsddaPrefs || this.savedPaperlessEsddaPrefs;
        this.enrolledInPaperless = params.enrolledInPaperless || this.enrolledInPaperless;
        this.setupPaymentResponse = params.setupPaymentResponse || this.setupPaymentResponse;
        this.paperlessEmail = params.paperlessEmail || this.paperlessEmail;
        this.hasVULCreditCardError = params.hasVULCreditCardError ?? this.hasVULCreditCardError;
        this.paymentPlan = params.paymentPlan || this.paymentPlan;
        this.errorDetails = params.errorDetails || this.errorDetails;
    }

    clear(): void {
        this.setUpAutomaticPaymentsForm = undefined;
        this.billingAccountHolderName = undefined;
        this.isEligibleForEasyPay = undefined;
        this.amountDue = undefined;
        this.currentBillDueDate = undefined;
        this.nextBillDueDate = undefined;
        this.isPaperlessEligible = undefined;
        this.considerThisDisplayable = undefined;
        this.emailAddress = undefined;
        this.selectedPayMethod = undefined;
        this.billingAccountNumber = undefined;
        this.selectedPaperless = undefined;
        this.successfullyEnrolled = undefined;
        this.billedStatus = undefined;
        this.billingStreetLine1 = undefined;
        this.billingCity = undefined;
        this.billingState = undefined;
        this.distributedDocumentsResponseModel = undefined;
        this.authorizationAgreementParams = undefined;
        this.isLifeOnlyAccount = undefined;
        this.paperlessEnrollmentAttempted = undefined;
        this.savedPaperlessEsddaPrefs = undefined;
        this.enrolledInPaperless = undefined;
        this.emailAddress = undefined;
        this.hasVULCreditCardError = undefined;
        this.paymentPlan = undefined;
        this.errorDetails = undefined;
    }

    get flowInPlay(): boolean {
        return this.setUpAutomaticPaymentsForm !== undefined;
    }
}
