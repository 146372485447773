<is-wait-message *ngIf="apiCallInProgress" [loadComplete]="!apiCallInProgress" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<form *ngIf="loadComplete" (ngSubmit)="onContinue()" [formGroup]="editPaymentForm.group" class="manage-payment-form-container">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="Update automatic payment progress value is 20%" class="progress-bar-container to-container" progressBarValue="20"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Update automatic payment information</h1>

        <div class="bolt-row">
            <div class="bolt-col-12">
                <bw-bolt-notification type="info" class="bolt-space-bottom-2xl to-container" *ngIf="selectedPaymentMethod?.isBankCard">
                    <is-interpolate [text]="bankCardAutomaticPaymentsCapMessage"></is-interpolate>
                </bw-bolt-notification>

                <bw-bolt-notification type="error" *ngIf="(globalErrorDisplayable || paperlessEsddaValidationError) && !accountValidationMessage" [id]="'global-error-message'"
                    class="bolt-space-bottom-2xl to-container">
                    Please check the information below and try again.
                </bw-bolt-notification>

                <bw-bolt-notification type="error" *ngIf="showEFTAuthFormValidationMessage" id="eFTAuthFormValidationMessageId" class="bolt-space-bottom-2xl to-container">
                    {{eFTAuthFormValidationMessage}}
                </bw-bolt-notification>

                <bw-bolt-notification type="error" *ngIf="hasCreditCardVulPolicyError" id="creditCardVulPolicyError" class="bolt-space-bottom-2xl to-container">
                    {{ vulErrorMessage }}
                </bw-bolt-notification>
                <app-payment-sub-header>Payment information</app-payment-sub-header>
                <section id="payment-method-section" class="fluid-container">
                    <bw-bolt-notification type="error" *ngIf="accountValidationMessage" id="accountValidationErrorMessageId" class="bolt-space-bottom-2xl to-container">
                        {{accountValidationMessage}}
                    </bw-bolt-notification>
                    <div class="section-content">
                        <div [ngClass]="{'bolt-space-bottom-lg' : (!(selectedPaymentMethod?.isBankCard) && !(selectedPaymentMethod?.isBankAccount)) } ">
                            <app-payment-method-select [paymentMethods]="paymentMethods" [(selectedPaymentMethod)]="selectedPaymentMethod" [isManagePaymentPreferencesFlow]="true"
                                [reducedFees]="this.NBP && !this.isLifeOnlyAccount" [isFullPay]="this.isFullPay" [(displayErrors)]="defaultPamentMethodSelectionError">
                            </app-payment-method-select>
                        </div>
                        <div *ngIf="selectedPaymentMethod?.isBankAccount" class="bolt-space-bottom-2xl">
                            <app-bank-account-form-controls formGroupName="paymentMethodGroup" [isManagePaymentPreferencesFlow]="true" routingNumberServiceFailureMessaging="required">
                            </app-bank-account-form-controls>
                        </div>

                        <div *ngIf="selectedPaymentMethod?.isBankCard" class="bolt-space-bottom-2xl">
                            <app-bank-card-form-controls formGroupName="paymentMethodGroup" [isManagePaymentPreferencesFlow]="true">
                            </app-bank-card-form-controls>
                        </div>
                    </div>
                </section>

                <app-consider-this formGroupName="considerThisGroup" [canSelectPaperlessBilling]="isPaperlessEligible" (esddaCheckBoxChecked)="onEsddaCheckboxClick($event)"
                    [paperlessEsddaValidationError]="paperlessEsddaValidationError" [email]="getEmailAddress()" [paperlessEnrollmentOption]="editPaymentForm.enrollInPaperless"
                    [isAutoPayment]="isAutoPayment">
                </app-consider-this>
            </div>
        </div>
        <bw-bolt-notification type="info" class="bolt-space-bottom-2xl to-container">
            If there is a change in your premium amount you’ll receive a notification before any automatic payment is
            processed.
        </bw-bolt-notification>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" (click)="cancelEditPayment()">Cancel</bolt-button>
        <bolt-button submit slot="forward" type="primary" id="continue-button">Continue</bolt-button>
    </bolt-button-bar>
</form>