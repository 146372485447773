import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { BillingCommonNavigationsService } from '../../../../../../billing/shared/billing-common-navigations.service';
import { SessionService } from '../../../../../../shared/session/session.service';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { Router } from '@angular/router';
import { CancelScheduleFuturePaymentFlow } from '../services/cancel-schedule-future-payment-flow.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { LoggerService } from '../../../../../../shared/logger/logger.service';

@Component({
    selector: 'app-scheduled-future-payments-confirmation',
    templateUrl: './cancel-scheduled-future-payment-confirmation.component.html',
    styleUrls: ['./cancel-scheduled-future-payment-confirmation.component.scss']
})
export class CancelScheduledFuturePaymentConfirmationComponent extends BasePageComponent implements OnInit, OnDestroy {
    pageLoadComplete = false;
    pageName = ManagePaymentPreferencesPageTitle.scheduledFuturePayments.cancel.confirmation;

    // eslint-disable-next-line max-params
    constructor(
        @Inject('window') private window: Window,
        private billingCommonNavigationsService: BillingCommonNavigationsService,
        protected session: SessionService,
        private flow: CancelScheduleFuturePaymentFlow,
        protected router: Router,
        @Inject('logger') private logger: LoggerService,
        private eventService: BillingEventService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.sendSuccessCtmEvent();
        this.pageLoadComplete = true;
        this.eventService.handleEvent(EBIEventType.ON_LOAD_CONFIRMATION_PAGE_CANCEL_SCHEDULED_PAYMENT, {
            responseCode: `${this.flow.apiCallResponseCode}`,
            responseStatus: this.flow.apiCallSuccessful ? 'SUCCESS' : 'ERROR'
        });
    }

    ngOnDestroy(): void {
        this.flow.reset();
    }

    showPrintDialog(): void {
        this.window.print();
    }

    navigateToBillingPreferences(): void {
        this.billingCommonNavigationsService.navigateToPaymentPreferences();
    }

    navigateToMakeAPayment(): void {
        this.logger.info('On click of Cancel Scheduled Future Payment Make a Payment link');
        this.billingCommonNavigationsService.navigateToMakeAPayment();
    }

    sendSuccessCtmEvent(): void {
        if (this.apiCallSuccessful) {
            this.eventService.handleCtmEvent(
                CtmEventType.CANCEL_SCHEDULE_FUTURE_PAYMENT_SUCCESSFUL, {
                productType: CtmProductType.BILLING,
                paymentAmount: this.paymentAmount,
                paymentDate: this.paymentDate
            });
        }
    }

    get paymentAmount(): string {
        return this.flow.paymentAmount;
    }

    get paymentDate(): string {
        return this.flow.paymentDate;
    }

    get nextDueDate(): string {
        return this.flow.nextDueDate;
    }

    get apiCallSuccessful(): boolean {
        return this.flow.apiCallSuccessful;
    }
}
