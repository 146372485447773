import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, catchError, map } from 'rxjs';
import { AccountValidationResponse, AccountValidationService, ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { SessionService } from '../shared/session/session.service';
import { AccountValidationDTORequest } from './account-validation-dto-request';
import { environment } from '../../environments/environment';
import { AccountValidationModel } from './account-validation.model';
import { AccountValidationAdapter } from './account-validation-adapter.service';
import { BillingEventService } from '../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../shared/service/ebi-service/ebi-model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ManageAccountValidationService {
    failureCount: number;
    failureLimit: number;
    // eslint-disable-next-line max-params
    constructor(
        private accountValidationService: AccountValidationService,
        private adapter: AccountValidationAdapter,
        private session: SessionService,
        private eventService: BillingEventService,
        private contentfulMapperService: ContentfulMapperService
    ) {
        this.failureCount = 0;
        this.failureLimit = 3;
    }

    invokeAccountValidationService(accountValidationRequest: AccountValidationDTORequest): Observable<AccountValidationModel> {
        return this.failureCount >= this.failureLimit ?
            this.hitFailureLimit(accountValidationRequest.isPlaidEnabled) : this.makeCall(accountValidationRequest);
    }

    get isEnabled(): boolean {
        return environment.ACCOUNT_VALIDATION.featureToggle;
    }

    getTotalFailureCount(): number {
        return this.failureCount;
    }

    setTotalFailureCount(val: number): void {
        this.failureCount = val;
    }

    getTotalFailureLimit(): number {
        return this.failureCount;
    }

    setTotalFailureLimit(val: number): void {
        this.failureCount = val;
    }

    private hitFailureLimit(isPlaidEnabled: boolean): Observable<AccountValidationModel> {
        return this.contentfulMapperService.getContentfulBillingContent().pipe(
            map((contentful) => this.adapter.failureLimitResponse(contentful, isPlaidEnabled))
        );
    }

    private makeCall(req: AccountValidationDTORequest): Observable<AccountValidationModel> {
        return forkJoin({
            accountValidationRes: this.accountValidationService.validateBankAccount({
                accessToken: this.session.accessToken,
                accountValidationRequest: {
                    AccountNumber: req.bankAccountNumber,
                    RoutingNumber: req.bankRoutingNumber,
                    AccountType: req.bankAccountType
                }
            }).pipe(catchError((err: HttpErrorResponse) => of({ httpResponseCode: err.status }))),
            contentful: this.contentfulMapperService.getContentfulBillingContent()
        }).pipe(
            map((responses) => {
                const mappedRes = this.adapter.mapResponse(<AccountValidationResponse>responses.accountValidationRes, responses.contentful);
                if (!mappedRes.accepted) {
                    this.failureCount++;
                    this.sendOnFailEbiEvent(req, <AccountValidationResponse>responses.accountValidationRes);
                }
                return this.failureCount >= this.failureLimit ? this.adapter.failureLimitResponse(responses.contentful, req.isPlaidEnabled) : mappedRes;
            })
        );
    }

    private sendOnFailEbiEvent(req: AccountValidationDTORequest, res: AccountValidationResponse): void {
        this.eventService.handleEvent(EBIEventType.ON_BANK_ACCOUNT_VERIFICATION_ERROR, {
            accountValidationVerificationCode: res.Verification?.Code ?? 'NA',
            responseCode: `${res.httpResponseCode}`,
            responseStatus: res.Verification ? 'SUCCESS' : 'ERROR',
            paymentMethod: [<any>{
                firstName: req.firstName,
                lastName: req.lastName,
                clientType: req.isAuthenticated ? 'Authenticated' : 'Unauthenticated'
            }]
        });
    }
}
