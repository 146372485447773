import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NewBankCardMethod } from '../../billing/payment-preferences/shared/models/new-bank-card.model';
import { PaymentItem } from '../../billing/payment-preferences/shared/models/payment-item.model';
import { PayBillLanding } from './pay-bill-landing.model';
import { LastPayment } from '../shared/your-last-payment/your-last-payment.component';
import { PayBillLandingDTO } from './PayBillLandingDTO.model';
import { PaymentHistoryItem } from '../../billing/bills-and-payments/payment-history/payment-history-item.model';
import { SessionService } from '../../shared/session/session.service';
import { environment } from '../../../environments/environment';
import { NewBankAccountMethod } from '../../billing/payment-preferences/shared/models/new-bank-account.model';
import { IsbSharedCommonHelperService } from '../../shared/isb-shared-common-helper.service';
import { PayBill } from './pay-bill-landing-adpater.model';

@Injectable()
export class PayBillLandingHelperService {
    constructor(
        private sessionService: SessionService,
        private datePipe: DatePipe,
        private isbSharedCommonHelperService: IsbSharedCommonHelperService) {
    }
    constructPaymentDueMessage(paymentDueDate: string): string {
        const currentDate: string = this.isbSharedCommonHelperService.getFormattedDate(new Date());
        const daysTillDueDateIncludingToday: number = this.isbSharedCommonHelperService.getDateDiff(new Date(paymentDueDate), new Date(currentDate));
        let paymentDueMessage = '';
        if (daysTillDueDateIncludingToday === 0) {
            paymentDueMessage = 'Due today';
        } else if (daysTillDueDateIncludingToday > 0 && new Date(paymentDueDate) > new Date(currentDate)) {
            paymentDueMessage = `Due in ${daysTillDueDateIncludingToday} days ${paymentDueDate}`;
        } else if (new Date(paymentDueDate) < new Date(currentDate)) {
            paymentDueMessage = `Past due ${paymentDueDate}`;
        }
        return paymentDueMessage;
    }

    constructPaybillLanding(payBillLandingDTO: PayBillLandingDTO, savedPaymentMethods?: PaymentItem[], isQuickPay?: boolean
    ): PayBillLanding {
        const lastPayment: LastPayment = null;
        let paymentMethods: PaymentItem[] = [];
        const defaultPaymentMethods: PaymentItem[] = this.getPaymentMethods();
        if (savedPaymentMethods && savedPaymentMethods.length > 0) {
            paymentMethods = isQuickPay ? savedPaymentMethods : [...defaultPaymentMethods, ...savedPaymentMethods];
        } else {
            paymentMethods = defaultPaymentMethods;
        }
        const isReinstatementEligible = false;
        const isPaperlessEligible = false;
        const isEligibleForEasyPay = false;
        const maxPaymentDateAllowed = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
        const displayPaymentDateSection = false;
        const partialPaymentHistory: PaymentHistoryItem[] = [];
        const statementAmount = NaN;

        return new PayBillLanding(
            payBillLandingDTO.amountDue,
            lastPayment,
            payBillLandingDTO.paymentAmountOptions,
            paymentMethods,
            isPaperlessEligible,
            isReinstatementEligible,
            isEligibleForEasyPay,
            payBillLandingDTO.isWarnedPolicy,
            payBillLandingDTO.paymentDueDate,
            maxPaymentDateAllowed,
            payBillLandingDTO.emailAddress,
            payBillLandingDTO.state,
            '',
            '',
            displayPaymentDateSection,
            this.sessionService.sourceSystem,
            '',
            payBillLandingDTO.agreementStatus,
            partialPaymentHistory,
            payBillLandingDTO.productName,
            statementAmount,
            payBillLandingDTO.fullPayAmount,
            '',
            payBillLandingDTO.producerNumbers,
            false,
            '',
            payBillLandingDTO.isCareMatterPolicy,
            payBillLandingDTO.isOipaPolicy,
            false,
            false,
            false,
            null,
            '',
            '',
            payBillLandingDTO.isAutomaticPaymentSuspended,
            null,
            false,
            '',
            null,
            null,
            '',
            payBillLandingDTO.policyNumber,
            false,
            '',
            '',
            '',
            false,
            false,
            false,
            false,
            '',
            false,
            payBillLandingDTO.paymentPlan,
            payBillLandingDTO.agreementType,
            payBillLandingDTO.installmentFee,
            payBillLandingDTO.minimumAmountDue
        );
    }

    isPaymentCap(paymentAmount: number): boolean {
        return Number(paymentAmount) > PayBill.BANKCARD_CAP ||
            Number(paymentAmount) > PayBill.PAYMENT_CAP;
    }

    isBankAccountPaymentCap(paymentAmount: number): boolean {
        return Number(paymentAmount) > PayBill.PAYMENT_CAP;
    }

    isBankCardPaymentCap(paymentAmount: number): boolean {
        return Number(paymentAmount) > PayBill.BANKCARD_CAP;
    }
    isBackButtonClickInReviewPage(): boolean {
        return this.sessionService.pageNameOnBackButtonClick === 'payBillReviewPage';
    }

    private getPaymentMethods(): PaymentItem[] {
        let paymentMethods: PaymentItem[];
        if (environment.CYBERLIFE_ACCOUNT_LEVEL_PAYMENTS.enabled) {
            paymentMethods = [new PaymentItem(new NewBankAccountMethod()), new PaymentItem(new NewBankCardMethod())];
        } else if (this.sessionService.sourceSystem !== 'SAP Billing') {
            paymentMethods = [new PaymentItem(new NewBankCardMethod())];
        }
        return paymentMethods;
    }
}
