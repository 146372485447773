import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';

export type ControlNames = 'accountTypeGroup' | 'nameGroup' | 'addressGroup' | 'accountNumberGroup' | 'makeDefaultPaymentMethod' | 'bankAccountInfoGroup'
    | 'savePaymentMethod';

@Component({
    selector: 'app-bank-account-plaid-form-controls',
    templateUrl: './bank-account-plaid-form-controls.component.html',
    styleUrls: [
        './bank-account-plaid-form-controls.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class BankAccountPlaidFormControlsComponent extends BaseFormGroupComponent<ControlNames> implements OnInit {
    @Input() canSaveAsDefault: boolean;
    @Input() plaidEnabled?: boolean;
    @Input() manualAccountDisabled?: boolean;
    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        this.controllerValueDefault();
        this.controllerValueSave();
        if (this.formGroup.get('bankAccountInfo')?.value !== 'ManualAccount') {
            this.formGroup.get('bankAccountInfoGroup').get('bankAccountInfo').reset();
        }
    }

    controllerValueDefault(): void {
        this.formGroup.get('makeDefaultPaymentMethod').valueChanges.subscribe((valueDefault) => {
            const valueSave = this.formGroup.get('savePaymentMethod').value;
            if (!valueSave && valueDefault) {
                this.formGroup.get('savePaymentMethod').setValue(true);
            }
        });
    }

    controllerValueSave(): void {
        this.formGroup.get('savePaymentMethod').valueChanges.subscribe((valueSave) => {
            const valueDefault = this.formGroup.get('makeDefaultPaymentMethod').value;
            if (!valueSave && valueDefault) {
                this.formGroup.get('makeDefaultPaymentMethod').setValue(false);
            }
        });
    }
}
