<fieldset class="bolt-space-bottom-xl" [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <ng-container *ngIf="readonly">
        <div class="row">
            <div class="col-md-5">
                <h4 class="bolt-heading">Card number</h4>
                <span>************</span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>
                    <em>To edit the card number, remove the payment method and add it again.</em>
                </p>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!readonly">
        <div class="row">
            <div class="col-md-5">
                <bolt-textfield class="bolt-space-bottom-sm" type="text" ngDefaultControl appDisablePaste id="bank-card-number" class="nwpii" formControlName="bankCardNumber" label="Card number"
                    required="true" (blur)="setSelectedCardType()" maxlength="16" ariaLabel="Card Number" [attr.invalid]="bankCardErrorsDisplayable ? true : null" ariadescribedby="card-number-errors">
                </bolt-textfield>
                <app-form-control-error id="card-number-errors" *ngIf="bankCardErrorsDisplayable">
                    <ul class="error-list">
                        <li *ngIf="bankCardNumber.errors['required']">
                            Card number is required.
                        </li>
                        <li *ngIf="(bankCardNumber.errors['containsOnlyDigits'] ||
							bankCardNumber.errors['cardTypeIdentifiable']) &&
							!bankCardNumber.errors['required']">
                            Card number is invalid.
                        </li>
                    </ul>
                </app-form-control-error>
            </div>
        </div>
    </ng-container>
</fieldset>