import { Injectable } from '@angular/core';
import { AreYouSureFormGroup } from '../../../../../../shared/payments/are-you-sure-radio-buttons/are-you-sure-form-group';

@Injectable()
export class SuspendAutomaticPaymentsFlowService {
    suspendAutomaticPaymentsForm: AreYouSureFormGroup;
    billingAccountNumber: string;
    amountDue: string;
    amountDueDate: Date;
    nextDueDate: Date;
    canPayNow = false;
    apiCallFailed = false;
    apiResponseCode = null;
    paymentPlan: string;
    emailAddress: string;

    get flowInPlay(): boolean {
        return this.suspendAutomaticPaymentsForm !== undefined;
    }

    save(params: Partial<SuspendAutomaticPaymentsFlowService>): void {
        this.suspendAutomaticPaymentsForm = params.suspendAutomaticPaymentsForm || this.suspendAutomaticPaymentsForm;
        this.amountDue = params.amountDue || this.amountDue;
        this.amountDueDate = params.amountDueDate || this.amountDueDate;
        this.nextDueDate = params.nextDueDate || this.nextDueDate;
        this.billingAccountNumber = params.billingAccountNumber || this.billingAccountNumber;
        this.canPayNow = params.canPayNow || this.canPayNow;
        this.paymentPlan = params.paymentPlan || this.paymentPlan;
    }

    clear(): void {
        this.suspendAutomaticPaymentsForm = undefined;
        this.amountDue = undefined;
        this.amountDueDate = undefined;
        this.billingAccountNumber = undefined;
        this.nextDueDate = undefined;
        this.canPayNow = undefined;
        this.apiResponseCode = undefined;
        this.apiCallFailed = undefined;
        this.paymentPlan = undefined;
    }

    get setupSuspendAutomaticPaymentsError(): boolean {
        return this.allErrors.includes(true);
    }

    get allErrors(): boolean[] {
        return [
            this.apiCallFailed
        ];
    }
}
