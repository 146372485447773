export const PLACEHOLDER_MESSAGES = {
    BILL_DUE_ALERT: `This change will affect your current bill due`,
    WARNING: 'Please be advised when canceling automatic payments: Nationwide will no longer draw from your bank or credit/debit card account.',
    CONFIRMATION_SUCESS_MESSAGE: 'We will no longer deduct payments from your bank account or credit/debit card. Please make arrangements for future payments.',
    ENROLL_MESSAGE: 'Even though you have canceled your automatic payments for now, you can re-enroll at any time.',
    CONFIRMATION_DETAIL: [
        'Request was made',
        'for the billing account'
    ],
    OUTSTANDING_PAYMENT_DUE: [
        'A payment of',
        'is due for this account on',
        'The amount will no longer be automatically deducted from your account. To make a payment, select Make a payment.'
    ]
};
