import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { CancelScheduleFuturePaymentFlow } from '../services/cancel-schedule-future-payment-flow.service';
import { SessionService } from '../../../../../../shared/session/session.service';

@Injectable()
export class CancelScheduleFuturePaymentConfirmationGuard implements CanActivate {
    constructor(
        private router: Router,
        private session: SessionService,
        private cancelScheduleFuturePaymentFlow: CancelScheduleFuturePaymentFlow
    ) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.cancelScheduleFuturePaymentFlow.flowInPlay || this.router.parseUrl(environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences({
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId
        }));
    }
}
