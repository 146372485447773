import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';
import { CardType } from '../card-utils/card-type.enum';
import { cardUtils } from '../card-utils/card-utils';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';

export type ControlNames = 'bankCardNumber';

@Component({
    selector: 'app-bank-card-number-input',
    templateUrl: './bank-card-number-input.component.html',
    styleUrls: [
        './bank-card-number-input.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class BankCardNumberInputComponent extends BaseFormGroupComponent<ControlNames> implements OnInit {
    @Input() readonly: boolean;
    @Input() cardType: CardType = CardType.INVALID;
    @Output() cardTypeSelection = new EventEmitter<CardType>();

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        this.setSelectedCardType();
    }

    get bankCardNumber(): AbstractControl {
        return this.getControl('bankCardNumber');
    }

    get bankCardErrorsDisplayable(): boolean {
        return this.errorsDisplayable('bankCardNumber');
    }

    get isVisa(): boolean {
        return this.cardType === CardType.VISA;
    }

    get isMastercard(): boolean {
        return this.cardType === CardType.MASTERCARD;
    }

    get isAmex(): boolean {
        return this.cardType === CardType.AMERICAN_EXPRESS;
    }

    get isDiscover(): boolean {
        return this.cardType === CardType.DISCOVER;
    }

    setSelectedCardType(): void {
        if (!this.readonly) {
            this.cardType = cardUtils.parseType(this.bankCardNumber.value);
            this.cardTypeSelection.emit(this.cardType);
        }
    }
}
