<div class="bolt-row">
    <div class="bolt-col-12">
        <label for="{{ id }}" class="font-weight-bold">
            {{ label }}
        </label>
    </div>
    <div class="bolt-col-12">
        <input id="{{ id }}" [type]="(id === 'card-holder-name-recap' || id ===
			'account-holders-name-recap') ? 'hidden' : 'text'" class="input-form-control-plain-text" value="{{ value }}" aria-readonly="true" readonly tabindex="-1" [ngClass]="{'nwpii' : id === 'account-number-recap'
			|| id === 'card-number-recap'
			|| id === 'cvv-recap' || id === 'expiration-date-recap'}">
        <div *ngIf="(id === 'card-holder-name-recap' || id ===
			'account-holders-name-recap')" class="name-wrap">
            {{ value }}
        </div>
    </div>
</div>