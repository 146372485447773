import { Component } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { BaseFormGroupComponent } from '../../../shared/payments/base-form-control/base-form-control.component';

export type ControlNames = 'emailAddress';

@Component({
    selector: 'app-email-form-control',
    templateUrl: './email-form-control.component.html',
    styleUrls: ['./email-form-control.component.scss']
})
export class EmailFormControlComponent extends BaseFormGroupComponent<ControlNames> {
    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    get email(): AbstractControl {
        return this.getControl('emailAddress');
    }

    get emailErrorsDisplayable(): boolean {
        return this.errorsDisplayable('emailAddress');
    }
}
