import { CardType } from '../../../../shared/payments/card-utils/card-type.enum';

export class BankCardPaymentItem {
    // eslint-disable-next-line max-params
    constructor(
        readonly firstName: string,
        readonly lastName: string,
        readonly middleInitial: string,
        readonly cvv: string,
        readonly zipCode: string,
        readonly cardType: CardType,
        readonly maskedCardNumber: string,
        readonly cardExpiration: Date,
        readonly cardNumberLastFour: string, // ???
        readonly creditOrDebit?: 'Credit' | 'Debit',
        readonly profileId?: string
    ) { }

    get nameOnAccount(): string {
        return `${this.firstName} ${this.middleInitial} ${this.lastName}`;
    }

    get displayNumbers(): string {
        return this.cardNumberLastFour;
    }

    get expirationMonth(): string {
        const monthNumber = this.cardExpiration.getMonth() + 1;
        return monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
    }

    get expirationYear(): string {
        return `${this.cardExpiration.getFullYear()}`;
    }
}
