import { Injectable } from '@angular/core';
import {
    GetDPIMQueryParameters
} from '@nationwide/internet-servicing-dpim-requests';
import { SessionService } from '../../../shared/session/session.service';
import { UnitedStatesNameService } from '../../../shared/payments/united-state-name.service';
import { ECIF } from '../../../shared/constants/common.constants';
import { environment } from '../../../../environments/environment';
@Injectable()
export class DistributionPartnerRoleInfoHelperService {
    constructor(
        private session: SessionService,
        private stateNames: UnitedStatesNameService
    ) {
    }

    getCurrentAgreement(internetRegistrationResponse: any): any {
        let output = {};
        if (internetRegistrationResponse.internetRegistrationResponse && internetRegistrationResponse.internetRegistrationResponse.agreements && internetRegistrationResponse.internetRegistrationResponse.agreements.length > 0) {
            output = internetRegistrationResponse.internetRegistrationResponse.agreements.filter(
                (agreement) => this.session.billingAccountNumber === agreement.agreementReference.referenceNumber)[0];
        }
        return output;
    }

    getStateFromInternetRegistrationResponse(
        internetRegistrationResponse: any
    ): string {
        try {
            if (
                internetRegistrationResponse.internetRegistrationResponse.customers.contactPoints[0].postalAddress
            ) {
                return internetRegistrationResponse.internetRegistrationResponse.customers.contactPoints[0]
                    .postalAddress.state;
            }
        } catch (error) {
            return '';
        }
    }

    generateQueryString(producerType: string, queryparams: GetDPIMQueryParameters): string {
        const includeSubRoleContractDataValue = producerType === ECIF.PRODUCER_VALUES.AMF ? 'true' : 'false';
        return `legacyIdentifierType=${queryparams.legacyIdentifierType}&legacyAdministrativeSystem=${queryparams.legacyAdministrativeSystem}&filterLicenseSummaries=true&includeSubRoleContractData=${includeSubRoleContractDataValue}&client_id=${environment.CLIENT_ID}`;
    }

    getProducerNumbers(agreement: any): string {
        const producerNumbers: string[] = [];
        if (agreement?.relatedDistributionPartners) {
            for (const relatedDistributionPartner of agreement.relatedDistributionPartners) {
                if (relatedDistributionPartner.distributionPartnerType.includes('Producer')) {
                    producerNumbers.push(relatedDistributionPartner.externalIdentifier);
                }
            }
        }
        return producerNumbers?.[0];
    }

    retrieveProducerType(agreement: any): string {
        let producerType: string;
        if (agreement?.relatedDistributionPartners) {
            for (const relatedDistributionPartner of agreement.relatedDistributionPartners) {
                if (relatedDistributionPartner.distributionPartnerType.includes(ECIF.PRODUCER_VALUES.RESIDENT_PRODUCER) &&
                    relatedDistributionPartner.roleDistributionChannel) {
                    producerType = relatedDistributionPartner.roleDistributionChannel === ECIF.PRODUCER_VALUES.INDEPENDENT_CHANNEL_AGENT ?
                        ECIF.PRODUCER_VALUES.AMF : ECIF.PRODUCER_VALUES.PDS;
                    break;
                }
            }
        }
        return producerType;
    }

    getStateName(abbreviation: string): string {
        return this.stateNames.convertAbbreviationToName(abbreviation);
    }
}
