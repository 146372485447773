<ng-container [formGroup]="formGroup">
    <div [ngClass]="{'radio-container': !shouldDisplayPaymentMethodsDropdown,
	'radio-container-dropdown': shouldDisplayPaymentMethodsDropdown}">
        <h3 class="label-heading">{{ displayMessage }}</h3>
        <app-form-control-error id="areYouSureErrors" *ngIf="submitted && errorsDisplayable('selection')">
            <ul class="error-list">
                <li id="are-you-sure-required-error" *ngIf="requiredErrorDisplayable">Yes or No is required.</li>
            </ul>
        </app-form-control-error>
        <bolt-radio-group [label]="" ngDefaultControl optionaltext="hide" name="selection" formControlName="selection" horizontal class="boltradio" required
            [invalid]="(submitted && errorsDisplayable('selection'))?true:null">
            <bolt-radio value="yes">Yes</bolt-radio>
            <bolt-radio value="no">No</bolt-radio>
        </bolt-radio-group>
    </div>
</ng-container>