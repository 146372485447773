import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';

export type ControlNames = 'cardExpirationMonth' | 'cardExpirationYear';

@Component({
    selector: 'app-bank-card-expiration-form-control',
    templateUrl: './bank-card-expiration-form-control.component.html',
    styleUrls: [
        './bank-card-expiration-form-control.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class BankCardExpirationFormControlComponent extends BaseFormGroupComponent<ControlNames> implements OnInit {
    @Input() labelText = 'Expiration date';
    @Input() placeholderMonth = 'Month';
    @Input() placeholderYear = 'Year';
    @Input() optionalText = 'hide';

    cardYearList: string[];
    cardMonthList: string[];
    isInitialState = true;

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        this.cardMonthList = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        this.getBankCardYearList();
    }
    getBankCardYearList(): void {
        this.cardYearList = [];
        const minYear = new Date().getFullYear();
        const ADDITIONAL_YEARS_TO_SHOW = 18;
        const maxYear = minYear + ADDITIONAL_YEARS_TO_SHOW;
        for (let year = minYear; year <= maxYear; year++) {
            this.cardYearList.push(year.toString());
        }
    }

    get expirationYear(): AbstractControl {
        return this.getControl('cardExpirationYear');
    }

    get expirationYearErrorsDisplayable(): boolean {
        return this.errorsDisplayable('cardExpirationYear');
    }

    get expirationYearUnselected(): boolean {
        const control = this.getControl('cardExpirationYear');
        return control.errors && control.errors['required'];
    }

    get expirationMonth(): AbstractControl {
        return this.getControl('cardExpirationMonth');
    }

    get expirationMonthErrorsDisplayable(): boolean {
        return this.errorsDisplayable('cardExpirationMonth');
    }

    get expirationMonthUnselected(): boolean {
        const control = this.getControl('cardExpirationMonth');
        return control.errors && control.errors['required'];
    }

    get isValidExpiryDate(): boolean {
        return !this.getControl('cardExpirationMonth').hasError('monthInPast') &&
            !this.getControl('cardExpirationYear').hasError('yearInPast');
    }

    onMonthSelectChange($evt): void {
        const newVal = $evt?.target?.value;
        if (newVal === '') { // which means the placeholder selected
            this.expirationMonth.setErrors({ required: true });
            this.expirationMonth.markAsDirty();
            this.expirationMonth.markAsTouched();
        } else {
            this.isInitialState = false;
            this.expirationMonth.patchValue(newVal);
            // wait a tick until the form control value updated
            setTimeout(() => {
                if (this.isValidExpiryDate) {
                    this.expirationMonth.setErrors({ required: null });
                    this.expirationMonth.updateValueAndValidity();
                }
            });
        }
    }

    onYearSelectChange($evt): void {
        const newVal = $evt?.target?.value;
        if (newVal === '') { // which means the placeholder selected
            this.expirationYear.setErrors({ required: true });
            this.expirationYear.markAsDirty();
            this.expirationYear.markAsTouched();
        } else {
            this.isInitialState = false;
            this.expirationYear.patchValue(newVal);
            // wait a tick until the form control value updated
            setTimeout(() => {
                if (this.isValidExpiryDate) {
                    this.expirationYear.setErrors({ required: null });
                    this.expirationYear.updateValueAndValidity();
                }
            });
        }
    }
}
