import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/internet-servicing-angular-services';
import { PostPreferencesRequestParams, PreferenceCenterService, preferenceCenterServiceToken, PREFERENCE_CENTER_CONSTANTS } from '@nationwide/dgs-angular-billing-common';
import { catchError, map, Observable, of, timeout } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../shared/logger/logger.service';
import { SessionService } from '../../shared/session/session.service';

@Injectable({
    providedIn: 'root'
})
export class PreferenceCenterPaperlesService {
    // eslint-disable-next-line max-params
    constructor(
        private session: SessionService,
        private datePipe: DatePipe,
        @Inject(preferenceCenterServiceToken) private preferenceCenterService: PreferenceCenterService,
        @Inject('logger') private LOGGER: LoggerService,
        private readonly apiCommonService: APICommonService
    ) { }

    saveEsddaPreferences(emailId: string, enrollinPaperless?: string): Observable<boolean> {
        const preferencePayload = this.postPreferencePayload(enrollinPaperless, emailId);
        return this.preferenceCenterService.postPreference(
            preferencePayload).pipe(
                map((response: any) => {
                    const preferences = response.preferences;
                    return preferences?.some((preference) => preference.preferenceType === 'ContactPointPreference' && preference.election === 'Yes' || preference.election === 'No');
                }),
                timeout(environment.TIMEOUT.apiCallDuration.submitPayment),
                catchError((err) => {
                    this.LOGGER.error('API ERROR: SAVE ESDDA PREFERENCES SUBMISSION', err);
                    return of(false);
                })
            );
    }

    private postPreferencePayload(enrollinPaperless: string, emailId: string): PostPreferencesRequestParams {
        let preferences;
        preferences = [{
            preferenceType: 'ContactPointPreference',
            name: 'eNotify Non-Policy Jacket Docs',
            election: enrollinPaperless,
            category: 'Servicing',
            reason: 'Unknown',
            source: 'Internet',
            startDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
            relatedAgreement: {
                agreementNumber: this.session.billingAccountNumber,
                productType: 'Billing Account'
            },
            contactPoint: {
                contactPointType: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.PAPERLESS_BILL_PREFERENCE_TYPE_AGREEMENT_CONTACT_POINT_TYPE,
                emailAddress: emailId
            }
        }
        ];
        if (enrollinPaperless === 'Yes') {
            preferences = [{
                preferenceType: 'ContactPointPreference',
                name: 'eNotify Non-Policy Jacket Docs',
                election: enrollinPaperless,
                category: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.SERVICE_CATEGORY,
                reason: 'Unknown',
                source: 'Internet',
                startDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
                relatedAgreement: {
                    agreementNumber: this.session.billingAccountNumber,
                    productType: 'Billing Account'
                },
                contactPoint: {
                    contactPointType: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.PAPERLESS_BILL_PREFERENCE_TYPE_AGREEMENT_CONTACT_POINT_TYPE,
                    emailAddress: emailId
                }
            },
            {
                preferenceType: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.PAPERLESS_BILL_PREFERENCE_TYPE_GENERIC,
                name: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.PREFERENCE_TYPE_PARTY_CONSENT_FOR_EDELIVERY,
                election: enrollinPaperless,
                category: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.SERVICE_CATEGORY,
                lineOfBusiness: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.LINE_OF_BUSINESS_PERSONAL
            },
            {
                preferenceType: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.PAPERLESS_BILL_PREFERENCE_TYPE_GENERIC,
                name: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.PREFERENCE_TYPE_PAPERLESS_BILL,
                election: enrollinPaperless,
                category: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.SERVICE_CATEGORY,
                lineOfBusiness: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.LINE_OF_BUSINESS_PERSONAL
            },
            {
                preferenceType: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.PAPERLESS_BILL_PREFERENCE_TYPE_GENERIC,
                name: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.PREFERENCE_TYPE_COMMERCIAL_EDDA,
                election: enrollinPaperless,
                category: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.SERVICE_CATEGORY,
                lineOfBusiness: PREFERENCE_CENTER_CONSTANTS.PREFERENCES.LINE_OF_BUSINESS_COMMERCIAL
            }
            ];
        }
        return {
            isFiredOnMAPReview: true,
            accessToken: this.session.accessToken,
            guid: this.session.guid,
            transactionId: this.apiCommonService.generateTransactionId(),
            ecn: this.session.ecn,
            consumerId: 'Internet Servicing Billing',
            cacheByPass: 'Y',
            payload: {
                preferences
            }
        };
    }
}
