import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    CimService,
    NFCIM,
    ECIM,
    enterpriseCIMServiceToken,
    EnterpriseCIMService,
    PersonalBillingHttpClient,
    BillInquiryProductType,
    PersonalBillingService,
    PersonalBillingServiceToken,
    isbExperienceServiceToken,
    IsbExperienceService,
    IsbExperience,
    RetrivePaymentHistoryResponse,
    ContextCacheService,
    PBS

} from '@nationwide/dgs-angular-billing-common';
import { DateUtil } from '@nationwide/angular-timeline';
import { Observable, forkJoin, of, timeout, mergeMap, map, catchError, tap } from 'rxjs';
import { SessionService } from '../../shared/session/session.service';
import { PayBillLandingAdapter } from '../shared/adapters/pay-bill-landing.adapter';
import { PayBillLanding } from './pay-bill-landing.model';
import { CyberlifeAdapter } from '../shared/adapters/cyberlife.adapter';
import { environment } from '../../../environments/environment';
import { BillingBaseAdapter } from '../../billing/billing-base/billing-base.adapter';
import { HttpCachingService } from '../../shared/service/http-caching-service/http-caching.service';
import { PaymentFlowType } from '../shared/payment-flow-type.service';
import { LoggerService } from '../../shared/logger/logger.service';
import { ATService } from '@nationwide/nw-at-angular';
import { RetriveCustomerAgreementResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/portfolio-business-service';
import { Bill, GetBillsResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/personal-billing';
import { QuickPayHelperService } from '../../quick-pay/shared/services/quick-pay-helper/quick-pay-helper.service';
@Injectable()
export class PayBillLandingService {
    private _endDate: Date;
    private _startDate: Date;
    increaseOverpaymentAdobeTargetExperience: any;
    increaseOverpaymentAdobeTargetExperienceError: boolean;
    currentAndFutureBills: Bill[];
    rcaResponse: PBS.RetriveCustomerAgreementResponse;

    // eslint-disable-next-line max-params
    constructor(
        private http: HttpClient,
        private cim: CimService,
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private adapter: PayBillLandingAdapter,
        private cyberlifeAdapter: CyberlifeAdapter,
        private session: SessionService,
        private billingBaseAdapter: BillingBaseAdapter,
        private personalBilling: PersonalBillingHttpClient,
        private dateUtil: DateUtil,
        @Inject(enterpriseCIMServiceToken)
        private ecimService: EnterpriseCIMService,
        @Inject(PersonalBillingServiceToken)
        private personalBillingService: PersonalBillingService,
        private quickPayHelperService: QuickPayHelperService,
        private readonly httpCachingService: HttpCachingService,
        private readonly paymentFlowType: PaymentFlowType,
        private readonly contextCacheService: ContextCacheService,
        private readonly atService: ATService,
        @Inject('logger') private logger: LoggerService
    ) { }

    getTokenExchange(publicToken: string, accountId: string): Observable<any> {
        const headers = this.getHeaderValues(accountId);
        return this.http.get(
            `${environment.PLAID.url}?public_token=${publicToken}&client_id=${environment.CLIENT_ID}`,
            { headers }
        );
    }

    fetchPaymentOptions(): Observable<PayBillLanding> {
        const isPayMidTermChange = this.paymentFlowType.isPayMidTermChange();
        const contextId = this.paymentFlowType.getContextCacheContextId();
        const observables = {
            rca: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
            internetRegistrations: this.getInternetRegistrations(),
            bills: this.paymentFlowType.isSplitPartyUser ? of(null) : this.retrieveBills()
        };
        if (isPayMidTermChange && contextId) {
            observables['contextCache'] = this.retrieveContextCache(contextId);
        }

        return forkJoin(observables).pipe(
            tap((responses: any) => {
                this.session.policyNumbers = this.billingBaseAdapter.mapAgreementNumbers(responses.rca);
                this.session.productTypes = this.billingBaseAdapter.mapProductTypes(responses.rca);
                this.session.activePolicyNumbers = this.billingBaseAdapter.mapActivePolicyNumbers(responses.rca);
                this.session.activeProductTypes = this.billingBaseAdapter.mapActiveProductTypes(responses.rca);
                this.currentAndFutureBills = this.adapter.getCurrentAndFutureBills(responses.bills);
            }),
            mergeMap((responses) =>
                forkJoin({
                    rca: of(responses.rca),
                    unmappedPayMethods: this.retrieveSavedPayMethods(),
                    paymentHistory: this.retrievePaymentHistory(),
                    internetRegistrations: of(responses.internetRegistrations),
                    getAgreement: this.paymentFlowType.isSplitPartyUser ? of(null) : this.getAgreement(),
                    contextCache: responses.contextCache ? of(responses.contextCache) : of(null),
                    unparsedAdobeTargetExperience: this.getAdobeTargetOffer(responses.rca, this.currentAndFutureBills, isPayMidTermChange)
                })
            ),
            tap((responses) => {
                this.setAdobeTargetExperience(responses.unparsedAdobeTargetExperience);
            }),
            map((responses) =>
                this.adapter.mapPayBillLanding({
                    rcaResponse: responses.rca,
                    unmappedPayMethods: responses.unmappedPayMethods,
                    paymentHistoryResponse: responses.paymentHistory,
                    internetRegistrationResponse: responses.internetRegistrations,
                    getAgreementResponse: responses.getAgreement,
                    contextCacheResponse: responses.contextCache
                })
            ),
            timeout(environment.TIMEOUT.apiCallDuration.payBillLanding),
            catchError((err) => {
                this.logger.error('MAPPING ERROR: FETCHPAYMENTOPTIONS METHOD IN PAY BILL LANDING SERVICE', err);
                return of(err);
            })
        );
    }

    getAdobeTargetOffer(rcaResponse: RetriveCustomerAgreementResponse, currentAndFutureBills: Bill[], isPayForMidTermChange: boolean): Observable<any> {
        // filtering out pay for midterm change since we don't want both showing up at the same time
        if (this.adapter.isIncreaseOverpaymentEligible(rcaResponse) &&
            this.adapter.hasIncreaseOverpaymentEligibleBills(currentAndFutureBills) &&
            !isPayForMidTermChange) {
            const options = {
                mbox: environment.ADOBE_MBOXES.INCREASE_OVERPAYMENT
            };
            return this.atService.getOffer(options).pipe(catchError(() => {
                this.increaseOverpaymentAdobeTargetExperienceError = true;
                return of(null);
            }));
        } else {
            return of(null);
        }
    }

    setAdobeTargetExperience(value: any): void {
        if (this.increaseOverpaymentAdobeTargetExperienceError) {
            this.increaseOverpaymentAdobeTargetExperience = 'Error';
        } else if (value) {
            this.increaseOverpaymentAdobeTargetExperience = JSON.parse(value.offer[0].content).experience;
        } else {
            this.increaseOverpaymentAdobeTargetExperience = null;
        }
    }

    fetchCyberLifePaymentOptions(): Observable<PayBillLanding> {
        return forkJoin(
            this.retrieveCustomerInformationManagement(),
            this.getInternetRegistrations()
        ).pipe(
            tap(([cimResponse, internetRegistrationResponse]) => this.session.intitialize({ nfPbs: cimResponse })),
            map(([
                cimResponse,
                internetRegistrationResponse
            ]) =>
                this.cyberlifeAdapter.mapCyberLifePayment({
                    cimResponse,
                    internetRegistrationResponse
                })
            ),
            timeout(environment.TIMEOUT.apiCallDuration.payBillLanding),
            catchError((err) => {
                this.logger.error('API ERROR: FETCH CYBER LIFE PAYMENT OPTIONS IN PAY BILL LANDING PAGE', err);
                return of(err);
            })
        );
    }

    callRTBC(amount: string, paymentType: string, productType: string): Observable<any> {
        return this.personalBilling.billCalculationInquiry({
            accessToken: this.session.accessToken,
            accountNumber: this.session.billingAccountNumber,
            payload: {
                inquiry: {
                    intendedAccountPayment: paymentType === 'account' ? amount : '0.00',
                    policyDetail: [
                        {
                            policyNumber: this.session.policyNumber,
                            product: this.getProductTypeFromPolicy(productType),
                            intendedPolicyPayment: paymentType === 'policy' ? amount : '0.00'
                        }
                    ]
                }
            }
        }).pipe(
            map((response) => response),
            tap((response) => response),
            catchError((error: HttpErrorResponse) => of(error))
        );
    }

    getBills(): Observable<any> {
        return this.personalBilling.getBills({
            accessToken: this.session.accessToken,
            accountNumber: this.session.billingAccountNumber
        }).pipe(
            catchError((error: HttpErrorResponse) => of(error))
        );
    }

    getProductTypeFromPolicy(productType: string): BillInquiryProductType {
        let billingProductType: BillInquiryProductType;
        if (productType === 'Property') {
            billingProductType = 'Fire';
        } else if (productType === 'Auto') {
            billingProductType = 'Auto';
        } else if (productType === 'LIFE') {
            billingProductType = 'Life';
        }
        return billingProductType;
    }

    private getHeaderValues(accountId: string): any {
        const accessToken = this.session.accessToken;
        const headerOptions = {
            client_id: `${environment.CLIENT_ID}`,
            'Content-Type': 'application/json',
            account_id: accountId
        };

        headerOptions['Authorization'] = `Bearer ${accessToken}`;

        return headerOptions;
    }

    private retrieveCustomerInformationManagement(): Observable<NFCIM.NfCustomerInformationManagementResponse> {
        return this.cim.retrieveNfCustomerInformation({
            accessToken: this.session.accessToken,
            agreementNumber: this.session.policyNumber,
            transactionId: this.session.a2aSessionId
        });
    }

    retrieveSavedPayMethods(): Observable<IsbExperience.SavedPaymentMethod[]> {
        return this.isbExperience.getSavedPaymentMethods(
            {
                isFiredOnMAPLanding: true,
                accessToken: this.session.accessToken,
                billingSystem: this.session.billingSystem,
                ecn: this.ecn,
                billingAccountNumber: this.billingAccountNumber
            }).pipe(
                catchError(() => of([]))
            );
    }

    private retrievePaymentHistory(): Observable<RetrivePaymentHistoryResponse> {
        return this.isbExperience.getPayments({
            isFiredOnMAPLanding: true,
            accessToken: this.session.accessToken,
            accountNumber: this.session.billingAccountNumber,
            billingSystem: this.session.billingSystem,
            sessionId: this.session.a2aSessionId,
            startDate: this.dateUtil.formatDateToString(this.startDate, '-'),
            endDate: this.dateUtil.formatDateToString(this.endDate, '-'),
            policyNumbers: this.getPolicyNumbers()
        }).pipe(
            catchError(() => of(null))
        );
    }

    private getPolicyNumbers(): string[] {
        let agreementNumbers: string[];
        this.httpCachingService.fetchRetrieveCustomerAgreementResponse().subscribe((rcaResponse: PBS.RetriveCustomerAgreementResponse) => {
            const agreementList: PBS.Agreement[] = this.adapter.getPolicies(rcaResponse);
            agreementNumbers = agreementList.map((agreement: PBS.Agreement) =>
                agreement.agreementNumber);
        });
        return agreementNumbers;
    }

    private get startDate(): Date {
        const MONTHS_IN_TERM = 11;
        this._startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth() - MONTHS_IN_TERM, this.endDate.getDate());
        return this._startDate;
    }

    private get endDate(): Date {
        this._endDate = new Date();
        return this._endDate;
    }

    private getInternetRegistrations(): Observable<ECIM.EnterpriseCustomerInformationManagementResponse> {
        return this.ecimService.getInternetRegistrations({
            pageFiredOn: 'landing',
            accessToken: this.session.accessToken,
            guid: this.session.guid
        }).pipe(
            catchError(() => of(null))
        );
    }

    private retrieveBills(): Observable<{ bills: GetBillsResponse[] }> {
        return this.personalBillingService.retrieveBills({
            accountNumber: this.session.billingAccountNumber,
            accessToken: this.session.accessToken
        }).pipe(
            catchError(() => of(null))
        );
    }

    private getAgreement(): Observable<ECIM.Agreement> {
        return this.ecimService.getAgreement({
            isFiredOnMAPLanding: true,
            accessToken: this.session.accessToken,
            guid: this.session.guid,
            accountNumber: this.session.billingAccountNumber,
            sourceSystem: this.session.billingSystem
        }).pipe(
            catchError(() => of(null))
        );
    }

    private retrieveContextCache(contextId: string): Observable<any> {
        const params = {
            accessToken: this.session.accessToken,
            contextId
        };
        return this.contextCacheService.retrieveContext(params)
            .pipe(
                map((response) => response),
                catchError((error: HttpErrorResponse) => of(error))
            );
    }

    get ecn(): string {
        return this.paymentFlowType.isQuickpay ?
            this.quickPayHelperService.getQuickPayEcn() :
            this.session.ecn;
    }

    get billingAccountNumber(): string {
        return this.paymentFlowType.isQuickpay ?
            this.quickPayHelperService.getQuickPayBillingAccountNumber() :
            this.session.billingAccountNumber;
    }
}
