import { Injectable } from '@angular/core';
import { SharedRCAAdapter } from '../../../../../shared/shared-base.adapter';
import { RemoveDuplicatesPipe } from '@nationwide/internet-servicing-angular-pipes';
import { EcimAdapter } from '../../../../../shared/adapters/ecim.adapter';
import { Agreement, RetriveCustomerAgreementResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/portfolio-business-service';
import { EnterpriseCustomerInformationManagementResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/enterprise-customer-information-management';
import { SessionService } from '../../../../../shared/session/session.service';
import { IsbSharedCommonHelperService } from '../../../../../shared/isb-shared-common-helper.service';

@Injectable()
export class ManagePaymentPrefsAdapter extends SharedRCAAdapter {
    // eslint-disable-next-line max-params
    constructor(
        removeDuplicatesPipe: RemoveDuplicatesPipe,
        private ecimAdapter: EcimAdapter,
        session: SessionService,
        protected sharedService: IsbSharedCommonHelperService
    ) {
        super(
            removeDuplicatesPipe,
            session,
            sharedService
        );
    }

    isEligibleForPaperless(internetRegistrationResponse: EnterpriseCustomerInformationManagementResponse): boolean {
        return this.ecimAdapter.getCanSelectPaperlessBilling(internetRegistrationResponse);
    }

    fetchBillingAccount(rca: RetriveCustomerAgreementResponse): Agreement {
        return this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rca));
    }

    fetchBillingAccountNumber(rca: RetriveCustomerAgreementResponse): string {
        const billingAgreemetResponse = this.fetchBillingAccount(rca);
        if (billingAgreemetResponse) {
            return billingAgreemetResponse.agreementNumber;
        }
    }

    fetchMostCurrentEmail(rca: RetriveCustomerAgreementResponse, internetRegistrationResponse: EnterpriseCustomerInformationManagementResponse): string {
        return this.ecimAdapter.getUpdatedEmailAddress(internetRegistrationResponse) || this.mapEmailAddress(rca);
    }

    fetchBillingAccountAmountDue(rca: RetriveCustomerAgreementResponse): string {
        return this.getAmountDueFromRCA(rca);
    }

    fetchBillingAccountAmountDueDate(rca: RetriveCustomerAgreementResponse): Date {
        return this.billingNextDueDate(rca);
    }

    fetchBillingAccountStatementDueDate(rca: RetriveCustomerAgreementResponse): Date {
        return this.billingStatementDueDate(rca);
    }

    checkLifeOnlyAccount(rca: RetriveCustomerAgreementResponse): boolean {
        return this.getLifeOnlyAccount(rca);
    }
}
