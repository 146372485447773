import { Injectable, Inject } from '@angular/core';
import { SessionService } from '../../session/session.service';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from '../../constants/common.constants';
import { Router } from '@angular/router';
import { OAuthConfig, OAuthService, OAuthEmitterService } from '@nationwide/angular-oauth-module';
import { LoggerService } from '../../logger/logger.service';

@Injectable()
export class PingRedirectService {
    // eslint-disable-next-line max-params
    constructor(
        private session: SessionService,
        private router: Router,
        private oAuthService: OAuthService,
        private oAuthEmitterService: OAuthEmitterService,
        private logger: LoggerService,
        @Inject('window') private window: Window
    ) { }

    initiateRedirect(oAuthConfig: OAuthConfig): void {
        const euaUrl = this.oAuthService.getRefreshUrl(oAuthConfig);
        this.session.pingRedirectInfo = {
            startTime: this.currentTimeString,
            callingRoute: this.router.url
        };
        this.window.location.href = euaUrl;
    }

    handleBackFromRedirect(): void {
        if (this.oAuthService.getOauthTokenHashParam()) {
            this.oAuthService.setOauthToken();
            this.oAuthService.setOauthIdToken();
            this.oAuthEmitterService.emitTokenSuccess(this.oAuthService.successEvent);
        } else {
            this.oAuthEmitterService.emitTokenFailure(this.oAuthService.failureEvent);
        }

        this.logger.info(`handleBackFromRedirect is routing to ${this.session.pingRedirectInfo.callingRoute}`);
        this.router.navigateByUrl(this.session.pingRedirectInfo.callingRoute);
    }

    removeOldRedirectInfo(): void {
        this.session.pingRedirectInfo = null;
    }

    get isBackFromRedirect(): boolean {
        return this.isRedirectInfoCurrent && !!this.oAuthService.getOauthTokenHashParam();
    }

    get isAccessTokenStored(): boolean {
        return this.session.accessToken.length > 0;
    }

    private get isRedirectInfoCurrent(): boolean {
        let isCurrent = false;

        if (this.session.pingRedirectInfo) {
            const startTime = parseInt(this.session.pingRedirectInfo.startTime, 10);
            const currentTime = Date.now();
            const difference = currentTime - startTime;
            const minutesUntilRedirect = 20;

            if (difference <= this.minutesToMilliseconds(minutesUntilRedirect)) {
                isCurrent = true;
            } else {
                this.removeOldRedirectInfo();
            }
        }

        return isCurrent;
    }

    get currentTimeString(): string {
        return Date.now().toString();
    }

    private minutesToMilliseconds(minutes: number): number {
        return MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * minutes;
    }
}
