import { CardType } from './card-type.enum';

export const cardConstants: Record<string, { patterns: RegExp[]; type: CardType }> = {
    visa: {
        patterns: [/^4/],
        type: CardType.VISA
    },
    mastercard: {
        patterns: [
            /^51/,
            /^52/,
            /^53/,
            /^54/,
            /^55/
        ],
        type: CardType.MASTERCARD
    },
    americanExpress: {
        patterns: [
            /^34/,
            /^37/
        ],
        type: CardType.AMERICAN_EXPRESS
    },
    discover: {
        patterns: [
            /^6011[02349][0-9]{11}$/,
            /^30[0-5][0-9]{13}$/,
            /^3095[0-9]{12}$/,
            /^38[0-9]{14}$/,
            /^39[0-9]{14}$/,
            /^(352[89]|35[3-8][0-9])[0-9]{12}$/,
            /^(622[1][2-9][6-9]|622[2-8][0-9]{2}|622[9][012][0-5])[0-9]{10}$/,
            /^62[4,5,6][0-9]{13}$/,
            /^628[^019][0-9]{12}$/,
            /^6011[234][0-9]{11}$/,
            /^601174[0-9]{10}$/,
            /^60117[789][0-9]{10}$/,
            /^6011[89][6-9][0-9]{10}$/,
            /^(64[4-9]|65[0-9])[0-9]{13}$/
        ],
        type: CardType.DISCOVER
    }
};
