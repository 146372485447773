import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PaymentPreferencesStateService } from '../../../shared/services/payment-preferences-state/payment-preferences-state.service';

@Injectable()
export class ManagePaymentPreferencesGuard implements CanActivate {
    stateValue: {
        [x: string]: () => any;
        savedpaymentmethods?: () => string; refundmethod?: () => boolean;
        automaticpayments?: () => boolean;
    };

    constructor(
        private router: Router,
        private state: PaymentPreferencesStateService) {
        this.stateValue = {
            savedpaymentmethods: (): string => this.state.currentPaymentMethodId,
            refundmethod: (): boolean => this.state.eligibleForRefundMethod,
            automaticpayments: (): boolean => this.state.eligibleForAutomaticPayments
        };
    }

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | UrlTree {
        const managementType = this.getManagementType(routerState);
        return !!this.stateValue[managementType]() || this.router.parseUrl('/personal/error/system-down');
    }

    getManagementType(routerState: RouterStateSnapshot): string {
        let output = '';
        const urlParts = routerState.url.split('/');

        if (urlParts.length > 1) {
            const threeChar = 3;
            const managementType = urlParts[urlParts.length - threeChar];
            output = this.stripDashes(managementType);
        }

        return output;
    }

    stripDashes(url: string): string {
        return url.replace(/-/g, '');
    }
}
