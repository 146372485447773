<authenticated-header [headerData]="headerData" (linkClick)="onLinkClick($event)"></authenticated-header>
<app-global-nav-wrapper></app-global-nav-wrapper>
<nav aria-label="Bread crumbs Top">
    <is-bread-crumbs [breadCrumbs]="crumbData" [homeIconLink]="homeIconLink" [currentPage]="currentPage" position="top">
    </is-bread-crumbs>
</nav>

<router-outlet></router-outlet>

<nav aria-label="Bread crumbs Bottom">
    <is-bread-crumbs [breadCrumbs]="crumbData" [homeIconLink]="homeIconLink" [currentPage]="currentPage" position="bottom">
    </is-bread-crumbs>
</nav>