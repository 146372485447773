import { Component, Input } from '@angular/core';
import { Row } from './table-row.api';

@Component({
    selector: 'app-confirmation-details-table',
    templateUrl: './confirmation-details-table.component.html',
    styleUrls: ['./confirmation-details-table.component.scss']
})
export class ConfirmationDetailsTableComponent {
    @Input() rows: Row[] = [];
    @Input() tableDataClass: string;
}
