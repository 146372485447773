<fieldset id="bank-account-info-form-controls" [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <app-payment-sub-header>Account information</app-payment-sub-header>
    <div class="radio-container" *ngIf="plaidEnabled">
        <b class="bolt-heading">Select an option for adding your bank account</b>
        <app-form-control-error id="account-info-errors" *ngIf="bankAccountInfoErrorDisplayable">
            <ul class="error-list">
                <li *ngIf="bankAccountInfo.errors['required']">
                    Select an option for adding your bank account.
                </li>
            </ul>
        </app-form-control-error>
        <bolt-radio-group [label]="" required ngDefaultControl id="bankAccountInfo" name="bankAccountInfo" formControlName="bankAccountInfo" optionaltext="hide"
            (change)="onChangeBankAccountInfoSelect($event)" [invalid]="bankAccountInfoErrorDisplayable ? true : null" class="jm-visible">
            <bolt-radio *ngIf="!manualAccountDisabled" value="ManualAccount">
                Add your bank account manually
            </bolt-radio>
            <bolt-radio value="DirectAccount">
                Directly link your bank account
            </bolt-radio>
        </bolt-radio-group>
        <ng-container *ngIf="bankAccountInfo.value">
            <div class="bolt-space-bottom-xl">
                <app-bank-account-info-manual *ngIf="bankAccountInfo.value === 'ManualAccount' && !manualAccountDisabled" [plaidOption]="true"></app-bank-account-info-manual>
                <app-bank-account-info-plaid *ngIf="bankAccountInfo.value === 'DirectAccount'"></app-bank-account-info-plaid>
            </div>
        </ng-container>
    </div>
    <div *ngIf="!plaidEnabled">
        <app-bank-account-info-manual></app-bank-account-info-manual>
    </div>
</fieldset>