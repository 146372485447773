import { Injectable } from '@angular/core';
import { IsbExperience, PBS } from '@nationwide/dgs-angular-billing-common';
import { AreYouSureFormGroup } from '../../../../../../shared/payments/are-you-sure-radio-buttons/are-you-sure-form-group';

@Injectable()
export class CancelAutomaticPaymentsFlowService {
    apiCallSuccessful: boolean;
    apiResponseCode: string;
    currentPayPlan: IsbExperience.GetPayPlanResponse;
    rcaResponse: PBS.RetriveCustomerAgreementResponse;
    cancelAutomaticPaymentsForm: AreYouSureFormGroup;
    emailAddress: string;
    preferredMethod: boolean;
    paymentPlan: string;

    get flowInPlay(): boolean {
        return this.cancelAutomaticPaymentsForm !== undefined;
    }

    save(params: Partial<CancelAutomaticPaymentsFlowService>): void {
        this.cancelAutomaticPaymentsForm = params.cancelAutomaticPaymentsForm || this.cancelAutomaticPaymentsForm;
        this.apiCallSuccessful = params.apiCallSuccessful == null ? this.apiCallSuccessful : params.apiCallSuccessful;
        this.currentPayPlan = params.currentPayPlan == null ? this.currentPayPlan : params.currentPayPlan;
        this.rcaResponse = params.rcaResponse == null ? this.rcaResponse : params.rcaResponse;
        this.apiResponseCode = params.apiResponseCode || this.apiResponseCode;
        this.emailAddress = params.emailAddress || this.emailAddress;
        this.preferredMethod = params.preferredMethod || this.preferredMethod;
        this.paymentPlan = params.paymentPlan || this.paymentPlan;
    }

    clear(): void {
        this.cancelAutomaticPaymentsForm = undefined;
        this.apiCallSuccessful = undefined;
        this.currentPayPlan = undefined;
        this.rcaResponse = undefined;
        this.apiResponseCode = undefined;
        this.emailAddress = undefined;
        this.preferredMethod = undefined;
        this.paymentPlan = undefined;
    }
}
