import { PaymentItem } from '../../shared/models/payment-item.model';
import { CardType } from '../../../../shared/payments/card-utils/card-type.enum';
import { PaymentPrefsSectionPlaceholder } from '../../shared/models/placeholder.model';
import { PaymentPreferencesAccordion } from '../../shared/models/acordion.model';

export type EnrollmentStatus = 'canEnroll' | 'cannotEnroll' | 'enrollmentDisabled';

export class AutomaticPayments {
    // eslint-disable-next-line max-params
    constructor(
        readonly accordion: AutomaticPaymentsAccordion,
        readonly placeholderMessage: PaymentPrefsSectionPlaceholder,
        readonly infoMessages: string[],
        readonly warningMessages: string[],
        readonly disclaimer: string[],
        readonly canSuspend: boolean,
        readonly enrollmentStatus: EnrollmentStatus,
        readonly authAgreementDisplayable: boolean,
        readonly creationDate: string,
        readonly isAccountWarned: boolean
    ) { }

    static create(params: CreateAutomaticPaymentsParams): AutomaticPayments {
        return new AutomaticPayments(
            params.accordion || null,
            params.placeholderMessage || null,
            params.infoMessages || null,
            params.warningMessages || null,
            params.disclaimer || null,
            params.canSuspend || false,
            params.enrollmentStatus || 'cannotEnroll',
            params.authAgreementDisplayable || false,
            params.creationDate || new Date().toDateString(),
            params.isAccountWarned || false
        );
    }

    get accordionDisplayable(): boolean {
        return !!this.accordion;
    }

    get accordionHeader(): string {
        return this.accordion.header;
    }

    get accordionSubHeader(): string {
        return this.accordion.subHeader;
    }

    get placeholderMessageDisplayable(): boolean {
        return !!this.placeholderMessage;
    }

    get infoMessagesDisplayable(): boolean {
        return !!(this.infoMessages && this.infoMessages.length);
    }

    get warningMessagesDisplayable(): boolean {
        return !!(this.warningMessages && this.warningMessages.length);
    }

    get payorName(): string {
        return this.accordion ? this.accordion.payorName : '';
    }

    get enrolledWithBankAccount(): boolean {
        return this.accordion ? this.accordion.enrolledWithBankAccount : false;
    }

    get bankName(): string {
        return this.accordion ? this.accordion.bankName : '';
    }

    get bankAccountType(): string {
        return this.accordion ? this.accordion.bankAccountType : '';
    }

    get bankRoutingNumber(): string {
        return this.accordion ? this.accordion.bankRoutingNumber : '';
    }

    get bankAccountNumber(): string {
        return this.accordion ? this.accordion.bankAccountNumber : '';
    }

    get enrolledWithBankCard(): boolean {
        return this.accordion ? this.accordion.enrolledWithBankCard : false;
    }

    get cardBrand(): CardType {
        return this.accordion ? this.accordion.cardBrand : CardType.INVALID;
    }

    get cardZipCode(): string {
        return this.accordion ? this.accordion.cardZipCode : '';
    }

    get cardLastFour(): string {
        return this.accordion ? this.accordion.cardLastFour : '';
    }

    get cardExpiration(): Date {
        return this.accordion ? this.accordion.cardExpiration : new Date(NaN);
    }

    get enrolledInSmartMiles(): boolean {
        return !!this.accordion && this.accordion.hasSmartMiles;
    }

    get canEdit(): boolean {
        return !!this.accordion && this.accordion.canEdit;
    }

    get canCancel(): boolean {
        return !!this.accordion && this.accordion.canCancel;
    }

    get warnedAccountForEnrolledAutoPay(): boolean {
        return this.accordion.isAccountWarned;
    }

    get warnedAccount(): boolean {
        return this.isAccountWarned;
    }

    get canEnroll(): boolean {
        return this.enrollmentStatus === 'canEnroll';
    }

    get enrollmentDisabled(): boolean {
        return this.enrollmentStatus === 'enrollmentDisabled';
    }
}

export interface CreateAutomaticPaymentsParams {
    accordion?: AutomaticPaymentsAccordion;
    placeholderMessage?: PaymentPrefsSectionPlaceholder;
    infoMessages?: string[];
    warningMessages?: string[];
    disclaimer?: string[];
    canSuspend?: boolean;
    enrollmentStatus?: EnrollmentStatus;
    authAgreementDisplayable?: boolean;
    creationDate?: string;
    isAccountWarned?: boolean;
}

export class AutomaticPaymentsAccordion extends PaymentPreferencesAccordion {
    // eslint-disable-next-line max-params
    constructor(
        header: string,
        readonly subHeader: string,
        readonly canCancel: boolean,
        readonly canEdit: boolean,
        readonly payMethod: PaymentItem,
        readonly hasSmartMiles: boolean,
        readonly isAccountWarned: boolean
    ) {
        super(header, payMethod);
    }

    static create(params: CreateAutomaticPaymentsAccordionParams): AutomaticPaymentsAccordion {
        return new AutomaticPaymentsAccordion(
            params.header || '',
            params.subHeader || '',
            params.canCancel || false,
            params.canEdit || false,
            params.payMethod || null,
            params.hasSmartMiles || false,
            params.isAccountWarned || false
        );
    }

    get enrolledWithBankAccount(): boolean {
        return this.isBankAccount;
    }

    get enrolledWithBankCard(): boolean {
        return this.isBankCard;
    }
}

export interface CreateAutomaticPaymentsAccordionParams {
    header?: string;
    subHeader?: string;
    canCancel?: boolean;
    canEdit?: boolean;
    payMethod?: PaymentItem;
    hasSmartMiles?: boolean;
    isAccountWarned?: boolean;
}
