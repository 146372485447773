<is-wait-message [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<div *ngIf="!hasError" id="timeline-container" class="timeline-widget-external" [class.has-error]="hasError" [class.load-complete]="loadComplete">
    <section id="page-content" class="page-content">
        <btl-timeline-scrollable *ngIf="!hasError && loadComplete" [billingHistoryUrl]="billingHistoryUrl" [paymentUrl]="paymentUrl" [timeline]="timeline">
        </btl-timeline-scrollable>
    </section>
</div>

<warning-notification *ngIf="loadComplete && hasError && !isLife">
    An error occurred while trying to load this account.
</warning-notification>
<warning-notification *ngIf="loadComplete && hasError && isLife">Billing Details are not available for Billing Accounts containing Life policies. Please use the Billing Account
    History.</warning-notification>