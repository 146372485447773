import { Injectable, Inject } from '@angular/core';
import { isbExperienceServiceToken, IsbExperienceService, MoneyProcessingService } from '@nationwide/dgs-angular-billing-common';
import { Observable, of, forkJoin, timeout, catchError, tap, flatMap } from 'rxjs';
import { SessionService } from '../../../../../../shared/session/session.service';
import { environment } from '../../../../../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import {
    DistributionPartnerAPI
} from '@nationwide/internet-servicing-dpim-requests';
import { submitPaymetCardTypeMap, CardTypeShortHand } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { CancelAutomaticPaymentsFlowService } from '../shared/cancel-automatic-payments-flow.service';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { CancelAndSuspendAutomaticPaymentLanding } from '../../../../shared/services/payment-preferences-api/payment-preferences-api-calls.model';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { BillingMethodType } from '@nationwide/dgs-angular-billing-common/api-response-types/personal-billing/pay-plan';
import { ElectronicFundsTransfer, PayorInfo } from '@nationwide/dgs-angular-billing-common/api-response-types/personal-billing';
import { BankCard } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience/personal-lines-billing/pay-plan/pay-plan';
import { OmsResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { DistributionPartnerRoleInfoService } from '../../../../../shared/distribution-partner-role-info/distribution-partner-role-info.service';
import { DistributionPartnerRoleInfoAdapter } from '../../../../../../billing/shared/distribution-partner-role-info/distribution-partner-role-info.adapter';
@Injectable()
export class CancelAutomaticPaymentsLandingService {
    // eslint-disable-next-line max-params
    constructor(
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private moneyProcessing: MoneyProcessingService,
        private session: SessionService,
        private flowService: CancelAutomaticPaymentsFlowService,
        private paymentPreferencesApi: PaymentPreferencesApiService,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private distributionPartnerRoleInfoService: DistributionPartnerRoleInfoService,
        private distributionPartnerRoleInfoAdapter: DistributionPartnerRoleInfoAdapter,
        @Inject('logger') private logger: LoggerService
    ) { }

    fetchLanding(): Observable<CancelAndSuspendAutomaticPaymentLanding | any> {
        return this.paymentPreferencesApi.cancelAndSuspendAutomaticPaymentLanding().pipe(
            flatMap((response) =>
                forkJoin({
                    res: of(response),
                    savedPaymentMethodID: this.isSap ?
                        this.paymentPreferencesApi.getSingleSavedPaymentMethod(response.automaticPayments.paymentMethod.savedPaymentMethodID) : of(null)
                })
            ),
            tap(({ res, savedPaymentMethodID }) =>
                this.flowService.save({
                    currentPayPlan: res.automaticPayments,
                    rcaResponse: res.retrieveCustomerAgreement,
                    emailAddress: this.managePaymentPrefsAdapter.fetchMostCurrentEmail(res.retrieveCustomerAgreement, res.internetRegistrationResponse),
                    preferredMethod: savedPaymentMethodID ? savedPaymentMethodID.preferredMethod : false
                }))
        );
    }

    private get isSap(): boolean {
        return this.session.billingSystem === 'SAP Billing';
    }

    get emailAddress(): string {
        return this.flowService.emailAddress;
    }

    submit(): Observable<CancelAutomaticPaymentsApiResponses> {
        return this.cancelAutomaticPayments().pipe(
            tap((responses) => {
                this.distributionPartnerRoleInfoAdapter.mapDistributionPartnerRoleResponse(responses.distributionPartnerRoleInfoResponse);
            }),
            flatMap((patchPayPlanRes) =>
                forkJoin({
                    patchPayPlan: of(patchPayPlanRes.patchPayPlan),
                    cancelAutomaticPaymentsEmail: this.flowService.apiCallSuccessful && this.session.isNBP ?
                        this.cancelAutomaticPaymentsEmail() : this.requestPlaceholder()
                })
            )
        );
    }

    cancelAutomaticPayments(): Observable<{
        patchPayPlan: number | HttpErrorResponse;
        distributionPartnerRoleInfoResponse: DistributionPartnerAPI.DpimRoleInfoResponse | HttpErrorResponse;
    }> {
        return forkJoin({
            patchPayPlan: this.patchPayPlan(),
            distributionPartnerRoleInfoResponse: this.distributionPartnerRoleInfoService.fetchDistributionPartnerRoleInfo()
        });
    }

    private patchPayPlan(): Observable<number | HttpErrorResponse> {
        return this.billingService.patchPayPlan({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            agreementNumber: this.session.billingAccountNumber,
            payload: {
                updatedEmail: this.flowService.emailAddress,
                billingPayload: {
                    paymentMethodId: this.currentPaymentMethodId,
                    enterpriseCustomerNumber: this.session.ecn,
                    requestType: 'BillingMethodChange.PayPlanUpdate',
                    action: 'update',
                    currentBillingMethodType: this.currentBillingMethodType,
                    newBillingMethodType: 'Direct',
                    accountDueDate: '',
                    paymentMethod: {
                        preferredMethod: this.flowService.preferredMethod,
                        paymentMethodType: this.paymentMethodType,
                        payorInfo: this.currentPayorInfo,
                        electronicFundsTransfer: this.isEft ? {
                            bankName: this.eft.bankName,
                            bankAccountType: this.bankAccountType,
                            bankRoutingNumber: this.eft.bankRoutingNumber,
                            encryptedBankAccountNumber: this.eft.encryptedBankAccountNumber,
                            maskedBankAccountNumber: this.eft.maskedBankAccountNumber
                        } : undefined,
                        bankCard: this.isBankCard ? {
                            encryptedPan: this.bankCard.encryptedPan,
                            encryptedPanKey: this.bankCard.encryptedPanKey,
                            expirationDate: this.expirationDate,
                            ccLastFour: this.bankCard.ccLastFour,
                            cardBrand: <any> this.cardBrand
                        } : undefined,
                        description: 'UNSET'
                    }
                }
            }
        }).pipe(
            tap(() => this.flowService.save({ apiCallSuccessful: true })),
            timeout(environment.TIMEOUT.apiCallDuration.submitPayment),
            catchError((err) => {
                this.logger.error('API ERROR: CANCEL AUTOMATIC PAYMENTS SUBMISSION', err);
                this.flowService.save({ apiCallSuccessful: false });
                return of(err);
            })
        );
    }

    private cancelAutomaticPaymentsEmail(): Observable<OmsResponse> {
        return this.isbExperience.sendCancelAutomaticPaymentsEmail({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            updatedEmail: this.flowService.emailAddress,
            omsData: {
                omsServiceTransformationStrategyData: {
                    servicePlusIndicator: this.session.dpimServicePlusIndicator,
                    agentPhoneNumber: this.session.dpimAgentPhoneNumber
                }
            }
        }).pipe(
            catchError((err) => of(err))
        );
    }

    private get currentPaymentMethodId(): string {
        return this.flowService.currentPayPlan.paymentMethod.savedPaymentMethodID;
    }

    private get currentBillingMethodType(): BillingMethodType {
        return this.flowService.currentPayPlan.billingMethodType;
    }

    private get paymentMethodType(): 'ElectronicFundsTransfer.PaymentMethod' | 'BankCard.PaymentMethod' {
        return this.isEft ? 'ElectronicFundsTransfer.PaymentMethod' : 'BankCard.PaymentMethod';
    }

    private get isEft(): boolean {
        return this.flowService.currentPayPlan.billingMethodType === 'Recurring EFT';
    }

    private get eft(): ElectronicFundsTransfer {
        return this.flowService.currentPayPlan.paymentMethod.electronicFundsTransfer;
    }

    private get bankAccountType(): string {
        let accountType = '';

        if (this.eft.bankAccountType.toLowerCase() === 's') {
            accountType = 'SAVINGS';
        } else if (this.eft.bankAccountType.toLowerCase() === 'c') {
            accountType = 'CHECKING';
        }

        return accountType;
    }

    private get isBankCard(): boolean {
        return this.flowService.currentPayPlan.billingMethodType === 'Recurring Bankcard';
    }

    private get bankCard(): BankCard {
        return this.flowService.currentPayPlan.paymentMethod.bankCard;
    }

    private get cardBrand(): string {
        return submitPaymetCardTypeMap.get(<CardTypeShortHand> this.bankCard.cardBrand);
    }

    private get expirationDate(): string {
        const [month, year] = this.bankCard.expirationDate.split('/');
        return `${year}-${month}`;
    }

    private get currentPayorInfo(): PayorInfo {
        return this.flowService.currentPayPlan.paymentMethod.payorInfo;
    }

    private get billingService(): IsbExperienceService | MoneyProcessingService {
        const service: IsbExperienceService | MoneyProcessingService = this.session.billingSystem === 'NBP' ? this.moneyProcessing : this.isbExperience;
        return service;
    }

    private requestPlaceholder(): Observable<any> {
        return of(undefined);
    }
}

export interface CancelAutomaticPaymentsApiResponses {
    patchPayPlan: number | HttpErrorResponse | Error;
    cancelAutomaticPaymentsEmail: OmsResponse | HttpErrorResponse | Error;
}
