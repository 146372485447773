import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { InternetServicingAngularComponentsModule, InterpolationComponent } from '@nationwide/internet-servicing-angular-components';
import { SharedBillingComponentsModule } from '@nationwide/dgs-angular-billing-common';
import { BillingTabsComponent } from './billing-tabs/billing-tabs.component';
import { DocOpenerComponent } from './docs/doc-opener/doc-opener.component';
import { ImageLoaderComponent } from './image-loader/image-loader.component';
import { FocusOnInitDirective } from './attr-directives/focus-on-init.directive';
import { FocusOnRouteChangeDirective } from './attr-directives/focus-on-route-change.directive';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ButtonContainerV2Component } from './button-container-v2/button-container-v2.component';
import { SharedPaymentsModule } from './payments/shared-payments.module';
import { NamePipe } from './payments/name-field-set/name.pipe';
import { LoggerService } from './logger/logger.service';
import { GlobalNavModule } from '@nationwide/internet-servicing-global-navigation';
import { GlobalNavWrapperComponent } from './global-nav-wrapper/global-nav-wrapper.component';
import { PolicyRequestsModule } from '@nationwide/dgs-internet-servicing-policy-requests';
import { BoltWrapperModule } from '@nationwide/internet-servicing-bolt-components';

@NgModule({
    imports: [
        CommonModule,
        InternetServicingAngularComponentsModule,
        SharedBillingComponentsModule,
        NgbDatepickerModule,
        FormsModule,
        SharedPaymentsModule,
        GlobalNavModule,
        PolicyRequestsModule,
        BoltWrapperModule
    ],
    declarations: [
        BillingTabsComponent,
        DocOpenerComponent,
        ImageLoaderComponent,
        FocusOnInitDirective,
        FocusOnRouteChangeDirective,
        LoadingSpinnerComponent,
        ButtonContainerV2Component,
        GlobalNavWrapperComponent
    ],
    exports: [
        BillingTabsComponent,
        DocOpenerComponent,
        ImageLoaderComponent,
        FocusOnInitDirective,
        FocusOnRouteChangeDirective,
        LoadingSpinnerComponent,
        ButtonContainerV2Component,
        SharedPaymentsModule,
        InterpolationComponent,
        GlobalNavWrapperComponent
    ],
    providers: [
        NamePipe,
        SharedPaymentsModule,
        LoggerService
    ]
})
export class ISBSharedComponentsModule { }
