import { Injectable } from '@angular/core';
import { EftAuthorizationFormEbiData } from '../../service/billing-api-common.service';
@Injectable()
export class EftAuthorizationFlowService {
    private _apiCallFailed: boolean;
    private _apiResponseCode: string;
    private _eftAuthorizationFormEbiData: EftAuthorizationFormEbiData;
    get apiCallFailed(): boolean {
        return this._apiCallFailed;
    }

    set apiCallFailed(value: boolean) {
        this._apiCallFailed = value;
    }
    get apiResponseCode(): string {
        return this._apiResponseCode;
    }
    set apiResponseCode(value: string) {
        this._apiResponseCode = value;
    }

    get eftAuthorizationFormEbiData(): EftAuthorizationFormEbiData {
        return this._eftAuthorizationFormEbiData;
    }
    set eftAuthorizationFormEbiData(value: EftAuthorizationFormEbiData) {
        this._eftAuthorizationFormEbiData = value;
    }
}
