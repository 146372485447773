<fieldset class="bank-card-expiration-fieldset bolt-space-bottom-xl" [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <span class="bolt-heading">{{labelText}}</span>
    <div class="bolt-row">
        <div class="bolt-col-md-5 expiration-row">
            <div class="select-50">
                <bw-bolt-select [label]='""' [invalid]='(expirationMonthErrorsDisplayable && expirationMonthUnselected)
					|| (!isInitialState && !isValidExpiryDate)' [placeholder]='placeholderMonth' [optionalText]='optionalText' id="card-expiration-month" ngDefaultControl formControlName="cardExpirationMonth"
                    (change)="onMonthSelectChange($event)" [ngModelOptions]="{standalone: true}" class="nwpii">
                    <option *ngFor="let month of cardMonthList" [ngValue]="month" [selected]="expirationMonth.value === month">
                        {{month}}
                    </option>
                </bw-bolt-select>
            </div>
            <div class="date-divider">/</div>
            <div class="select-50">
                <bw-bolt-select [label]='""' [invalid]='(expirationYearErrorsDisplayable && expirationYearUnselected) ||
					(!isInitialState && !isValidExpiryDate)' [placeholder]='placeholderYear' [optionalText]='optionalText' id="card-expiration-year" ngDefaultControl formControlName="cardExpirationYear"
                    (change)="onYearSelectChange($event)" [ngModelOptions]="{standalone: true}" class="nwpii">
                    <option *ngFor=" let year of cardYearList" [ngValue]="year" [selected]="expirationYear.value === year">
                        {{year}}
                    </option>
                </bw-bolt-select>
            </div>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-md-5">
            <app-form-control-error id="card-expiration-errors" *ngIf="expirationMonthErrorsDisplayable || expirationYearErrorsDisplayable
				|| (!isInitialState && !isValidExpiryDate)">
                <ul class="error-list">
                    <li *ngIf="expirationMonthUnselected || expirationYearUnselected">Expiration
                        date is required.</li>
                    <li *ngIf="(!expirationMonthUnselected && !expirationYearUnselected) &&
						!isValidExpiryDate">
                        Expiration date is invalid.</li>
                </ul>
            </app-form-control-error>
        </div>
    </div>
</fieldset>