import { CancelScheduledFuturePaymentLandingComponent } from './cancel/landing/cancel-scheduled-future-payment-landing.component';
import { CancelScheduledFuturePaymentConfirmationComponent } from './cancel/confirmation/cancel-scheduled-future-payment-confirmation.component';
import { ManagePaymentPreferencesPageTitle } from '../shared/models/manage-payment-preferences-page-title.constant';
import { CancelScheduleFuturePaymentConfirmationGuard } from './cancel/guards/cancel-scheduled-future-payment-confirmation-guard';
import { CancelFutureSchedulePaymentLandingGuard } from './cancel/guards/cancel-scheduled-future-payment-landing-guards';
import { CancelScheduleFuturePaymentComponent } from './cancel/cancel-schedule-future-payment.component';
import { StateClearingGuard } from '../../../../shared/service/guards/state-clearing-guard/state-clearing.guard';

export const ScheduledFuturePaymentsRoutes = [
    {
        path: 'scheduled-future-payments/cancel',
        component: CancelScheduleFuturePaymentComponent,
        children: [
            {
                path: 'landing',
                component: CancelScheduledFuturePaymentLandingComponent,
                data: { title: ManagePaymentPreferencesPageTitle.scheduledFuturePayments.cancel.landing },
                canActivate: [CancelFutureSchedulePaymentLandingGuard]
            },
            {
                path: 'confirmation',
                component: CancelScheduledFuturePaymentConfirmationComponent,
                data: { title: ManagePaymentPreferencesPageTitle.scheduledFuturePayments.cancel.confirmation },
                canActivate: [CancelScheduleFuturePaymentConfirmationGuard],
                canDeactivate: [StateClearingGuard]
            }
        ]
    }
];
