import { Component, Input, ViewEncapsulation, Inject, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { isbExperienceServiceToken, IsbExperienceService } from '@nationwide/dgs-angular-billing-common';
import { SessionService } from '../../session/session.service';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';
import { environment } from '../../../../../src/environments/environment';

export type ControlNames = 'bankName' | 'routingNumber' | 'accountNumber' | 'confirmAccountNumber';

@Component({
    selector: 'app-bank-account-number-form-controls',
    templateUrl: './bank-account-number-form-controls.component.html',
    styleUrls: [
        './bank-account-number-form-controls.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class BankAccountNumberFormControlsComponent extends BaseFormGroupComponent<ControlNames> implements OnInit, OnChanges {
    @Input() readonly: boolean;
    @Input() routingNumberServiceFailureMessaging: 'validRoutingNumber' | 'required' = 'validRoutingNumber';
    @Input() plaidAccountNumber: string;
    @Input() plaidRoutingNumber: string;
    @Input() plaidResponse: boolean;
    prod = false;
    maskedAccountNumber = '';

    constructor(
        controlContainer: ControlContainer,
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private session: SessionService
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        if (this.plaidResponse) {
            this.formGroup['controls'].routingNumber.patchValue(this.plaidRoutingNumber);
            this.formGroup['controls'].accountNumber.patchValue(this.plaidAccountNumber);
            this.formGroup['controls'].confirmAccountNumber.patchValue(this.plaidAccountNumber);
            this.maskedAccountNumber = this.maskPlaidAccountNumber(this.plaidAccountNumber);
            this.fetchBankName();
            if (environment.PRODUCTION) {
                this.prod = true;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.plaidRoutingNumber) {
            this.formGroup['controls'].routingNumber.patchValue(changes.plaidRoutingNumber.currentValue);
            this.fetchBankName();
        }
        if (changes.plaidAccountNumber) {
            this.formGroup['controls'].accountNumber.patchValue(changes.plaidAccountNumber.currentValue);
            this.formGroup['controls'].confirmAccountNumber.patchValue(changes.plaidAccountNumber.currentValue);
            this.maskedAccountNumber = this.maskPlaidAccountNumber(changes.plaidAccountNumber.currentValue);
        }
    }

    maskPlaidAccountNumber(accNumber: string): string {
        const lastThreeDigits = 3;
        let accountNumber = '';
        if (accNumber) {
            accountNumber = `*******${accNumber.slice(accNumber.length - lastThreeDigits)}`;
        }
        return accountNumber;
    }

    get routingNumber(): AbstractControl {
        return this.getControl('routingNumber');
    }

    get routingNumberErrorDisplayable(): boolean {
        return this.errorsDisplayable('routingNumber');
    }

    get accountNumber(): AbstractControl {
        return this.getControl('accountNumber');
    }

    get accountNumberErrorDisplayable(): boolean {
        return this.errorsDisplayable('accountNumber');
    }

    get confirmAccountNumber(): AbstractControl {
        return this.getControl('confirmAccountNumber');
    }

    get confirmAccountNumberErrorDisplayable(): boolean {
        return this.errorsDisplayable('confirmAccountNumber');
    }

    fetchBankName(): void {
        if (this.readonly) {
            return;
        }

        if (this.getControl('routingNumber').valid) {
            this.isbExperience.getBank({
                billingSystem: this.session.setCyberLifeBillingSystem(),
                accessToken: this.session.accessToken,
                routingNumber: this.getControl('routingNumber').value
            }).subscribe(
                (response) => {
                    this.bankName.setValue(response?.fullName || '');
                }, (error) => {
                    const invalidRoutingNumberStatus = 404;
                    if (error.status === invalidRoutingNumberStatus) {
                        this.routingNumber.setErrors({ validRoutingNumber: true });
                    } else {
                        this.routingNumber.setErrors({ serviceDown: true, validRoutingNumber: true });
                    }
                    this.bankName.setValue('');
                });
        } else {
            this.bankName.setValue('');
        }
    }

    get bankName(): AbstractControl {
        return this.getControl('bankName');
    }

    get displayRequiredError(): boolean {
        return this.routingNumber.errors['required'] ||
            this.routingNumberServiceFailureMessaging === 'required' &&
            this.routingNumber.errors['validRoutingNumber'] &&
            this.routingNumber.errors['serviceDown'];
    }

    get displayInvalidError(): boolean {
        return (this.routingNumber.errors['validRoutingNumber'] ||
            this.routingNumber.errors['minlength'] ||
            this.routingNumber.errors['containsOnlyDigits']) &&
            !this.displayRequiredError;
    }
}
