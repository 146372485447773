<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>
<div id="confirmation-page" *ngIf="!unexpectedNavigationEncountered" class="manage-payment-form-container">
    <div class="bolt-container bolt-space-bottom-2xl">

        <bw-bolt-progress-bar appFocusOnInit arialabel="Add payment method progress value is 100%" progressBarValue="100" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Add payment method: confirmation</h1>

        <ng-container *ngIf="!addPaymentMethodError && isRCACallProcessed">

            <bw-bolt-notification *ngIf="displayVulCreditCardMessage" class="to-container bolt-space-bottom-2xl" type="success" id="vulCreditCardSuccessMessage">
                <is-interpolate [parent]="vulCCMessageTridionContent.variables" [text]="vulCCMessageTridionContent.content"></is-interpolate>
            </bw-bolt-notification>
            <bw-bolt-notification *ngIf="!displayVulCreditCardMessage" class="to-container bolt-space-bottom-2xl" type="success" id="requestProcessed">
                Your request has been processed.
            </bw-bolt-notification>
            <app-payment-sub-header>Confirmation details</app-payment-sub-header>
        </ng-container>
        <ng-container>
            <bw-bolt-notification *ngIf="paymentMethodAlreadyExists" class="to-container bolt-space-bottom-2xl" id="paymentExistsErrorNotify" type="error">
                The payment method you are trying to add already exists.
            </bw-bolt-notification>
            <bw-bolt-notification *ngIf="paymentMethodLimitReached" class="to-container bolt-space-bottom-2xl" type="error" id="paymentLimitErrorNotify">
                You've reached the maximum number of saved payment methods.
                You can have up to 10 bank cards and up to 10 bank accounts. If you would like to save this
                payment method, please delete one or more saved preferences.
            </bw-bolt-notification>
            <bw-bolt-notification *ngIf="apiCallFailed" class="to-container bolt-space-bottom-2xl" type="error" id="apiCallFailError">
                {{ apiServiceDownMessage }}
            </bw-bolt-notification>
        </ng-container>

        <ng-container>
            <app-saved-payment-confimation-details [paymentMethod]="addPaymentConfirmationDetail" [addPaymentMethodError]="addPaymentMethodError"
                [successfulRefundMethodEnrollments]="successfulRefundMethodEnrollments" [unsuccessfulRefundMethodEnrollments]="unsuccessfulRefundMethodEnrollments"
                [wasPreferenceEsddaEnrollmentSuccessful]="wasPreferenceEsddaEnrollmentSuccessful" [isPaperlessEnrollmentUnsuccessful]="isPaperlessEnrollmentUnsuccessful" [errorMessage]="errorMessage">
            </app-saved-payment-confimation-details>
        </ng-container>
    </div>

    <bolt-button-bar>
        <bolt-button id="back-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
        <bolt-button id="submit-button" slot="forward" (click)="onContinue()" type="primary">
            {{upNextLabel || 'Payment preferences'}}
        </bolt-button>
    </bolt-button-bar>
</div>