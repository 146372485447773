<is-wait-message [useBolt]="false" [loadComplete]="canLoadApplication" [waitMessage]="'Please wait while we process your request.'" *ngIf="!shouldHideWaitMessage">
</is-wait-message>

<div *ngIf="canLoadApplication" class="global-container" id="global-container">
    <div class="outer-container" autoscroll="true">
        <router-outlet></router-outlet>
    </div>
    <div *ngIf="isLoadedByBAM !== true">
        <is-footer [footerData]="footer"></is-footer>
    </div>
</div>
<app-oauth *ngIf="(!isLoadedByExternalApplication && !skipOAuth) || (isLoadedByExternalApplication && isOauthRequired)" style="display: none"></app-oauth>