import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RefundMethodRoutes } from './refund-method/refund-methods-routes';
import { SavedPaymentMethodRoutes } from './saved-payment-methods/saved-payment-method-routes';
import { AutomaticPaymentsRoutes } from './automatic-payments/automatic-payments-routes';
import { ManagePaymentPreferencesBaseComponent } from './base/manage-payment-preferences-base.component';
import { ScheduledFuturePaymentsRoutes } from './scheduled-future-payments/scheduled-future-payments-routes';
import { PaymentPlanRoutes } from './payment-plan/payment-plan-routes';

const routes: Routes = [
    {
        path: '',
        component: ManagePaymentPreferencesBaseComponent,
        children: [
            ...AutomaticPaymentsRoutes,
            ...SavedPaymentMethodRoutes,
            ...RefundMethodRoutes,
            ...ScheduledFuturePaymentsRoutes,
            ...PaymentPlanRoutes
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManagePaymentPreferencesRoutingModule { }
