import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    CardEncryptionHelperService, ContentfulMapperService, ContentfulRawDataItem, EncryptBankCardResponse, IsbExperience, IsbExperienceService,
    isbExperienceServiceToken, MoneyProcessingService,
    PostSavedPaymentMethodBankCard
} from '@nationwide/dgs-angular-billing-common';
import { catchError, flatMap, forkJoin, map, Observable, of, tap, timeout } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { PreferenceCenterPaperlesService } from '../../../../../../pay-bill/shared/preference-center-paperless.service';
import { IsbSharedCommonHelperService } from '../../../../../../shared/isb-shared-common-helper.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { encryptionCardTypeMap } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { SessionService } from '../../../../../../shared/session/session.service';
import { PaperlessEnrollmentBaseService } from '../../../../shared/services/paperless-enrollment-error.service';
import { AddPaymentReview } from './add-payment-review.model';

@Injectable()
export class AddPaymentMethodReviewService extends PaperlessEnrollmentBaseService {
    // eslint-disable-next-line max-params
    constructor(
        private review: AddPaymentReview,
        protected session: SessionService,
        @Inject(isbExperienceServiceToken) private isbExperienceApi: IsbExperienceService,
        @Inject(PreferenceCenterPaperlesService) private preferencePaperlesService: PreferenceCenterPaperlesService,
        @Inject('logger') private logger: LoggerService,
        private cardEncryptionHelperService: CardEncryptionHelperService,
        private moneyProcessing: MoneyProcessingService,
        private readonly contentfulMapperservice: ContentfulMapperService,
        private isbSharedCommonHelperService: IsbSharedCommonHelperService
    ) {
        super(session);
    }

    addPaymentMethod(): Observable<AddPaymentMethodServiceResponses> {
        const encryptedResponse = this.review.isBankCard ?
            this.encryptBankCard() : requestPlaceHolder();
        return encryptedResponse.pipe(
            flatMap((encryptResponse: EncryptBankCardResponse | HttpErrorResponse) =>
                forkJoin({
                    savedPaymentMethodResponse: this.paymentMethodSaveAttempt({ encryptResponse }),
                    saveAsRefundMethodResponses: this.review.isEnrollingInRefund ? forkJoin(this.review.saveAsRefundMethodAccounts.map((accountNumber) =>
                        this.enrollInRefundMethod(accountNumber))) : of([]),
                    tridion: this.getContentfulBillingContainer()
                })
            ),
            flatMap((apiResponses) =>
                forkJoin({
                    savedPaymentMethodResponse: of(apiResponses.savedPaymentMethodResponse),
                    saveAsRefundMethodResponses: of(apiResponses.saveAsRefundMethodResponses),
                    customerEmailSentSuccessfully: this.sendCustomerEmail(apiResponses),
                    tridion: of(apiResponses.tridion),
                    esddaPreferences: this.review.enrollingInPaperless === 'Yes' || this.review.enrollingInPaperless === 'No' ? this.enrollInPaperless() : requestPlaceHolder()
                })
            )
        );
    }

    enrollInPaperless(): Observable<boolean> {
        this.review.save({ paperlessEnrollmentAttempted: true });
        return this.preferencePaperlesService.saveEsddaPreferences(this.review.emailAddress, this.review.enrollingInPaperless).pipe(
            tap((response) => {
                if (response) {
                    this.review.save({
                        savedPaperlessEsddaPrefs: true, enrolledInPaperless: true,
                        addPaymentMethodServiceResponses: {
                            esddaPreferences: true
                        }
                    });
                } else {
                    this.review.save({
                        savedPaperlessEsddaPrefs: false, enrolledInPaperless: false,
                        addPaymentMethodServiceResponses: {
                            esddaPreferences: false
                        }
                    });
                }
            }),
            catchError(() => of(false))
        );
    }

    paymentMethodSaveAttempt(params: { encryptResponse?: EncryptBankCardResponse | HttpErrorResponse } = {}): Observable<IsbExperience.PostSavedPaymentMethodResponse | HttpErrorResponse> {
        this.review.save({ paymentMethodSaveAttempted: true });
        let response: Observable<IsbExperience.PostSavedPaymentMethodResponse | HttpErrorResponse>;

        if (params.encryptResponse instanceof HttpErrorResponse) {
            this.review.save(
                {
                    paymentMethodSaved: false,
                    apiCallFailed: true
                });
            response = of(params.encryptResponse);
        } else {
            response = this.isbExperienceApi.postSavedPaymentMethod({
                accessToken: this.session.accessToken,
                billingSystem: this.session.billingSystem,
                sendCustomerEmail: 'true',
                accountNumber: this.session.billingAccountNumber,
                payload: {
                    billingPayload: {
                        enterpriseCustomerNumber: this.session.ecn,
                        preferredMethod: this.review.savePayMethodAsDefault,
                        paymentMethodType: this.review.isBankCard ? 'BankCard.SavedPaymentMethod' : 'ElectronicFundsTransfer.SavedPaymentMethod',
                        payorInfo: this.isbSharedCommonHelperService.truncatePayerInfo(this.review.payorInfo),
                        description: 'UNSET',
                        bankCard: this.review.isBankCard ?
                            this.getBankCardDetails(params) :
                            undefined,
                        electronicFundsTransfer: this.review.isBankAccount ? {
                            bankName: this.review.bankName,
                            bankRoutingNumber: this.review.routingNumber,
                            bankAccountNumber: this.review.accountNumber,
                            bankAccountType: this.review.accountType.toUpperCase(),
                            maskedBankAccountNumber: this.review.accountNumberLastThree
                        } : undefined,
                        accounts: this.review.isEnrollingInRefund ? {
                            billingAccountRefundMethodList: this.review.saveAsRefundMethodAccounts.map((accountNumber) => ({ accountNumber }))
                        } : undefined
                    }
                }
            }).pipe(
                tap((savedPaymentMethodResponse) => this.review.save({
                    apiResponseCode: savedPaymentMethodResponse.responseCode
                })),
                timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences)
            );
        }
        return response.pipe(
            tap(() => this.review.save({ paymentMethodSaved: true })),
            timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences),
            catchError((error) => {
                this.logger.error('API ERROR: POST SAVED PAYMENT METHOD IN ADD PAYMENT METHOD FLOW', error);
                this.review.save(
                    {
                        paymentMethodSaved: false,
                        apiCallFailed: true
                    });
                return of(error);
            })
        );
    }

    private getBankCardDetails(params): PostSavedPaymentMethodBankCard {
        const postSavedPaymentMethod: PostSavedPaymentMethodBankCard = {
            encryptedPan: params.encryptResponse.encryptedNumber,
            encryptedPanKey: params.encryptResponse.sessionKey,
            encryptedPanFingerprint: params.encryptResponse.recipientCertificateFingerprint,
            expirationDate: `${this.review.cardExpirationYear}${this.review.cardExpirationMonth}`,
            ccLastFour: this.review.cardNumberLastFour,
            cardBrand: this.review.cardBrand,
            cardVerificationValue: this.review.cvv
        };
        if (this.session.billingSystem === 'NBP') {
            postSavedPaymentMethod.encryptedPan = '';
            postSavedPaymentMethod.profileId = params.encryptResponse.encryptedNumber;
        }
        return postSavedPaymentMethod;
    }

    private encryptBankCard(): Observable<EncryptBankCardResponse | HttpErrorResponse> {
        return this.cardEncryptionHelperService.encryptBankCard({
            billingSystem: this.session.billingSystem,
            accessToken: this.session.accessToken,
            payload: {
                cardType: encryptionCardTypeMap.get(this.review.cardType),
                cardNumber: this.review.cardNumber,
                cardholderName: this.isbSharedCommonHelperService.truncateCardholderName(this.review.displayName),
                billingAddress1: this.review.street,
                billingAddress2: '',
                billingCity: this.review.city,
                billingState: this.review.stateAbbreviated,
                billingZIP: this.review.zip,
                billingCountryCode: 'US',
                expirationDate: `${this.review.cardExpirationYear}${this.review.cardExpirationMonth}`
            }
        }).pipe(
            timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences),
            catchError((err) => {
                this.logger.error('API ERROR: ENCRYPT BANK CARD IN ADD PAYMENT METHOD REVIEW PAGE', err);
                return of(err);
            })
        );
    }

    private enrollInRefundMethod(accountNumber: string): Observable<RefundEnrollmentStatus> {
        return this.moneyProcessing.patchPayPlan({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            agreementNumber: accountNumber,
            payload: {
                billingPayload: {
                    enterpriseCustomerNumber: this.session.ecn,
                    requestType: 'RefundMethodChange.PayPlanUpdate',
                    action: 'update',
                    currentBillingMethodType: <any>'',
                    newBillingMethodType: 'Recurring EFT',
                    accountDueDate: '',
                    paymentMethod: {
                        electronicFundsTransfer: {
                            bankName: this.review.bankName,
                            bankRoutingNumber: this.review.routingNumber,
                            bankAccountNumber: this.review.accountNumber,
                            bankAccountType: this.review.accountType.toUpperCase(),
                            maskedBankAccountNumber: this.review.accountNumberLastThree
                        },
                        paymentMethodType: 'ElectronicFundsTransfer.PaymentMethod',
                        payorInfo: undefined,
                        description: 'UNSET'
                    }
                }
            }
        }).pipe(
            map((responseCode) => ({ accountNumber, successful: true, responseCode })),
            catchError((err: HttpErrorResponse) => of({ accountNumber, successful: false, responseCode: err.status }))
        );
    }

    private sendCustomerEmail(apiResponses: Partial<AddPaymentMethodServiceResponses>): Observable<boolean> {
        return this.isbExperienceApi.sendAddSavedPaymentMethodEmail({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            omsData: {
                savedAsRefundMethod: this.review.isEnrollingInRefund &&
                    apiResponses.saveAsRefundMethodResponses.filter((account) => account.successful).length > 0,
                omsServiceTransformationStrategyData: {
                    servicePlusIndicator: this.session.dpimServicePlusIndicator,
                    agentPhoneNumber: this.session.dpimAgentPhoneNumber
                }
            }
        }).pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }

    private getContentfulBillingContainer(): Observable<ContentfulRawDataItem> {
        return this.contentfulMapperservice.getContentfulBillingContent().pipe(
            map((response) => response)
        );
    }
}

function requestPlaceHolder(): Observable<any> {
    return of(null);
}
export interface AddPaymentMethodServiceResponses {
    savedPaymentMethodResponse?: IsbExperience.PostSavedPaymentMethodResponse | HttpErrorResponse | Error;
    updatedEmailAddress?: boolean | HttpErrorResponse | Error;
    saveAsRefundMethodResponses?: RefundEnrollmentStatus[];
    esddaPreferences?: boolean | null;
    tridion?: ContentfulRawDataItem;
}

export interface RefundEnrollmentStatus {
    accountNumber: string;
    successful: boolean;
    responseCode: number;
}
