<is-wait-message *ngIf="apiCallInProgress" [loadComplete]="!apiCallInProgress" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<ng-container *ngIf="tridionErrorMessage | async as message">
    <form *ngIf="loadComplete" [formGroup]="addPaymentForm.group" class="manage-payment-form-container">
        <div class="bolt-container">

            <bw-bolt-progress-bar appFocusOnInit arialabel="Add payment method progress value is 20%" progressBarValue="20" class="progress-bar-container to-container"></bw-bolt-progress-bar>
            <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Add payment method</h1>

            <div class="bolt-row">
                <div class="bolt-col-12">
                    <bw-bolt-notification type="error" [id]="'global-error-message'" *ngIf="globalErrorDisplayable || paperlessEsddaValidationError" class="bolt-space-bottom-xl to-container">
                        Please check the information below and try again.
                    </bw-bolt-notification>
                    <bw-bolt-notification type="error" id="accountValidationErrorMessageId" *ngIf="showAccountValidationMessage" class="bolt-space-bottom-xl to-container">
                        {{ message }}
                    </bw-bolt-notification>
                    <bw-bolt-notification type="error" id="creditCardVulPolicyError" *ngIf="displayCreditCardVulPolicyError" class="bolt-space-bottom-xl to-container">
                        <is-interpolate [text]="message"></is-interpolate>
                    </bw-bolt-notification>
                    <section id="payment-method-section" class="fluid-container" [ngClass]="{'bolt-space-bottom-lg': !isPaperlessEligible}">
                        <app-payment-sub-header>Payment method account information
                        </app-payment-sub-header>
                        <bw-bolt-notification type="error" *ngIf="accountValidationMessage" id="accountValidationErrorMessageId" class="bolt-space-bottom-xl to-container">
                            {{accountValidationMessage}}
                        </bw-bolt-notification>
                        <div class="section-content">
                            <div [ngClass]="{'bolt-space-bottom-lg' : (!(selectedPaymentMethod?.isBankCard) && !(selectedPaymentMethod?.isBankAccount)) }">
                                <app-payment-method-select [paymentMethods]="newPaymentMethodOptions" [(selectedPaymentMethod)]="selectedPaymentMethod"></app-payment-method-select>
                            </div>
                            <div *ngIf="selectedPaymentMethod?.isBankAccount" [ngClass]="(isEasyPayEligible || canEnrollInRefund)?'bolt-space-bottom-xl': 'bolt-space-bottom-2xl'">
                                <app-bank-account-form-controls [canSaveAsDefault]="true" formGroupName="paymentMethodGroup">
                                </app-bank-account-form-controls>
                            </div>

                            <div *ngIf="selectedPaymentMethod?.isBankCard" [ngClass]="(isEasyPayEligible || canEnrollInRefund)?'bolt-space-bottom-xl': 'bolt-space-bottom-2xl'">
                                <app-bank-card-form-controls [canSaveAsDefault]="true" [isManagePaymentPreferencesFlow]="isManagePaymentPrefsFlow" formGroupName="paymentMethodGroup"
                                    [isPaperlessEligible]="isPaperlessEligible" [isEasyPayEligible]="isEasyPayEligible">
                                </app-bank-card-form-controls>
                            </div>

                        </div>

                        <app-consider-this formGroupName="considerThisGroup" [canSelectPaperlessBilling]="isPaperlessEligible" [isEligibleForEasyPay]="isEasyPayEligible"
                            [canEnrollInRefund]="canEnrollInRefund" (billingAccountsForRefund)="billingAccountsForRefund = $event" (esddaCheckBoxChecked)="onEsddaCheckboxClick($event)"
                            [paperlessEsddaValidationError]="paperlessEsddaValidationError" [email]="getEmailAddress()" [paperlessEnrollmentOption]="addPaymentForm.enrollInPaperless">
                        </app-consider-this>

                    </section>
                </div>
            </div>
        </div>
        <bolt-button-bar>
            <bolt-button slot="cancel" id="cancel-button" (click)="cancelAddPayment()">Cancel</bolt-button>
            <bolt-button submit slot="forward" id="continue-button" type="primary" (click)="onContinue()">Continue</bolt-button>
        </bolt-button-bar>
    </form>
</ng-container>