<is-wait-message [loadComplete]="pageLoadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<form [formGroup]="formGroup.group" *ngIf="formGroup && pageLoadComplete" class="manage-payment-form-container">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="change default payment method progress value is 50%" progressBarValue="50" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Change default payment method</h1>

        <bw-bolt-notification type="error" [id]="'global-error-message'" *ngIf="globalErrorDisplayable" class="bolt-space-bottom-xl to-container">
            Please check the information below and try again.
        </bw-bolt-notification>

        <section class="fluid-container">
            <app-payment-sub-header>
                Change default payment method
            </app-payment-sub-header>

            <div class="section-content">
                <app-are-you-sure-radio-buttons formGroupName="areYouSureFormGroup" [submitted]="submitted" [displayMessage]="displayMessageForForm"></app-are-you-sure-radio-buttons>
            </div>
        </section>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" (click)="cancelSetAsDefault()">Cancel</bolt-button>
        <bolt-button submit slot="forward" type="primary" id="submit-button" (click)="onSubmit()">Submit</bolt-button>
    </bolt-button-bar>
</form>