/* eslint-disable no-extra-parens */
import { AccountPolicyHolderAddressElements } from './../../../shared/payments/ez-pay-authorization/eft-authorization-form/form-elements/account-policy-holder-address-elements';
import { Injectable, Inject } from '@angular/core';
import {
    ECIM as UnmappedECIM,
    MoneyProcessingRequestEnum as MPEnum,
    PBS as UnmappedRCA,
    IsbExperience,
    RetrivePaymentHistoryResponse,
    PBS as Unmapped,
    RetrieveContextResponse
} from '@nationwide/dgs-angular-billing-common';
import { PaymentHistoryItem } from '../../../billing/bills-and-payments/payment-history/payment-history-item.model';
import { PaymentMethodsAdapter } from '../../../billing/payment-preferences/shared/adapters/payment-methods.adapter';
import { PaymentItem } from '../../../billing/payment-preferences/shared/models/payment-item.model';
import { SessionService } from '../../../shared/session/session.service';
import { SharedRCAAdapter } from '../../../shared/shared-base.adapter';
import { PayBill } from '../../pay-bill-landing/pay-bill-landing-adpater.model';
import { PayBillLanding } from '../../pay-bill-landing/pay-bill-landing.model';
import {
    PaymentAmountOption,
    PaymentType
} from '../payment-amount-form-controls/payment-amount.component';
import { PaymentFlowType } from '../payment-flow-type.service';
import { LastPayment } from '../your-last-payment/your-last-payment.component';
import { RemoveDuplicatesPipe } from '@nationwide/internet-servicing-angular-pipes';
import { LoggerService } from '../../../shared/logger/logger.service';
import { CtmAdapter } from '../../../shared/service/ctm-service/ctm-shared.adapter';
import { EcimAdapter } from '../../../shared/adapters/ecim.adapter';
import { IsbSharedCommonHelperService } from '../../../../app/shared/isb-shared-common-helper.service';
import { SavedPaymentMethod } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience';
import { Bill } from '@nationwide/dgs-angular-billing-common/api-response-types/personal-billing';
import { INCREASE_OVERPAYMENT_STATES } from '../../../shared/constants/common.constants';

@Injectable()
export class PayBillLandingAdapter extends SharedRCAAdapter {
    // eslint-disable-next-line max-params
    constructor(
        private paymentMethodsAdapter: PaymentMethodsAdapter,
        public session: SessionService,
        private paymentFlowType: PaymentFlowType,
        removeDuplicatesPipe: RemoveDuplicatesPipe,
        @Inject('logger') private logger: LoggerService,
        private ctmAdapter: CtmAdapter,
        private ecimAdapter: EcimAdapter,
        private isbSharedCommonHelperService: IsbSharedCommonHelperService
    ) {
        super(
            removeDuplicatesPipe,
            session,
            isbSharedCommonHelperService
        );
    }

    mapPayBillLanding(mapPayBillParams: MapPayBillLandingParams): PayBillLanding {
        let payBillLanding: PayBillLanding;

        if (this.paymentFlowType.isAccountLevel) {
            payBillLanding = this.mapAccountLevelPayment(mapPayBillParams);
        } else if (this.paymentFlowType.isPolicyLevel) {
            payBillLanding = this.mapPolicyLevelPayment(mapPayBillParams);
        } else {
            payBillLanding = null;
        }

        return payBillLanding;
    }

    getPolicyNumber(policyAgreement: any): string {
        let policyNumber = '';

        // Logic from shared-base.adapter.ts.
        if (
            this.formatAgreementNumber(policyAgreement.agreementNumber) ===
            this.formatAgreementNumber(policyAgreement.displayAgreementNumber)
        ) {
            policyNumber = this.formatPolicyNumber(policyAgreement.agreementNumber);
        } else {
            policyNumber = policyAgreement.displayAgreementNumber;
        }

        return policyNumber;
    }

    getUpdatedEmailAddress(
        internetRegistrationResponse: UnmappedECIM.EnterpriseCustomerInformationManagementResponse
    ): string {
        return (
            internetRegistrationResponse?.contactPoints?.electronicAddress
                ?.emailAddress || PayBill.NULL
        );
    }

    getBillingAccountHolderName(
        getAgreementResponse: UnmappedECIM.Agreement
    ): string {
        let billingAccountHolderName = '';
        if (getAgreementResponse?.agreementRoles) {
            const billingOwnerAgreement = getAgreementResponse.agreementRoles.filter(
                (agreementRole) => agreementRole.roleType === 'Owner'
            )[0];
            if (billingOwnerAgreement.customerInRole) {
                billingAccountHolderName =
                    billingOwnerAgreement.customerInRole.fullName;
            }
        }
        return billingAccountHolderName;
    }

    getNextPaymentDate(
        rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse
    ): Date {
        let paymentDate: Date;

        try {
            const billingAccount = this.getBillingAgreement(rcaResponse);
            paymentDate = new Date(
                billingAccount.billingAccountDetail.billingDetail.nextDueDate
            );
        } catch (error) {
            paymentDate = null;
        }

        return paymentDate;
    }

    mapPaymentAmountOptionsPolicyLevel(
        rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse,
        pkey: string
    ): PaymentAmountOption[] {
        const billingAgreement = this.getBillingAgreementFromRCA(
            this.getAgreementsFromRCA(rcaResponse)
        );
        const agreementsRCA = this.getAgreementsFromRCA(rcaResponse);
        const policyAgreement = this.getAgreement(rcaResponse, pkey);
        const hasLifeAgreementLabel = this.getLifeOnlyAgreementLabel(
            rcaResponse,
            pkey
        );
        const hasLifeNotCareMatters = this.getLifeOnlyNotCareMatters(
            rcaResponse,
            pkey
        );
        const hasSAWarnedPolicy = this.isSAWarnedPolicy(rcaResponse, pkey);
        const isCareMatters = this.getCareMattersLob(rcaResponse, pkey);
        const isPowersports = this.getPowerSportsAgreementLabel(rcaResponse, pkey);
        const hasPaymentPlan = this.getPaymentPlan(billingAgreement);
        const hasNumberOfWarnedPolicy =
            this.isWarnedPolicyWarnedOnAccount(rcaResponse);
        const isWarnedPolicy = this.isWarnedPolicy(rcaResponse, pkey);
        const amountDue = this.getMinimumPolicyLevelAmount(rcaResponse, pkey);
        const fullPayAmount = this.getFullPayPolicyLevelAmount(rcaResponse, pkey);
        const hasPolicyReinstate = this.isPolicyEligibleForReinstate(rcaResponse, pkey);
        const hasAnyReinstatePolicy = this.hasAnyReinstatePolicy(rcaResponse, pkey);
        const collectionPaymentDetail = this.isPaymentCollection(rcaResponse, pkey);

        let paymentAmountOptions: PaymentAmountOption[] = [];
        if (agreementsRCA instanceof Array) {
            if (hasLifeAgreementLabel && hasLifeNotCareMatters) {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.MINIMUMPAYMENT,
                        amount: this.getMinimumPolicyLevelAmount(rcaResponse, pkey),
                        description: `make a total payment - ${this.getMessageforPaymentDue(
                            billingAgreement
                        )}`,
                        finePrint: ''
                    },
                    {
                        isPartial: true,
                        id: PayBill.PARTIALPAYMENT,
                        amount: '',
                        description: '',
                        finePrint: ''
                    }
                ];
            } else if (
                isWarnedPolicy &&
                hasPaymentPlan !== PayBill.FULLPAYTEXT &&
                (hasNumberOfWarnedPolicy === PayBill.SINGLEWARNED ||
                    hasNumberOfWarnedPolicy === PayBill.MULTIPLEWARNEDTEXT)
            ) {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.MINIMUMPAYMENT,
                        amount: amountDue,
                        description: `make a minimum payment - ${this.getMessagePaymentDueWarnedPolicy(
                            billingAgreement,
                            policyAgreement
                        )}`,
                        finePrint: ''
                    },
                    {
                        isPartial: true,
                        id: PayBill.PARTIALPAYMENT,
                        amount: '',
                        description: '',
                        finePrint: this.getMessageForMinimumPartialPayment(
                            amountDue,
                            isWarnedPolicy
                        )
                    }
                ];
                if (Number(fullPayAmount) > Number(amountDue)) {
                    paymentAmountOptions.push({
                        isPartial: false,
                        id: PayBill.FULLPAYMENT,
                        amount: fullPayAmount,
                        description: 'make a full payment',
                        finePrint: 'Avoid future installment fees by paying in full.'
                    });
                }
            } else if (
                !isPowersports &&
                hasNumberOfWarnedPolicy === PayBill.MULTIPLEWARNEDTEXT
            ) {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.MINIMUMPAYMENT,
                        amount: this.getMinimumPolicyLevelAmount(rcaResponse, pkey),
                        description: `make a minimum payment - ${this.getMessagePaymentDueWarnedPolicy(
                            billingAgreement,
                            policyAgreement
                        )}`,
                        finePrint: ''
                    },
                    {
                        isPartial: true,
                        id: PayBill.PARTIALPAYMENT,
                        amount: '',
                        description: '',
                        finePrint: ''
                    }
                ];
            } else if (isCareMatters) {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.MINIMUMPAYMENT,
                        amount: this.getMinimumPolicyLevelAmount(rcaResponse, pkey),
                        description: `make a total payment - ${this.getMessageforPaymentDue(
                            billingAgreement
                        )}`,
                        finePrint: ''
                    }
                ];
            } else if (
                isWarnedPolicy &&
                PayBill.FULLPAYTEXT.toUpperCase() !== hasPaymentPlan.toUpperCase() &&
                !isPowersports &&
                !hasSAWarnedPolicy
            ) {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.MINIMUMPAYMENT,
                        amount: this.getMinimumPolicyLevelAmount(rcaResponse, pkey),
                        description: `make a minimum payment - ${this.getMessagePaymentDueWarnedPolicy(
                            billingAgreement,
                            policyAgreement
                        )}`,
                        finePrint: ''
                    },
                    {
                        isPartial: true,
                        id: PayBill.PARTIALPAYMENT,
                        amount: '',
                        description: '',
                        finePrint: ''
                    }
                ];
            } else if (
                isWarnedPolicy &&
                hasPaymentPlan &&
                PayBill.FULLPAYTEXT.toUpperCase() === hasPaymentPlan.toUpperCase() &&
                !isPowersports &&
                !hasSAWarnedPolicy
            ) {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.FULLPAYMENT,
                        amount: this.getFullPayPolicyLevelAmount(rcaResponse, pkey),
                        description: 'make a full payment',
                        finePrint: 'Avoid future installment fees by paying in full.'
                    }
                ];
            } else if (
                isWarnedPolicy &&
                isPowersports &&
                Number(amountDue) > 0 &&
                amountDue === fullPayAmount
            ) {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.MINIMUMPAYMENT,
                        amount: this.getMinimumPolicyLevelAmount(rcaResponse, pkey),
                        description: `make a total payment - ${this.getMessagePaymentDueWarnedPolicy(
                            billingAgreement,
                            policyAgreement
                        )}`,
                        finePrint: ''
                    }
                ];
            } else if (isWarnedPolicy && isPowersports) {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.MINIMUMPAYMENT,
                        amount: this.getMinimumPolicyLevelAmount(rcaResponse, pkey),
                        description: `make a minimum payment - ${this.getMessagePaymentDueWarnedPolicy(
                            billingAgreement,
                            policyAgreement
                        )}`,
                        finePrint: ''
                    }
                ];
            } else if (hasSAWarnedPolicy && hasPaymentPlan === 'Monthly') {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.MINIMUMPAYMENT,
                        amount: this.getMinimumPolicyLevelAmount(rcaResponse, pkey),
                        description: `make a minimum payment - ${this.getMessagePaymentDueWarnedPolicy(
                            billingAgreement,
                            policyAgreement
                        )}`,
                        finePrint: ''
                    }
                ];
            } else if (hasSAWarnedPolicy && hasPaymentPlan === PayBill.FULLPAYTEXT) {
                paymentAmountOptions = [
                    {
                        isPartial: false,
                        id: PayBill.FULLPAYMENT,
                        amount: this.getFullPayPolicyLevelAmount(rcaResponse, pkey),
                        description: 'make a full payment',
                        finePrint: 'Avoid future installment fees by paying in full.'
                    }
                ];
            } else if (hasPolicyReinstate && hasAnyReinstatePolicy) {
                paymentAmountOptions = this.getReinstate(rcaResponse, pkey);
            }
            if (collectionPaymentDetail.isCollectionPayment) {
                paymentAmountOptions = [{
                    isPartial: false,
                    id: PayBill.FULLPAYMENT,
                    amount: this.getMinimumPolicyLevelAmount(rcaResponse, pkey),
                    description: 'Collection Amount.',
                    finePrint: 'Avoid collection agencies by making a full payment.'
                }];
            }
        }
        return paymentAmountOptions.filter(
            (option) => parseFloat(option.amount) >= 1 || option.amount === ''
        );
    }

    mapPaymentAmountOptionsAccountLevel(
        rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse
    ): PaymentAmountOption[] {
        const paymentAmountOptionId: { id: PaymentType }[] = [];
        const agreementsRCA = this.getAgreementsFromRCA(rcaResponse);
        let paymentAmountOptions: PaymentAmountOption[] = [];
        const collectionPaymentDetail = this.isPaymentCollection(rcaResponse);

        if (agreementsRCA instanceof Array) {
            const billingAgreement = this.getBillingAgreementFromRCA(
                this.getAgreementsFromRCA(rcaResponse)
            );
            const isEasyPayEligible =
                this.isAccountEligibleForEasyPay(billingAgreement);
            const isWarnedAccount = this.isAccountWarned(billingAgreement);
            const minimumAmount = this.getMinimumAccountLevelAmount(billingAgreement);
            const fullPayAmount =
                this.getFullPaymentAmountAccountLevel(billingAgreement);
            const statementAmount = this.getStatementAmount(billingAgreement);
            const agreementStatus =
                this.getBillingAccountAgreementStatus(billingAgreement);
            const lastMonthOfInstallement =
                this.isAgreementInLastMonthTerm(billingAgreement);
            const isEftBilled = this.getEFTBilledStatus(billingAgreement);
            const paymentPlan = this.getPaymentPlan(billingAgreement);
            const hasPolicyReinstate = this.isPolicyEligibleForReinstate(rcaResponse);
            const hasPastDueStatus = this.getPastDueStatus(billingAgreement);
            const hasRecievedStatus = this.getRecievedStatus(billingAgreement);
            const hasLifePolicy = this.getLifeOnlyAccount(rcaResponse);
            const hasLifeOnlyBusiness = this.hasLifeOnlyBusiness(rcaResponse);
            const hasCareMatters = this.containsCareMattersPolicy(rcaResponse);
            const hasAnyReinstatePolicy = this.hasAnyReinstatePolicy(rcaResponse);
            const hasCanPayBillingAccountWithReinstate =
                this.hasCanPayBillingAccountWithReinstate(
                    billingAgreement,
                    rcaResponse
                );

            if (isEasyPayEligible === PayBill.TRUE && !isWarnedAccount) {
                if (
                    agreementStatus === PayBill.PAID &&
                    !hasPolicyReinstate &&
                    parseFloat(fullPayAmount) < 1
                ) {
                    paymentAmountOptions.push(
                        this.getFullAmount(
                            this.getFullPaymentAmountAccountLevel(billingAgreement),
                            PayBill.FULLPAYMENT
                        )
                    );
                } else if (agreementStatus === PayBill.PAID && !hasPolicyReinstate) {
                    paymentAmountOptions = this.getFullPayPlan(billingAgreement);
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    agreementStatus === PayBill.PAID
                ) {
                    paymentAmountOptions = this.getFullPayPlan(billingAgreement);
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    agreementStatus === PayBill.BILLED &&
                    Number(minimumAmount) === Number(fullPayAmount)
                ) {
                    paymentAmountOptions =
                        this.getTotalPaymentForEasypay(billingAgreement);
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    agreementStatus === PayBill.BILLED &&
                    Number(minimumAmount) < Number(statementAmount)
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId
                    });
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    isEftBilled &&
                    Number(minimumAmount) === Number(statementAmount)
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId
                    });
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    isEftBilled &&
                    !(Number(minimumAmount) === Number(statementAmount))
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED
                    });
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    agreementStatus === PayBill.NULL &&
                    Number(minimumAmount) > PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    agreementStatus === PayBill.NULL &&
                    Number(minimumAmount) <= PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    hasPolicyReinstate &&
                    hasAnyReinstatePolicy &&
                    !hasCanPayBillingAccountWithReinstate
                ) {
                    paymentAmountOptions = this.getReinstate(rcaResponse);
                } else if (
                    agreementStatus === PayBill.BILLED &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(statementAmount) > PayBill.ZERO &&
                    Number(minimumAmount) === Number(statementAmount) &&
                    !hasLifePolicy
                ) {
                    if (
                        Number(minimumAmount) === Number(fullPayAmount) &&
                        hasCareMatters
                    ) {
                        paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                        paymentAmountOptions = this.createPaymentAmountOption({
                            billingAgreement,
                            paymentAmountOptionIds: paymentAmountOptionId,
                            policyType: <string>PayBill.CAREMATTERS
                        });
                    } else if (
                        Number(minimumAmount) === Number(fullPayAmount) &&
                        !hasCareMatters &&
                        !hasLifeOnlyBusiness
                    ) {
                        paymentAmountOptions =
                            this.getTotalPaymentForEasypay(billingAgreement);
                    } else if (
                        Number(minimumAmount) > PayBill.ZERO &&
                        Number(fullPayAmount) > PayBill.ZERO &&
                        Number(minimumAmount) !== Number(fullPayAmount) &&
                        !hasCareMatters
                    ) {
                        paymentAmountOptionId.push({ id: PayBill.EASYPAYENROLLMENT });
                        paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                        paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                        paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                        paymentAmountOptions = this.createPaymentAmountOption({
                            billingAgreement,
                            paymentAmountOptionIds: paymentAmountOptionId
                        });
                    }
                } else if (
                    agreementStatus === PayBill.BILLED &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    hasLifeOnlyBusiness &&
                    !hasCareMatters
                ) {
                    paymentAmountOptions = this.getTotalPaymentForLife(billingAgreement);
                } else if (
                    agreementStatus === PayBill.BILLED &&
                    Number(minimumAmount) < Number(statementAmount) &&
                    !hasLifeOnlyBusiness
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId
                    });
                } else if (
                    agreementStatus === PayBill.NULL &&
                    Number(minimumAmount) > PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    agreementStatus === PayBill.NULL &&
                    Number(minimumAmount) <= PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    agreementStatus === PayBill.BILLED &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    !hasLifeOnlyBusiness &&
                    !hasCareMatters
                ) {
                    paymentAmountOptions =
                        this.getTotalPaymentForEasypay(billingAgreement);
                } else if (
                    isEftBilled &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    hasLifeOnlyBusiness &&
                    !hasCareMatters
                ) {
                    paymentAmountOptions = this.getTotalPaymentForLife(billingAgreement);
                } else if (
                    isEftBilled &&
                    !hasLifeOnlyBusiness &&
                    !hasCareMatters &&
                    (Number(minimumAmount) === Number(statementAmount) ||
                        Number(minimumAmount) !== Number(fullPayAmount)) &&
                    Number(fullPayAmount) > PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED
                    });
                } else if (
                    isEftBilled &&
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    !hasLifeOnlyBusiness &&
                    !hasCareMatters
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED
                    });
                } else if (
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    hasCareMatters
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.CAREMATTERS
                    });
                } else if (
                    agreementStatus === PayBill.RETURNED &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    Number(minimumAmount) <= PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    agreementStatus === PayBill.RETURNED &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.MINIMUM
                    });
                } else if (
                    lastMonthOfInstallement &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    hasPastDueStatus &&
                    !hasCareMatters &&
                    !hasLifeOnlyBusiness &&
                    !hasCareMatters
                ) {
                    paymentAmountOptionId.push({ id: PayBill.EASYPAYENROLLMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EASYPAY
                    });
                } else if (
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    this.logger.info(
                        `User ${this.session.userId} has no paymentAmountOptions hence assigning default paymentOptions`
                    );
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.MINIMUM
                    });
                } else if (Number(minimumAmount) <= PayBill.ZERO) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    this.logger.info(
                        `User ${this.session.userId} has no paymentAmountOptions hence assigning default paymentOptions`
                    );
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                }
            } else if (isEasyPayEligible === PayBill.FALSE && !isWarnedAccount) {
                if (
                    hasCanPayBillingAccountWithReinstate &&
                    agreementStatus === PayBill.PAID
                ) {
                    paymentAmountOptions = this.getFullPayPlan(billingAgreement);
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    agreementStatus === PayBill.BILLED &&
                    Number(minimumAmount) < Number(statementAmount)
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId
                    });
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    isEftBilled &&
                    Number(minimumAmount) === Number(statementAmount)
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId
                    });
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    isEftBilled &&
                    !(Number(minimumAmount) === Number(statementAmount))
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED
                    });
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    agreementStatus === PayBill.NULL &&
                    Number(minimumAmount) > PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    hasCanPayBillingAccountWithReinstate &&
                    agreementStatus === PayBill.NULL &&
                    Number(minimumAmount) <= PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    hasPolicyReinstate &&
                    hasAnyReinstatePolicy &&
                    !hasCanPayBillingAccountWithReinstate
                ) {
                    paymentAmountOptions = this.getReinstate(rcaResponse);
                } else if (
                    agreementStatus === PayBill.BILLED &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(statementAmount) > PayBill.ZERO &&
                    (Number(minimumAmount) < Number(statementAmount) ||
                        Number(fullPayAmount) > PayBill.ZERO) &&
                    !hasLifeOnlyBusiness
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId
                    });
                } else if (
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    hasCareMatters
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.CAREMATTERS
                    });
                } else if (
                    agreementStatus === PayBill.BILLED &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    hasLifeOnlyBusiness &&
                    !hasCareMatters
                ) {
                    paymentAmountOptions = this.getTotalPaymentForLife(billingAgreement);
                } else if (
                    isEftBilled &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    !hasCareMatters &&
                    !hasLifeOnlyBusiness
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED,
                        messageString: <string>PayBill.LASTINSTALLEMENT
                    });
                } else if (
                    isEftBilled &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    Number(minimumAmount) !== Number(fullPayAmount) &&
                    hasPastDueStatus &&
                    !hasCareMatters &&
                    !hasLifeOnlyBusiness
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED
                    });
                } else if (
                    isEftBilled &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(statementAmount) > PayBill.ZERO &&
                    Number(minimumAmount) !== Number(statementAmount) &&
                    hasRecievedStatus &&
                    !hasCareMatters &&
                    !hasLifeOnlyBusiness
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED,
                        messageString: <string>PayBill.RECIEVED
                    });
                } else if (
                    isEftBilled &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(statementAmount) > PayBill.ZERO &&
                    Number(minimumAmount) === Number(statementAmount) &&
                    !hasCareMatters &&
                    !hasLifeOnlyBusiness
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED
                    });
                } else if (paymentPlan === PayBill.FULLPAYTEXT) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.FULLPAYTEXT,
                        messageString: <string>PayBill.NULL
                    });
                } else if (
                    paymentPlan === PayBill.MONTHLY &&
                    agreementStatus === PayBill.BILLED
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.MINIMUM
                    });
                } else if (
                    agreementStatus === PayBill.RETURNED &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.MINIMUM
                    });
                } else if (
                    agreementStatus === PayBill.NULL &&
                    Number(minimumAmount) > PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    agreementStatus === PayBill.NULL &&
                    Number(minimumAmount) <= PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    isEftBilled &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    hasLifeOnlyBusiness &&
                    !hasCareMatters
                ) {
                    paymentAmountOptions = this.getTotalPaymentForLife(billingAgreement);
                } else if (
                    isEftBilled &&
                    Number(minimumAmount) === Number(fullPayAmount) &&
                    !hasLifeOnlyBusiness
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED
                    });
                } else if (
                    isEftBilled &&
                    (Number(minimumAmount) === Number(statementAmount) ||
                        Number(minimumAmount) !== Number(fullPayAmount)) &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    !hasCareMatters &&
                    !hasLifeOnlyBusiness
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.EFTBILLED
                    });
                } else if (
                    agreementStatus === PayBill.RETURNED &&
                    Number(fullPayAmount) > PayBill.ZERO &&
                    Number(minimumAmount) <= PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    Number(minimumAmount) > PayBill.ZERO &&
                    Number(fullPayAmount) > PayBill.ZERO
                ) {
                    paymentAmountOptionId.push({ id: PayBill.MINIMUMPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    this.logger.info(
                        `User ${this.session.userId} has no paymentAmountOptions hence assigning default paymentOptions`
                    );
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.MINIMUM
                    });
                } else if (Number(minimumAmount) <= PayBill.ZERO) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    this.logger.info(
                        `User ${this.session.userId} has no paymentAmountOptions hence assigning default paymentOptions`
                    );
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                } else if (
                    lastMonthOfInstallement &&
                    Number(minimumAmount) > PayBill.ZERO &&
                    hasPastDueStatus &&
                    !hasCareMatters &&
                    !hasLifeOnlyBusiness &&
                    !hasCareMatters
                ) {
                    paymentAmountOptionId.push({ id: PayBill.PARTIALPAYMENT });
                    paymentAmountOptionId.push({ id: PayBill.FULLPAYMENT });
                    paymentAmountOptions = this.createPaymentAmountOption({
                        billingAgreement,
                        paymentAmountOptionIds: paymentAmountOptionId,
                        policyType: <string>PayBill.NULL
                    });
                }
            }
        }
        return paymentAmountOptions.filter(
            (option) =>
                parseFloat(option.amount) >= 1 ||
                option.amount === '' ||
                option.id === PayBill.FULLPAYMENT
        );
    }

    createPaymentAmountOption({
        billingAgreement,
        paymentAmountOptionIds,
        policyType,
        messageString,
        partialPaymentFinePrint
    }: {
        billingAgreement: UnmappedRCA.Agreement;
        paymentAmountOptionIds: { id: PaymentType }[];
        policyType?: string;
        messageString?: string;
        partialPaymentFinePrint?: string;
    }): PaymentAmountOption[] {
        const optionIdLength = paymentAmountOptionIds.length;
        const easyPay = this.getEasyPay(
            this.getAmountAccountLevel(billingAgreement),
            PayBill.EASYPAYENROLLMENT
        );
        const minimumPay = this.getMinimumPayment(
            this.getMinimumAccountLevelAmount(billingAgreement),
            this.getMessageforPaymentDue(billingAgreement),
            PayBill.MINIMUMPAYMENT
        );
        const partialPay = this.getPartialPayment(
            PayBill.PARTIALPAYMENT,
            partialPaymentFinePrint
        );
        const fullpay = this.getFullAmount(
            this.getFullPaymentAmountAccountLevel(billingAgreement),
            PayBill.FULLPAYMENT
        );
        const totalPayWithLastInstallement =
            this.getMinimumPaymentWithLastInstallment(
                this.getMinimumAccountLevelAmount(billingAgreement),
                this.getMessageforPaymentDue(billingAgreement),
                PayBill.MINIMUMPAYMENT
            );
        const minimumPayWithNoMessage = this.getMinimumPayment(
            this.getMinimumAccountLevelAmount(billingAgreement),
            '',
            PayBill.MINIMUMPAYMENT
        );
        const paymentAmountOption: PaymentAmountOption[] = [];

        if (optionIdLength === PayBill.FOUR) {
            paymentAmountOption.push(easyPay, minimumPay, partialPay, fullpay);
        } else if (
            optionIdLength === PayBill.THREE &&
            policyType === PayBill.EASYPAY
        ) {
            paymentAmountOption.push(easyPay, fullpay, partialPay);
        } else if (optionIdLength === PayBill.THREE) {
            paymentAmountOption.push(minimumPay, partialPay, fullpay);
        } else if (
            optionIdLength === PayBill.TWO &&
            policyType === PayBill.FULLPAYTEXT
        ) {
            paymentAmountOption.push(partialPay, fullpay);
        } else if (
            optionIdLength === PayBill.TWO &&
            policyType === PayBill.MULTIPLEWARNEDTEXT
        ) {
            paymentAmountOption.push(minimumPay, partialPay);
        } else if (
            optionIdLength === PayBill.TWO &&
            policyType === PayBill.EFTBILLED &&
            messageString === PayBill.LASTINSTALLEMENT
        ) {
            paymentAmountOption.push(totalPayWithLastInstallement, partialPay);
        } else if (
            optionIdLength === PayBill.TWO &&
            policyType === PayBill.EFTBILLED
        ) {
            paymentAmountOption.push(minimumPay, partialPay);
        } else if (optionIdLength === PayBill.TWO && policyType === PayBill.NULL) {
            paymentAmountOption.push(partialPay, fullpay);
        } else if (
            optionIdLength === PayBill.ONE &&
            policyType === PayBill.CAREMATTERS
        ) {
            paymentAmountOption.push(totalPayWithLastInstallement);
        } else if (
            optionIdLength === PayBill.ZERO &&
            messageString === PayBill.MINIMUM
        ) {
            paymentAmountOption.push(minimumPayWithNoMessage);
        } else if (optionIdLength === PayBill.ZERO) {
            paymentAmountOption.push({
                isPartial: false,
                id: PayBill.FULLPAYMENT,
                amount: this.getFullPaymentAmountAccountLevel(billingAgreement),
                description: 'this is your total amount',
                finePrint: ''
            });
        }
        return paymentAmountOption;
    }

    isIncreaseOverpaymentEligible(rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse): boolean {
        let isEligible = false;
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        if (billingAgreement) {
            const isWarnedAccount = this.isAccountWarned(billingAgreement);
            const paymentMethod = this.getPaymentMethod(billingAgreement);
            const activePolicyAgreements = this.getPolicies(rcaResponse).filter((agreement) => agreement.status === 'Active');
            const hasSinglePolicy = activePolicyAgreements.length === 1;
            if (hasSinglePolicy) {
                const activePolicy = activePolicyAgreements[0];
                const productNameOnPolicy = this.isbSharedCommonHelperService.getProductName(activePolicy);
                const isAutoOrProperty = productNameOnPolicy === 'Auto' || productNameOnPolicy === 'Property';
                const stateWherePolicyIssued = activePolicy.placeOfIssue;
                const isIncreaseOverpaymentState = INCREASE_OVERPAYMENT_STATES.includes(stateWherePolicyIssued);
                isEligible = !isWarnedAccount && paymentMethod === 'CPPDB' && isAutoOrProperty && isIncreaseOverpaymentState;
            }
        }
        return isEligible;
    }

    getCurrentAndFutureBills(billsResponse: any): Bill[] {
        let currentAndFutureBills: Bill[] = [];
        if (billsResponse?.[0]?.bills) {
            currentAndFutureBills = billsResponse?.[0]?.bills?.filter((bill: Bill) => (bill.billType === 'Current' || bill.billType === 'Next'));
        }
        if (currentAndFutureBills.length > 1) {
            currentAndFutureBills.sort((a: any, b: any) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
        }
        return currentAndFutureBills;
    }

    hasIncreaseOverpaymentEligibleBills(currentAndFutureBills: Bill[]): boolean {
        const installmentFeeAmount = 5;
        const everyBillHasInstallmentFeeOnly = currentAndFutureBills.every((bill) => bill.fees?.length === 1 &&
            bill.fees[0].type.valueOf().toLowerCase() === 'installment fee' &&
            bill.fees[0].amount === installmentFeeAmount);
        const hasAppliedPayments = currentAndFutureBills.some((bill) => bill.appliedPayments);
        const currentBill: Bill = currentAndFutureBills.find((bill) => bill.billType === 'Current');
        const hasOverPaymentAmount = this.hasOverPaymentAmount(currentBill);
        const hasUnderPaymentAmount = this.hasUnderPaymentAmount(currentBill);
        return currentAndFutureBills?.length >= PayBill.THREE && everyBillHasInstallmentFeeOnly && !hasAppliedPayments && !hasOverPaymentAmount && !hasUnderPaymentAmount;
    }

    hasOverPaymentAmount(bill: Bill): boolean {
        return !!bill?.overPaymentAmount && bill?.overPaymentAmount !== 0;
    }

    hasUnderPaymentAmount(bill: Bill): boolean {
        return !!bill?.underPaymentAmount && bill?.underPaymentAmount !== 0;
    }

    getEasyPay(easyPayAmount: string, idName: PaymentType): PaymentAmountOption {
        return {
            isPartial: false,
            id: idName,
            amount: easyPayAmount,
            description: 'enroll in automatic payments',
            finePrint:
                'Save time and money by having payments made automatically each billing cycle.'
        };
    }
    getMinimumPayment(
        minimumAmount: string,
        message: string,
        idName: PaymentType
    ): PaymentAmountOption {
        return {
            isPartial: false,
            id: idName,
            amount: minimumAmount,
            description: `make a minimum payment - ${message}`,
            finePrint: ''
        };
    }
    getMinimumPaymentWithLastInstallment(
        minimumAmount: string,
        message: string,
        idName: PaymentType
    ): PaymentAmountOption {
        return {
            isPartial: false,
            id: idName,
            amount: minimumAmount,
            description: `make a total payment - ${message}`,
            finePrint: ''
        };
    }
    getMinumumPaymentPowersportsWithoutLastMonthTerm(
        minimumAmount: string,
        message: string,
        idName: PaymentType
    ): PaymentAmountOption {
        return {
            isPartial: false,
            id: idName,
            amount: minimumAmount,
            description: `make a minimum payment - ${message}`,
            finePrint: ''
        };
    }
    getTotalPaymentForLife(
        billingAgreement: UnmappedRCA.Agreement
    ): PaymentAmountOption[] {
        return [
            {
                isPartial: false,
                id: PayBill.MINIMUMPAYMENT,
                amount: this.getMinimumAccountLevelAmount(billingAgreement),
                description: `make a total payment - ${this.getMessageforPaymentDue(
                    billingAgreement
                )}`,
                finePrint: ''
            },
            {
                isPartial: true,
                id: PayBill.PARTIALPAYMENT,
                amount: '',
                description: '',
                finePrint: ''
            }
        ];
    }
    getTotalPaymentForAutoWarned(
        billingAgreement: UnmappedRCA.Agreement
    ): PaymentAmountOption[] {
        return [
            {
                isPartial: false,
                id: PayBill.MINIMUMPAYMENT,
                amount: this.getMinimumAccountLevelAmount(billingAgreement),
                description: `make a total payment - ${this.getMessageforPaymentDue(
                    billingAgreement
                )}`,
                finePrint: ''
            },
            {
                isPartial: true,
                id: PayBill.PARTIALPAYMENT,
                amount: '',
                description: '',
                finePrint: ''
            }
        ];
    }
    getTotalPaymentForEasypay(
        billingAgreement: UnmappedRCA.Agreement
    ): PaymentAmountOption[] {
        return [
            {
                isPartial: false,
                id: PayBill.EASYPAYENROLLMENT,
                amount: this.getAmountAccountLevel(billingAgreement),
                description: 'enroll in automatic payments',
                finePrint:
                    'Save time and money by having payments made automatically each billing cycle.'
            },
            {
                isPartial: false,
                id: PayBill.MINIMUMPAYMENT,
                amount: this.getMinimumAccountLevelAmount(billingAgreement),
                description: `make a total payment - ${this.getMessageforPaymentDue(
                    billingAgreement
                )}`,
                finePrint: ''
            },
            {
                isPartial: true,
                id: PayBill.PARTIALPAYMENT,
                amount: '',
                description: '',
                finePrint: ''
            }
        ];
    }

    getPartialPayment(
        idName: PaymentType,
        finePrint = '',
        minimumAmount = ''
    ): PaymentAmountOption {
        return {
            isPartial: true,
            id: idName,
            amount: minimumAmount,
            description: '',
            finePrint
        };
    }

    getMultiMonthPay(): PaymentAmountOption {
        return {
            isPartial: false,
            id: PayBill.MULTIMONTHPAYMENT,
            amount: '',
            description: '',
            finePrint: ''
        };
    }

    getFullAmount(fullAmount: string, idName: PaymentType): PaymentAmountOption {
        return {
            isPartial: false,
            id: idName,
            amount: fullAmount,
            description: 'make a full payment',
            finePrint: 'Avoid future installment fees by paying in full.'
        };
    }

    getFullPayPlan(
        billingAgreement: UnmappedRCA.Agreement
    ): PaymentAmountOption[] {
        return [
            {
                isPartial: true,
                id: PayBill.PARTIALPAYMENT,
                amount: '',
                description: '',
                finePrint: ''
            },
            {
                isPartial: false,
                id: PayBill.FULLPAYMENT,
                amount: this.getFullPaymentAmountAccountLevel(billingAgreement),
                description: 'make a full payment',
                finePrint: 'Avoid future installment fees by paying in full.'
            }
        ];
    }

    getReinstate(
        rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse,
        pkey?: string
    ): PaymentAmountOption[] {
        return [
            {
                isPartial: false,
                id: PayBill.MINIMUMPAYMENT,
                amount: this.getReinstatementAmount(rcaResponse, pkey),
                description: 'make a minimum payment',
                finePrint: ''
            }
        ];
    }

    getPolicyProductName(rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse, pkey: string): MPEnum.ProductName {
        const policyAccount = this.getAgreementsFromRCA(rcaResponse).find((policy) => policy.refId === pkey);
        return this.isbSharedCommonHelperService.getProductName(policyAccount);
    }

    mapPaymentMethods(unmappedPayMethods: SavedPaymentMethod[]): PaymentItem[] {
        return this.session.isVUL ?
            this.paymentMethodsAdapter.mapSortPayMethods(unmappedPayMethods, {
                includeNewBankCard: false,
                includeNewDebitCard: true,
                filterCreditCards: true
            }, true) :
            this.paymentMethodsAdapter.mapSortPayMethods(unmappedPayMethods, {
                includeMasterpass: false
            }, true);
    }

    private mapAccountLevelPayment(
        params: MapPayBillLandingParams
    ): PayBillLanding {
        const {
            rcaResponse,
            unmappedPayMethods,
            paymentHistoryResponse,
            internetRegistrationResponse,
            getAgreementResponse
        } = params;

        const billingAccount = this.getBillingAgreementFromRCA(
            this.getAgreementsFromRCA(rcaResponse)
        );

        const lastPayment: LastPayment = this.mapLastPayment(rcaResponse);
        const canManage = this.getCanManage(billingAccount);
        const canSelectPaperlessBilling =
            this.ecimAdapter.getCanSelectPaperlessBilling(internetRegistrationResponse);

        const isEligibleForEasyPay = this.isEligibleForEasyPay(rcaResponse);
        const sourceSystem = this.getAccountLevelSourceSystem(rcaResponse);
        const address: AccountPolicyHolderAddressElements = this.addressFromInternetRegistrationResponse(internetRegistrationResponse);
        const streetLine1 = address?.AcctPolHolderAddr1;
        const city = address?.AcctPolHolderCity;
        const state = address?.AcctPolHolderState;

        const paymentMethods: PaymentItem[] =
            this.mapPaymentMethods(unmappedPayMethods);
        const partialPaymentHistory: PaymentHistoryItem[] = paymentHistoryResponse ? this.getPartialPaymentHistoryAccountLevel(paymentHistoryResponse) : [];
        const emailAddress =
            this.ecimAdapter.getUpdatedEmailAddress(internetRegistrationResponse) ||
            this.mapEmailAddress(rcaResponse);
        const paymentDueDate = this.getDueDateAccountLevel(rcaResponse);
        const maxPaymentDateAllowed =
            this.getMaxPaymentDateAcountLevel(rcaResponse);
        const amountDue = this.getAmountDueFromRCA(rcaResponse);
        const isPaymentCapExceededLimit = Number(amountDue) > PayBill.PAYMENT_CAP;
        const isBankCardCapExceeded = Number(amountDue) > PayBill.BANKCARD_CAP;
        const displayPaymentDateSection =
            !this.isDuedatePastCurrentDateAccountLevel(rcaResponse) &&
            !this.isPaymentScheduledAccountLevel(rcaResponse) &&
            this.isSchedulePaymentAllowedAccountLevel(rcaResponse);
        const agreementStatus =
            this.getBillingAccountAgreementStatus(billingAccount);
        const isPaymentCapExceeded = this.isCapPaymentExceeded(rcaResponse);
        const hasCareMatters = this.containsCareMattersPolicy(rcaResponse);
        const isOipaPolicy = this.containsOipaPolicy(rcaResponse);
        const isAutomaticPaymentSuspended =
            this.isAutomaticPaymentSuspended(rcaResponse);
        const billingNextDueDate = this.billingNextDueDate(rcaResponse);
        const canViewBill = this.canViewBilling(rcaResponse);
        let oipaPolicyPaymentCapAcheived;
        let careMattersPolicyPaymentCapAcheived;
        let nonOipaPolicyPaymentCapAcheived;
        if (isOipaPolicy) {
            oipaPolicyPaymentCapAcheived = isPaymentCapExceededLimit;
        } else if (hasCareMatters) {
            careMattersPolicyPaymentCapAcheived = isBankCardCapExceeded;
        } else if (!isOipaPolicy && !hasCareMatters) {
            nonOipaPolicyPaymentCapAcheived = isBankCardCapExceeded;
        }

        const nextPaymentDate: Date = this.getNextPaymentDate(rcaResponse);
        const nextPaymentAmount = this.getNextPaymentAmount(billingAccount);

        const statementAmount = Number(this.getStatementAmount(billingAccount));
        const fullPayAmount = Number(
            this.getFullPaymentAmountAccountLevel(billingAccount)
        );

        const hasScheduledPaymentIndicator =
            this.isPaymentScheduledAccountLevel(rcaResponse);

        const scheduledPaymentAmount =
            this.scheduledPaymentAmountAccountLevel(rcaResponse);

        const scheduledPaymentDate =
            this.scheduledPaymentDateAccountLevel(rcaResponse);

        const billingAccountHolderName = getAgreementResponse ? this.ecimAdapter.getBillingAccountHolderName(getAgreementResponse) : '';

        const roleIdentifier = getAgreementResponse ? this.ecimAdapter.getRoleIdentifer(getAgreementResponse) : '';

        let paymentAmountOptions: PaymentAmountOption[] =
            this.mapPaymentAmountOptionsAccountLevel(rcaResponse);

        const hasEnrollInEzPayOption = !!paymentAmountOptions.filter(
            (option) => option.id === 'easy-pay-enrollment'
        ).length;
        if (!billingAccountHolderName && hasEnrollInEzPayOption) {
            paymentAmountOptions = paymentAmountOptions.filter(
                (option) => option.id !== 'easy-pay-enrollment'
            );
        }

        const producerNumbers = getAgreementResponse ? this.ctmAdapter.getProducerNumbers(getAgreementResponse) : [];

        // Additional fields needed for Policy Level Overpayment Allocation
        const policyAgreements = this.getPolicies(rcaResponse);
        let policyNumber;
        let singlePolicyProductName;
        let isEligibleForOverpaymentAllocation = false;

        if (policyAgreements.length === 1) {
            policyNumber = this.getPolicyNumber(policyAgreements[0]);
            singlePolicyProductName =
                this.isbSharedCommonHelperService.getProductName(policyAgreements[0]);
            isEligibleForOverpaymentAllocation = true;
        }
        const isSingleActiveAutoPolicy = this.isSingleActiveAutoPolicyExisting(policyAgreements);
        const messages = this.getBillingAccountMessagesFromRCA(rcaResponse);
        const lastDayToPayBeforeCancelled =
            this.getParameterValueFromMultipleMessages(
                messages,
                'lastDayToPayBeforeCancelled',
                Date
            );
        const cancellationDate = this.getParameterValueFromMultipleMessages(
            messages,
            'cancellationDate',
            Date
        );
        const automaticPayments = this.enrolled(rcaResponse);
        const amountDueFromBilling = this.getMinimumAccountLevelAmount(billingAccount);

        return new PayBillLanding(
            amountDueFromBilling,
            lastPayment,
            paymentAmountOptions,
            paymentMethods,
            canSelectPaperlessBilling,
            false,
            isEligibleForEasyPay,
            false,
            paymentDueDate,
            maxPaymentDateAllowed,
            emailAddress,
            state,
            city,
            streetLine1,
            displayPaymentDateSection,
            sourceSystem,
            '', // was placeholder for payment Status
            agreementStatus,
            partialPaymentHistory,
            MPEnum.ProductName.BILLING_ACCOUNT,
            statementAmount,
            fullPayAmount,
            billingAccountHolderName,
            producerNumbers,
            isPaymentCapExceeded,
            amountDue,
            hasCareMatters,
            isOipaPolicy,
            oipaPolicyPaymentCapAcheived,
            careMattersPolicyPaymentCapAcheived,
            nonOipaPolicyPaymentCapAcheived,
            nextPaymentDate,
            nextPaymentAmount,
            '', // place holder for reistatePolicyNumber
            isAutomaticPaymentSuspended,
            billingNextDueDate,
            hasScheduledPaymentIndicator,
            scheduledPaymentAmount,
            scheduledPaymentDate,
            // Additional fields for Policy Level Overpayment Allocation
            singlePolicyProductName,
            billingAccount.refId,
            policyNumber,
            isEligibleForOverpaymentAllocation,
            roleIdentifier,
            lastDayToPayBeforeCancelled,
            cancellationDate,
            false,
            canManage,
            false,
            canViewBill,
            '',
            automaticPayments,
            '', // placeholder for paymentPlan
            '', // placeholder for agreementType
            null, // placeholder for installmentFee
            '', // placeholder for minimumAmountDue
            isSingleActiveAutoPolicy
        );
    }

    private getPolicyChangePayment(contextData): PaymentAmountOption[] {
        return [
            {
                isPartial: false,
                id: PayBill.POLICYCHANGEPAYMENT,
                amount: contextData.proratedPremiumChange,
                description: 'This reflects your recent policy change.',
                finePrint: 'Pay this amount now and your remaining bills will stay the same for the rest of the policy term.'
            }
        ];
    }

    private mapPolicyLevelPayment(params: MapPayBillLandingParams): PayBillLanding {
        const { rcaResponse, unmappedPayMethods, paymentHistoryResponse, internetRegistrationResponse, getAgreementResponse } = params;
        const billingAccount = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const canManage = this.getCanManage(billingAccount);
        const { isCollectionPayment, collectionPolicyNumber } = this.isPaymentCollection(rcaResponse, this.paymentFlowType.pKey);
        const lastPayment: LastPayment = this.mapLastPayment(rcaResponse, this.paymentFlowType.pKey);
        let paymentAmountOptions: PaymentAmountOption[];

        const policyAgreements = this.getPolicies(rcaResponse);
        const isSingleActiveAutoPolicy = this.isSingleActiveAutoPolicyExisting(policyAgreements);
        const isPayMidTermChange = this.paymentFlowType.isPayMidTermChange();
        const contextId = this.paymentFlowType.getContextCacheContextId();

        if (isPayMidTermChange && contextId) {
            if (!params.contextCacheResponse.context) {
                throw new Error('Context Cache Error');
            }
            this.setPayMidTermChangeLogData(params.contextCacheResponse.context);
            paymentAmountOptions = this.getPolicyChangePayment(params.contextCacheResponse.context);
        } else {
            paymentAmountOptions = this.mapPaymentAmountOptionsPolicyLevel(
                rcaResponse,
                this.paymentFlowType.pKey
            );
        }

        const paymentMethods: PaymentItem[] = this.mapPaymentMethods(unmappedPayMethods);
        const address: AccountPolicyHolderAddressElements = this.addressFromInternetRegistrationResponse(internetRegistrationResponse);
        const streetLine1 = address?.AcctPolHolderAddr1;
        const city = address?.AcctPolHolderCity;
        const state = address?.AcctPolHolderState;

        const sourceSystem = this.getPolicyLevelSourceSystem(rcaResponse, this.paymentFlowType.pKey);
        const billingAccountHolderName = this.ecimAdapter.getBillingAccountHolderName(getAgreementResponse);
        const canSelectPaperlessBilling = this.ecimAdapter.getCanSelectPaperlessBilling(internetRegistrationResponse) && !isCollectionPayment && canManage;
        const isEligibleForEasyPay = false;
        const isReinstatementEligible = this.isPolicyEligibleForReinstate(
            rcaResponse,
            this.paymentFlowType.pKey
        );
        const emailAddress =
            this.ecimAdapter.getUpdatedEmailAddress(internetRegistrationResponse) ||
            this.mapEmailAddress(rcaResponse);
        const isWarnedPolicy = this.isWarnedPolicy(
            rcaResponse,
            this.paymentFlowType.pKey
        );
        const maxPaymentDateAllowed = this.getMaxPaymentDatePolicyLevel(
            rcaResponse,
            this.paymentFlowType.pKey
        );
        const paymentDueDate = maxPaymentDateAllowed;
        const isPaymentCapExceeded = this.isCapPaymentExceeded(rcaResponse);
        const oipaPolicyAmountDue = this.getOipaAccountAmountDue(
            rcaResponse,
            this.paymentFlowType.pKey
        );
        const hasCareMatters = this.containsCareMattersPolicy(rcaResponse);
        const isOipaPolicy = this.containsOipaPolicy(rcaResponse);
        const nonOipaPolicyAmountDue = this.getOipaAccountAmountDue(
            rcaResponse,
            this.paymentFlowType.pKey
        );
        const isAmountDuePaymentCap =
            Number(oipaPolicyAmountDue) > PayBill.PAYMENT_CAP;
        const isAmountDueBankCardCap =
            Number(oipaPolicyAmountDue) > PayBill.BANKCARD_CAP;
        const reinstatePolicyNumber = this.reinstatePolicyNumber(
            rcaResponse,
            this.paymentFlowType.pKey
        );
        let oipaPolicyPaymentCapAcheived;
        let careMattersPolicyPaymentCapAcheived;
        let nonOipaPolicyPaymentCapAcheived;
        if (isOipaPolicy) {
            oipaPolicyPaymentCapAcheived = isAmountDuePaymentCap;
        } else if (hasCareMatters) {
            careMattersPolicyPaymentCapAcheived = isAmountDueBankCardCap;
        } else if (!isOipaPolicy && !hasCareMatters) {
            nonOipaPolicyPaymentCapAcheived = nonOipaPolicyAmountDue;
        }
        this.session.isReinstatementEligible = isReinstatementEligible;
        const partialPaymentHistory: PaymentHistoryItem[] =
            this.getPartialPaymentHistoryPolicyLevel(
                paymentHistoryResponse,
                this.paymentFlowType.pKey
            );
        const displayPaymentDateSection =
            !this.isPaymentScheduledPolicyLevel(
                rcaResponse,
                this.paymentFlowType.pKey
            ) &&
            this.isSchedulePaymentAllowedPolicyLevel(
                rcaResponse,
                this.paymentFlowType.pKey
            );
        const agreementStatus = this.getBillingAccountAgreementStatus(
            this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse))
        );
        const nextPaymentDate: Date = this.getNextPaymentDate(rcaResponse);
        const nextPaymentAmount = this.getNextPaymentAmount(billingAccount);
        const productName = this.getPolicyProductName(rcaResponse, this.paymentFlowType.pKey);

        const statementAmount = NaN; /* SET TO NAN AS STATEMENT AMOUNT IS NOT YET NEEDED FOR POLICY LEVEL PAYMENTS */
        const fullPayAmount = Number(this.getFullPayPolicyLevelAmount(rcaResponse, this.paymentFlowType.pKey));

        const hasScheduledPaymentIndicator = this.isPaymentScheduledPolicyLevel(
            rcaResponse,
            this.paymentFlowType.pKey
        );

        const scheduledPaymentAmount = this.scheduledPaymentAmountPolicyLevel(
            rcaResponse,
            this.paymentFlowType.pKey
        );

        const scheduledPaymentDate = this.scheduledPaymentDatePolicyLevel(
            rcaResponse,
            this.paymentFlowType.pKey
        );

        const isAutomaticPaymentSuspended = this.isAutomaticPaymentSuspended(rcaResponse);

        const billingNextDueDate = undefined;

        const producerNumbers =
            this.ctmAdapter.getProducerNumbers(getAgreementResponse);
        const agreement = this.getAgreement(rcaResponse, this.paymentFlowType.pKey);
        let messages: Unmapped.Message[] = [];
        if (agreement?.policyDetail?.billingDetail?.message instanceof Array) {
            messages = agreement?.policyDetail?.billingDetail?.message;
        } else {
            messages = [agreement?.policyDetail?.billingDetail?.message];
        }
        const lastDayToPayBeforeCancelled =
            this.getParameterValueFromMultipleMessages(
                messages,
                'lastDayToPayBeforeCancelled',
                Date
            );
        const cancellationDate = this.getParameterValueFromMultipleMessages(
            messages,
            'cancellationDate',
            Date
        );
        const canViewBill = this.canViewBilling(rcaResponse);
        const isShowSuspendAutomaticPaymentMessage = !this.isPaymentScheduledAccountLevel(rcaResponse) &&
            this.extracthasAutomaticPaymentsSuspended(billingAccount) &&
            !agreementStatus.toUpperCase().includes('PAID') && isWarnedPolicy;
        this.session.isCollectionPayment = isCollectionPayment;
        const automaticPayments = this.enrolled(rcaResponse);
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const paymentPlan = this.getPaymentPlan(billingAgreement);
        const agreementType = billingAgreement?.agreementType;
        const installmentFee = billingAgreement?.billingAccountDetail?.billingDetail ? parseFloat(billingAgreement.billingAccountDetail.billingDetail.installmentFee) : undefined;
        const minimumAmountDue = this.getMinimumPolicyLevelAmount(rcaResponse, this.paymentFlowType.pKey);
        const amountDueFromBilling = this.getMinimumAccountLevelAmount(billingAgreement);
        return new PayBillLanding(
            amountDueFromBilling,
            lastPayment,
            paymentAmountOptions,
            paymentMethods,
            canSelectPaperlessBilling,
            isReinstatementEligible,
            isEligibleForEasyPay,
            isWarnedPolicy,
            paymentDueDate,
            maxPaymentDateAllowed,
            emailAddress,
            state,
            city,
            streetLine1,
            displayPaymentDateSection,
            sourceSystem,
            '',
            agreementStatus,
            partialPaymentHistory,
            productName,
            statementAmount,
            fullPayAmount,
            billingAccountHolderName,
            producerNumbers,
            isPaymentCapExceeded,
            oipaPolicyAmountDue,
            hasCareMatters,
            isOipaPolicy,
            oipaPolicyPaymentCapAcheived,
            careMattersPolicyPaymentCapAcheived,
            nonOipaPolicyPaymentCapAcheived,
            nextPaymentDate,
            nextPaymentAmount,
            reinstatePolicyNumber,
            isAutomaticPaymentSuspended,
            billingNextDueDate,
            hasScheduledPaymentIndicator,
            scheduledPaymentAmount,
            scheduledPaymentDate,
            undefined,
            '',
            '',
            false,
            '',
            lastDayToPayBeforeCancelled,
            cancellationDate,
            isShowSuspendAutomaticPaymentMessage,
            canManage,
            isCollectionPayment,
            canViewBill,
            collectionPolicyNumber,
            automaticPayments,
            paymentPlan,
            agreementType,
            installmentFee,
            minimumAmountDue,
            isSingleActiveAutoPolicy
        );
    }

    private setPayMidTermChangeLogData(data: any): void {
        if (!this.session.payMidTermChangeLog) {
            this.session.payMidTermChangeLog = {
                agreementNumber: this.session.payMidTermChangeAgreementNumber,
                appSession: sessionStorage.getItem('camSessionId'),
                ecn: this.session.ecn,
                event: 'paymentConfirmationLoad',
                eventType: 'testLearn-digex6',
                groupAssignment: data.experimentFlag,
                zData: {
                    previousPremium: data.previousPremium,
                    newPremium: data.newPremium,
                    minimumDue: data.minimumDue,
                    proratedPremiumChange: data.proratedPremiumChange,
                    changeId: data.policyChangeId
                }
            };
        }
    }

    private mapLastPayment(
        rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse,
        pKey?: string
    ): LastPayment {
        if (rcaResponse) {
            const billingAgreement = this.getBillingAgreement(rcaResponse);
            const policies = this.getPolicies(rcaResponse);

            const parameters = this.getParameters(billingAgreement);
            let reinstatementFlag;
            for (const agreement of policies) {
                if (agreement.refId === pKey) {
                    reinstatementFlag = this.getReinstateFlag(agreement);
                }
            }
            return {
                amount:
                    reinstatementFlag === 'E' ?
                        this.getAmountOfReinstatement(billingAgreement) :
                        this.getLastPaymentAmountAccountLevel(parameters) ||
                        this.getPaymentAmount(billingAgreement),
                pendingPaymentCount:
                    billingAgreement?.billingAccountDetail?.billingDetail
                        ?.pendingPaymentCount,
                pendingPaymentTotal:
                    billingAgreement?.billingAccountDetail?.billingDetail
                        ?.pendingPaymentTotal,
                account: this.session.billingAccountNumber,
                date:
                    reinstatementFlag === 'E' ?
                        this.getPaymentDateOfReinstatement(billingAgreement) :
                        this.getLastPaymentDateAccountLevel(parameters) ||
                        this.getPaymentDate(billingAgreement),
                warningIndicator: this.isAccountWarned(billingAgreement),
                lastPaymentsSum: this.getLastPaymentsSumAccountLevel(parameters),
                reinstatementFlag
            };
        }
    }

    private isSingleActiveAutoPolicyExisting(policyAgreements: any): boolean {
        const activePolicies = policyAgreements?.filter((policy) => policy?.status?.toLowerCase() === 'active');
        let singleActiveAutoPolicyFlag = false;
        if (activePolicies?.length === 1) {
            if (activePolicies[0].agreementLabel) {
                singleActiveAutoPolicyFlag = activePolicies[0].agreementLabel.toLowerCase() === 'auto';
            } else if (activePolicies[0].lineOfBusiness) {
                singleActiveAutoPolicyFlag = activePolicies[0].lineOfBusiness.toLowerCase() === 'personal auto';
            }
        }
        return singleActiveAutoPolicyFlag;
    }

    canViewBilling(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        let canViewBill = false;
        try {
            const billingAgreement = this.getBillingAgreement(rcaResponse);
            billingAgreement.roleInAgreement.permission.forEach((permission) => {
                if (permission.name === 'canViewBilling' && permission.value === 'true') {
                    canViewBill = true;
                }
            });
        } catch (error) {
            canViewBill = false;
        }
        this.session.onViewBillChange(canViewBill);
        return canViewBill;
    }

    isPaymentCollection(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string = null): { isCollectionPayment: boolean; collectionPolicyNumber: string } {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        let isCollectionPayment = false;
        let collectionPolicyNumber = '';
        let collectionPolicyIndex = -1;
        collectionPolicyIndex = agreementList.findIndex((agreement) => {
            if (agreement?.policyDetail) {
                const isCollectionAgreement = agreement?.policyDetail?.billingDetail?.message?.name === 'billing.inline.Collection';
                return pkey ? (isCollectionAgreement && agreement.refId === pkey) : isCollectionAgreement;
            } else if (agreement?.billingAccountDetail) {
                const billingDetailsMessage = Array.isArray(agreement?.billingAccountDetail?.billingDetail?.message) ?
                    Array.from(agreement?.billingAccountDetail?.billingDetail?.message) : [agreement?.billingAccountDetail?.billingDetail?.message];
                return billingDetailsMessage?.some((message) => {
                    const isCollectionMessage = message?.name === 'billing.inline.Collection';
                    return pkey ? (isCollectionMessage && agreement.refId === pkey) : isCollectionMessage;
                });
            }
            return false;
        }

        );
        isCollectionPayment = collectionPolicyIndex >= 0;
        if (isCollectionPayment) {
            collectionPolicyNumber = agreementList[collectionPolicyIndex].agreementNumber;
        }

        return { isCollectionPayment, collectionPolicyNumber };
    }
}

export interface MapPayBillLandingParams {
    rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse;
    unmappedPayMethods: IsbExperience.SavedPaymentMethod[];
    paymentHistoryResponse: RetrivePaymentHistoryResponse;
    internetRegistrationResponse: UnmappedECIM.EnterpriseCustomerInformationManagementResponse;
    getAgreementResponse: UnmappedECIM.Agreement;
    contextCacheResponse?: RetrieveContextResponse;
}
