import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-save-as-checkbox',
    templateUrl: './save-as-checkbox.component.html',
    styleUrls: [
        './save-as-checkbox.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class SaveAsCheckboxComponent {
    @Input() label: string;
    @Input() arialabel: string;
    @Input() helpTextHtml: string;
    @Input() defaultChecked: boolean;
    @Input() bolt = false;
}
