import { Injectable } from '@angular/core';
import { IsbExperience } from '@nationwide/dgs-angular-billing-common';

@Injectable()
export class CancelScheduleFuturePaymentFlow {
    paymentAmount: string;
    paymentMethodType: string;
    paymentDate: string;
    flowInPlay: boolean;
    unmappedPaymentHistory: IsbExperience.RetrievePaymentsResponse;
    apiCallSuccessful: boolean;
    apiCallResponseCode: number;
    receiptId: string;
    nextDueDate: string;

    save(params: Partial<CancelScheduleFuturePaymentFlow>): void {
        this.flowInPlay = true;
        this.unmappedPaymentHistory = params.unmappedPaymentHistory == null ? this.unmappedPaymentHistory : params.unmappedPaymentHistory;
        this.paymentAmount = params.paymentAmount == null ? this.paymentAmount : params.paymentAmount;
        this.paymentMethodType = params.paymentMethodType == null ? this.paymentMethodType : params.paymentMethodType;
        this.paymentDate = params.paymentDate == null ? this.paymentDate : params.paymentDate;
        this.nextDueDate = params.nextDueDate == null ? this.nextDueDate : params.nextDueDate;
        this.apiCallSuccessful = params.apiCallSuccessful == null ? this.apiCallSuccessful : params.apiCallSuccessful;
        this.receiptId = params.receiptId == null ? this.receiptId : params.receiptId;
        this.apiCallResponseCode = params.apiCallResponseCode == null ? this.apiCallResponseCode : params.apiCallResponseCode;
    }

    reset(): void {
        this.paymentAmount = null;
        this.paymentMethodType = null;
        this.paymentDate = null;
        this.flowInPlay = null;
        this.unmappedPaymentHistory = null;
        this.apiCallSuccessful = null;
        this.receiptId = null;
        this.apiCallResponseCode = null;
    }
}
