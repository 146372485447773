import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';
import { ControlContainer, AbstractControl } from '@angular/forms';
import { PaymentItem } from '../../../billing/payment-preferences/shared/models/payment-item.model';
import { DistributedDocumentsResponseModel } from './eft-authorization-form/model/distributed-documents-response.model';
import { EFTAuthorizationAgreementParams, CCAuthorizationAgreementParams } from './easy-pay-authorization-agreement.service';
import { AgencyInformationResponseModel } from './eft-authorization-form/model/agency-information-response.model';
import { AuthConfirmationDetailsElements } from './eft-authorization-form/form-elements/auth-confirmation-details.elements';
import { AccountPolicyHolderElements } from './eft-authorization-form/form-elements/account-policy-holder-elements';
import { ReceiptDetailsElements } from './eft-authorization-form/form-elements/receipt-details-elements';
import { EftAuthorizationFormElements } from './eft-authorization-form/form-elements/eft-authorization-form-elements';
import { EftAuthorizationFormHelperService } from './eft-authorization-form-helper.service';
import { BamLtrFlagsElements } from './eft-authorization-form/form-elements/bam-ltr-flags-elements';
import { SessionService } from '../../session/session.service';
export type ControlNames = 'acknowledged';

@Component({
    selector: 'app-ez-pay-authorization',
    templateUrl: './ez-pay-authorization.component.html',
    styleUrls: [
        './ez-pay-authorization.component.scss',
        '../stylesheets/form-formatting.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss'
    ]
})
export class EzPayAuthorizationComponent extends BaseFormGroupComponent<ControlNames> implements OnInit {
    @Input() displayBillingAccountInformation = false;
    @Input() set paymentMethodType(newType: 'CC' | 'EFT' | PaymentItem) {
        if (newType && newType instanceof PaymentItem) {
            this.chosenPaymentMethodType = newType.isBankAccount ? 'EFT' : 'CC';
        } else {
            this.chosenPaymentMethodType = <'CC' | 'EFT'>newType;
        }
    }
    @Input() authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
    @Input() isAcknowledgedCheckboxDisable: boolean;
    @Input() distributedDocumentsResponseModel: DistributedDocumentsResponseModel;
    @Output() onClickAuthAgreementLink = new EventEmitter();
    chosenPaymentMethodType: 'CC' | 'EFT';
    agencyInformationResponseModel: AgencyInformationResponseModel;
    accountPolicyHolderElements: AccountPolicyHolderElements;
    authConfirmationDetailsElements: AuthConfirmationDetailsElements;
    receiptDetailsElements: ReceiptDetailsElements;
    bamLtrFlagsElements: BamLtrFlagsElements;
    eftAuthorizationFormElements: EftAuthorizationFormElements;
    authorizationFormUrl: string;

    // eslint-disable-next-line max-params
    constructor(
        controlContainer: ControlContainer,
        private eftAuthorizationFormHelperService: EftAuthorizationFormHelperService,
        private session: SessionService,
        @Inject('window') private window: Window
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        if (!this.isNeitherEFTNorCC() && this.isEFTForm()) {
            this.disableAcknowledgedCheckbox();
        }

        this.setAuthAggrementResponseToSession();
    }

    setAuthAggrementResponseToSession(): void {
        this.session.authAggrementResponse = this.authorizationAgreementParams;
    }

    get acknowledged(): AbstractControl {
        return this.getControl('acknowledged');
    }

    get acknowledgementErrorsDisplayable(): boolean {
        return this.errorsDisplayable('acknowledged') || this.acknowledgementDisabled;
    }

    get acknowledgementDisabled(): boolean {
        return this.acknowledged.disabled && this.acknowledged.touched;
    }

    openAuthForm(): void {
        this.enableAcknowledgedCheckbox();
        this.onClickAuthAgreementLink.emit();
        this.getAuthorizationFormUrl();
    }

    getAuthorizationFormUrl(): string {
        let authorizationFormUrl = '';
        if (!this.isNeitherEFTNorCC()) {
            if (this.isEFTForm() && this.session.isNBP) {
                if (this.distributedDocumentsResponseModel?.fileOutput) {
                    authorizationFormUrl = this.eftAuthorizationFormHelperService.convertBase64EncodedTextToPdfContent(this.distributedDocumentsResponseModel.fileOutput);
                }
            } else {
                authorizationFormUrl = '/#/personal/ez-pay-auth-agreement';
            }
            this.window.open(authorizationFormUrl, '_blank');
        }
        return authorizationFormUrl;
    }

    isEFTForm(): boolean {
        return this.authorizationAgreementParams?.accountType === 'EFT';
    }

    isNeitherEFTNorCC(): boolean {
        return !this.authorizationAgreementParams;
    }

    disableAcknowledgedCheckbox(): void {
        if (this.isAcknowledgedCheckboxDisable) {
            this.acknowledged.disable();
        }
    }

    enableAcknowledgedCheckbox(): void {
        this.acknowledged.enable();
    }
}
