import { Injectable } from '@angular/core';
import { BillingTile, BillingTileAdapter, MapBillingTileParams } from '@nationwide/angular-billing-tile';
import { PBS as Unmapped } from '@nationwide/dgs-angular-billing-common';
import { RemoveDuplicatesPipe } from '@nationwide/internet-servicing-angular-pipes';
import { IsbSharedCommonHelperService } from '../../shared/isb-shared-common-helper.service';
import { SessionService } from '../../shared/session/session.service';
import { SharedRCAAdapter } from '../../shared/shared-base.adapter';

@Injectable()
export class BillingBaseAdapter extends SharedRCAAdapter {
    // eslint-disable-next-line max-params
    constructor(
        removeDuplicatesPipe: RemoveDuplicatesPipe,
        private billingTileAdapter: BillingTileAdapter,
        session: SessionService,
        protected sharedService: IsbSharedCommonHelperService
    ) {
        super(
            removeDuplicatesPipe,
            session,
            sharedService
        );
    }

    mapBillingTile(params: MapBillingTileParams): BillingTile {
        return this.billingTileAdapter.mapBillingTile(params);
    }

    mapAgreementNumbers(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string[] {
        const agreements = rcaResponse.retrieveCustomerAgreementResponse.return.profile.agreementGroup.agreement;
        return agreements.length && agreements
            .filter((agreement) => agreement.agreementType === 'POLICY')
            // eslint-disable-next-line no-extra-parens
            .map((policy) => (policy.issuingCompany === 'Allied' ? this.formatPolicyNumber(policy.agreementNumber) : policy.agreementNumber)) || [];
    }

    mapActivePolicyNumbers(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string[] {
        const agreements = rcaResponse.retrieveCustomerAgreementResponse.return.profile.agreementGroup.agreement;
        // eslint-disable-next-line no-extra-parens
        return this.mapActivePolicyAgreement(agreements).map((policy) => (policy.issuingCompany === 'Allied' ? this.formatPolicyNumber(policy.agreementNumber) :
            policy.agreementNumber)) || [];
    }

    mapPolicyNumbersRelatedToAccount(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string[] {
        const agreements = rcaResponse.retrieveCustomerAgreementResponse.return.profile.agreementGroup.agreement;
        return agreements.length && agreements
            .filter((agreement) => {
                agreement = this.sharedService.transformRefIdToArray(agreement);
                return agreement.agreementType === 'POLICY' && agreement.relatedAgreement?.refId?.includes(this.session.billingAccountNumber);
            })
            // eslint-disable-next-line no-extra-parens
            .map((policy) => (policy.issuingCompany === 'Allied' ? this.formatPolicyNumber(policy.agreementNumber) : policy.agreementNumber)) || [];
    }

    mapActiveProductTypes(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string[] {
        const agreements = rcaResponse.retrieveCustomerAgreementResponse.return.profile.agreementGroup.agreement;
        return this.mapActivePolicyAgreement(agreements).map((policy) => policy.agreementLabel) || [];
    }

    private mapActivePolicyAgreement(agreements: any[]): Unmapped.Agreement[] {
        return (
            agreements.length &&
            agreements
                .filter((agreement) => agreement.agreementType === 'POLICY' && agreement.status.toUpperCase() === 'ACTIVE')
        );
    }
    mapProductTypes(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string[] {
        const agreements = rcaResponse.retrieveCustomerAgreementResponse.return.profile.agreementGroup.agreement;
        return agreements.length && agreements
            .filter((agreement) => agreement.agreementType === 'POLICY' && (agreement.status !== 'Cancelled' || agreement?.policyDetail?.reinstatementFlag === 'E'))
            .map((policy) => policy.agreementLabel) || [];
    }
}
