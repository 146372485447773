import { InjectionToken } from '@angular/core';
import { ISBRouteParams } from '../../shared/routing/isb-route.model';

export interface MakePaymentConfig {
    urls: {
        cancelMakePayment: (canAccessBillingPages: boolean, isQuickpay: boolean, parameters?: ISBRouteParams) => string;
    };
}

export const makePaymentConfigToken = new InjectionToken<MakePaymentConfig>('makePaymentConfigToken');
