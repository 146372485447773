import { Component } from '@angular/core';

@Component({
    selector: 'app-cancel-schedule-future-payment',
    templateUrl: './cancel-schedule-future-payment.component.html',
    styleUrls: ['./cancel-schedule-future-payment.component.scss']
})
export class CancelScheduleFuturePaymentComponent {
    constructor() { }
}
