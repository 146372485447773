import { Injectable, ElementRef, Inject } from '@angular/core';
import { EXTERNAL_BTL } from '../billing-timeline-widget.constants';
import { PersonalBillingConfiguration } from '@nationwide/dgs-angular-billing-common';
import { environment } from '../../../environments/environment';
import { OAuthConfig } from '@nationwide/angular-oauth-module';
import { McrConfig, PaymentKillswitch } from '@nationwide/angular-timeline';
import { LoggerService } from '../../shared/logger/logger.service';

@Injectable()
export class ConsumerConfigurerService {
    authMehodArray: string[] = [];
    private loggedInputs = {};
    private elementRef: ElementRef;

    // eslint-disable-next-line max-params
    constructor(
        @Inject('logger') private logger: LoggerService,
        @Inject('ctAuthConfig') private oauthConfig: OAuthConfig,
        private mcrConfig: McrConfig,
        private personalBillingConfiguration: PersonalBillingConfiguration,
        private paymentKillswitch: PaymentKillswitch
    ) {
        this.authMehodArray = ['ping-jwt', 'cleartrust'];
    }

    setupTimeline(elementRef: ElementRef): void {
        this.elementRef = elementRef;
        [
            { configurerProperty: 'realm', consumerInput: 'realm' },
            { configurerProperty: 'authMethod', consumerInput: 'auth-method' },
            { configurerProperty: 'identityMethod', consumerInput: 'identity-method' },
            { configurerProperty: 'loginTokenParam', consumerInput: 'login-token' },
            { configurerProperty: 'redirectUri', consumerInput: 'redirect-uri' },
            { configurerProperty: 'isMcr', consumerInput: 'mcr' },
            { configurerProperty: 'billingAccountNumber', consumerInput: 'billing-account-number' },
            { configurerProperty: 'userId', consumerInput: 'user-id' },
            { configurerProperty: 'paymentsAvailable', consumerInput: 'payments-enabled' },
            { configurerProperty: 'loggerToken', consumerInput: 'logger-token' },
            { configurerProperty: 'euaAPIHost', consumerInput: 'eua-api-host' },
            { configurerProperty: 'personalLinesBillingAPIHost', consumerInput: 'personal-lines-billing-api-host' },
            { configurerProperty: 'clientLoggingAPIHost', consumerInput: 'client-logging-api-host' },
            { configurerProperty: 'oAuthRequired', consumerInput: 'is-oauth-required' },
            { configurerProperty: 'billingRegion', consumerInput: 'billing-region' },
            { configurerProperty: 'accessToken', consumerInput: 'access-token' },
            { configurerProperty: 'clientID', consumerInput: 'client-id' }
        ]

            .forEach((mapping) => {
                const { configurerProperty, consumerInput } = mapping;
                const inputValue = this.getConsumerInput(consumerInput);
                if (inputValue && inputValue !== 'null') {
                    this[configurerProperty] = inputValue;
                }
                this.loggedInputs[consumerInput] = inputValue;
            });
        environment.tridion.endpoint = `${environment.ISB.HOST_URL}/tridion`;

        this.logger.debug('CONSUMER INPUTS', this.loggedInputs);
    }

    private getConsumerInput(inputName: string): string {
        const inputValue: string = this.elementRef.nativeElement.getAttribute(inputName);
        this.loggedInputs[inputName] = inputValue;
        return inputValue;
    }

    set realm(realm: string) {
        this.oauthConfig.realm = realm;
        if (realm === 'distribution-partner') {
            this.personalBillingConfiguration.isConnectionTypeRequired = true;
        }
    }

    set identityMethod(identityMethod: string) {
        this.oauthConfig.identityMethod = identityMethod;
    }

    set authMethod(inputAuthMethod: string) {
        if (this.authMehodArray.includes(inputAuthMethod)) {
            this.oauthConfig.auth_method = <'ping-jwt' | 'cleartrust'>inputAuthMethod;
        } else if (inputAuthMethod) {
            throw new Error('Please provide either ping-jwt Or cleartrust values for authmethod ');
        }
    }

    set loginTokenParam(loginTokenValue: string) {
        this.oauthConfig.loginTokenSessionStorageLocation = 'pingJwtSessionStorage';
        this.oauthConfig.loginTokenParam = 'pingToken';
        sessionStorage.setItem(this.oauthConfig.loginTokenParam, loginTokenValue);
    }

    set redirectUri(endpoint: string) {
        this.oauthConfig.redirectUri = endpoint;
    }

    set isMcr(isMcr: string | boolean) {
        if (typeof isMcr === 'string') {
            isMcr = this.parseBoolean(isMcr);
        }

        this.mcrConfig.isMcr = isMcr;
    }

    set billingAccountNumber(billingAccountNumber: string) {
        sessionStorage['BILLING_ACCOUNT_NUMBER'] = billingAccountNumber;
    }

    set userId(userId: string) {
        sessionStorage[EXTERNAL_BTL.SESSION_STORAGE_KEYS.USER_ID] = userId;
    }

    set paymentsAvailable(isPaymentsAvailable: string | boolean) {
        if (typeof isPaymentsAvailable === 'string') {
            isPaymentsAvailable = this.parseBoolean(isPaymentsAvailable);
        }
        this.paymentKillswitch.paymentsAvailable = isPaymentsAvailable;
    }

    set loggerToken(loggerToken: string) {
        if (loggerToken) {
            this.logger.splunkToken = loggerToken;
            this.logger.clientLoggingEnabled = true;
        } else {
            this.logger.splunkToken = undefined;
            this.logger.clientLoggingEnabled = false;
        }
    }

    set euaAPIHost(host: string) {
        this.oauthConfig.oauthEndpoint = `${host}${environment.EUA_ENDPOINT_URL}`;
    }

    set personalLinesBillingAPIHost(host: string) {
        this.personalBillingConfiguration.billsUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.bills(account)}`;
        this.personalBillingConfiguration.relatedPolicesUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.relatedPolicies(account)}`;
        this.personalBillingConfiguration.policyEventsUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.policiesEvents(account)}`;
        this.personalBillingConfiguration.transactionsUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.transactions(account)}`;
        this.personalBillingConfiguration.billingAccountUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.billingAccount(account)}`;
        this.personalBillingConfiguration.payPlanUrl = (account: string, method: string): string =>
            `${host}${environment.BILLING.PATHS.payPlan(account, method)}`;
        this.personalBillingConfiguration.zeroAmountDueUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.zeroAmountDue(account)}`;
        this.personalBillingConfiguration.patchPayPlanUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.patchPayPlan(account)}`;
        this.personalBillingConfiguration.billCalculationInquiryUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.billCalculationInquiry(account)}`;
        this.personalBillingConfiguration.retrieveDueDatesUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.billingDueDates(account)}`;
        this.personalBillingConfiguration.updateDueDatesUrl = (account: string): string =>
            `${host}${environment.BILLING.PATHS.billingAccount(account)}`;
        this.personalBillingConfiguration.retrieveRefundStatusesUrl = (account: string, method: string): string =>
            `${host}${environment.BILLING.PATHS.refundStatuses(account, method)}`;
    }

    set clientLoggingAPIHost(host: string) {
        environment.LOGGING.endpoint = `${host}${environment.LOGGING_ENDPOINT_URL}-cloud`;
    }

    set oAuthRequired(oAuthRequiredValue: string) {
        const isOAuthRequired = this.parseBoolean(oAuthRequiredValue);
        if (!isOAuthRequired) {
            this.personalBillingConfiguration.isAccessTokenRequired = false;
        }
    }

    set accessToken(accessTokenValue: string) {
        sessionStorage.setItem(environment.OAUTH_CONFIG.tokenStorage, accessTokenValue);
    }

    set clientID(clientIDValue: string) {
        this.personalBillingConfiguration.clientId = clientIDValue;
        environment.CLIENT_ID = clientIDValue;
    }

    set billingRegion(billingRegionValue: string) {
        if (billingRegionValue) {
            this.personalBillingConfiguration.connectionType = `CI${billingRegionValue}`;
        }
    }

    private parseBoolean(booleanString: string): boolean {
        return booleanString === 'true';
    }
}
