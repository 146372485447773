/* eslint-disable no-extra-parens */
/* eslint-disable prefer-template */
import { Injectable } from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { forkJoin, Observable, of, flatMap, tap, map, share, ReplaySubject } from 'rxjs';
import {
    AgencyInformationService,
    DistributedDocumentsService,
    DpimAgencyInformationService,
    DistributedDocumentResponse
} from '@nationwide/dgs-angular-billing-common';
import { EftAuthorizationAdapter } from './eft-authorization-form/adapters/eft-authorization.adapter';
import { PaymentPreferencesApiService } from '../../../billing/payment-preferences/shared/services/payment-preferences-api/payment-preferences-api.service';
import { AgencyInformationResponseModel } from './eft-authorization-form/model/agency-information-response.model';
import { EftAuthorizationFormElements } from './eft-authorization-form/form-elements/eft-authorization-form-elements';
import { EftAuthorizationFormHelperService } from './eft-authorization-form-helper.service';
import { SessionService } from '../../session/session.service';
import { EftAuthorizationFormValidationService } from './eft-authorization-form-validation.service';
import { BillingAccountsService } from '../../../../app/billing-details/billing-acccount-picker/billing-accounts.service';
import { EFT_AUTH_FORM_CONSTANT } from '../../../../app/shared/constants/common.constants';

@Injectable()
export class EasyPayAuthorizationAgreementService {
    callAgencyInformation: any;

    // eslint-disable-next-line max-params
    constructor(
        private datePipe: DatePipe,
        private titleCasePipe: TitleCasePipe,
        private session: SessionService,
        private agencyInformationService: AgencyInformationService,
        private distributedDocumentsService: DistributedDocumentsService,
        private eftAuthorizationAdapter: EftAuthorizationAdapter,
        private paymentPreferencesApiService: PaymentPreferencesApiService,
        private eftAuthorizationFormHelperService: EftAuthorizationFormHelperService,
        private dpimAgencyInformationService: DpimAgencyInformationService,
        private eftAuthorizationFormValidationService: EftAuthorizationFormValidationService,
        private billingAccountsService: BillingAccountsService
    ) { }

    easyPayAuthLink(authAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams): string {
        const formattedParams = this.formatParams(authAgreementParams);
        const uriEncodedParams = this.uriEncodeParams(formattedParams);
        return authAgreementParams.accountType === 'EFT' ?
            this.easyPayAuthLinkEFT(<EFTAuthorizationAgreementParams>uriEncodedParams) : this.easyPayAuthLinkCC(<CCAuthorizationAgreementParams>uriEncodedParams);
    }

    fetchEFTAuthFormsAlreadyEnrolled(authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams): Observable<DistributedDocumentResponse> {
        let eftAuthorizationFormElements: EftAuthorizationFormElements;
        let areEftAuthorizationFormElementsValid: boolean;
        const distributedDocumentResponse: DistributedDocumentResponse = this.getIncompleteFormElementsDistributedDocumentResponse();
        return forkJoin({
            rcaResponse: this.paymentPreferencesApiService.managePaymentPreferencesBase(),
            agencyInformation: this.agencyInformationService.retrieveAgencyInformation({
                accessToken: this.session.accessToken,
                agreementNumber: this.session.billingAccountNumber
            }),
            policies: this.billingAccountsService.getPoliciesForAgreement({
                accountNumber: this.session.billingAccountNumber, accessToken: this.session.accessToken
            }),
            internetRegistrationResponse: this.paymentPreferencesApiService.retrieveInternetRegistrationResponse()
        }).pipe(
            tap((responses) => {
                const agencyInformationResponseModel: AgencyInformationResponseModel = this.eftAuthorizationAdapter.buildAgencyInformationResponseModel(responses.agencyInformation);

                eftAuthorizationFormElements = this.eftAuthorizationAdapter.mapEftAuthorizationFormElements({
                    rcaResponse: responses.rcaResponse,
                    relatedPolicies: responses.policies.relatedPolicies,
                    agencyInformationResponseModel,
                    authorizationAgreementParams: <EFTAuthorizationAgreementParams>authorizationAgreementParams,
                    internetRegistrationResponse: responses.internetRegistrationResponse
                });
                areEftAuthorizationFormElementsValid = this.eftAuthorizationFormValidationService.validateEftAuthorizationFormElementsAlreadyEnrolled(eftAuthorizationFormElements, agencyInformationResponseModel);
            }),
            flatMap(() => (
                areEftAuthorizationFormElementsValid ? this.distributedDocumentsService.retrieveEFTAuthorizationForms({
                    accessToken: this.session.accessToken,
                    payload: this.eftAuthorizationFormHelperService.buildEFTAuthorizationFormsRequest(eftAuthorizationFormElements)
                }) : of(distributedDocumentResponse)
            ))
        );
    }

    fetchEFTAuthFormsForNewlyEnrolled(authorizationAgreementParams: EFTAuthorizationAgreementParams, agencyInformationResponseModel: AgencyInformationResponseModel): Observable<DistributedDocumentResponse> {
        let eftAuthorizationFormElements: EftAuthorizationFormElements;
        let areEftAuthorizationFormElementsValid: boolean;
        const distributedDocumentResponse: DistributedDocumentResponse = this.getIncompleteFormElementsDistributedDocumentResponse();
        return forkJoin({
            rcaResponse: this.paymentPreferencesApiService.managePaymentPreferencesBase(),
            policies: this.billingAccountsService.getPoliciesForAgreement({
                accountNumber: this.session.billingAccountNumber, accessToken: this.session.accessToken
            }),
            internetRegistrationResponse: this.paymentPreferencesApiService.retrieveInternetRegistrationResponse()
        })
            .pipe(
                tap((responses) => {
                    eftAuthorizationFormElements = this.eftAuthorizationAdapter.mapEftAuthorizationFormElements({
                        rcaResponse: responses.rcaResponse,
                        relatedPolicies: responses.policies.relatedPolicies,
                        agencyInformationResponseModel,
                        authorizationAgreementParams,
                        internetRegistrationResponse: responses.internetRegistrationResponse
                    });
                    areEftAuthorizationFormElementsValid = this.eftAuthorizationFormValidationService.validateEftAuthorizationFormElementsNewlyEnrolled(eftAuthorizationFormElements);
                }),
                flatMap(() => (
                    areEftAuthorizationFormElementsValid ? this.distributedDocumentsService.retrieveEFTAuthorizationForms({
                        accessToken: this.session.accessToken,
                        payload: this.eftAuthorizationFormHelperService.buildEFTAuthorizationFormsRequest(eftAuthorizationFormElements)
                    }) : of(distributedDocumentResponse)
                ))
            );
    }

    fetchAgencyInformationFuture(): Observable<AgencyInformationResponseModel> {
        if (!this.callAgencyInformation) {
            this.callAgencyInformation = forkJoin({
                agencyInformation: this.session.isNBP ?
                    this.agencyInformationService.retrieveAgencyInformation({
                        accessToken: this.session.accessToken,
                        agreementNumber: this.session.billingAccountNumber
                    }) : this.dpimAgencyInformationService.retrieveDpimAgencyInformation({
                        accessToken: this.session.accessToken,
                        agentNumber: EFT_AUTH_FORM_CONSTANT.DPIM_TEMP_AGENT_NUMBER
                    })
            }).pipe(
                map((response) =>
                    this.eftAuthorizationAdapter.buildAgencyInformationResponseModelFuture(response.agencyInformation)
                ),
                share({ connector: () => new ReplaySubject(1) })
            );
        }
        return this.callAgencyInformation;
    }

    fetchAgencyInformation(): Observable<AgencyInformationResponseModel> {
        if (!this.callAgencyInformation) {
            this.callAgencyInformation = this.agencyInformationService.retrieveAgencyInformation({
                accessToken: this.session.accessToken,
                agreementNumber: this.session.billingAccountNumber
            }).pipe(
                map((response) =>
                    this.eftAuthorizationAdapter.buildAgencyInformationResponseModel(response)
                ),
                share({ connector: () => new ReplaySubject(1) })
            );
        }
        return this.callAgencyInformation;
    }

    private baseAuthLink(params: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams): string {
        return `/assets/html/ez-pay-auth-agreement.html?` +
            `bkey=${params.billingAccountNumber}` +
            `&accountHolder=${params.billingAccountHolderName}` +
            `&enrolledDate=${params.enrolledDate}` +
            `&paymentMethodType=${params.accountType}` +
            `&displayBillingInfo=${params.displayBillingInfo}`;
    }

    private easyPayAuthLinkEFT(authAgreementParams: EFTAuthorizationAgreementParams): string {
        const { bankName, methodType, maskedAccountNum, routingNumber } = authAgreementParams;
        return this.baseAuthLink(authAgreementParams) +
            `&bankName=${bankName}` +
            `&accountNumber=${maskedAccountNum}` +
            `&methodType=${methodType}` +
            `&routingNumber=${routingNumber}`;
    }

    private easyPayAuthLinkCC(authAgreementParams: CCAuthorizationAgreementParams): string {
        const { cardType, cardZip, maskedCardNum, expirationDate, payorName } = authAgreementParams;
        return this.baseAuthLink(authAgreementParams) +
            `&cardType=${cardType}` +
            `&payorName=${payorName}` +
            `&cardZip=${cardZip}` +
            `&cardNumber=${maskedCardNum}` +
            `&expirationDate=${expirationDate}`;
    }

    private formatParams(params: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams): EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams {
        let formattedParams = {
            ...params,
            billingAccountHolderName: this.titleCasePipe.transform(params.billingAccountHolderName),
            enrolledDate: this.datePipe.transform(params.enrolledDate, 'MM/dd/yyyy')
        };

        if (params['payorName']) {
            formattedParams = {
                ...formattedParams,
                payorName: this.titleCasePipe.transform(params['payorName'])
            };
        }

        if ((<CCAuthorizationAgreementParams>params).cardType) {
            (<CCAuthorizationAgreementParams>formattedParams).cardType = this.titleCasePipe.transform((<CCAuthorizationAgreementParams>params).cardType);
        }

        return formattedParams;
    }

    private uriEncodeParams(params: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams): EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams {
        const encodedParams = {};
        Object.keys(params).forEach((key) => encodedParams[key] = encodeURIComponent(params[key]));
        return <EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams>encodedParams;
    }

    private getIncompleteFormElementsDistributedDocumentResponse(): DistributedDocumentResponse {
        return {
            message: 'Incomplete Form Elements',
            returnCode: 300,
            fileName: '',
            fileOutput: ''
        };
    }
}

export interface AuthorizationAgreementParams {
    accountType: 'EFT' | 'CC';
    billingAccountHolderName: string;
    billingAccountNumber: string;
    enrolledDate: string | Date;
    displayBillingInfo: boolean;
    billingAccountUserName?: string;
}

export interface CCAuthorizationAgreementParams extends AuthorizationAgreementParams {
    cardType: string;
    cardZip: string;
    payorName: string;
    maskedCardNum: string;
    expirationDate: string;
}

export interface EFTAuthorizationAgreementParams extends AuthorizationAgreementParams {
    bankName: string;
    methodType: string;
    routingNumber: string;
    maskedAccountNum: string;
}
