import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
    MILLISECONDS_IN_SECOND,
    SECONDS_IN_MINUTE,
    MINUTES_IN_HOUR,
    HOURS_IN_DAY,
    LOCAL_NAME,
    DEFAULT_FORMAT_TYPE,
    PAYER_INFO_MAXIMUM_ALLOW_CHAR_API
} from './constants/common.constants';
import {
    MoneyProcessingRequestEnum as MPEnum,
    PayorInfo,
    PBS
} from '@nationwide/dgs-angular-billing-common';
import dayjs from 'dayjs';
@Injectable()
export class IsbSharedCommonHelperService {
    autoProductList = [
        'Auto',
        'Recreational Vehicle',
        'Personal Auto',
        'Antique / Classic Auto',
        'Non-Standard Auto'
    ];

    powerSportsProductList = [
        'RV',
        'Boat',
        'Motorcycle/Misc Vehicle'
    ];

    umbrellaProductList = [
        'Umbrella',
        'Personal Umbrella'
    ];

    propertyProductList = [
        'Renters',
        'Home',
        'Property',
        'Home',
        'Condo',
        'Mobile Home Property',
        'Primary Homeowners',
        'Secondary Homeowners',
        'Rental Homeowners',
        'Property Contents'];

    lifeProductList = [
        'Life',
        'Individual Life'];

    getFormattedDate(dateValue: Date): string {
        return dayjs(dateValue).format('MM/DD/YYYY');
    }

    formatDate(dateValue: Date, formatType: string): string {
        return dayjs(dateValue).format(formatType);
    }

    getDateDiff(date1: Date, date2: Date): number {
        const datediff = Math.round(Math.abs((date1.getTime() - date2.getTime()) /
            (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY)));
        return datediff;
    }

    getProductName(policyAgreement: any): MPEnum.ProductName {
        let productName: MPEnum.ProductName;

        if (this.autoProductList.includes(policyAgreement.lineOfBusiness) ||
            this.autoProductList.includes(policyAgreement.agreementLabel)) {
            productName = MPEnum.ProductName.AUTO;
        }

        if (this.propertyProductList.includes(policyAgreement.lineOfBusiness) ||
            this.propertyProductList.includes(policyAgreement.agreementLabel)) {
            productName = MPEnum.ProductName.PROPERTY;
        }

        if (this.lifeProductList.includes(policyAgreement.lineOfBusiness) ||
            this.lifeProductList.includes(policyAgreement.agreementLabel)) {
            productName = MPEnum.ProductName.LIFE;
        }

        if (this.powerSportsProductList.includes(policyAgreement.lineOfBusiness) ||
            this.powerSportsProductList.includes(policyAgreement.agreementLabel)) {
            productName = MPEnum.ProductName.POWER_SPORTS;
        }

        if (this.umbrellaProductList.includes(policyAgreement.lineOfBusiness) ||
            this.umbrellaProductList.includes(policyAgreement.agreementLabel)) {
            productName = MPEnum.ProductName.PERSONAL_UMBRELLA;
        }

        return productName;
    }
    encodeBase64(text: string): string {
        return btoa(text);
    }

    decodeBase64(text: string): string {
        return atob(text);
    }
    getCurrentDate(): string {
        return new DatePipe(LOCAL_NAME).transform(new Date(), DEFAULT_FORMAT_TYPE);
    }

    getCurrentDateWithGivenFormatType(formatType: string): string {
        return new DatePipe(LOCAL_NAME).transform(new Date(), formatType);
    }
    removeSpecialCharacter(value: string): string {
        return value ? value.replace(/[^\w]/gi, '') : null;
    }
    mapFullName(firstName: string, lastName: string, middleName: string): string {
        return middleName ? `${firstName} ${middleName} ${lastName}` :
            `${firstName} ${lastName}`;
    }

    truncatePayerInfo(argPayerInfo: PayorInfo): PayorInfo {
        const payerInfo = argPayerInfo;
        if (payerInfo?.firstName && payerInfo.lastName) {
            payerInfo.firstName = payerInfo.firstName.length > PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.FIRST_LAST_NAME ?
                payerInfo.firstName.slice(0, PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.FIRST_LAST_NAME) : payerInfo.firstName;
            payerInfo.lastName = payerInfo.lastName.length > PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.FIRST_LAST_NAME ?
                payerInfo.lastName.slice(0, PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.FIRST_LAST_NAME) : payerInfo.lastName;
            payerInfo.displayName = this.getDisplayName(payerInfo);
        }
        if (payerInfo?.addressLine1) {
            payerInfo.addressLine1 = payerInfo.addressLine1.length > PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.STREET_ADDRESS ?
                payerInfo.addressLine1.slice(0, PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.STREET_ADDRESS) : payerInfo.addressLine1;
        }
        return payerInfo;
    }

    getDisplayName(payerInfo: PayorInfo): string {
        return payerInfo.middleName ?
            `${payerInfo.firstName} ${payerInfo.middleName} ${payerInfo.lastName}` :
            `${payerInfo.firstName} ${payerInfo.lastName}`;
    }

    truncateCardholderName(name: string): string {
        return name?.length > PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.FIRST_LAST_NAME ?
            name?.slice(0, PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.FIRST_LAST_NAME) : name;
    }

    truncateCardholderBillingAddress(billingAddress: string): string {
        return billingAddress?.length > PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.BILLING_ADDRESS ?
            billingAddress?.slice(0, PAYER_INFO_MAXIMUM_ALLOW_CHAR_API.BILLING_ADDRESS) : billingAddress;
    }

    transformRefIdToArray(agreement: PBS.Agreement): PBS.Agreement {
        if (agreement.relatedAgreement?.refId) {
            agreement.relatedAgreement.refId = agreement.relatedAgreement.refId instanceof Array ? agreement.relatedAgreement.refId : [agreement.relatedAgreement.refId];
        }
        return agreement;
    }
}
