<div class="bolt-container bolt-space-bottom-2xl">

    <bw-bolt-progress-bar appFocusOnInit arialabel="Stop next automatic payment progress value is 100%" progressBarValue="100" class="progress-bar-container to-container"></bw-bolt-progress-bar>
    <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Stop next automatic payment: confirmation</h1>

    <ng-container *ngIf="!suspendEasyPayError && dueDate">
        <div class="bolt-space-bottom-2xl">
            <bw-bolt-notification type="success">
                <span class="success-notification">
                    We have paused your automatic payment for this billing cycle only. Please be sure to <a href="javascript:void(0)" (click)="onMakeAPayment()">make a payment</a> before your due date
                    on {{dueDate |
                    easternTime:'MM/dd/yyyy'}}. We will resume automatic payments for your next billing cycle.
                </span>
            </bw-bolt-notification>
        </div>

        <ng-container *ngIf="nextDueAmount">
            <app-payment-sub-header>Next steps</app-payment-sub-header>
            <div class="bolt-row">
                <div class="bolt-col">
                    <strong>Make a payment by:</strong>
                </div>
                <div class="bolt-col">
                    {{dueDate | easternTime:'MM/dd/yyyy'}}
                </div>
            </div>
            <div class="bolt-row">
                <div class="bolt-col">
                    <strong>Amount due:</strong>
                </div>
                <div class="bolt-col">
                    {{nextDueAmount | currency:'USD' }}
                </div>
            </div>
        </ng-container>

    </ng-container>
    <ng-container *ngIf="apiCallFailed">
        <bw-bolt-notification type="error">
            <span class="error-notification">{{ apiServiceDownMessage }}</span>
        </bw-bolt-notification>
    </ng-container>
</div>

<bolt-button-bar>
    <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
    <bolt-button id="pay-later-button" slot="back" (click)="onPayLater()">Pay later</bolt-button>
    <bolt-button id="make-a-payment-button" slot="forward" (click)="onMakeAPayment()" type="primary">
        Make a payment
    </bolt-button>
</bolt-button-bar>