import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlUtil } from '../../shared/url-util/url-util.service';
import { environment } from '../../../environments/environment';
import { SessionService } from '../../shared/session/session.service';
import { ISBRouteParams } from '../../shared/routing/isb-route.model';

@Injectable()
export class BillingCommonNavigationsService {

    constructor(
        private router: Router,
        private urlUtil: UrlUtil,
        private session: SessionService
    ) { }

    navigateToHardfall(): void {
        this.router.navigateByUrl('/personal/error/system-down');
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences(this.urlParams)
        );
    }

    navigateToSuspendAutomaticPaymentsLanding(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.SUSPEND.LANDING(this.urlUtil.hashParamsString)
        );
    }

    navigateToEditAutomaticPaymentsLanding(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.EDIT.LANDING(this.urlUtil.hashParamsString));
    }

    navigateToCancelAutomaticPaymentsLanding(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.CANCEL.LANDING(this.urlUtil.hashParamsString)
        );
    }

    navigateToCancelScheduledPaymentLanding(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SCHEDULED_FUTURE_PAYMENTS.CANCEL.LANDING(this.urlUtil.hashParamsString)
        );
    }

    navigateToAddPaymentMethodLanding(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.ADD.LANDING(this.urlUtil.hashParamsString)
        );
    }

    navigateToEditPaymentMethodLanding(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.EDIT.LANDING(this.urlUtil.hashParamsString)
        );
    }

    navigateToRemovePaymentMethodLanding(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.REMOVE.LANDING(this.urlUtil.hashParamsString)
        );
    }

    navigateToSetAsDefaultPaymentMethodLanding(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.SET_AS_DEFAULT.LANDING(this.urlUtil.hashParamsString)
        );
    }

    navigateToAddRefundMethodLanding(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_REFUND_METHOD.ADD.LANDING(this.urlUtil.hashParamsString));
    }

    navigateToRemoveRefundMethodLanding(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_REFUND_METHOD.REMOVE.LANDING(this.urlUtil.hashParamsString));
    }

    navigateToMakeAPayment(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.RELATIVE.payBillLanding(this.urlParams));
    }

    get urlParams(): ISBRouteParams {
        return {
            accountNumber: this.session.billingAccountNumber,
            policyNumber: this.session.policyNumber,
            sessionId: this.session.a2aSessionId
        };
    }
}
