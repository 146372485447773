import { PayMethodList } from '../../../../shared/payments/model/pay-method-list.model';
import { PaymentItem } from '../../../../billing/payment-preferences/shared/models/payment-item.model';

export class PaymentPrefsLanding {
    private _payMethods: PayMethodList;

    // eslint-disable-next-line max-params
    constructor(
        payMethods: PaymentItem[],
        readonly billingAccountHolderName: string,
        readonly billingAccountNumber: string,
        readonly amountDue: string,
        readonly nextBillDueDate: Date,
        readonly currentBillDueDate: Date,
        readonly canSelectPaperlessBilling: boolean,
        readonly isEasyPayEligible: boolean,
        readonly emailAddress: string,
        readonly streetLine1: string,
        readonly billingCity: string,
        readonly billingState: string,
        readonly isLifeOnlyAccount: boolean,
        readonly paymentPlan: string
    ) {
        this._payMethods = new PayMethodList(payMethods);
    }

    get defaultMethod(): PaymentItem {
        return this._payMethods.defaultMethod;
    }

    get payMethods(): PaymentItem[] {
        return this._payMethods.asList;
    }

    get bankAccountPayMethods(): PaymentItem[] {
        return this._payMethods.asBankAccountList;
    }

    get hasPayMethods(): boolean {
        return !!(this._payMethods.asList && this._payMethods.asList.length);
    }

    get hasSavedPayMethods(): boolean {
        return this._payMethods.hasSavedPayMethods;
    }
}
