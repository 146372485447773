export enum CardType {
    MASTERCARD = 'Mastercard',
    AMERICAN_EXPRESS = 'Amex',
    AMERICAN_EXPRESS_LONG_FORM = 'American Express',
    VISA = 'Visa',
    DISCOVER = 'Discover',
    INVALID = ''
}

export enum CardTypeShortHand {
    MASTERCARD = 'MC',
    AMERICAN_EXPRESS = 'AX',
    VISA = 'VI',
    DISCOVER = 'DI',
    INVALID = ''
}

export type CTMPaymentTypes = 'Bank Account' | 'AMEX' | 'DISC' | 'MAST' | 'VISA' | 'Masterpass';

export const encryptionCardTypeMap = new Map<CardType, string>()
    .set(CardType.MASTERCARD, 'MAST')
    .set(CardType.AMERICAN_EXPRESS, 'AMEX')
    .set(CardType.VISA, 'VISA')
    .set(CardType.DISCOVER, 'DISC');

export const submitPaymetCardTypeMap = new Map<CardType | CardTypeShortHand, string>()
    .set(CardType.MASTERCARD, 'Mastercard')
    .set(CardTypeShortHand.MASTERCARD, 'Mastercard')
    .set(CardType.AMERICAN_EXPRESS, 'American Express')
    .set(CardTypeShortHand.AMERICAN_EXPRESS, 'American Express')
    .set(CardType.VISA, 'Visa')
    .set(CardTypeShortHand.VISA, 'Visa')
    .set(CardType.DISCOVER, 'Discover')
    .set(CardTypeShortHand.DISCOVER, 'Discover');

export const paymentPreferencesCardTypeMap = new Map<CardType, string>()
    .set(CardType.MASTERCARD, 'Mastercard')
    .set(CardType.AMERICAN_EXPRESS, 'Amex card')
    .set(CardType.VISA, 'Visa card')
    .set(CardType.DISCOVER, 'Discover card');

export const updatePaymentCardTypeMap = submitPaymetCardTypeMap;
export const paymentPrefsBrandName = submitPaymetCardTypeMap;
export const CtmEventCardTypeMap = encryptionCardTypeMap;
