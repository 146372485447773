import {
    DatePipe,
    DecimalPipe,
    HashLocationStrategy,
    LocationStrategy,
    CurrencyPipe,
    LowerCasePipe
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthConfig, OAuthModule } from '@nationwide/angular-oauth-module';
import { APICommonService, BrowserUtilsService } from '@nationwide/internet-servicing-angular-services';
import { BillingServicesModule, ValidatorModule } from '@nationwide/dgs-angular-billing-common';
import { DpimInformationRoleModule } from '@nationwide/internet-servicing-dpim-requests';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { NWAdobeTargetModule } from '@nationwide/nw-at-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppServiceConfigModule } from './app-service-config.module';
import { AppComponent } from './app.component';
import { LoggerService } from './shared/logger/logger.service';
import { SessionServicesModule } from './shared/session/session-services.module';
import { ISBSharedComponentsModule } from './shared/shared-components.module';
import { ISBSharedServicesModule } from './shared/shared-services.module';
import { BillingTileAdapterModule } from '@nationwide/angular-billing-tile';
import { AccountValidationAdapter } from './account-validation/account-validation-adapter.service';
import { ConsumerConfigurerService } from './billing-timeline-widget/service/consumer-configurer.service';
import { BillingTimelineWidgetModule } from './billing-timeline-widget/billing-timeline-widget.module';
import { CookieService } from 'ngx-cookie';
import { IsbSharedCommonHelperService } from './shared/isb-shared-common-helper.service';
import { ManageAccountValidationService } from './account-validation/manage-account-validation.service';
import { ManagePaymentPreferencesModule } from './billing/payment-preferences/manage/manage-payment-preferences.module';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { DistributionPartnerRoleInfoService } from './billing/shared/distribution-partner-role-info/distribution-partner-role-info.service';
import { DistributionPartnerRoleInfoHelperService } from './billing/shared/distribution-partner-role-info/distribution-partner-role-info-helper.service';
import { DistributionPartnerRoleInfoAdapter } from './billing/shared/distribution-partner-role-info/distribution-partner-role-info.adapter';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FooterService } from './shared/footer/footer.service';
import { RemoveDuplicatesPipe } from '@nationwide/internet-servicing-angular-pipes';
import { InternetServicingBillingErrorHandler } from './shared/internet-servicing-billing-error-handler';
import { BoltDirectivesModule } from '@nationwide-bolt/ng-directives';
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        OAuthModule,
        InternetServicingAngularComponentsModule,
        BillingServicesModule,
        AppRoutingModule,
        AppServiceConfigModule,
        SessionServicesModule,
        ISBSharedServicesModule,
        ISBSharedComponentsModule,
        BillingTileAdapterModule,
        ValidatorModule,
        BillingTimelineWidgetModule,
        NWAdobeTargetModule.forRoot(),
        ManagePaymentPreferencesModule,
        BoltDirectivesModule,
        DpimInformationRoleModule
    ],
    declarations: [AppComponent],
    providers: [
        { provide: 'servicingCommonConfig', useValue: environment },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: 'logger', useClass: LoggerService },
        { provide: 'vinConfig', useValue: environment.GLOBAL_NAV },
        { provide: 'policyRequestsConfig', useValue: environment.POLICY_REQUESTS },
        { provide: 'contentfulConfig', useFactory: (): any => environment.contentful },
        { provide: 'globalNavConfig', useValue: environment },
        { provide: 'ctAuthConfig', useFactory: (): OAuthConfig => environment.OAUTH_CONFIG },
        { provide: 'contentfulTestConfig', useFactory: (): any => environment.contentful },
        { provide: ErrorHandler, useClass: InternetServicingBillingErrorHandler },
        // ToDo figure out what requires these
        APICommonService,
        BrowserUtilsService,
        DatePipe,
        DecimalPipe,
        CurrencyPipe,
        LowerCasePipe,
        RemoveDuplicatesPipe,
        AccountValidationAdapter,
        ConsumerConfigurerService,
        CookieService,
        IsbSharedCommonHelperService,
        ManageAccountValidationService,
        DistributionPartnerRoleInfoService,
        DistributionPartnerRoleInfoHelperService,
        DistributionPartnerRoleInfoAdapter,
        NgbActiveModal,
        FooterService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
