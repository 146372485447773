import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BasePageComponent } from '../../shared/base/base-page.component';
import { AccountValidationModel } from '../../../app/account-validation/account-validation.model';
import { DistributedDocumentsResponseModel } from '../../shared/payments/ez-pay-authorization/eft-authorization-form/model/distributed-documents-response.model';
import { CommonValuesPaymentFormGroupModel } from '../model/common-values-payment-form-group.model';
import { EFTAuthorizationAgreementParams, CCAuthorizationAgreementParams } from '../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { EftAuthorizationFormElements } from '../../shared/payments/ez-pay-authorization/eft-authorization-form/form-elements/eft-authorization-form-elements';
import { AgencyInformationResponseModel } from '../../shared/payments/ez-pay-authorization/eft-authorization-form/model/agency-information-response.model';
import { SessionService } from '../../shared/session/session.service';
import { EftAuthorizationFormHelperService } from '../../shared/payments/ez-pay-authorization/eft-authorization-form-helper.service';
import { BillingEventService } from '../../shared/service/ebi-service/billing-events.service';
import { EftAuthorizationFlowService } from '../../shared/payments/ez-pay-authorization/eft-authorization-flow.service';
import { ACCOUNT_VALIDATION_CONSTANT, EFT_AUTH_FORM_CONSTANT, SUCCESS, ERROR } from '../../shared/constants/common.constants';
import { EBIEventType } from '../../shared/service/ebi-service/ebi-model';
import { PaymentFormGroup } from '../../shared/payments/payment-form-group';
import { AccountValidationDTORequest } from '../../account-validation/account-validation-dto-request';
import { PaymentItem } from '../../billing/payment-preferences/shared/models/payment-item.model';

@Component({ template: '' })
export abstract class BillingCommonComponent extends BasePageComponent implements OnInit {
    protected accountValidationModel: AccountValidationModel;
    showAccountValidationMessage = false;
    accountValidationMessage = '';

    protected distributedDocumentsResponseModel: DistributedDocumentsResponseModel;
    protected commonValuesPaymentFormGroupModel: CommonValuesPaymentFormGroupModel;
    protected authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
    protected showEFTAuthFormValidationMessage = false;
    protected eFTAuthFormValidationMessage = '';
    protected eftAuthorizationFormElements: EftAuthorizationFormElements;
    protected agencyInformationResponseModel: AgencyInformationResponseModel;
    protected isGlobalErrorMessageReqDefaultMethod = false;
    protected defaultPamentMethodSelectionError = false;

    // eslint-disable-next-line max-params
    constructor(
        protected session: SessionService,
        protected router: Router,
        protected eftAuthorizationFormHelperService: EftAuthorizationFormHelperService,
        protected eventService: BillingEventService,
        protected eftAuthorizationFlowService: EftAuthorizationFlowService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    get isAccountValidationReturnErrorCode(): boolean {
        return this.accountValidationModel?.accepted === false;
    }

    clearAccountValidationValues(): void {
        this.showAccountValidationMessage = false;
        this.accountValidationMessage = '';
        this.accountValidationModel = null;
    }

    setAccountValidationValues(): void {
        this.showAccountValidationMessage = true;
        this.accountValidationMessage = this.accountValidationModel?.errorMessage;
        scroll(0, 0);
    }

    sendIncompleteFormElementsOnEFTAuthFormEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_INCOMPLETE_FORM_ELEMENTS_IN_EFT_AUTH_FORM,
            { eftAuthorizationFormEbiData: this.eftAuthorizationFlowService.eftAuthorizationFormEbiData });
    }

    triggerEbiEventToLogNumberOfTimesHPExtremeServiceInvoke(): void {
        this.eventService.handleEvent(EBIEventType.LOG_NUMBER_OF_TIMES_HP_EXTREME_SERVICE_INVOKING,
            {
                responseCode: this.eftAuthorizationFlowService.apiResponseCode,
                responseStatus: this.eftAuthorizationFlowService.apiCallFailed ? ERROR : SUCCESS
            });
    }

    setEFTAuthFormValidationValues(): void {
        this.showEFTAuthFormValidationMessage = true;
        this.eFTAuthFormValidationMessage = EFT_AUTH_FORM_CONSTANT.VALIDATION_MESSAGE_NOT_PAYMENT_PREFERENCE_PAGE;
        scroll(0, 0);
    }

    getMessage(code: string): string {
        return ACCOUNT_VALIDATION_CONSTANT.MESSAGE_CONTAINER_MAP.get(code);
    }

    buildAccountValidationDTORequest(paymentFormGroup: PaymentFormGroup): AccountValidationDTORequest {
        return {
            firstName: paymentFormGroup.firstName,
            lastName: paymentFormGroup.lastName,
            bankAccountNumber: paymentFormGroup.bankAccountNumber,
            bankRoutingNumber: paymentFormGroup.bankRoutingNumber,
            bankAccountType: <any>paymentFormGroup.bankAccountType,
            isPlaidEnabled: false,
            isAuthenticated: false
        };
    }

    setAuthorizationAgreementParams(isRequiredToReplaceTypedName: boolean): void {
        if (this.commonValuesPaymentFormGroupModel) {
            this.authorizationAgreementParams =
                this.eftAuthorizationFormHelperService.buildAuthorizationAgreementParams(this.commonValuesPaymentFormGroupModel, isRequiredToReplaceTypedName);
        }
    }

    removeBankAccountPaymentMethods(paymentItems: PaymentItem[]): PaymentItem[] {
        return paymentItems.filter((paymentItem) => paymentItem.isBankAccount === false);
    }

    getNewBankCardPaymentMethod(paymentItems: PaymentItem[]): PaymentItem {
        return paymentItems.filter((paymentItem) => paymentItem.isBankCard === true && paymentItem.isNewBankCard === true)[0];
    }

    setHPExtremeServiceResponseStatus(apiResponseCode: number, apiCallFailedStatus: boolean): void {
        this.eftAuthorizationFlowService.apiResponseCode = apiResponseCode.toString();
        this.eftAuthorizationFlowService.apiCallFailed = apiCallFailedStatus;
    }

    getStausFromErrorResponse(httpErrorResponse: HttpErrorResponse): number {
        return httpErrorResponse?.status;
    }

    getMessageFromErrorResponse(httpErrorResponse: HttpErrorResponse): string {
        return httpErrorResponse?.message;
    }

    setDefaultPaymentMethodSelectionError(selectedPaymentMethod: PaymentItem): void {
        this.defaultPamentMethodSelectionError = !!selectedPaymentMethod && selectedPaymentMethod.isPlaceholder;
    }
}
