<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<div class="manage-payment-form-container">
    <div class="container bolt-container bolt-space-bottom-2xl">
        <bw-bolt-progress-bar appFocusOnInit arialabel="add refund method progress value is 75%" class="progress-bar-container to-container" progressBarValue="75"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Add refund method: review & submit</h1>

        <div class="bolt-row">
            <div class="bolt-col-12">
                <section *ngIf="disclaimerMessage | async as message">
                    <app-payment-sub-header>Refund details</app-payment-sub-header>
                    <div class="recap-content bolt-space-bottom-2xl">
                        <div *ngFor='let field of recapBankAccountFields'>
                            <app-recap-form-field [field]="field"></app-recap-form-field>
                        </div>
                    </div>
                    <app-payment-sub-header>Contact information</app-payment-sub-header>
                    <div class="email-content bolt-space-bottom-2xl">
                        <app-recap-form-field class="email-field" [field]="recapEmailAddressField"></app-recap-form-field>
                    </div>
                    <div class="notification-content">
                        <bw-bolt-notification type="info" class="to-container bolt-space-bottom-lg">
                            <is-interpolate [text]="message"></is-interpolate>
                        </bw-bolt-notification>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" (click)="cancelAddRefundMethod()">Cancel</bolt-button>
        <bolt-button slot="back" id="back-button" (click)="navigateToLanding()">Back</bolt-button>
        <bolt-button slot="forward" id="submit-button" type="primary" (click)="submit()">Submit</bolt-button>
    </bolt-button-bar>
</div>