import { Injectable } from '@angular/core';
import {
    DPIM
} from '@nationwide/dgs-angular-billing-common';
import { Address } from '../../../../model/address.model';
import { AgencyInformationResponseModel } from '../model/agency-information-response.model';

@Injectable()
export class DpimAgentInformationAdapter {
    mapDpimAgentInformation(dpimAgencyInformationResponse: DPIM.DpimAgencyInformationResponse): AgencyInformationResponseModel {
        let phoneNumber: string;
        let emailAddress: string;
        let address: Address;
        if (dpimAgencyInformationResponse.contactPoints) {
            phoneNumber = this.getPhoneNumber(dpimAgencyInformationResponse.contactPoints);
            emailAddress = this.getEmailId(dpimAgencyInformationResponse.contactPoints);
            address = this.mapAddress(dpimAgencyInformationResponse.contactPoints);
        }
        return {
            fullName: dpimAgencyInformationResponse.contactName &&
                dpimAgencyInformationResponse.contactName.personName ?
                this.mapFullName(dpimAgencyInformationResponse.contactName.personName) : '',
            phoneNumber,
            emailAddress,
            address
        };
    }

    mapAddress(contactPoints: DPIM.ContactPoint[]): Address {
        const contactPointAddress = this.getAddressContactPoint(contactPoints);
        let postalAddress: DPIM.PostalAddress = null;
        if (contactPointAddress.contactPoint.postalAddress) {
            postalAddress = contactPointAddress.contactPoint.postalAddress;
        }
        return {
            addressLine1: postalAddress ? postalAddress.addressLineOne : '',
            city: postalAddress ? postalAddress.city : '',
            state: postalAddress ? postalAddress.stateOrProvince : '',
            zip: postalAddress ? postalAddress.postalCode : ''
        };
    }

    getPhoneNumber(contactPoints: DPIM.ContactPoint[]): string {
        const contactPointPhone = this.getPhoneContactPoint(contactPoints);
        return contactPointPhone?.contactPoint.phone &&
            contactPointPhone.contactPoint.phone.usageType === 'Business Phone' ?
            contactPointPhone.contactPoint.phone.fullPhoneNumber : '';
    }
    getEmailId(contactPoints: DPIM.ContactPoint[]): string {
        const contactPointEmail = this.getEmailContactPoint(contactPoints);
        return contactPointEmail.contactPoint.email.emailAddress || '';
    }

    mapFullName(agentName: DPIM.PersonName): string {
        return agentName.middleName ?
            `${agentName.firstName} ${agentName.middleName} ${agentName.lastName}` :
            `${agentName.firstName} ${agentName.lastName}`;
    }

    private getPhoneContactPoint(contactPoints: DPIM.ContactPoint[]): DPIM.ContactPoint {
        return contactPoints.filter((contactPointIterate) => contactPointIterate?.contactPoint?.phone)[0];
    }

    private getEmailContactPoint(contactPoints: DPIM.ContactPoint[]): DPIM.ContactPoint {
        return contactPoints.filter((contactPointIterate) => contactPointIterate?.contactPoint?.email)[0];
    }

    private getAddressContactPoint(contactPoints: DPIM.ContactPoint[]): DPIM.ContactPoint {
        return contactPoints.filter((contactPointIterate) => contactPointIterate?.contactPoint?.postalAddress)[0];
    }
}
