import { Injectable } from '@angular/core';
import { AccountValidationModel } from './account-validation.model';
import { AccountValidationResponse, AccountValidationVerificationCode } from '@nationwide/dgs-angular-billing-common/api-response-types/account-validation/account-validation-response';
import { ACCEPTABLE_RESPONSE_CODES, WRONG_ACCOUNT_TYPE_CODES } from './response-contstants';
import { ContentfulISBData, ContentfulData } from '@nationwide/dgs-angular-billing-common';
@Injectable()
export class AccountValidationAdapter {
    mapResponse(res: AccountValidationResponse, contentfulData: ContentfulData): AccountValidationModel {
        const verificationCode = res?.Verification?.Code ?? null;
        const failureLimitReached = false;
        let accepted = false;
        let errorMessage = this.getCorrectTridionMessage(verificationCode, contentfulData);
        let wrongAccountType = false;
        if (ACCEPTABLE_RESPONSE_CODES.includes(verificationCode)) {
            accepted = true;
            errorMessage = null;
        } else if (WRONG_ACCOUNT_TYPE_CODES.includes(verificationCode)) {
            accepted = false;
            wrongAccountType = true;
        }

        return { accepted, failureLimitReached, errorMessage, verificationCode, wrongAccountType };
    }

    failureLimitResponse(contentfulData: ContentfulISBData, isPlaidEnabled: boolean): AccountValidationModel {
        const billingComponents = contentfulData;
        const errorMessage = isPlaidEnabled ? billingComponents.bankAccountValidationSoftLockMessagePersonal.content : billingComponents.bankAccountValidationSoftLockMessageComm.content;
        return { accepted: false, failureLimitReached: true, errorMessage, verificationCode: null, wrongAccountType: false };
    }

    private getCorrectTridionMessage(responseCode: AccountValidationVerificationCode, contentfulData: ContentfulData): string {
        const billingComponents = contentfulData;
        let message: string;

        switch (responseCode) {
            case 'NW01':
                message = billingComponents?.bankAccountValidationIsSavingsMessage?.content;
                break;
            case 'NW02':
                message = billingComponents?.bankAccountValidationIsCheckingMessage?.content;
                break;
            default:
                message = billingComponents?.bankAccountValidationRejectMessage?.content;
                break;
        }
        return message;
    }
}
