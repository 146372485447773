import { Injectable } from '@angular/core';
import { PayBillLanding } from '../../pay-bill-landing/pay-bill-landing.model';
import { NFCIM, ECIM as UnmappedECIM, MoneyProcessingRequestEnum } from '@nationwide/dgs-angular-billing-common';
import { PaymentAmountOption } from '../payment-amount-form-controls/payment-amount.component';
import { SessionService } from '../../../shared/session/session.service';
import { PayBill } from '../../pay-bill-landing/pay-bill-landing-adpater.model';
import { Message } from '@nationwide/dgs-angular-billing-common/api-response-types/nf-customer-information-management';
import { CtmAdapter } from '../../../shared/service/ctm-service/ctm-shared.adapter';
import { PayBillLandingDTO } from '../../pay-bill-landing/PayBillLandingDTO.model';
import { PayBillLandingHelperService } from '../../pay-bill-landing/pay-bill-landing-helper.service';
import { IsbSharedCommonHelperService } from '../../../shared/isb-shared-common-helper.service';

@Injectable()
export class CyberlifeAdapter {
    // eslint-disable-next-line max-params
    constructor(
        public session: SessionService,
        private ctmAdapter: CtmAdapter,
        private payBillLandingHelperService: PayBillLandingHelperService,
        private isbSharedCommonHelperService: IsbSharedCommonHelperService
    ) { }

    mapCyberLifePayment(params: CyberLifeMapPayBillLandingParams): PayBillLanding {
        const { cimResponse, internetRegistrationResponse } = params;
        const paymentAmountOptions: PaymentAmountOption[] =
            this.mapPaymentAmountOptions(cimResponse) || [];

        const payBillLandingDTO: PayBillLandingDTO = this.buildPayBillLandingDTO(cimResponse, paymentAmountOptions, internetRegistrationResponse);
        const payBillLanding: PayBillLanding = this.payBillLandingHelperService.constructPaybillLanding(payBillLandingDTO);
        return payBillLanding;
    }

    mapPaymentAmountOptions(cimResponse: NFCIM.NfCustomerInformationManagementResponse): PaymentAmountOption[] {
        const billingDetails = this.getBillingDetailsFromCIM(cimResponse);
        return [{
            isPartial: false,
            id: PayBill.MINIMUMPAYMENT,
            amount: this.getAmountDueCyberLife(billingDetails),
            description: 'This is your total amount.',
            finePrint: this.getMessageforPaymentDueCyberLife(billingDetails)
        }];
    }

    getBillingDetailsFromCIM(cimResponse: NFCIM.NfCustomerInformationManagementResponse): Message[] {
        if (cimResponse.profile.agreementGroup[0] &&
            cimResponse.profile.agreementGroup[0].agreements[0] &&
            cimResponse.profile.agreementGroup[0].agreements[0].policyDetail &&
            cimResponse.profile.agreementGroup[0].agreements[0].policyDetail.billingDetail) {
            return cimResponse.profile.agreementGroup[0].agreements[0].policyDetail.billingDetail.message;
        }
    }

    getAmountDueCyberLife(billingDetails: Message[]): string {
        const premiumAmountMessage = this.getPremiumAmountBlock(billingDetails);
        let amountDue = '';
        if (premiumAmountMessage) {
            amountDue = premiumAmountMessage.parameters.filter((parameter) => parameter.name === 'premiumAmount')[0].value;
        }
        return amountDue;
    }

    getPaymentDueDate(premiumAmountMessage: NFCIM.Message): string {
        let payDate = '';
        if (premiumAmountMessage) {
            payDate = premiumAmountMessage.parameters.filter((parameter) => parameter.name === 'paidToDate')[0].value;
        }
        return payDate;
    }

    getMessageforPaymentDueCyberLife(billingDetails: Message[]): string {
        const premiumAmountMessage: NFCIM.Message = this.getPremiumAmountBlock(billingDetails);
        const installmentDate: string = this.getPaymentDueDate(premiumAmountMessage);
        const extractedInstallmentDate: Date = this.extractParsedDate(installmentDate);
        const formattedDueDate: string = this.isbSharedCommonHelperService.getFormattedDate(new Date(extractedInstallmentDate));
        return this.payBillLandingHelperService.constructPaymentDueMessage(formattedDueDate);
    }

    getPremiumAmountBlock(billingDetails: Message[]): Message {
        return billingDetails.filter((message) => message.name === 'nf.billing.block.next.premium.amount')[0];
    }

    extractParsedDate(dateField: string): Date {
        if (new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])?$').test(dateField)) {
            const rawDate: any = dateField.split('-'); // MM/DD/YYYY
            return new Date(rawDate[0], rawDate[1] - 1, rawDate[2].slice(0, 2));
        }
        return undefined;
    }

    getUpdatedEmailAddress(internetRegistrationResponse:
        UnmappedECIM.EnterpriseCustomerInformationManagementResponse): string {
        if (internetRegistrationResponse.contactPoints.electronicAddress) {
            return internetRegistrationResponse.contactPoints.electronicAddress.emailAddress;
        } else {
            return PayBill.NULL;
        }
    }

    getStateFromInternetRegistrationResponse(
        internetRegistrationResponse: UnmappedECIM.EnterpriseCustomerInformationManagementResponse
    ): string {
        try {
            if (
                internetRegistrationResponse.customers.contactPoints[0].postalAddress
            ) {
                return internetRegistrationResponse.customers.contactPoints[0]
                    .postalAddress.state;
            }
        } catch (error) {
            return PayBill.NULL;
        }
    }

    getCurrentAgreement(internetRegistrationResponse: UnmappedECIM.EnterpriseCustomerInformationManagementResponse): UnmappedECIM.Agreement {
        let output = <UnmappedECIM.Agreement>{};
        if (internetRegistrationResponse.agreements && internetRegistrationResponse.agreements.length > 0) {
            output = internetRegistrationResponse.agreements.filter((agreement) =>
                agreement.agreementReference.referenceNumber === this.session.policyNumber
            )[0];
        }
        return output;
    }

    private buildPayBillLandingDTO(cimResponse: NFCIM.NfCustomerInformationManagementResponse,
        paymentAmountOptions: PaymentAmountOption[], internetRegistrationResponse?: UnmappedECIM.EnterpriseCustomerInformationManagementResponse
    ): PayBillLandingDTO {
        let emailAddress = '';
        let state = '';
        let producerNumbers: string[] = [];
        let paymentDueDate = '';
        const productName = MoneyProcessingRequestEnum.ProductName.LIFE;

        emailAddress =
            internetRegistrationResponse && this.getUpdatedEmailAddress(internetRegistrationResponse) ||
            this.session.emailId;

        state = internetRegistrationResponse && this.getStateFromInternetRegistrationResponse(internetRegistrationResponse);
        producerNumbers = this.ctmAdapter.getProducerNumbers(this.getCurrentAgreement(internetRegistrationResponse));
        paymentDueDate = this.getPaymentDueDate(this.getPremiumAmountBlock(this.getBillingDetailsFromCIM(cimResponse)));

        return {
            emailAddress,
            state,
            producerNumbers,
            paymentDueDate,
            paymentAmountOptions,
            productName
        };
    }
}
export interface CyberLifeMapPayBillLandingParams {
    cimResponse: NFCIM.NfCustomerInformationManagementResponse;
    internetRegistrationResponse?: UnmappedECIM.EnterpriseCustomerInformationManagementResponse;
}
