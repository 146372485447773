import { Injectable } from '@angular/core';
import { AccountValidationVerificationCode, MoneyProcessingRequestEnum as MPEnum } from '@nationwide/dgs-angular-billing-common';
import { PaperlessFlow } from '../../billing/payment-preferences/shared/models/paperless-flow.model';
import { PaymentItem } from '../../billing/payment-preferences/shared/models/payment-item.model';
import { BillingAccountItem } from '../../shared/payments/consider-this/save-as-refund-method/models/billing-account-item.model';
import { CCAuthorizationAgreementParams, EFTAuthorizationAgreementParams } from '../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { DistributedDocumentsResponseModel } from '../../shared/payments/ez-pay-authorization/eft-authorization-form/model/distributed-documents-response.model';
import { SubmitPaymentResponses } from '../pay-bill-review/pay-bill-review.service';
import { PayBillForm } from '../pay-bill/pay-bill.form';
import { PaymentAmountOption } from './payment-amount-form-controls/payment-amount.component';

@Injectable()
export class PayBillFlow implements PaperlessFlow {
    savePaperlessPreferredEmail: boolean;
    payBillForm: PayBillForm;
    considerThisDisplayable: boolean;
    emailAddress: string;
    selectedPayMethod: PaymentItem;
    isEligibleForEasyPay: boolean;
    isWarnedPolicy: boolean;
    isPaperlessEligible: boolean;
    isReinstatementEligible: boolean;
    minimumPaymentOption: PaymentAmountOption;
    paymentDueDate: string;
    submitPaymentTimestamp: Date;
    oipaPolicyPaymentCapAcheived?: boolean;
    careMattersPolicyPaymentCapAcheived?: boolean;
    nonOipaPolicyPaymentCapAcheived?: boolean;
    agreementStatus: string;
    sourceSystem: string;
    state: string;
    city: string;
    streetLine1: string;
    nextDueDate: Date;
    nextPaymentAmount: string;
    submitPaymentResponses: SubmitPaymentResponses;
    isPaymentCapExceededOnContinue: boolean;
    reinstatePolicyNumber: string;
    displayPaymentDateSection: boolean;
    productName: MPEnum.ProductName;
    isCyberLife: boolean;
    statementAmount: number;
    fullPayAmount: number;
    masterpassEncryptedData: any;
    roleIdentifier: string;
    billingAccountsForRefund: BillingAccountItem[];
    updateAutoPaymentAttempted: boolean;
    updateAutoPaySuccessful: boolean;
    isScheduledPayment: boolean;
    paymentMethods: PaymentItem[];
    paymentSuccessfullySubmitted = false;

    ezPayEnrollmentAttempted = false;
    enrolledInEZPay = false;
    billingAccountHolderName: string;

    paperlessEnrollmentAttempted = false;
    savedPaperlessEsddaPrefs = false;
    enrolledInPaperless = false;

    producerNumbers: string[];
    isOipaPolicy: boolean;
    hasCareMatters: boolean;
    isNonOwner: boolean;
    isSplitPartyUser: boolean;
    isQuickPay: boolean;
    isEligibleToSaveAsRefundMethod: boolean;
    paperlessEmail: string;
    displayCreditCardVulPolicyError: boolean;
    vulCreditCardErrorText = 'Credit cards cannot be used for variable life policies. Please use a different payment method.';
    enrollInFutureAutoPay: string;

    contextCallSuccess: boolean;
    contextCacheCallResponse: any;

    // Additional fields for Policy Level Overpayment Allocation
    isInPolicyLevelOverpaymentAllocationFlow: boolean;
    policyNumber: string;
    distributedDocumentsResponseModel: DistributedDocumentsResponseModel;
    authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
    accountValidationVerificationCode: AccountValidationVerificationCode;
    automaticPaymentsEnrolled: boolean;
    quickPayAutomaticPaymentsEnrolled: boolean;

    isCollectionPayment = false;
    isReinstatementPayment = false;
    collectionPaymentPolicyNumber: string;
    minimumAmountDue: number;
    eligibleToLogBetweenStatements: boolean;
    waysToPayLogger: string;

    save(params: Partial<PayBillFlow>): void {
        this.payBillForm ||= params.payBillForm;
        this.state ||= params.state;
        this.city ||= params.city;
        this.streetLine1 ||= params.streetLine1;
        this.sourceSystem ||= params.sourceSystem;
        this.emailAddress ||= params.emailAddress;
        this.selectedPayMethod ||= params.selectedPayMethod;
        this.minimumPaymentOption ||= params.minimumPaymentOption;
        if (params.paymentDueDate !== undefined && params.paymentDueDate !== null) {
            this.paymentDueDate = params.paymentDueDate;
        }
        if (params.agreementStatus !== undefined && params.agreementStatus !== null) {
            this.agreementStatus = params.agreementStatus;
        }
        this.isEligibleForEasyPay ||= params.isEligibleForEasyPay;
        this.isWarnedPolicy ||= params.isWarnedPolicy;
        this.enrollInFutureAutoPay ||= params.enrollInFutureAutoPay;
        this.updateAutoPaymentAttempted ||= params.updateAutoPaymentAttempted;
        this.updateAutoPaySuccessful ||= params.updateAutoPaySuccessful;
        this.isPaperlessEligible ||= params.isPaperlessEligible;
        this.isReinstatementEligible ||= params.isReinstatementEligible;
        this.submitPaymentTimestamp ||= params.submitPaymentTimestamp;
        this.oipaPolicyPaymentCapAcheived ||= params.oipaPolicyPaymentCapAcheived;
        this.careMattersPolicyPaymentCapAcheived ||= params.careMattersPolicyPaymentCapAcheived;
        this.nonOipaPolicyPaymentCapAcheived ||= params.nonOipaPolicyPaymentCapAcheived;
        this.nextDueDate ||= params.nextDueDate;
        this.nextPaymentAmount ||= params.nextPaymentAmount;
        this.submitPaymentResponses ||= params.submitPaymentResponses;
        this.isPaymentCapExceededOnContinue ||= params.isPaymentCapExceededOnContinue;
        this.reinstatePolicyNumber ||= params.reinstatePolicyNumber;
        this.displayPaymentDateSection ||= params.displayPaymentDateSection;
        this.productName ||= params.productName;
        this.isCyberLife ||= params.isCyberLife;
        this.statementAmount ||= params.statementAmount;
        this.fullPayAmount ||= params.fullPayAmount;
        this.masterpassEncryptedData ||= params.masterpassEncryptedData;
        this.roleIdentifier ||= params.roleIdentifier;
        this.billingAccountsForRefund ||= params.billingAccountsForRefund;
        this.isScheduledPayment ||= params.isScheduledPayment;
        this.paymentMethods ||= params.paymentMethods;
        this.paymentSuccessfullySubmitted ||= params.paymentSuccessfullySubmitted;

        this.ezPayEnrollmentAttempted ||= params.ezPayEnrollmentAttempted;
        this.enrolledInEZPay ||= params.enrolledInEZPay;

        this.paperlessEnrollmentAttempted ||= params.paperlessEnrollmentAttempted;
        this.savedPaperlessEsddaPrefs ||= params.savedPaperlessEsddaPrefs;
        this.enrolledInPaperless ||= params.enrolledInPaperless;

        this.billingAccountHolderName ||= params.billingAccountHolderName;
        this.producerNumbers ||= params.producerNumbers;
        this.isOipaPolicy ||= params.isOipaPolicy;
        this.hasCareMatters ||= params.hasCareMatters;
        this.isNonOwner ||= params.isNonOwner;
        this.isSplitPartyUser ||= params.isSplitPartyUser;
        this.isQuickPay ||= params.isQuickPay;

        if (params.isEligibleToSaveAsRefundMethod != null) {
            this.isEligibleToSaveAsRefundMethod = params.isEligibleToSaveAsRefundMethod;
        }

        if (params.isEligibleForEasyPay != null || params.isPaperlessEligible != null || this.isEligibleToSaveAsRefundMethod != null || params.payBillForm?.savePaymentMethod || params.automaticPaymentsEnrolled != null) {
            this.considerThisDisplayable = params.isEligibleForEasyPay || params.isPaperlessEligible || this.isEligibleToSaveAsRefundMethod || Boolean(params.payBillForm?.savePaymentMethod || params.automaticPaymentsEnrolled);
        }

        if (params.isCollectionPayment !== null && params.isCollectionPayment !== undefined) {
            this.isCollectionPayment = params.isCollectionPayment;
        }

        this.isReinstatementPayment ||= params.isReinstatementPayment;

        if (params.collectionPaymentPolicyNumber !== null && params.collectionPaymentPolicyNumber !== undefined) {
            this.collectionPaymentPolicyNumber = params.collectionPaymentPolicyNumber;
        }
        this.displayCreditCardVulPolicyError ||= params.displayCreditCardVulPolicyError;
        this.waysToPayLogger ||= params.waysToPayLogger;
        this.vulCreditCardErrorText ||= params.vulCreditCardErrorText;

        // Additional fields for Policy Level Overpayment Allocation
        this.isInPolicyLevelOverpaymentAllocationFlow ||= params.isInPolicyLevelOverpaymentAllocationFlow;
        this.policyNumber ||= params.policyNumber;
        this.distributedDocumentsResponseModel ||= params.distributedDocumentsResponseModel;
        this.authorizationAgreementParams ||= params.authorizationAgreementParams;

        this.paperlessEmail ||= params.paperlessEmail;
        this.accountValidationVerificationCode ||= params.accountValidationVerificationCode;
        this.automaticPaymentsEnrolled ||= params.automaticPaymentsEnrolled;
        this.quickPayAutomaticPaymentsEnrolled ||= params.quickPayAutomaticPaymentsEnrolled;
        this.contextCallSuccess ||= params.contextCallSuccess;
        this.contextCacheCallResponse ||= params.contextCacheCallResponse;
    }

    get postPaymentResponse(): any {
        return this.submitPaymentResponses && this.submitPaymentResponses.postPayment;
    }

    get nextBillResponse(): any {
        return this.submitPaymentResponses && this.submitPaymentResponses.getNextBill;
    }

    get enrollInEzPayResponse(): any {
        return this.submitPaymentResponses && this.submitPaymentResponses.enrollInEzPay;
    }

    get flowInPlay(): boolean {
        return this.payBillForm != null;
    }

    getIsReinstatementEligible(): boolean {
        return this.isReinstatementEligible;
    }

    setIsReinstatementEligible(isReinstatementEligible: boolean): void {
        this.isReinstatementEligible = isReinstatementEligible;
    }

    get isRestrictedFlowType(): boolean {
        return !this.isQuickPay && this.isCyberLife || this.isReinstatementEligible || this.isNonOwner || this.isCollectionPayment || this.isSplitPartyUser;
    }

    clear(): void {
        this.payBillForm = undefined;
        this.considerThisDisplayable = undefined;
        this.emailAddress = undefined;
        this.paperlessEmail = undefined;
        this.selectedPayMethod = undefined;
        this.minimumPaymentOption = undefined;
        this.paymentDueDate = undefined;
        this.isReinstatementEligible = undefined;
        this.isEligibleForEasyPay = undefined;
        this.isWarnedPolicy = undefined;
        this.isPaperlessEligible = undefined;
        this.submitPaymentTimestamp = undefined;
        this.oipaPolicyPaymentCapAcheived = undefined;
        this.careMattersPolicyPaymentCapAcheived = undefined;
        this.nonOipaPolicyPaymentCapAcheived = undefined;
        this.nextDueDate = undefined;
        this.roleIdentifier = undefined;
        this.submitPaymentResponses = undefined;
        this.billingAccountsForRefund = undefined;

        this.paymentSuccessfullySubmitted = false;

        this.ezPayEnrollmentAttempted = false;
        this.enrolledInEZPay = false;

        this.paperlessEnrollmentAttempted = false;
        this.savedPaperlessEsddaPrefs = false;
        this.enrolledInPaperless = false;
        this.paymentMethods = undefined;
        this.billingAccountHolderName = undefined;
        this.producerNumbers = undefined;
        this.isOipaPolicy = false;
        this.isScheduledPayment = false;
        this.hasCareMatters = false;
        this.isNonOwner = false;
        this.isEligibleToSaveAsRefundMethod = undefined;
        this.displayCreditCardVulPolicyError = false;
        this.accountValidationVerificationCode = undefined;
        this.contextCallSuccess = false;
        this.contextCacheCallResponse = undefined;
        this.waysToPayLogger = undefined;
    }
}

export interface NextBill {
    dueDate: Date | string;
    amountDue: number;
}
