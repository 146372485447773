import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentSubHeaderComponent } from './payment-sub-header/payment-sub-header.component';
import { PaymentMethodSelectComponent } from './payment-method-select/payment-method-select.component';
import { ConfirmationDetailsTableComponent } from './confirmation-details-table/confirmation-details-table.component';
import { ConsiderThisComponent } from './consider-this/consider-this.component';
import { EzPayAuthorizationComponent } from './ez-pay-authorization/ez-pay-authorization.component';
import { EmailFormControlComponent } from './email-form-control/email-form-control.component';
import { CvvFormControlComponent } from './cvv-form-control/cvv-form-control.component';
import { FormControlErrorComponent } from './form-control-error/form-control-error.component';
import { NameFieldSetComponent } from './name-field-set/name-field-set.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressFormControlComponent } from './address-form-control/address-form-control.component';
import { BankAccountNumberFormControlsComponent } from './bank-account-number-form-controls/bank-account-number-form-controls.component';
import { BankAccountTypeFormControlComponent } from './bank-account-type-form-control/bank-account-type-form-control.component';
import { BankCardFormControlsComponent } from './bank-card-form-controls/bank-card-form-controls.component';
import { BankCardExpirationFormControlComponent } from './bank-card-expiration-form-control/bank-card-expiration-form-control.component';
import { BankCardNumberInputComponent } from './bank-card-number-input/bank-card-number-input.component';
import { BankcardRecapFormFieldComponent } from './bankcard-recap-form-field/bankcard-recap-form-field.component';
import { ZipCodeFormControlComponent } from './zip-code-form-control/zip-code-form-control.component';
import { PmofBankAccountFormControlsComponent } from './pmof-bank-account-form-controls/pmof-bank-account-form-controls.component';
import { PmofBankCardFormControlsComponent } from './pmof-bank-card-form-controls/pmof-bank-card-form-controls.component';
import { SaveAsCheckboxComponent } from './save-as-checkbox/save-as-checkbox.component';
import { RecapFormFieldComponent } from './recap-form-field/recap-form-field.component';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { PaymentFormControls } from './payment-form-controls';
import { UnitedStatesNameService } from './united-state-name.service';
import { DisablePasteDirective } from '../attr-directives/disable-paste.directive';
import { AreYouSureRadioButtonsComponent } from './are-you-sure-radio-buttons/are-you-sure-radio-buttons.component';
import { FormUtil } from './form-util';
import { SharedBillingComponentsModule } from '@nationwide/dgs-angular-billing-common';
import { BankAccountInfoPlaidComponent } from './bank-account-info-plaid/bank-account-info-plaid.component';
import { BankAccountInfoManualComponent } from './bank-account-info-manual/bank-account-info-manual.component';
import { BankAccountInfoFormControlsComponent } from './bank-account-info-form-controls/bank-account-info-form-controls.component';
import { BankAccountPlaidFormControlsComponent } from './bank-account-plaid-form-controls/bank-account-plaid-form-controls.component';
import { BankAccountFormControlsComponent } from './bank-account-form-controls/bank-account-form-controls.component';
import { ProgressBarHelperService } from './progress-bar-helper/progress-bar-helper.service';
import { SaveAsRefundMethodComponent } from './consider-this/save-as-refund-method/save-as-refund-method.component';
import { BillingAccountForRefundComponent } from './consider-this/save-as-refund-method/components/billing-account-for-refund/billing-account-for-refund.component';
import { SaveAsRefundConfimationDetailsComponent } from './save-as-refund-confirmation-details/save-as-refund-confirmation-details.component';
import { BoltWrapperModule } from '@nationwide/internet-servicing-bolt-components';
import { EasyPayAuthorizationAgreementComponent } from './ez-pay-authorization/ez-pay-auth-agreement/ez-pay-auth-agreement.component';
import { PaymentMethodSectionComponent } from './payment-method-section/payment-method-section.component';
import { BoltDirectivesModule } from '@nationwide-bolt/ng-directives';

@NgModule({
    declarations: [
        PaymentSubHeaderComponent,
        PaymentMethodSectionComponent,
        PaymentMethodSelectComponent,
        AddressFormControlComponent,
        BankAccountFormControlsComponent,
        BankAccountPlaidFormControlsComponent,
        BankAccountNumberFormControlsComponent,
        BankAccountTypeFormControlComponent,
        BankCardFormControlsComponent,
        BankCardExpirationFormControlComponent,
        BankCardNumberInputComponent,
        BankcardRecapFormFieldComponent,
        ConfirmationDetailsTableComponent,
        ConsiderThisComponent,
        EzPayAuthorizationComponent,
        EmailFormControlComponent,
        CvvFormControlComponent,
        FormControlErrorComponent,
        NameFieldSetComponent,
        ZipCodeFormControlComponent,
        PmofBankAccountFormControlsComponent,
        PmofBankCardFormControlsComponent,
        SaveAsCheckboxComponent,
        RecapFormFieldComponent,
        DisablePasteDirective,
        AreYouSureRadioButtonsComponent,
        BankAccountInfoPlaidComponent,
        BankAccountInfoManualComponent,
        BankAccountInfoFormControlsComponent,
        SaveAsRefundMethodComponent,
        BillingAccountForRefundComponent,
        SaveAsRefundConfimationDetailsComponent,
        EasyPayAuthorizationAgreementComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InternetServicingAngularComponentsModule,
        SharedBillingComponentsModule,
        BoltDirectivesModule,
        BoltWrapperModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        PaymentSubHeaderComponent,
        PaymentMethodSectionComponent,
        PaymentMethodSelectComponent,
        AddressFormControlComponent,
        BankAccountFormControlsComponent,
        BankAccountPlaidFormControlsComponent,
        BankAccountNumberFormControlsComponent,
        BankAccountTypeFormControlComponent,
        BankCardFormControlsComponent,
        BankCardExpirationFormControlComponent,
        BankCardNumberInputComponent,
        BankcardRecapFormFieldComponent,
        ConfirmationDetailsTableComponent,
        ConsiderThisComponent,
        EzPayAuthorizationComponent,
        EmailFormControlComponent,
        CvvFormControlComponent,
        FormControlErrorComponent,
        NameFieldSetComponent,
        ZipCodeFormControlComponent,
        PmofBankAccountFormControlsComponent,
        PmofBankCardFormControlsComponent,
        SaveAsCheckboxComponent,
        RecapFormFieldComponent,
        DisablePasteDirective,
        AreYouSureRadioButtonsComponent,
        SaveAsRefundMethodComponent,
        BillingAccountForRefundComponent,
        SaveAsRefundConfimationDetailsComponent
    ],
    providers: [
        PaymentFormControls,
        UnitedStatesNameService,
        FormUtil,
        ProgressBarHelperService
    ]
})
export class SharedPaymentsModule { }
