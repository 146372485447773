import { NgModule } from '@angular/core';
import { ActivationEnd, Event, NavigationStart, Router, RouterModule, Routes } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { BackFromConfirmationGuard } from './pay-bill/pay-bill-review/back-from-confirmation-guard.service';
import { PaymentFlowType } from './pay-bill/shared/payment-flow-type.service';
import { PayBillFlow } from './pay-bill/shared/pay-bill-flow.service';
import { BillingTimelineWidgetComponent } from './billing-timeline-widget/billing-timeline-widget.component';
import { BillingDueDateConfirmationGuard } from './change-due-date/review-due-date/billing-due-date-confirmation-guard.service';
import { QuickPayHelperService } from './quick-pay/shared/services/quick-pay-helper/quick-pay-helper.service';
import { PayBillLandingHelperService } from './pay-bill/pay-bill-landing/pay-bill-landing-helper.service';
import { AgentInformationAdapter } from './shared/payments/ez-pay-authorization/eft-authorization-form/adapters/agent-information.adapter';
import { EftAuthorizationAdapter } from './shared/payments/ez-pay-authorization/eft-authorization-form/adapters/eft-authorization.adapter';
import { EftAuthorizationFormHelperService } from './shared/payments/ez-pay-authorization/eft-authorization-form-helper.service';
import { DpimAgentInformationAdapter } from './shared/payments/ez-pay-authorization/eft-authorization-form/adapters/dpim-agent-information.adapter';
import { EftAuthorizationFormValidationService } from './shared/payments/ez-pay-authorization/eft-authorization-form-validation.service';
import { EftAuthorizationFlowService } from './shared/payments/ez-pay-authorization/eft-authorization-flow.service';
import { BillingAccountsService } from './billing-details/billing-acccount-picker/billing-accounts.service';
import { EasyPayAuthorizationAgreementComponent } from './shared/payments/ez-pay-authorization/ez-pay-auth-agreement/ez-pay-auth-agreement.component';
import { PayBillConfirmationGuard } from './pay-bill/pay-bill-confirmation/pay-bill-confirmation-guard.service';

const routes: Routes = [
    {
        path: 'personal/ez-pay-auth-agreement', component: EasyPayAuthorizationAgreementComponent, data: { title: 'Authorization Form' }
    },
    {
        path: 'personal/billing',
        loadChildren: (): Promise<any> => import('./billing-details/billing-details.module').then((m) => m.BillingDetailsModule)
    },
    {
        path: 'personal/billingMigration',
        loadChildren: (): Promise<any> => import('./billing/billing.module').then((m) => m.BillingModule)
    },
    {
        path: 'personal/make-another-payment',
        loadChildren: (): Promise<any> => import('./make-another-payment/make-another-payment.module').then((m) => m.MakeAnotherPaymentModule)
    },
    {
        path: 'personal/pay-bill',
        loadChildren: (): Promise<any> => import('./pay-bill/pay-bill.module').then((m) => m.PayBillModule)
    },
    {
        path: 'personal/quickpay',
        loadChildren: (): Promise<any> => import('./quick-pay/quick-pay.module').then((m) => m.QuickPayModule)
    },
    {
        path: 'personal/change-due-date',
        loadChildren: (): Promise<any> => import('./change-due-date/change-due-date.module').then((m) => m.ChangeDueDateModule)
    },
    {
        path: 'personal/error',
        loadChildren: (): Promise<any> => import('./error/error.module').then((m) => m.ErrorModule)
    },
    {
        path: 'personal/billing-timeline',
        component: BillingTimelineWidgetComponent
    },
    {
        path: 'personal/stub',
        loadChildren: (): Promise<any> => import('./billing-stub/billing-stub.module').then((m) => m.BillingStubModule)
    },
    {
        path: '**',
        redirectTo: 'personal/error/404'
    }
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        BackFromConfirmationGuard,
        PayBillConfirmationGuard,
        BillingDueDateConfirmationGuard,
        PaymentFlowType,
        PayBillFlow,
        QuickPayHelperService,
        PayBillLandingHelperService,
        AgentInformationAdapter,
        EftAuthorizationAdapter,
        EftAuthorizationFormHelperService,
        DpimAgentInformationAdapter,
        EftAuthorizationFormValidationService,
        EftAuthorizationFlowService,
        BillingAccountsService
    ]
})
export class AppRoutingModule {
    constructor(router: Router, private meta: Meta) {
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                const newrelic = (window as any).newrelic;
                if (!newrelic) {
                    return;
                }
                newrelic.setCurrentRouteName(event?.url?.split('?')[0]); // setting route path without uriparams
                newrelic.interaction().save();
            }
            if (event instanceof ActivationEnd && this.specifiesDocumentTitle(event)) {
                document.title = event.snapshot.data.title;
            }
            if (event instanceof ActivationEnd && this.specifiesMetaDescription(event)) {
                this.meta.updateTag({ name: 'description', content: event.snapshot.data.description });
            }
        });
    }

    private specifiesMetaDescription(event: ActivationEnd): boolean {
        return event.snapshot.data && event.snapshot.data.description;
    }

    private specifiesDocumentTitle(event: ActivationEnd): boolean {
        return event.snapshot.data && event.snapshot.data.title;
    }
}
