import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    IsbExperienceService,
    isbExperienceServiceToken
} from '@nationwide/dgs-angular-billing-common';

import { catchError, flatMap, forkJoin, Observable, of, tap, timeout } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { DistributionPartnerRoleInfoAdapter } from '../../../../../../billing/shared/distribution-partner-role-info/distribution-partner-role-info.adapter';
import { PreferenceCenterPaperlesService } from '../../../../../../pay-bill/shared/preference-center-paperless.service';
import { IsbSharedCommonHelperService } from '../../../../../../shared/isb-shared-common-helper.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { updatePaymentCardTypeMap } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { SessionService } from '../../../../../../shared/session/session.service';
import { DistributionPartnerRoleInfoService } from '../../../../../shared/distribution-partner-role-info/distribution-partner-role-info.service';
import { PaperlessEnrollmentBaseService } from '../../../../shared/services/paperless-enrollment-error.service';
import { EditPaymentMethodFlowService } from '../edit-payment-method-flow.service';
import { EditPaymentMethodReview } from './edit-payment-review.model';

@Injectable()
export class EditPaymentMethodReviewService extends PaperlessEnrollmentBaseService {
    // eslint-disable-next-line max-params
    constructor(
        @Inject(isbExperienceServiceToken) private isbExperienceApi: IsbExperienceService,
        @Inject(PreferenceCenterPaperlesService) private preferencePaperlesService: PreferenceCenterPaperlesService,
        @Inject('logger') private logger: LoggerService,
        private editPaymentMethodFlowService: EditPaymentMethodFlowService,
        protected sessionService: SessionService,
        private editPaymentReview: EditPaymentMethodReview,
        private distributionPartnerRoleInfoService: DistributionPartnerRoleInfoService,
        private distributionPartnerRoleInfoAdapter: DistributionPartnerRoleInfoAdapter,
        private isbSharedCommonHelperService: IsbSharedCommonHelperService
    ) {
        super(sessionService);
    }

    updatePaymentMethod(): Observable<EditPaymentMethodResponse> {
        return forkJoin({
            updatePaymentMethod: this.updateSavedPaymentMethod(),
            distributionPartnerRoleInfoResponse: this.distributionPartnerRoleInfoService.fetchDistributionPartnerRoleInfo()
        }).pipe(
            tap((responses) => {
                this.distributionPartnerRoleInfoAdapter.mapDistributionPartnerRoleResponse(responses.distributionPartnerRoleInfoResponse);
            }),
            flatMap((apiResponses) =>
                forkJoin({
                    updatePaymentMethod: of(apiResponses.updatePaymentMethod),
                    esddaPreferences: this.editPaymentReview.enrollingInPaperless === 'Yes' || this.editPaymentReview.enrollingInPaperless === 'No' ?
                        this.saveEsddaPreferences() : this.requestPlaceHolder()
                })
            )
        );
    }

    saveEsddaPreferences(): Observable<boolean> {
        this.editPaymentReview.save({ paperlessEnrollmentAttempted: true });
        return this.preferencePaperlesService.saveEsddaPreferences(this.editPaymentReview.emailAddress, this.editPaymentReview.enrollingInPaperless).pipe(
            tap((response) => {
                if (response) {
                    this.editPaymentReview.save({
                        savedPaperlessEsddaPrefs: true, enrolledInPaperless: true,
                        editPaymentMethodResponse: {
                            esddaPreferences: true
                        }
                    });
                } else {
                    this.editPaymentReview.save({
                        savedPaperlessEsddaPrefs: false, enrolledInPaperless: false,
                        editPaymentMethodResponse: {
                            esddaPreferences: false
                        }
                    });
                }
            }),
            catchError(() => of(false))
        );
    }

    private updateSavedPaymentMethod(): Observable<any> {
        this.editPaymentReview.save({ paymentMethodSaveAttempted: true });

        const response: Observable<any> = this.isbExperienceApi.putSavedPaymentMethod({
            sendCustomerEmail: 'true',
            billingSystem: this.sessionService.billingSystem,
            accessToken: this.sessionService.accessToken,
            methodID: this.editPaymentReview.paymentMethodId,
            payload: {
                billingPayload: {
                    billingAccountNumber: this.sessionService.billingAccountNumber,
                    description: 'UNSET',
                    enterpriseCustomerNumber: this.sessionService.ecn,
                    preferredMethod: this.editPaymentReview.savePayMethodAsDefault || this.editPaymentReview.isSelectedPayMethodAlreadyDefault,
                    recurringMethod: this.editPaymentReview.isRecurringMethod,
                    payorInfo: this.isbSharedCommonHelperService.truncatePayerInfo(this.editPaymentReview.payorInfo),

                    paymentMethodType: this.editPaymentReview.isBankCard ?
                        'BankCard.SavedPaymentMethod' : 'ElectronicFundsTransfer.SavedPaymentMethod',

                    bankCard: this.editPaymentReview.isBankCard ? {
                        cardBrand: updatePaymentCardTypeMap.get(this.editPaymentReview.savedCardType),
                        expirationDate: `${this.editPaymentReview.cardExpirationYear}${this.editPaymentReview.cardExpirationMonth}`,
                        ccLastFour: this.editPaymentReview.cardNumberLastFour
                    } : undefined,
                    electronicFundsTransfer: this.editPaymentReview.isBankAccount ? {
                        bankAccountType: this.editPaymentReview.accountType.toUpperCase(),
                        bankName: this.editPaymentReview.bankName,
                        bankRoutingNumber: this.editPaymentReview.routingNumber,
                        maskedBankAccountNumber: this.editPaymentReview.accountNumberLastThree,
                        bankAccountNumber: this.editPaymentReview.accountNumber,
                        encryptedBankAccountNumber: this.editPaymentReview.encryptedBankAccountNumber
                    } : undefined
                },
                omsData: {
                    omsServiceTransformationStrategyData: {
                        servicePlusIndicator: this.sessionService.dpimServicePlusIndicator,
                        agentPhoneNumber: this.sessionService.dpimAgentPhoneNumber
                    }
                }
            }
        }).pipe(
            tap(() => this.editPaymentReview.save({ paymentMethodSaved: true })),
            timeout(environment.TIMEOUT.apiCallDuration.submitPayment),
            catchError((err) => {
                this.logger.error('API ERROR: UPDATED SAVED PAYMENT METHOD IN EDIT PAYMENT REVIEW PAGE', err);
                this.editPaymentReview.save({ paymentMethodSaved: false });
                return of(err);
            })
        );
        return response;
    }

    private requestPlaceHolder(): Observable<null> {
        return of(null);
    }
}

export interface EditPaymentMethodResponse {
    updatePaymentMethod?: boolean | HttpErrorResponse | null;
    updateEmailAddress?: boolean | null;
    esddaPreferences?: boolean | null;
}
