import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup, Validators } from '@angular/forms';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { ManagePaymentPreferencesPageTitle } from '../../../billing/payment-preferences/manage/shared/models/manage-payment-preferences-page-title.constant';
import { PaymentAmountOption, PaymentSelection } from '../../../pay-bill/shared/payment-amount-form-controls/payment-amount.component';
import { PaymentFlowType } from '../../../pay-bill/shared/payment-flow-type.service';
import { BaseFormGroupComponent } from '../../../shared/payments/base-form-control/base-form-control.component';
import { ChangeDueDateCommonService } from '../../../shared/service/change-due-date-common.service';
import { PageName } from '../../page-name.enum';
import { SessionService } from '../../session/session.service';
import { PaymentFormControls } from '../payment-form-controls';
import { BillingAccountItem } from './save-as-refund-method/models/billing-account-item.model';

export type ControlNames = 'enrollInPaperless' | 'enrollInEasyPay' | 'enrollInChangeDueDate' | 'saveAsRefundMethodGroup' | 'savePaymentMethod' | 'emailAddressGroup' |
    'futureAutoPayment';

@Component({
    selector: 'app-consider-this',
    templateUrl: './consider-this.component.html',
    styleUrls: [
        './consider-this.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss'
    ]
})
export class ConsiderThisComponent extends BaseFormGroupComponent<ControlNames> implements OnInit {
    @Output() billingAccountsForRefund = new EventEmitter<BillingAccountItem[]>();
    @Output() esddaCheckBoxChecked = new EventEmitter<any>();
    isDisableEmailPreference = true;
    @Input() isAutomaticPaymentsEnrolled: boolean;
    @Input() email: string;
    @Input() paymentAmountOptions: PaymentAmountOption;
    @Input() set selectedPaymentOption(value: PaymentSelection) {
        this.paymentSelection = value;
        this.setEasyPayValidators();
    }
    @Input() canSelectPaperlessBilling: boolean;
    @Input() canSaveAsDefault: boolean;
    @Input() billingDueDates: any;
    @Input() isCollectionPayment = false;
    @Input() paperlessEsddaValidationError: boolean;
    @Input() set easyPay(value: string) {
        const easyPayEnrollment = 'easy-pay-enrollment';
        if (value === easyPayEnrollment && !this.savedEasyPayPaperlessOption) {
            this.paperlessEnrollmentOptionValue = 'yes';
            this.paperlessEnrollmentSelected = 'yes';
        }
    }

    @Input() isAutoPayment: boolean;

    @Input() set paperlessEnrollmentOption(value: string) {
        const emailAddressGroupControlemail = this.getControl('emailAddressGroup').get('emailAddress');
        if (value === 'no') {
            emailAddressGroupControlemail.setErrors(null);
            emailAddressGroupControlemail.clearValidators();
            this.paperlessEnrollmentOptionValue = value;
            this.savedEasyPayPaperlessOption = true;
        } else if (value === 'yes') {
            this.paperlessEnrollmentOptionValue = value;
            this.savedEasyPayPaperlessOption = false;
        }
    }
    @Input() set isEligibleForEasyPay(value: boolean) {
        this.canShowEasyPay = value;
        if (this.isMAPLandingPage) {
            this.setEasyPayValidators();
        }
    }
    reviewFormGroup: UntypedFormGroup;
    savedEasyPayPaperlessOption: boolean;
    paperlessEnrollmentOptionValue: string;
    contactPointPreference;
    emailId: string;
    @Input() set canSavePaymentMethod(value: boolean) {
        const savePaymentMethodSelectionControl = this.getControl('savePaymentMethod');
        if (value && !this.isSavedPaymentandRefundDisplayable()) {
            savePaymentMethodSelectionControl?.setErrors({ require: true });
            savePaymentMethodSelectionControl?.setValidators([Validators.required]);
        } else {
            savePaymentMethodSelectionControl?.setErrors(null);
            savePaymentMethodSelectionControl?.clearValidators();
        }
        this.showSavePaymentMethod = value;
        savePaymentMethodSelectionControl?.updateValueAndValidity();
    }
    @Input() set canEnrollInRefund(value: boolean) {
        const saveAsRefundSelectionControl = this.getControl('saveAsRefundMethodGroup').get('selection');
        if (value && !this.isSavedPaymentandRefundDisplayable()) {
            saveAsRefundSelectionControl?.setValidators([Validators.required]);
        } else {
            saveAsRefundSelectionControl?.setValue('');
            saveAsRefundSelectionControl?.setErrors(null);
            saveAsRefundSelectionControl?.clearValidators();
        }
        this.showSaveAsRefundComponent = value;
        saveAsRefundSelectionControl?.updateValueAndValidity();
    }
    @Input() set isWarnedPolicy(value: boolean) {
        const futureAutoPaymentSelectionControl = this.getControl('futureAutoPayment');
        if (value && this.isAutomaticPaymentsEnrolled) {
            futureAutoPaymentSelectionControl?.setErrors({ require: true });
            futureAutoPaymentSelectionControl?.setValidators([Validators.required]);
        } else {
            futureAutoPaymentSelectionControl?.setErrors(null);
            futureAutoPaymentSelectionControl?.clearValidators();
        }
        this.showFutureAutoPayMethod = value;
        futureAutoPaymentSelectionControl?.updateValueAndValidity();
    }

    canShowEasyPay: boolean;
    showSaveAsRefundComponent: boolean;
    showFutureAutoPayMethod: boolean;
    showSavePaymentMethod: boolean;
    paperlessEnrollmentSelected: string;
    paymentSelection: PaymentSelection;
    isUpdatedEmailAddress: boolean;
    get paperlessExperience(): string {
        return sessionStorage.getItem('paperlessExperience');
    }

    helpText = {
        enrollInEasyPay: 'Save time and money by having payments made automatically each billing cycle.',
        enrollInChangeDueDate: `Be aware that this change will affect your <strong>next bill</strong>, not the current bill you're paying now.`,
        enrollInPaperless: 'Choose how you want to receive billing documents, such as statements and other notices.',
        savePaymentMethod: 'Your information will be kept on file to make it easier to pay your bill, without having to re-enter details the next time you log in. This feature is not enrolling you in automatic payments.'
    };
    ESDDAAutoForm: string;

    // eslint-disable-next-line max-params
    constructor(
        controlContainer: ControlContainer,
        private paymentFlowType: PaymentFlowType,
        private changeDueDateCommonService: ChangeDueDateCommonService,
        private paymentFormControls: PaymentFormControls,
        private sessionService: SessionService,
        readonly contentfulService: ContentfulMapperService
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        this.addEmailControlToForm();
        this.paperlessEnrollmentSelection();
        this.fetchESDDAAutoForm();
    }

    addEmailControlToForm(): void {
        this.reviewFormGroup = new UntypedFormGroup({});
        this.reviewFormGroup.addControl(
            'emailAddressGroup',
            this.paymentFormControls.newEmailFormGroup(this.email)
        );
    }

    paperlessEnrollmentSelection(): void {
        if (this.paperlessEnrollmentOptionValue) {
            if (this.canSelectPaperlessBilling) {
                this.enrollInPaperless.setValue(this.paperlessEnrollmentOptionValue);
            }
            this.paperlessEnrollmentSelected = this.paperlessEnrollmentOptionValue;
        } else if (this.isAutomaticPaymentsLanding || this.isEditAutomaticPaymentsLanding) {
            this.paperlessEnrollmentOptionValue = 'yes';
            this.paperlessEnrollmentSelected = 'yes';
            if (this.canSelectPaperlessBilling) {
                const enrollInPaperlessControl = this.formGroup.get('enrollInPaperless');
                enrollInPaperlessControl.setValue(this.paperlessEnrollmentSelected);
            }
        }
    }

    futurePay(): boolean {
        return this.isWarnedPolicy;
    }

    showEasyPay(): boolean {
        return this.paymentSelection?.paymentType !== 'easy-pay-enrollment' && this.canShowEasyPay && !this.isAutomaticPaymentsEnrolled;
    }

    toggleConsiderThis(): boolean {
        const toggleConsiderThisList = [this.canSelectPaperlessBilling, this.showEasyPay(), this.showSavePaymentMethod, this.showSaveAsRefundComponent, this.showDueDate(), this.isSavedPaymentandRefundDisplayable()];
        return toggleConsiderThisList.includes(true);
    }

    isSavedPaymentandRefundDisplayable(): boolean {
        return this.isAutomaticPaymentsEnrolled && this.showFutureAutoPayMethod;
    }

    showDueDate(): boolean {
        return !!this.billingDueDates && !this.isCollectionPayment && !this.paymentFlowType.isCyberLife;
    }

    getCurrentBillDueDate(): string {
        return this.changeDueDateCommonService.getCurrentDueDate(this.billingDueDates);
    }

    private fetchESDDAAutoForm(): void {
        const contentfulResponse = this.contentfulService.getContentfulBillingContent();
        contentfulResponse.subscribe((res) => {
            this.ESDDAAutoForm = res.ESDDAAutoForm.content;
        });
    }

    get easyPayRequiredErrorDisplayable(): boolean {
        return this.errorsDisplayable('enrollInEasyPay') &&
            this.getControl('enrollInEasyPay').getError('required');
    }

    get isWarnedFuturePayErrorDisplayable(): boolean {
        return this.errorsDisplayable('futureAutoPayment') &&
            this.getControl('futureAutoPayment').getError('required');
    }

    get savePaymentMethodErrorDisplayable(): boolean {
        return this.errorsDisplayable('savePaymentMethod') &&
            this.getControl('savePaymentMethod').getError('required');
    }

    get enrollInPaperless(): AbstractControl {
        return this.getControl('enrollInPaperless');
    }

    get dueDateChangeEnrollErrorDisplayable(): boolean {
        return this.errorsDisplayable('enrollInChangeDueDate') &&
            this.getControl('enrollInChangeDueDate').getError('required');
    }

    get paperlessEnrollErrorDisplayable(): boolean {
        return this.errorsDisplayable('enrollInPaperless') &&
            this.getControl('enrollInPaperless').getError('required');
    }

    emitEsddaCheckedValue(event): void {
        this.esddaCheckBoxChecked.emit(event.target.checked);
    }

    paperlessEnrollment(): void {
        const enrollInPaperlessControl = this.getControl('enrollInPaperless');
        this.paperlessEnrollmentSelected = enrollInPaperlessControl?.value;
    }

    get emailFormControlDisplayable(): string {
        return this.getControl('enrollInPaperless').value;
    }

    get isAutomaticPaymentsLanding(): boolean {
        return this.sessionService.currentPage === ManagePaymentPreferencesPageTitle.automaticPayments.setup.landing;
    }

    get isEditAutomaticPaymentsLanding(): boolean {
        return this.sessionService.currentPage === ManagePaymentPreferencesPageTitle.automaticPayments.edit.landing;
    }

    get isMAPLandingPage(): boolean {
        return this.sessionService.currentPage === PageName.PAY_BILL_LANDING;
    }

    setEasyPayValidators(): void {
        const paymentTypeNotEasyPay = this.paymentSelection?.paymentType !== 'easy-pay-enrollment';
        const enrollEasyPaySelectionControl = this.getControl('enrollInEasyPay');
        const enrollEasyPayRequiredAlready = this.getControl('enrollInEasyPay').getError('required') === true;
        if (this.isMAPLandingPage && paymentTypeNotEasyPay && this.canShowEasyPay && !enrollEasyPayRequiredAlready) {
            enrollEasyPaySelectionControl.setErrors({ require: true });
            enrollEasyPaySelectionControl.setValidators([Validators.required]);
        } else if (!this.isMAPLandingPage || !paymentTypeNotEasyPay || !this.canShowEasyPay) {
            enrollEasyPaySelectionControl.setErrors(null);
            enrollEasyPaySelectionControl.clearValidators();
        }
    }
}
