import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';
import { BankAccountPaymentItem } from '../../../billing/payment-preferences/shared/models/bankaccount-item.model';

export type ControlNames = 'accountTypeGroup' | 'nameGroup' | 'addressGroup' | 'accountNumberGroup';

@Component({
    selector: 'app-pmof-bank-account-form-controls',
    templateUrl: './pmof-bank-account-form-controls.component.html',
    styleUrls: [
        '../../../shared/payments/stylesheets/form-formatting.scss'
    ]
})
export class PmofBankAccountFormControlsComponent extends BaseFormGroupComponent<ControlNames> {
    @Input() updatingPaymentMethod: boolean;
    @Input() bankAccountMethod: BankAccountPaymentItem;
    @Input() defaultPaymentMethodIsSelected: boolean;

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }
}
