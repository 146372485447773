<is-wait-message *ngIf="!pageLoadComplete" [loadComplete]="pageLoadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<form *ngIf="pageLoadComplete" [formGroup]="formGroup.group" (ngSubmit)="onSubmit()" class="manage-payment-form-container">
    <div class="bolt-container bolt-space-bottom-2xl">

        <bw-bolt-progress-bar appFocusOnInit arialabel="Stop next automatic payment progress value is 50%" class="progress-bar-container to-container" progressBarValue="50"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Stop next automatic payments</h1>

        <bw-bolt-notification type="error" [id]="'global-error-message'" *ngIf="globalErrorDisplayable" class="bolt-space-bottom-xl to-container">
            Please check the information below and try again.
        </bw-bolt-notification>

        <section id="payment-method-section" class="fluid-container">
            <app-payment-sub-header>Confirm your choice</app-payment-sub-header>
            <app-are-you-sure-radio-buttons formGroupName="areYouSureFormGroup" [submitted]="submitted" [displayMessage]="displayMessageForForm"></app-are-you-sure-radio-buttons>
            <bw-bolt-notification type="info">Stopping an automatic payment does not mean you skip the payment. You
                will need to make other arrangements to pay your bill before the due date.
            </bw-bolt-notification>
        </section>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" (click)="cancelSuspendEasy()">Cancel</bolt-button>
        <bolt-button submit type="primary" slot="forward" id="submit-button">Submit</bolt-button>
    </bolt-button-bar>
</form>