import { Component, Input, OnInit } from '@angular/core';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

@Component({
    selector: 'app-saved-payment-confimation-details',
    templateUrl: './saved-payment-confimation-details.component.html',
    styleUrls: ['./saved-payment-confimation-details.component.scss']
})

export class SavedPaymentConfimationDetailsComponent implements OnInit {
    @Input() paymentMethod: PaymentMethodConfirmationDetail;
    @Input() saveVerb = 'Saved';
    @Input() addPaymentMethodError: boolean;
    @Input() successfulRefundMethodEnrollments: string[] = [];
    @Input() unsuccessfulRefundMethodEnrollments: string[] = [];
    @Input() wasPreferenceEsddaEnrollmentSuccessful: boolean;
    @Input() isPaperlessEnrollmentUnsuccessful: boolean;
    @Input() wasEsddaEnrollmentSuccessful: boolean;
    @Input() errorMessage: string;
    effectiveDate = new Date();
    formattedCurrentDate: string;

    constructor(private easternTimeDatePipe: EasternTimeDatePipe) { }

    ngOnInit(): void {
        this.setFormattedCurrentDate();
    }

    setFormattedCurrentDate(): void {
        const dateFormat = 'MM/dd/yyyy, hh:mm aa ';
        this.formattedCurrentDate = `${this.easternTimeDatePipe.transform(new Date(), dateFormat)}ET`;
    }
}

export interface PaymentMethodConfirmationDetail {
    paymentType: string;
    maskedNumber: string;
}
