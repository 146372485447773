import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
    DistributionPartnerAPI
} from '@nationwide/internet-servicing-dpim-requests';
import { SessionService } from '../../../shared/session/session.service';
import { ECIF, OMS } from '../../../shared/constants/common.constants';
@Injectable()
export class DistributionPartnerRoleInfoAdapter {
    constructor(
        private session: SessionService
    ) {
    }

    mapDistributionPartnerRoleResponse(distributionPartnerRoleResponse: DistributionPartnerAPI.DpimRoleInfoResponse | HttpErrorResponse): void {
        if (distributionPartnerRoleResponse instanceof HttpErrorResponse) {
            this.session.dpimServicePlusIndicator = '';
            this.session.dpimAgentPhoneNumber = '';
        } else {
            let dpimServicePlusIndicator = 'N';
            const sa360ContractAddendum = this.session.dpimProducerType === ECIF.PRODUCER_VALUES.AMF ?
                this.getContractAddendumsAMF(distributionPartnerRoleResponse) :
                this.getContractAddendumsPDS(distributionPartnerRoleResponse);
            if (sa360ContractAddendum) {
                dpimServicePlusIndicator = this.getServicePlusIndicator(sa360ContractAddendum);
            }
            const dpimAgentPhoneNumber = dpimServicePlusIndicator === 'N' ?
                this.getAgentPhoneNumber(distributionPartnerRoleResponse) :
                OMS.SERVICE_TRANSFORMATION_STRATEGY.AGENT_PHONE_NUMBER;
            this.session.dpimServicePlusIndicator = dpimServicePlusIndicator;
            this.session.dpimAgentPhoneNumber = dpimAgentPhoneNumber;
        }
    }

    getContractAddendumsPDS(distributionPartnerRoleResponse: DistributionPartnerAPI.DpimRoleInfoResponse): any {
        const sa360Addendums = distributionPartnerRoleResponse?.contractSummary?.contractAddendums
            ?.filter((contractAddendum) => contractAddendum.addendumType.includes('Service Alliance 360'));

        return sa360Addendums?.find((addendum) => this.isAgentSA360MembershipActive(addendum));
    }

    getContractAddendumsAMF(distributionPartnerRoleResponse: DistributionPartnerAPI.DpimRoleInfoResponse): any {
        const producerNumber = this.session.producerNumber;
        const subRolesForProducer = distributionPartnerRoleResponse?.subRoles?.filter((subRole) => subRole.sourceIdentifier === producerNumber)[0];
        let sa360ContractAddendums = null;
        if (subRolesForProducer?.subRoleContractAddendums) {
            sa360ContractAddendums = subRolesForProducer.subRoleContractAddendums?.filter((contractAddendum) => contractAddendum.addendumType?.indexOf('Service Alliance 360') !== -1);
        }

        return sa360ContractAddendums?.find((addendum) => this.isAgentSA360MembershipActive(addendum));
    }

    getServicePlusIndicator(contractAddendums: any): string {
        return contractAddendums.addendumType ? 'Y' : 'N';
    }

    getAgentPhoneNumber(distributionPartnerRoleResponse: DistributionPartnerAPI.DpimRoleInfoResponse): string {
        let phoneNumber: string;
        if (distributionPartnerRoleResponse?.roleContactPoints) {
            distributionPartnerRoleResponse?.roleContactPoints.forEach((roleContactPoint) => {
                if (roleContactPoint?.contactPoint?.phone?.usageType === 'Business Phone') {
                    phoneNumber = roleContactPoint?.contactPoint?.phone?.fullPhoneNumber;
                }
            });
        }
        return phoneNumber;
    }

    isAgentSA360MembershipActive(contractAddendums: any): boolean {
        let isSA360MembershipActive = false;
        const expiryDate = this.getExpiryDate(contractAddendums);
        if (expiryDate) {
            if (new Date(expiryDate) > new Date()) {
                isSA360MembershipActive = true;
            }
        } else {
            isSA360MembershipActive = true;
        }
        return isSA360MembershipActive;
    }

    getExpiryDate(contractAddendums: any): string {
        let firstSpaceIndex: number;
        let expiryDate;
        if (contractAddendums.expiryDate) {
            const expiryDateOriginal: string = contractAddendums.expiryDate;
            if (expiryDateOriginal.includes(' ')) {
                firstSpaceIndex = expiryDateOriginal.indexOf(' ');
                expiryDate = expiryDateOriginal.slice(0, firstSpaceIndex);
            } else {
                expiryDate = expiryDateOriginal;
            }
        }
        return expiryDate;
    }
}
