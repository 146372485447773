
import { WalletPayPaymentItem } from './masterpass-item.model';

export class NewMasterPassMethod extends WalletPayPaymentItem {
    constructor() {
        super(
            '',
            ''
        );
    }
}
