import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticatedHeader } from '@nationwide/internet-servicing-angular-components';
import { HeaderService } from '../../../../shared/header/header.service';
import { environment } from '../../../../../environments/environment';
import { SessionService } from '../../../../shared/session/session.service';
import { Router } from '@angular/router';
import { Subscription, forkJoin, map } from 'rxjs';
import { PaymentPreferencesApiService } from '../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { DistributionPartnerRoleInfoService } from '../../../shared/distribution-partner-role-info/distribution-partner-role-info.service';
import { DistributionPartnerRoleInfoAdapter } from '../../../shared/distribution-partner-role-info/distribution-partner-role-info.adapter';

@Component({
    selector: 'app-manage-payment-preferences-base',
    templateUrl: './manage-payment-preferences-base.component.html',
    styleUrls: ['./manage-payment-preferences-base.component.scss']
})
export class ManagePaymentPreferencesBaseComponent
    implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    headerData: AuthenticatedHeader;
    homeIconLink = environment.CAM_HOME;
    crumbData: any;
    currentPage = 'Manage';

    onLinkClick: (linkMap: { url: string }) => void;

    // eslint-disable-next-line max-params
    constructor(
        private headerService: HeaderService,
        private session: SessionService,
        private router: Router,
        private paymentPreferencesApi: PaymentPreferencesApiService,
        private distributionPartnerRoleInfo: DistributionPartnerRoleInfoService,
        private distributionPartnerRoleInfoAdapter: DistributionPartnerRoleInfoAdapter
    ) { }

    ngOnInit(): void {
        this.stubHeader();
        this.initializeSession();
        this.setBreadCrumbs();
        this.setSessionIdToSession();
        window.scrollTo(0, 0);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    fetchHeader(): void {
        this.headerService.fetchHeaderData(
            {
                lastLogin: '',
                username: this.session.firstName,
                isBillingTimelineNotEligible: this.session.hasLifePolicy,
                isCyberLife: false
            },
            (header: AuthenticatedHeader) => this.headerData = header
        );
    }
    get routeParams(): string {
        return this.router.url.split('?')[1];
    }

    setBreadCrumbs(): void {
        this.crumbData = environment.BREAD_CRUMBS.MANAGE_PAYMENT_PREFERENCES({
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId
        });
    }

    initializeSession(): void {
        if (this.hasSessionInitialized) {
            this.fetchHeader();
        } else {
            forkJoin(
                this.paymentPreferencesApi.managePaymentPreferencesBase(),
                this.distributionPartnerRoleInfo.fetchDistributionPartnerRoleInfo()
            ).pipe(
                map((responses) => ({ rcaResponse: responses[0], distributionPartnerRoleInfoResponse: responses[1] }))
            ).subscribe((responses) => {
                this.session.intitialize({ rcaResponse: responses.rcaResponse });
                this.distributionPartnerRoleInfoAdapter.mapDistributionPartnerRoleResponse(responses.distributionPartnerRoleInfoResponse);
                this.fetchHeader();
            });
        }
    }

    private stubHeader(): void {
        this.headerService.fetchHeaderData(
            {
                lastLogin: '',
                username: this.session.firstName,
                isBillingTimelineNotEligible: false,
                isCyberLife: false
            },
            (header: AuthenticatedHeader) => this.headerData = header
        );
    }

    get hasSessionInitialized(): boolean {
        return this.session.hasInitialized;
    }

    setSessionIdToSession(): void {
        this.session.setSessionIdToSession(this.session.a2aSessionId);
    }
}
