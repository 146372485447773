import { TimeOutConfig, timeOutConfigToken } from '../time-out/time-out.config';
import { Injectable, Inject } from '@angular/core';
import { UrlUtil } from '../../url-util/url-util.service';

@Injectable()
export class SessionNavigation {
    constructor(
        private urlUtil: UrlUtil,
        @Inject(timeOutConfigToken) private config: TimeOutConfig
    ) { }

    gotoLogin(): void {
        this.clearTimeOutSession();
        window.location.href = this.config.logInUrl;
    }

    sessionLogOutUser(): void {
        this.clearTimeOutSession();
        window.location.href = this.config.sessionLogOutUrl;
        sessionStorage.setItem('logout', 'true');
    }

    logOutUser(): void {
        const preUserAwayNavigation = this.preUserAwayNavigationAction();
        this.clearTimeOutSession();
        this.postUserAwayNavigationAction(preUserAwayNavigation);
        window.location.href = this.config.logOutUrl;
    }

    clearTimeOutSession(): void {
        sessionStorage.clear();
        this.removeCTTokenFromUrl();
    }

    private removeCTTokenFromUrl(): void {
        const updatedPath = this.pathWithoutCTToken(this.urlUtil.hashPath, this.urlUtil.hashParams);
        window.history.replaceState({}, document.title, `${window.location.origin}/${updatedPath}`);
    }

    private pathWithoutCTToken(path: string, params: object): string {
        delete params['clearTrustToken'];

        const formattedQueryParams = Object
            .keys(params)
            .map((paramName) => `${paramName}=${params[paramName]}&`);

        return [`${path}?`, ...formattedQueryParams].join('');
    }

    get isBrowserBackAfterLogOut(): boolean {
        return this.returnedByBrowserBack && this.loggedOut;
    }

    private get returnedByBrowserBack(): boolean {
        return !!performance &&
            !!performance.navigation &&
            performance.navigation.type === performance.navigation.TYPE_BACK_FORWARD;
    }

    private get loggedOut(): boolean {
        return sessionStorage.getItem('logout') === 'true';
    }
    private isLogOut(): boolean {
        return sessionStorage['externalAppPageName'] && sessionStorage['externalAppPageName'] === 'Logout.action';
    }

    private preUserAwayNavigationAction(): { logOutFlag: boolean; manageSavePaymentMethodId: string } {
        let logOutFlag = false;
        let manageSavePaymentMethodId: string;
        if (this.isLogOut()) {
            logOutFlag = true;
        } else if (sessionStorage['MANAGE_SAVED_PAYMENT_METHODS_ID']) {
            manageSavePaymentMethodId = sessionStorage['MANAGE_SAVED_PAYMENT_METHODS_ID'];
        }

        return { logOutFlag, manageSavePaymentMethodId };
    }

    private postUserAwayNavigationAction(params: { logOutFlag: boolean; manageSavePaymentMethodId: string }): void {
        if (params.logOutFlag) {
            sessionStorage.setItem('logout', 'true');
        } else {
            sessionStorage['MANAGE_SAVED_PAYMENT_METHODS_ID'] = params.manageSavePaymentMethodId;
        }
    }
}
