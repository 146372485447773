<ng-container [formGroup]="formGroup">
    <div [ngClass]="{'bolt-space-bottom-xl': canSaveAsDefault, 'bolt-space-bottom-2xl': (!isManagePaymentPreferencesFlow && !canSaveAsDefault)}">
        <app-bank-account-type-form-control formGroupName="accountTypeGroup" [accountTypeLabel]=accountTypeLabel [isHorizontal]=isHorizontal class="bolt-space-bottom-xl">
        </app-bank-account-type-form-control>

        <app-bank-account-number-form-controls formGroupName="accountNumberGroup" [routingNumberServiceFailureMessaging]="routingNumberServiceFailureMessaging">
        </app-bank-account-number-form-controls>
    </div>

    <div id="save-as-default-payment" *ngIf="canSaveAsDefault" class="bolt-row">
        <div class="bolt-col-md-12" [ngClass]="{'bolt-space-bottom-2xl': !isManagePaymentPreferencesFlow }">
            <bolt-checkbox type="checkbox" ngDefaultControl name="makeDefaultPaymentMethod" formControlName="makeDefaultPaymentMethod" value="isDefaultPaymentMethod"
                arialabel="Make this your default payment method">
                Make this your default payment method
                <bolt-contextual-help>
                    <p>This will be your preferred method of payment, even though you can add up to 10 credit card accounts and up to 10 bank accounts. You can also delete payment methods online
                        anytime.</p>
                </bolt-contextual-help>
            </bolt-checkbox>

        </div>
    </div>

    <div *ngIf="!isManagePaymentPreferencesFlow">
        <app-name-field-set formGroupName="nameGroup" class="to-container bolt-space-bottom-xl" header="Account holder's name and address">
        </app-name-field-set>

        <app-address-form-control [appTitle]="" formGroupName="addressGroup">
        </app-address-form-control>
    </div>
</ng-container>