import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-form-control-error',
    templateUrl: './form-control-error.component.html',
    styleUrls: ['./form-control-error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormControlErrorComponent {
    @Input() id: string;
}
