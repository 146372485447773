import { Component, OnDestroy } from '@angular/core';
import { EditAutomaticPaymentFlowService } from './services/edit-automatic-payments-flow-service';

@Component({
    selector: 'app-edit-automatic-payments',
    templateUrl: './edit-automatic-payments.component.html',
    styleUrls: ['./edit-automatic-payments.component.scss']
})
export class EditAutomaticPaymentsComponent implements OnDestroy {
    constructor(
        private editPaymentFlow: EditAutomaticPaymentFlowService
    ) { }

    ngOnDestroy(): void {
        this.editPaymentFlow.clear();
    }
}
