import { Component, Input } from '@angular/core';
import { RecapFormFieldComponent, RecapFormField } from '../../../shared/payments/recap-form-field/recap-form-field.component';
import { CardType } from '../card-utils/card-type.enum';

@Component({
    selector: 'app-bankcard-recap-form-field',
    templateUrl: './bankcard-recap-form-field.component.html',
    styleUrls: [
        '../../../shared/payments/recap-form-field/recap-form-field.component.scss',
        './bankcard-recap-form-field.component.scss'
    ]
})
export class BankcardRecapFormFieldComponent extends RecapFormFieldComponent {
    @Input() declare field: BankcardRecapFormField;

    constructor() {
        super();
    }

    get isVisa(): boolean {
        return this.cardType === CardType.VISA;
    }

    get isMastercard(): boolean {
        return this.cardType === CardType.MASTERCARD;
    }

    get isAmex(): boolean {
        return this.cardType === CardType.AMERICAN_EXPRESS;
    }

    get isDiscover(): boolean {
        return this.cardType === CardType.DISCOVER;
    }

    private get cardType(): CardType {
        return this.field.cardType;
    }
}

export interface BankcardRecapFormField extends RecapFormField {
    cardType: CardType;
}
