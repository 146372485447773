import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of, finalize, map, tap, catchError } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PaymentMethodsAdapter } from '../../../../shared/adapters/payment-methods.adapter';
import { PaymentItem } from '../../../../shared/models/payment-item.model';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { AddRefundMethodFlowService } from '../services/add-refund-method-flow.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
@Component({
    selector: 'app-add-refund-method-landing',
    templateUrl: './add-refund-method-landing.component.html',
    styleUrls: ['./add-refund-method-landing.component.scss']
})
export class AddRefundMethodLandingComponent implements OnInit {
    @ViewChild(FormGroupDirective) addRefundMethodFormDirective: FormGroupDirective;

    addRefundMethodFormGroup: PaymentFormGroup;
    savedEligiblePaymentMethods: Observable<PaymentItem[]>;
    savedPaymentMethods: PaymentItem[] = [];
    emailAddress: string;

    _selectedPaymentMethod: PaymentItem;
    loadComplete = false;
    formValidated = false;

    // eslint-disable-next-line max-params
    constructor(
        private router: Router,
        @Inject('logger') private logger: LoggerService,
        private urlUtil: UrlUtil,
        private paymentPrefsApi: PaymentPreferencesApiService,
        private paymentMethodAdapter: PaymentMethodsAdapter,
        private titlecasePipe: TitleCasePipe,
        private paymentPrefsFormBuilder: ManagePaymentPrefsFormBuilder,
        private addRefundMethodFlowService: AddRefundMethodFlowService,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private formUtils: FormUtil) { }

    ngOnInit(): void {
        this.setAddSavedRefundMethodData();
    }

    setAddSavedRefundMethodData(): void {
        const includeNewBankCard = false;
        const includePlaceholder = false;
        this.savedEligiblePaymentMethods = this.paymentPrefsApi.addSavedRefundMethodLanding()
            .pipe(
                map(({ retrieveCustomerAgreement, internetRegistrationResponse, savedBankAccountMethods }) => {
                    this.emailAddress = this.managePaymentPrefsAdapter.fetchMostCurrentEmail(retrieveCustomerAgreement, internetRegistrationResponse);
                    return this.paymentMethodAdapter.mapSortPayMethods(savedBankAccountMethods, { includePlaceholder, includeNewBankCard });
                }),
                tap((savedEligiblePaymentMethods) => {
                    if (this.addRefundMethodFlowService.flowInProgress) {
                        this.selectedPaymentMethod = savedEligiblePaymentMethods[savedEligiblePaymentMethods.findIndex((method) => method.id === this.addRefundMethodFlowService.selectedPaymentMethod.id)];
                    } else {
                        this.selectedPaymentMethod = savedEligiblePaymentMethods[0];
                    }
                }),
                catchError((err) => {
                    this.logger.error('API ERROR: ADD REFUND METHOD LANDING', err);
                    return of(err);
                }),
                finalize(() => this.loadComplete = true)
            );
        this.savedEligiblePaymentMethods.subscribe((methods) => {
            this.savedPaymentMethods = methods;
        });
    }

    navigateToReview(): void {
        this.formUtils.touchAllFormControls(this.addRefundMethodFormGroup.group);
        if (this.addRefundMethodFormGroup.group.valid) {
            this.loadComplete = false;
            this.saveAddSavedRefundData();
            this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_REFUND_METHOD.ADD.REVIEW(
                this.urlUtil.hashParamsString
            ));
        } else {
            this.formValidated = false;
        }
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(
            this.urlUtil.hashParamsString
        ));
    }

    setupAddSavedRefundMethodForm(selectedPayMethod: PaymentItem): void {
        if (this.addRefundMethodFlowService.flowInProgress && this.addRefundMethodFlowService.isNewBankAccount) {
            this.addRefundMethodFormGroup = this.paymentPrefsFormBuilder.reconstructFormGroup({
                selectedPayMethod,
                oldForm: this.addRefundMethodFlowService.addRefundMethodFormGroup.group,
                hasSavedPayments: false
            });
        } else {
            this.addRefundMethodFormGroup = this.paymentPrefsFormBuilder.newPaymentFormGroup({
                selectedPayMethod,
                hasSavedPayments: false
            });
        }
    }

    saveAddSavedRefundData(): void {
        const isNewBankAccount = this.selectedPaymentMethod.isNewMethod;
        this.addRefundMethodFlowService.save(
            {
                paymentItem: isNewBankAccount ? this.paymentMethodAdapter.convertPaymentFormGroupToPaymentItem(this.addRefundMethodFormGroup) : this.selectedPaymentMethod,
                emailAddress: this.emailAddress,
                addRefundMethodFormGroup: this.addRefundMethodFormGroup,
                isNewBankAccount
            }
        );
    }

    get selectedPaymentMethod(): PaymentItem {
        return this._selectedPaymentMethod;
    }

    set selectedPaymentMethod(paymentItem: PaymentItem) {
        if (paymentItem) {
            this.setupAddSavedRefundMethodForm(paymentItem);
            this._selectedPaymentMethod = paymentItem;
            this.formValidated = true;
        } else {
            this._selectedPaymentMethod = null;
        }
    }

    get accountType(): string {
        return this.selectedPaymentMethod.methodType;
    }

    get bankName(): string {
        return this.selectedPaymentMethod.bankName;
    }

    get routingNumber(): string {
        return this.selectedPaymentMethod.bankAccountRoutingNumber;
    }

    get accountNumber(): string {
        return this.selectedPaymentMethod.displayNumbers;
    }

    get submitted(): boolean {
        return this.addRefundMethodFormDirective && this.addRefundMethodFormDirective.submitted;
    }

    get isPageLevelError(): boolean {
        return !this.formValidated && !this.addRefundMethodFormGroup.group.valid &&
            this.submitted;
    }

    get accountInfoFields(): RecapFormField[] {
        return [
            {
                id: 'account-type-recap',
                label: 'Account type',
                value: this.titlecasePipe.transform(this.accountType)
            },
            {
                id: 'bank-routing-number-recap',
                label: 'Bank routing number',
                value: this.routingNumber
            },
            {
                id: 'bank-name-recap',
                label: 'Bank name',
                value: this.bankName
            },
            {
                id: 'account-number-recap',
                label: 'Account number',
                value: `*********${this.accountNumber}`
            }
        ];
    }
}
