/* eslint-disable no-magic-numbers */
export enum PayBill {
    ZERO = 0,
    ZEROSTRING = '0',
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    EMPTY = '',
    MONTHLY = 'Monthly',
    TRUE = 'true',
    FALSE = 'false',
    LIFE = 'Life',
    PAID = 'Paid',
    NULL = '',
    RETURNED = 'Returned',
    BILLED = 'Billed',
    MINIMUM = 'minimum',
    RECIEVED = 'Recieved',
    EFTBILLED = 'EFT Billed',
    SINGLEWARNED = 'singleWarned',
    EASYPAYENROLLMENT = 'easy-pay-enrollment',
    CAREMATTERS = 'CareMatters',
    POWERSPORTSTEXT = 'PowerSports',
    MULTIPLEWARNEDTEXT = 'multipleWarned',
    LASTINSTALLEMENT = 'lastInstallment',
    FULLPAYTEXT = 'Full Pay',
    POLICYLEVEL = 'policyLevel',
    EASYPAY = 'easyPay',
    MINIMUMPAYMENT = 'minimum-payment',
    PARTIALPAYMENT = 'partial-payment',
    FULLPAYMENT = 'full-payment',
    MULTIMONTHPAYMENT = 'multi-month-payment',
    POLICYCHANGEPAYMENT = 'policy-change-payment',
    MINIMUMMESSAGE = 'This is your minimum payment.',
    TOTALPAYMENTMESSAGE = 'This is your total payment.',
    FULLPAYMESSAGE = 'Make a full payment.',
    FULLPAYMENTMESSAGE = 'Avoid future installment fees by paying in full.',
    EASYPAYMESSAGE = 'Enroll in automatic payments',
    EASYPAYMENTMESSAGE = 'Save time and money by having payments made automatically each billing cycle.',
    PAYMENT_CAP = 100000,
    BANKCARD_CAP = 9999.99
}
