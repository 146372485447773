<div class="manage-payment-form-container">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="setup automatic payments progress value is 100%" class="progress-bar-container to-container" progressBarValue="100"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Payment plan: confirmation</h1>

        <div class="bolt-row">
            <div class="bolt-col-sm-12">
                <ng-container *ngIf="isPayplanUpdateSuccess">
                    <bw-bolt-notification class="bolt-space-bottom-2xl to-container" type="success">Nice work! You are now on {{payPlan}}. Your budget will thank you for
                        eliminating all those monthly installment fees.
                    </bw-bolt-notification>
                </ng-container>

                <ng-container *ngIf="!isPayplanUpdateSuccess">
                    <bw-bolt-notification class="bolt-space-bottom-2xl to-container" type="error" id="error-notification">
                        We are unable to process your request. For assistance, please <a [href]="contactusUrl" target="_blank" rel=noopener>contact us.</a>
                    </bw-bolt-notification>
                </ng-container>
                <section *ngIf="isPayplanUpdateSuccess">
                    <app-payment-sub-header>Confirmation details</app-payment-sub-header>
                    <app-confirmation-details-table [rows]="confirmationDetails">
                    </app-confirmation-details-table>
                </section>
            </div>


            <div class="bolt-col-sm-12">
                <ng-container *ngIf="isPayplanUpdateSuccess">

                    <app-payment-sub-header>Upcoming payments</app-payment-sub-header>
                    <div class="bolt-space-bottom-2xl">
                        <div class="bolt-row">
                            <div class="bolt-col">
                                <strong>{{billLabel}}</strong>
                            </div>
                            <div class="bolt-col">
                                <strong>{{amountLabel}}</strong>
                            </div>
                            <div class="bolt-col">
                                <strong>{{dateLabel}}</strong>
                            </div>
                        </div>
                        <div class="bolt-row" *ngFor="let bill of upComingPaymentModel?.recordInfo">
                            <div class="bolt-col">
                                {{bill.billType}}
                            </div>
                            <div class="bolt-col">
                                {{bill.amount}}
                            </div>
                            <div class="bolt-col">
                                {{bill.dateValue | date: "MM/dd/yyyy"}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <ng-container *ngIf="canPayNow;else noMAPTemplate">
        <bolt-button-bar>
            <bolt-button slot="cancel" id="print-button" (click)="showPrintDialog()">Print</bolt-button>
            <bolt-button slot="back" id="secondary-button" (click)="cancelEditPaymentPlan()">Payment preferences</bolt-button>
            <bolt-button slot="forward" (click)="navigateToMakeAPayment()" id="submit-button" type="primary">
                Make a payment</bolt-button>
        </bolt-button-bar>
    </ng-container>
    <ng-template #noMAPTemplate>
        <bolt-button-bar>
            <bolt-button slot="cancel" id="print-button" (click)="showPrintDialog()">Print</bolt-button>
            <bolt-button slot="back" id="secondary-button" (click)="cancelEditPaymentPlan()">Payment preferences</bolt-button>
        </bolt-button-bar>
    </ng-template>
</div>