import { OnInit, Component } from '@angular/core';
import { SessionService } from '../session/session.service';
import { SubscribingComponent } from './subscribing.component';
import { Router } from '@angular/router';

@Component({ template: '' })

export abstract class BasePageComponent extends SubscribingComponent implements OnInit {
    protected abstract pageName: string;
    isDisableEmailPreference = true;
    constructor(
        protected session: SessionService,
        protected router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.session.referringPage = this.session.currentPage;
        this.session.currentPage = this.pageName;
        this.session.referringPageUrl = this.router.url;
    }
}
