<is-wait-message [loadComplete]="pageLoadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'" *ngIf="!pageLoadComplete">
</is-wait-message>

<form [formGroup]="formGroup.group" *ngIf="formGroup && pageLoadComplete" class="manage-payment-form-container" (ngSubmit)="onSubmit()">

    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="remove refund method progress value is 50%" class="progress-bar-container to-container" progressBarValue="50"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Remove refund method</h1>

        <bw-bolt-notification type="error" aria-labelledby="global-error-message" [id]="'global-error-message'" *ngIf="globalErrorDisplayable" class="bolt-space-bottom-xl to-container">
            Please check the information below and try again.
        </bw-bolt-notification>

        <section class="fluid-container">
            <app-payment-sub-header>
                Remove refund method
            </app-payment-sub-header>

            <app-are-you-sure-radio-buttons formGroupName="areYouSureFormGroup" [submitted]="submitted" [displayMessage]="displayMessageForForm"></app-are-you-sure-radio-buttons>

        </section>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" aria-label="cancel" (click)="cancelRemoveRefundMethod()">Cancel</bolt-button>
        <bolt-button submit aria-label="submit" type="primary" slot="forward" id="submit-button">Submit</bolt-button>
    </bolt-button-bar>
</form>