export enum CtmEventType {
    PAYBILL_STARTED_POLICY = 'Pay Bill - Started Process - Policy For Personal Lines and CyberLife',
    PAYBILL_SUCCESSFUL_POLICY = 'Pay Bill - Successful - Policy For Personal Lines and CyberLife',
    PAYBILL_STARTED_BILLING_ACCOUNT = 'User starts Payment Process - User starts the process of paying a bill online - account level',
    PAYBILL_SUCCESSFUL_BILLING_ACCOUNT = 'User Completes Payment Process - User successfully pays their bill online - account level',
    PAYBILL_SCHEDULED_ACCOUNT_LEVEL = 'Pay Bill - User successfully schedules their bill online - account level',
    PAYBILL_SCHEDULED_POLICY_LEVEL = 'Pay Bill - User successfully scheduled their bill online - policy level',
    PAYBILL_REINSTATEMENT_SUCCESSFUL = 'Reinstatement Attempt - Successful',
    PAYBILL_REINSTATEMENT_UNSUCCESSFUL = 'Reinstatement Attempt - Unsuccessful',
    PP_REMOVE_PAYMENT_METHOD = 'Remove Payment Method on File - User removes an existing payment method ',
    PP_ADD_PAYMENT_METHOD = 'Add Payment Method on File - Adds a payment method on file',
    PP_EDIT_PAYMENT_METHOD = 'Edit Payment Method on File - Edits a payment method on file',
    PP_SETUP_EASY_PAYMENT_METHOD = 'Enrolls in Recurring Payments - User successfully enrolls in Easy Pay',
    PP_EDIT_EASY_PAYMENT_METHOD = 'Edits Recurring Payments - User successfully changes payment method for Easy Pay automatic payments ',
    SUSPEND_EASY_PAY_SUCCESSFUL = 'Customer completes the suspension of Easy Pay',
    CANCEL_EASY_PAY_SUCCESSFUL = 'Customer completes the cancellation of Easy Pay',
    CANCEL_SCHEDULE_FUTURE_PAYMENT_SUCCESSFUL = 'Cancel Schedule Payment - User successfully cancelled their scheduled future payment',
    ON_CLICK_OF_REMOVE_REFUND_METHOD_BTN = 'Event will fire when user clicks on Remove Refund Method button',
    PP_ADD_REFUND_METHOD = 'Customer completes the adding of a refund method on file',

    PP_CHANGEDUEDATE_SUCCESSFUL = 'Customer Successfully Changes the Due Date'
}

export enum CtmProductType {
    ANNUITY = 'Annuity',
    AUTO = 'Auto',
    BANK = 'Bank',
    BOAT = 'Boat',
    PROPERTY = 'Property',
    LIFE = 'Life',
    FINANCIAL = 'Financial',
    PUL = 'PUL',
    PET = 'Pet',
    OTHER = 'Other',
    BILLING = 'Billing',
    POWER_SPORTS = 'PowerSports',
    UMBRELLA = 'Umbrella'
}

export enum ProductType {
    ANNUITY = 'Annuity',
    ANTIQUE_CLASSIC_AUTO = 'Antique / Classic Auto',
    AUTO = 'Auto',
    BANK = 'Bank',
    BOAT = 'Boat',
    CONDO = 'Condo',
    HOME = 'Home',
    HOMEOWNERS = 'Homeowners',
    INDIVIDUAL_ANNUITY = 'Individual Annuity',
    INDIVIDUAL_LIFE = 'Individual Life',
    LIFE = 'Life',
    MOBILE_HOME_PROPERTY = 'Mobile Home Property',
    MOTORCYCLE_MISC_VEHICLE = 'Motorcycle/Misc Vehicle',
    PENSION = 'Pension',
    PENSION_ACCOUNT = 'Pension Account',
    PRIMARY_HOMEOWNERS = 'Primary Homeowners',
    PROPERTY = 'Property',
    PROPERTY_CONTENTS = 'Property Contents',
    RENTAL_HOMEOWNERS = 'Rental Homeowners',
    RENTERS = 'Renters',
    RV = 'RV',
    SECONDARY_HOMEOWNERS = 'Secondary Homeowners',
    SECONDARY_RENTAL_HOMEOWNERS = 'Secondary Rental Homeowners',
    UMBRELLA = 'Umbrella',
    PERSONAL_UMBRELLA = 'Personal Umbrella',
    POWER_SPORTS = 'PowerSports',
    PET_MEDICAL = 'Pet Medical Insurance',
    PET_WELLNESS = 'Pet Wellness Insurance',
    BILLING = 'Billing'
}

export type CtmPaymentMethods = 'Bank Account' | 'AMEX' | 'DISC' | 'MAST' | 'VISA' | 'Masterpass' | string;

export interface CtmEventData {
    isSelectedPaymentMethodBankCard?: boolean;
    isCommercialAccount?: boolean;
    isThirdParty?: boolean;
    paymentAmount?: string;
    dueDate?: string;
    paymentMethod?: CtmPaymentMethods;
    productType?: string;
    sourceSystem?: string;
    producerNumbers?: string[];
    paymentDate?: string;
    confirmationNumber?: string;
    currentBillDueDate?: string;
    nextBillingDueDate?: string;
}

export interface CtmEventBody {
    authenticationId: string;
    transactionId: string;
    mappingId: string;
    applicationSource?: string;
    visibilityLevel?: string;
    createdByRepresentative: CtmCreatedByRepresentative;
    customer: CtmCustomer;
    businessItem?: CtmBusinessItem;
    productType?: CtmProductType;
    topic?: string;
    topicDescription?: string;
    detailedDescription: string;
    outcome?: string;
    interactionGrouping: CtmInteractionGrouping;
}

export interface CtmCreatedByRepresentative {
    userId?: string;
    role?: string;
    visibilityProducers: VisibilityProducer[];
}

export interface CtmCustomer {
    name: string;
    enterpriseCustomerNumber: string;
    id?: string;
}

export interface CtmBusinessItem {
    sourceApplication?: string;
    id: string;
    type?: string;
}

export interface CtmInteractionGrouping {
    id: string;
}

export interface VisibilityProducer {
    producerNumber: string;
}

export const CtmProductTypeMap = new Map<ProductType, CtmProductType>()
    .set(ProductType.ANNUITY, CtmProductType.ANNUITY)
    .set(ProductType.ANTIQUE_CLASSIC_AUTO, CtmProductType.AUTO)
    .set(ProductType.AUTO, CtmProductType.AUTO)
    .set(ProductType.BANK, CtmProductType.BANK)
    .set(ProductType.BOAT, CtmProductType.BOAT)
    .set(ProductType.CONDO, CtmProductType.PROPERTY)
    .set(ProductType.HOME, CtmProductType.PROPERTY)
    .set(ProductType.HOMEOWNERS, CtmProductType.PROPERTY)
    .set(ProductType.INDIVIDUAL_ANNUITY, CtmProductType.ANNUITY)
    .set(ProductType.INDIVIDUAL_LIFE, CtmProductType.LIFE)
    .set(ProductType.LIFE, CtmProductType.LIFE)
    .set(ProductType.MOBILE_HOME_PROPERTY, CtmProductType.PROPERTY)
    .set(ProductType.MOTORCYCLE_MISC_VEHICLE, CtmProductType.AUTO)
    .set(ProductType.PENSION, CtmProductType.FINANCIAL)
    .set(ProductType.PENSION_ACCOUNT, CtmProductType.FINANCIAL)
    .set(ProductType.PRIMARY_HOMEOWNERS, CtmProductType.PROPERTY)
    .set(ProductType.PROPERTY, CtmProductType.PROPERTY)
    .set(ProductType.PROPERTY_CONTENTS, CtmProductType.PROPERTY)
    .set(ProductType.RENTAL_HOMEOWNERS, CtmProductType.PROPERTY)
    .set(ProductType.RENTERS, CtmProductType.PROPERTY)
    .set(ProductType.RV, CtmProductType.AUTO)
    .set(ProductType.SECONDARY_HOMEOWNERS, CtmProductType.PROPERTY)
    .set(ProductType.SECONDARY_RENTAL_HOMEOWNERS, CtmProductType.PROPERTY)
    .set(ProductType.UMBRELLA, CtmProductType.UMBRELLA)
    .set(ProductType.PERSONAL_UMBRELLA, CtmProductType.UMBRELLA)
    .set(ProductType.POWER_SPORTS, CtmProductType.POWER_SPORTS)
    .set(ProductType.PET_MEDICAL, CtmProductType.PET)
    .set(ProductType.PET_WELLNESS, CtmProductType.PET)
    .set(ProductType.BILLING, CtmProductType.BILLING);
