import { Injectable, Inject } from '@angular/core';
import * as jsonToXML from 'js2xmlparser';
import { HttpErrorResponse } from '@angular/common/http';
import { IsbSharedCommonHelperService } from '../../isb-shared-common-helper.service';
import { EftAuthorizationFormElements } from './eft-authorization-form/form-elements/eft-authorization-form-elements';
import { LoggerService } from '../../logger/logger.service';
import { EFTAuthorizationAgreementParams, CCAuthorizationAgreementParams } from './easy-pay-authorization-agreement.service';
import { AuthConfirmationPolicyDetailsElements } from './eft-authorization-form/form-elements/auth-confirmation-policy-details-elements';
import { AuthConfirmationDetailsElements } from './eft-authorization-form/form-elements/auth-confirmation-details.elements';
import { ReceiptDetailsElements } from './eft-authorization-form/form-elements/receipt-details-elements';
import { ReceiptDetailsChildElements } from './eft-authorization-form/form-elements/receipt-details-child-elements';
import { SessionService } from '../../session/session.service';
import { AccountPolicyElements } from './eft-authorization-form/form-elements/account-policy-elements';
import { DocumentsElements } from './eft-authorization-form/form-elements/documents-elements';
import { DocumentChildElements } from './eft-authorization-form/form-elements/document-child-elements';
import { AgencyInformationResponseModel } from './eft-authorization-form/model/agency-information-response.model';
import { AgentElements } from './eft-authorization-form/form-elements/agent-elements';
import { DistributedDocumentsPayload } from '@nationwide/dgs-angular-billing-common';
import { CommonValuesPaymentFormGroupModel } from '../../../../app/billing/model/common-values-payment-form-group.model';
import { EFT_AUTH_FORM_CONSTANT } from '../../../../app/shared/constants/common.constants';
import { EftAuthorizationFlowService } from './eft-authorization-flow.service';
@Injectable()
export class EftAuthorizationFormHelperService {
    // eslint-disable-next-line max-params
    constructor(
        private isbSharedCommonHelperService: IsbSharedCommonHelperService,
        private session: SessionService,
        private eftAuthorizationFlowService: EftAuthorizationFlowService,
        @Inject('logger') private logger: LoggerService,
        @Inject('window') private window: Window) {
    }

    buildAuthConfirmationDetailsElements(authorizationAgreementParams: EFTAuthorizationAgreementParams,
        authConfirmationPolicyDetailsElements: AuthConfirmationPolicyDetailsElements[]): AuthConfirmationDetailsElements {
        return {
            EffectiveDate: <string>authorizationAgreementParams.enrolledDate,
            FinInstName: authorizationAgreementParams.bankName,
            FinInstRoutingNumber: authorizationAgreementParams.routingNumber,
            FinInstAccountNumber: authorizationAgreementParams.maskedAccountNum,
            PolicyTotal: 0,
            InstallmentFeeAmount: 0,
            NextBillAmount: 0,
            AuthConfirmationDetail: authConfirmationPolicyDetailsElements
        };
    }

    buildReceiptDetailsElements(authorizationAgreementParams: EFTAuthorizationAgreementParams): ReceiptDetailsElements {
        return {
            ReceiptNumber: '0',
            ReceiptDebitDate: <string>authorizationAgreementParams.enrolledDate,
            ReceivedDate: <string>authorizationAgreementParams.enrolledDate,
            ReceiptType: 'EB',
            TotalAmtPaidOrVoided: 0,
            ReceiptDetail: this.mapReceiptDetailsChildElements(authorizationAgreementParams)
        };
    }

    buildDocumentElements(): DocumentsElements {
        const documentsElementsArray: DocumentChildElements[] = [];
        const documentsElements: DocumentChildElements = {
            DocName: 'EFTAuthForm650'
        };
        documentsElementsArray.push(documentsElements);

        return { Document: documentsElementsArray };
    }

    buildAgentInformation(agencyInformationResponseModel: AgencyInformationResponseModel): AgentElements {
        return {
            AgentNane: agencyInformationResponseModel.fullName,
            AgentPhone: agencyInformationResponseModel.phoneNumber,
            AgentAddress: {
                AgentAddr1: agencyInformationResponseModel.address.addressLine1,
                AgentCity: agencyInformationResponseModel.address.city,
                AgentState: agencyInformationResponseModel.address.state,
                AgentZip: agencyInformationResponseModel.address.zip
            }
        };
    }

    buildAccountPolicyElements(billingAccountHolderName: string): AccountPolicyElements {
        return {
            AccountPolNumber: this.session.billingAccountNumber,
            UserName: billingAccountHolderName
        };
    }

    buildEFTAuthorizationFormsRequest(eftAuthorizationFormElements: EftAuthorizationFormElements): DistributedDocumentsPayload {
        const eftAuthorizationXMLFormatText = this.convertJSONObjectToXML(eftAuthorizationFormElements);
        const base64Text = this.convertXMLFormatToBase64Text(eftAuthorizationXMLFormatText);
        return {
            pubName: 'bamforms.pub',
            referenceArea: '',
            fileName: 'INPUT',
            driverFile: base64Text
        };
    }

    getDayOfMonthInEnrolledDate(enrolledDate: string): string {
        const paidDateTime = new Date(enrolledDate);
        const dayOfMonth = paidDateTime?.getDate() ? paidDateTime.getDate().toString() : '';
        return dayOfMonth;
    }

    convertBase64EncodedTextToPdfContent(base64EncodedText: string): string {
        const byteArray = this.convertBase64EncodedTextToByteArray(base64EncodedText);
        const pdfFileWithData = new Blob([byteArray], { type: 'application/pdf' });
        return this.url.createObjectURL(pdfFileWithData);
    }

    buildAuthorizationAgreementParams(commonValuesPaymentFormGroupModel: CommonValuesPaymentFormGroupModel, isRequiredToReplaceTypedName: boolean):
        EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams {
        let authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
        const commonAgreementParams = {
            billingAccountHolderName: commonValuesPaymentFormGroupModel.billingAccountHolderName,
            billingAccountNumber: this.session.billingAccountNumber,
            enrolledDate: this.isbSharedCommonHelperService.getCurrentDate(),
            displayBillingInfo: true,
            billingAccountUserName: isRequiredToReplaceTypedName ? this.getBillingAccountHolderName(commonValuesPaymentFormGroupModel) :
                commonValuesPaymentFormGroupModel.billingAccountHolderName
        };

        if (commonValuesPaymentFormGroupModel.isBankCardFlow) {
            authorizationAgreementParams = {
                ...commonAgreementParams,
                accountType: 'CC',
                cardType: commonValuesPaymentFormGroupModel.cardType,
                payorName: commonValuesPaymentFormGroupModel.payorName,
                cardZip: commonValuesPaymentFormGroupModel.zip,
                maskedCardNum: commonValuesPaymentFormGroupModel.cardNumberLastFour,
                expirationDate: commonValuesPaymentFormGroupModel.cardExpirationDate
            };
        } else if (commonValuesPaymentFormGroupModel.isBankAccountFlow) {
            authorizationAgreementParams = {
                ...commonAgreementParams,
                accountType: 'EFT',
                bankName: commonValuesPaymentFormGroupModel.bankName,
                methodType: commonValuesPaymentFormGroupModel.bankAccountType,
                maskedAccountNum: `************${commonValuesPaymentFormGroupModel.accountNumberLastThree}`,
                routingNumber: commonValuesPaymentFormGroupModel.routingNumber
            };
        }
        return authorizationAgreementParams;
    }

    buildEBIValueAgentInformationServiceDown(): void {
        const eftAuthorizationFormElementsObject = {
            AGENTNAME: EFT_AUTH_FORM_CONSTANT.SERVICE_DOWN,
            AGENT_PHONE: EFT_AUTH_FORM_CONSTANT.SERVICE_DOWN
        };
        this.eftAuthorizationFlowService.eftAuthorizationFormEbiData = {
            eftAuthorizationFormElementsObject
        };
    }

    getAllAccountPolHolderElementsMissing(): object {
        return {
            ...this.getAllNameElementsMissing(),
            ...this.getAllAddressElementsMissing()
        };
    }

    getAllNameElementsMissing(): object {
        return {
            FIRST_NAME: EFT_AUTH_FORM_CONSTANT.MISSING,
            LAST_NAME: EFT_AUTH_FORM_CONSTANT.MISSING
        };
    }

    getNameElementsObject(firstName: string, lastName: string): object {
        return {
            FIRST_NAME: firstName,
            LAST_NAME: lastName
        };
    }

    getAllAddressElementsMissing(): object {
        return {
            STREET_ADDRESS: EFT_AUTH_FORM_CONSTANT.MISSING,
            CITY: EFT_AUTH_FORM_CONSTANT.MISSING,
            STATE: EFT_AUTH_FORM_CONSTANT.MISSING,
            ZIP: EFT_AUTH_FORM_CONSTANT.MISSING
        };
    }
    getAddressElementsObject({ streetAddress, city, state, zip }: { streetAddress: string; city: string; state: string; zip: string }): object {
        return {
            STREET_ADDRESS: streetAddress,
            CITY: city,
            STATE: state,
            ZIP: zip
        };
    }

    getAllBankDetailElementsMissing(): object {
        return {
            ACCOUNT_NUMBER: EFT_AUTH_FORM_CONSTANT.MISSING,
            BANKNAME: EFT_AUTH_FORM_CONSTANT.MISSING,
            ROUTING_NUMBER: EFT_AUTH_FORM_CONSTANT.MISSING
        };
    }
    getBankDetailObject(accountNumber: string, bankName: string, routingNumber: string): object {
        return {
            ACCOUNT_NUMBER: accountNumber,
            BANKNAME: bankName,
            ROUTING_NUMBER: routingNumber
        };
    }

    getAllReceiptDetailElementsMissing(): object {
        return {
            ACCOUNTTYPE: EFT_AUTH_FORM_CONSTANT.MISSING,
            BANKNAME: EFT_AUTH_FORM_CONSTANT.MISSING,
            PAYMENT_METHOD: EFT_AUTH_FORM_CONSTANT.MISSING,
            DATE_OF_DEBIT: EFT_AUTH_FORM_CONSTANT.MISSING,
            PAYMENTAMOUNT: EFT_AUTH_FORM_CONSTANT.MISSING
        };
    }
    getAgentDetailObject(agentNane: string, agentPhone: string): object {
        return {
            AGENTNAME: agentNane,
            AGENT_PHONE: agentPhone
        };
    }
    getReceiptDetailsObject(dateOfDebit: string, paymentAmount: string): object {
        return {
            DATE_OF_DEBIT: dateOfDebit,
            PAYMENTAMOUNT: paymentAmount
        };
    }
    getReceiptDetailsChildObject(bankName: string, bankAccountType: string, paymentMethod: string): object {
        return {
            BANKNAME: bankName,
            ACCOUNTTYPE: bankAccountType,
            PAYMENT_METHOD: paymentMethod
        };
    }
    getAllReceiptDetailsChildElementsMissing(): object {
        return {
            BANKNAME: EFT_AUTH_FORM_CONSTANT.MISSING,
            ACCOUNTTYPE: EFT_AUTH_FORM_CONSTANT.MISSING,
            PAYMENT_METHOD: EFT_AUTH_FORM_CONSTANT.MISSING
        };
    }

    isAgencyInformationServiceThrowingError(httpErrorResponse: HttpErrorResponse): boolean {
        let message: string;
        if (httpErrorResponse.url) {
            message = httpErrorResponse.url;
        } else {
            message = httpErrorResponse.message;
        }
        return message && (message.includes('accountagencyinformation') ||
            message.includes('distribution-partner-management'));
    }

    get url(): any {
        return URL;
    }

    getFirstAndLastName(billingAccountHolderName: string): { firstName: string; lastName: string } {
        let firstName: string;
        let lastName: string;
        let firstSpaceIndex: number;
        if (billingAccountHolderName.includes(' ')) {
            firstSpaceIndex = billingAccountHolderName.indexOf(' ');
            firstName = billingAccountHolderName.slice(0, firstSpaceIndex);
            lastName = billingAccountHolderName.slice(firstSpaceIndex + 1, billingAccountHolderName.length);
        }
        return {
            firstName,
            lastName
        };
    }

    private mapReceiptDetailsChildElements(authorizationAgreementParams: EFTAuthorizationAgreementParams): ReceiptDetailsChildElements[] {
        const receiptDetailsChildElementsArray: ReceiptDetailsChildElements[] = [];
        const receiptDetailsChildElements: ReceiptDetailsChildElements = {
            PaymentMethod: authorizationAgreementParams.accountType,
            PaidAmount: 0,
            BankName: authorizationAgreementParams.bankName,
            BankAccountType: authorizationAgreementParams.methodType
        };
        receiptDetailsChildElementsArray.push(receiptDetailsChildElements);
        return receiptDetailsChildElementsArray;
    }

    private convertJSONObjectToXML(eftAuthorizationFormElements: EftAuthorizationFormElements): string {
        const eftAuthorizationXMLFormat = jsonToXML.parse('BAMLTR', eftAuthorizationFormElements);
        this.logger.debug(`EftAuthorizationXMLFormat Request ${eftAuthorizationXMLFormat}`);
        return eftAuthorizationXMLFormat;
    }

    private convertXMLFormatToBase64Text(eftAuthorizationRequestXMLFormat: string): string {
        return this.isbSharedCommonHelperService.encodeBase64(eftAuthorizationRequestXMLFormat);
    }

    private convertBase64EncodedTextToByteArray(base64EncodedText: string): Uint8Array {
        return new Uint8Array(this.window.atob(base64EncodedText).split('').map((char) => char.charCodeAt(0)));
    }

    private getBillingAccountHolderName(commonValuesPaymentFormGroupModel: CommonValuesPaymentFormGroupModel): string {
        return commonValuesPaymentFormGroupModel.firstName && commonValuesPaymentFormGroupModel.lastName ?
            this.isbSharedCommonHelperService.mapFullName(commonValuesPaymentFormGroupModel.firstName,
                commonValuesPaymentFormGroupModel.lastName,
                commonValuesPaymentFormGroupModel.middleInitial) :
            commonValuesPaymentFormGroupModel.billingAccountHolderName;
    }
}
