import { PaymentItem } from './payment-item.model';
import { BankAccountPaymentItem } from './bankaccount-item.model';
import { BankCardPaymentItem } from './bankcard-item.model';
import { CardType } from '../../../../shared/payments/card-utils/card-type.enum';
import { WalletPayPaymentItem } from './masterpass-item.model';
import { PlaceholderPaymentItem } from './placeholder-item.model';

export class PaymentPreferencesAccordion {
    constructor(
        readonly header: string,
        readonly payMethod: PaymentItem
    ) { }

    get payorName(): string {
        let name = '';
        if (this.payMethod && !(this.payMethod.item instanceof WalletPayPaymentItem) && !(this.payMethod.item instanceof PlaceholderPaymentItem)) {
            if (this.payMethod.item.middleInitial) {
                name = `${this.payMethod.item.firstName} ${this.payMethod.item.middleInitial} ${this.payMethod.item.lastName}`;
            } else {
                name = `${this.payMethod.item.firstName} ${this.payMethod.item.lastName}`;
            }
        }
        return name;
    }

    get isDefaultPayMethod(): boolean {
        return this.payMethod ? this.payMethod.isDefault : false;
    }

    get isBankAccount(): boolean {
        return !!this.payMethod && this.payMethod.isBankAccount;
    }

    get bankName(): string {
        let name = '';
        if (this.payMethod && this.payMethod.isBankAccount) {
            const bankPayMethod = <BankAccountPaymentItem> this.payMethod.item;
            name = bankPayMethod.bankName;
        }
        return name;
    }

    get address(): string {
        let address = '';
        if (this.payMethod && this.payMethod.isBankAccount) {
            const bankPayMethod = <BankAccountPaymentItem> this.payMethod.item;
            address = `${bankPayMethod.street} ${bankPayMethod.city}, ${bankPayMethod.state} ${bankPayMethod.zip}`;
        }
        return address;
    }

    get bankAccountType(): string {
        let accountType = '';
        if (this.payMethod && this.payMethod.isBankAccount) {
            const bankPayMethod = <BankAccountPaymentItem> this.payMethod.item;
            accountType = bankPayMethod.accountType;
        }
        return accountType;
    }

    get bankRoutingNumber(): string {
        let routingNumber = '';
        if (this.payMethod && this.payMethod.isBankAccount) {
            const bankPayMethod = <BankAccountPaymentItem> this.payMethod.item;
            routingNumber = bankPayMethod.routingNumber;
        }
        return routingNumber;
    }

    get bankAccountNumber(): string {
        let accountNumber = '';
        if (this.payMethod && this.payMethod.isBankAccount) {
            const bankPayMethod = <BankAccountPaymentItem> this.payMethod.item;
            accountNumber = bankPayMethod.maskedBankAccountNumber;
        }
        return accountNumber;
    }

    get isBankCard(): boolean {
        return !!this.payMethod && this.payMethod.isBankCard;
    }

    get cardBrand(): CardType {
        let cardBrand = CardType.INVALID;
        if (this.payMethod && this.payMethod.isBankCard) {
            const cardPayMethod = <BankCardPaymentItem> this.payMethod.item;
            cardBrand = cardPayMethod.cardType;
        }
        return cardBrand;
    }

    get cardZipCode(): string {
        let cardZipCode = '';
        if (this.payMethod && this.payMethod.isBankCard) {
            const cardPayMethod = <BankCardPaymentItem> this.payMethod.item;
            cardZipCode = cardPayMethod.zipCode;
        }
        return cardZipCode;
    }

    get cardLastFour(): string {
        let cardLastFour = '';
        if (this.payMethod && this.payMethod.isBankCard) {
            const cardPayMethod = <BankCardPaymentItem> this.payMethod.item;
            cardLastFour = cardPayMethod.cardNumberLastFour;
        }
        return cardLastFour;
    }

    get cardExpiration(): Date {
        let expirationDate = new Date(NaN);
        if (this.payMethod && this.payMethod.isBankCard) {
            const cardPayMethod = <BankCardPaymentItem> this.payMethod.item;
            expirationDate = cardPayMethod.cardExpiration;
        }
        return expirationDate;
    }
}
