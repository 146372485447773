import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineModule } from '@nationwide/angular-timeline';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import {
    SharedBillingComponentsModule
} from '@nationwide/dgs-angular-billing-common';
import { BillingTimelineWidgetComponent } from './billing-timeline-widget.component';

@NgModule({
    imports: [
        CommonModule,
        TimelineModule,
        InternetServicingAngularComponentsModule,
        SharedBillingComponentsModule
    ],
    declarations: [

        BillingTimelineWidgetComponent],
    exports: [],
    providers: []
})
export class BillingTimelineWidgetModule { }
