<is-wait-message [loadComplete]="pageLoadComplete" [waitMessage]="'Please wait while we process your request.'" [useBolt]="false" *ngIf="!pageLoadComplete">
</is-wait-message>

<div class="bolt-container bolt-space-bottom-2xl">

    <bw-bolt-progress-bar appFocusOnInit arialabel="Cancel scheduled payment progress value is 100%" progressBarValue="100" class="progress-bar-container to-container"></bw-bolt-progress-bar>
    <h1 class="bolt-heading-primary bolt-space-bottom-2xl page-title">Cancel scheduled payment: confirmation</h1>

    <div class="main-container">

        <ng-container *ngIf="!apiCallSuccessful">
            <bw-bolt-notification type="error">
                <span class="error-notification">We are unable to process your request. For assistance, please contact us.</span>
            </bw-bolt-notification>
        </ng-container>

        <ng-container *ngIf="apiCallSuccessful">
            <bw-bolt-notification type="success" class="bolt-space-bottom-2xl to-container">
                <span class="success-notification">Your payment has been canceled.</span>
            </bw-bolt-notification>
            <section id="payment-due">
                <app-payment-sub-header>
                    Outstanding payment due
                </app-payment-sub-header>
            </section>
            <div class="main-contents">
                <p>A payment of {{paymentAmount}} is due for this account on {{nextDueDate}}. To make a payment, select
                    Make a payment.</p>
            </div>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="apiCallSuccessful;else apiFailButtons">
    <bolt-button-bar>
        <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
        <bolt-button id="secondary-button" slot="back" (click)="navigateToBillingPreferences()">Payment preferences</bolt-button>
        <bolt-button id="submit-button" slot="forward" type="primary" (click)="navigateToMakeAPayment()">
            Make a payment
        </bolt-button>
    </bolt-button-bar>
</ng-container>
<ng-template #apiFailButtons>
    <bolt-button-bar>
        <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
        <bolt-button id="secondary-button" slot="forward" (click)="navigateToBillingPreferences()">Payment preferences</bolt-button>
    </bolt-button-bar>
</ng-template>