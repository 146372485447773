
export class UpComingPaymentModel {
    headerDetails: HeaderDetails;
    recordInfo: RecordInfo[];
}

export interface HeaderDetails {
    billLabel: string;
    amountLabel: string;
    dateLabel: string;
}
export interface RecordInfo {
    billType: string;
    amount: string;
    dateValue: Date;
}
