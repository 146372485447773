<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<main class="fluid-container">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="change default payment method progress value is 100%" progressBarValue="100" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Change default payment method: confirmation</h1>

        <ng-container *ngIf="!successfullyUpdated">
            <bw-bolt-notification type="error" id="error-notification" class="bolt-space-bottom-2xl to-container">
                <is-interpolate [text]="failureMessageHTML"></is-interpolate>
            </bw-bolt-notification>
        </ng-container>

        <ng-container *ngIf="successfullyUpdated">
            <bw-bolt-notification type="success" id="success-notification" class="bolt-space-bottom-2xl to-container">
                Your request has been processed.
            </bw-bolt-notification>

            <section id="confirmation-details">
                <app-payment-sub-header>Confirmation details</app-payment-sub-header>
                <div class="section-content bolt-space-bottom-2xl" id="confirmation-message">
                    <span class="font-weight-bold">Changed default payment method : </span> {{displayName}}, Effective
                    {{formattedCurrentDate}}.
                </div>
            </section>
        </ng-container>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="print-button" (click)="showPrintDialog()">Print</bolt-button>
        <bolt-button submit slot="forward" id="continue-button" (click)="onContinue()">Payment preferences</bolt-button>
    </bolt-button-bar>
</main>