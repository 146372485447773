import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { LoggerService } from './logger/logger.service';

@Injectable()
export class InternetServicingBillingErrorHandler implements ErrorHandler {
  constructor(
    // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
    private injector: Injector
  ) { }
  handleError(error): void {
    const logger = this.injector.get(LoggerService);
    try {
      logger.error('ERROR HANDLER', { message: error.message, stack: error.stack, error });
    } catch (e) {
      logger.error('Exception in error handler', e);
    }
    logger.logCurrentContents();
  }
}
