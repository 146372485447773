import { Component, Inject, OnInit } from '@angular/core';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { AreYouSureFormGroup } from '../../../../../../shared/payments/are-you-sure-radio-buttons/are-you-sure-form-group';
import { SessionService } from '../../../../../../shared/session/session.service';
import { Router } from '@angular/router';
import { ManagePaymentPrefsFormBuilder } from '../../../../manage/base/manage-payment-prefs-form-builder';
import { BillingCommonNavigationsService } from '../../../../../shared/billing-common-navigations.service';
import { environment } from '../../../../../../../environments/environment';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { ManagePaymentPreferencesPageTitle } from '../../../../manage/shared/models/manage-payment-preferences-page-title.constant';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { CancelScheduleFuturePaymentService } from '../services/cancel-schedule-future-payment.service';
import { CancelScheduleFuturePaymentFlow } from '../services/cancel-schedule-future-payment-flow.service';
import { PaymentPreferencesStateService } from '../../../../../../billing/payment-preferences/shared/services/payment-preferences-state/payment-preferences-state.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';

@Component({
    selector: 'app-scheduled-future-payments-landing',
    templateUrl: './cancel-scheduled-future-payment-landing.component.html',
    styleUrls: [
        './cancel-scheduled-future-payment-landing.component.scss'
    ]
})
export class CancelScheduledFuturePaymentLandingComponent extends BasePageComponent implements OnInit {
    pageLoadComplete = false;
    submitted = false;
    formGroup: AreYouSureFormGroup;
    pageName = ManagePaymentPreferencesPageTitle.scheduledFuturePayments.cancel.landing;

    // eslint-disable-next-line max-params
    constructor(
        router: Router,
        session: SessionService,
        private formBuilder: ManagePaymentPrefsFormBuilder,
        private navigation: BillingCommonNavigationsService,
        private urlUtil: UrlUtil,
        private formUtils: FormUtil,
        private cancelScheduleFuturePaymentService: CancelScheduleFuturePaymentService,
        private flow: CancelScheduleFuturePaymentFlow,
        private state: PaymentPreferencesStateService,
        @Inject('logger') private logger: LoggerService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.formGroup = this.formBuilder.newAreYouSureFormGroup();
        this.cancelScheduleFuturePaymentService.retrieveScheduledPayment().subscribe({
            next: () => this.pageLoadComplete = true,
            error: (error) => {
                this.pageLoadComplete = true;
                this.logger.error('HARDFALL: CANCEL SCHEDULED FUTURE PAYMENT LANDING API ERROR', { error });
                this.navigation.navigateToHardfall();
            }
        });
    }

    onCancelButton(): void {
        this.navigation.navigateToPaymentPreferences();
    }

    onSubmit(): void {
        this.submitted = true;
        this.formUtils.touchAllFormControls(this.formGroup.group);
        if (this.formGroup.group.valid) {
            if (this.formGroup.selection === 'yes') {
                this.cancelPayment();
            } else if (this.formGroup.selection === 'no') {
                this.cancelScheduleFuturePayment();
            }
        }
    }

    cancelScheduleFuturePayment(): void {
        this.state.resetState();
        this.flow.reset();
        this.navigation.navigateToPaymentPreferences();
    }

    cancelPayment(): void {
        this.pageLoadComplete = false;
        this.cancelScheduleFuturePaymentService.submit().subscribe({
            complete: () => this.navigateToConfirmation()
        });
    }

    navigateToConfirmation(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SCHEDULED_FUTURE_PAYMENTS.CANCEL.CONFIRMATION(this.urlUtil.hashParamsString)
        );
    }

    get paymentAmount(): string {
        return this.flow && this.flow.paymentAmount;
    }

    get paymentDate(): string {
        return this.flow && this.flow.paymentDate;
    }

    get displayMessageForForm(): string {
        return `Are you sure you want to cancel this payment of ${this.paymentAmount}, scheduled for ${this.paymentDate}?`;
    }

    get globalErrorDisplayable(): boolean {
        return !this.formGroup.group.valid && this.submitted;
    }
}
