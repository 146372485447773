import { Injectable } from '@angular/core';
import { BillingMethodType } from '@nationwide/dgs-angular-billing-common/services/internet-servicing-billing-experience/isb-personal-lines-billing-request-params';
import { CCAuthorizationAgreementParams, EFTAuthorizationAgreementParams } from '../../../../../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { DistributedDocumentsResponseModel } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/model/distributed-documents-response.model';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaperlessFlow } from '../../../../shared/models/paperless-flow.model';
import { PaymentItem } from '../../../../shared/models/payment-item.model';
import { EditAutomaticPaymentResponse } from '../review/edit-automatic-payments-review.service';

@Injectable()
export class EditAutomaticPaymentFlowService implements PaperlessFlow {
    editAutomaticPaymentForm: PaymentFormGroup;
    editAutomaticPaymentResponse: EditAutomaticPaymentResponse;
    billingAccountHolderName: string;
    amountDue: string;
    nextBillDueDate: Date;
    currentBillDueDate: Date;
    isPaperlessEligible: boolean;
    considerThisDisplayable: boolean;
    emailAddress: string;
    billingAccountNumber: string;
    selectedPaperless: string;
    selectedPayMethod: PaymentItem;
    currentBillingMethodType: BillingMethodType;
    successfullyUpdatedEnrollment: boolean;
    automaticPaymentSuspended: boolean;
    billedStatus: boolean;
    billingStreet1: string;
    billingCity: string;
    billingState: string;
    distributedDocumentsResponseModel: DistributedDocumentsResponseModel;
    authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
    isLifeOnlyAccount: boolean;
    canPayNow = false;
    paperlessEnrollmentAttempted = false;
    savedPaperlessEsddaPrefs = false;
    enrolledInPaperless = false;
    paperlessEmail: string;
    hasCreditCardVulPolicyError: boolean;
    paymentPlan: string;
    errorDetails: {
        errorMessage: string;
        errorCode: number;
    };

    get flowInPlay(): boolean {
        return this.editAutomaticPaymentForm != null;
    }

    save(params: Partial<EditAutomaticPaymentFlowService>): void {
        this.editAutomaticPaymentForm = params.editAutomaticPaymentForm || this.editAutomaticPaymentForm;
        this.billingAccountHolderName = params.billingAccountHolderName || this.billingAccountHolderName;
        this.amountDue = params.amountDue || this.amountDue;
        this.canPayNow = params.canPayNow || this.canPayNow;
        this.nextBillDueDate = params.nextBillDueDate || this.nextBillDueDate;
        this.currentBillDueDate = params.currentBillDueDate || this.currentBillDueDate;
        this.isPaperlessEligible = params.isPaperlessEligible || this.isPaperlessEligible;
        this.considerThisDisplayable = params.isPaperlessEligible;
        this.emailAddress = params.emailAddress || this.emailAddress;
        this.billingAccountNumber = params.billingAccountNumber || this.billingAccountNumber;
        this.selectedPaperless = params.selectedPaperless || this.selectedPaperless;
        this.selectedPayMethod = params.selectedPayMethod || this.selectedPayMethod;
        this.currentBillingMethodType = params.currentBillingMethodType || this.currentBillingMethodType;
        this.successfullyUpdatedEnrollment = params.successfullyUpdatedEnrollment || this.successfullyUpdatedEnrollment;
        this.automaticPaymentSuspended = params.automaticPaymentSuspended || this.automaticPaymentSuspended;
        this.billedStatus = params.billedStatus || this.billedStatus;
        this.billingStreet1 = params.billingStreet1 || this.billingStreet1;
        this.billingCity = params.billingCity || this.billingCity;
        this.billingState = params.billingState || this.billingState;
        this.distributedDocumentsResponseModel = params.distributedDocumentsResponseModel || this.distributedDocumentsResponseModel;
        this.authorizationAgreementParams = params.authorizationAgreementParams || this.authorizationAgreementParams;
        this.isLifeOnlyAccount = params.isLifeOnlyAccount;
        this.paperlessEnrollmentAttempted = params.paperlessEnrollmentAttempted || this.paperlessEnrollmentAttempted;
        this.savedPaperlessEsddaPrefs = params.savedPaperlessEsddaPrefs || this.savedPaperlessEsddaPrefs;
        this.enrolledInPaperless = params.enrolledInPaperless || this.enrolledInPaperless;
        this.editAutomaticPaymentResponse = params.editAutomaticPaymentResponse || this.editAutomaticPaymentResponse;
        this.paperlessEmail = params.paperlessEmail || this.paperlessEmail;
        this.hasCreditCardVulPolicyError = params.hasCreditCardVulPolicyError ?? this.hasCreditCardVulPolicyError;
        this.paymentPlan = params.paymentPlan || this.paymentPlan;
        this.errorDetails = params.errorDetails || this.errorDetails;
    }

    clear(): void {
        this.editAutomaticPaymentForm = undefined;
        this.selectedPayMethod = undefined;
        this.billingAccountHolderName = undefined;
        this.amountDue = undefined;
        this.nextBillDueDate = undefined;
        this.currentBillDueDate = undefined;
        this.isPaperlessEligible = undefined;
        this.considerThisDisplayable = undefined;
        this.emailAddress = undefined;
        this.billingAccountNumber = undefined;
        this.selectedPaperless = undefined;
        this.currentBillingMethodType = undefined;
        this.successfullyUpdatedEnrollment = undefined;
        this.billedStatus = undefined;
        this.billingStreet1 = undefined;
        this.billingCity = undefined;
        this.billingState = undefined;
        this.isLifeOnlyAccount = undefined;
        this.canPayNow = undefined;
        this.paperlessEnrollmentAttempted = undefined;
        this.savedPaperlessEsddaPrefs = undefined;
        this.enrolledInPaperless = undefined;
        this.emailAddress = undefined;
        this.paperlessEmail = undefined;
        this.hasCreditCardVulPolicyError = undefined;
        this.paymentPlan = undefined;
        this.errorDetails = undefined;
    }
}
