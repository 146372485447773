import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Row } from '../../../../../../shared/payments/confirmation-details-table/table-row.api';
import { PayPlanFlow } from '../../../automatic-payments/pay-plan/shared/pay-plan-flow.service';
import { PayPlanAdapter } from '../../../automatic-payments/pay-plan/shared/pay-plan.adapter';
import { environment } from '../../../../../../../environments/environment';
import { ISBRouteParams } from '../../../../../../shared/routing/isb-route.model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UpComingPaymentModel } from './up-coming-payments.model';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { PageName } from '../../../../../../shared/page-name.enum';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

@Component({
    selector: 'edit-payment-plan-confirmation',
    templateUrl: './edit-payment-plan-confirmation.component.html'
})
export class EditPaymentPlanConfirmationComponent extends BasePageComponent implements OnInit {
    isPayplanUpdateSuccess = false;
    confirmationDetails: Row[];
    upComingPaymentModel: UpComingPaymentModel;
    pageName = PageName.EDIT_PAYMENT_PLAN_CONFIRMATION;
    // eslint-disable-next-line max-params
    constructor(
        protected router: Router,
        private payPlanFlow: PayPlanFlow,
        private easternTimeDatePipe: EasternTimeDatePipe,
        private payPlanAdapter: PayPlanAdapter,
        @Inject('logger') private logger: LoggerService,
        @Inject('window') private window: Window,
        protected session: SessionService) {
        super(session, router);
    }

    ngOnInit(): void {
        if (!this.payPlanFlow.flowInPlay) {
            this.cancelEditPaymentPlan();
        }
        this.session.currentPage = this.pageName;
        this.isPayplanUpdateSuccess = this.payPlanFlow.isPayPlanUpdateSuccess;
        this.initializeConfirmationDetails();
    }

    currentRouteParams(): string {
        return this.router.url.split('?')[1];
    }

    cancelEditPaymentPlan(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(
                this.currentRouteParams()
            )
        );
    }

    initializeConfirmationDetails(): void {
        this.confirmationDetails = [
            { label: 'Account number:', value: this.payPlanFlow.billingAccountNumber },
            { label: 'Payment Plan:', value: this.payPlanFlow.selectedPayPlan },
            { label: 'Effective date', value: this.easternTimeDatePipe.transform(this.payPlanFlow.payPlanChangeDate, 'MM/dd/yyyy') },
            { label: 'Policy type', value: this.payPlanFlow.policyType }
        ];
        this.buildUpComingPaymentsSection();
    }

    buildUpComingPaymentsSection(): void {
        this.upComingPaymentModel = this.payPlanAdapter.mapUpComingPayments();
    }

    get payPlan(): string {
        return this.payPlanFlow.selectedPayPlan;
    }

    get canPayNow(): boolean {
        return this.payPlanFlow.canPayNow;
    }

    get contactusUrl(): string {
        return environment.CONTACT_US.url;
    }

    navigateToMakeAPayment(): void {
        this.logger.info('On click of Edit Payment Plan Make a Payment link');
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.RELATIVE.payBillLanding(this.urlParams));
    }

    showPrintDialog(): void {
        this.window.print();
    }

    get billLabel(): string {
        return this.upComingPaymentModel?.headerDetails?.billLabel;
    }

    get amountLabel(): string {
        return this.upComingPaymentModel?.headerDetails?.amountLabel;
    }

    get dateLabel(): string {
        return this.upComingPaymentModel?.headerDetails?.dateLabel;
    }

    get urlParams(): ISBRouteParams {
        return {
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId,
            policyNumber: this.session.policyNumber
        };
    }
}
