import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TimeoutModalComponent } from './time-out/time-out.component';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { SharedBillingComponentsModule } from '@nationwide/dgs-angular-billing-common';

@NgModule({
    imports: [
        InternetServicingAngularComponentsModule,
        SharedBillingComponentsModule
    ],
    declarations: [TimeoutModalComponent],
    exports: [TimeoutModalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SessionComponentsModule { }
