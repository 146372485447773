<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<div class="bolt-container">

    <bw-bolt-progress-bar appFocusOnInit arialabel="remove payment method progress value is 100%" progressBarValue="100" class="progress-bar-container to-container"></bw-bolt-progress-bar>
    <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Remove payment method: confirmation</h1>

    <div class="bolt-row">
        <div class="bolt-col-12">

            <ng-container *ngIf="!apiCallsSuccessful; else successful">
                <bw-bolt-notification type="error" [id]="'global-error-message'" class="bolt-space-bottom-2xl to-container">
                    {{ failureMessageHTML }}
                </bw-bolt-notification>
            </ng-container>

            <ng-template #successful>
                <bw-bolt-notification type="success" [id]="'global-success-message'" class="bolt-space-bottom-2xl to-container">
                    Your request has been processed.
                </bw-bolt-notification>

                <section id="confirmation-details">
                    <app-payment-sub-header>Confirmation details</app-payment-sub-header>

                    <div class="section-content bolt-space-bottom-2xl" id="confirmation-message">
                        <span class="font-weight-bold">{{ 'Removed ' + (isDefaultPaymentMethod?'default ':'') + 'payment method:' }}</span>{{ confirmationMessage }}
                    </div>
                </section>
            </ng-template>
        </div>
    </div>
</div>
<ng-container *ngIf="apiCallsSuccessful; else apiFailButtons">
    <bolt-button-bar>
        <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print
        </bolt-button>
        <bolt-button id="continue-button" slot="forward" (click)="onContinue()">Payment preferences
        </bolt-button>
    </bolt-button-bar>
</ng-container>
<ng-template #apiFailButtons>
    <bolt-button-bar>
        <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print
        </bolt-button>
        <bolt-button id="continue-button" slot="forward" type="primary" (click)="onContinue()">Payment preferences
        </bolt-button>
    </bolt-button-bar>
</ng-template>