import { Injectable, Inject } from '@angular/core';
import { SessionService } from '../../shared/session/session.service';
import { QUICK_PAY_CONSTANTS } from '../../quick-pay/quick-pay.constants';

@Injectable()
export class PaymentFlowType {
    private _isCyberLifePolicyQuickPayFlow: boolean;
    private _lifeQuickPayFlagFromSaved: boolean;
    private _cyberLifePolicyNumber: string;
    private _isSAPBillingQuickPayFlow: boolean;
    // Split Bill
    private _splitPayGuid: string;
    constructor(
        private sessionService: SessionService,
        @Inject('window') private window: Window
    ) { }

    get isPolicyLevel(): boolean {
        return !!this.getParameterByName('pkey') || this.sessionService.isCyberLifePolicyQuickPayFlow || this.sessionService.isPolicyLevelQuickPayPayment || this.sessionService.isPayMidTermChange;
    }

    get pKey(): string {
        return this.getParameterByName('pkey');
    }

    get bKey(): string {
        return this.getParameterByName('bkey');
    }

    get isAccountLevel(): boolean {
        return !this.isPolicyLevel;
    }

    get splitPayGUID(): string {
        return this._splitPayGuid;
    }

    set splitPayGUID(guid: string) {
        this._splitPayGuid = guid;
    }

    set GUID(guid: string) {
        this._splitPayGuid = guid;
    }

    get isCyberLife(): boolean {
        const sourceSytemParam = this.getParameterByName('sourceSystem')?.toLowerCase() || '';
        const cyberLifeFlag: boolean = sourceSytemParam === 'cyberlife';
        return cyberLifeFlag || this.sessionService.isCyberLifePolicyQuickPayFlow;
    }
    get isOipa(): boolean {
        return this.sessionService.hasLifePolicy && !this.sessionService.hasCyberLifePolicy;
    }

    get isNonOwner(): boolean {
        let output: boolean;
        if (this.isAccountLevel) {
            output = !this.sessionService.isCurrentAccountContainedInJwt;
        } else if (this.isPolicyLevel) {
            output = !this.sessionService.isCurrentPolicyContainedInJwt;
        }
        return output;
    }

    get isSplitPartyUser(): boolean {
        let output: boolean;
        if (this.isAccountLevel) {
            output = !this.sessionService.isCurrentAccountContainedInJwt;
        }
        return output;
    }

    get isQuickpay(): boolean {
        const url = this.window.location.href;
        return url.includes('quickpay');
    }

    get isLifeQuickpay(): boolean {
        return this.getParameterByName('lob') === 'life';
    }

    get isQuickPaySessionTimeOut(): boolean {
        return this.getParameterByName('isSessionTimeOut') === 'true';
    }

    get isCyberLifePolicyQuickPayFlow(): boolean {
        return this._isCyberLifePolicyQuickPayFlow;
    }

    set cyberLifePolicyQuickPayFlow(system: string) {
        this._isCyberLifePolicyQuickPayFlow = QUICK_PAY_CONSTANTS.CYBER_LIFE === system;
    }

    get isSapBillingQuickPayFlow(): boolean {
        return this._isSAPBillingQuickPayFlow;
    }

    getContextCacheContextId(): string {
        return this.getParameterByName('contextId') || this.sessionService.contextCacheContextId;
    }

    isPayMidTermChange(): boolean {
        return !!this.getParameterByName('payMidTermChange') || this.sessionService.isPayMidTermChange;
    }

    set sapBillingQuickPayFlow(system: string) {
        this._isSAPBillingQuickPayFlow = QUICK_PAY_CONSTANTS.SAP_BILLING === system;
    }

    get isCancelNavigationFromIcpMakePaymentPage(): boolean {
        return this.getParameterByName('cancelNavigation') === 'fromIcpMAPPage';
    }
    get isBackNavigationFromIcpMakePaymentPage(): boolean {
        return this.getParameterByName('backNavigation') === 'fromIcpMAPPage';
    }

    // COPIED FROM https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
    getParameterByName(name: string): string {
        const url = this.window.location.href;
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);

        if (!results) {
            return null;
        }

        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    get lifeQuickPayFlagFromSaved(): boolean {
        return this._lifeQuickPayFlagFromSaved;
    }
    set lifeQuickPayFlagFromSaved(value: boolean) {
        this._lifeQuickPayFlagFromSaved = value;
    }
    get cyberLifePolicyNumber(): string {
        return this._cyberLifePolicyNumber;
    }

    set cyberLifePolicyNumber(value: string) {
        this._cyberLifePolicyNumber = value;
    }
}
