import { NgModule } from '@angular/core';
import { BillingTileConfig, billingTileConfigToken } from '@nationwide/angular-billing-tile';
import { OAuthConfig } from '@nationwide/angular-oauth-module';
import { McrConfig, PaymentKillswitch } from '@nationwide/angular-timeline';
import {
    AccountValidationConfig,
    accountValidationConfigToken, AgencyInformationConfig,
    agencyInformationConfigToken, ChaseProfileCreationConfig,
    ChaseProfileCreationConfigToken, cimConfigToken, CimConfiguration, contentfulConfigToken, ContentfulConfiguration, contentfulTestConfigToken, contextCacheConfigToken, ContextCacheConfiguration, DistributedDocumentsConfig,
    distributedDocumentsConfigToken,
    DpimAgencyInformationConfig,
    dpimAgencyInformationConfigToken, EncryptBankcardConfig,
    encryptBankcardConfigToken, enterpriseCIMCofigToken, EnterpriseCIMConfiguration, isbExperienceConfigToken, IsbExperienceConfiguration, ispConfigToken, IspConfiguration, masterpassConfigToken, MasterpassConfiguration, mobiusDocumentManagementConfigToken, MobiusDocumentManagementConfiguration, moneyProcessingConfigToken, MoneyProcessingConfiguration, OneTimePasswordConfig,
    otpConfigToken, pbsConfigToken,
    PbsConfiguration,
    PersonalBillingConfiguration, PreferenceCenterConfig, preferenceCenterConfigToken, ValidatorConfig,
    validatorConfigToken
} from '@nationwide/dgs-angular-billing-common';
import {
    DistributionPartnerInformationServiceConfig,
    distributionPartnerInformationServiceConfigToken
} from '@nationwide/internet-servicing-dpim-requests';
import { environment } from '../environments/environment';
import { tridionDownConfigToken } from './error/tridion-down/tridion-down.config';
import { MakePaymentConfig, makePaymentConfigToken } from './pay-bill/pay-bill-landing/pay-bill.config';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from './shared/constants/common.constants';
import { DocumentOpenerConfig, documentOpenerConfigToken } from './shared/docs/doc-opener/doc-opener.config';
import { ISBRouteParams } from './shared/routing/isb-route.model';
import { TimeOutConfig, timeOutConfigToken } from './shared/session/time-out/time-out.config';

const pbsConfiguration: PbsConfiguration = {
    rcaEndpoint: environment.PBS.ENDPOINTS.RCA,
    rcawrEndpoint: environment.PBS.ENDPOINTS.RCAWR,
    rcpEndpoint: environment.PBS.ENDPOINTS.RCP,
    searchAgreementEndPoint: environment.PBS.ENDPOINTS.SEARCH_AGREEMENT,
    searchPhoneNumberEndPoint: environment.PBS.ENDPOINTS.SEARCH_PHONE_NUMBER,
    splitPartyEndpoint: environment.PBS.ENDPOINTS.SPLIT_PARTY_RCA,
    version: environment.PBS.VERSION,
    clientId: environment.CLIENT_ID
};

const cimConfiguration: CimConfiguration = {
    cimEndpoint: (agreementNumber) => environment.NFCIM.cimEndpoint(agreementNumber),
    clientId: environment.CLIENT_ID
};

const personalBillingConfiguration: PersonalBillingConfiguration = {
    clientId: environment.CLIENT_ID,
    billsUrl: environment.BILLING.ENDPOINTS.bills_url,
    relatedPolicesUrl: environment.BILLING.ENDPOINTS.related_policies,
    policyEventsUrl: environment.BILLING.ENDPOINTS.policies_events,
    transactionsUrl: environment.BILLING.ENDPOINTS.transactions,
    billingAccountUrl: environment.BILLING.ENDPOINTS.billing_account,
    payPlanUrl: environment.BILLING.ENDPOINTS.payPlanUrl,
    zeroAmountDueUrl: environment.BILLING.ENDPOINTS.zero_amount_due_url,
    patchPayPlanUrl: environment.BILLING.ENDPOINTS.patch_pay_plan,
    billCalculationInquiryUrl: environment.BILLING.ENDPOINTS.bill_calculation_inquiry,
    connectionType: environment.CONNECTION_TYPE,
    isConnectionTypeRequired: environment.IS_CONNECTION_TYPE_REQUIRED,
    isAccessTokenRequired: true,
    retrieveDueDatesUrl: environment.BILLING.ENDPOINTS.retrieve_due_dates,
    updateDueDatesUrl: environment.BILLING.ENDPOINTS.update_due_dates,
    retrieveCustomerBillingAccountsUrl: environment.BILLING.ENDPOINTS.retrieve_customer_billing_accounts_url,
    retrieveRefundStatusesUrl: environment.BILLING.ENDPOINTS.refund_statuses
};

const enterpriseCIMCofig: EnterpriseCIMConfiguration = {
    depth: environment.ECIM.DEPTH,
    clientId: environment.CLIENT_ID,
    getInternetRegistrationsUrl: environment.ECIM.URL.getInternetRegistrationsUrl,
    getAgreementUrl: environment.ECIM.URL.getAgreementUrl
};

const paymentPreferenceConfig: PreferenceCenterConfig = {
    clientId: environment.CLIENT_ID,
    postPreferenceUrl: environment.PREFERENCECENTER.URL.postPreferenceUrl,
    getPreferenceUrl: (a) => 'not implemented yet'
};

const paymentKillswitch: PaymentKillswitch = {
    paymentsAvailable: environment.MAKE_A_PAYMENT
};

const mcrConfig: McrConfig = {
    isMcr: false
};

const timeOutConfig: TimeOutConfig = {
    pollIntervalMilliseconds: environment.TIMEOUT.pollIntervalSeconds * MILLISECONDS_IN_SECOND,
    sessionLogOutUrl: environment.TIMEOUT.sessionLogOutUrl,
    logOutUrl: environment.TIMEOUT.logOutUrl,
    logInUrl: environment.TIMEOUT.logInUrl,
    inactivityThresholdMilliseconds: environment.TIMEOUT.inactivityThresholdMinutes * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND,
    timeToTimeoutWarningMilliseconds: environment.TIMEOUT.minutesToTimeoutWarning * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND,
    excludedPaths: environment.TIMEOUT.excludedPaths,
    accessTokenKey: environment.OAUTH_CONFIG.tokenStorage
};

const oauth: OAuthConfig = environment.OAUTH_CONFIG;

const mobiusDocumentMangementConfig: MobiusDocumentManagementConfiguration = {
    clientId: environment.CLIENT_ID,
    documentSearchUrl: environment.MOBIUS_DOCUMENT_MANAGEMENT.documentSearchUrl
};

const moneyProcessingConfiguration: MoneyProcessingConfiguration = {
    clientId: environment.CLIENT_ID,
    personalLinesAccountPaymentsUrl: environment.BILLING.ENDPOINTS.money_processing_personal_payments_url,
    banksUrl: environment.BILLING.ENDPOINTS.retrieveBank,
    personalLinesVoidPaymentUrl: (a, b) => 'not implemented yet',
    savedPaymentMethodsUrl: environment.BILLING.ENDPOINTS.savedPaymentMethodsUrl,
    submitPaymentUrl: environment.BILLING.ENDPOINTS.money_processing_personal_payments_url,
    saveNewPayMethodUrl: environment.BILLING.ENDPOINTS.saveNewPayMethodUrl,
    updatePayMethodUrl: environment.BILLING.ENDPOINTS.updatePayMethodUrl,
    billingRegion: environment.CONNECTION_TYPE.slice(2),
    singleSavedPaymentMethodUrl: environment.BILLING.ENDPOINTS.singleSavedPaymentMethodUrl,
    billingAccountPaymentMethodUrl: environment.BILLING.ENDPOINTS.billingAccountPaymentMethodUrl
};

const masterpassConfiguration: MasterpassConfiguration = {
    masterpassEncryptDataUrl: environment.WALLETPAY.ENDPOINTS.masterpassEncryptDataUrl,
    masterpassPostbackUrl: environment.WALLETPAY.ENDPOINTS.masterpassPostbackUrl,
    masterpassISPEncryptDataUrl: environment.WALLETPAY.ENDPOINTS.masterpassISPEncryptDataUrl,
    masterpassISPPostbackUrl: environment.WALLETPAY.ENDPOINTS.masterpassISPPostbackUrl
};

const otpConfig: OneTimePasswordConfig = {
    clientId: environment.CLIENT_ID,
    endpoint: environment.ONE_TIME_PASSWORD.url
};

const billingTileConfiguration: BillingTileConfig = {
    urls: {
        billsPayment: environment.ISB.ENDPOINTS.billsAndPayments,
        makePayment: environment.ISB.ENDPOINTS.makePayment,
        makePaymentPolicylevel: environment.ISB.ENDPOINTS.makePaymentPolicylevel,
        billingDetails: environment.ISB.ENDPOINTS.billingTimeline
    },
    // TODO these below fields are no longer needed. remove from angular billing tile
    clientId: 'c68fN5C5AGkQlysNOCLuzGGv0dvmAVD3',
    pbsInternetServicing: 'https://api-test.nwie.net/operations-management/internet-servicing/v1/billing-accounts/'
};

const timeLineContentfulConfig: ContentfulConfiguration = {
    space: environment.contentful.space,
    environment: environment.contentful.environment,
    accessToken: environment.contentful.accessToken,
    contentfulDeliveryAccessToken: environment.contentful.contentfulDeliveryAccessToken,
    contentfulPreviewAccessToken: environment.contentful.contentfulPreviewAccessToken
};

const makePaymentConfiguration: MakePaymentConfig = {
    urls: {
        cancelMakePayment: (canAccessBillingPages: boolean, isQuickpay: boolean, parameters: ISBRouteParams): string =>
            environment.MAKE_PAYMENT_CANCEL_BUTTON_LINK(parameters, isQuickpay, canAccessBillingPages)
    }
};

const documentsConfig = {
    authorize: {
        client_id: environment.CLIENT_ID,
        clientId: environment.CLIENT_ID
    },
    APIs: {
        apigeeEnvironment: environment.DOCUMENTS.apigeeEnvironment
    },
    useMockDocuments: false,
    mobile: false,
    DOCUMENTS: {
        search: environment.DOCUMENTS.search,
        mobiusEndpoint: environment.DOCUMENTS.mobiusEndpoint,
        repoId: environment.DOCUMENTS.repoId,
        consumerId: environment.DOCUMENTS.consumerId,
        docusignApiEndpoint: environment.DOCUMENTS.docusignApiEndpoint,
        filenetEndpoint: environment.DOCUMENTS.filenetEndpoint
    }
};

const ispConfig: IspConfiguration = {
    clientId: environment.CLIENT_ID,
    billsAndPaymentsUrl: environment.ISP.ENDPOINTS.BILLS_AND_PAYMENTS,
    retrievePaymentHistoryUrl: environment.ISP.ENDPOINTS.RETREIVE_PAYMENT_HISTORY,
    connectionType: environment.CONNECTION_TYPE
};

const docOpenerConfig: DocumentOpenerConfig = {
    endpoint: environment.DOCUMENT_OPENER.endpoint
};

const encryptBankcardConfig: EncryptBankcardConfig = {
    endpoint: environment.CARD_ENCRYPTION.url
};

const validatorConfig: ValidatorConfig = {
    endpoints: {
        save: (id: string): string => `https://api-test.nwie.net/testing-independence/validator/v1/cached-responses/${id}/save`,
        retrieve: (id: string): string => `https://api-test.nwie.net/testing-independence/validator/v1/cached-responses/${id}/retrieve`
    },
    enableSave: environment.SAVE_TO_VALIDATOR,
    enableRetrieve: environment.RETRIEVE_FROM_VALIDATOR
};

const accountValidationConfig: AccountValidationConfig = {
    clientId: environment.CLIENT_ID,
    url: environment.ACCOUNT_VALIDATION.url
};

const contextCacheConfig: ContextCacheConfiguration = {
    clientId: environment.CLIENT_ID,
    contextsUrl: environment.CONTEXT_CACHE.contextsUrl
};

const isbExperienceApiConfig: IsbExperienceConfiguration = {
    clientId: environment.CLIENT_ID,
    billingRegion: environment.CONNECTION_TYPE.slice(2),
    moneyProcessing: environment.ISB_EXP_API.moneyProcessing,
    personalLinesBilling: environment.ISB_EXP_API.personalLinesBilling,
    customerEmails: environment.ISB_EXP_API.customerEmails,
    contexts: environment.ISB_EXP_API.contexts
};

const chaseProfileCreationConfig: ChaseProfileCreationConfig = {
    endpoint: environment.CHASE_PROFILE_CREATION_URL,
    clientId: environment.CLIENT_ID
};
const agencyInformationConfig: AgencyInformationConfig = {
    clientId: environment.CLIENT_ID,
    retrieveAgencyInfoUrl: environment.AGENCY_INFORMATION.url,
    billingRegion: environment.CONNECTION_TYPE.slice(2)
};

const distributedDocumentsConfig: DistributedDocumentsConfig = {
    clientId: environment.CLIENT_ID,
    distributedDocumentsUrl: environment.DISTRIBUTED_DOCUMENTS.url,
    targetEnvironment: environment.DISTRIBUTED_DOCUMENTS.targetEnvironment
};
const dpimAgencyInformationConfig: DpimAgencyInformationConfig = {
    retrieveDpimAgencyInfoUrl: environment.DPIM_AGENCY_INFORMATION.url,
    clientId: environment.CLIENT_ID
};

const distributionPartnerInformationServiceConfig: DistributionPartnerInformationServiceConfig = {
    retrieveDistributionPartnerInfoUrl: environment.GETDISTRIBUTIONPARTNER_INFO.url,
    clientId: environment.CLIENT_ID
};

@NgModule({
    providers: [
        { provide: 'config', useValue: environment },
        { provide: 'ctAuthConfig', useFactory: (): OAuthConfig => oauth },
        { provide: pbsConfigToken, useValue: pbsConfiguration },
        { provide: cimConfigToken, useValue: cimConfiguration },
        { provide: PersonalBillingConfiguration, useFactory: (): PersonalBillingConfiguration => personalBillingConfiguration },
        { provide: PaymentKillswitch, useValue: paymentKillswitch },
        { provide: McrConfig, useValue: mcrConfig },
        { provide: timeOutConfigToken, useValue: timeOutConfig },
        { provide: mobiusDocumentManagementConfigToken, useValue: mobiusDocumentMangementConfig },
        { provide: moneyProcessingConfigToken, useFactory: (): MoneyProcessingConfiguration => moneyProcessingConfiguration },
        { provide: masterpassConfigToken, useValue: masterpassConfiguration },
        { provide: billingTileConfigToken, useValue: billingTileConfiguration },
        { provide: contentfulConfigToken, useValue: timeLineContentfulConfig },
        { provide: contentfulTestConfigToken, useValue: timeLineContentfulConfig },
        { provide: makePaymentConfigToken, useValue: makePaymentConfiguration },
        { provide: tridionDownConfigToken, useValue: environment.TRIDION_DOWN_CONFIG },
        { provide: ispConfigToken, useFactory: (): IspConfiguration => ispConfig },
        { provide: documentOpenerConfigToken, useValue: docOpenerConfig },
        { provide: otpConfigToken, useValue: otpConfig },
        { provide: encryptBankcardConfigToken, useValue: encryptBankcardConfig },
        { provide: validatorConfigToken, useFactory: (): ValidatorConfig => validatorConfig },
        { provide: enterpriseCIMCofigToken, useValue: enterpriseCIMCofig },
        { provide: preferenceCenterConfigToken, useValue: paymentPreferenceConfig },
        { provide: accountValidationConfigToken, useFactory: (): AccountValidationConfig => accountValidationConfig },
        { provide: contextCacheConfigToken, useFactory: (): ContextCacheConfiguration => contextCacheConfig },
        { provide: isbExperienceConfigToken, useValue: isbExperienceApiConfig },
        { provide: ChaseProfileCreationConfigToken, useValue: chaseProfileCreationConfig },
        { provide: agencyInformationConfigToken, useFactory: (): AgencyInformationConfig => agencyInformationConfig },
        { provide: distributedDocumentsConfigToken, useFactory: (): DistributedDocumentsConfig => distributedDocumentsConfig },
        { provide: dpimAgencyInformationConfigToken, useFactory: (): DpimAgencyInformationConfig => dpimAgencyInformationConfig },
        { provide: distributionPartnerInformationServiceConfigToken, useFactory: (): DistributionPartnerInformationServiceConfig => distributionPartnerInformationServiceConfig }
    ]
})
export class AppServiceConfigModule { }
