import { Injectable } from '@angular/core';
import { PaymentPreferencesStateService } from '../payment-preferences-state/payment-preferences-state.service';
import { PBS, IsbExperience, PersonalBillingRequestParams, IsbCalculateDownPaymentRequestParams, ECIM, ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { Observable, of, forkJoin, map, mergeMap } from 'rxjs';
import { PaymentPreferencesApiHttpService } from './payment-preferences-api.http.service';
import * as returnTypes from './payment-preferences-api-calls.model';
import { HttpCachingService } from '../../../../../shared/service/http-caching-service/http-caching.service';
import { SessionService } from '../../../../../shared/session/session.service';
@Injectable()
export class PaymentPreferencesApiService {
    // eslint-disable-next-line max-params
    constructor(
        private paymentPreferencesState: PaymentPreferencesStateService,
        private paymentPreferencesHttpService: PaymentPreferencesApiHttpService,
        private httpCachingService: HttpCachingService,
        private session: SessionService,
        readonly contentfulMapperService: ContentfulMapperService
    ) { }

    viewPaymentPreferences(personalBillingRequestParams: PersonalBillingRequestParams, calculateDownPaymentRequestParams: IsbCalculateDownPaymentRequestParams): Observable<returnTypes.ViewPaymentPreferences> {
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() =>
                forkJoin({
                    retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
                    savedPaymentMethods: this.populateObservable(
                        this.paymentPreferencesState.savedPaymentMethods,
                        this.paymentPreferencesHttpService.fetchSavedPaymentMethods()),
                    automaticPayments: this.populateObservable(
                        this.paymentPreferencesState.automaticPayments,
                        this.paymentPreferencesHttpService.fetchAutomaticPayments()),
                    refundMethod: this.populateObservable(
                        this.paymentPreferencesState.refundMethod,
                        this.paymentPreferencesHttpService.fetchSavedRefundMethod()),
                    payPlanEligible: of({}),
                    calculateDownPayment: this.session.payPlanEligibleFromPbsRule === 'true' ? this.populateObservable(
                        this.paymentPreferencesState.calculateDownPayment,
                        this.paymentPreferencesHttpService.calculateDownPayment(calculateDownPaymentRequestParams)) : of({}),
                    bills: this.populateObservable(
                        this.paymentPreferencesState.bills,
                        this.paymentPreferencesHttpService.fetchBills()),
                    relatedPolicies: this.populateObservable(
                        this.paymentPreferencesState.relatedPolicies,
                        this.paymentPreferencesHttpService.fetchRelatedPolicies(personalBillingRequestParams)
                    )

                })
            ));
    }

    editPaymentPlanLanding(personalBillingRequestParams: PersonalBillingRequestParams): Observable<returnTypes.EditPaymentPlan> {
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() =>
                forkJoin({
                    retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
                    bills: this.populateObservable(
                        this.paymentPreferencesState.bills,
                        this.paymentPreferencesHttpService.fetchBills()),
                    relatedPolicies: this.populateObservable(
                        this.paymentPreferencesState.relatedPolicies,
                        this.paymentPreferencesHttpService.fetchRelatedPolicies(personalBillingRequestParams)
                    )
                })
            ));
    }

    createPaymentPreferencesLanding(): Observable<returnTypes.CreatePaymentPreferencesLanding> {
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() =>
                forkJoin({
                    retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
                    savedPaymentMethods: this.populateObservable(
                        this.paymentPreferencesState.savedPaymentMethods,
                        this.paymentPreferencesHttpService.fetchSavedPaymentMethods()),
                    internetRegistrationResponse: this.retrieveInternetRegistrationResponse(),
                    automaticPayments: this.populateObservable(
                        this.paymentPreferencesState.automaticPayments,
                        this.paymentPreferencesHttpService.fetchAutomaticPayments())
                })
            ));
    }

    createAddSavedPaymentMethodLanding(): Observable<returnTypes.AddSavedPaymentMethodLanding> {
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() =>
                forkJoin({
                    retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
                    savedPaymentMethods: this.populateObservable(
                        this.paymentPreferencesState.savedPaymentMethods,
                        this.paymentPreferencesHttpService.fetchSavedPaymentMethods()),
                    internetRegistrationResponse: this.retrieveInternetRegistrationResponse()
                })
            ));
    }

    editSavedPaymentMethodLanding(): Observable<returnTypes.EditSavedPaymentMethodLanding> {
        const payMethodId = this.paymentPreferencesState.currentPaymentMethodId;
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() =>
                forkJoin({
                    retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
                    savedPaymentMethod: this.populateObservable(
                        this.paymentPreferencesState.getSingleSavedPaymentMethod(payMethodId),
                        this.paymentPreferencesHttpService.fetchSingleSavedPaymentMethod(payMethodId)
                    ),
                    internetRegistrationResponse: this.retrieveInternetRegistrationResponse()
                })
            ));
    }

    editAutomaticPaymentLanding({ callContentful = false }): Observable<returnTypes.EditAutomaticPaymentPaymentLanding> {
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() =>
                forkJoin({
                    retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
                    automaticPayments: this.populateObservable(
                        this.paymentPreferencesState.automaticPayments,
                        this.paymentPreferencesHttpService.fetchAutomaticPayments()
                    ),
                    internetRegistrationResponse: this.retrieveInternetRegistrationResponse(),
                    contentful: callContentful ? this.contentfulMapperService.getContentfulBillingContent() : of(undefined)
                })
            ));
    }

    retrieveInternetRegistrationResponse(): Observable<ECIM.EnterpriseCustomerInformationManagementResponse> {
        return this.populateObservable(
            this.paymentPreferencesState.internetRegistrationResponse,
            this.paymentPreferencesHttpService.fetchInternetRegistrationResponse());
    }

    getSingleSavedPaymentMethod(savedPaymentMethodID?: string): Observable<IsbExperience.SavedPaymentMethod> {
        const payMethodId = savedPaymentMethodID || this.paymentPreferencesState.currentPaymentMethodId;
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() =>
                this.populateObservable(
                    this.paymentPreferencesState.getSingleSavedPaymentMethod(payMethodId),
                    this.paymentPreferencesHttpService.fetchSingleSavedPaymentMethod(payMethodId)
                )
            ));
    }

    managePaymentPreferencesBase(): Observable<PBS.RetriveCustomerAgreementResponse> {
        return this.httpCachingService.fetchRetrieveCustomerAgreementResponse();
    }

    cancelFutureSchedulePaymentsLanding(startDate: Date, endDate: Date): Observable<returnTypes.CancelFutureSchedulePaymentsLanding> {
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() =>
                forkJoin({
                    retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
                    paymentsHistory: this.populateObservable(
                        this.paymentPreferencesState.paymentsHistory,
                        this.paymentPreferencesHttpService.fetchPaymentHistory(startDate, endDate))
                })
            ));
    }

    cancelAndSuspendAutomaticPaymentLanding(): Observable<returnTypes.CancelAndSuspendAutomaticPaymentLanding> {
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() =>
                forkJoin({
                    retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
                    automaticPayments: this.populateObservable(
                        this.paymentPreferencesState.automaticPayments,
                        this.paymentPreferencesHttpService.fetchAutomaticPayments()),
                    internetRegistrationResponse: this.retrieveInternetRegistrationResponse()
                })
            ));
    }

    removeSavedPaymentMethodLanding(): Observable<returnTypes.RemoveSavedPaymentMethodLanding> {
        const methodIdToRemove = this.paymentPreferencesState.currentPaymentMethodId;

        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() => forkJoin({
                savedPaymentMethods: this.populateObservable(
                    this.paymentPreferencesState.savedPaymentMethods,
                    this.paymentPreferencesHttpService.fetchSavedPaymentMethods()),
                paymentMethodToRemove: this.populateObservable(
                    this.paymentPreferencesState.getSingleSavedPaymentMethod(methodIdToRemove),
                    this.paymentPreferencesHttpService.fetchSingleSavedPaymentMethod(methodIdToRemove)
                )
            })
            ));
    }

    addSavedRefundMethodLanding(): Observable<returnTypes.AddSavedRefundPaymentMethodLanding> {
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() => forkJoin({
                internetRegistrationResponse: this.retrieveInternetRegistrationResponse(),
                retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse(),
                savedBankAccountMethods: this.populateObservable(
                    this.paymentPreferencesState.savedPaymentMethods,
                    this.paymentPreferencesHttpService.fetchSavedPaymentMethods()
                ).pipe(
                    map((savedPaymentMethods) => savedPaymentMethods.filter((method) => method.electronicFundsTransfer !== undefined))
                )
            })
            ));
    }

    removeSavedRefundMethodLanding(): Observable<returnTypes.RemoveSavedRefundMethodLanding> {
        return this.initializeSessionBeforeCalls().pipe(
            mergeMap(() => forkJoin({
                savedRefundMethod: this.populateObservable(
                    this.paymentPreferencesState.refundMethod,
                    this.paymentPreferencesHttpService.fetchSavedRefundMethod()),
                internetRegistrationResponse: this.retrieveInternetRegistrationResponse(),
                retrieveCustomerAgreement: this.httpCachingService.fetchRetrieveCustomerAgreementResponse()
            })
            ));
    }

    initializeSessionBeforeCalls(): Observable<void> {
        return this.session.hasInitialized ? of(undefined) :
            this.httpCachingService.fetchRetrieveCustomerAgreementResponse().pipe(map(() => undefined));
    }

    initSessions(): Observable<void> {
        return this.initializeSessionBeforeCalls();
    }

    private populateObservable<T>(stateObjectToCheck: T, httpCallToMake: Observable<T>): Observable<T> {
        let outputObservable: Observable<T>;

        if (stateObjectToCheck) {
            outputObservable = of(stateObjectToCheck);
        } else {
            outputObservable = httpCallToMake;
        }

        return outputObservable;
    }
}
