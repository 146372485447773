<is-wait-message [loadComplete]="!apiCallInProgress" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>
<div class="bolt-container">
    <bw-bolt-progress-bar appFocusOnInit arialabel="edit payment plan progress value is 30%" class="progress-bar-container to-container" progressBarValue="30">
    </bw-bolt-progress-bar>
    <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Payment plan: select</h1>

    <strong>Billing account #</strong>: {{billingAccountNumber}} <br>
    <strong>Policy type</strong>: {{agreementType}} <br>
    <strong>Policy number</strong>: {{policyNumber}} <br>
    <br>

    <div class="bolt-row">
        <div class="bolt-col-6">
            <div class="card-header">
                Current plan
            </div>
            <div [ngClass]="payCardType == 'month'? 'selected-card bolt-background-vibrantBlue': 'card bolt-background-white'" class="card-height">
                <h4 class="bold">Monthly pay plan</h4>
                <p>{{ amountDue | currency:'USD'}}</p>
                <div *ngIf="showEmptyBillResponse">
                    <br>
                </div>
                <p class="description">{{monthPayDescription}}</p>
                <br>
                <div class="bolt-row">
                    <div class="btn-bg-style">
                        <bolt-button *ngIf="payCardType != 'month'" (click)="onMonthPaySelected()">Select</bolt-button>
                        <bolt-button iconleft="checkmark" type="tertiary" *ngIf="payCardType == 'month'" color="white" (click)="onMonthPaySelected()">Selected</bolt-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="bolt-col-6">
            <div class="empty-header">
            </div>
            <div [ngClass]="payCardType == 'full'? 'selected-card bolt-background-vibrantBlue': 'card bolt-background-white'">
                <h4 class="bold">Full pay plan</h4>
                <p class="selected-premium">{{ premium | currency:'USD'}}</p>
                <p class="description">{{fullPayDescription}}</p>
                <div class="bolt-row">
                    <div class="btn-bg-style">
                        <bolt-button *ngIf="payCardType != 'full'" (click)="onFullPaySelected()">Select</bolt-button>
                        <bolt-button iconleft="checkmark" type="tertiary" *ngIf="payCardType == 'full'" color="white" (click)="onFullPaySelected()">Selected</bolt-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <bw-bolt-notification type="info" class="bolt-space-bottom-2xl to-container" *ngIf="payCardType=='full'">
        <is-interpolate [text]="infoText"></is-interpolate>
    </bw-bolt-notification>
    <bolt-checkbox [attr.invalid]="disAgreedError ? true : null" ngDefaultControl *ngIf="payCardType=='full'" value="agree" required (change)="onAgreeChanged($event)">{{agreementText}}</bolt-checkbox>
    <app-form-control-error id="esdda-checkbox-error" *ngIf="disAgreedError">
        Please acknowledge your changes.
    </app-form-control-error>
    <br>
</div>
<bolt-button-bar>
    <bolt-button (click)="cancelEditPaymentPlan()" slot="cancel" id="cancel-button">Cancel</bolt-button>
    <bolt-button submit slot="forward" type="primary" id="continue-button" [disabled]="payCardType=='month'" (click)="onSubmit()">Submit</bolt-button>
</bolt-button-bar>