import { TitleCasePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PersonalBillingService, PersonalBillingServiceToken, ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { CustomerBillingAccount, RelatedPolicy } from '@nationwide/dgs-angular-billing-common/api-response-types/personal-billing';
import { forkJoin, Observable, map, shareReplay } from 'rxjs';
import { SessionService } from '../../../../session/session.service';
import { BillingAccountItem } from '../models/billing-account-item.model';

@Injectable({
    providedIn: 'root'
})
export class SaveAsRefundMethodService {
    private accountsResponse: Observable<BillingAccountItem[]>;

    // eslint-disable-next-line max-params
    constructor(
        @Inject(PersonalBillingServiceToken) private personalBillingService: PersonalBillingService,
        private session: SessionService,
        private titleCase: TitleCasePipe,
        readonly contentfulService: ContentfulMapperService
    ) {
        this.clearCache();
    }

    clearCache(): void {
        this.accountsResponse = this.setupRequest();
    }

    retrieveAccounts(): Observable<BillingAccountItem[]> {
        return this.accountsResponse;
    }

    private setupRequest(): Observable<BillingAccountItem[]> {
        return forkJoin({
            accounts: this.personalBillingService.retrieveCustomerBillingAccounts({
                accessToken: this.session.accessToken,
                ecn: this.session.ecn
            }),
            contentful: this.contentfulService.getContentfulRefundMethod()
        }).pipe(
            /* eslint-disable-next-line no-extra-parens */
            map(({ accounts, contentful }) => (
                accounts.map((account) => new BillingAccountItem(
                    account.accountNumber,
                    this.titleCasePolicies(account),
                    this.mapRefundMethod(account),
                    account.refundMethodEligibility === 'Eligible' && !this.isLifeOnly(account),
                    this.mapIneligibleReason(account, contentful)
                ))
            ),
                shareReplay(1)
            ));
    }

    private titleCasePolicies(account: CustomerBillingAccount): RelatedPolicy[] {
        return account.relatedPolicy.map((policy) => ({ ...policy, policyLabel: this.titleCase.transform(policy.policyLabel) }));
    }

    private mapRefundMethod(account: CustomerBillingAccount): string {
        let refundMethod = <string>account.billingMethodType;

        if (account.billingMethodType === 'Direct' && !account.maskedBankAccountNumber || account.billingMethodType === 'Third-Party Billed') {
            refundMethod = 'none';
        } else if (account.billingMethodType === 'Direct') {
            const accountType = this.titleCase.transform(account.accountType || '');
            refundMethod = `${accountType} ****${account.maskedBankAccountNumber.replace(/\D/g, '')}`;
        } else if (account.billingMethodType === 'Recurring Bankcard' || account.billingMethodType === 'Recurring EFT') {
            refundMethod = 'Easy Pay';
        }

        return refundMethod;
    }

    private mapIneligibleReason(account: CustomerBillingAccount, contentful: any): string {
        let ineligibleMessage: string;

        if (account.billingMethodType === 'Direct' && this.isLifeOnly(account)) {
            ineligibleMessage = contentful.ineligibleLifeOnly.Content;
        } else if (account.billingMethodType === 'Recurring Bankcard') {
            ineligibleMessage = contentful.ineligibleRBC.Content;
        } else if (account.billingMethodType === 'Recurring EFT') {
            ineligibleMessage = contentful.ineligibleREFT.Content;
        } else if (account.billingMethodType === 'Third-Party Billed') {
            ineligibleMessage = contentful.ineligibleEscrow.Content;
        }

        return ineligibleMessage;
    }

    private isLifeOnly(account: CustomerBillingAccount): boolean {
        return account.relatedPolicy.every((policy) => policy.policyLabel.toLowerCase().includes('life'));
    }
}
