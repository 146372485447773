import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { PaymentFlowType } from '../shared/payment-flow-type.service';
import { MakePaymentConfig, makePaymentConfigToken } from '../pay-bill-landing/pay-bill.config';
import { ISBRouteParams } from '../../shared/routing/isb-route.model';
import { SessionService } from '../../shared/session/session.service';
import { PageName } from '../../shared/page-name.enum';

@Injectable()
export class BackFromConfirmationGuard implements CanActivate {
    // eslint-disable-next-line max-params
    constructor(
        private paymentFlowType: PaymentFlowType,
        private session: SessionService,
        private router: Router,
        @Inject(makePaymentConfigToken) private makePaymentConfig: MakePaymentConfig
    ) { }

    canActivate(): boolean {
        let canActivate = true;
        if (this.session.currentPage === PageName.PAY_BILL_CONFIRMATION) {
            canActivate = false;
            this.cancelMakePayment();
        }
        return canActivate;
    }

    cancelMakePayment(): void {
        let url: string;
        if (this.paymentFlowType.isQuickpay) {
            this.quickPayBackClick();
        } else if (this.session.isReinstatementEligible || this.session.isCollectionPayment || this.paymentFlowType.isNonOwner) {
            url = this.makePaymentConfig.urls.cancelMakePayment(false, this.paymentFlowType.isQuickpay);
            window.location.href = url;
        } else {
            url = environment.ISB.ENDPOINTS.RELATIVE.billsAndPayments(this.urlParams);
            this.router.navigateByUrl(url);
        }
    }

    quickPayBackClick(): void {
        let quickPaySearchEndPoint = environment.ISB.ENDPOINTS.QUICK_PAY.quickPaySearch(this.quickPayUrlParams);
        if (this.session.isLifeQuickPayFlagFromSaved) {
            quickPaySearchEndPoint = `${quickPaySearchEndPoint}&lob=life`;
        }
        this.router.navigateByUrl(quickPaySearchEndPoint);
    }

    private get quickPayUrlParams(): ISBRouteParams {
        let pageName;
        if (this.session.currentPage === PageName.PAY_BILL_CONFIRMATION) {
            pageName = 'confirmation';
        } else if (this.session.currentPage === PageName.PAY_BILL_LANDING) {
            pageName = 'landing';
        }
        return {
            referringPage: pageName
        };
    }

    private get urlParams(): ISBRouteParams {
        return {
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId,
            policyNumber: this.paymentFlowType.pKey,
            isCyberLife: this.paymentFlowType.isCyberLife
        };
    }

    goToAccountSummary(): void {
        const url = environment.CAM_HOME;
        window.location.href = url;
    }
}
