import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { RESPONSE_CODES } from '../../../../../../shared/constants/common.constants';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { AddPaymentMethodFlowService } from '../services/add-payment-method-flow.service';
import { AddPaymentMethodReviewService, AddPaymentMethodServiceResponses } from './add-payment-method-review.service';
import { AddPaymentReview } from './add-payment-review.model';

@Component({
    selector: 'app-add-payment-method-review',
    templateUrl: './add-payment-method-review.component.html',
    styleUrls: ['./add-payment-method-review.component.scss']
})

export class AddPaymentMethodReviewComponent implements OnInit {
    @ViewChild(FormGroupDirective) addPaymentFormDirective: FormGroupDirective;

    loadComplete = false;
    addPaymentDetailsReviewFields: RecapFormField[] | BankcardRecapFormField[] = [];
    considerThisReviewFields: RecapFormField[] = [];
    isDisableEmailPreference = true;

    // eslint-disable-next-line max-params
    constructor(
        protected session: SessionService,
        private addPaymentFlow: AddPaymentMethodFlowService,
        private router: Router,
        private review: AddPaymentReview,
        private apiClient: AddPaymentMethodReviewService,
        private formUtils: FormUtil,
        @Inject('logger') private logger: LoggerService,
        @Inject('window') private window: Window,
        private eventService: BillingEventService,
        private urlUtil: UrlUtil
    ) { }

    ngOnInit(): void {
        if (this.addPaymentFlow.isPaperlessEligible) {
            this.logger.info('Add Payment Method Review - Page load',
                this.apiClient.logPageLoadForPaperless(this.addPaymentForm.enrollInPaperless)
            );
        }
        if (this.isReviewPageReloaded) {
            this.navigateToPaymentPreferences();
        } else {
            this.review.initialize();
            this.addPaymentDetailsReviewFields = this.review.addPaymentDetailsReviewFields;
            this.considerThisReviewFields = this.review.considerThisReviewFields;
            this.loadComplete = true;
            this.sendOnPageLoadEbiEvent();
        }
    }

    onSubmit(): void {
        this.formUtils.touchAllFormControls(this.addPaymentForm.group);
        this.sendOnSubmitEbiEvent();
        if (this.addPaymentForm.group.valid) {
            this.loadComplete = false;
            this.apiClient.addPaymentMethod().pipe(
                tap((addPaymentMethodServiceResponses) => {
                    this.handleApiResponse(addPaymentMethodServiceResponses);
                }),
                take(1)
            ).subscribe();
        } else {
            this.window.scrollTo(0, 0);
        }
    }

    handleApiResponse(response: AddPaymentMethodServiceResponses): void {
        if (response.savedPaymentMethodResponse instanceof HttpErrorResponse &&
            this.isCreditCardVulError(<HttpErrorResponse>response.savedPaymentMethodResponse)) {
            this.addPaymentFlow.clear();
            this.addPaymentFlow.save({ hasCreditCardVulPolicyError: true });
            this.navigateToAddPaymentMethodLanding();
        } else if (response.savedPaymentMethodResponse instanceof HttpErrorResponse ||
            response.savedPaymentMethodResponse instanceof Error) {
            this.review.save({ addPaymentMethodServiceResponses: response });
            this.logger.error('API ERROR: ADD PAYMENT METHOD SUBMISSION', response.savedPaymentMethodResponse);
            this.navigateToConfirmation();
        } else {
            this.review.save({ addPaymentMethodServiceResponses: response });
            this.navigateToConfirmation();
        }
    }

    navigateToConfirmation(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.ADD.CONFIRMATION(
                this.urlUtil.hashParamsString
            ));
    }

    navigateToAddPaymentMethodLanding(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.ADD.LANDING(
                this.urlUtil.hashParamsString
            ));
    }

    cancelAddPayment(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString)
        );
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.PP_ADD_PAYMENT_METHOD_ON_REVIEW_LOAD, {
            paymentMethod: this.ebiPaymentMethodData
        });
    }

    sendOnSubmitEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.PP_ADD_PAYMENT_REVIEW_ON_CLICK_SUBMIT);
    }

    clearFormInPlay(): void {
        this.addPaymentFlow.clear();
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString));
    }

    private isCreditCardVulError(addPaymentMethodServiceErrorResponse: HttpErrorResponse): boolean {
        return addPaymentMethodServiceErrorResponse?.status === RESPONSE_CODES.ERRORS.SAP.VUL_CREDIT_CARD;
    }

    get isReviewPageReloaded(): boolean {
        return !this.addPaymentFlow.flowInPlay;
    }

    get ebiPaymentMethodData(): PaymentMethodsData[] {
        const data = [];
        const payMethod = this.addPaymentFlow.selectedPaymentMethod;

        const accountType = payMethod.isBankAccount ? this.addPaymentForm.bankAccountType : 'NA';
        const bankName = payMethod.isBankAccount ? this.addPaymentForm.bankName : 'NA';
        const creditCardVendor = payMethod.isBankCard ? this.addPaymentForm.cardType : 'NA';
        const paymentType = payMethod.isBankCard ? 'CC' : 'EFT';
        const firstName = this.addPaymentForm.firstName;
        const lastName = this.addPaymentForm.lastName;
        const defaultMethod = this.addPaymentForm.makeDefaultPaymentMethod ? 'Yes' : 'No';
        const clientType = 'Authenticated';
        const paperless = this.addPaymentForm.enrollInPaperless === 'yes';

        data.push({
            accountType,
            bankName,
            creditCardVendor,
            paymentType,
            firstName,
            lastName,
            defaultMethod,
            clientType,
            paperless
        });

        return data;
    }

    get addPaymentForm(): PaymentFormGroup {
        return this.addPaymentFlow.addPaymentForm;
    }

    get considerThisSectionDisplayable(): boolean {
        return this.addPaymentFlow.considerThisDisplayable;
    }

    get submitted(): boolean {
        return !!this.addPaymentFormDirective;
    }

    get globalErrorDisplayable(): boolean {
        return !this.addPaymentForm.group.valid;
    }

    get paperlessSelection(): boolean {
        return this.review?.enrollingInPaperless !== 'Yes';
    }
}
