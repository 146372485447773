<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<form class="fluid-container" *ngIf="paymentFormReview" [formGroup]="paymentFormReview.group" class="manage-payment-form-container">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="Update automatic payment progress value is 60%" class="progress-bar-container to-container" progressBarValue="60"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Update automatic payment information: review & submit
        </h1>

        <div class="bolt-row">
            <div class="bolt-col-12">
                <section id="payment-details" class="bolt-space-bottom-lg">
                    <app-payment-sub-header>Payment details</app-payment-sub-header>
                    <fieldset class="section-content">
                        <legend class="fields-only"></legend>
                        <ng-container *ngFor='let field of paymentDetailsReviewFields'>
                            <app-recap-form-field *ngIf="!field.cardType" [field]="field" class="bolt-space-bottom-xl">
                            </app-recap-form-field>
                            <app-bankcard-recap-form-field *ngIf="field.cardType" [field]="field" class="bolt-space-bottom-xl">
                            </app-bankcard-recap-form-field>
                        </ng-container>
                    </fieldset>
                </section>

                <section id="ez-pay-authorization-section" class="bolt-space-bottom-2xl" *ngIf="ezPayAuthSectionDisplayable">
                    <app-payment-sub-header>Authorization agreement</app-payment-sub-header>
                    <div class="ez-pay-section-content">
                        <app-ez-pay-authorization formGroupName="ezPayAuthGroup" [authorizationAgreementParams]="authorizationAgreementParams"
                            [isAcknowledgedCheckboxDisable]="acknowledgedCheckboxDisabled" [distributedDocumentsResponseModel]="distributedDocumentsResponseModel"
                            (onClickAuthAgreementLink)="sendOnClickOfAuthAgreementEbiEvent()">
                        </app-ez-pay-authorization>
                    </div>
                </section>

                <section class="bolt-space-bottom-lg" *ngIf="considerThisSectionDisplayable">
                    <fieldset class="section-content">
                        <legend class="fields-only"></legend>
                        <app-recap-form-field *ngFor="let field of considerThisReviewFields" [field]="field" class="bolt-space-bottom-xl">
                        </app-recap-form-field>
                    </fieldset>
                </section>

                <ng-container *ngIf="emailAddress">
                    <section class="bolt-space-bottom-lg">
                        <app-payment-sub-header>
                            Contact information
                        </app-payment-sub-header>
                        <app-email-form-control formGroupName="emailAddressGroup">
                        </app-email-form-control>
                    </section>
                </ng-container>


            </div>
        </div>
    </div>
    <bolt-button-bar>
        <bolt-button id="cancel-button" (click)="navigateToPaymentPreferences()" slot="cancel">Cancel</bolt-button>
        <bolt-button id="back-button" (click)="navigateToEditAutomaticPayments()" slot="back">Back</bolt-button>
        <bolt-button id="submit-button" slot="forward" (click)="onSubmitEditAutomaticPayment()" type="primary">Submit</bolt-button>
    </bolt-button-bar>
</form>