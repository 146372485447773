<is-wait-message *ngIf="!loadComplete" [useBolt]="false" [loadComplete]="loadComplete"></is-wait-message>

<form class="fluid-container" *ngIf="paymentFormReview && loadComplete" #paymentForm="ngForm" (ngSubmit)="onSubmit()" [formGroup]="paymentFormReview.group" class="manage-payment-form-container">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="setup automatic payments progress value is 75%" class="progress-bar-container to-container" progressBarValue="75"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Set up automatic
            payments: review & submit</h1>

        <div class="bolt-row">
            <div class="bolt-col-12">
                <section id="payment-details">
                    <app-payment-sub-header>Payment details</app-payment-sub-header>
                    <fieldset class="section-content">
                        <legend class="fields-only"></legend>
                        <ng-container *ngFor='let field of paymentDetailsReviewFields'>
                            <app-recap-form-field *ngIf="!field.cardType" [field]="field" class="bolt-space-bottom-xl">
                            </app-recap-form-field>
                            <app-bankcard-recap-form-field *ngIf="field.cardType" [field]="field" class="bolt-space-bottom-xl">
                            </app-bankcard-recap-form-field>
                        </ng-container>
                    </fieldset>
                </section>

                <section id="ez-pay-authorization-section" class="bolt-space-bottom-2xl" *ngIf="ezPayAuthSectionDisplayable">
                    <app-payment-sub-header>Authorization agreement</app-payment-sub-header>
                    <div class="ez-pay-section-content">
                        <app-ez-pay-authorization formGroupName="ezPayAuthGroup" [authorizationAgreementParams]="authorizationAgreementParams"
                            [isAcknowledgedCheckboxDisable]="acknowledgedCheckboxDisabled" [distributedDocumentsResponseModel]="distributedDocumentsResponseModel"
                            (onClickAuthAgreementLink)="sendOnClickOfAuthAgreementEbiEvent()">
                        </app-ez-pay-authorization>
                    </div>
                </section>

                <section [ngClass]="{'bolt-space-bottom-lg':
					this.review.enrollingInPaperless === 'Yes'}" *ngIf="considerThisSectionDisplayable">
                    <fieldset class="section-content">
                        <legend class="fields-only"></legend>
                        <app-recap-form-field id="consider-this-fieldset" class="bolt-space-bottom-xl" *ngFor="let field of considerThisReviewFields" [field]="field">
                        </app-recap-form-field>
                    </fieldset>
                </section>

                <ng-container *ngIf="emailAddress">
                    <section>
                        <app-payment-sub-header>
                            Contact information
                        </app-payment-sub-header>
                        <app-email-form-control formGroupName="emailAddressGroup">
                        </app-email-form-control>
                    </section>
                </ng-container>

            </div>
        </div>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" (click)="navigateToPaymentPreferences()">Cancel</bolt-button>
        <bolt-button slot="back" id="back-button" (click)="navigateToSetupAutomaticPayments()">Back</bolt-button>
        <bolt-button slot="forward" id="submit-button" type="primary" (click)="paymentForm.onSubmit($event)">Submit</bolt-button>
    </bolt-button-bar>
</form>