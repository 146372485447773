<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<form *ngIf="editPaymentMethodForm && loadComplete" class="manage-payment-form-container edit-payment-method-review-form" [formGroup]="editPaymentMethodForm.group">

    <div class="bolt-container" [ngClass]="{'bolt-space-bottom-lg': !considerThisSectionDisplayable}">
        <bw-bolt-progress-bar appFocusOnInit arialabel="Edit payment method progress value is 67%" progressBarValue="67" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Edit payment method:
            review & submit</h1>

        <bw-bolt-notification type="error" [id]="'global-error-message'" *ngIf="globalErrorDisplayable">
            Please check the information below and try again.
        </bw-bolt-notification>

        <section id="payment-details">
            <app-payment-sub-header>Payment details</app-payment-sub-header>
            <fieldset class="section-content" [ngClass]="{'bolt-space-bottom-lg': !considerThisSectionDisplayable}">
                <legend class="fields-only"></legend>
                <ng-container *ngFor='let field of editPaymentDetailsReviewFields'>
                    <app-recap-form-field class="bolt-space-bottom-xl" *ngIf="!field.cardType" [field]="field"></app-recap-form-field>
                    <app-bankcard-recap-form-field class="bolt-space-bottom-xl" *ngIf="field.cardType" [field]="field">
                        CARD TYPE
                    </app-bankcard-recap-form-field>
                </ng-container>
            </fieldset>
        </section>
        <section *ngIf="considerThisSectionDisplayable">
            <fieldset class="section-content bolt-space-bottom-lg">
                <legend class="fields-only"></legend>
                <app-recap-form-field class="bolt-space-bottom-xl" *ngFor="let field of considerThisReviewFields" [field]="field">
                </app-recap-form-field>
            </fieldset>
        </section>
        <ng-container *ngIf="paperlessSelection">
            <section>
                <app-payment-sub-header>Contact information</app-payment-sub-header>
                <app-email-form-control formGroupName="emailAddressGroup">
                </app-email-form-control>
            </section>
        </ng-container>
    </div>

    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" (click)="cancelEditPayment()">Cancel</bolt-button>
        <bolt-button slot="back" id="back-button" (click)="navigateToEditPaymentMethodLanding()">Back</bolt-button>
        <bolt-button id="submit-button" slot="forward" (click)="onSubmit()" type="primary">Submit</bolt-button>
    </bolt-button-bar>
</form>