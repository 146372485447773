import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-button-container-v2',
    templateUrl: './button-container-v2.component.html',
    styleUrls: ['./button-container-v2.component.scss']

})
export class ButtonContainerV2Component {
    @Input() id;
    @Input() upNext;
}
