import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { CardType } from '../../../shared/payments/card-utils/card-type.enum';
import { cardUtils } from '../../../shared/payments/card-utils/card-utils';

function matchesPatternValidator(params: { pattern: RegExp; errorName: string }): any {
    return (control: AbstractControl): Record<string, any> | null => {
        const passesPattern = params.pattern.test(control.value);
        const validationObject = {};
        validationObject[params.errorName] = { value: control.value };
        return passesPattern ? null : validationObject;
    };
}

export const PayBillValidators = {

    hasNameCharacters: matchesPatternValidator({
        pattern: /^[A-Za-z\-'\s,.]+$/,
        errorName: 'containsNameCharacters'
    }),

    onlyStreetCharacters: matchesPatternValidator({
        pattern: /^[A-Za-z`\s.\d]+$/,
        errorName: 'onlyStreetCharacters'
    }),

    digitsOnly: matchesPatternValidator({
        pattern: /^[\d]+$/,
        errorName: 'containsOnlyDigits'
    }),

    onlyDigitsAndNameCharacters: matchesPatternValidator({
        pattern: /^[A-Za-z\-'\s,.\d]*$/,
        errorName: 'containsDigitsAndNameCharacters'
    }),

    includeDigitsNameAndApostrophe: matchesPatternValidator({
        pattern: /^[A-Za-z\-'\s,.\d*]*$/,
        errorName: 'includeDigitsNameAndApostrophe'
    }),

    isValidEmail: matchesPatternValidator({

        pattern: /^.*$/,
        errorName: 'emailFormat'
    }),

    isValidPhoneNumber: matchesPatternValidator({
        pattern: /^[(][0-9]{3}[)][ ][0-9]{3}[-][0-9]{4}$/,
        errorName: 'phoneNumberFormat'
    }),

    isBetweenMinAndMax: (fullAmount: number, minimumAmount: number) =>
        (control: AbstractControl): Record<string, any> | null => {
            const partialPayAmount = Number(control.value);
            const passes = partialPayAmount <= fullAmount && partialPayAmount >= minimumAmount;
            return passes ? null : { isBetweenMinAndMax: true };
        },
    containsNonSpaceCharacters: (control: AbstractControl): Record<string, any> | null => {
        const passes = control.value.length === 0 || control.value.trim().length > 0;
        return passes ? null : { containsNonSpaceCharacters: { value: control.value } };
    },

    startsWithAlphabetCharacter: (control: AbstractControl): Record<string, any> | null => {
        const passesPattern = !control.value || control.value.length === 0 || /^[A-Za-z]$/.test(control.value[0]);
        return passesPattern ? null : { startsWithAlphabetCharacter: { value: control.value } };
    },

    isNumberWithTwoDecimalPlaces: (control: AbstractControl): Record<string, any> | null => {
        const passesPattern = /^\d+\.?(?:\d{1,2})?$/.test(control.value);
        return passesPattern ? null : { isNumberWithTwoDecimalPlaces: { value: control.value } };
    },

    startsWithNameCharacter: (control: AbstractControl): Record<string, any> | null => {
        const passesPattern = control.value.length === 0 || /^['A-Za-z]$/.test(control.value[0]);
        return passesPattern ? null : { validNameStarter: { value: control.value } };
    },

    valuesMatch: (controlName: string, confirmControlName: string) =>
        (group: UntypedFormGroup): void => {
            const control = group.get(controlName);
            const confirmationControl = group.get(confirmControlName);

            if (control.value !== confirmationControl.value) {
                confirmationControl.setErrors({
                    ...confirmationControl.errors,
                    confirmationValueMatches: true
                });
            } else if (control.value === confirmationControl.value &&
                confirmationControl.errors &&
                confirmationControl.errors.confirmationValueMatches) {
                delete confirmationControl.errors.confirmationValueMatches;

                confirmationControl.setErrors(Object.keys(confirmationControl.errors).length ? confirmationControl.errors : null);
            }
        },

    hasIdentifiableCardType: (control: AbstractControl): Record<string, any> | null => {
        const parsedType = cardUtils.parseType(control.value);
        const identified = parsedType !== CardType.INVALID;
        return identified ? null : { cardTypeIdentifiable: { value: control.value } };
    },

    formArrayHasValue: (control: AbstractControl): Record<string, any> | null => {
        const hasValue = control.value && control.value.length > 0;
        return hasValue ? null : { formArrayHasValue: true };
    },

    saveAsRefundMethodFormGroupValidation: (group: UntypedFormGroup): ValidationErrors => {
        const selection = group.controls.selection.value;
        const accountsArray = group.controls.selectedAgreements.value;
        const isGroupInvalid = selection === 'yes' && (!accountsArray || accountsArray.length === 0);
        return isGroupInvalid ? { formArrayHasValue: true } : null;
    }
};
