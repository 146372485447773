import { Component, OnInit } from '@angular/core';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { SessionService } from '../../../session/session.service';
import { EFTAuthorizationAgreementParams, CCAuthorizationAgreementParams } from '../easy-pay-authorization-agreement.service';

@Component({
    selector: 'app-ez-pay-auth-agreement',
    templateUrl: './ez-pay-auth-agreement.component.html',
    styleUrls: ['./ez-pay-auth-agreement.component.scss']
})

export class EasyPayAuthorizationAgreementComponent implements OnInit {
    authValue: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
    showEFT: boolean;
    showCC: boolean;
    autoPayBankAuthAgreement: string;
    autoPayCreditCardAgreement: string;

    constructor(
        private session: SessionService,
        private contentfulService: ContentfulMapperService
    ) { }

    ngOnInit(): void {
        this.authValue = this.session.authAggrementResponse;
        if (this.authValue.accountType === 'EFT') {
            this.fetchAutoPayBankAuthAgreement();
            this.showEFT = true;
        }
        if (this.authValue.accountType === 'CC') {
            this.fetchAutoPayCreditCardAuthAgreement();
            this.showCC = true;
        }
    }

    fetchAutoPayCreditCardAuthAgreement(): void {
        const contentfulResponse = this.contentfulService.getContentfulBillingContent();
        contentfulResponse.subscribe((res) => {
            this.autoPayCreditCardAgreement = res.autoPayCreditCardAuthAgreement.content;
        });
    }

    fetchAutoPayBankAuthAgreement(): void {
        const contentfulResponse = this.contentfulService.getContentfulBillingContent();
        contentfulResponse.subscribe((res) => {
            this.autoPayBankAuthAgreement = res.autoPayBankAuthAgreement.content;
        });
    }
}
