/* eslint-disable no-extra-parens */
/* eslint-disable no-negated-condition */
/* eslint-disable no-unreachable-loop */
/* eslint-disable array-callback-return */
import { PBS as Unmapped, PBS as UnmappedRCA, RetrivePaymentHistoryResponse, ECIM } from '@nationwide/dgs-angular-billing-common';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { PaymentHistoryMethod } from '../billing/bills-and-payments/payment-history/payment-history-item-method.enum';
import { PaymentHistoryStatus } from '../billing/bills-and-payments/payment-history/payment-history-item-status.enum';
import { PaymentHistoryItem } from '../billing/bills-and-payments/payment-history/payment-history-item.model';
import { PayBill } from '../pay-bill/pay-bill-landing/pay-bill-landing-adpater.model';
import {
    MILLISECONDS_IN_SECOND,
    SECONDS_IN_MINUTE,
    MINUTES_IN_HOUR,
    HOURS_IN_DAY,
    LIFE_LINE_OF_BUSINESS_LIST
} from './constants/common.constants';
import { Parameter, Permission, QuickPayAgreement, RetriveCustomerAgreementResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/portfolio-business-service';
import { RemoveDuplicatesPipe } from '@nationwide/internet-servicing-angular-pipes';
import { LINE_OF_BUSINESS_LIST } from '../../app/shared/constants/common.constants';
import { ProductType } from '../shared/service/ctm-service/ctm-model';
import { SessionService } from './session/session.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AccountPolicyHolderAddressElements } from './payments/ez-pay-authorization/eft-authorization-form/form-elements/account-policy-holder-address-elements';
import { IsbSharedCommonHelperService } from './isb-shared-common-helper.service';

dayjs.extend(utc);

export abstract class SharedRCAAdapter {
    inlineMessageContent: string[];
    warnedRegex = new RegExp('^(Warned)');

    tridionMessageBlackIdList = [
        'inline.downpayment',
        'inline.notacceptedpolicyadmsys',
        'billing.inline.PleaseContactAgent'
    ];

    constructor(
        protected removeDuplicatesPipe: RemoveDuplicatesPipe,
        protected session: SessionService,
        protected sharedService: IsbSharedCommonHelperService) { }

    amountDueDisplayStatus(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const billingAgreement = this.getBillingAgreement(rcaResponse);

        return this.canPay(billingAgreement.roleInAgreement.permission) &&
            billingAgreement.billingAccountDetail.warningIndicator === 'false' &&
            this.isValidPaymentStatus(billingAgreement) &&
            this.isValidInstallmentAmount(billingAgreement);
    }

    getPolicyHasScheduledPaymentIndicator(response: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const hasScheduledPaymentIndicatorArray: string[] = [];
        for (const agreement of this.getAgreementsFromRCA(response)) {
            if (agreement.policyDetail &&
                agreement.policyDetail.billingDetail) {
                hasScheduledPaymentIndicatorArray.push(agreement.policyDetail.billingDetail.hasScheduledPaymentIndicator);
            }
        }
        return hasScheduledPaymentIndicatorArray.includes('true');
    }

    getAgreementsFromRCA(response: Unmapped.RetriveCustomerAgreementResponse): Unmapped.Agreement[] {
        return response?.retrieveCustomerAgreementResponse?.return?.profile?.agreementGroup?.agreement || [];
    }

    getBillingAgreementFromRCA(agreements: Unmapped.Agreement[]): Unmapped.Agreement {
        return agreements
            ?.find((agreement) => agreement.agreementType === 'BILLING' && agreement.agreementNumber === this.session.billingAccountNumber);
    }

    getBillingAccountMessagesFromRCA(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): Unmapped.Message[] {
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        return this.getMessagesFromBillingDetail(billingAgreement?.billingAccountDetail?.billingDetail);
    }

    getMessagesFromBillingDetail(billingDetail: Unmapped.BillingDetail): Unmapped.Message[] {
        const messageOrMessages = billingDetail?.message;
        let messages: Unmapped.Message[];
        if (messageOrMessages instanceof Array) {
            messages = messageOrMessages;
        } else {
            messages = [messageOrMessages];
        }
        return messages;
    }

    getMessagesFromQuickPayBillingDetail(quickPayBillingDetail: Unmapped.QuickPayBillingDetail): Unmapped.Message[] {
        const messageOrMessages = quickPayBillingDetail?.messages;
        let messages: Unmapped.Message[];
        if (messageOrMessages instanceof Array) {
            messages = messageOrMessages;
        } else {
            messages = [messageOrMessages];
        }

        return messages;
    }

    getParametersFromRCAMessage(message: Unmapped.Message): Unmapped.Parameter[] {
        let parameters: Unmapped.Parameter[];

        if (message.parameter instanceof Array) {
            parameters = message.parameter;
        } else {
            parameters = [message.parameter];
        }
        return parameters;
    }

    isRenewalDateDisplayforPolicy(agreemnent: Unmapped.Agreement, billingAgreement: Unmapped.Agreement): boolean {
        return this.isShowRenewalDate(agreemnent, billingAgreement) &&
            (this.isNbpBilled(billingAgreement) || this.isEmptyBilledSystem(billingAgreement));
    }

    getPolicyBillAmountDue(agreement: Unmapped.Agreement): string {
        return agreement.policyDetail.billingDetail.amountDue;
    }

    isNbpBilled(billingAgreement: Unmapped.Agreement): boolean {
        return billingAgreement.system === 'NBP';
    }

    isEmptyBilledSystem(billingAgreement: Unmapped.Agreement): boolean {
        return billingAgreement.system === undefined;
    }

    getBillingAgreement(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): Unmapped.Agreement {
        if (rcaResponse && this.getAgreementsFromRCA(rcaResponse) instanceof Array) {
            return this
                .getAgreementsFromRCA(rcaResponse)
                .find((agreement) => agreement.agreementType === 'BILLING' && agreement.agreementNumber === this.session.billingAccountNumber);
        }
    }

    getPolicies(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): any {
        const agreements = this.getAgreementsFromRCA(rcaResponse);
        let policies = [];
        if (agreements instanceof Array) {
            if (this.session.billingAccountNumber) {
                policies = this.getAgreementsFromRCA(rcaResponse).filter((agreement) => {
                    agreement = this.sharedService.transformRefIdToArray(agreement);
                    return agreement.agreementType === 'POLICY' && agreement.relatedAgreement?.refId?.includes(this.session.billingAccountNumber);
                });
            } else {
                // For when new CAM sends us collection payments with no billing account number
                policies = agreements.filter((agreement) => agreement.agreementType === 'POLICY');
            }
        }
        return policies;
    }

    isNoBillingAgreement(agreements: Unmapped.QuickPayAgreement[]): boolean {
        return agreements.filter((agreement) => agreement.agreementType === 'BILLING')?.length === 0;
    }

    isBillingAgreement(agreement: Unmapped.QuickPayAgreement): boolean {
        return agreement.agreementType?.toUpperCase() === 'BILLING';
    }

    isPolicyAgreement(agreement: Unmapped.QuickPayAgreement): boolean {
        return agreement.agreementType?.toUpperCase() === 'POLICY';
    }

    isShowRenewalDate(agreement: Unmapped.Agreement, billingAgreement: Unmapped.Agreement): boolean {
        return agreement.lineOfBusiness !== 'Individual Life' &&
            agreement.lineOfBusiness !== 'Personal Umbrella' &&
            agreement.lineOfBusiness !== 'Home' &&
            agreement.lineOfBusiness !== 'N3' &&
            agreement.lineOfBusiness !== 'IL' &&
            agreement.lineOfBusiness !== 'SI' &&
            agreement.agreementLabel !== 'Home' &&
            this.getMessageId(agreement) !== 'billing.inline.Reinstate' &&
            this.getMessageId(agreement) !== '' &&
            agreement.agreementLabel !== 'Life' &&
            !this.warnedRegex.test(this.getPolicyStatus(agreement)) &&
            billingAgreement.billingAccountDetail.warningIndicator === 'false';
    }

    isShowRelatedAgreement(agreement: Unmapped.Agreement, billingAgreement: Unmapped.Agreement): boolean {
        return this.isRelatedToAccount(billingAgreement, agreement.refId) && this.canDisplay(agreement.roleInAgreement.permission);
    }

    isRelatedToAccount(billingAgreement: any, agreementNumber: string): boolean {
        let isRelatedToAccount: boolean;
        if (billingAgreement?.relatedAgreement instanceof Array) {
            isRelatedToAccount = billingAgreement.relatedAgreement.includes(agreementNumber);
        } else if (billingAgreement?.relatedAgreement?.refId instanceof Array) {
            isRelatedToAccount = billingAgreement.relatedAgreement.refId?.includes(agreementNumber);
        } else {
            isRelatedToAccount = billingAgreement?.relatedAgreement?.refId === agreementNumber;
        }
        return isRelatedToAccount;
    }

    getMessageId(agreement: any): string {
        let messageId = '';
        if (agreement.policyDetail.billingDetail.message && agreement.policyDetail.billingDetail.message instanceof Array) {
            for (const parameter of agreement.policyDetail.billingDetail.message) {
                if (parameter.type === 'INLINE') {
                    messageId = parameter.name;
                }
            }
        } else if (agreement.policyDetail.billingDetail.message !== undefined) {
            messageId = agreement.policyDetail.billingDetail.message.name;
        }
        return messageId;
    }

    getLineOfBusiness(agreement: any): string {
        return agreement.lineOfBusiness;
    }

    getPolicyStatus(agreement: Unmapped.Agreement): string {
        return agreement.status;
    }

    extractDate(dateField: any): Date {
        let extractedDate: Date;
        if (new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$').test(dateField)) {
            const rawDate = dateField.split('-'); // MM/DD/YYYY
            extractedDate = new Date(rawDate[0], rawDate[1] - 1, rawDate[2].slice(0, 2));
        }
        return extractedDate;
    }

    canDisplay(permissions: any): boolean {
        let canDisplay = false;
        if (permissions instanceof Array && permissions.length) {
            const permissionsResults = permissions.filter((permission) => permission.name === 'canDisplay' && permission.value === 'true');
            canDisplay = permissionsResults.length === 1;
        }
        return canDisplay;
    }

    canQuickPay(permissions: any): boolean {
        let canQuickPayFlag = false;
        if (permissions instanceof Array && permissions.length > 0) {
            const permissionsResults = permissions.filter((permission) => permission.name === 'canQuickPay' && permission.value === 'true');
            canQuickPayFlag = permissionsResults.length === 1;
        }
        return canQuickPayFlag;
    }

    canPayCyberLifePolicy(permissions: any): boolean {
        let canPayFlag = false;
        if (permissions instanceof Array && permissions.length > 0) {
            const permissionsResults = permissions.filter((permission) => permission.name === 'canPay' &&
                permission.value === 'true');
            canPayFlag = permissionsResults.length === 1;
        }
        return canPayFlag;
    }

    canSuspend(permissions: Permission[]): boolean {
        let canSuspend = false;
        if (permissions instanceof Array && permissions.length) {
            const displayedProfiles = permissions.filter((permission) => permission.name === 'canSuspend' && permission.value === 'true');
            canSuspend = displayedProfiles.length === 1;
        }
        return canSuspend;
    }

    extractCanPayNow(policyAgreements: any[], displayAgreementNumber: any): boolean {
        let canPayNow = false;
        for (const policyAgreement of policyAgreements) {
            if (policyAgreement.displayAgreementNumber === displayAgreementNumber && this.canPay(policyAgreement.roleInAgreement?.permission)) {
                canPayNow = true;
            }
        }
        return canPayNow;
    }
    paymentStatusIsPaid(parameters: Unmapped.Parameter[] | Unmapped.Parameter): boolean {
        let paymentStatusIsPaid = false;
        if (parameters instanceof Array) {
            paymentStatusIsPaid = !parameters.some((parameter) => parameter.name === 'paymentStatus' && parameter.value === 'Paid');
        } else if (parameters === undefined) {
            paymentStatusIsPaid = true;
        } else if (parameters instanceof Object) {
            paymentStatusIsPaid = parameters.name === 'paymentStatus' && parameters.value !== 'Paid';
        }
        return paymentStatusIsPaid;
    }

    isInstallmentAmountAvailable(parameters: Unmapped.Parameter | Unmapped.Parameter[]): boolean {
        let installmentAmountAvailable = false;
        if (parameters instanceof Array && parameters.length) {
            const installmentAmountParameters = parameters.filter((parameter) =>
                parameter.name === 'installmentAmount' && parameter.value != null && parseInt(parameter.value, 10) > 0
            );
            installmentAmountAvailable = installmentAmountParameters.length === 1;
        }
        return installmentAmountAvailable;
    }

    getLastPaymentAmountAccountLevel(parameters: Unmapped.Parameter | Unmapped.Parameter[]): string {
        if (parameters instanceof Array && parameters.length) {
            for (const parameter of parameters) {
                if (parameter.name === 'lastPaymentAmount') {
                    return parameter.value;
                }
            }
        }
    }

    getLastPaymentsSumAccountLevel(parameters: Unmapped.Parameter | Unmapped.Parameter[]): string {
        if (parameters instanceof Array && parameters.length) {
            for (const parameter of parameters) {
                if (parameter.name === 'lastPaymentsSum') {
                    return parameter.value;
                }
            }
        }
    }

    getLastPaymentDateAccountLevel(parameters: Unmapped.Parameter | Unmapped.Parameter[]): Date {
        if (parameters instanceof Array && parameters.length) {
            for (const parameter of parameters) {
                if (parameter.name === 'lastPaymentDate') {
                    return this.extractDate(parameter.value);
                }
            }
        }
    }

    getPaymentDate(billingAgreement: Unmapped.Agreement): Date {
        return this.extractDate(billingAgreement?.billingAccountDetail?.billingDetail?.payment?.paymentDate);
    }

    getPaymentAmount(billingAgreement: Unmapped.Agreement): string {
        let paymentAmount: string;
        if (billingAgreement?.billingAccountDetail?.billingDetail?.payment) {
            paymentAmount = billingAgreement?.billingAccountDetail?.billingDetail?.payment?.amount;
        } else if (billingAgreement?.billingAccountDetail?.billingDetail?.amountDue ===
            billingAgreement?.billingAccountDetail?.billingDetail?.fullPayAmount) {
            paymentAmount = billingAgreement?.billingAccountDetail?.billingDetail?.amountDue;
        }
        return paymentAmount;
    }

    getPolicyPaymentAmount(policyAgreement: Unmapped.Agreement): string {
        let paymentAmount: string;
        if (policyAgreement?.policyDetail?.billingDetail?.amountDue ===
            policyAgreement?.policyDetail?.billingDetail?.fullPayAmount) {
            paymentAmount = policyAgreement.policyDetail.billingDetail.amountDue;
        }
        return paymentAmount;
    }

    extractCanSuspend(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        const billingAccount = agreementList.find((agreement) => agreement.agreementNumber === this.session.billingAccountNumber);
        return this.canSuspend(billingAccount.roleInAgreement.permission);
    }

    isValidPaymentStatus(billingAgreement: Unmapped.Agreement): boolean {
        let isValidPaymentStatus: boolean;
        if (billingAgreement?.billingAccountDetail?.billingDetail?.message instanceof Array) {
            isValidPaymentStatus = this.paymentStatusIsPaid(billingAgreement.billingAccountDetail.billingDetail.message[0].parameter);
        } else {
            isValidPaymentStatus = this.paymentStatusIsPaid(billingAgreement?.billingAccountDetail?.billingDetail?.message?.parameter);
        }
        return isValidPaymentStatus;
    }

    isValidInstallmentAmount(billingAgreement: Unmapped.Agreement): boolean {
        let isValidInstallmentAmount: boolean;
        if (billingAgreement?.billingAccountDetail?.billingDetail?.message instanceof Array) {
            isValidInstallmentAmount = this.isInstallmentAmountAvailable(billingAgreement.billingAccountDetail.billingDetail.message[0].parameter);
        } else {
            isValidInstallmentAmount = this.isInstallmentAmountAvailable(billingAgreement?.billingAccountDetail?.billingDetail?.message?.parameter);
        }
        return isValidInstallmentAmount;
    }

    hasLifeOnlyBusiness(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        return this.hasOneAgreementLabelLife(this.getAgreementLabelsForAccount(rcaResponse)) &&
            this.isLifePolicyDisplayable(rcaResponse) &&
            !this.isNonLifePolicyDisplayable(rcaResponse);
    }

    isLifePolicyDisplayable(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) => agreement.agreementType !== 'BILLING' &&
            agreement.agreementLabel === 'Life' &&
            this.canDisplay(agreement.roleInAgreement?.permission)
        );
    }

    isNonLifePolicyDisplayable(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            agreement.agreementLabel !== 'Life' &&
            this.canDisplay(agreement.roleInAgreement?.permission)
        );
    }

    containsOipaPolicy(paramValue: Unmapped.RetriveCustomerAgreementResponse | string): boolean {
        let isContainsOipaPolicy = false;
        if (typeof paramValue !== 'string') {
            const agreementList = this.getAgreementsFromRCA(paramValue);
            isContainsOipaPolicy = agreementList.some((agreement) => {
                agreement = this.sharedService.transformRefIdToArray(agreement);
                return agreement.agreementType !== 'BILLING' &&
                    agreement.relatedAgreement?.refId?.includes(this.session.billingAccountNumber) &&
                    agreement.lineOfBusiness && LINE_OF_BUSINESS_LIST.OIPA.includes(agreement.lineOfBusiness?.toUpperCase());
            });
        } else {
            isContainsOipaPolicy = paramValue && LINE_OF_BUSINESS_LIST.OIPA.includes(paramValue.toUpperCase());
        }
        return isContainsOipaPolicy;
    }

    getAgreementLabelsForAccount(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string[] {
        const agreementLabelValues: string[] = [];
        for (const agreement of this.getAgreementsFromRCA(rcaResponse)) {
            if (agreement.agreementType !== 'BILLING' && this.canDisplay(agreement.roleInAgreement?.permission)) {
                agreementLabelValues.push(agreement.agreementLabel);
            }
        }
        return agreementLabelValues;
    }

    hasOneAgreementLabelLife(agreementLabelValues: string[]): boolean {
        const filteredLabels = this.removeDuplicatesPipe.transform(agreementLabelValues);
        return filteredLabels.length === 1 && filteredLabels[0] === 'Life';
    }

    displayButtonAgreement(policyAgreements: any, displayAgreementNumber: any): boolean {
        let displayButtonAgreement = false;
        for (const policyAgreement of policyAgreements) {
            if (policyAgreement.displayAgreementNumber === displayAgreementNumber && this.canPay(policyAgreement.roleInAgreement?.permission)) {
                displayButtonAgreement = true;
            }
        }
        return displayButtonAgreement;
    }

    getReinstatement(agreement: Unmapped.Agreement): string {
        return agreement?.policyDetail?.reinstatementAmount;
    }

    getAmountDue(agreement: Unmapped.Agreement): string {
        return agreement?.policyDetail?.billingDetail?.amountDue;
    }

    getLastPaymentsSum(agreement: Unmapped.Agreement): string {
        return agreement?.policyDetail?.billingDetail?.pendingPaymentTotal;
    }

    getParameters(billingAgreement: Unmapped.Agreement): Parameter | Parameter[] {
        let parameter: Parameter | Parameter[];
        if (billingAgreement?.billingAccountDetail?.billingDetail?.message instanceof Array) {
            parameter = billingAgreement.billingAccountDetail.billingDetail.message[0].parameter;
        } else {
            parameter = billingAgreement?.billingAccountDetail?.billingDetail?.message?.parameter;
        }
        return parameter;
    }

    canPayAccountlevel(billingAgreement: Unmapped.Agreement): boolean {
        return this.canPay(billingAgreement?.roleInAgreement?.permission);
    }

    getBillingAccountAgreementStatus(billingAgreement: Unmapped.Agreement): string {
        return billingAgreement?.billingAccountDetail?.billingDetail?.status;
    }

    isWarnedOrReinstatePolicy(agreement: Unmapped.Agreement, pKey: string): boolean {
        return agreement?.agreementType !== 'BILLING' &&
            agreement?.refId === pKey &&
            (agreement?.status?.includes('Warned') || agreement?.policyDetail?.reinstatementFlag === 'E');
    }

    reinstatePolicyNumber(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pKey: string): string {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        let reinstatedPolicyNumber: string;
        for (const agreement of agreementList) {
            if (this.isWarnedOrReinstatePolicy(agreement, pKey)) {
                if (this.formatAgreementNumber(agreement?.agreementNumber) === this.formatAgreementNumber(agreement?.displayAgreementNumber)) {
                    reinstatedPolicyNumber = this.formatPolicyNumber(agreement?.agreementNumber);
                } else {
                    reinstatedPolicyNumber = agreement?.displayAgreementNumber;
                }
            }
        }
        return reinstatedPolicyNumber;
    }

    formatPolicyNumber(policyNumber: string): string {
        return policyNumber?.replace(/[*]/g, '').trim();
    }

    formatAgreementNumber(agreement: string): string {
        return agreement?.replace(/[*\s]/g, '');
    }

    isBillingAccountPaid(billingAgreement: Unmapped.Agreement): boolean {
        return billingAgreement?.billingAccountDetail?.billingDetail?.status === PayBill.PAID;
    }

    getCareMattersLob(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            agreement.refId === pkey &&
            agreement.status !== 'Warned' &&
            agreement.lineOfBusiness === 'C2'
        );
    }

    isRecurringPaymentSuspended(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const billingAccount = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const autoPayIsSuspended = this.extracthasAutomaticPaymentsSuspended(billingAccount);
        const warnedPolicy = billingAccount?.billingAccountDetail?.warningIndicator;
        let installmentDate = null;
        if (billingAccount?.billingAccountDetail?.billingDetail?.statement?.dueDate) {
            installmentDate = new Date(billingAccount.billingAccountDetail.billingDetail.statement.dueDate);
        }
        return autoPayIsSuspended && warnedPolicy === 'false' && installmentDate;
    }

    isAutomaticPaymentSuspended(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const billingAccount = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const autoPayIsSuspended = this.extracthasAutomaticPaymentsSuspended(billingAccount);
        const warnedPolicy = billingAccount?.billingAccountDetail?.warningIndicator;
        let installmentDate = null;
        if (billingAccount?.billingAccountDetail?.billingDetail?.statement?.dueDate) {
            installmentDate = new Date(billingAccount.billingAccountDetail.billingDetail.statement.dueDate);
        }
        return autoPayIsSuspended && !this.hasReceivedPayment(billingAccount) && warnedPolicy === 'false' && installmentDate instanceof Date;
    }

    getMaskedBankAccountNumber(bankNumber: string): string {
        const MASKED_NUMBERS_LENGTH = 3;
        return `${bankNumber.slice(bankNumber.length - MASKED_NUMBERS_LENGTH)}`;
    }

    billingNextDueDate(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): Date {
        const billingAccount = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const installmentDate = this.billingAccountStatementDate(billingAccount);
        const dueDate = this.billingAccountNextDueDate(billingAccount);

        return dueDate && !installmentDate ? dueDate : installmentDate;
    }

    billingStatementDueDate(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): Date {
        const billingAccount = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const installmentDate = this.billingAccountStatementDate(billingAccount);
        return installmentDate || undefined;
    }

    getEFTBilledStatus(billingAgreement: Unmapped.Agreement): boolean {
        return billingAgreement.billingAccountDetail.billingDetail.status === 'EFT Billed';
    }

    getRBCBilledStatus(billingAgreement: Unmapped.Agreement): boolean {
        return billingAgreement.billingAccountDetail.billingDetail.status === 'RBC Billed';
    }

    getBilledStatus(billingAgreement: Unmapped.Agreement): boolean {
        return billingAgreement.billingAccountDetail.billingDetail.status === 'Billed';
    }

    getPastDueStatus(billingAgreement: Unmapped.Agreement): boolean {
        return this.getPastDueAccountLevel(this.getParameters(billingAgreement));
    }
    getRecievedStatus(billingAgreement: Unmapped.Agreement): boolean {
        return this.getRecievedAccountLevel(this.getParameters(billingAgreement));
    }

    /**
     * @deprecated No Longer in use. RCAResponse no longer hold these fields.
     * @param {Unmapped.RetriveCustomerAgreementResponse} rcaResponse : RCA Response.
     * @return {string} State of a policy holder.
     */
    getState(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        let state: string;
        if (rcaResponse.retrieveCustomerAgreementResponse.return.profile.person) {
            state = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .person
                .contactPointAddress
                .alphaState;
        } else {
            state = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .organization &&
                rcaResponse
                    .retrieveCustomerAgreementResponse
                    .return
                    .profile
                    .organization
                    .contactPointAddress
                    .alphaState;
        }
        return state;
    }

    /**
     * @deprecated No Longer in use. RCAResponse no longer hold these fields.
     * @param {Unmapped.RetriveCustomerAgreementResponse} rcaResponse : RCA Response.
     * @return {string} City of a policy holder.
     */
    getCity(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        let city: string;
        if (rcaResponse.retrieveCustomerAgreementResponse.return.profile.person) {
            city = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .person
                .contactPointAddress
                .city;
        } else {
            city = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .organization &&
                rcaResponse
                    .retrieveCustomerAgreementResponse
                    .return
                    .profile
                    .organization
                    .contactPointAddress
                    .city;
        }
        return city;
    }

    /**
     * @deprecated No Longer in use. RCAResponse no longer hold these fields.
     * @param {Unmapped.RetriveCustomerAgreementResponse} rcaResponse : RCA Response.
     * @return {string} Address Line 1 of a policy holder.
     */
    getStreetLine1(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        let streetLine1: string;
        if (rcaResponse.retrieveCustomerAgreementResponse.return.profile.person) {
            streetLine1 = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .person
                .contactPointAddress
                .line1;
        } else {
            streetLine1 = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .organization &&
                rcaResponse
                    .retrieveCustomerAgreementResponse
                    .return
                    .profile
                    .organization
                    .contactPointAddress
                    .line1;
        }
        return streetLine1;
    }

    /**
     * @deprecated No Longer in use. RCAResponse no longer hold these fields.
     * @param {Unmapped.RetriveCustomerAgreementResponse} rcaResponse : RCA Response.
     * @return {string} Zip code of a policy holder.
     */
    getZipCode(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        let zipCode: string;
        if (rcaResponse.retrieveCustomerAgreementResponse.return.profile.person) {
            zipCode = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .person
                .contactPointAddress
                .zipCode;
        } else {
            zipCode = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .organization &&
                rcaResponse
                    .retrieveCustomerAgreementResponse
                    .return
                    .profile
                    .organization
                    .contactPointAddress
                    .zipCode;
        }
        return zipCode;
    }

    /**
     * @deprecated No Longer in use. RCAResponse no longer hold these fields.
     * @param {Unmapped.RetriveCustomerAgreementResponse} rcaResponse : RCA Response.
     * @return {string} Phone number of a policy holder.
     */
    getPhone(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        let phone: string;
        if (rcaResponse.retrieveCustomerAgreementResponse.return.profile.person) {
            phone = this.populatePhone(rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .person.contactPointPhone);
        } else {
            phone = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .organization &&
                this.populatePhone(rcaResponse
                    .retrieveCustomerAgreementResponse
                    .return
                    .profile
                    .organization.contactPointPhone);
        }
        return phone;
    }

    getPolicyLevelSourceSystem(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): string {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        for (const agreement of agreementList) {
            if (agreement.refId === pkey) {
                return agreement.system;
            }
        }
    }

    getAgreement(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): Unmapped.Agreement {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        for (const agreement of agreementList) {
            if (agreement.refId === pkey) {
                return agreement;
            }
        }
    }

    getAccountLevelSourceSystem(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        const billingAgreement = this.getBillingAgreement(rcaResponse);
        return this.canPay(billingAgreement.roleInAgreement.permission) ? billingAgreement.system : undefined;
    }

    getPastDueAccountLevel(parameters: Unmapped.Parameter | Unmapped.Parameter[]): boolean {
        if (parameters instanceof Array && parameters.length) {
            for (const parameter of parameters) {
                if (parameter.name === 'paymentStatus') {
                    return parameter.value === 'Past due';
                }
            }
        }
    }

    getRecievedAccountLevel(parameters: Unmapped.Parameter | Unmapped.Parameter[]): boolean {
        if (parameters instanceof Array && parameters.length) {
            for (const parameter of parameters) {
                if (parameter.name === 'paymentStatus') {
                    return parameter.value === 'Received';
                }
            }
        }
    }

    isWarnedPolicyWarnedOnAccount(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        const warnedStatusPolicy: WarnedPolicy[] = [];
        const policyLists = this.getPolicies(rcaResponse);
        for (const policyList of policyLists) {
            if (this.warnedRegex.test(policyList.status) && this.canPay(policyList.roleInAgreement.permission)) {
                warnedStatusPolicy.push(policyList);
            }
        }
        return this.getNumberWarnedPolicyOnAccount(warnedStatusPolicy);
    }

    getNumberWarnedPolicyOnAccount(warnedpolicy: WarnedPolicy[]): string {
        let warnedCount: string;
        const singleWarnedPolicy = 'singleWarned';
        const multipleWarnedPolicy = 'multipleWarned';
        if (warnedpolicy.length === 1) {
            warnedCount = singleWarnedPolicy;
        } else if (warnedpolicy.length > 1) {
            warnedCount = multipleWarnedPolicy;
        }
        return warnedCount;
    }

    getPowerSportslineOfBusiness(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const powersportslineofBusiness = new Set(['Boat', 'Recreational Vehicle']);
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            agreement.refId === pkey &&
            powersportslineofBusiness.has(agreement.lineOfBusiness)
        );
    }

    getPowerSportsAgreementLabel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const powersportsLabel = new Set(['Boat', 'RV', 'Motorcycle / Misc Vehicle']);
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            agreement.refId === pkey &&
            powersportsLabel.has(agreement.agreementLabel)
        );
    }

    getLifeOnlyNotCareMatters(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            agreement.refId === pkey &&
            LINE_OF_BUSINESS_LIST.OIPA.includes(agreement.lineOfBusiness)
        );
    }

    getLifeOnlyAccount(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        for (const lifeAgreement of agreementList) {
            if (LIFE_LINE_OF_BUSINESS_LIST.includes(lifeAgreement.lineOfBusiness)) {
                return true;
            }
        }
    }

    hasVULProductPolicy(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, agreementNumber: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        const relatedPolicies = agreementList?.filter((agreement) => {
            agreement = this.sharedService.transformRefIdToArray(agreement);
            return agreement.relatedAgreement?.refId?.includes(agreementNumber);
        });
        return relatedPolicies?.some((policy) => policy.lineOfBusiness === LINE_OF_BUSINESS_LIST.VUL);
    }

    containsCareMattersPolicy(paramValue: Unmapped.RetriveCustomerAgreementResponse | string): boolean {
        let output = false;
        if (typeof paramValue !== 'string') {
            const agreementList = this.getAgreementsFromRCA(paramValue);
            for (const lifeAgreement of agreementList) {
                let agreement = lifeAgreement;
                agreement = this.sharedService.transformRefIdToArray(agreement);
                if (agreement.agreementType !== 'BILLING' && agreement.lineOfBusiness &&
                    agreement.relatedAgreement?.refId?.includes(this.session.billingAccountNumber) &&
                    LINE_OF_BUSINESS_LIST.CAREMATTERS.includes(agreement.lineOfBusiness.toUpperCase())) {
                    output = true;
                    break;
                }
            }
        } else {
            output = paramValue && LINE_OF_BUSINESS_LIST.CAREMATTERS.includes(paramValue.toUpperCase());
        }
        return output;
    }

    isCapPaymentExceeded(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const MAXIMUM_PAYMENT_AMOUNT = 100000;
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        return this.containsCareMattersPolicy(rcaResponse) && this.canPayAccountlevel(billingAgreement) &&
            parseInt(this.getStatementAmount(billingAgreement), 10) > MAXIMUM_PAYMENT_AMOUNT;
    }

    getLifeOnlyAgreementLabel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            agreement.refId === pkey &&
            agreement.lineOfBusiness === 'Life'
        );
    }

    getAccountInactiveAndPolicyCancelled(billingAgreement: Unmapped.Agreement, policyAgreement: Unmapped.Agreement): PolicyAgreementStatus[] {
        const policyAgreementStatus = [];
        if (this.getPolicyStatus(policyAgreement) === 'Cancelled' && billingAgreement.status === 'Inactive' &&
            this.getReinstateFlag(policyAgreement) === 'E') {
            policyAgreementStatus.push({
                agreement: policyAgreement,
                cancelledStatus: this.getPolicyStatus(policyAgreement),
                reInstateFlag: this.getReinstateFlag(policyAgreement)
            });
            return policyAgreementStatus;
        }
    }

    getAccountInactive(billingAgreement: Unmapped.Agreement): boolean {
        return billingAgreement.status === 'Inactive';
    }

    hasAnyReinstatePolicy(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey?: string): boolean {
        let hasAnyReinstatePolicy: boolean;
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        if (pkey) {
            const reinstatePolicies = agreementList.filter((agreement) => agreement.agreementType !== 'BILLING' &&
                !this.warnedRegex.test(agreement.status) &&
                agreement.refId === pkey &&
                agreement.policyDetail.reinstatementFlag === 'E');
            hasAnyReinstatePolicy = reinstatePolicies.length > 0;
        } else {
            const reinstatePolicies = agreementList.filter((agreement) => {
                agreement = this.sharedService.transformRefIdToArray(agreement);
                return agreement.agreementType !== 'BILLING' &&
                    !this.warnedRegex.test(agreement.status) &&
                    agreement.relatedAgreement?.refId?.includes(this.session.billingAccountNumber) &&
                    agreement.policyDetail.reinstatementFlag === 'E';
            });
            hasAnyReinstatePolicy = reinstatePolicies.length > 0;
        }
        return hasAnyReinstatePolicy;
    }

    hasCanPayBillingAccountWithReinstate(billingAgreement: Unmapped.Agreement,
        rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        return this.canPay(billingAgreement.roleInAgreement.permission) && this.isPolicyEligibleForReinstate(rcaResponse);
    }

    isPolicyEligibleForReinstate(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey?: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        if (pkey) {
            return agreementList.some((agreement) =>
                agreement.agreementType !== 'BILLING' &&
                !this.warnedRegex.test(agreement.status) &&
                agreement.refId === pkey &&
                agreement.policyDetail.reinstatementFlag === 'E'
            );
        }
    }

    getReinstatementAmount(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey?: string): string {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        if (agreementList instanceof Array) {
            for (const agreement of agreementList) {
                if (agreement.agreementType !== 'BILLING' && agreement.refId === pkey && agreement.policyDetail.reinstatementFlag === 'E') {
                    return agreement.policyDetail.reinstatementAmount;
                }
            }
        }
    }
    getReinstateFlag(agreement: Unmapped.Agreement): string {
        return agreement.policyDetail.reinstatementFlag;
    }

    parametersInstallmentDateOptions(parameters: Unmapped.Parameter | Unmapped.Parameter[]): boolean {
        let isLastMonth = false;
        if (parameters) {
            if (parameters instanceof Array) {
                for (const parameter of parameters) {
                    if (parameter.name === 'installmentDate') {
                        isLastMonth = new Date(parameter.value).getMonth() - this.getCurrentDate().getMonth() === 0;
                    }
                }
            } else if (parameters.name === 'installmentDate') {
                isLastMonth = this.getCurrentDate().getMonth() - new Date(parameters.value).getMonth() === 0;
            }
        }
        return isLastMonth;
    }

    isLastMonthOfTerm(billingAgreement: Unmapped.Agreement): boolean {
        let isLastMonthOfTerm: boolean;
        if (billingAgreement.billingAccountDetail.billingDetail.message instanceof Array) {
            const billingAgreementRCA = billingAgreement.billingAccountDetail.billingDetail.message[0];
            isLastMonthOfTerm = this.parametersInstallmentDateOptions(billingAgreementRCA.parameter);
        } else if (billingAgreement.billingAccountDetail.billingDetail.message &&
            billingAgreement.billingAccountDetail.billingDetail.message.parameter) {
            isLastMonthOfTerm = this.parametersInstallmentDateOptions(billingAgreement.billingAccountDetail.billingDetail.message.parameter);
        }
        return isLastMonthOfTerm;
    }

    isAgreementInLastMonthTerm(billingAgreement: Unmapped.Agreement): boolean {
        let isAgreementInLastMonthTerm: boolean;
        const statementDate = billingAgreement.billingAccountDetail.billingDetail.statement.dueDate;
        if (this.isLastMonthOfTerm(billingAgreement)) {
            isAgreementInLastMonthTerm = this.isLastMonthOfTerm(billingAgreement);
        } else if (statementDate) {
            isAgreementInLastMonthTerm = new Date(statementDate).getMonth() - this.getCurrentDate().getMonth() === 0 &&
                new Date(statementDate).getFullYear() - new Date().getFullYear() === 0;
        }
        return isAgreementInLastMonthTerm;
    }

    getDayjsDateNow(): dayjs.Dayjs {
        return dayjs.utc();
    }

    getCurrentDate(): Date {
        return new Date();
    }

    canPay(permissions: Unmapped.Permission[]): boolean {
        let canPay = false;
        if (permissions instanceof Array && permissions.length) {
            const displayedProfiles = permissions.filter((permission) => permission.name === 'canPayNow' && permission.value === 'true');
            canPay = displayedProfiles.length === 1;
        }
        return canPay;
    }

    isPaymentReminderEligiblePolicies(permissions: Unmapped.Permission[]): boolean {
        let isPaymentRemiderEligible = false;
        if (permissions instanceof Array && permissions.length) {
            isPaymentRemiderEligible = permissions.some(
                (permission) => permission.name === 'isPaymentReminderEligible' && permission.value === 'true');
        }
        return isPaymentRemiderEligible;
    }

    isPaymentRemiderEligible(billingAgreement: Unmapped.Agreement): boolean {
        return this.isPaymentReminderEligiblePolicies(billingAgreement.roleInAgreement.permission);
    }

    isAccountEligibleForEasyPay(billingAgreement: Unmapped.Agreement): string {
        return billingAgreement?.billingAccountDetail.eligibleForEasyPay;
    }

    isAccountWarned(billingAgreement: Unmapped.Agreement | QuickPayAgreement): boolean {
        return billingAgreement?.billingAccountDetail?.warningIndicator === 'true';
    }

    isPolicyWarned(agreementStatus: string): boolean {
        return this.warnedRegex.test(agreementStatus);
    }

    isAccountFullPay(response: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const agreements = this.getAgreementsFromRCA(response);
        return agreements.some((agreement) => agreement.billingAccountDetail && agreement.billingAccountDetail?.paymentPlan === 'Full Pay');
    }

    isAccountMonthly(response: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const agreements = this.getAgreementsFromRCA(response);
        return agreements.some((agreement) => agreement.billingAccountDetail && agreement.billingAccountDetail?.paymentPlan === 'Monthly');
    }

    isAccount306090(response: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const agreements = this.getAgreementsFromRCA(response);
        return agreements.some((agreement) => agreement.billingAccountDetail && agreement.billingAccountDetail?.paymentPlan === '30/60/90');
    }

    isPaymentScheduledAccountLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const billingAgreement = this.getBillingAgreement(rcaResponse);
        return billingAgreement?.billingAccountDetail?.billingDetail?.hasScheduledPaymentIndicator === 'true';
    }

    isPaymentScheduledPolicyLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' && agreement.refId === pkey &&
            agreement.policyDetail?.billingDetail?.hasScheduledPaymentIndicator === 'true'
        );
    }

    isSchedulePaymentAllowedAccountLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const billingAgreement = this.getBillingAgreement(rcaResponse);
        return billingAgreement?.billingAccountDetail?.billingDetail?.schedulePaymentAllowedIndicator === 'true';
    }

    isSchedulePaymentAllowedPolicyLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' && agreement.refId === pkey &&
            agreement?.policyDetail?.billingDetail?.schedulePaymentAllowedIndicator === 'true'
        );
    }

    scheduledPaymentAmountAccountLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        const messages = this.getBillingAccountMessagesFromRCA(rcaResponse);

        for (const message of messages) {
            if (message?.parameter) {
                const parameters = this.getParametersFromRCAMessage(message);
                for (const param of parameters) {
                    if (param.name === 'scheduledPaymentAmount') {
                        return param.value;
                    }
                }
            }
        }
    }

    getPayPlanValueForEBI(response: Unmapped.RetriveCustomerAgreementResponse): string {
        if (this.isAccountMonthly(response)) {
            return 'Monthly';
        } else if (this.isAccountFullPay(response)) {
            return 'Full Pay';
        } else if (this.isAccount306090(response)) {
            return '30/60/90';
        } else {
            return 'NA';
        }
    }

    getParameterValueFromMultipleMessages(messages: Unmapped.Message[], parameterName: string, type: any): string {
        for (const message of messages) {
            if (message?.parameter) {
                const parameters = this.getParametersFromRCAMessage(message);
                for (const param of parameters) {
                    if (param.name === parameterName) {
                        const dateOrCurrency: Date | string = this.mapDateOrCurrency(param.value, Date);
                        return type === Date ? this.getStringDate(<Date>dateOrCurrency) : <string>dateOrCurrency;
                    }
                }
            }
        }
    }

    getParameterValueFromSingleMessage(message: Unmapped.Message, parameterName: string, type: any): string | Date {
        if (message.parameter) {
            const parameters = this.getParametersFromRCAMessage(message);
            for (const param of parameters) {
                if (param.name === parameterName) {
                    return type === Date ? new Date(param.value) : param.value;
                }
            }
        }
    }

    scheduledPaymentDateAccountLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): Date {
        const messages = this.getBillingAccountMessagesFromRCA(rcaResponse);

        for (const message of messages) {
            if (message?.parameter) {
                const parameters = this.getParametersFromRCAMessage(message);
                for (const param of parameters) {
                    if (param.name === 'scheduledPaymentDate') {
                        return new Date(param.value);
                    }
                }
            }
        }
    }

    scheduledPaymentAmountPolicyLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): string {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        for (const agreement of agreementList) {
            if (agreement.refId === pkey && agreement.policyDetail?.billingDetail?.message) {
                if (agreement.policyDetail.billingDetail.message instanceof Array) {
                    for (const message of agreement.policyDetail.billingDetail.message) {
                        return <string> this.getParameterValueFromSingleMessage(message, 'scheduledPaymentAmount', 'currency');
                    }
                } else {
                    return <string> this.getParameterValueFromSingleMessage(agreement.policyDetail.billingDetail.message, 'scheduledPaymentAmount', 'currency');
                }
            }
        }
    }

    scheduledPaymentDatePolicyLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): Date {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        for (const agreement of agreementList) {
            if (agreement.refId === pkey && agreement.policyDetail?.billingDetail?.message) {
                if (agreement.policyDetail.billingDetail.message instanceof Array) {
                    for (const message of agreement.policyDetail.billingDetail.message) {
                        return <Date> this.getParameterValueFromSingleMessage(message, 'scheduledPaymentDate', Date);
                    }
                } else {
                    return <Date> this.getParameterValueFromSingleMessage(agreement.policyDetail.billingDetail.message, 'scheduledPaymentDate', Date);
                }
            }
        }
    }

    isDuedatePastCurrentDateAccountLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        let isDueDatePastCurrentDate: boolean;
        const billingAccount = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const statementDueDate = this.getStatementDueDate(billingAccount);
        const nextDueDate = this.getNextDueDate(billingAccount);
        const currentTime = new Date();
        const currentDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
        if (statementDueDate) {
            isDueDatePastCurrentDate = new Date(statementDueDate) < currentDate && !this.isAccountWarned(billingAccount);
        } else {
            isDueDatePastCurrentDate = this.isBillingAccountPaid(billingAccount) && new Date(nextDueDate) < currentDate;
        }
        return isDueDatePastCurrentDate;
    }

    getAmountAccountLevel(billingAgreement: Unmapped.Agreement): string {
        if (billingAgreement?.billingAccountDetail?.billingDetail) {
            const diff = parseFloat(billingAgreement.billingAccountDetail.billingDetail.amountDue) -
                parseFloat(billingAgreement.billingAccountDetail.billingDetail.installmentFee);

            return diff.toFixed(2);
        }
    }

    getPendingPaymentTotal(billingAgreement: Unmapped.Agreement): number {
        if (billingAgreement?.billingAccountDetail?.billingDetail) {
            return parseInt(billingAgreement.billingAccountDetail.billingDetail.pendingPaymentTotal, 10);
        }
    }

    getMinimumAccountLevelAmount(billingAgreement: Unmapped.Agreement | Unmapped.QuickPayAgreement): string {
        if (billingAgreement?.billingAccountDetail?.billingDetail?.amountDue) {
            return `${billingAgreement.billingAccountDetail.billingDetail.amountDue}`;
        }
    }

    getAmountDueFromRCA(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        return billingAgreement?.billingAccountDetail?.billingDetail?.amountDue;
    }

    isOipaOrCaremattersPolicy(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            LIFE_LINE_OF_BUSINESS_LIST.includes(agreement.lineOfBusiness)
        );
    }

    getMinimumPolicyLevelAmount(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): string {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        if (agreementList) {
            for (const agreement of agreementList) {
                if (agreement.refId === pkey && agreement.agreementType !== 'BILLING') {
                    return agreement.policyDetail?.billingDetail?.amountDue;
                }
            }
        }
    }

    getOipaAccountAmountDue(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): string {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        if (agreementList) {
            for (const agreement of agreementList) {
                if (agreement.refId === pkey && agreement.agreementType !== 'BILLING' &&
                    LINE_OF_BUSINESS_LIST.OIPA.includes(agreement.lineOfBusiness)) {
                    return agreement.policyDetail?.billingDetail?.amountDue;
                }
            }
        }
    }

    getFullPayPolicyLevelAmount(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): string {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        for (const agreement of agreementList) {
            if (agreement.refId === pkey && agreement.agreementType !== 'BILLING') {
                return agreement.policyDetail?.billingDetail?.fullPayAmount;
            }
        }
    }

    getStatementDueDate(billingAgreement: Unmapped.Agreement): string {
        return this.getFormattedDate(new Date(billingAgreement?.billingAccountDetail?.billingDetail?.statement?.dueDate));
    }

    getDraftPendingState(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        let isInDraftPendingState = false;
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        let draftPendingStartDate: any;
        let draftPendingEndDate: any;
        const currentDate = new Date();
        if (billingAgreement?.billingAccountDetail?.billingDetail?.statement &&
            billingAgreement.billingAccountDetail.billingDetail.statement.dueDate) {
            const statementDate = new Date(billingAgreement.billingAccountDetail.billingDetail.statement.dueDate);
            draftPendingEndDate = this.getDraftPendingEndDate(statementDate);
            draftPendingStartDate = this.getDraftPendingStartDate(statementDate);
        }

        if (draftPendingEndDate && draftPendingStartDate) {
            isInDraftPendingState = currentDate.toISOString() >= draftPendingStartDate.toISOString() && currentDate.toISOString() <= draftPendingEndDate.toISOString();
        }

        return isInDraftPendingState;
    }

    isPaymentFutureOrDraftPending(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const billingDetail = billingAgreement.billingAccountDetail?.billingDetail;
        const currentDate = this.getDayjsDateNow();
        let isPaymentPending = false;
        if (billingDetail?.status?.toLowerCase() === 'paid') {
            const paymentDate = dayjs.utc(billingDetail.payment?.paymentDate);
            if (paymentDate?.isAfter(currentDate, 'day')) {
                isPaymentPending = true;
            }
        } else {
            isPaymentPending = this.getDraftPendingState(rcaResponse);
        }
        return isPaymentPending;
    }

    getDraftPendingEndDate(date: Date): Date {
        return new Date(date);
    }

    getDraftPendingStartDate(date: Date): Date {
        const startDate = new Date(date);
        startDate.setDate(startDate.getDate() - 2);
        return startDate;
    }

    getNextDueDate(billingAgreement: Unmapped.Agreement): string {
        return this.getFormattedDate(new Date(billingAgreement?.billingAccountDetail?.billingDetail?.nextDueDate));
    }

    getStatementAmount(billingAgreement: Unmapped.Agreement): string {
        return billingAgreement?.billingAccountDetail?.billingDetail?.statement?.statementAmount;
    }

    getNextPaymentAmount(billingAgreement: Unmapped.Agreement): string {
        return billingAgreement?.billingAccountDetail?.billingDetail?.amountDue;
    }

    getFormattedDate(dateValue: Date): string {
        return dayjs(dateValue)?.format('MM/DD/YYYY');
    }

    getFullPaymentAmountAccountLevel(billingAgreement: Unmapped.Agreement | Unmapped.QuickPayAgreement): string {
        return `${billingAgreement?.billingAccountDetail?.billingDetail?.fullPayAmount}`;
    }

    getDateDiff(date1: Date, date2: Date): number {
        const datediff = Math.round(Math.abs((date1.getTime() - date2.getTime()) /
            (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY)));
        return datediff;
    }

    getMessageforPaymentDue(billingAgreement: Unmapped.Agreement | Unmapped.QuickPayAgreement): string {
        const installmentDate = this.getInstallmentDate(billingAgreement);
        const parsedDate = this.extractDate(installmentDate);
        const givenDate = this.getFormattedDate(parsedDate);
        const currentDate = this.getFormattedDate(new Date());
        let messageForPaymentDue: string;
        const daysIncludingToday = this.getDateDiff(new Date(givenDate), new Date(currentDate));
        if (daysIncludingToday === 0) {
            messageForPaymentDue = 'due today';
        } else if ((daysIncludingToday > 0) && (new Date(givenDate) > new Date(currentDate))) {
            messageForPaymentDue = `due in ${daysIncludingToday} days ${givenDate}`;
        } else if (new Date(givenDate) < new Date(currentDate)) {
            messageForPaymentDue = `past due ${givenDate}`;
        }
        return messageForPaymentDue;
    }

    getMessagePaymentDueWarnedPolicy(billingAgreement: Unmapped.Agreement | Unmapped.QuickPayAgreement, policyAgreement: Unmapped.Agreement): string {
        const installmentDate = this.getInstallmentDate(billingAgreement);
        const parsedDate = this.extractDate(installmentDate);
        const givenDate = this.getFormattedDate(parsedDate);
        const currentDate = this.getFormattedDate(new Date());
        let messages: Unmapped.Message[] = [];
        if (policyAgreement?.policyDetail?.billingDetail?.message instanceof Array) {
            messages = policyAgreement?.policyDetail?.billingDetail?.message;
        } else {
            messages = [policyAgreement?.policyDetail?.billingDetail?.message];
        }
        const cancellationDate = this.getParameterValueFromMultipleMessages(
            messages,
            'cancellationDate',
            Date
        );
        let messageForPaymentDue: string;
        const daysIncludingToday = this.getDateDiff(new Date(givenDate), new Date(currentDate));
        if ((new Date(givenDate) < new Date(currentDate)) || (cancellationDate)) {
            messageForPaymentDue = `past due ${givenDate}`;
        } else if ((daysIncludingToday > 0) && (new Date(givenDate) > new Date(currentDate))) {
            messageForPaymentDue = `due in ${daysIncludingToday} days ${givenDate}`;
        } else if (daysIncludingToday === 0) {
            messageForPaymentDue = 'due today';
        }
        return messageForPaymentDue;
    }
    getMessageForMinimumPartialPayment(amountDue: string, isWarnedPolicy: boolean): string {
        return amountDue && !isWarnedPolicy ? `Pay at least ${new CurrencyPipe('en-US').transform(amountDue)}` : 'Pay at least $1.00';
    }
    getInstallmentDate(billingAgreement: Unmapped.Agreement | Unmapped.QuickPayAgreement): string {
        let installmentDate;

        if (billingAgreement?.billingAccountDetail?.billingDetail) {
            const billingDetail = billingAgreement.billingAccountDetail.billingDetail;
            if ((<Unmapped.BillingDetail>billingDetail).statement && (<Unmapped.BillingDetail>billingDetail).statement.dueDate) {
                installmentDate = (<Unmapped.BillingDetail>billingDetail).statement.dueDate;
            } else if ((<any>billingDetail).currentStatement && (<any>billingDetail).currentStatement.dueDate) {
                const date = new Date((<any>billingDetail).currentStatement.dueDate);
                installmentDate = date.toISOString();
            }
        }

        return installmentDate;
    }

    getPaymentPlan(billingAgreement: Unmapped.Agreement): string {
        return billingAgreement?.billingAccountDetail?.paymentPlan;
    }

    getPaymentMethod(billingAgreement: Unmapped.Agreement | Unmapped.QuickPayAgreement): string {
        return billingAgreement?.billingAccountDetail?.paymentMethod;
    }

    isWarnedPolicy(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            agreement.refId === pkey && this.warnedRegex.test(agreement.status)
        );
    }

    isWarnedPolicyNonPowersports(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        const powersportsLabel = new Set(['Boat', 'RV', 'Motorcycle / Misc Vehicle']);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            agreement.refId === pkey &&
            (agreement.refId.slice(0, 2) === 'MSN' ||
                agreement.refId.slice(0, 2) === 'PP' || agreement.refId.slice(0, 2) === 'BT' || agreement.refId.slice(0, 2) === 'RV') &&
            this.warnedRegex.test(agreement.status) &&
            !powersportsLabel.has(agreement.agreementLabel)
        );
    }

    isSAWarnedPolicy(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        const saPolicy = new Set(['PP', 'MS', 'AA', 'BT', 'RV', 'HO', 'HA', 'HM', 'HX', 'PE', 'PA']);
        return agreementList.some((agreement) =>
            agreement.agreementType !== 'BILLING' &&
            agreement.refId === pkey &&
            this.warnedRegex.test(agreement.status) &&
            saPolicy.has(agreement.refId.slice(0, 2))
        );
    }

    getDueDateAccountLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        let dueDate = '';
        const billingAgreement = this.getBillingAgreement(rcaResponse);
        if (billingAgreement.billingAccountDetail.billingDetail.statement.dueDate) {
            const dateExtracted = billingAgreement.billingAccountDetail.billingDetail.statement.dueDate.split('T')[0].split('-');
            dueDate = `${dateExtracted[1]}/${dateExtracted[2]}/${dateExtracted[0]}`;
        }
        return dueDate;
    }

    getMaxPaymentDateAcountLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): string {
        const billingAgreement = this.getBillingAgreement(rcaResponse);
        let dueDate = '';
        if (billingAgreement.agreementType === 'BILLING' && billingAgreement.billingAccountDetail.billingDetail.scheduleMaximumPaymentDateAllowed) {
            const dateExtracted = billingAgreement.billingAccountDetail.billingDetail.scheduleMaximumPaymentDateAllowed.split('T')[0].split('-');
            dueDate = `${dateExtracted[1]}/${dateExtracted[2]}/${dateExtracted[0]}`;
        }
        return dueDate;
    }
    getMaxPaymentDatePolicyLevel(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): string {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        let maxDateAllowed = '';
        for (const agreement of agreementList) {
            if (agreement.agreementType !== 'BILLING' && agreement.refId === pkey) {
                if (agreement.policyDetail.billingDetail.scheduleMaximumPaymentDateAllowed) {
                    const dateExtracted = agreement.policyDetail.billingDetail.scheduleMaximumPaymentDateAllowed.split('T')[0].split('-');
                    maxDateAllowed = `${dateExtracted[1]}/${dateExtracted[2]}/${dateExtracted[0]}`;
                }
            }
        }
        return maxDateAllowed;
    }

    getCanPayPolicies(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, pkey: string): boolean {
        const agreementList = this.getAgreementsFromRCA(rcaResponse);
        return agreementList.some((agreement) => agreement.agreementType !== 'BILLING' &&
            agreement.refId === pkey && this.canPay(agreement.roleInAgreement?.permission));
    }

    fetchIsPaperlessEligible(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        let isPaperlessEligible = false;
        if (rcaResponse) {
            const billingAgreement = this.getBillingAgreement(rcaResponse);
            if (billingAgreement?.roleInAgreement?.permission instanceof Array && billingAgreement.roleInAgreement.permission.length) {
                isPaperlessEligible = billingAgreement.roleInAgreement.permission.some((permission) =>
                    permission.name === 'isPaperlessEligible' && permission.value === 'true'
                );
            }
        }
        return isPaperlessEligible;
    }

    isEligibleForEasyPay(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        if (rcaResponse) {
            const billingAgreement = this.getBillingAgreement(rcaResponse);
            return billingAgreement?.billingAccountDetail?.eligibleForEasyPay === 'true';
        }
    }

    enrolled(rcaResponse: any): boolean {
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        let isEnrolled = false;
        const paymentMethod = this.getPaymentMethod(billingAgreement);
        if (paymentMethod === 'CPPEP' || paymentMethod === 'CPPRP') {
            isEnrolled = true;
        }
        return isEnrolled;
    }

    getAmountOfReinstatement(billingAgreement: Unmapped.Agreement): string {
        return billingAgreement?.billingAccountDetail?.billingDetail?.payment?.amount;
    }

    getPaymentDateOfReinstatement(billingAgreement: Unmapped.Agreement): Date {
        return this.extractDate(billingAgreement?.billingAccountDetail?.billingDetail?.payment?.paymentDate);
    }

    getPartialPaymentHistoryAccountLevel(paymentHistoryResponse: RetrivePaymentHistoryResponse):
        PaymentHistoryItem[] {
        const partialPayments: PaymentHistoryItem[] = [];
        paymentHistoryResponse?.payments.filter((payment) => {
            const paymentStatus = payment.status;
            if (paymentStatus && (paymentStatus === 'WAITING TO PROCESS' || paymentStatus === 'SETTLED')) {
                partialPayments.push(new PaymentHistoryItem(
                    this.mapPaymentMethod(payment.paymentTxnDetails?.paymentMethod),
                    PaymentHistoryStatus.WAITING,
                    this.extractPaymentDate(payment.paymentDate),
                    payment.amount,
                    parseInt(payment.paymentTxnDetails?.receiptId, 10),
                    payment.paymentTxnDetails?.paymentMethod?.savedPaymentMethod?.savedPaymentMethod?.paymentType
                ));
            }
        });
        return partialPayments;
    }

    getPartialPaymentHistoryPolicyLevel(paymentHistoryResponse: RetrivePaymentHistoryResponse, pkey: string):
        PaymentHistoryItem[] {
        const partialPayments: PaymentHistoryItem[] = [];
        paymentHistoryResponse?.payments.filter((payment) => {
            const paymentStatus = payment.status;
            const policyNumber = payment.relatedPolicy?.policyNumber?.replace(' ', '');
            if (pkey === policyNumber && paymentStatus && (paymentStatus === 'WAITING TO PROCESS' || paymentStatus === 'SETTLED')) {
                partialPayments.push(new PaymentHistoryItem(
                    this.mapPaymentMethod(payment.paymentTxnDetails?.paymentMethod),
                    PaymentHistoryStatus.WAITING,
                    this.extractPaymentDate(payment.paymentDate),
                    payment.amount,
                    parseInt(payment.paymentTxnDetails?.receiptId, 10),
                    payment.paymentTxnDetails?.paymentMethod?.savedPaymentMethod?.savedPaymentMethod?.paymentType
                ));
            }
        });
        return partialPayments;
    }

    mapPaymentMethod(rawPaymentMethod: any): PaymentHistoryMethod {
        // TODO this needs review
        let hardCodedSavedPaymentMethod: any;
        let paymentMethod: any;
        try {
            hardCodedSavedPaymentMethod = rawPaymentMethod?.savedPaymentMethod?.savedPaymentMethod?.type;
        } catch (e) {
            hardCodedSavedPaymentMethod = undefined;
        }
        switch (hardCodedSavedPaymentMethod) {
            case 'CHECK': paymentMethod = PaymentHistoryMethod.CHECK;
                break;
            case 'BANKCARD': paymentMethod = PaymentHistoryMethod.BANKCARD;
                break;
            case 'ApplePay': paymentMethod = PaymentHistoryMethod.APPLE_PAY;
                break;
            case 'CASH': paymentMethod = PaymentHistoryMethod.CASH;
                break;
            case 'PayPal': paymentMethod = PaymentHistoryMethod.PAY_PAL;
                break;
            case 'RECURRING BANK EFT': paymentMethod = PaymentHistoryMethod.RECURRING_EFT;
                break;
            // SAP
            case 'RECURRING BANKEFT': paymentMethod = PaymentHistoryMethod.RECURRING_EFT;
                break;
            case 'ELECTRONIC FUND TRANSFER': paymentMethod = PaymentHistoryMethod.EFT;
                break;
            case 'MONEY ORDER': paymentMethod = PaymentHistoryMethod.MONEY_ORDER;
                break;
            case 'MASTERPASS': paymentMethod = PaymentHistoryMethod.MASTERPASS;
                break;
        }
        return paymentMethod;
    }

    extractPaymentDate(dateField: string): Date {
        let paymentDate: Date;
        if (new RegExp('^[01]?[0-9]-[0123]?[0-9]-20[0-9]{2}').test(dateField)) {
            const rawDate: any = dateField.split('-');
            paymentDate = new Date(rawDate[2], rawDate[0] - 1, rawDate[1]);
        }
        return paymentDate;
    }

    mapEmailAddress(rcaResponse: RetriveCustomerAgreementResponse): string {
        let contactString: any = '';
        if (rcaResponse?.retrieveCustomerAgreementResponse?.return?.profile?.person) {
            contactString = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .person
                .contactPointElectronic
                ?.contactString;
        } else if (rcaResponse?.retrieveCustomerAgreementResponse?.return?.profile?.organization) {
            contactString = rcaResponse
                .retrieveCustomerAgreementResponse
                .return
                .profile
                .organization
                .contactPointElectronic
                ?.contactString;
        }
        return contactString;
    }
    getNextPaymentDate(rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse): Date {
        let paymentDate: Date;

        try {
            const billingAccount = this.getBillingAgreement(rcaResponse);
            paymentDate = new Date(billingAccount?.billingAccountDetail?.billingDetail?.nextDueDate);
        } catch (error) {
            paymentDate = null;
        }

        return paymentDate;
    }

    getBillingStatus(rcaResponse: UnmappedRCA.RetriveCustomerAgreementResponse): string {
        const billingAgreement = this.getBillingAgreement(rcaResponse);
        return billingAgreement?.billingAccountDetail?.billingDetail?.status;
    }

    getPlaceOfIssue(agreement: Unmapped.Agreement): string {
        return agreement?.placeOfIssue;
    }

    getPermissions(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): Permission[] {
        return this.getBillingAgreement(rcaResponse)?.roleInAgreement?.permission;
    }

    getSmartMilesPermission(permissions: Permission[]): Permission {
        return permissions?.find((permission) => permission.name === 'containsSmartMiles');
    }

    isEnrolledInSmartMiles(permission: Permission): boolean {
        return permission?.name === 'containsSmartMiles' && permission?.value === 'true';
    }

    areStateAndLOBBanned(billingAccountNumber: string, rcaResponse: Unmapped.RetriveCustomerAgreementResponse): boolean {
        let isBanned = false;
        const productTypes: string[] = [ProductType.AUTO, ProductType.HOMEOWNERS, ProductType.CONDO, ProductType.RENTERS, ProductType.MOTORCYCLE_MISC_VEHICLE, ProductType.RV];
        for (const agreement of this.getAgreementsFromRCA(rcaResponse)) {
            if (this.isRelatedToAccount(agreement, billingAccountNumber) &&
                this.getPlaceOfIssue(agreement).equalsIgnoreCase('WA') &&
                productTypes.includes(agreement?.agreementLabel)) {
                isBanned = true;
                break;
            }
        }
        return isBanned;
    }

    isPayPlanPermission(agreement: Unmapped.Agreement): boolean {
        let canCheckPayPlanPermission: Unmapped.Permission[] = null;
        if (agreement.roleInAgreement?.permission) {
            canCheckPayPlanPermission = this.getCanCheckPayPlanPermission(agreement.roleInAgreement.permission);
        }
        return canCheckPayPlanPermission !== null && canCheckPayPlanPermission.length > 0;
    }

    private getCanCheckPayPlanPermission(permissions: Unmapped.Permission[]): Unmapped.Permission[] {
        let autoMvpPayPlanPermissions: Unmapped.Permission[] = null;
        if (permissions instanceof Array && permissions.length > 0) {
            autoMvpPayPlanPermissions = permissions.filter((permission) => permission && permission.name === 'canCheckPayPlan' && permission.value === 'true');
        }
        return autoMvpPayPlanPermissions;
    }

    transformToCurrency(value: string): string {
        if (value) {
            value = new CurrencyPipe('en-US')
                .transform(value, 'USD', 'symbol-narrow', '1.2-2')
                .slice(1);
        }
        return value;
    }

    mapDateOrCurrency(value: string, type: any): Date | string {
        let dateOrCurrency: Date | string = value;
        if (type === Date) {
            dateOrCurrency = this.extractDate(value);
        } else if (type === 'currency') {
            dateOrCurrency = this.transformToCurrency(value);
        }
        return dateOrCurrency;
    }

    getStringDate(dateValue: Date): string {
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(dateValue, 'MM/dd/yyyy');
    }

    isDirectBill(agreement: Unmapped.Agreement): boolean {
        return agreement?.billingAccountDetail?.paymentMethod === 'CPPDB';
    }

    isAutoPayEnrolled(billingAgreement: Unmapped.Agreement | Unmapped.QuickPayAgreement): boolean {
        const paymentMethod = this.getPaymentMethod(billingAgreement);
        return paymentMethod === 'CPPEP' || paymentMethod === 'CPPRP';
    }

    private populatePhone(contactPointPhoneArray: Unmapped.ContactPointPhone[]): string {
        let phone;
        if (contactPointPhoneArray && contactPointPhoneArray.length > 0) {
            phone = contactPointPhoneArray.length === 1 ? contactPointPhoneArray[0].contactString :
                this.retrievePhoneFromType(contactPointPhoneArray, 'home phone');
            phone = !phone && contactPointPhoneArray[contactPointPhoneArray.length - 1] ?
                contactPointPhoneArray[contactPointPhoneArray.length - 1].contactString : '';
        }
        return phone;
    }

    private retrievePhoneFromType(contactPointPhoneArray: Unmapped.ContactPointPhone[], phoneType: string): string {
        const contactPointPhoneResultArray: Unmapped.ContactPointPhone[] = contactPointPhoneArray.filter((contactPointPhone) =>
            contactPointPhone.type?.toLowerCase() === phoneType);
        return contactPointPhoneResultArray && contactPointPhoneResultArray.length > 0 ?
            contactPointPhoneResultArray[0].contactString : '';
    }

    extracthasAutomaticPaymentsSuspended(billingAccount: Unmapped.Agreement | Unmapped.QuickPayAgreement): boolean {
        return billingAccount?.billingAccountDetail?.billingDetail?.automaticPaymentSuspended === 'true';
    }

    private hasReceivedPayment(billingAgreement: Unmapped.Agreement): boolean {
        let message: Unmapped.Message;
        if (billingAgreement?.billingAccountDetail?.billingDetail?.message instanceof Array) {
            message = billingAgreement.billingAccountDetail.billingDetail.message
                .filter((nextMessage) => nextMessage.name?.startsWith('billing.block'))[0];
        } else {
            message = billingAgreement?.billingAccountDetail?.billingDetail?.message;
        }
        return this.hasReceivedMessage(message?.parameter);
    }

    private hasReceivedMessage(parameters: Unmapped.Parameter | Unmapped.Parameter[]): boolean {
        let paymentStatusParameter: Unmapped.Parameter;

        if (parameters instanceof Array) {
            paymentStatusParameter = parameters.filter((parameter) => parameter.name === 'paymentStatus')[0];
        } else {
            paymentStatusParameter = parameters;
        }

        return paymentStatusParameter?.value === 'Paid' || paymentStatusParameter?.value === 'Received';
    }

    private billingAccountStatementDate(billingAccount: Unmapped.Agreement): Date {
        const dateString = this.statementDateString(billingAccount);
        return dateString ? new Date(this.statementDateString(billingAccount)) : undefined;
    }

    private billingAccountNextDueDate(billingAccount: Unmapped.Agreement): Date {
        const nextDueDateString = this.nextDueDateString(billingAccount);
        return nextDueDateString ? new Date(nextDueDateString) : null;
    }

    private statementDateString(billingAgreement: Unmapped.Agreement): string {
        return billingAgreement?.billingAccountDetail?.billingDetail?.statement?.dueDate;
    }

    private nextDueDateString(billingAccount: Unmapped.Agreement): string {
        return billingAccount?.billingAccountDetail?.billingDetail?.nextDueDate;
    }

    getCanManage(billingAgreement: UnmappedRCA.Agreement): boolean {
        const canManage = billingAgreement?.roleInAgreement?.permission?.some((permission) =>
            permission.name === 'canViewBilling' && permission.value === 'true'
        );

        return canManage;
    }

    addressFromInternetRegistrationResponse(internetRegistrationResponse: ECIM.EnterpriseCustomerInformationManagementResponse): AccountPolicyHolderAddressElements {
        const address: {} = {};
        if (internetRegistrationResponse?.customers?.contactPoints) {
            const contactPoints: ECIM.ContactPoint[] = internetRegistrationResponse.customers.contactPoints;
            for (const contactPoint of contactPoints) {
                if (contactPoint?.contactPointType === 'Postal Address' && contactPoint?.preferredIndicator === 'Y') {
                    const postalAddress = contactPoint?.postalAddress;
                    if (postalAddress) {
                        address['AcctPolHolderAddr1'] = postalAddress?.addressLineOne;
                        address['AcctPolHolderCity'] = postalAddress?.city;
                        address['AcctPolHolderState'] = postalAddress?.state;
                        address['AcctPolHolderZip'] = postalAddress?.postalCode;
                        break;
                    }
                }
            }
        }
        return address;
    }
}

export interface PolicyAgreementStatus {
    agreement: string;
    canPayStatus: true | false;
    reInstateFlag: 'E';
}

export interface WarnedPolicy {
    agreement: string;
    canPayStatus: true | false;
}
