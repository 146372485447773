import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-recap-form-field',
    templateUrl: './recap-form-field.component.html',
    styleUrls: ['./recap-form-field.component.scss']
})
export class RecapFormFieldComponent {
    @Input() field: RecapFormField;

    get id(): string {
        return this.field.id;
    }

    get label(): string {
        return this.field.label;
    }

    get value(): string {
        return this.field.value;
    }
}

export interface RecapFormField {
    id: string;
    label: string;
    value: string;
}
