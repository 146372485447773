import { Injectable } from '@angular/core';
import { LowerCasePipe } from '@angular/common';

const STATES = [
    ['ak', 'Alaska'],
    ['al', 'Alabama'],
    ['ar', 'Arkansas'],
    ['az', 'Arizona'],
    ['ca', 'California'],
    ['co', 'Colorado'],
    ['ct', 'Connecticut'],
    ['dc', 'District of Columbia'],
    ['de', 'Delaware'],
    ['fl', 'Florida'],
    ['ga', 'Georgia'],
    ['hi', 'Hawaii'],
    ['ia', 'Iowa'],
    ['id', 'Idaho'],
    ['il', 'Illinois'],
    ['in', 'Indiana'],
    ['ks', 'Kansas'],
    ['ky', 'Kentucky'],
    ['la', 'Louisiana'],
    ['ma', 'Massachusetts'],
    ['md', 'Maryland'],
    ['me', 'Maine'],
    ['mi', 'Michigan'],
    ['mn', 'Minnesota'],
    ['mo', 'Missouri'],
    ['ms', 'Mississippi'],
    ['mt', 'Montana'],
    ['nc', 'North Carolina'],
    ['nd', 'North Dakota'],
    ['ne', 'Nebraska'],
    ['nh', 'New Hampshire'],
    ['nj', 'New Jersey'],
    ['nm', 'New Mexico'],
    ['nv', 'Nevada'],
    ['ny', 'New York'],
    ['oh', 'Ohio'],
    ['ok', 'Oklahoma'],
    ['or', 'Oregon'],
    ['pa', 'Pennsylvania'],
    ['ri', 'Rhode Island'],
    ['sc', 'South Carolina'],
    ['sd', 'South Dakota'],
    ['tn', 'Tennessee'],
    ['tx', 'Texas'],
    ['ut', 'Utah'],
    ['va', 'Virginia'],
    ['vt', 'Vermont'],
    ['wa', 'Washington'],
    ['wi', 'Wisconsin'],
    ['wv', 'West Virginia'],
    ['wy', 'Wyoming']
];

const ABBREVIATIONS = STATES.map(([abbreviation]) => abbreviation);

const NAMES = STATES.map(([abbreviation, name]) => name);

const ABBREVIATION_TO_NAME = new Map<string, string>();
STATES.forEach(([abbreviation, name]) => {
    ABBREVIATION_TO_NAME.set(abbreviation, name);
});

const NAME_TO_ABBREVIATION = new Map<string, string>();
STATES.forEach(([abbreviation, name]) => {
    NAME_TO_ABBREVIATION.set(name, abbreviation);
});
@Injectable({
    providedIn: 'root'
})
export class UnitedStatesNameService {
    constructor(
        private lowercasePipe: LowerCasePipe
    ) { }

    convertNameToAbbreviation(name: string): string {
        return NAME_TO_ABBREVIATION.get(name);
    }

    convertAbbreviationToName(abbreviation: string): string {
        const abbreviationLowerCase = this.lowercasePipe.transform(abbreviation);
        return ABBREVIATION_TO_NAME.get(abbreviationLowerCase);
    }

    get abbreviations(): string[] {
        return ABBREVIATIONS;
    }

    get names(): string[] {
        return NAMES;
    }
}
