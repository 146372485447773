<div class="manage-payment-form-container">
    <div class="bolt-container bolt-space-bottom-2xl">

        <bw-bolt-progress-bar appFocusOnInit arialabel="cancel automatic payments progress value is 100%" progressBarValue="100" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Cancel automatic payment: confirmation</h1>

        <div *ngIf="!apiCallSuccessful" class="bolt-space-bottom-2xl">
            <bw-bolt-notification type="error" id="error-notification">
                <bw-html-interpolate content="{{apiFailureMessage}}">
                </bw-html-interpolate>
            </bw-bolt-notification>
        </div>
        <ng-container *ngIf="apiCallSuccessful">
            <div class="bolt-space-bottom-2xl">
                <bw-bolt-notification type="success">
                    {{confirmationMessage}}
                </bw-bolt-notification>
            </div>
            <div *ngIf="amountDueNotExists">
                <app-payment-sub-header><strong>Next Steps</strong></app-payment-sub-header>
                <div class="main-contents">

                    <div class="bolt-row bolt-space-bottom-xl">
                        <div class="bolt-col">
                            <strong>Make a payment by:</strong>
                        </div>
                        <div class="bolt-col">
                            {{amountDueByDate}}
                        </div>
                    </div>

                    <div class="bolt-row bolt-row bolt-space-bottom-2xl">
                        <div class="bolt-col">
                            <strong>Amount due:</strong>
                        </div>
                        <div class="bolt-col">
                            {{dueAmount}}
                        </div>
                    </div>
                </div>
            </div>
            <p class="refund-message bolt-space-bottom-2xl" *ngIf="!isLifeOnlyAccount">
                {{enrollMessage}}
            </p>
            <app-payment-sub-header>Confirmation details</app-payment-sub-header>
            <div class="main-contents">

                <div class="bolt-row bolt-space-bottom-xl">
                    <div class="bolt-col">
                        <strong>Account number:</strong>
                    </div>
                    <div class="bolt-col">
                        {{billingAccountNumber}}
                    </div>
                </div>

                <div class="bolt-row bolt-row bolt-space-bottom-2xl">
                    <div class="bolt-col">
                        <strong>Submitted date:</strong>
                    </div>
                    <div class="bolt-col">
                        {{formattedCurrentDate}}
                    </div>
                </div>
            </div>
            <ng-container *ngIf="amountDueExists">
                <app-payment-sub-header>Outstanding payment due</app-payment-sub-header>
                <div class="main-contents">
                    <p>{{outstandingdueMessage}}
                    </p>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="amountDueExists;else amountDueNotExists;">
        <bolt-button-bar>
            <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
            <bolt-button id="secondary-button" slot="back" (click)="secondayNavigation(secondaryButton)">{{secondaryButton.label}}</bolt-button>
            <bolt-button id="make-a-payment-button" slot="forward" (click)="onContinue()" type="primary" *ngIf='upNextLabel'>
                {{upNextLabel}}
            </bolt-button>
        </bolt-button-bar>
    </ng-container>
    <ng-template #amountDueNotExists>
        <bolt-button-bar>
            <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
            <bolt-button id="secondary-button" slot="forward" (click)="secondayNavigation(secondaryButton)">{{secondaryButton.label}}</bolt-button>
        </bolt-button-bar>
    </ng-template>
</div>