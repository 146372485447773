import { Component, OnInit, Inject } from '@angular/core';
import { AreYouSureFormGroup } from '../../../../../../shared/payments/are-you-sure-radio-buttons/are-you-sure-form-group';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { MapSortPayMethodsOptions, PaymentMethodsAdapter } from '../../../../shared/adapters/payment-methods.adapter';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PaymentItem } from '../../../../shared/models/payment-item.model';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SessionService } from '../../../../../../shared/session/session.service';
import { isbExperienceServiceToken, IsbExperienceService, IsbExperience, PayorInfo } from '@nationwide/dgs-angular-billing-common';
import { PlaceholderPaymentItem } from '../../../../shared/models/placeholder-item.model';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { RemovePaymentMethodFlowService } from '../services/remove-payment-method-flow.service';
import { Observable, of, forkJoin, timeout, flatMap, tap } from 'rxjs';
import { DatePipe } from '@angular/common';
import { updatePaymentCardTypeMap } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
@Component({
    selector: 'app-remove-payment-method-landing',
    templateUrl: './remove-payment-method-landing.component.html',
    styleUrls: [
        '../../../../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../../../../../../shared/payments/stylesheets/form-formatting.scss',
        './remove-payment-method-landing.component.scss'
    ]
})
export class RemovePaymentMethodLandingComponent extends BasePageComponent implements OnInit {
    pageLoadComplete = false;
    submitted = false;
    formGroup: AreYouSureFormGroup;
    paymentMethodToRemove: PaymentItem;
    savedPaymentMethods: PaymentItem[];
    selectedReplacementMethod: PaymentItem;
    pageName = ManagePaymentPreferencesPageTitle.savedPaymentMethods.remove.confirmation;
    shouldDisplayPaymentMethodsDropdown = false;
    displayPaymentMethodsDropdownError = false;

    // eslint-disable-next-line max-params
    constructor(
        private paymentPreferencesApi: PaymentPreferencesApiService,
        router: Router,
        private savedPaymentMethodAdapter: PaymentMethodsAdapter,
        private formBuilder: ManagePaymentPrefsFormBuilder,
        private urlUtil: UrlUtil,
        private datePipe: DatePipe,
        session: SessionService,
        @Inject('logger') private logger: LoggerService,
        private formUtil: FormUtil,
        private removePaymentMethodFlow: RemovePaymentMethodFlowService,
        @Inject(isbExperienceServiceToken) private isbExperienceApi: IsbExperienceService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        this.setupFormGroup();
        this.fetchPaymentMethods();
    }

    setupFormGroup(): void {
        this.formGroup = this.formBuilder.newAreYouSureFormGroup();
    }

    fetchPaymentMethods(): void {
        this.nextSub = this.paymentPreferencesApi.removeSavedPaymentMethodLanding().pipe(
            timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences)
        ).subscribe({
            next: (responses) => {
                const { savedPaymentMethods, paymentMethodToRemove } = responses;
                this.paymentMethodToRemove = this.savedPaymentMethodAdapter.mapSinglePaymentMethod(paymentMethodToRemove);
                this.setupSavedPaymentMethods(savedPaymentMethods);
                this.pageLoadComplete = true;
            },
            error: (error) => {
                this.logger.error('API ERROR: REMOVE PAYMENT METHOD LANDING', error);
                this.navigateToConfirmation();
            }
        });
    }

    setupSavedPaymentMethods(savedPaymentMethodsResponse: IsbExperience.SavedPaymentMethod[]): void {
        const sortingOptions: Partial<MapSortPayMethodsOptions> = {
            alphabeticalSort: true,
            includeNewBankAccount: false,
            includeNewBankCard: false,
            filterCreditCards: this.session.isVUL,
            includePlaceholder: false
        };
        if (this.isSelectedMethodDefault) {
            let mappedPaymentMethods = this.savedPaymentMethodAdapter.mapSortPayMethods(savedPaymentMethodsResponse, sortingOptions);
            mappedPaymentMethods = this.removeDefaultPaymentMethod(mappedPaymentMethods);
            if (mappedPaymentMethods.length > 0) {
                this.shouldDisplayPaymentMethodsDropdown = true;
                mappedPaymentMethods.splice(0, 0, this.placeholderPaymentItem);

                this.savedPaymentMethods = mappedPaymentMethods;
                this.selectedReplacementMethod = this.savedPaymentMethods[0];
            }
        }
    }

    removeDefaultPaymentMethod(mappedPaymentMethods: PaymentItem[] = []): PaymentItem[] {
        return mappedPaymentMethods.filter((method) => !method.isDefault);
    }

    onSubmit(): void {
        this.submitted = true;
        this.formUtil.touchAllFormControls(this.formGroup.group);
        if (this.isPlaceholderSelected) {
            this.displayPaymentMethodsDropdownError = true;
        }

        if (this.formGroup.group.valid) {
            if (this.formGroup.selection === 'yes' && !this.isPlaceholderSelected) {
                this.removePaymentMethodFlow.save({
                    paymentMethodToRemove: this.paymentMethodToRemove,
                    replacementMethod: this.selectedReplacementMethod
                });
                this.pageLoadComplete = false;
                this.handleApiCalls().subscribe({
                    next: () => {
                        this.navigateToConfirmation();
                    },
                    error: (error) => {
                        this.logger.error('API ERROR: DELETE SAVED PAYMENT METHOD', error);
                        this.removePaymentMethodFlow.save({ apiCallsSuccessful: false });
                        this.navigateToConfirmation();
                        return of(null);
                    }
                });
            } else if (this.formGroup.selection === 'no') {
                this.cancelRemovePaymentMethod();
            }
        }
    }

    cancelRemovePaymentMethod(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences(
                {
                    accountNumber: this.session.billingAccountNumber,
                    sessionId: this.session.a2aSessionId
                }
            )
        );
    }

    navigateToConfirmation(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.REMOVE.CONFIRMATION(
                this.urlUtil.hashParamsString
            )
        );
    }

    handleApiCalls(): Observable<RemovePaymentMethodLandingResponse> {
        const updateObservable = this.shouldCallUpdate ? this.updateDefaultPaymentMethod() : this.requestPlaceholder();
        return updateObservable
            .pipe(
                flatMap((updateDefaultPaymentMethodResponse) =>
                    forkJoin({
                        deleteSavedPaymentMethodResponse: this.deleteSavedPaymentMethod(),
                        updateDefaultPaymentMethodResponse: of(updateDefaultPaymentMethodResponse)
                    })
                )
            );
    }

    get shouldCallUpdate(): boolean {
        return this.isSelectedMethodDefault &&
            this.savedPaymentMethods &&
            this.savedPaymentMethods.length > 1;
    }

    private deleteSavedPaymentMethod(): Observable<IsbExperience.DeleteSavedPaymentMethodResponse> {
        return this.isbExperienceApi.deleteSavedPaymentMethod({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            billingAccountNumber: this.session.billingAccountNumber,
            ecn: this.session.ecn,
            methodID: this.paymentMethodToRemove.id,
            relatedPaymentMethodIDs: this.paymentMethodToRemove.relatedPaymentMethodIDs,
            omsServiceTransformationStrategyData: {
                servicePlusIndicator: this.session.dpimServicePlusIndicator,
                agentPhoneNumber: this.session.dpimAgentPhoneNumber
            }
        })
            .pipe(
                tap((response) => this.removePaymentMethodFlow.save({
                    apiCallsSuccessful: true,
                    apiResponseCode: response.responseCode
                })),
                timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences)
            );
    }

    private updateDefaultPaymentMethod(): Observable<IsbExperience.SavedPaymentMethod> {
        const paymentItem = this.removePaymentMethodFlow.replacementMethod;

        return this.isbExperienceApi.putSavedPaymentMethod({
            billingSystem: this.session.billingSystem,
            accessToken: this.session.accessToken,
            methodID: paymentItem.id,
            relatedPaymentMethodIDs: paymentItem.relatedPaymentMethodIDs,

            payload: {
                billingPayload: {
                    billingAccountNumber: this.session.billingAccountNumber,
                    description: '',
                    enterpriseCustomerNumber: this.session.ecn,
                    preferredMethod: true,
                    recurringMethod: paymentItem.recurringMethod,
                    payorInfo: this.fetchPayorInfo(paymentItem),
                    paymentMethodType: paymentItem.isBankCard ?
                        'BankCard.SavedPaymentMethod' : 'ElectronicFundsTransfer.SavedPaymentMethod',

                    bankCard: paymentItem.assertIsBankCard(paymentItem.item) ? {
                        expirationDate: this.datePipe.transform(paymentItem.item.cardExpiration, 'yyyyMM'),
                        ccLastFour: paymentItem.item.cardNumberLastFour,
                        cardBrand: updatePaymentCardTypeMap.get(paymentItem.item.cardType)
                    } : undefined,

                    electronicFundsTransfer: paymentItem.assertIsBankAccount(paymentItem.item) ? {
                        bankAccountType: paymentItem.item.accountType.toUpperCase(),
                        bankName: paymentItem.item.bankName,
                        bankRoutingNumber: paymentItem.item.routingNumber,
                        bankAccountNumber: paymentItem.item.encryptedAccountNumber,
                        maskedBankAccountNumber: paymentItem.item.maskedBankAccountNumber,
                        encryptedBankAccountNumber: paymentItem.item.encryptedAccountNumber
                    } : undefined
                }
            }
        }).pipe(
            timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences)
        );
    }

    private fetchPayorInfo(paymentItem: PaymentItem): PayorInfo {
        let payorInfo: PayorInfo;
        if (paymentItem.assertIsBankAccount(paymentItem.item)) {
            const { item } = paymentItem;
            payorInfo = {
                firstName: item.firstName,
                middleName: item.middleInitial,
                lastName: item.lastName,
                displayName: item.middleInitial ?
                    `${item.firstName} ${item.middleInitial} ${item.lastName}` :
                    `${item.firstName} ${item.lastName}`,
                addressLine1: item.street,
                addressLine2: item.apartmentNumber,
                city: item.city,
                state: item.state,
                postalCode: item.zip,
                emailAddress: '',
                phone: ''
            };
        } else if (paymentItem.assertIsBankCard(paymentItem.item)) {
            const { item } = paymentItem;
            payorInfo = {
                firstName: item.firstName,
                middleName: item.middleInitial,
                lastName: item.lastName,
                displayName: item.middleInitial ?
                    `${item.firstName} ${item.middleInitial} ${item.lastName}` :
                    `${item.firstName} ${item.lastName}`,
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                postalCode: item.zipCode,
                emailAddress: '',
                phone: ''
            };
        }
        return payorInfo;
    }

    private requestPlaceholder(): Observable<null> {
        return of(null);
    }

    get placeholderPaymentItem(): PaymentItem {
        return new PaymentItem(new PlaceholderPaymentItem('Select'));
    }

    get isPlaceholderSelected(): boolean {
        return !!this.selectedReplacementMethod && this.selectedReplacementMethod.isPlaceholder;
    }

    get displayName(): string {
        return this.paymentMethodToRemove && this.paymentMethodToRemove.managePaymentPrefsDisplayName;
    }

    get displayMessageForForm(): string {
        const defaultText = this.isSelectedMethodDefault ? 'default ' : '';
        return `Are you sure you want to remove the ${defaultText}payment method: ${this.displayName}?`;
    }

    get globalErrorDisplayable(): boolean {
        return (!this.formGroup.group.valid || this.isPlaceholderSelected) &&
            this.submitted;
    }

    get isSelectedMethodDefault(): boolean {
        return this.paymentMethodToRemove && this.paymentMethodToRemove.isDefault;
    }
}

export interface RemovePaymentMethodLandingResponse {
    deleteSavedPaymentMethodResponse: IsbExperience.DeleteSavedPaymentMethodResponse | HttpErrorResponse;
    updateDefaultPaymentMethodResponse: IsbExperience.SavedPaymentMethod | HttpErrorResponse;
}
