import { MaskAbstractControl } from '../../../quick-pay/model/mask-abstract-control.model';
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';

function matchesMaskPatternValidator(params: { pattern: RegExp; errorName: string }): any {
    return (control: MaskAbstractControl): { [key: string]: any } | null => {
        const passesPattern = control.unMaskedValue ? params.pattern.test(control.unMaskedValue) : params.pattern.test(control.value);
        const validationObject = {};
        validationObject[params.errorName] = control.unMaskedValue ? { value: control.unMaskedValue } : { value: control.value };
        return passesPattern ? null : validationObject;
    };
}

export function isYearValid(year: number): boolean {
    const oneThousandAD = 1000;
    const threeThousandAD = 3000;
    return !(year < oneThousandAD || year > threeThousandAD);
}

export function isMonthValid(month: number): boolean {
    const monthsInYear = 12;
    return !(month < 1 || month > monthsInYear);
}

export function isDayValid(month: number, day: number, year: number): boolean {
    const daysInJan = 31;
    const daysInFeb = 28;
    const daysInMar = 31;
    const daysInApr = 30;
    const daysInMay = 31;
    const daysInJune = 30;
    const daysInJuly = 31;
    const daysInAug = 31;
    const daysInSept = 30;
    const daysInOct = 31;
    const daysInNov = 30;
    const daysInDec = 31;
    const monthLength = [daysInJan, daysInFeb, daysInMar, daysInApr, daysInMay, daysInJune, daysInJuly, daysInAug, daysInSept, daysInOct, daysInNov, daysInDec];

    let validDay = false;
    dayjs.extend(isLeapYear);
    if (dayjs(`${year}-01-01`).isLeapYear()) {
        const daysInFebLeapYear = 29;
        monthLength[1] = daysInFebLeapYear;
    }

    if (day > 0 && day <= monthLength[month - 1]) {
        validDay = true;
    }
    return validDay;
}

export const QuickPayValidators = {

    checkDOBFormat: matchesMaskPatternValidator({
        pattern: /^(?:(0[1-9]|1[012]|[1-9])\/([123]0|[012][1-9]|31|[1-9])\/([0-9]{4}))$/,
        errorName: 'checkDOBFormat'
    }),

    checkDobValidity: (control: MaskAbstractControl): { [key: string]: any } | null => {
        let validYear = false;
        let validMonth = false;
        let validDay = false;
        if (control.unMaskedValue) {
            const parts = control.unMaskedValue.split('/');
            const day = parseInt(parts[1], 10);
            const month = parseInt(parts[0], 10);
            const year = parseInt(parts[2], 10);

            validYear = isYearValid(year);
            validMonth = isMonthValid(month);
            validDay = isDayValid(month, day, year);
            return control.unMaskedValue && validYear && validMonth && validDay ? null : { checkDobValidity: { value: control.unMaskedValue } };
        } else if (control.value) {
            const parts = control.value.split('/');
            const day = parseInt(parts[1], 10);
            const month = parseInt(parts[0], 10);
            const year = parseInt(parts[2], 10);

            validYear = isYearValid(year);
            validMonth = isMonthValid(month);
            validDay = isDayValid(month, day, year);
            return control.value && validYear && validMonth && validDay ? null : { checkDobValidity: { value: control.value } };
        }
    },

    isFutureDate: (control: MaskAbstractControl): { [key: string]: any } | null => {
        let dateDiff = 0;
        if (control.unMaskedValue) {
            dateDiff = new Date(control.unMaskedValue).getTime() - Date.now();
        }

        return dateDiff > 0 ? { isFutureDate: { value: control.unMaskedValue } } : null;
    }

};
