import { EBIEventType } from './ebi-model';

/* Default Ebi Fields
ebiData = {
      BROWSER_TYPE: this.getBrowser()[0],
      BROWSER_VERSION: this.getBrowser()[1],
      DESCRIPTION: eventDescription.substring(0, descriptionLimit),
      DEVICE_TYPE: this.getPlatformType(),
      ECN: this.session.ecn,
      GUID: this.session.guid,
      IDENTIFIER: this.session.billingAccountNumber,
      IP_ADDRESS: '10.10.00.0',
      JSESSION_ID: this.session.a2aSessionId,
      OS_TYPE: this.getOperatingSystem(),
      OS_VERSION: this.getOperatingSystemVersion(),
      PREVIOUS_URL: this.routingState.getPreviousUrl(),
      STATE: this.session.stateValue,
      TEST_LEARN: 'B',
      USER_ACCESS_LVL: 'View',
      USER_ID: this.session.userId,
      406800076

      BROWSER_TYPE: this.getBrowser()[0],
      BROWSER_VERSION: this.getBrowser()[1],
      DESCRIPTION: eventDescription.substring(0, descriptionLimit),
      DEVICE_TYPE: this.getPlatformType(),
      ECN: this.session.ecn,
      FULLPOLICYNB: this.session.billingAccountNumber,
      GUID: this.session.guid,
      IP_ADDRESS: '10.10.00.0',
      JSESSION_ID: this.session.a2aSessionId,
      OS_TYPE: this.getOperatingSystem(),
      OS_VERSION: this.getOperatingSystemVersion(),
      PAGE_URL: this.routingState.getPreviousUrl(),
      SOURCE_SYSTEM:
      STATE: this.session.stateValue,
      TEST_LEARN: 'B',
      USER_ACCESS_LVL: 'View',
      USER_ID: this.session.userId,
      406800211
}
*/
export interface EbiEventProperty {
    event: EBIEventType;
    triggerEbi?: boolean;
    ebiEventId?: string;
    ebiEventDescription?: string;
    additionalEbiFields?: string[];
    isMobile?: boolean;
    paymentMethod?: PaymentMethodData[];
}

export interface GlobalNavEventProperty {
    event: string;
    triggerEbi?: boolean;
    ebiEventId?: string;
    ebiEventDescription?: string;
    additionalEbiFields?: string[];
}

export const EBI_EVENT_PROPERTIES: { eventMap: Map<EBIEventType, EbiEventProperty>; pageChangeTypes: any } = {
    eventMap: new Map<EBIEventType, EbiEventProperty>()
        .set(EBIEventType.BILLING_DETAILS, {
            event: EBIEventType.BILLING_DETAILS,
            triggerEbi: true,
            ebiEventId: '406800076',
            ebiEventDescription: 'Number of customers with Billing Accounts',
            additionalEbiFields: ['IDENTIFIER', 'PREVIOUS_URL', 'IP_ADDRESS', 'JSESSION_ID', 'USER_ID', 'DEVICE_TYPE', 'GUID', 'STATE']
        })
        .set(EBIEventType.QUESTIONS_AND_TIPS, {
            event: EBIEventType.QUESTIONS_AND_TIPS,
            triggerEbi: true,
            ebiEventId: '406800301',
            ebiEventDescription: 'Number of users who select the Questions and Tips tile on one of the following pages Billing Details, Bills and Payments, Payment Preferences',
            additionalEbiFields: ['IDENTIFIER', 'PREVIOUS_URL', 'IP_ADDRESS', 'JSESSION_ID', 'USER_ID', 'DEVICE_TYPE', 'GUID', 'STATE']
        })
        .set(EBIEventType.PAYMENT_PREFERENCES_PAGE_LOAD_NO_SAVED_PAYMENT_METHODS, {
            event: EBIEventType.PAYMENT_PREFERENCES_PAGE_LOAD_NO_SAVED_PAYMENT_METHODS,
            triggerEbi: true,
            ebiEventId: '406800082',
            ebiEventDescription: 'PMOF landing page loads and the user has no saved payment method.',
            additionalEbiFields: ['ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        })
        .set(EBIEventType.PAYMENT_PREFERENCES_PAGE_LOAD_SAVED_PAYMENT_METHODS, {
            event: EBIEventType.PAYMENT_PREFERENCES_PAGE_LOAD_SAVED_PAYMENT_METHODS,
            triggerEbi: true,
            ebiEventId: '406800083',
            ebiEventDescription: 'PMOF landing page loads and the user has saved payment method.',
            additionalEbiFields: ['ECN', 'GUID', 'IP_ADDRESS', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        })
        .set(EBIEventType.DEVICE_DETAILS, {
            event: EBIEventType.DEVICE_DETAILS,
            triggerEbi: true,
            ebiEventId: '406800211',
            ebiEventDescription: 'Capture Device, Browser and OS details on landing Servicing Page for the user session',
            additionalEbiFields: ['FULLPOLICYNB', 'SOURCE_SYSTEM', 'PAGE_URL', 'IP_ADDRESS', 'JSESSION_ID', 'USER_ID', 'DEVICE_TYPE', 'GUID', 'STATE']
        })
        .set(EBIEventType.VIEW_PDF_STATEMENT, {
            event: EBIEventType.VIEW_PDF_STATEMENT,
            triggerEbi: true,
            ebiEventId: '406800078',
            ebiEventDescription: 'Count of how many times a billing PDF statement was accessed from the Billing Details page',
            additionalEbiFields: ['SOURCE_SYSTEM', 'PRODUCT_LINE', 'STATEMENT_TYPE', 'VIEWBILL']
        })
        .set(EBIEventType.LOAD_PDF_STATEMENT, {
            event: EBIEventType.LOAD_PDF_STATEMENT,
            triggerEbi: true,
            ebiEventId: '406800303',
            ebiEventDescription: 'Tracks the number of times the customer accesses the billing statement PDF from Bills and Payments page',
            additionalEbiFields: ['SOURCE_SYSTEM', 'PRODUCT_LINE', 'PREVIOUS_URL', 'IDENTIFIER', 'STATEMENT_TYPE', 'IP_ADDRESS', 'JSESSION_ID', 'USER_ID', 'DEVICE_TYPE', 'GUID', 'STATE']
        })
        .set(EBIEventType.PRINTER_LINK_CLICKED_ID, {
            event: EBIEventType.PRINTER_LINK_CLICKED_ID,
            triggerEbi: true,
            ebiEventId: '406800080',
            ebiEventDescription: 'Track the number of times that the customer selects the Printer friendly link from the billing details page',
            additionalEbiFields: ['SOURCE_SYSTEM', 'PRODUCT_LINE', 'PREVIOUS_URL', 'JSESSION_ID', 'STATE', 'GUID', 'DEVICE_TYPE', 'PRINT']
        })
        .set(EBIEventType.PRINT_LINK_CONFIRMATION_CLICKED, {
            event: EBIEventType.PRINT_LINK_CONFIRMATION_CLICKED,
            triggerEbi: true,
            ebiEventId: '406800122',
            ebiEventDescription: 'Number of customers who print the confirmation page',
            additionalEbiFields: ['SOURCE_SYSTEM', 'DEVICE_TYPE', 'STATE', 'GUID', 'JSESSION_ID', 'PRINT', 'IDENTIFIER']
        })
        .set(EBIEventType.ON_PAYMENT_CAP_ERROR, {
            event: EBIEventType.ON_PAYMENT_CAP_ERROR,
            triggerEbi: true,
            ebiEventId: '406800298',
            ebiEventDescription: 'Customers who attempted to submit a payment exceeding the payment cap',
            additionalEbiFields: ['AGREEMENT_STATUS', 'BILLING_ACCOUNTNB', 'CLIENT_TYPE', 'PAYMENT_DATE', 'PAYMENT_AMOUNT', 'PAYMENT_TYPE', 'SOURCE_SYSTEM', 'FULLPOLICYNB', 'PREVIOUS_URL', 'JSESSION_ID', 'DEVICE_TYPE', 'USER_ID', 'STATE', 'GUID']
        })
        .set(EBIEventType.ON_CLICK_MAKE_PAYMENT_BTN, {
            event: EBIEventType.ON_CLICK_MAKE_PAYMENT_BTN,
            triggerEbi: true,
            ebiEventId: '406800077',
            ebiEventDescription: 'Track customers that have landed on the Make a Payment page when coming from Billing Details.',
            additionalEbiFields: ['PREVIOUS_URL', 'IDENTIFIER', 'IP_ADDRESS', 'JSESSION_ID', 'USER_ID', 'GUID', 'STATE', 'RESPONSE_CODE', 'RESPONSE_STATUS']
        }).set(EBIEventType.ON_SUBMIT_OF_FULLPAY_PAYMENT_PLAN, {
            event: EBIEventType.ON_SUBMIT_OF_FULLPAY_PAYMENT_PLAN,
            triggerEbi: true,
            ebiEventId: '406800372',
            ebiEventDescription: 'User selects the Submit button to switch from Monthly to Full Pay',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'SOURCE_SYSTEM', 'PREVIOUS_URL', 'JSESSION_ID', 'DEVICE_TYPE', 'USER_ID', 'STATE', 'GUID', 'USER_ACCESS_LVL', 'PAY_PLAN_TYPE', 'METHOD_TYPE', 'ECN', 'RESPONSE_CODE', 'RESPONSE_STATUS']
        })
        .set(EBIEventType.ON_CLICK_CONTINUE_MAKE_PAYMENT_PAGE, {
            event: EBIEventType.ON_CLICK_CONTINUE_MAKE_PAYMENT_PAGE,
            triggerEbi: true,
            ebiEventId: '406800118',
            ebiEventDescription: 'Number of customers that complete the input page of the Make a Payment flow',
            additionalEbiFields: ['ACCOUNTTYPE', 'BILLING_ACCOUNT_NUMBER', 'BILLING_STATE', 'BROWSER_TYPE', 'BROWSER_VERSION', 'CLIENT_TYPE', 'DEFAULT_METHOD', 'DEVICE_TYPE', 'CREDITCARDVENDOR', 'DESCRIPTION', 'EASY_PAY', 'ECN', 'FIRST_NAME', 'LAST_NAME', 'IDENTIFIER', 'JSESSION_ID', 'PAPERLESS', 'PAYMENT_OPTION', 'PAYMENTAMOUNT', 'PAYMENTTYPE', 'PREVIOUS_URL', 'PRODUCT_LINE', 'STATE', 'USER_ID', 'GUID', 'SOURCE_SYSTEM', 'PLAID']
        })
        .set(EBIEventType.ON_LOAD_MAKE_PAYMENT_PAGE, {
            event: EBIEventType.ON_LOAD_MAKE_PAYMENT_PAGE,
            triggerEbi: true,
            ebiEventId: '406800116',
            ebiEventDescription: 'Number of customers who want to pay their bill (land on first page of the Make a Payment flow)',
            additionalEbiFields: ['SOURCE_SYSTEM', 'PREVIOUS_URL', 'JSESSION_ID', 'USER_ID', 'STATE', 'GUID', 'ECN', 'IDENTIFIER']
        })
        .set(EBIEventType.ON_CLICK_OF_PAYMENT_REVIEW_PAGE, {
            event: EBIEventType.ON_CLICK_OF_PAYMENT_REVIEW_PAGE,
            triggerEbi: true,
            ebiEventId: '406800120',
            ebiEventDescription: 'Number of customers that review their payment information',
            additionalEbiFields: ['AUTOMATIC_PAYMENT_SETUP', 'CLIENT_TYPE', 'DEFAULT_METHOD', 'DESCRIPTION', 'ECN', 'FIRST_NAME', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'LAST_NAME', 'NEXT_PAYMENT_AMOUNT', 'NEXT_PAYMENT_DATE', 'PAPERLESS', 'PAYMENT_DATE', 'PAYMENTAMOUNT', 'PAYNOW_PAYLATER', 'PREVIOUS_URL', 'STATE', 'USER_ACCESS_LVL', 'USER_ID']
        })
        .set(EBIEventType.ON_LAND_CONFIRMATION_PAGE_OF_THE_MAKE_A_PAYMENT_FLOW, {
            event: EBIEventType.ON_LAND_CONFIRMATION_PAGE_OF_THE_MAKE_A_PAYMENT_FLOW,
            triggerEbi: true,
            ebiEventId: '406800121',
            ebiEventDescription: 'Number of customers who land on the Confirmation page of the Make a Payment flow',
            additionalEbiFields: ['AGREEMENT_STATUS', 'BROWSER_TYPE', 'BROWSER_VERSION', 'DESCRIPTION', 'DEVICE_TYPE', 'CLIENT_TYPE', 'NEXT_PAYMENT_DATE', 'NEXT_PAYMENT_AMOUNT', 'ECN', 'GUID', 'FULLPOLICYNB', 'IDENTIFIER', 'OS_TYPE', 'OS_VERSION', 'PAYMENT_CONFIRMATION_NUMBER', 'PAYMENT_DATE', 'PAYMENTAMOUNT', 'PAYMENTTYPE', 'PRODUCT_LINE', 'STATE', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'USER_ACCESS_LVL', 'USER_ID', 'JSESSION_ID', 'PRODUCT_TYPE', 'PLAID', 'Bank_Verification_Code']
        })
        .set(EBIEventType.ON_PAYMENT_AMOUNT_NOTIFICATION, {
            event: EBIEventType.ON_PAYMENT_AMOUNT_NOTIFICATION,
            triggerEbi: true,
            ebiEventId: '406800250',
            ebiEventDescription: 'Track number of customers who receive the partial payment warning notification message on MAP landing page',
            additionalEbiFields: ['AGREEMENT_STATUS', 'JSESSION_ID', 'SOURCE_SYSTEM', 'STATE', 'USER_ID']
        })
        .set(EBIEventType.ON_SMART_MILES_LINK_CLICK, {
            event: EBIEventType.ON_SMART_MILES_LINK_CLICK,
            triggerEbi: true,
            ebiEventId: '406800276',
            ebiEventDescription: 'User has launched to Telematics page from Policy Servicing',
            additionalEbiFields: ['SOURCE_SYSTEM', 'STATE', 'IDENTIFIER', 'PREVIOUS_URL', 'IP_ADDRESS', 'USER_ID', 'GUID', 'FULLPOLICYNB']
        })
        .set(EBIEventType.BILLING_TIMELINE_LOAD, {
            event: EBIEventType.BILLING_TIMELINE_LOAD,
            triggerEbi: true,
            ebiEventId: '406800267',
            ebiEventDescription: 'Event will trigger on load of Billing Details page',
            additionalEbiFields: [
                'DEVICE_TYPE',
                'FULLPOLICYNB',
                'GUID',
                'IDENTIFIER',
                'IP_ADDRESS',
                'PREVIOUS_URL',
                'SOURCE_SYSTEM',
                'STATE',
                'USER_ID'
            ]
        })
        .set(EBIEventType.ON_LOAD_MAKE_PAYMENT_PAGE_WITH_PMOF, {
            event: EBIEventType.ON_LOAD_MAKE_PAYMENT_PAGE_WITH_PMOF,
            triggerEbi: true,
            ebiEventId: '406800117',
            ebiEventDescription: 'Track number of customers who want to pay their bill using preferences.',
            additionalEbiFields: ['GUID', 'IDENTIFIER', 'JSESSION_ID', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'USER_ID']
        })
        .set(EBIEventType.ON_LOAD_PAYMENT_CONFIRMATION_PAGE_WITH_SCHEDULED_FUTURE_PAYMENT, {
            event: EBIEventType.ON_LOAD_PAYMENT_CONFIRMATION_PAGE_WITH_SCHEDULED_FUTURE_PAYMENT,
            triggerEbi: true,
            ebiEventId: '406800231',
            ebiEventDescription: 'Track number of customers who received confirmation of a scheduled future payment',
            additionalEbiFields: ['ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAYMENT_DATE', 'PAYMENTAMOUNT', 'PREVIOUS_URL', 'STATE', 'USER_ACCESS_LVL', 'USER_ID', 'AGREEMENT_STATUS', 'CLIENT_TYPE', 'FULLPOLICYNB', 'PAYMENT_CONFIRMATION_NUMBER']
        })
        .set(EBIEventType.ON_LOAD_OF_QUICK_PAY_SEARCH_PAGE, {
            event: EBIEventType.ON_LOAD_OF_QUICK_PAY_SEARCH_PAGE,
            triggerEbi: true,
            ebiEventId: '406800232',
            ebiEventDescription: 'User for the load of Quick Pay Search page',
            additionalEbiFields: ['BROWSER_TYPE', 'BROWSER_VERSION', 'DESCRIPTION', 'DEVICE_TYPE', 'ECN', 'IP_ADDRESS', 'JSESSION_ID', 'PREVIOUS_URL', 'TEST_LEARN', 'USER_ACCESS_LVL']
        })
        .set(EBIEventType.ON_LOAD_OF_QUICK_PAY_PICKER_PAGE, {
            event: EBIEventType.ON_LOAD_OF_QUICK_PAY_PICKER_PAGE,
            triggerEbi: true,
            ebiEventId: '406800259',
            ebiEventDescription: 'Event will provide number of times the Select Policy page is loaded for QuickPay',
            additionalEbiFields: ['BROWSER_TYPE', 'BROWSER_VERSION', 'DESCRIPTION', 'DEVICE_TYPE', 'ECN', 'FULLPOLICYNB', 'IP_ADDRESS', 'JSESSION_ID', 'PHONENB', 'PREVIOUS_URL', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_CLICK_CONTINUE_OF_QUICK_PAY_PICKER_PAGE, {
            event: EBIEventType.ON_CLICK_CONTINUE_OF_QUICK_PAY_PICKER_PAGE,
            triggerEbi: true,
            ebiEventId: '406800260',
            ebiEventDescription: 'Event will track the number of users fail/success to continue Select Policy page',
            additionalEbiFields: ['BROWSER_TYPE', 'BROWSER_VERSION', 'CLIENT_TYPE', 'DESCRIPTION', 'DEVICE_TYPE', 'DOB', 'ECN', 'FULLPOLICYNB', 'IP_ADDRESS', 'JSESSION_ID', 'PHONENB', 'PAYMENT_AMOUNT', 'PREVIOUS_URL', 'PRODUCT_LINE', 'SOURCE_SYSTEM', 'TEST_LEARN', 'USER_ACCESS_LVL']
        })
        .set(EBIEventType.BILLING_TIMELINE_LOAD, {
            event: EBIEventType.BILLING_TIMELINE_LOAD,
            triggerEbi: true,
            ebiEventId: '406800267',
            ebiEventDescription: 'Event will trigger on load of Billing Details page',
            additionalEbiFields: [
                'DEVICE_TYPE',
                'FULLPOLICYNB',
                'GUID',
                'IDENTIFIER',
                'IP_ADDRESS',
                'PREVIOUS_URL',
                'SOURCE_SYSTEM',
                'STATE',
                'USER_ID'
            ]
        })
        .set(EBIEventType.LOG_NUMBER_OF_USERS_COMING_IN_BILLING_VIA_THE_BILLING_TIMELINE_URL, {
            event: EBIEventType.LOG_NUMBER_OF_USERS_COMING_IN_BILLING_VIA_THE_BILLING_TIMELINE_URL,
            triggerEbi: true,
            ebiEventId: '406800340',
            ebiEventDescription: 'Log event when user types or selects nationwide.com/billdetails url and successfully authenticate',
            additionalEbiFields: [
                'BROWSER_TYPE',
                'BROWSER_VERSION',
                'DESCRIPTION',
                'DEVICE_TYPE',
                'ECN',
                'FULLPOLICYNB',
                'GUID',
                'IDENTIFIER',
                'IP_ADDRESS',
                'OS_TYPE',
                'OS_VERSION',
                'PREVIOUS_URL',
                'SOURCE_SYSTEM',
                'STATE',
                'TEST_LEARN',
                'USER_ACCESS_LVL',
                'USER_ID'
            ]
        })
        .set(EBIEventType.ON_OPEN_TIMELINE_EVENTS_MODAL, {
            event: EBIEventType.ON_OPEN_TIMELINE_EVENTS_MODAL,
            triggerEbi: true,
            ebiEventId: '406800331',
            ebiEventDescription: 'The number of times the events modal window on billing timeline page is loaded.',
            additionalEbiFields: ['DEVICE_TYPE', 'FULLPOLICYNB', 'GUID', 'IDENTIFIER', 'IP_ADDRESS', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'USER_ID']
        })
        .set(EBIEventType.ON_OPEN_TIMELINE_REFUNDS_MODAL, {
            event: EBIEventType.ON_OPEN_TIMELINE_REFUNDS_MODAL,
            triggerEbi: true,
            ebiEventId: '406800358',
            ebiEventDescription: 'This event will track the number of users that select the Refund Status link and continue to the Refund Information modal window',
            additionalEbiFields: ['DEVICE_TYPE', 'FULLPOLICYNB', 'GUID', 'IDENTIFIER', 'IP_ADDRESS', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'USER_ID']
        })
        .set(EBIEventType.PP_ADD_PAYMENT_METHOD_LANDING_ON_CONTINUE, {
            event: EBIEventType.PP_ADD_PAYMENT_METHOD_LANDING_ON_CONTINUE,
            triggerEbi: true,
            ebiEventId: '406800084',
            ebiEventDescription: 'Where customer can input what method of Payment to keep on File',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CLIENT_TYPE', 'CREDITCARDVENDOR', 'DEFAULT_METHOD', 'DESCRIPTION', 'ECN', 'FIRST_NAME', 'GUID', 'JSESSION_ID', 'LAST_NAME', 'PAPERLESS', 'PAYMENTTYPE', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        })
        .set(EBIEventType.PP_ADD_PAYMENT_METHOD_ON_REVIEW_LOAD, {
            event: EBIEventType.PP_ADD_PAYMENT_METHOD_ON_REVIEW_LOAD,
            triggerEbi: true,
            ebiEventId: '406800085',
            ebiEventDescription: 'Customer lands on the add payment method review page',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CREDITCARDVENDOR', 'DESCRIPTION', 'ECN', 'GUID', 'IP_ADDRESS', 'JSESSION_ID', 'PAYMENTTYPE', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        })
        .set(EBIEventType.PP_ADD_PAYMENT_METHOD_CONFIRMATION_LOAD, {
            event: EBIEventType.PP_ADD_PAYMENT_METHOD_CONFIRMATION_LOAD,
            triggerEbi: true,
            ebiEventId: '406800086',
            ebiEventDescription: 'This event counts users that reach the add payment method confirmation page',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IP_ADDRESS', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID', 'SOURCE_SYSTEM', 'RESPONSE_CODE', 'RESPONSE_STATUS']
        })
        .set(EBIEventType.PP_ADD_PAYMENT_REVIEW_ON_CLICK_SUBMIT, {
            event: EBIEventType.PP_ADD_PAYMENT_REVIEW_ON_CLICK_SUBMIT,
            triggerEbi: true,
            ebiEventId: '406800087',
            ebiEventDescription: 'This event counts users that select the Submit button on the add payment method review page',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        })
        .set(EBIEventType.PP_ADD_PAYMENT_CONFIRMATION_PRINT, {
            event: EBIEventType.PP_ADD_PAYMENT_CONFIRMATION_PRINT,
            triggerEbi: true,
            ebiEventId: '406800088',
            ebiEventDescription: 'Track the number of times that the customer selects to print the add payment method confirmation',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'PRINT', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        })
        .set(EBIEventType.PP_ADD_PAYMENT_CONFIRMATION_HARDFALL, {
            event: EBIEventType.PP_ADD_PAYMENT_CONFIRMATION_HARDFALL,
            triggerEbi: true,
            ebiEventId: '406800164',
            ebiEventDescription: 'On load of Hardfall page',
            additionalEbiFields: ['CHANGE_TYPE', 'DESCRIPTION', 'ECN', 'FULLPOLICYNB', 'GUID', 'IP_ADDRESS', 'JSESSION_ID', 'QUOTE_ID', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        })
        .set(EBIEventType.PP_REMOVE_NON_DEFAULT_PAYMENT_METHOD_CONFIRMATION, {
            event: EBIEventType.PP_REMOVE_NON_DEFAULT_PAYMENT_METHOD_CONFIRMATION,
            triggerEbi: true,
            ebiEventId: '406800089',
            ebiEventDescription: 'Track number of customers removing their payment method when the method is not the default.',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CREDITCARDVENDOR', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PAYMENTTYPE', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        })
        .set(EBIEventType.PP_REMOVE_DEFAULT_PAYMENT_METHOD_CONFIRMATION, {
            event: EBIEventType.PP_REMOVE_DEFAULT_PAYMENT_METHOD_CONFIRMATION,
            triggerEbi: true,
            ebiEventId: '406800090',
            ebiEventDescription: 'Track number of customers removing their payment method when the method is the default.',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CREDITCARDVENDOR', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PAYMENTTYPE', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.PP_REMOVE_PAYMENT_METHOD_CONFIRMATION, {
            event: EBIEventType.PP_REMOVE_PAYMENT_METHOD_CONFIRMATION,
            triggerEbi: true,
            ebiEventId: '406800091',
            ebiEventDescription: 'Track number of customers who received confirmation or failure on remove process',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID', 'SOURCE_SYSTEM', 'RESPONSE_CODE', 'RESPONSE_STATUS']
        }).set(EBIEventType.PP_ADD_REFUND_METHOD, {
            event: EBIEventType.PP_ADD_REFUND_METHOD,
            triggerEbi: true,
            ebiEventId: '406800248',
            ebiEventDescription: 'Track number of customers who successfully added a Refund Method',
            additionalEbiFields: ['ACCOUNT_TYPE', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ID']
        }).set(EBIEventType.ON_LOAD_OF_EDIT_PAYMENT_METHOD_LANDING_PAGE, {
            event: EBIEventType.ON_LOAD_OF_EDIT_PAYMENT_METHOD_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800093',
            ebiEventDescription: 'Customers selecting to edit payment method and capture the payment method edited',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CREDITCARDVENDOR', 'DESCRIPTION', 'ECN', 'FIRST_NAME', 'GUID', 'JSESSION_ID', 'LAST_NAME', 'PAYMENTTYPE', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_CLICK_OF_SUBMIT_EDIT_PAYMENT_METHOD_REVIEW_PAGE, {
            event: EBIEventType.ON_CLICK_OF_SUBMIT_EDIT_PAYMENT_METHOD_REVIEW_PAGE,
            triggerEbi: true,
            ebiEventId: '406800094',
            ebiEventDescription: 'Customer selects submit button on edit payment method review page ',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CREDITCARDVENDOR', 'DEFAULT_METHOD', 'DESCRIPTION', 'ECN', 'FIRST_NAME', 'GUID', 'JSESSION_ID', 'LAST_NAME', 'PAPERLESS', 'PAYMENTTYPE', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_LOAD_OF_EDIT_PAYMENT_METHOD_REVIEW_PAGE, {
            event: EBIEventType.ON_LOAD_OF_EDIT_PAYMENT_METHOD_REVIEW_PAGE,
            triggerEbi: true,
            ebiEventId: '406800095',
            ebiEventDescription: 'Customer lands on the edit payment method review page',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_LOAD_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PAGE, {
            event: EBIEventType.ON_LOAD_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PAGE,
            triggerEbi: true,
            ebiEventId: '406800096',
            ebiEventDescription: 'Customers who confirmed the Edit Preference',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_CLICK_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PRINT_BUTTON, {
            event: EBIEventType.ON_CLICK_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PRINT_BUTTON,
            triggerEbi: true,
            ebiEventId: '406800097',
            ebiEventDescription: 'Customer selects the edit payment method confirmation print button',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'PRINT', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_CLICK_OF_AUTHORIZATION_AGREEMENT_LINK, {
            event: EBIEventType.ON_CLICK_OF_AUTHORIZATION_AGREEMENT_LINK,
            triggerEbi: true,
            ebiEventId: '406800098',
            ebiEventDescription: 'Customers who viewed authoirization agreement form',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'PAYMENT_PLAN', 'TEST_LEARN', 'USER_ACCESS_LVL', 'AUTH_AGREE']
        }).set(EBIEventType.ON_CLICK_RECURRING_PATMENT_BTN, {
            event: EBIEventType.ON_CLICK_RECURRING_PATMENT_BTN,
            triggerEbi: true,
            ebiEventId: '406800373',
            ebiEventDescription: 'Number of customers who click Automatic Payments button on payment confirmation page',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_LOAD_EDIT_AUTOMATIC_PAYMENTS_LANDING_PAGE, {
            event: EBIEventType.ON_LOAD_EDIT_AUTOMATIC_PAYMENTS_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800104',
            ebiEventDescription: 'Number of customers who Edit Easypay',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CREDITCARDVENDOR', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'NEXTPAYMENT', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'SCHEDULED_DATE', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_LOAD_AUTOMATIC_PAYMENTS_LANDING_PAGE, {
            event: EBIEventType.ON_LOAD_AUTOMATIC_PAYMENTS_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800099',
            ebiEventDescription: 'Number of customers selecting to enroll in automatic payments',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE, {
            event: EBIEventType.ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE,
            triggerEbi: true,
            ebiEventId: '406800100',
            ebiEventDescription: 'Number of customers landing on the automatic payments enrollment review page and the type of payment enrolled with',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CREDITCARDVENDOR', 'DEFAULT_METHOD', 'DESCRIPTION', 'ECN', 'FIRST_NAME', 'LAST_NAME', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAPERLESS', 'PAYMENT_PLAN', 'PAYMENTTYPE', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_CLICK_OF_SUBMIT_BTN_EDIT_AUTOMATIC_PAYMENTS_REVIEW_PAGE, {
            event: EBIEventType.ON_CLICK_OF_SUBMIT_BTN_EDIT_AUTOMATIC_PAYMENTS_REVIEW_PAGE,
            triggerEbi: true,
            ebiEventId: '406800107',
            ebiEventDescription: 'Number of customers who completed EasyPay Process',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_LOAD_OF_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE, {
            event: EBIEventType.ON_LOAD_OF_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE,
            triggerEbi: true,
            ebiEventId: '406800108',
            ebiEventDescription: 'Number of customers who got confirmation/failure on Manage EasyPay',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_CLICK_OF_PRINT_BTN_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE, {
            event: EBIEventType.ON_CLICK_OF_PRINT_BTN_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE,
            triggerEbi: true,
            ebiEventId: '406800109',
            ebiEventDescription: 'Track the number of times that the customer selects the Print option from this page',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'PAYMENT_PLAN', 'PRINT', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE_AUTH_AGREEMENT, {
            event: EBIEventType.ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE_AUTH_AGREEMENT,
            triggerEbi: true,
            ebiEventId: '406800124',
            ebiEventDescription: 'Track the number of customers who agreed to the Authorization Form',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_COMPLETION_OF_EASYPAY_ENROLLMENT, {
            event: EBIEventType.ON_COMPLETION_OF_EASYPAY_ENROLLMENT,
            triggerEbi: true,
            ebiEventId: '406800101',
            ebiEventDescription: 'Track number of customers who completed the automatic payment enrollment process',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_SUCCESS_OR_FAIL_EASYPAY_ENROLLMENT, {
            event: EBIEventType.ON_SUCCESS_OR_FAIL_EASYPAY_ENROLLMENT,
            triggerEbi: true,
            ebiEventId: '406800103',
            ebiEventDescription: 'Number of customers who got confirmation/failure on enrollment into EasyPay',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_CLICK_PRINT_BTN_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE, {
            event: EBIEventType.ON_CLICK_PRINT_BTN_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE,
            triggerEbi: true,
            ebiEventId: '406800102',
            ebiEventDescription: 'Track the number of times that the customer selects the print button on the automatic payment enrollment confirmation page',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'PRINT', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_EDIT_OF_PAYMENT_METHOD_ON_AUTOMATIC_PAYMENT_LANDING_PAGE, {
            event: EBIEventType.ON_EDIT_OF_PAYMENT_METHOD_ON_AUTOMATIC_PAYMENT_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800106',
            ebiEventDescription: 'Number of customers who edit financial information or switch payment method for Easy Pay',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CREDITCARDVENDOR', 'DESCRIPTION', 'DRAFT_NOTIFICATION', 'ECN', 'GUID', 'JSESSION_ID', 'PAPERLESS', 'PAYMENT_PLAN', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_LANDING_PAGE, {
            event: EBIEventType.ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800206',
            ebiEventDescription: 'Count customers that go to the suspend landing page and the URL they came from',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'FIRST_NAME', 'LAST_NAME', 'MONTHLY_PAYMENT', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'IP_ADDRESS', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_CLICK_OF_CANCEL_IN_SUSPEND_EASY_PAY_LANDING_PAGE, {
            event: EBIEventType.ON_CLICK_OF_CANCEL_IN_SUSPEND_EASY_PAY_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800311',
            ebiEventDescription: 'Track the number of customers who cancel the suspend transaction',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'FIRST_NAME', 'LAST_NAME', 'MONTHLY_PAYMENT', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'IP_ADDRESS', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID', 'DEVICE_TYPE']
        }).set(EBIEventType.ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_CONFIRMATION_PAGE, {
            event: EBIEventType.ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_CONFIRMATION_PAGE,
            triggerEbi: true,
            ebiEventId: '406800309',
            ebiEventDescription: 'Track the number of customers who land on the confirmation page',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'FIRST_NAME', 'LAST_NAME', 'MONTHLY_PAYMENT', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'IP_ADDRESS', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID', 'DEVICE_TYPE', 'SOURCE_SYSTEM', 'RESPONSE_CODE', 'RESPONSE_STATUS']
        }).set(EBIEventType.ON_CLICK_OF_PRINT_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE, {
            event: EBIEventType.ON_CLICK_OF_PRINT_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE,
            triggerEbi: true,
            ebiEventId: '406800310',
            ebiEventDescription: 'Track the number of customers who print the Suspend confirmation page',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'FIRST_NAME', 'LAST_NAME', 'MONTHLY_PAYMENT', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'IP_ADDRESS', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID', 'DEVICE_TYPE']
        }).set(EBIEventType.ON_CLICK_OF_PAY_LATER_OR_MAP_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE, {
            event: EBIEventType.ON_CLICK_OF_PAY_LATER_OR_MAP_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE,
            triggerEbi: true,
            ebiEventId: '406800207',
            ebiEventDescription: 'Track customers that proceeded on in the suspend process from the landing page and capture whether they choose to pay now or pay later',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'PAYNOW_PAYLATER', 'FIRST_NAME', 'LAST_NAME', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSIONID', 'IP_ADDRESS', 'PAYMENT_PLAN', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_LANDING_PAGE, {
            event: EBIEventType.ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800110',
            ebiEventDescription: 'Number of customers who Cancel Easy Pay',
            additionalEbiFields: ['ACCOUNTTYPE', 'BANKNAME', 'CREDITCARDVENDOR', 'DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'NEXTPAYMENT', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'SCHEDULED_DATE', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_CLICK_SUBMIT_CANCEL_AUTOMATIC_PAYMENTS_LANDING, {
            event: EBIEventType.ON_CLICK_SUBMIT_CANCEL_AUTOMATIC_PAYMENTS_LANDING,
            triggerEbi: true,
            ebiEventId: '406800111',
            ebiEventDescription: 'Track number of customers who cancel EasyPay. Cancel Dialog Box',
            additionalEbiFields: ['CANCEL', 'DESCRIPTION', 'ECN', 'GUID', 'PAYMENT_PLAN', 'SCHEDULED_DATE', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_CLICK_OF_PRINT_BTN_CANCEL_EASY_PAY_CONFIRMATION_PAGE, {
            event: EBIEventType.ON_CLICK_OF_PRINT_BTN_CANCEL_EASY_PAY_CONFIRMATION_PAGE,
            triggerEbi: true,
            ebiEventId: '406800113',
            ebiEventDescription: 'Number of customers printing cancel EasyPay confirmation page',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PAYMENT_PLAN', 'PRINT', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_CONFIRMATION_PAGE, {
            event: EBIEventType.ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_CONFIRMATION_PAGE,
            triggerEbi: true,
            ebiEventId: '406800112',
            ebiEventDescription: 'Track number of customers who confirmed cancellation of EasyPay',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PAYMENT_PLAN', 'PREVIOUS_URL', 'RESPONSE_CODE', 'RESPONSE_STATUS', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_LOAD_CONFIRMATION_PAGE_CANCEL_SCHEDULED_PAYMENT, {
            event: EBIEventType.ON_LOAD_CONFIRMATION_PAGE_CANCEL_SCHEDULED_PAYMENT,
            triggerEbi: true,
            ebiEventId: '406800230',
            ebiEventDescription: 'Track customers that choose to cancel a scheduled payment',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'DESCRIPTION', 'ECN', 'FIRST_NAME', 'GUID', 'IP_ADDRESS', 'JSESSIONID', 'LAST_NAME', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID', 'RESPONSE_CODE', 'RESPONSE_STATUS']
        }).set(EBIEventType.ON_CLICK_CONTINUE_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD_LANDING_PAGE, {
            event: EBIEventType.ON_CLICK_CONTINUE_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800351',
            ebiEventDescription: 'Event will fire when user clicks Yes to save new payment method as refund method on landing page',
            additionalEbiFields: ['CURRENT_URL', 'DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ID']
        }).set(EBIEventType.ON_INCOMPLETE_FORM_ELEMENTS_IN_EFT_AUTH_FORM, {
            event: EBIEventType.ON_INCOMPLETE_FORM_ELEMENTS_IN_EFT_AUTH_FORM,
            triggerEbi: true,
            ebiEventId: '406800349',
            ebiEventDescription: 'Provide a count of the REFT forms that would have failed due to incomplete form data',
            additionalEbiFields: ['ACCOUNT_NUMBER', 'DESCRIPTION', 'AGENT_PHONE', 'AGENTNAME', 'BANKNAME', 'CITY',
                'DATE_OF_DEBIT', 'FIRST_NAME', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'LAST_NAME', 'PAYMENTAMOUNT',
                'ROUTING_NUMBER', 'SOURCE_SYSTEM', 'STATE', 'STREET_ADDRESS', 'TEST_LEARN', 'ZIP']
        }).set(EBIEventType.LOG_NUMBER_OF_TIMES_HP_EXTREME_SERVICE_INVOKING, {
            event: EBIEventType.LOG_NUMBER_OF_TIMES_HP_EXTREME_SERVICE_INVOKING,
            triggerEbi: true,
            ebiEventId: '406800350',
            ebiEventDescription: 'Count the Number of times to invoke the HP Extreme service',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'JSESSION_ID', 'PREVIOUS_URL', 'RESPONSE_CODE', 'RESPONSE_STATUS', 'SOURCE_SYSTEM', 'TEST_LEARN', 'USER_ACCESS_LVL']
        }).set(EBIEventType.ON_CLICK_OF_SUBMIT_BTN_REMOVE_REFUND_METHOD_LANDING_PAGE, {
            event: EBIEventType.ON_CLICK_OF_SUBMIT_BTN_REMOVE_REFUND_METHOD_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800249',
            ebiEventDescription: 'Tracks the number of customers who click Submit Button to Remove Refund Method',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ID']
        }).set(EBIEventType.ON_CLICK_OF_REMOVE_REFUND_METHOD_BTN, {
            event: EBIEventType.ON_CLICK_OF_REMOVE_REFUND_METHOD_BTN,
            triggerEbi: true,
            ebiEventId: '406800247',
            ebiEventDescription: 'Tracks the number of customers who click Remove Refund Method button',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ID']
        }).set(EBIEventType.ON_CONFIRMATION_LOAD_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD, {
            event: EBIEventType.ON_CONFIRMATION_LOAD_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD,
            triggerEbi: true,
            ebiEventId: '406800352',
            ebiEventDescription: 'Event will fire when user clicks Yes to save new payment method as refund method then Submit on Review page',
            additionalEbiFields: ['CURRENT_CHANGE_TYPE', 'CURRENT_URL', 'DESCRIPTION', 'ECN', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'PREVIOUS_URL', 'RESPONSE_CODE', 'RESPONSE_STATUS', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ID']
        }).set(EBIEventType.ON_CLICK_OF_ADD_REFUND_METHOD_BTN, {
            event: EBIEventType.ON_CLICK_OF_ADD_REFUND_METHOD_BTN,
            triggerEbi: true,
            ebiEventId: '406800246',
            ebiEventDescription: 'Tracks the number of customers who click Add Refund Method button',
            additionalEbiFields: ['DESCRIPTION', 'ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'TEST_LEARN', 'USER_ID']
        }).set(EBIEventType.ON_BANK_ACCOUNT_VERIFICATION_ERROR, {
            event: EBIEventType.ON_BANK_ACCOUNT_VERIFICATION_ERROR,
            triggerEbi: true,
            ebiEventId: '406800360',
            ebiEventDescription: 'Bank Verification service response',
            additionalEbiFields: ['Bank_Verification_Code', 'BROWSER_TYPE', 'CLIENT_TYPE', 'CURRENT_URL', 'DESCRIPTION', 'DEVICE_TYPE', 'ECN', 'FIRST_NAME', 'GUID', 'IDENTIFIER', 'JSESSION_ID', 'LAST_NAME', 'PREVIOUS_URL', 'RESPONSE_CODE', 'RESPONSE_STATUS', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_LOAD_CHANGE_DUE_DATE_CONFIRMATION_LANDING_PAGE, {
            event: EBIEventType.ON_LOAD_CHANGE_DUE_DATE_CONFIRMATION_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800367',
            ebiEventDescription: 'Track customers who have landed on the change due date confirmation page',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'BROWSER_TYPE', 'DESCRIPTION', 'DEVICE_TYPE', 'ECN', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'RESPONSE_CODE', 'RESPONSE_STATUS', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'UPDATED_DUE_DATE', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_CLICK_OF_CONTACT_US_LINK_LOGS_OUT_OF_ISB, {
            event: EBIEventType.ON_CLICK_OF_CONTACT_US_LINK_LOGS_OUT_OF_ISB,
            triggerEbi: true,
            ebiEventId: '406800363',
            ebiEventDescription: 'Tracks whenever the user goes out of ISB application ( click on contact us link)',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'BILLING_STATE', 'BROWSER_TYPE', 'BROWSER_VERSION', 'CURRENT_URL', 'DEVICE_TYPE', 'ECN', 'FIRST_NAME', 'GUID', 'JSESSION_ID', 'LAST_NAME', 'LOB', 'PREVIOUS_URL', 'PRODUCT_LINE', 'SOURCE_SYSTEM', 'STATE', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_SELECT_OF_THE_CONTACT_US_BUTTON_IN_THE_PAY_PLAN_SECTION, {
            event: EBIEventType.ON_SELECT_OF_THE_CONTACT_US_BUTTON_IN_THE_PAY_PLAN_SECTION,
            triggerEbi: true,
            ebiEventId: '406800371',
            ebiEventDescription: 'Eligible customers on Full Pay who select Contact us button',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'BROWSER_TYPE', 'BROWSER_VERSION', 'DESCRIPTION', 'DEVICE_TYPE', 'ECN', 'GUID', 'JSESSION_ID', 'METHOD_TYPE', 'OS_TYPE', 'OS_VERSION', 'PAY_PLAN_TYPE', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_SELECT_OF_THE_EDIT_PAYMENT_PLAN_BUTTON, {
            event: EBIEventType.ON_SELECT_OF_THE_EDIT_PAYMENT_PLAN_BUTTON,
            triggerEbi: true,
            ebiEventId: '406800370',
            ebiEventDescription: 'Track number of customers who select Edit payment plan button',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'BROWSER_TYPE', 'BROWSER_VERSION', 'DESCRIPTION', 'DEVICE_TYPE', 'ECN', 'GUID', 'JSESSION_ID', 'METHOD_TYPE', 'OS_TYPE', 'OS_VERSION', 'PAY_PLAN_TYPE', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_DISPLAY_OF_THE_PAYMENT_PLAN_SECTION, {
            event: EBIEventType.ON_DISPLAY_OF_THE_PAYMENT_PLAN_SECTION,
            triggerEbi: true,
            ebiEventId: '406800369',
            ebiEventDescription: 'Track number of customers who have the Payment plan section displayed',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'BROWSER_TYPE', 'BROWSER_VERSION', 'DESCRIPTION', 'DEVICE_TYPE', 'ECN', 'GUID', 'JSESSION_ID', 'METHOD_TYPE', 'OS_TYPE', 'OS_VERSION', 'PAY_PLAN_TYPE', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }).set(EBIEventType.ON_SUBMIT_OF_FULLPAY_PAYMENT_PLAN, {
            event: EBIEventType.ON_SUBMIT_OF_FULLPAY_PAYMENT_PLAN,
            triggerEbi: true,
            ebiEventId: '406800372',
            ebiEventDescription: 'User selects the Submit button to switch from Monthly to Full Pay',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'SOURCE_SYSTEM', 'PREVIOUS_URL', 'JSESSION_ID', 'DEVICE_TYPE', 'USER_ID', 'STATE', 'GUID', 'USER_ACCESS_LVL', 'PAY_PLAN_TYPE', 'METHOD_TYPE', 'ECN', 'RESPONSE_CODE', 'RESPONSE_STATUS']
        }).set(EBIEventType.ON_LOAD_CHANGE_DUE_DATE_REVIEW_AND_SUBMIT_PAGE, {
            event: EBIEventType.ON_LOAD_CHANGE_DUE_DATE_REVIEW_AND_SUBMIT_PAGE,
            triggerEbi: true,
            ebiEventId: '406800366',
            ebiEventDescription: 'Track number of customers who landed on change due date Review and Submit page',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'DEVICE_TYPE', 'GUID', 'JSESSION_ID', 'PREVIOUS_URL', 'STATE', 'USER_ID']
        }).set(EBIEventType.ON_LOAD_CHANGE_DUE_DATE_LANDING_PAGE, {
            event: EBIEventType.ON_LOAD_CHANGE_DUE_DATE_LANDING_PAGE,
            triggerEbi: true,
            ebiEventId: '406800365',
            ebiEventDescription: 'Track number of customers who have landed on the change due date Landing page',
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'BROWSER_TYPE', 'DESCRIPTION', 'DEVICE_TYPE', 'ECN', 'FIRST_NAME', 'GUID', 'JSESSION_ID', 'LAST_NAME', 'PREVIOUS_URL', 'SOURCE_SYSTEM', 'STATE', 'TEST_LEARN', 'USER_ACCESS_LVL', 'USER_ID']
        }),

    pageChangeTypes: {
        'Bills & Details': 'Bills & Details',
        'Bills & Payments': 'Bills & Payments',
        'Payment Preferences': 'Payment Preferences',
        'Questions and Tips': 'Questions and Tips'
    }
};

export const GLOBAL_NAV_EVENT_PROPERTIES: { listOfEvents: GlobalNavEventProperty[] } = {
    listOfEvents: [
        {
            event: 'globalNavigationAllAccounts',
            triggerEbi: true,
            ebiEventId: '406800341',
            ebiEventDescription: `On click of 'All accounts' tab`
        },
        {
            event: 'globalNavigationBillsMenu',
            triggerEbi: true,
            ebiEventId: '406800342',
            ebiEventDescription: `On click of 'Bills' tab`
        },
        {
            event: 'globalNavigationPoliciesMenu',
            triggerEbi: true,
            ebiEventId: '406800343',
            ebiEventDescription: `On click of 'Policies' tab`
        },
        {
            event: 'globalNavigationBillsChildOption',
            triggerEbi: true,
            ebiEventId: '406800344',
            ebiEventDescription: `On click of one of the children that is displayed in Bills`,
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER', 'DESTINATION_URL']
        },
        {
            event: 'globalNavigationPoliciesChildOption',
            triggerEbi: true,
            ebiEventId: '406800345',
            ebiEventDescription: `On click of one of the children that is displayed in Policies`,
            additionalEbiFields: ['DESTINATION_URL']
        },
        {
            event: 'globalNavigationPoliciesOption',
            triggerEbi: true,
            ebiEventId: '406800346',
            ebiEventDescription: `On click of initial 'Policies' tab`
        },
        {
            event: 'globalNavigationBillsOption',
            triggerEbi: true,
            ebiEventId: '406800347',
            ebiEventDescription: `On click of initial 'Bills' tab`,
            additionalEbiFields: ['BILLING_ACCOUNT_NUMBER']
        }
    ]
};
