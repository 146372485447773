import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SessionService } from '../../../../../../shared/session/session.service';
import { environment } from '../../../../../../../environments/environment';
import { Router } from '@angular/router';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { TitleCasePipe } from '@angular/common';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PLACEHOLDER_MESSAGES } from '../../../../shared/constants/cancel-automatic-payments-messages';
import { AreYouSureFormGroup } from '../../../../../../shared/payments/are-you-sure-radio-buttons/are-you-sure-form-group';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { CancelAutomaticPaymentsLandingService } from './cancel-automatic-payments-landing.service';
import { FormGroupDirective } from '@angular/forms';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { IsbExperience } from '@nationwide/dgs-angular-billing-common';
import { CancelAutomaticPaymentsFlowService } from '../shared/cancel-automatic-payments-flow.service';
import { BillingCommonNavigationsService } from '../../../../../shared/billing-common-navigations.service';
import { timeout } from 'rxjs';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

@Component({
    selector: 'app-cancel-automatic-payments-landing',
    templateUrl: './cancel-automatic-payments-landing.component.html',
    styleUrls: [
        '../../../../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../../../../../../shared/payments/stylesheets/form-formatting.scss',
        './cancel-automatic-payments-landing.component.scss'
    ]
})
export class CancelAutomaticPaymentsLandingComponent extends BasePageComponent implements OnInit {
    @ViewChild(FormGroupDirective) areYouSureFormDirective: FormGroupDirective;
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.cancel.landing;
    formGroup: AreYouSureFormGroup;
    billDueDate: Date;
    amountDue: string;
    loadComplete = false;
    automaticPayments: IsbExperience.GetPayPlanResponse;
    submitCallInProgress = false;
    paymentPlan: string;

    // eslint-disable-next-line max-params
    constructor(
        private formUtil: FormUtil,
        public router: Router,
        private urlUtil: UrlUtil,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private formBuilder: ManagePaymentPrefsFormBuilder,
        private easternTimeDatePipe: EasternTimeDatePipe,
        public session: SessionService,
        private landingService: CancelAutomaticPaymentsLandingService,
        private eventService: BillingEventService,
        private titleCase: TitleCasePipe,
        private cancelAutomaticPaymentsFlowService: CancelAutomaticPaymentsFlowService,
        private billingCommonNavigationsService: BillingCommonNavigationsService,
        @Inject('logger') private logger: LoggerService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setVariablesFromApiResponse();
    }

    setVariablesFromApiResponse(): void {
        this.setupFormGroup();
        this.nextSub = this.landingService.fetchLanding().pipe(
            timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences)
        ).subscribe({
            next: (response) => {
                const { automaticPayments, retrieveCustomerAgreement } = response.res;
                this.automaticPayments = automaticPayments;
                this.billDueDate = this.managePaymentPrefsAdapter.fetchBillingAccountStatementDueDate(retrieveCustomerAgreement);
                this.amountDue = this.managePaymentPrefsAdapter.fetchBillingAccountAmountDue(retrieveCustomerAgreement);
                this.paymentPlan = this.managePaymentPrefsAdapter.getPayPlanValueForEBI(retrieveCustomerAgreement);
                this.loadComplete = true;
                this.sendOnPageLoadEbiEvent();
            },
            error: (error) => {
                this.logger.error('HARDFALL: CANCEL AUTOMATIC PAYMENTS LOAD ERROR', { error });
                this.logger.logCurrentContents();
                this.billingCommonNavigationsService.navigateToHardfall();
            }
        });
    }

    setupFormGroup(): void {
        this.formGroup = this.formBuilder.newAreYouSureFormGroup();
    }

    get displayDueDate(): string {
        return this.billDueDate ? this.easternTimeDatePipe.transform(this.billDueDate, 'MM/dd/yyyy.') : '';
    }

    get dueAlertMessage(): string {
        return `${PLACEHOLDER_MESSAGES.BILL_DUE_ALERT} ${this.displayDueDate}`;
    }

    get warningMessage(): string {
        return PLACEHOLDER_MESSAGES.WARNING;
    }

    get billingAccountNumber(): string {
        return this.session.billingAccountNumber;
    }

    get displayMessageForForm(): string {
        return `Are you sure you want to cancel automatic payments for billing account ${this.billingAccountNumber}?`;
    }

    get submitted(): boolean {
        return !!this.areYouSureFormDirective && this.areYouSureFormDirective.submitted;
    }

    get globalErrorDisplayable(): boolean {
        return !this.formGroup.group.valid && this.submitted;
    }

    submit(): void {
        this.formUtil.touchAllFormControls(this.formGroup.group);

        if (this.formGroup.group.valid) {
            this.sendSubmitButtonClickedEbiEvent();

            if (this.formGroup.selection === 'yes') {
                this.submitCallInProgress = true;
                this.saveFormToFlow();
                this.landingService.submit()
                    .subscribe((response) => {
                        this.cancelAutomaticPaymentsFlowService.apiResponseCode = response.patchPayPlan.toString();
                        this.navigateToConfirmation();
                    });
            } else if (this.formGroup.selection === 'no') {
                this.cancelAutomaticPayment();
            }
        }
    }

    sendSubmitButtonClickedEbiEvent(): void {
        this.eventService.handleEvent(
            EBIEventType.ON_CLICK_SUBMIT_CANCEL_AUTOMATIC_PAYMENTS_LANDING,
            {
                yesNoSelection: <'Yes' | 'No'>(
                    this.titleCase.transform(this.formGroup.selection)
                ),
                paymentDate: this.displayDueDate,
                paymentPlan: this.paymentPlan
            }
        );
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_LANDING_PAGE, {
            paymentMethod: this.ebiPaymentMethodData,
            paymentPlan: this.paymentPlan
        });
    }

    get ebiPaymentMethodData(): PaymentMethodsData[] {
        const data = [];
        const paymentMethodType = this.automaticPayments.paymentMethod.paymentMethodType;
        const isBankAccount = paymentMethodType === 'ElectronicFundsTransfer.PaymentMethod';
        const isBankCard = paymentMethodType === 'BankCard.PaymentMethod';
        const accountType = isBankAccount ? this.automaticPayments.paymentMethod.electronicFundsTransfer.bankAccountType : 'NA';
        const bankName = isBankAccount ? this.automaticPayments.paymentMethod.electronicFundsTransfer.bankName : 'NA';
        const creditCardVendor = isBankCard ? this.automaticPayments.paymentMethod.bankCard.cardBrand : 'NA';
        const paymentType = isBankCard ? 'CC' : 'EFT';
        const nextPaymentAmount = this.amountDue;
        const paymentDate = this.displayDueDate;
        const stateEbi = this.session.state;

        data.push({
            accountType,
            bankName,
            creditCardVendor,
            paymentType,
            nextPaymentAmount,
            paymentDate,
            stateEbi
        });
        return data;
    }

    navigateToConfirmation(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.CANCEL.CONFIRMATION(
                this.urlUtil.hashParamsString
            )
        );
    }

    saveFormToFlow(): void {
        this.cancelAutomaticPaymentsFlowService.save({
            cancelAutomaticPaymentsForm: this.formGroup,
            paymentPlan: this.paymentPlan
        });
    }

    cancelAutomaticPayment(): void {
        this.cancelAutomaticPaymentsFlowService.clear();
        this.billingCommonNavigationsService.navigateToPaymentPreferences();
    }
}
