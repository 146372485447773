import { Injectable } from '@angular/core';
import { PaymentFormGroup } from '../../../../../shared/payments/payment-form-group';
import { PaperlessFlow } from '../../../shared/models/paperless-flow.model';
import { PaymentItem } from '../../../shared/models/payment-item.model';
import { EditPaymentMethodResponse } from './review/edit-payment-review.service';

@Injectable()
export class EditPaymentMethodFlowService implements PaperlessFlow {
    editPaymentMethodForm: PaymentFormGroup;
    considerThisDisplayable = false;
    emailAddress: string;
    selectedPaymentMethod: PaymentItem;
    selectedEasyPay: string;
    selectedPaperless: string;
    isEasyPayEligible: boolean;
    isPaperlessEligible: boolean;
    paymentMethodSaveAttempted = false;
    paymentMethodSaved = false;
    paperlessEnrollmentAttempted = false;
    savedPaperlessEsddaPrefs = false;
    enrolledInPaperless = false;
    editPaymentMethodResponse: EditPaymentMethodResponse;
    paperlessEmail: string;

    save(params: Partial<EditPaymentMethodFlowService>): void {
        this.editPaymentMethodForm = params.editPaymentMethodForm || this.editPaymentMethodForm;

        this.emailAddress = params.emailAddress || this.emailAddress;
        this.selectedPaymentMethod = params.selectedPaymentMethod || this.selectedPaymentMethod;

        this.isEasyPayEligible = params.isEasyPayEligible || this.isEasyPayEligible;
        this.isPaperlessEligible = params.isPaperlessEligible || this.isPaperlessEligible;

        this.selectedEasyPay = params.selectedEasyPay || this.selectedEasyPay;
        this.selectedPaperless = params.selectedPaperless || this.selectedPaperless;

        this.considerThisDisplayable = params.isEasyPayEligible || params.isPaperlessEligible;

        this.paymentMethodSaveAttempted = params.paymentMethodSaveAttempted || this.paymentMethodSaveAttempted;
        this.paymentMethodSaved = params.paymentMethodSaved || this.paymentMethodSaved;
        this.paperlessEnrollmentAttempted = params.paperlessEnrollmentAttempted || this.paperlessEnrollmentAttempted;
        this.savedPaperlessEsddaPrefs = params.savedPaperlessEsddaPrefs || this.savedPaperlessEsddaPrefs;
        this.enrolledInPaperless = params.enrolledInPaperless || this.enrolledInPaperless;
        this.editPaymentMethodResponse = params.editPaymentMethodResponse || this.editPaymentMethodResponse;
        this.paperlessEmail = params.paperlessEmail || this.paperlessEmail;
    }

    clear(): void {
        this.editPaymentMethodForm = undefined;

        this.emailAddress = undefined;
        this.selectedPaymentMethod = undefined;

        this.isEasyPayEligible = undefined;
        this.isPaperlessEligible = undefined;

        this.selectedEasyPay = undefined;
        this.selectedPaperless = undefined;

        this.considerThisDisplayable = false;

        this.paymentMethodSaveAttempted = false;
        this.paymentMethodSaved = false;
        this.paperlessEnrollmentAttempted = false;
        this.savedPaperlessEsddaPrefs = false;
        this.enrolledInPaperless = false;
        this.editPaymentMethodResponse = undefined;
        this.emailAddress = undefined;
        this.paperlessEmail = undefined;
    }

    get flowInPlay(): boolean {
        return this.editPaymentMethodForm != null;
    }
}
