import { AutomaticPayments, AutomaticPaymentsAccordion } from './automatic-payments.model';
import { PaymentItem } from '../../shared/models/payment-item.model';
import { BankAccountPaymentItem } from '../../shared/models/bankaccount-item.model';
import { BankCardPaymentItem } from '../../shared/models/bankcard-item.model';
import { CardType } from '../../../../shared/payments/card-utils/card-type.enum';
import { PaymentPrefsSectionPlaceholder } from '../../shared/models/placeholder.model';

export const STUB_UI = {
    NOT_ENROLLED: AutomaticPayments.create({
        placeholderMessage: new PaymentPrefsSectionPlaceholder(
            'You have not set up automatic payments ',
            [
                `
          No checks. No stamps. No trips to the post office.
          Have your payments automatically deducted from the same account every month.
          Simply enter your bank account or debit/credit card information and your payments
          will be automatically deducted at the same time each month. That's all there is to it!
        `,
                `
          If you ever want to cancel automatic payments , you can do so online anytime. Please allow 5 days to process.
        `,
                `
          By enrolling in automatic payments , your third-party electronic bill account will be deactivated.
        `
            ]
        ),
        disclaimer: [`
      Payments are automatically deducted from your account on the due date
      (or the next business day, if the due date falls on a weekend or holiday).
      For assistance, please contact your local agent or call 1-877-669-6877.
      Representatives are available Monday through Friday from 8AM to 9PM ET.
    `],
        enrollmentStatus: 'canEnroll'
    }),

    NOT_ELLIGBLE_3RD_PARTY_BILLED: AutomaticPayments.create({
        infoMessages: [
            `Third-party billed accounts (i.e., mortgage/escrow) are not eligible for automatic payments . `
        ]
    }),

    NOT_ELLIGBLE_WARNED: AutomaticPayments.create({
        warningMessages: [`
      Automatic payments  cannot be set up at this time. Please check back later.
      If you need immediate assistance, please contact your local agent or call 1-877-669-6877.
    `]
    }),

    NEWLY_ENROLLED: AutomaticPayments.create({
        placeholderMessage: new PaymentPrefsSectionPlaceholder(
            `Thank you for setting up automatic payments.`,
            [`
          Please allow 1 to 2 business days to process your request.
          Your first automatic payment  will be scheduled for your next bill.
      `]
        ),
        disclaimer: [`
      Payments are automatically deducted from your account on the due date
      (or the next business day, if the due date falls on a weekend or holiday).
      For assistance, please contact your local agent or call 1-877-669-6877.
      Representatives are available Monday through Friday from 8AM to 9PM ET.
    `]
    }),

    ENROLLED_NOT_STOPPED: AutomaticPayments.create({
        accordion: AutomaticPaymentsAccordion.create({
            header: 'Bank Account ...703',
            subHeader: '(enrolled in automatic payments) ',
            canCancel: true,
            canEdit: true,
            payMethod: new PaymentItem(
                new BankAccountPaymentItem(
                    'Emma',
                    'Michael',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    'Fleetboston',
                    'Savings',
                    '011000138',
                    '703',
                    ''
                ),
                false,
                ''
            )
        }),
        disclaimer: [`
      Payments are automatically deducted from your account on the due date
      (or the next business day, if the due date falls on a weekend or holiday).
      For assistance, please contact your local agent or call 1-877-669-6877.
      Representatives are available Monday through Friday from 8AM to 9PM ET.
    `,
            `
      If you have an automatic payment set up as a bank transfer,
      applicable refunds will be made to the bank account you have indicated.
    `],
        authAgreementDisplayable: true
    }),

    ENROLLED_BANK_CARD: AutomaticPayments.create({
        accordion: AutomaticPaymentsAccordion.create({
            header: 'MasterCard ...5454',
            subHeader: '(enrolled in automatic payments) ',
            canCancel: true,
            canEdit: true,
            payMethod: new PaymentItem(
                new BankCardPaymentItem(
                    'Test',
                    'User',
                    '',
                    '',
                    '43015',
                    CardType.MASTERCARD,
                    '...5454',
                    new Date('Jan 06 2023'),
                    '5454'
                ),
                false,
                ''
            )
        }),
        disclaimer: [`
      Payments are automatically deducted from your account on the due date
      (or the next business day, if the due date falls on a weekend or holiday).
      For assistance, please contact your local agent or call 1-877-669-6877.
      Representatives are available Monday through Friday from 8AM to 9PM ET.
    `,
            `
      If you have an automatic payment set up as a bank transfer,
      applicable refunds will be made to the bank account you have indicated.
    `]
    }),

    ENROLLED_STOPPED_THIS_MONTHS_PAYMENT: AutomaticPayments.create({
        accordion: AutomaticPaymentsAccordion.create({
            header: 'MasterCard ...5454',
            subHeader: '(stopped until 1/31/19)',
            canCancel: true,
            canEdit: true,
            payMethod: new PaymentItem(
                new BankCardPaymentItem(
                    'Bob',
                    'Hope',
                    '',
                    '',
                    '43015',
                    CardType.MASTERCARD,
                    '...5454',
                    new Date('Jan 06 2023'),
                    '5454'
                ),
                false,
                ''
            )
        }),
        disclaimer: [`
      Payments are automatically deducted from your account on the due date
      (or the next business day, if the due date falls on a weekend or holiday).
      For assistance, please contact your local agent or call 1-877-669-6877.
      Representatives are available Monday through Friday from 8AM to 9PM ET.
    `,
            `
      If you have an automatic payment set up as a bank transfer,
      applicable refunds will be made to the bank account you have indicated.
    `],
        authAgreementDisplayable: true
    }),

    ENROLLED_CHANGES_CANNOT_BE_MADE: AutomaticPayments.create({
        warningMessages: [`
      Changes to automatic payments  cannot be made at this time. Please check back after your payment due date.
      If you need immediate assistance, please contact your local agent or call 1-877-669-6877.
    `],
        accordion: AutomaticPaymentsAccordion.create({
            header: 'MasterCard ...5454',
            subHeader: '(enrolled in automatic payments) ',
            payMethod: new PaymentItem(
                new BankCardPaymentItem(
                    'Mary',
                    'Smith',
                    '',
                    '',
                    '43015',
                    CardType.MASTERCARD,
                    '...5454',
                    new Date('Jan 06 2023'),
                    '5454'
                ),
                false,
                ''
            )
        }),
        disclaimer: [`
      Payments are automatically deducted from your account on the due date
      (or the next business day, if the due date falls on a weekend or holiday).
      For assistance, please contact your local agent or call 1-877-669-6877.
      Representatives are available Monday through Friday from 8AM to 9PM ET.
    `,
            `
      If you have an automatic payment set up as a bank transfer,
      applicable refunds will be made to the bank account you have indicated.
    `],
        authAgreementDisplayable: true
    }),

    NOT_BILLED_WITH_SCHEDULED_PAYMENT: AutomaticPayments.create({
        warningMessages: [`
      Because you have already scheduled a payment for 01/28/2019, you cannot set up automatic payments  at this time.
      If you'd like to enroll now, please cancel your scheduled payment.
    `],
        placeholderMessage: new PaymentPrefsSectionPlaceholder(
            'You have not set up automatic payments ',
            [
                `
          No checks. No stamps. No trips to the post office.
          Have your payments automatically deducted from the same account every month.
          Simply enter your bank account or debit/credit card information and your payments
          will be automatically deducted at the same time each month. That's all there is to it!
        `,
                `
          If you ever want to cancel automatic payments , you can do so online anytime. Please allow 5 days to process.
        `,
                `
          By enrolling in automatic payments , your third-party electronic bill account will be deactivated.
        `
            ]
        ),
        disclaimer: [`
      Payments are automatically deducted from your account on the due date
      (or the next business day, if the due date falls on a weekend or holiday).
      For assistance, please contact your local agent or call 1-877-669-6877.
      Representatives are available Monday through Friday from 8AM to 9PM ET.
    `],
        enrollmentStatus: 'enrollmentDisabled'
    }),

    ENROLLED_WITH_CHANGE_PENDING: AutomaticPayments.create({
        placeholderMessage: new PaymentPrefsSectionPlaceholder(
            'You recently updated your automatic payments information.',
            [
                `
          Please allow 1 to 2 business days to process your request. Once the changes are updated,
          you will be able to manage your automatic payments .
        `
            ]
        ),
        disclaimer: [`
      Payments are automatically deducted from your account on the due date
      (or the next business day, if the due date falls on a weekend or holiday).
      For assistance, please contact your local agent or call 1-877-669-6877.
      To change the draft date on your Life Policy, please contact a Life Customer
      Service Representative from 8:00 am to 8:00 pm ET, Monday through Friday at 1-800-848-6331.
    `]
    }),

    EZ_PAY_UNAVAILABLE: AutomaticPayments.create({
        warningMessages: [`
      This feature is temporarily unavailable. Please try again later.
      For immediate assistance concerning your policy or bill payments,
      please contact your local Nationwide agent or call 1-877-669-6877.
      Representatives are available Monday through Friday from 8AM to 9PM ET.
    `]
    }),

    EZ_PAY_WITH_SMARTMILES: AutomaticPayments.create({
        accordion: AutomaticPaymentsAccordion.create({
            header: 'MasterCard ...5454',
            subHeader: '(enrolled in automatic payments) ',
            payMethod: new PaymentItem(
                new BankCardPaymentItem(
                    'Mary',
                    'Smith',
                    '',
                    '',
                    '43015',
                    CardType.MASTERCARD,
                    '...5454',
                    new Date('Jan 06 2023'),
                    '5454'
                ),
                false,
                ''
            ),
            canEdit: true,
            hasSmartMiles: true
        }),
        disclaimer: [`
      Payments are automatically deducted from your account on the due date
      (or the next business day, if the due date falls on a weekend or holiday).
      For assistance, please contact your local agent or call 1-877-669-6877.
      Representatives are available Monday through Friday from 8AM to 9PM ET.
    `,
            `
      If you have an automatic payment set up as a bank transfer,
      applicable refunds will be made to the bank account you have indicated.
    `],
        authAgreementDisplayable: true
    })
};
