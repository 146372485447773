<div class="manage-payment-form-container">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="setup automatic payments progress value is 100%" class="progress-bar-container to-container" progressBarValue="100"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Set up automatic
            payments: confirmation</h1>

        <div class="bolt-row">
            <div class="bolt-col-sm-12">
                <ng-container *ngIf="successfullyEnrolled">
                    <bw-bolt-notification class="bolt-space-bottom-2xl to-container" type="success">Your automatic payment changes have been confirmed.
                    </bw-bolt-notification>
                    <app-payment-sub-header>Confirmation details</app-payment-sub-header>
                    <div class="bolt-space-bottom-2xl">
                        <ng-container>
                            <div class="bolt-row" [ngClass]="[wasEsddaEnrollmentSuccessful ? 'bolt-space-bottom-xl':'']">
                                <div class="bolt-col">
                                    <strong>{{effectiveDateAutomatic}}</strong>
                                </div>
                                <div class="bolt-col">
                                    {{formattedCurrentDate}}
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="wasEsddaEnrollmentSuccessful">
                            <div class="bolt-row">
                                <div class="bolt-col">
                                    <strong>{{effectiveDatePaperless}}</strong>
                                </div>
                                <div class="bolt-col">
                                    {{formattedCurrentDate}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="this.amountDueExists()">
                        <app-payment-sub-header>Outstanding payment due</app-payment-sub-header>
                        <p [ngClass]="[isPaperlessEnrollmentUnsuccessful ?
							'bolt-space-bottom-xl':'bolt-space-bottom-2xl']">
                            A payment of {{this.amountDue}} for this account is due on
                            {{this.amountDueDate | date:'MM/dd/yyyy'}}. This
                            amount
                            will not be automatically deducted from your account.</p>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="!successfullyEnrolled">
                    <bw-bolt-notification class="bolt-space-bottom-2xl to-container" type="error" id="error-notification">
                        {{ enrollmentErrorMessage }}
                    </bw-bolt-notification>
                </ng-container>

                <ng-container *ngIf="isPaperlessEnrollmentUnsuccessful">
                    <p *ngIf="successfullyEnrolled">Paperless enrollment: error</p>
                    <bw-bolt-notification class="bolt-space-bottom-2xl to-container" type="error">
                        {{ errorMessage }}
                    </bw-bolt-notification>
                </ng-container>
            </div>
        </div>
    </div>
    <ng-container *ngIf="amountDueExists();else amountDueNotExists">
        <bolt-button-bar>
            <bolt-button slot="cancel" id="print-button" (click)="showPrintDialog()">Print</bolt-button>
            <bolt-button slot="back" id="secondary-button" (click)="secondayNavigation(secondaryButton)">{{secondaryButton.label}}</bolt-button>
            <bolt-button slot="forward" id="submit-button" type="primary" (click)="onContinue()" *ngIf='upNextLabel'>
                {{upNextLabel}}</bolt-button>
        </bolt-button-bar>
    </ng-container>
    <ng-template #amountDueNotExists>
        <bolt-button-bar>
            <bolt-button slot="cancel" id="print-button" (click)="showPrintDialog()">Print</bolt-button>
            <bolt-button slot="forward" id="secondary-button" (click)="secondayNavigation(secondaryButton)">{{secondaryButton.label}}</bolt-button>
        </bolt-button-bar>
    </ng-template>
</div>