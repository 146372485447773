<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<form *ngIf="addPaymentForm" [formGroup]="addPaymentForm.group" (ngSubmit)="onSubmit()" class="manage-payment-form-container">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="Add payment method progress value is 50%" progressBarValue="50" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Add payment method:
            review & submit</h1>

        <div class="bolt-row">
            <div class="bolt-col-12">
                <bw-bolt-notification type="error" [id]="'global-error-message'" *ngIf="globalErrorDisplayable" class="bolt-space-bottom-xl to-container">
                    Please check the information below and try again.
                </bw-bolt-notification>

                <section id="payment-details" [ngClass]="{'bottom_space': considerThisSectionDisplayable}">
                    <app-payment-sub-header>Payment details</app-payment-sub-header>
                    <fieldset class="section-content to-container" [ngClass]="{'bolt-space-bottom-lg': !considerThisSectionDisplayable}">
                        <legend class="fields-only"></legend>
                        <ng-container *ngFor="let field of addPaymentDetailsReviewFields;">
                            <app-recap-form-field *ngIf="!field.cardType" class="bolt-space-bottom-xl to-container" [field]="field"></app-recap-form-field>

                            <app-bankcard-recap-form-field *ngIf="field.cardType" class="bolt-space-bottom-xl to-container" [field]="field"></app-bankcard-recap-form-field>

                        </ng-container>
                    </fieldset>
                </section>

                <section *ngIf="considerThisSectionDisplayable">
                    <fieldset class="section-content bolt-space-bottom-lg to-container">
                        <legend class="fields-only"></legend>
                        <ng-container *ngFor="let field of considerThisReviewFields;">
                            <app-recap-form-field [field]="field" class="bolt-space-bottom-xl to-container">
                            </app-recap-form-field>
                        </ng-container>
                    </fieldset>
                </section>
                <ng-container *ngIf="paperlessSelection">
                    <section>
                        <app-payment-sub-header>Contact information</app-payment-sub-header>
                        <app-email-form-control formGroupName="emailAddressGroup">
                        </app-email-form-control>
                    </section>
                </ng-container>
            </div>
        </div>
    </div>
    <bolt-button-bar>
        <bolt-button id="cancel-button" slot="cancel" (click)="cancelAddPayment()">Cancel</bolt-button>
        <bolt-button id="back-button" slot="back" (click)="navigateToAddPaymentMethodLanding()">Back</bolt-button>
        <bolt-button id="submit-button" aria-label="submit" slot="forward" (click)="onSubmit()" type="primary">
            Submit
        </bolt-button>
    </bolt-button-bar>
</form>