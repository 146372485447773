export enum EBIEventType {
    BILLING_TIMELINE_LOAD = 'Billing Timeline',
    BILLING_DETAILS = 'Billing Details',
    PAYMENT_PREFERENCES_PAGE_LOAD_NO_SAVED_PAYMENT_METHODS = 'payment preferences loads and the user has no saved payment methods',
    PAYMENT_PREFERENCES_PAGE_LOAD_SAVED_PAYMENT_METHODS = 'payment preferences loads and the user has saved payment methods',
    QUESTIONS_AND_TIPS = 'Questions-Tips',
    DEVICE_DETAILS = 'device-Browser-OS-details',
    VIEW_PDF_STATEMENT = 'View billing PDF statement',
    LOAD_PDF_STATEMENT = 'Load of billing statement PDF',
    PRINTER_LINK_CLICKED_ID = 'Print Billing Details page',
    PRINT_LINK_CONFIRMATION_CLICKED = 'Print of Make a Payment Confirmation page',
    ON_CLICK_MAKE_PAYMENT_BTN = 'On load of Make a Payment page',
    ON_CLICK_CONTINUE_MAKE_PAYMENT_PAGE = 'Submission of Make a Payment input page',
    ON_CLICK_OF_PAYMENT_REVIEW_PAGE = 'Submission of Make a Payment Review page',
    ON_LOAD_MAKE_PAYMENT_PAGE = 'Load of Pay Bill flow',
    ON_PAYMENT_AMOUNT_NOTIFICATION = 'Partial payment warning content',
    ON_SMART_MILES_LINK_CLICK = 'User has launched to Telematics page from Policy Servicing',
    ON_PAYMENT_CAP_ERROR = 'Payment Cap Error',
    ON_LOAD_EDIT_AUTOMATIC_PAYMENTS_LANDING_PAGE = 'Load of Automatic Payments Edit Landing Page',
    ON_LOAD_MAKE_PAYMENT_PAGE_WITH_PMOF = 'Load of Make a Payment Landing with preferences',
    ON_LOAD_AUTOMATIC_PAYMENTS_LANDING_PAGE = 'Load of Automatic Payments Enrollment Page',
    ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE = 'Number of customers landing on the automatic payments enrollment review page and the type of payment enrolled with',
    ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE_AUTH_AGREEMENT = 'Track the number of customers who agreed to the Authorization Form',
    ON_CLICK_OF_SUBMIT_BTN_EDIT_AUTOMATIC_PAYMENTS_REVIEW_PAGE = 'Service Redesign / Billing Management - Manage EasyPay Review Page',
    ON_CLICK_OF_PRINT_BTN_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE = 'Service Redesign / Billing Management - Manage EasyPay Enrollment Confirmation Page Print',
    ON_LOAD_OF_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE = 'Service Redesign / Billing Management - Manage EasyPay Enrollment Confirmation Page',
    ON_SUCCESS_OR_FAIL_EASYPAY_ENROLLMENT = 'Track number of customers who completed the automatic payment enrollment process',
    ON_COMPLETION_OF_EASYPAY_ENROLLMENT = 'Number of customers who got confirmation/failure on enrollment into EasyPay',
    ON_CLICK_PRINT_BTN_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE = 'Track the number of times that the customer selects the print button on the automatic payment enrollment confirmation page',
    ON_LOAD_PAYMENT_CONFIRMATION_PAGE_WITH_SCHEDULED_FUTURE_PAYMENT = 'Load of Confirmation page after Scheduling a Future Payment',
    ON_LAND_CONFIRMATION_PAGE_OF_THE_MAKE_A_PAYMENT_FLOW = 'Number of customers who land on the Confirmation page of the Make a Payment flow',
    ON_CLICK_RECURRING_PATMENT_BTN = 'Track users who select the Automatic payments button on the payment confirmation page',
    ON_OPEN_TIMELINE_EVENTS_MODAL = 'The number of times the events modal window on billing timeline page is loaded',
    ON_OPEN_TIMELINE_REFUNDS_MODAL = 'Launch of the Refund information modal window on billing timeline page',
    ON_LOAD_OF_QUICK_PAY_SEARCH_PAGE = 'User for the load of Quick Pay Search page',
    ON_SUBMIT_OF_QUICK_PAY_SEARCH_PAGE = 'Customer who were able to continue to Make a Payment flow.',
    ON_SUBMIT_OF_QUICK_PAY_SEARCH_PAGE_FAILURE = 'Customer who were failed to continue to Make a Payment flow.',
    PP_ADD_PAYMENT_METHOD_ON_REVIEW_LOAD = 'On load of add payment method review page',
    PP_ADD_PAYMENT_METHOD_LANDING_ON_CONTINUE = 'Where customer can input what method of Payment to keep on File',
    PP_ADD_PAYMENT_METHOD_CONFIRMATION_LOAD = 'On load of add payment method confirmation page',
    PP_ADD_PAYMENT_REVIEW_ON_CLICK_SUBMIT = 'Click of the Submit button on the Add Payment Method review page',
    PP_ADD_PAYMENT_CONFIRMATION_PRINT = 'On click of add payment method confirmation print Button',
    PP_ADD_PAYMENT_CONFIRMATION_HARDFALL = 'On load of Hardfall page',
    PP_REMOVE_NON_DEFAULT_PAYMENT_METHOD_CONFIRMATION = 'Track number of customers removing their payment method when the method is not the default.',
    PP_REMOVE_DEFAULT_PAYMENT_METHOD_CONFIRMATION = 'On load of remove payment method confirmation page when payment method is the default',
    PP_REMOVE_PAYMENT_METHOD_CONFIRMATION = 'Service Redesign / Billing Management - Remove Payment Method Confirmation Page',
    PP_ADD_REFUND_METHOD = 'Track number of customers who successfully added a Refund Method',
    ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_LANDING_PAGE = 'Count customers that go to the suspend landing page and the URL they came from',
    ON_CLICK_OF_CANCEL_IN_SUSPEND_EASY_PAY_LANDING_PAGE = 'Track the number of customers who cancel the suspend transaction',
    ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_CONFIRMATION_PAGE = 'Track the number of customers who land on the confirmation page',
    ON_CLICK_OF_PRINT_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE = 'Track the number of customers who print the Suspend confirmation page',
    ON_CLICK_OF_PAY_LATER_OR_MAP_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE = 'Track customers that proceeded on in the suspend process from the landing page and capture whether they choose to pay now or pay later',
    ON_LOAD_OF_EDIT_PAYMENT_METHOD_LANDING_PAGE = 'Edit payment method landing page',
    ON_LOAD_OF_EDIT_PAYMENT_METHOD_REVIEW_PAGE = 'Edit payment method review page',
    ON_CLICK_OF_SUBMIT_EDIT_PAYMENT_METHOD_REVIEW_PAGE = 'On click of the submit button on edit payment method review page',
    ON_LOAD_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PAGE = 'Service Redesign / Billing Management - Edit Payment Method Confirmation Page',
    ON_CLICK_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PRINT_BUTTON = 'Edit payment method confirmation print button',
    ON_EDIT_OF_PAYMENT_METHOD_ON_AUTOMATIC_PAYMENT_LANDING_PAGE = 'Number of customers who edit financial information or switch payment method for Easy Pay',
    ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_LANDING_PAGE = 'Cancel Easy Pay Landing Page',
    ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_CONFIRMATION_PAGE = 'Cancel Easy Pay Confirmation Page',
    ON_CLICK_OF_AUTHORIZATION_AGREEMENT_LINK = 'Customers who viewed authoirization agreement form',
    ON_CLICK_SUBMIT_CANCEL_AUTOMATIC_PAYMENTS_LANDING = 'Service Redesign / Billing Management - Cancel EasyPay Bank Account Page',
    ON_LOAD_OF_QUICK_PAY_PICKER_PAGE = 'Event will provide number of times the Select Policy page is loaded for QuickPay',
    ON_CLICK_CONTINUE_OF_QUICK_PAY_PICKER_PAGE = 'Event will track the number of users fail/success to continue Select Policy page',
    LOG_NUMBER_OF_USERS_COMING_IN_BILLING_VIA_THE_BILLING_TIMELINE_URL = 'Log number of users coming in  Billing via the billing timeiline url: nationwide.com/billdetails',
    ON_LOAD_CONFIRMATION_PAGE_CANCEL_SCHEDULED_PAYMENT = 'Generation of Confirmation page when cancelling a scheduled payment',
    ON_CLICK_OF_PRINT_BTN_CANCEL_EASY_PAY_CONFIRMATION_PAGE = 'Service Redesign / Billing Management - EasyPay Cancellation Confirmation Print Button',
    ON_CLICK_CONTINUE_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD_LANDING_PAGE = 'On Continue when user elects to add new payment method as refund method (make payment landing or add payment method landing)',
    ON_INCOMPLETE_FORM_ELEMENTS_IN_EFT_AUTH_FORM = 'Provide a count of the REFT forms that would have failed due to incomplete form data',
    LOG_NUMBER_OF_TIMES_HP_EXTREME_SERVICE_INVOKING = 'Capture the number of HP Extreme calls and the response code/status',
    ON_CLICK_OF_SUBMIT_BTN_REMOVE_REFUND_METHOD_LANDING_PAGE = 'Event measures the number of customers who select the Submit button on the Review & Submit page during the Remove Refund method flow.',
    ON_CLICK_OF_REMOVE_REFUND_METHOD_BTN = 'Event will fire when user clicks on Remove Refund Method button',
    ON_CONFIRMATION_LOAD_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD = 'Event will fire when user clicks Yes to save new payment method as refund method then Submit on Review page',
    ON_CLICK_OF_ADD_REFUND_METHOD_BTN = 'Event will fire when user clicks on Add Refund Method button',
    ON_BANK_ACCOUNT_VERIFICATION_ERROR = 'On submission of page when New Checking/savings Account is the selected payment method',
    ON_LOAD_CHANGE_DUE_DATE_CONFIRMATION_LANDING_PAGE = 'On load of Change Due Date confirmation page',
    ON_LOAD_CHANGE_DUE_DATE_REVIEW_AND_SUBMIT_PAGE = 'On load of Change Due Date Review and Submit page',
    ON_LOAD_CHANGE_DUE_DATE_LANDING_PAGE = 'On load of Change Due Date Landing page',
    ON_CLICK_OF_CONTACT_US_LINK_LOGS_OUT_OF_ISB = 'Tracks whenever the user goes out of ISB application ( Browser back button, click on contact us link etc)',
    ON_SELECT_OF_THE_CONTACT_US_BUTTON_IN_THE_PAY_PLAN_SECTION = 'Eligible customers on Full Pay who select Contact us button',
    ON_SELECT_OF_THE_EDIT_PAYMENT_PLAN_BUTTON = 'Track number of customers who select Edit payment plan button',
    ON_DISPLAY_OF_THE_PAYMENT_PLAN_SECTION = 'Track number of customers who have the Payment plan section displayed',
    ON_SUBMIT_OF_FULLPAY_PAYMENT_PLAN = 'User selects the Submit button to switch from Monthly to Full Pay,'
}
