<fieldset class="payment-method-selection-fieldset">
    <div [ngClass]="selectedPaymentMethod?.isBankCard ? 'bolt-space-bottom-md'
		:'bolt-space-bottom-xl'">
        <div class="bolt-row align-end">
            <div class="bolt-col-sm-5 bolt-col-md-5">
                <bw-bolt-select [label]='labelText' [invalid]="displayErrors ? true : null" [placeholder]='placeholderText' [optionalText]='optionalText' id="payment-method-selection"
                    name="payment-method-selection" ngDefaultControl [value]="selectedValue" (change)="updateSelectedPaymentMethod($event)" [ngModelOptions]="{standalone: true}"
                    class="jm-visible nwpii" [class]="'jm-visible'">
                    <ng-container *ngFor="let method of paymentMethods">
                        <option *ngIf="!method.isPlaceholder" [selected]="method.displayName === selectedPaymentMethod?.displayName" [ngValue]="method.displayName">
                            {{ method.displayName }}
                            {{ method.isDefault ? DEFAULT_STRING : '' }}
                        </option>
                    </ng-container>
                </bw-bolt-select>
            </div>

            <div class="bolt-col-sm-5 bolt-col-md-5" *ngIf="(selectedPaymentMethod && !selectedPaymentMethod.isPlaceholder &&
        !selectedPaymentMethod.isNewMethod) && !isManagePaymentPreferencesFlow &&
        !isQuickPay">
                <bw-bolt-button id="update-method-button" type="tertiary" (click)="updatePaymentMethodOnFile= !updatePaymentMethodOnFile;
          updatePaymentMethodOnFileChange.emit(updatePaymentMethodOnFile)">
                    {{ updateMethodOnFileButtonText }}
                </bw-bolt-button>
            </div>
            <div class="bolt-col-sm-7 bolt-col-md-7" *ngIf="reducedFees">
                <span class="ways-to-save" *ngIf="reducedFees && !isFullPay && selectedPaymentMethod?.isBankAccount" id="waysToSave">Reduced installment fees apply</span>
                <span class="ways-to-save" *ngIf="reducedFees && !isFullPay && selectedPaymentMethod?.isBankCard" id="waysToSave">Installment fees apply</span>
            </div>
        </div>
        <app-form-control-error id="paymentMethodSelectionErrors" *ngIf="displayErrors">
            <ul class="error-list">
                <li id="payment-method-select-required-error">
                    Select a payment method
                </li>
            </ul>
        </app-form-control-error>
    </div>
    <ng-container *ngIf="increaseReftAutoPayFlag && !isLifeOnlyAccount && selectedPaymentMethod?.isBankCard; else waysToSave">
        <div class="bolt-row bolt-space-bottom-xl" id="increase-reft-autoPay-warning">
            <div class="bolt-col-lg-5 bolt-col-sm-5 bolt-col-md-5 d-flex">
                <img alt="Increase Reft AutoPay Warning" [src]="increaseReftWarningIcon" class="mr-2 mb-4">
                <bw-html-interpolate class="warning-text" id="increase-reft-savings-message" [content]='increaseReftAutoSavingsContent'></bw-html-interpolate>
            </div>
        </div>
    </ng-container>
    <ng-template #waysToSave>
        <ng-container *ngIf="reducedFees">
            <div class="bolt-space-bottom-xl">
                <span class="bolt-heading">Ways to save</span>
                <bw-bolt-contextual-help>
                    <bw-html-interpolate id="ways-to-save-message" [content]='waysToSaveMessage'></bw-html-interpolate>
                </bw-bolt-contextual-help>
            </div>
        </ng-container>
    </ng-template>
</fieldset>