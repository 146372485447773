import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { PaymentItem } from '../../../../../../billing/payment-preferences/shared/models/payment-item.model';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { CardType } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PaymentMethodsAdapter } from '../../../../shared/adapters/payment-methods.adapter';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { EditPaymentMethodFlowService } from '../edit-payment-method-flow.service';
@Component({
    selector: 'app-edit-payment-method-landing',
    templateUrl: './edit-payment-method-landing.component.html',
    styleUrls: ['./edit-payment-method-landing.component.scss']
})
export class EditPaymentMethodLandingComponent extends BasePageComponent implements OnInit {
    @ViewChild(FormGroupDirective) editPaymentFormDirective: FormGroupDirective;
    pageName = ManagePaymentPreferencesPageTitle.savedPaymentMethods.edit.landing;
    loadComplete = false;
    paymentItem: PaymentItem;
    editPaymentMethodForm: PaymentFormGroup;
    isManagePaymentPrefsFlow = true;
    emailAddress: string;
    isPaperlessEligible: boolean;
    isEasyPayEligible: boolean;
    formValidated = false;
    esddaCheckBoxChecked = false;
    paperlessEsddaValidationError = false;

    // eslint-disable-next-line max-params
    constructor(
        router: Router,
        session: SessionService,
        private urlUtil: UrlUtil,
        private paymentPrefsApiService: PaymentPreferencesApiService,
        private editPaymentFormBuilder: ManagePaymentPrefsFormBuilder,
        private paymentMethodAdapter: PaymentMethodsAdapter,
        private formUtils: FormUtil,
        private eventService: BillingEventService,
        @Inject('window') private window: Window,
        @Inject('logger') private logger: LoggerService,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private editPaymentMethodFlow: EditPaymentMethodFlowService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        this.session.currentPage = this.pageName;
        this.setVariablesFromApiResponse();
    }

    onEsddaCheckboxClick(value): void {
        this.esddaCheckBoxChecked = value;
        if (this.esddaCheckBoxChecked) {
            this.paperlessEsddaValidationError = false;
        }
    }

    setVariablesFromApiResponse(): void {
        this.nextSub = this.paymentPrefsApiService.editSavedPaymentMethodLanding().subscribe((response) => {
            const { retrieveCustomerAgreement, internetRegistrationResponse, savedPaymentMethod } = response;
            this.isEasyPayEligible = this.managePaymentPrefsAdapter.isEligibleForEasyPay(retrieveCustomerAgreement);
            this.isPaperlessEligible = this.managePaymentPrefsAdapter.isEligibleForPaperless(internetRegistrationResponse);
            this.emailAddress = this.managePaymentPrefsAdapter.fetchMostCurrentEmail(retrieveCustomerAgreement, internetRegistrationResponse);
            this.paymentItem = this.paymentMethodAdapter.mapSinglePaymentMethod(savedPaymentMethod);
            this.setupEditPaymentForm();
            this.loadComplete = true;
            this.sendOnPageLoadEbiEvent();
        },
            (error) => {
                this.logger.error('API ERROR: EDIT PAYMENT METHOD LANDING', error);
            });
    }

    navigateToReview(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.EDIT.REVIEW(
                this.urlUtil.hashParamsString
            )
        );
    }

    getEmailAddress(): string {
        if (this.editPaymentMethodForm.enrollInPaperless === 'no') {
            this.editPaymentMethodFlow.paperlessEmail = this.emailAddress;
        }
        return this.editPaymentMethodFlow.paperlessEmail || this.emailAddress;
    }

    setupEditPaymentForm(): void {
        if (this.editPaymentMethodFlow.flowInPlay) {
            this.editPaymentMethodForm = this.editPaymentFormBuilder.reconstructFormGroup({
                selectedPayMethod: this.paymentItem,
                oldForm: this.editPaymentMethodFlow.editPaymentMethodForm.group,
                hasSavedPayments: true
            });
        } else {
            this.editPaymentMethodForm = this.editPaymentFormBuilder.newPaymentFormGroup({
                selectedPayMethod: this.paymentItem,
                hasSavedPayments: true
            });
        }
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_LOAD_OF_EDIT_PAYMENT_METHOD_LANDING_PAGE, {
            paymentMethod: this.ebiPaymentMethodData
        });
    }

    get ebiPaymentMethodData(): PaymentMethodsData[] {
        const data = [];
        const payMethod = this.paymentItem;
        const accountType = payMethod.isBankAccount ? this.editPaymentMethodForm.bankAccountType : 'NA';
        const bankName = payMethod.isBankAccount ? this.editPaymentMethodForm.bankName : 'NA';
        const creditCardVendor = payMethod.isBankCard ? this.editPaymentMethodForm.cardType : 'NA';
        const paymentType = payMethod.isBankCard ? 'CC' : 'EFT';
        const firstName = this.editPaymentMethodForm.firstName;
        const lastName = this.editPaymentMethodForm.lastName;

        data.push({
            accountType,
            bankName,
            creditCardVendor,
            paymentType,
            firstName,
            lastName
        });
        return data;
    }

    onContinue(): void {
        this.formUtils.touchAllFormControls(this.editPaymentMethodForm.group);
        this.formValidated = false;
        if (this.isEsddaValidationFailure()) {
            this.paperlessEsddaValidationError = this.isEsddaValidationFailure();
            if (this.paperlessEsddaValidationError) {
                this.logger.info('Edit Payment Method Landing Page - Failed page validation due to ESDDA after continue button is clicked', {
                    accountNumber: this.session.billingAccountNumber,
                    enterpriseCustomerNumber: this.session.ecn,
                    paperlessBillingDeliveryResponse: this.editPaymentMethodForm.enrollInPaperless
                });
            }
        }
        if (this.editPaymentMethodForm.group.valid && !this.paperlessEsddaValidationError) {
            this.saveFormToFlow();
            this.navigateToReview();
        } else {
            this.window.scroll(0, 0);
        }
    }

    isEsddaValidationFailure(): boolean {
        return this.editPaymentMethodForm.enrollInPaperless === 'yes' && !this.esddaCheckBoxChecked;
    }

    saveFormToFlow(): void {
        this.editPaymentMethodFlow.save({
            editPaymentMethodForm: this.editPaymentMethodForm,
            considerThisDisplayable: this.considerThisDisplayable,
            selectedPaymentMethod: this.paymentItem,
            isEasyPayEligible: this.isEasyPayEligible,
            isPaperlessEligible: this.isPaperlessEligible,
            selectedEasyPay: this.editPaymentMethodForm.enrollInEasyPay,
            selectedPaperless: this.editPaymentMethodForm.enrollInPaperless,
            emailAddress: this.emailAddress
        });
    }

    cancelEditPayment(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString)
        );
    }

    get globalErrorDisplayable(): boolean {
        return !this.formValidated &&
            !this.editPaymentMethodForm.group.valid &&
            this.submitted;
    }

    get submitted(): boolean {
        return !!this.editPaymentFormDirective && this.editPaymentFormDirective.submitted;
    }

    get bankCardRecap(): BankcardRecapFormField {
        return {
            id: 'card-number-recap',
            label: 'Card number',
            value: `*************${this.paymentItem.displayNumbers}`,
            cardType: <CardType> this.paymentItem.methodType
        };
    }

    get paymentMethodGroup(): AbstractControl {
        return this.editPaymentMethodForm && this.editPaymentMethodForm.group.get('paymentMethodGroup');
    }

    get considerThisGroup(): AbstractControl {
        return this.editPaymentMethodForm && this.editPaymentMethodForm.group.get('considerThisGroup');
    }

    get considerThisDisplayable(): boolean {
        return this.isEasyPayEligible || this.isPaperlessEligible;
    }

    changeMakeDefaultPayment($event): void {
        this.paymentMethodGroup.get('makeDefaultPaymentMethod').setValue(false);
        if ($event.target.checked) {
            this.paymentMethodGroup.get('makeDefaultPaymentMethod').setValue(true);
        }
    }

    isDefaultPayment(): boolean {
        return !!this.paymentMethodGroup.get('makeDefaultPaymentMethod').value;
    }
}
