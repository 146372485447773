<form target="_blank" action="{{endpoint}}" method="POST" #form>

    <input [hidden]="true" type="text" name="access_token" value="Bearer {{accessToken}}">
    <input [hidden]="true" type="text" name="client_id" value="{{clientId}}">
    <input [hidden]="true" type="text" name="message_id" value="{{messageId}}">
    <input [hidden]="true" type="text" name="repo" value="{{origin}}">
    <input [hidden]="true" type="text" name="output_format" value="{{'pdf'}}">
    <input [hidden]="true" type="text" name="statement_id" value="{{statementId}}">
    <input [hidden]="true" type="text" name="section_id" value="{{sectionId}}">
    <input [hidden]="true" type="text" name="report_id" value="{{reportId}}">
    <input [hidden]="true" type="text" name="version" value="{{version}}">

    <input *ngFor="let keyValuePair of viewPdfEBIPayload" [hidden]="true" type="text" name="{{keyValuePair.key}}" value="{{keyValuePair.value}}">

    <button role="link" type="submit" (click)="onSubmit(); form.submit()">
        <strong>
            <ng-content></ng-content>
        </strong>
    </button>
</form>