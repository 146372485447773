import { AccountValidationVerificationCode } from '@nationwide/dgs-angular-billing-common';

export const ACCEPTABLE_RESPONSE_CODES: AccountValidationVerificationCode[] = [
    '1111',
    '3333',
    '5555',
    'GN01',
    'RT03',
    'ND00'
];

export const WRONG_ACCOUNT_TYPE_CODES: AccountValidationVerificationCode[] = [
    'NW01',
    'NW02'
];

export const FAIL_RESPONSE_CODES: AccountValidationVerificationCode[] = [
    'GN05',
    'GP01',
    'GS01',
    'GS02',
    'ND01',
    'RT00',
    'RT01',
    'RT02',
    'RT04'
];
