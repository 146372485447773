import { Injectable } from '@angular/core';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class EditAutomaticPaymentLanding {
    constructor(
        private editPaymentFlow: EditAutomaticPaymentFlowService
    ) { }

    accessFormControl(paymentMethodGroup: string): AbstractControl {
        return this.editPaymentForm && this.editPaymentForm.group.get(paymentMethodGroup);
    }

    get editPaymentForm(): PaymentFormGroup {
        return this.editPaymentFlow.editAutomaticPaymentForm;
    }

    get firstName(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.nameGroup.firstName');
    }

    get middleName(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.nameGroup.middleInitial');
    }

    get lastName(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.nameGroup.lastName');
    }

    get zipCode(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.addressGroup.zipGroup.zip') || this.accessFormControl('paymentMethodGroup.zipGroup.zip');
    }

    get accountNumber(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.accountNumberGroup.accountNumber');
    }

    get confirmAccountNumber(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.accountNumberGroup.confirmAccountNumber');
    }

    get bankRoutingNumber(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.accountNumberGroup.routingNumber');
    }

    get bankCardNumber(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.bankCardFormGroup.bankCardNumber');
    }

    get bankAccountType(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.accountTypeGroup.accountType');
    }

    get bankAccountName(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.accountNumberGroup.bankName');
    }

    get cardExpirationMonth(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.cardExpirationGroup.cardExpirationMonth');
    }

    get cardExpirationYear(): AbstractControl {
        return this.accessFormControl('paymentMethodGroup.cardExpirationGroup.cardExpirationYear');
    }

    get ezPayAuthGroup(): AbstractControl {
        return this.editPaymentForm && this.editPaymentForm.group.get('ezPayAuthGroup');
    }
}
