import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class RoutingState {
    private previousUrl: string;
    private currentUrl: string;

    constructor(private router: Router) {
        this.currentUrl = this.router.url;
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    getPreviousUrl(): string {
        const url = this.previousUrl;
        const newURL = url ? url.split('?')[0] : 'UNSET';
        return newURL;
    }

    getCurrentUrl(): string {
        const url = this.currentUrl;
        const newURL = url ? url.split('?')[0] : '';
        return newURL;
    }
}
