import { PaymentItem } from '../../shared/models/payment-item.model';

export class SavedPaymentPrefs {
    // eslint-disable-next-line max-params
    constructor(
        readonly canSelectPaperlessBilling: boolean,
        readonly isEasyPayEligible: boolean,
        readonly emailAddress: string,
        readonly payMethods: PaymentItem[] | PaymentItem,
        readonly billingStreet1: string,
        readonly billingCity: string,
        readonly billingState: string
    ) { }
}
