import { RelatedPolicy } from '@nationwide/dgs-angular-billing-common/api-response-types/personal-billing';

export class BillingAccountItem {
    policies: string[];

    // eslint-disable-next-line max-params
    constructor(
        public accountNumber: string,
        relatedPolicies: RelatedPolicy[],
        public refundMethod: string,
        public eligible: boolean,
        public warningMessage: string
    ) {
        this.policies = relatedPolicies
            .map((policy) => `${policy.policyLabel} ${policy.policyNumber}`);
    }

    get changeTypeForEbi(): 'Add' | 'Edit' {
        return this.refundMethod === 'none' ? 'Add' : 'Edit';
    }
}
