<is-wait-message [loadComplete]="loadComplete && !submitCallInProgress" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<form *ngIf="loadComplete" [formGroup]="formGroup.group" class="form-group manage-payment-form-container">
    <div class="bolt-container bolt-space-bottom-2xl">

        <bw-bolt-progress-bar appFocusOnInit arialabel="cancel automatic payments progress value is 50%" progressBarValue="50" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Cancel automatic payments</h1>

        <div *ngIf="globalErrorDisplayable" class="bolt-space-bottom-2xl" [id]="'global-error-message'">
            <bw-bolt-notification class="to-container" type="error" id="#global-error-message">Please check the information below and try again.</bw-bolt-notification>
        </div>
        <h2 class="bolt-heading-sm bolt-space-bottom-xl">Confirm your choice</h2>
        <section id="payment-method-section" class="fluid-container">
            <app-are-you-sure-radio-buttons formGroupName="areYouSureFormGroup" [submitted]="submitted" [displayMessage]="displayMessageForForm"></app-are-you-sure-radio-buttons>
            <bw-bolt-notification *ngIf="displayDueDate" type="info" class="to-container bolt-space-bottom-2xl">{{dueAlertMessage}}</bw-bolt-notification>
            <bw-bolt-notification class="to-container bolt-space-bottom-2xl" type="info">{{warningMessage}}</bw-bolt-notification>
        </section>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" (click)="cancelAutomaticPayment()">Cancel</bolt-button>
        <bolt-button submit type="primary" slot="forward" id="submit-button" (click)="submit()">
            Submit</bolt-button>
    </bolt-button-bar>
</form>