import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';
import { environment } from '../../../../../../../environments/environment';
import { PaymentFlowType } from '../../../../../../pay-bill/shared/payment-flow-type.service';
import { SafeCurrencyPipe } from '../../../../../../pay-bill/shared/safe-currency-pipe';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { Row } from '../../../../../../shared/payments/confirmation-details-table/table-row.api';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { ISBRouteParams } from '../../../../../../shared/routing/isb-route.model';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PaymentItem } from '../../../../../payment-preferences/shared/models/payment-item.model';
import { WARNING_MESSAGES } from '../../../../shared/constants/warning-messages';
import { PaperlessConfirmationMessage } from '../../../../shared/models/paperless-confirmation-message';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SetupAutomaticPaymentsFlowService } from '../services/setup-automatic-payments-flow.service';
import { SetupAutomaticPaymentConfirmationService } from './setup-automatic-payments-confirmation.service';

@Component({
    selector: 'app-setup-automatic-payments-confirmation',
    templateUrl: './setup-automatic-payments-confirmation.component.html',
    styleUrls: ['./setup-automatic-payments-confirmation.component.scss']
})
export class SetupAutomaticPaymentsConfirmationComponent extends BasePageComponent implements OnInit, OnDestroy {

    _successMessage: string;
    amountDue: number;
    amountDueDate: string;
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.setup.confirmation;
    formattedCurrentDate: string;
    isNbpUser: boolean;
    confirmationDetails: Row[];
    paperlessConfirmationMessage: PaperlessConfirmationMessage;
    effectiveDateAutomatic = 'Automatic payment change effective date:';
    effectiveDatePaperless = 'Paperless enrollment change effective date:';
    enrollmentErrorMessage = '';

    // eslint-disable-next-line max-params
    constructor(
        private eventService: BillingEventService,
        private paymentFlowType: PaymentFlowType,
        private easternTimeDatePipe: EasternTimeDatePipe,
        private safeCurrencyPipe: SafeCurrencyPipe,
        router: Router,
        session: SessionService,
        private setupPaymentFlow: SetupAutomaticPaymentsFlowService,
        private setupAutomaticPaymentConfirmationService: SetupAutomaticPaymentConfirmationService,
        private urlUtil: UrlUtil,
        @Inject('window') private window: Window,
        @Inject('logger') private logger: LoggerService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        this.session.currentPage = this.pageName;
        this.sendSetupAutoPaySuccessCtmEvent();
        this.setFormattedCurrentDate();
        this.setVariablesFromApiResponse();
        this.sendOnPageLoadEbiEvent();
        this.logPreviouslyHadSAP417ErrorOnSuccess();
        this.initializeErrorMessage();
        this.paperlessConfirmationMessage = this.setupAutomaticPaymentConfirmationService.getPaperlessFlowMessages(
            this.setupPaymentFlow.enrolledInPaperless, this.setupPaymentFlow.paperlessEnrollmentAttempted,
            this.setupPaymentFlow.savedPaperlessEsddaPrefs);
        if (this.setupPaymentFlow.isPaperlessEligible) {
            let logMessage = 'Setup Automatic Payment Confirmation - Page load';
            if (this.setupAutomaticPaymentConfirmationService.paperlessEnrollmentError(this.paperlessConfirmationMessage)) {
                logMessage = 'Setup Automatic Payment Confirmation- Page load with error message';
            }
            this.logger.info(logMessage, this.setupAutomaticPaymentConfirmationService.logPageLoadForPaperless(this.setupPaymentFlow.setUpAutomaticPaymentsForm.enrollInPaperless));
        }
        this.logPaperlessSuccessfulEnrollment();
    }

    ngOnDestroy(): void {
        this.setupPaymentFlow.clear();
    }

    setVariablesFromApiResponse(): void {
        this.amountDue = this.safeCurrencyPipe.transformToCurrency(this.setupAutomaticPaymentConfirmationService.amountDue);
        this.amountDueDate = this.easternTimeDatePipe.transform(this.setupAutomaticPaymentConfirmationService.amountDueDate);
        this.isNbpUser = this.setupAutomaticPaymentConfirmationService.isNbpUser;
    }

    get urlParams(): ISBRouteParams {
        return {
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId,
            policyNumber: this.paymentFlowType.pKey,
            referringPage: 'review'
        };
    }

    onContinue(): void {
        if (this.amountDueExists() && this.isNbpUser) {
            this.navigateToMAP();
        } else {
            this.navigateToPaymentPreferences();
        }
    }

    secondayNavigation(metaData: ButtonMetaData): void {
        if (metaData.label === 'Account Summary') {
            this.navigateToCAM();
        } else {
            this.router.navigateByUrl(metaData.navigation);
        }
    }

    amountDueExists(): boolean {
        if (this.isNbpUser) {
            return this.setupAutomaticPaymentConfirmationService.amountDue > 0;
        }
    }

    displayContinueButton(): boolean {
        return this.setupAutomaticPaymentConfirmationService.amountDue >= 0;
    }

    showPrintDialog(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_PRINT_BTN_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE, {
            paymentPlan: this.setupPaymentFlow.paymentPlan
        });
        this.window.print();
    }

    navigateToCAM(): void {
        this.window.location.href = environment.CAM_HOME;
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString));
    }

    navigateToMAP(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.RELATIVE.payBillLanding(this.urlParams));
    }

    navigateToPaperless(): void {
        this.window.location.href = environment.NW_HEADER.preferences;
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_SUCCESS_OR_FAIL_EASYPAY_ENROLLMENT, {
            paymentPlan: this.setupPaymentFlow.paymentPlan
        });
        if (this.successfullyEnrolled) {
            this.eventService.handleEvent(EBIEventType.ON_COMPLETION_OF_EASYPAY_ENROLLMENT, {
                paymentPlan: this.setupPaymentFlow.paymentPlan
            });
        }
    }

    sendSetupAutoPaySuccessCtmEvent(): void {
        if (this.successfullyEnrolled) {
            this.eventService.handleCtmEvent(
                CtmEventType.PP_SETUP_EASY_PAYMENT_METHOD, {
                productType: CtmProductType.BILLING,
                paymentMethod: this.setupAutomaticPaymentConfirmationService.fetchCtmPaymentMethod(this.paymentMethod, this.setupAutoPaymentForm)
            });
        }
    }

    setFormattedCurrentDate(): void {
        const dateFormat = 'MM/dd/yyyy';
        const timeFormat = 'hh:mm aa';
        const date = this.easternTimeDatePipe.transform(new Date(), dateFormat);
        const time = this.easternTimeDatePipe.transform(new Date(), timeFormat);
        this.formattedCurrentDate = `${date}, ${time} ET`;
    }

    private logPaperlessSuccessfulEnrollment(): void {
        if (this.wasEsddaEnrollmentSuccessful) {
            this.logger.info('user enrolled in paperless successfully through setup automatic payments flow');
        }
    }

    private logPreviouslyHadSAP417ErrorOnSuccess(): void {
        if (this.successfullyEnrolled && this.session.hadAutoPay417Error === 'true') {
            this.logger.info('User successfully enrolled in automatic payments after receiving SAP 417 error');
        }
    }

    get wasEsddaEnrollmentSuccessful(): boolean {
        return this.selectedPaperless && this.setupPaymentFlow.setupPaymentResponse?.esddaPreferences === true;
    }

    get secondaryButton(): ButtonMetaData {
        const buttonMetaData: ButtonMetaData = {
            label: 'Payment preferences',
            navigation: environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString)
        };
        return buttonMetaData;
    }

    get selectedPaperless(): boolean {
        return this.setupPaymentFlow.selectedPaperless === 'yes';
    }

    get paymentMethod(): PaymentItem {
        return this.setupPaymentFlow.selectedPayMethod;
    }

    get setupAutoPaymentForm(): PaymentFormGroup {
        return this.setupPaymentFlow.setUpAutomaticPaymentsForm;
    }

    get paymentType(): string {
        return this.setupAutomaticPaymentConfirmationService.paymentType;
    }

    get maskedNumber(): string {
        return this.setupAutomaticPaymentConfirmationService.maskedNumber;
    }

    get upNextLabel(): string {
        return this.setupAutomaticPaymentConfirmationService.upNextLabel;
    }

    get successfullyEnrolled(): boolean {
        return this.setupPaymentFlow.successfullyEnrolled;
    }

    initializeErrorMessage(): void {
        let enrollmentErrorMessage = '';
        const bankCardErrorCode = 417;
        if (this.session.isSAP && this.setupPaymentFlow?.errorDetails?.errorCode === bankCardErrorCode) {
            enrollmentErrorMessage = this.setupPaymentFlow.errorDetails.errorMessage;
            this.logger.info(`User received SAP 417 error on auto pay enrollment submission: ${enrollmentErrorMessage}`);
            this.session.hadAutoPay417Error = 'true';
        } else {
            enrollmentErrorMessage = WARNING_MESSAGES.API_FAILURE;
        }

        this.enrollmentErrorMessage = enrollmentErrorMessage;
    }

    get isPaperlessEnrollmentUnsuccessful(): boolean {
        return this.setupAutomaticPaymentConfirmationService.paperlessEnrollmentError(this.paperlessConfirmationMessage);
    }

    get errorMessage(): string {
        return this.setupAutomaticPaymentConfirmationService.errorMessage;
    }
}
export interface ButtonMetaData {
    label: string;
    navigation: string;
}
