import { Directive, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription, filter } from 'rxjs';

@Directive({
    selector: '[appFocusOnRouteChange]'
})
export class FocusOnRouteChangeDirective implements AfterViewInit, OnDestroy {
    routerSubscription: Subscription;

    constructor(
        private router: Router,
        private elRef: ElementRef
    ) { }

    ngAfterViewInit(): void {
        this.routerSubscription = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe(() => {
            window.scrollTo(0, 0);
            const element = <HTMLElement> this.elRef.nativeElement;
            element.setAttribute('tabindex', '-1');
            element.focus();
        });
    }

    ngOnDestroy(): void {
        this.routerSubscription?.unsubscribe();
    }
}
