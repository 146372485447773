import { Component, OnDestroy } from '@angular/core';
import { AddRefundMethodFlowService } from './services/add-refund-method-flow.service';
@Component({
    selector: 'app-add-refund-method',
    templateUrl: './add-refund-method.component.html',
    styleUrls: ['./add-refund-method.component.scss']
})
export class AddRefundMethodComponent implements OnDestroy {
    constructor(private addRefundFlowService: AddRefundMethodFlowService) { }

    ngOnDestroy(): void {
        this.addRefundFlowService.clear();
    }
}
