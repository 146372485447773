import { UntypedFormGroup } from '@angular/forms';

export class AreYouSureFormGroup {
    constructor(
        public group: UntypedFormGroup
    ) { }

    get selection(): string {
        const control = this.group.get('areYouSureFormGroup.selection');
        return control?.value || '';
    }
}
