import { Inject, Injectable } from '@angular/core';
import { PBS, IsbExperience } from '@nationwide/dgs-angular-billing-common';
import { SharedRCAAdapter } from '../../../../shared/shared-base.adapter';
import { PaymentPrefsSectionPlaceholder } from '../models/placeholder.model';
import { WARNING_MESSAGES } from '../constants/warning-messages';
import { AutomaticPayments, AutomaticPaymentsAccordion } from '../../view/automatic-payments/automatic-payments.model';
import { STUB_UI } from '../../view/automatic-payments/stub-ui';
import { PaymentPreferencesStateService } from '../services/payment-preferences-state/payment-preferences-state.service';
import { PaymentItem } from '../../shared/models/payment-item.model';
import { BankAccountPaymentItem, BankAccountType } from '../models/bankaccount-item.model';
import { BankCardPaymentItem } from '../models/bankcard-item.model';
import { PaymentMethodsAdapter } from './payment-methods.adapter';
import { CardType } from '../../../../shared/payments/card-utils/card-type.enum';
import { SessionService } from '../../../../shared/session/session.service';
import { RemoveDuplicatesPipe, EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';
import { IsbSharedCommonHelperService } from '../../../../shared/isb-shared-common-helper.service';
import { LoggerService } from '../../../../shared/logger/logger.service';

@Injectable()
export class EasyPayAdapter extends SharedRCAAdapter {
    AUTO_PAY_SUBHEADER = '(enrolled in automatic payments)';

    // eslint-disable-next-line max-params
    constructor(
        removeDuplicatesPipe: RemoveDuplicatesPipe,
        private paymentMethodsAdapter: PaymentMethodsAdapter,
        private paymentPreferencesState: PaymentPreferencesStateService,
        private easternDatePipe: EasternTimeDatePipe,
        session: SessionService,
        protected sharedService: IsbSharedCommonHelperService,
        @Inject('logger') private logger: LoggerService
    ) {
        super(
            removeDuplicatesPipe,
            session,
            sharedService
        );
    }

    isSingleAutoPolicy(policies: PBS.Agreement[]): boolean {
        return policies.filter((policy) => policy.agreementLabel === 'Auto').length === 1;
    }

    getNonCancelledPolicyAgreements(policies: PBS.Agreement[]): PBS.Agreement[] {
        return policies.filter((policy) => policy.status !== 'Cancelled');
    }
    isAgreementAutoPolicy(policies: PBS.Agreement[]): PBS.Agreement {
        return policies.find((policy) => policy.agreementLabel === 'Auto');
    }

    isAgreementBilling(policies: PBS.Agreement[], billAcNum): PBS.Agreement {
        return policies.find((policy) => policy.agreementType === 'BILLING' && policy.agreementNumber === billAcNum);
    }

    extractNonBillingAccounts(rcaResponse: PBS.RetriveCustomerAgreementResponse): PBS.Agreement[] {
        return rcaResponse.retrieveCustomerAgreementResponse.return.profile.agreementGroup.agreement.filter(
            (agreement) => agreement.agreementType !== 'BILLING' && agreement.status !== 'Cancelled'
        );
    }

    mapEasyPayMethod(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse, rcaResponse: PBS.RetriveCustomerAgreementResponse): AutomaticPayments {
        let automaticPayments: AutomaticPayments;
        this.paymentPreferencesState.eligibleForAutomaticPayments = true; // TODO: update this to conditionally set the value based on business rules

        if (this.ezPayUnavailable(unmappedAutomaticPayments, rcaResponse)) {
            automaticPayments = AutomaticPayments.create({
                warningMessages: [WARNING_MESSAGES.API_TIMEOUT]
            });
        } else if (this.isThirdPartyBilled(rcaResponse)) {
            automaticPayments = AutomaticPayments.create({
                infoMessages: [
                    `Third-party billed accounts (i.e., mortgage/escrow) are not eligible for automatic payments . `
                ]
            });
        } else if (this.enrolled(rcaResponse) && this.draftPending(rcaResponse)) {
            automaticPayments = this.mapEnrolledDraftPending(unmappedAutomaticPayments);
        } else if (this.isIneligibleforEasyPay(rcaResponse) && this.isWarned(rcaResponse)) {
            automaticPayments = this.mapWarnedInelligble();
        } else if (this.isWarned(rcaResponse) && this.enrolled(rcaResponse)) {
            automaticPayments = this.mapEnrolledisAccountWarned(unmappedAutomaticPayments, rcaResponse);
        } else if (!this.enrolled(rcaResponse) && this.isWarned(rcaResponse) && this.canEnroll(unmappedAutomaticPayments, rcaResponse)) {
            automaticPayments = this.notEnrolled(unmappedAutomaticPayments, rcaResponse);
        } else if (this.enrolled(rcaResponse) && this.hasSmartMiles(rcaResponse)) {
            automaticPayments = this.mapEnrolledSmartMiles(unmappedAutomaticPayments);
        } else if (!this.enrolled(rcaResponse) && this.hasScheduledPayment(rcaResponse)) {
            automaticPayments = this.mapSFPInelligble(rcaResponse);
        } else if (this.enrolled(rcaResponse) && this.ezPaymentSuspended(rcaResponse)) {
            automaticPayments = this.mapEnrolledSuspended(unmappedAutomaticPayments, rcaResponse);
        } else if (this.enrolled(rcaResponse) && !this.ezPaymentSuspended(rcaResponse)) {
            automaticPayments = this.mapEnrolledNotSuspended(unmappedAutomaticPayments, rcaResponse);
        } else if (this.enrolled(rcaResponse) && this.outsideStopWindow(rcaResponse)) {
            automaticPayments = this.mapEnrolledOusideStopWindow(rcaResponse);
        } else if (this.enrolled(rcaResponse) && this.insideStopWindow(rcaResponse)) {
            automaticPayments = this.mapEnrolledInsideStopWindow(rcaResponse);
        } else if (this.canEnroll(unmappedAutomaticPayments, rcaResponse)) {
            automaticPayments = AutomaticPayments.create({
                placeholderMessage: new PaymentPrefsSectionPlaceholder(
                    'You have not set up automatic payments ',
                    [
                        `
            Tired of going online, phoning in, or writing checks to pay your bill?
            With automatic payments we pull from your account before your due date, so you can set it and forget it!
            You can save time when you choose automatic payments, and money on your bill when you say goodbye to late fees.
          `
                    ]
                ),
                disclaimer: [`
        Payments will be automatically deducted from your account of choice on the due date
        (or the next business day, if the due date falls on a weekend or holiday).
        You can always stop or cancel automatic payments at any time if you need (just allow 5 days to process).
      `],
                enrollmentStatus: 'canEnroll'
            });
        } else {
            this.logger.info('Unable to map automaticPayments.', { ...rcaResponse, unmappedAutomaticPayments });
            automaticPayments = AutomaticPayments.create({
                warningMessages: [WARNING_MESSAGES.API_FAILURE_SHORT]
            });
        }
        return automaticPayments;
    }

    getFullName(payPlan: IsbExperience.GetPayPlanResponse): string {
        let fullName: string;
        const payorInfo = payPlan.paymentMethod.payorInfo;
        fullName = payorInfo.fullName;
        if (!payorInfo.fullName) {
            fullName = payorInfo.middleName ? `${payorInfo.firstName} ${payorInfo.middleName} ${payorInfo.lastName}` :
                `${payorInfo.firstName} ${payorInfo.lastName}`;
        }
        return fullName;
    }

    getPaymentItem(payPlan: IsbExperience.GetPayPlanResponse): PaymentItem {
        const { paymentMethod } = payPlan;
        const { paymentMethodType } = paymentMethod;
        let accountItem: BankAccountPaymentItem | BankCardPaymentItem;
        if (paymentMethodType === 'ElectronicFundsTransfer.PaymentMethod') {
            const { payorInfo, electronicFundsTransfer } = paymentMethod;
            accountItem = new BankAccountPaymentItem(
                payorInfo.firstName || payorInfo.fullName,
                payorInfo.lastName || '',
                payorInfo.middleName,
                payorInfo.addressLine1,
                '',
                payorInfo.city,
                payorInfo.state,
                payorInfo.postalCode,
                electronicFundsTransfer.bankName,
                this.retrieveBankAccountType(electronicFundsTransfer.bankAccountType),
                electronicFundsTransfer.bankRoutingNumber,
                this.getMaskedBankAccountNumber(electronicFundsTransfer.bankAccountNumber),
                electronicFundsTransfer.bankAccountNumber
            );
        } else {
            const { payorInfo, bankCard } = paymentMethod;
            accountItem = new BankCardPaymentItem(
                payorInfo.firstName || payorInfo.fullName,
                payorInfo.lastName || '',
                payorInfo.middleName,
                bankCard.cvvCode,
                payorInfo.postalCode,
                this.retrieveBankCardType(bankCard.cardBrand),
                bankCard.ccLastFour,
                this.retrieveBankCardExpirationDate(bankCard.expirationDate),
                bankCard.ccLastFour
            );
        }
        return new PaymentItem(accountItem);
    }

    nextDueDate(rcaResponse: any): string {
        let nextDueDate: string;
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const nextDueDateFromRCA = billingAgreement?.billingAccountDetail?.billingDetail?.nextDueDate;

        if (nextDueDateFromRCA) {
            nextDueDate = this.easternDatePipe.transform(new Date(nextDueDateFromRCA), 'MM/dd/yyyy');
        }

        return nextDueDate;
    }

    private mapSFPInelligble(rcaResponse: PBS.RetriveCustomerAgreementResponse): AutomaticPayments {
        const scheduledPaymentDate = this.scheduledPaymentDateAccountLevel(rcaResponse);
        const formattedPaymentDate = this.getEasterDateTime(scheduledPaymentDate);
        return AutomaticPayments.create({
            warningMessages: [`Because you have already scheduled a payment for ${formattedPaymentDate}, you cannot set up automatic payments  at this time. If you'd like to enroll now, please cancel your scheduled payment.`],
            placeholderMessage: new PaymentPrefsSectionPlaceholder(
                'You have not set up automatic payments ',
                [
                    `
            No checks. No stamps. No trips to the post office.
            Have your payments automatically deducted from the same account every month.
            Simply enter your bank account or debit/credit card information and your payments
            will be automatically deducted at the same time each month. That's all there is to it!
          `,
                    `
            If you ever want to cancel automatic payments , you can do so online anytime. Please allow 5 days to process.
          `,
                    `
            By enrolling in automatic payments , your third-party electronic bill account will be deactivated.
          `
                ]
            ),
            disclaimer: [`
        Payments are automatically deducted from your account on the due date
        (or the next business day, if the due date falls on a weekend or holiday).
        For assistance, please contact your local agent or call 1-877-669-6877.
        Representatives are available Monday through Friday from 8AM to 9PM ET.
      `],
            enrollmentStatus: 'enrollmentDisabled'
        });
    }

    private mapEnrolledDraftPending(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse): AutomaticPayments {
        return AutomaticPayments.create({
            warningMessages: [WARNING_MESSAGES.EZ_PAY_ENROLLED_CHANGES_CANNOT_BE_MADE],
            accordion: AutomaticPaymentsAccordion.create({
                header: this.getAccordionHeaderContent(unmappedAutomaticPayments),
                subHeader: this.AUTO_PAY_SUBHEADER,
                payMethod: this.getPaymentItem(unmappedAutomaticPayments)
            }),
            disclaimer: this.disclaimerConent,
            authAgreementDisplayable: true
        });
    }

    private mapEnrolledSuspended(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse, rcaResponse: PBS.RetriveCustomerAgreementResponse): AutomaticPayments {
        return AutomaticPayments.create({
            accordion: AutomaticPaymentsAccordion.create({
                header: this.getAccordionHeaderContent(unmappedAutomaticPayments),
                subHeader: this.hasLifeOnlyBusiness(rcaResponse) ? `(stopped until your next bill)` : `(stopped until ${this.easternDatePipe.transform(new Date(this.getDueDateAccountLevel(rcaResponse)), 'MM/dd/yyyy')})`,
                canCancel: true,
                canEdit: true,
                payMethod: this.getPaymentItem(unmappedAutomaticPayments)
            }),
            disclaimer: this.disclaimerConent,
            authAgreementDisplayable: true
        });
    }

    private mapEnrolledNotSuspended(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse, rcaResponse: PBS.RetriveCustomerAgreementResponse): AutomaticPayments {
        return AutomaticPayments.create({
            accordion: AutomaticPaymentsAccordion.create({
                header: this.getAccordionHeaderContent(unmappedAutomaticPayments),
                subHeader: this.AUTO_PAY_SUBHEADER,
                canCancel: true,
                canEdit: true,
                payMethod: this.getPaymentItem(unmappedAutomaticPayments)
            }),
            canSuspend: this.extractCanSuspend(rcaResponse),
            disclaimer: this.disclaimerConent,
            authAgreementDisplayable: true,
            creationDate: this.creationDate(unmappedAutomaticPayments)
        });
    }

    private mapEnrolledisAccountWarned(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse, rcaResponse: PBS.RetriveCustomerAgreementResponse): AutomaticPayments {
        return AutomaticPayments.create({
            accordion: AutomaticPaymentsAccordion.create({
                header: this.getAccordionHeaderContent(unmappedAutomaticPayments),
                subHeader: this.AUTO_PAY_SUBHEADER,
                canCancel: true,
                canEdit: true,
                isAccountWarned: true,
                payMethod: this.getPaymentItem(unmappedAutomaticPayments)
            }),
            canSuspend: this.extractCanSuspend(rcaResponse),
            disclaimer: this.disclaimerConent,
            authAgreementDisplayable: true,
            creationDate: this.creationDate(unmappedAutomaticPayments)
        });
    }

    private notEnrolled(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse, rcaResponse: PBS.RetriveCustomerAgreementResponse): AutomaticPayments {
        return AutomaticPayments.create({
            canSuspend: this.extractCanSuspend(rcaResponse),
            // disclaimer: this.disclaimerConent,
            authAgreementDisplayable: true,
            isAccountWarned: true,
            creationDate: this.creationDate(unmappedAutomaticPayments),

            placeholderMessage: new PaymentPrefsSectionPlaceholder(
                'You have not set up automatic payments',
                [
                    `
            No checks. No stamps. No trips to the post office.
            Have your payments automatically deducted from the same account every month.
            Simply enter your bank account or debit/credit card information and your payments
            will be automatically deducted at the same time each month. That's all there is to it!
          `,
                    `
            If you ever want to cancel automatic payments, you can do so online anytime. Please allow 5 days to process.
          `,
                    `
            By enrolling in automatic payments, your third-party electronic bill account will be deactivated.
          `
                ]
            ),
            disclaimer: [`
        Payments are automatically deducted from your account on the due date
        (or the next business day, if the due date falls on a weekend or holiday).
        For assistance, please contact your local agent or call 1-877-669-6877.
        Representatives are available Monday through Friday from 8AM to 9PM ET.
      `],
            enrollmentStatus: 'enrollmentDisabled'
        });
    }

    private mapEnrolledInsideStopWindow(rcaResponse: any): AutomaticPayments {
        // TODO: REPLACE WITH REAL IMPL
        return null;
    }

    private mapWarnedInelligble(): AutomaticPayments {
        return AutomaticPayments.create({
            warningMessages: [WARNING_MESSAGES.WARNED_INELIGIBLE_EASYPAY]
        });
    }

    private mapEnrolledSmartMiles(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse): AutomaticPayments {
        return AutomaticPayments.create({
            accordion: AutomaticPaymentsAccordion.create({
                hasSmartMiles: true,
                header: this.getAccordionHeaderContent(unmappedAutomaticPayments),
                subHeader: this.AUTO_PAY_SUBHEADER,
                canCancel: false,
                canEdit: true,
                payMethod: this.getPaymentItem(unmappedAutomaticPayments)
            }),
            disclaimer: this.disclaimerConent,
            authAgreementDisplayable: true
        });
    }

    private mapEnrolledOusideStopWindow(rcaResponse: any): AutomaticPayments {
        // TODO: REPLACE WITH REAL IMPL
        return STUB_UI.ENROLLED_NOT_STOPPED;
    }

    private ezPayUnavailable(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse, rcaResponse: PBS.RetriveCustomerAgreementResponse): boolean {
        return unmappedAutomaticPayments === null || rcaResponse === null || this.isLifeBilled(unmappedAutomaticPayments);
    }

    private isThirdPartyBilled(rcaResponse: any): boolean {
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        let isThirdPartyBilled = false;
        const paymentMethod = this.getPaymentMethod(billingAgreement);
        if (paymentMethod === 'CPP3P' || paymentMethod === 'BILL 3/P') {
            isThirdPartyBilled = true;
        }
        return isThirdPartyBilled;
    }

    isMonthlyPaymentPlan(isPbsRuleValueEligibleAutoMvpPayPlan: boolean, paymentPlan: string, isFullPayPlan: boolean): boolean {
        return isPbsRuleValueEligibleAutoMvpPayPlan && paymentPlan?.equalsIgnoreCase('Monthly') && isFullPayPlan;
    }

    isFullPayPaymentPlan(isPbsRuleValueEligibleAutoMvpPayPlan: boolean, paymentPlan: string, isMonthlyPlan: boolean): boolean {
        return isPbsRuleValueEligibleAutoMvpPayPlan && paymentPlan?.equalsIgnoreCase('Full Pay') && isMonthlyPlan;
    }

    private isWarned(rcaResponse: PBS.RetriveCustomerAgreementResponse): boolean {
        const billingAccount = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        return this.isAccountWarned(billingAccount);
    }

    private isIneligibleforEasyPay(rcaResponse: PBS.RetriveCustomerAgreementResponse): boolean {
        return !this.isEligibleForEasyPay(rcaResponse);
    }

    private creationDate(automaticPayments: IsbExperience.GetPayPlanResponse): string {
        return automaticPayments.paymentMethod.creationDate;
    }

    private hasScheduledPayment(rcaResponse: PBS.RetriveCustomerAgreementResponse): boolean {
        return this.isPaymentScheduledAccountLevel(rcaResponse) && this.getBillingStatus(rcaResponse) !== 'Billed';
    }

    private getEasterDateTime(scheduledPaymentDate): string {
        return this.easternDatePipe.transform(new Date(scheduledPaymentDate), 'MM/dd/yyyy');
    }

    private draftPending(rcaResponse: any): boolean {
        return this.isPaymentFutureOrDraftPending(rcaResponse);
    }

    private ezPaymentSuspended(rcaResponse: any): boolean {
        return this.isAutomaticPaymentSuspended(rcaResponse);
    }

    private getAccordionHeaderContent(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse): string {
        const paymentItem = this.getPaymentItem(unmappedAutomaticPayments);
        return paymentItem.paymentPrefsDisplayName;
    }

    private retrieveBankAccountType(bankAccountType: string): BankAccountType {
        return this.paymentMethodsAdapter.retrieveBankAccountType(bankAccountType);
    }

    private retrieveBankCardType(cardBrand: string): CardType {
        return this.paymentMethodsAdapter.retrieveBankCardType(cardBrand);
    }

    private retrieveBankCardExpirationDate(expirationDate: string): Date {
        return this.paymentMethodsAdapter.retrieveBankCardExpirationDate(expirationDate);
    }

    private outsideStopWindow(rcaResponse: any): boolean {
        // TODO: REPLACE WITH REAL IMPL
        return false;
    }

    private insideStopWindow(rcaResponse: any): boolean {
        // TODO: REPLACE WITH REAL IMPL
        return false;
    }

    private canEnroll(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse, rcaResponse: PBS.RetriveCustomerAgreementResponse): boolean {
        let canEnrollAutoPay = false;
        if (this.isEligibleForEasyPay(rcaResponse) && (unmappedAutomaticPayments.billingMethodType !== 'Recurring EFT' &&
            unmappedAutomaticPayments.billingMethodType !== 'Recurring Bankcard')) {
            canEnrollAutoPay = true;
        }
        return canEnrollAutoPay;
    }

    private hasSmartMiles(rcaResponse: PBS.RetriveCustomerAgreementResponse): boolean {
        const permissions = this.getPermissions(rcaResponse);
        const smartMilesPermission = this.getSmartMilesPermission(permissions);
        return this.isEnrolledInSmartMiles(smartMilesPermission);
    }

    get disclaimerConent(): string[] {
        return [`
    Payments will be automatically deducted from your account of choice on the due date
        (or the next business day, if the due date falls on a weekend or holiday).
        You can always stop or cancel automatic payments at any time if you need (just allow 5 days to process).
      `];
    }

    private isLifeBilled(unmappedAutomaticPayments: IsbExperience.GetPayPlanResponse): boolean {
        return unmappedAutomaticPayments?.billingMethodType.toLowerCase() === 'life billed';
    }
}
