import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';

export type ControlNames = 'accountNumberGroup' | 'accountTypeGroup' | 'bankAccountInfo';

@Component({
    selector: 'app-bank-account-info-manual',
    templateUrl: './bank-account-info-manual.component.html',
    styleUrls: ['./bank-account-info-manual.component.scss']
})

export class BankAccountInfoManualComponent extends BaseFormGroupComponent<ControlNames> implements OnInit {
    @Input() plaidOption?: boolean;
    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        this.formGroup.get('bankAccountInfo').valueChanges.subscribe((valueSave) => {
            const valueDefault = this.formGroup.get('accountNumberGroup').value;
            if (valueSave && valueDefault) {
                this.formGroup.get('accountNumberGroup').reset();
                this.formGroup.get('accountTypeGroup').reset();
            }
        });
        if (this.plaidOption && this.formGroup.get('bankAccountInfo').value !== 'ManualAccount') {
            this.formGroup.get('accountNumberGroup').reset();
            this.formGroup.get('accountTypeGroup').reset();
        }
    }
}
