import { BankAccountPaymentItem } from './bankaccount-item.model';
import { BankCardPaymentItem } from './bankcard-item.model';
import { NewBankAccountMethod } from './new-bank-account.model';
import { NewBankCardMethod } from './new-bank-card.model';
import { CardType, paymentPreferencesCardTypeMap } from '../../../../shared/payments/card-utils/card-type.enum';
import { WalletPayPaymentItem } from './masterpass-item.model';
import { PlaceholderPaymentItem } from './placeholder-item.model';
import { NewDebitCardMethod } from './new-debit-card.model';
import { PAYMENT_METHOD_TYPES } from '../../../../shared/constants/common.constants';

export class PaymentItem {
    // eslint-disable-next-line max-params
    constructor(
        readonly item: BankAccountPaymentItem | BankCardPaymentItem | WalletPayPaymentItem | PlaceholderPaymentItem,
        readonly isDefault = false,
        readonly id = '',
        readonly relatedPaymentMethodIDs: string[] = [],
        readonly recurringMethod = false
    ) { }

    get isNewMethod(): boolean {
        return this.item instanceof NewBankAccountMethod || this.item instanceof NewBankCardMethod || this.item instanceof NewDebitCardMethod || this.item instanceof WalletPayPaymentItem || !(this.item instanceof PlaceholderPaymentItem) && this.id === '';
    }

    get isNewBankAccount(): boolean {
        return this.item instanceof NewBankAccountMethod;
    }

    get isNewBankCard(): boolean {
        return this.item instanceof NewBankCardMethod;
    }

    get isNewDebitCard(): boolean {
        return this.item instanceof NewDebitCardMethod;
    }

    get isBankCard(): boolean {
        return this.item instanceof BankCardPaymentItem;
    }

    get isDebitCard(): boolean {
        return this.item instanceof BankCardPaymentItem && this.item.creditOrDebit === 'Debit';
    }

    get isBankAccount(): boolean {
        return this.item instanceof BankAccountPaymentItem;
    }

    get isMasterPass(): boolean {
        return this.item instanceof WalletPayPaymentItem;
    }

    get isPlaceholder(): boolean {
        return this.item instanceof PlaceholderPaymentItem;
    }

    get methodType(): string {
        let methodType;
        if (this.item instanceof BankCardPaymentItem) {
            methodType = this.item.cardType;
        } else if (this.item instanceof BankAccountPaymentItem) {
            methodType = this.item.accountType;
        } else if (this.item instanceof WalletPayPaymentItem) {
            methodType = this.item.walletType;
        } else if (this.item instanceof PlaceholderPaymentItem) {
            methodType = this.item.placeholderName;
        }
        return methodType;
    }

    get displayName(): string {
        let displayName;
        if (this.isMasterPass) {
            displayName = 'Click to pay';
        } else if (this.isPlaceholder) {
            displayName = this.methodType;
        } else if (!this.isNewMethod) {
            displayName = `${this.methodType} ...${this.item && !(this.item instanceof WalletPayPaymentItem) ? this.item.displayNumbers : ''}`;
        } else if (this.isBankAccount) {
            displayName = 'New checking/savings account';
        } else if (this.isDebitCard) {
            displayName = 'New debit card';
        } else if (this.isBankCard) {
            displayName = 'New credit/debit card';
        }
        return displayName;
    }

    get paymentPrefsDisplayName(): string {
        let paymentPrefsDisplayName;
        if (this.isBankCard) {
            paymentPrefsDisplayName = `${paymentPreferencesCardTypeMap.get(<CardType> this.methodType)} ...${this.item && !(this.item instanceof WalletPayPaymentItem) ? this.item.displayNumbers : ''}`;
        } else if (this.isBankAccount) {
            paymentPrefsDisplayName = `Bank Account ...${this.item && !(this.item instanceof WalletPayPaymentItem) ? this.item.displayNumbers : ''}`;
        }
        return paymentPrefsDisplayName;
    }

    get managePaymentPrefsDisplayName(): string {
        if (this.isBankCard) {
            return `${paymentPreferencesCardTypeMap.get(<CardType> this.methodType)} ` +
                `${this.maskedAccountNumbers}`;
        } else if (this.isBankAccount) {
            return `${this.methodType} ${this.maskedAccountNumbers}`;
        }
        return this.displayName;
    }

    get maskedAccountNumbers(): string {
        let accountNumbers: string;
        if (this.isBankAccount) {
            accountNumbers = `*********${this.item && !(this.item instanceof WalletPayPaymentItem) ? this.item.displayNumbers : ''}`;
        } else if (this.isBankCard) {
            accountNumbers = `************${this.item && !(this.item instanceof WalletPayPaymentItem) ? this.item.displayNumbers : ''}`;
        }

        return accountNumbers;
    }

    get shortDisplayName(): string {
        let displayName = '';
        if (this.isBankAccount) {
            displayName = 'New checking/savings account';
        } else if (this.isDebitCard) {
            displayName = 'New debit card';
        } else if (this.isBankCard) {
            displayName = 'New credit/debit card';
        } else if (this.isPlaceholder) {
            displayName = this.displayName;
        }
        return displayName;
    }

    equals(otherItem: PaymentItem): boolean {
        return this.displayName === otherItem.displayName;
    }

    get displayNumbers(): string {
        return this.item instanceof WalletPayPaymentItem ? '' : this.item.displayNumbers;
    }

    get bankAccountRoutingNumber(): string {
        return this.item instanceof BankAccountPaymentItem ? this.item.routingNumber : '';
    }

    get bankName(): string {
        return this.item instanceof BankAccountPaymentItem ? this.item.bankName : '';
    }

    get bankCardExpirationDate(): string {
        return this.item instanceof BankCardPaymentItem ? `${this.item.expirationMonth}/${this.item.expirationYear}` : '';
    }

    get bankCardZipCode(): string {
        return this.item instanceof BankCardPaymentItem ? this.item.zipCode : '';
    }

    getWaysToPayButtonLabel(action = 'Use'): string {
        let waysToPayButtonLabel = 'Continue';
        if (this.isBankAccount || this.isBankCard) {
            waysToPayButtonLabel = `${action} ${this.getWaysToPayMethodLabel()}`;
        }

        return waysToPayButtonLabel;
    }

    getWaysToPayMethodLabel(): string {
        const maxDigitsToDisplay = 4;
        let waysToPayMethodLabel = '';
        if (this.isBankAccount) {
            const maskedBankAccountNumber = (<BankAccountPaymentItem> this.item).maskedBankAccountNumber;
            const bankName = (<BankAccountPaymentItem> this.item).bankName;
            const numberOfDigitsToDisplay = maskedBankAccountNumber.length < maxDigitsToDisplay ? maskedBankAccountNumber.length : maxDigitsToDisplay;
            waysToPayMethodLabel = `${bankName} ${this.bulletPoint()}${maskedBankAccountNumber.slice(maskedBankAccountNumber.length - numberOfDigitsToDisplay)}`;
        } else if (this.isBankCard) {
            waysToPayMethodLabel = `${(<BankCardPaymentItem> this.item).cardType} ${this.bulletPoint()}${(<BankCardPaymentItem> this.item).maskedCardNumber}`;
        }

        return waysToPayMethodLabel;
    }

    private bulletPoint(): string {
        const NUMBER_OF_BULLET_POINTS = 4;
        const bulletPoint = '\u2022';
        return bulletPoint.repeat(NUMBER_OF_BULLET_POINTS);
    }

    assertIsBankCard(paymentItem: BankAccountPaymentItem | BankCardPaymentItem | WalletPayPaymentItem | PlaceholderPaymentItem): paymentItem is BankCardPaymentItem {
        return paymentItem instanceof BankCardPaymentItem;
    }

    assertIsBankAccount(paymentItem: BankAccountPaymentItem | BankCardPaymentItem | WalletPayPaymentItem | PlaceholderPaymentItem): paymentItem is BankAccountPaymentItem {
        return paymentItem instanceof BankAccountPaymentItem;
    }

    get itemType(): string {
        let itemType = '';
        if (this.isBankAccount) {
            itemType = PAYMENT_METHOD_TYPES.BANK_ACCOUNT;
        } else if (this.isBankCard) {
            itemType = PAYMENT_METHOD_TYPES.BANK_CARD;
        } else if (this.isMasterPass) {
            itemType = PAYMENT_METHOD_TYPES.MASTERPASS;
        }

        return itemType;
    }
}
