<ng-container [formGroup]="formGroup">
    <ng-container *ngIf="updatingPaymentMethod">
        <p>Update credit/debit card</p>
        <app-name-field-set [header]="'Card holder\'s name'" class="to-container bolt-space-bottom-xl" formGroupName="nameGroup">
        </app-name-field-set>

        <app-bank-card-number-input formGroupName="bankCardFormGroup" [readonly]="true" (cardTypeSelection)="cardType = $event" [cardType]="cardType">
        </app-bank-card-number-input>

        <app-bank-card-expiration-form-control formGroupName="cardExpirationGroup">
        </app-bank-card-expiration-form-control>
    </ng-container>

    <div class="row bolt-space-bottom-xl">
        <app-cvv-form-control formGroupName="cvvGroup" class="col-md-5 col-sm-12" [cardType]="cardType">
        </app-cvv-form-control>
    </div>

    <div *ngIf="updatingPaymentMethod" class="row bolt-space-bottom-xl">
        <app-zip-code-form-control formGroupName="zipGroup" class="col-md-5 col-sm-12">
        </app-zip-code-form-control>
    </div>

    <div *ngIf="!defaultPaymentMethodIsSelected" class="row">
        <div class="col-md-12">
            <bolt-checkbox ngDefaultControl id="save-as-default-pay-method" formControlName="makeDefaultPaymentMethod" arialabel="Make this your default payment method">
                Make this your default payment method
                <bolt-contextual-help>
                    <p>This will be your preferred method of payment, even though you can add up to 10 credit card accounts and up to 10 bank accounts. You can also delete payment methods online
                        anytime.</p>
                </bolt-contextual-help>
            </bolt-checkbox>
        </div>
    </div>

</ng-container>