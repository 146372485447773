<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>
<div class="bolt-container bolt-space-bottom-2xl">

    <bw-bolt-progress-bar appFocusOnInit arialabel="add refund method progress value is 100%" class="progress-bar-container to-container" progressBarValue="100"></bw-bolt-progress-bar>
    <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Add refund method: confirmation</h1>
    <div class="bolt-row">
        <div class="bolt-col-12">
            <ng-container *ngIf="errorNotification | async as errorMessage; else successful">
                <bw-bolt-notification class="bolt-space-bottom-xl to-container" type="error" id="error-notification">
                    <div [innerHTML]="errorMessage"></div>
                </bw-bolt-notification>
            </ng-container>
            <ng-template #successful>
                <bw-bolt-notification id="confirmation-body" class="bolt-space-bottom-2xl to-container" type="success">
                    {{successNotification}}
                </bw-bolt-notification>
                <app-payment-sub-header>Confirmation details</app-payment-sub-header>
                <p class="space-bottom-0"><span class="font-weight-bold">Saved refund
                        method:</span>{{confirmationMessage}}</p>
            </ng-template>
        </div>
    </div>
</div>
<bolt-button-bar>
    <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
    <bolt-button id="submit-button" (click)="onContinue()" slot="forward">Payment preferences</bolt-button>
</bolt-button-bar>