/* eslint-disable no-useless-concat */
import { Component, Inject, OnInit } from '@angular/core';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

import { Router } from '@angular/router';
import { SessionService } from '../../../../../../shared/session/session.service';
import { RemoveRefundMethodFlowService } from '../services/remove-refund-method-flow.service';
import { environment } from '../../../../../../../environments/environment';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { WARNING_MESSAGES } from '../../../../shared/constants/warning-messages';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';

@Component({
    selector: 'app-remove-refund-method-confirmation',
    templateUrl: './remove-refund-method-confirmation.component.html',
    styleUrls: ['./remove-refund-method-confirmation.component.scss']
})
export class RemoveRefundMethodConfirmationComponent extends BasePageComponent implements OnInit {
    pageName = ManagePaymentPreferencesPageTitle.refundMethod.remove.confirmation;
    confirmationMessage: string;
    loadComplete = false;
    failureMessageHTML: string;

    // eslint-disable-next-line max-params
    constructor(
        private easternTimeDatePipe: EasternTimeDatePipe,
        private urlUtil: UrlUtil,
        router: Router,
        private flowService: RemoveRefundMethodFlowService,
        private eventService: BillingEventService,
        session: SessionService,
        @Inject('window') private window: Window
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.sendCtmEvent();
        this.setConfirmationMessage();
    }

    get apiCallSuccessful(): boolean {
        return this.flowService.apiCallSuccessful;
    }

    showPrintDialog(): void {
        this.window.print();
    }

    sendCtmEvent(): void {
        if (this.apiCallSuccessful) {
            this.eventService.handleCtmEvent(
                CtmEventType.ON_CLICK_OF_REMOVE_REFUND_METHOD_BTN, {
                productType: CtmProductType.BILLING
            });
        }
    }

    onContinue(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString));
    }

    setConfirmationMessage(): void {
        const maskedAccountNumber = this.flowService.maskedBankAccountNumber;
        const effectiveTime = `${this.easternTimeDatePipe.transform(new Date(), 'MM/dd/yyyy, hh:mm aa')} ET`;
        this.confirmationMessage = `${this.displayName} ` + `${maskedAccountNumber}, Effective ${effectiveTime}.`;
    }

    get displayName(): string {
        let name;
        const bankAccountType = this.flowService.refundMethod.electronicFundsTransfer.bankAccountType;
        if (bankAccountType === 'C' || bankAccountType === 'Checking') {
            name = 'Checking';
        } else {
            name = 'Savings';
        }
        return name;
    }

    get apiServiceDownMessage(): string {
        return WARNING_MESSAGES.API_FAILURE;
    }

    get upNextLabel(): string {
        return 'Payment preferences';
    }
}
