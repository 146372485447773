import { Injectable, Inject } from '@angular/core';
import { isbExperienceServiceToken, IsbExperienceService } from '@nationwide/dgs-angular-billing-common';
import { Observable, of, tap, map, catchError } from 'rxjs';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { CancelScheduleFuturePaymentFlow } from './cancel-schedule-future-payment-flow.service';
import { Payment } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience';
import { SessionService } from '../../../../../../shared/session/session.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { DateUtil } from '@nationwide/angular-timeline';

@Injectable()
export class CancelScheduleFuturePaymentService {
    // eslint-disable-next-line max-params
    constructor(
        private flow: CancelScheduleFuturePaymentFlow,
        private paymentPreferencesApi: PaymentPreferencesApiService,
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private session: SessionService,
        private easternTimeDatePipe: EasternTimeDatePipe,
        @Inject('logger') private logger: LoggerService,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private dateUtil: DateUtil
    ) { }

    retrieveScheduledPayment(): Observable<Payment> {
        return this.paymentPreferencesApi
            .cancelFutureSchedulePaymentsLanding(this.startDate, this.endDate).pipe(
                map((res) => {
                    const { retrieveCustomerAgreement, paymentsHistory } = res;
                    this.flow.save({ unmappedPaymentHistory: paymentsHistory });
                    if (paymentsHistory?.payments) {
                        const nextDueDate = this.easternTimeDatePipe.transform(new Date(this.managePaymentPrefsAdapter.fetchBillingAccountAmountDueDate(retrieveCustomerAgreement)), 'MM/dd/yyyy');
                        for (const payment of paymentsHistory.payments) {
                            if (payment.status === 'SCHEDULED') {
                                const rawDate: any[] = payment.paymentDate.split('-');
                                const paymentDueDate = new Date(rawDate[2], rawDate[0] - 1, rawDate[1]);
                                this.flow.save({
                                    paymentDate: this.easternTimeDatePipe.transform(paymentDueDate, 'MM/dd/yyyy'),
                                    paymentAmount: payment.amount,
                                    paymentMethodType: this.getPaymentMethodType(payment),
                                    receiptId: payment.paymentTxnDetails.receiptId,
                                    nextDueDate
                                });
                                return payment;
                            }
                        }
                    }
                })
            );
    }

    submit(): Observable<number | HttpErrorResponse> {
        return this.isbExperience.cancelScheduledPayment({
            agreementNumber: this.session.billingAccountNumber,
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            paymentId: this.flow.receiptId,
            startDate: this.dateUtil.formatDateToString(this.startDate, '-'),
            endDate: this.dateUtil.formatDateToString(this.endDate, '-'),
            policyNumbers: this.session.policyNumbers,
            payload: {
                omsData: {
                    agreementNumber: this.session.billingAccountNumber,
                    paymentAmount: this.flow.paymentAmount,
                    receivedDate: this.flow.paymentDate
                },
                paymentMethodType: this.flow.paymentMethodType
            }
        }).pipe(
            tap((res) => this.flow.save({ apiCallSuccessful: true, apiCallResponseCode: res })),
            catchError((err: HttpErrorResponse) => {
                this.logger.error('API ERROR: CANCEL SCHEDULED FUTURE PAYMENT SUBMISSION', err);
                this.flow.save({ apiCallSuccessful: false, apiCallResponseCode: err.status });
                return of(err);
            })
        );
    }

    private getPaymentMethodType(payment: Payment): string {
        return payment.paymentTxnDetails?.paymentMethod?.savedPaymentMethod?.savedPaymentMethod?.type === 'ELECTRONIC FUND TRANSFER' ?
            'EFT' :
            'Bankcard';
    }

    private get startDate(): Date {
        const MONTHS_IN_TERM = 11;
        return new Date(this.endDate.getFullYear(), this.endDate.getMonth() - MONTHS_IN_TERM, this.endDate.getDate());
    }

    private get endDate(): Date {
        return new Date();
    }
}
