export const ManagePaymentPreferencesPageTitle = {
    savedPaymentMethods: {
        add: 'Add New Payment Method',
        edit: {
            landing: 'Edit Saved Payment Method - Landing',
            review: 'Edit Saved Payment Method - Review',
            confirmation: 'Edit Saved Payment Method - Confirmation'
        },
        remove: {
            base: 'Remove Saved Payment Method',
            landing: 'Remove Saved Payment Method - Landing',
            confirmation: 'Remove Saved Payment Method - Confirmation'
        },
        setAsDefault: {
            landing: 'Set Default Payment Method - Landing',
            confirmation: 'Set Default Payment Method - Confirmation'
        }
    },
    refundMethod: {
        add: 'Add New Refund Method',
        edit: 'Edit Saved Refund Method',
        remove: {
            landing: 'Remove Saved Refund Method - Landing',
            confirmation: 'Remove Saved Refund Method - Confirmation'
        }
    },
    paymentPlan: {
        edit: {
            payplan: 'Edit Automatic Payments - Payment Plan',
            confirmation: 'Pay Plan - confirmation'
        }
    },
    automaticPayments: {
        setup: {
            landing: 'Setup Automatic Payments - Landing',
            review: 'Setup Automatic Payments - Review',
            confirmation: 'Setup Automatic Payments - Confirmation'
        },
        edit: {
            landing: 'Edit Automatic Payments - Landing',
            review: 'Edit Automatic Payments - Review',
            confirmation: 'Edit Automatic Payments - Confirmation'
        },
        cancel: {
            landing: 'Cancel Automatic Payments - Landing',
            confirmation: 'Cancel Automatic Payments - Confirmation'
        },
        suspend: {
            landing: 'Skip automatic payment',
            confirmation: 'Skip automatic payment: confirmation'
        }
    },
    scheduledFuturePayments: {
        cancel: {
            landing: 'Cancel Scheduled Future Payment - Landing',
            confirmation: 'Cancel Scheduled Future Payment - Confirmation'
        }
    }
};
