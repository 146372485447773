import { PaperlessConfirmationMessage } from '../models/paperless-confirmation-message';
import { PAPERLESS_ENROLL_MESSAGE } from '../../../../shared/constants/common.constants';
import { PaperlessLogging } from '../models/paperless-logging';
import { SessionService } from '../../../../shared/session/session.service';

export abstract class PaperlessEnrollmentBaseService {
    constructor(protected session: SessionService) { }

    paperlessEnrollmentError(paperlessConfirmationMessage: PaperlessConfirmationMessage): boolean {
        return paperlessConfirmationMessage.paperlessEnrollmentAttempted &&
            (!paperlessConfirmationMessage.savedPaperlessEsddaPreferences || !paperlessConfirmationMessage.enrolledInPaperless);
    }

    get errorMessage(): string {
        return this.paperlessEnrollmentError ? PAPERLESS_ENROLL_MESSAGE.ERROR_MESSAGE : '';
    }

    getPaperlessFlowMessages(enrolledInPaperless: boolean, paperlessEnrollmentAttempted: boolean, savedPaperlessEsddaPreferences: boolean): PaperlessConfirmationMessage {
        return {
            enrolledInPaperless,
            paperlessEnrollmentAttempted,
            savedPaperlessEsddaPreferences
        };
    }

    logPageLoadForPaperless(enrollInPaperless: string): PaperlessLogging {
        return {
            accountNumber: this.session.billingAccountNumber,
            enterpriseCustomerNumber: this.session.ecn,
            paperlessBillingDeliveryResponse: enrollInPaperless
        };
    }
}
