import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Bolt from '@nationwide-bolt/bundle-core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MasterpassConstants } from './tridion/masterpass-constants';
import { extendPrototypes } from './prototype-extensions';

declare global {
    interface Window {
        WebComponents: {
            ready: boolean;
        };
    }
}

if (environment.PRODUCTION) {
    enableProdMode();
}

const scriptAddresses = [
    {
        prodImport: '//tags.nationwide.com/Bootstrap.js',
        testImport: '//tags.nationwide.com/test/Bootstrap.js'
    },
    {
        prodImport: `https://src.mastercard.com/srci/integration/merchant.js?locale=en_us&checkoutid=${MasterpassConstants.CHECKOUT_ID}`,
        // this import breaks automation in test envs
        testImport: '' // `https://sandbox.src.mastercard.com/srci/integration/merchant.js?locale=en_us&checkoutid=${MasterpassConstants.CHECKOUT_ID}`
    }
];

function runningCrossOrigin(): boolean {
    return !window.location.href.includes(environment.ISB.HOST_URL);
}

if (!runningCrossOrigin()) {
    scriptAddresses.forEach((scriptObject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';

        if (environment.PRODUCTION) {
            script.src = scriptObject.prodImport;
        } else {
            script.src = scriptObject.testImport;
        }

        document.getElementsByTagName('head')[0].appendChild(script);
    });
}

Bolt.initialize().then(() => {
    extendPrototypes();
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((error) => console.log(error));
});
