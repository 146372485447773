import { PaymentHistoryMethod } from './payment-history-item-method.enum';
import { PaymentHistoryStatus } from './payment-history-item-status.enum';

export class PaymentHistoryItem {
    // eslint-disable-next-line max-params
    constructor(
        readonly method: PaymentHistoryMethod,
        readonly status: PaymentHistoryStatus,
        readonly date: Date,
        readonly amount: string,
        readonly receiptId: number,
        readonly paymentType: string
    ) { }
}
