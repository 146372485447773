import { Component, OnInit, Inject } from '@angular/core';
import { PersonalBillingService, PersonalBillingServiceToken, PersonalBilling as Unmapped } from '@nationwide/dgs-angular-billing-common';
import { SessionService } from '../../../../../shared/session/session.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { of, timeout, catchError } from 'rxjs';
import { LoggerService } from '../../../../../shared/logger/logger.service';

@Component({
    selector: 'app-edit-refund-method',
    templateUrl: './edit-refund-method.component.html',
    styleUrls: ['./edit-refund-method.component.scss']
})
export class EditRefundMethodComponent implements OnInit {
    savedRefundMethod: Unmapped.RefundMethod;
    pageLoadComplete = false;

    // eslint-disable-next-line max-params
    constructor(
        @Inject(PersonalBillingServiceToken) private personalBillingService: PersonalBillingService,
        private session: SessionService,
        private router: Router,
        @Inject('logger') private logger: LoggerService
    ) { }

    ngOnInit(): void {
        this.fetchSavedRefundMethod();
    }

    private fetchSavedRefundMethod(): void {
        this.personalBillingService
            .retrievePayPlanForRefund({
                accessToken: this.session.accessToken,
                accountNumber: this.session.billingAccountNumber,
                isZeroAmountDueServiceEnable: environment.ZERO_AMOUNT_DUE_API_SERVICE.isEnabled
            }).pipe(
                timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences),
                catchError((error) => {
                    this.logger.error('HARDFALL: EDIT REFUND METHOD API ERROR', { error });
                    this.navigateToHardfall();
                    return of(<any>{});
                })
            ).subscribe((payPlan) => {
                this.pageLoadComplete = true;
                this.savedRefundMethod = payPlan;
            });
    }

    private navigateToHardfall(): void {
        this.router.navigateByUrl('/personal/error/system-down');
    }
}
