<is-wait-message [loadComplete]="!callInProgress" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<fieldset class="section-content" id="save-as-refund-method-section" [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <div id="refund-enroll-group" class="group mb-12" role="radiogroup" aria-labelledby="refund-enroll-label">
        <h4 class="bolt-heading">Use this payment method to receive refunds, credits, and other payments due to you?
            <bw-bolt-contextual-help>
                {{helpText}}
            </bw-bolt-contextual-help>
        </h4>
        <app-form-control-error *ngIf="selectionRequiredErrorDisplayable" id="selection-required-error-message">
            Yes or No is required.
        </app-form-control-error>

        <app-form-control-error *ngIf="apiCallFailed" id="api-call-failed-error-message">
            We were unable to process your request at this time. Please try again later.
        </app-form-control-error>

        <bolt-radio-group label="" ngDefaultControl required name="selection" formControlName="selection" [attr.invalid]="selectionRequiredErrorDisplayable ? true : null" horizontal
            class="jm-visible">
            <bolt-radio value="yes">Yes</bolt-radio>
            <bolt-radio value="no">No</bolt-radio>
        </bolt-radio-group>
    </div>

    <div *ngIf="shouldDisplayAccounts" id="accounts-for-refund-container">
        <p class="font-weight-bold bolt-space-bottom-sm">
            Which billing account(s) would you like to use this saved payment and refund method for?</p>

        <app-form-control-error *ngIf="pleaseSelectAccountErrorDisplayable" id="account-not-selected-error-message" class="mb-3">Please select an account for refund, Yes was selected
            for the Use this account to receive refunds, credits and other payments due to you? question.
        </app-form-control-error>

        <app-billing-account-for-refund *ngFor="let account of billingAccounts; let index = index" [account]="account" [selected]="isAccountSelected(account)" (change)="handleAccountClick(account)"
            [checkboxId]="'save-as-refund-method-checkbox-' + index">
        </app-billing-account-for-refund>
    </div>
</fieldset>