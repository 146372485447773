import { Component, OnDestroy } from '@angular/core';
import { AddPaymentMethodFlowService } from './services/add-payment-method-flow.service';

@Component({
    selector: 'app-add-payment-method',
    templateUrl: './add-payment-method.component.html',
    styleUrls: ['./add-payment-method.component.scss']
})

export class AddPaymentMethodComponent implements OnDestroy {
    constructor(private addPaymentFlow: AddPaymentMethodFlowService) { }

    ngOnDestroy(): void {
        this.addPaymentFlow.clear();
    }
}
