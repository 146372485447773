import { ElementRef, AfterViewInit, Directive } from '@angular/core';

@Directive({
    selector: '[appFocusOnInit]'
})
export class FocusOnInitDirective implements AfterViewInit {
    constructor(
        private elRef: ElementRef
    ) { }

    ngAfterViewInit(): void {
        const element = <HTMLElement> this.elRef.nativeElement;
        element.setAttribute('tabindex', '-1');
        element.focus();
    }
}
