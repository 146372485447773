import { Injectable } from '@angular/core';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaymentItem } from '../../../../shared/models/payment-item.model';

@Injectable()
export class AddRefundMethodFlowService {
    selectedPaymentMethod: PaymentItem;
    addRefundMethodFormGroup: PaymentFormGroup;
    emailAddress: string;
    apiCallSuccessful: boolean;
    landingPageVisited = false;
    isNewBankAccount: boolean;

    save({ paymentItem, emailAddress, addRefundMethodFormGroup, isNewBankAccount }: AddRefundMethodFlow): void {
        this.selectedPaymentMethod = paymentItem;
        this.addRefundMethodFormGroup = addRefundMethodFormGroup;
        this.emailAddress = emailAddress;
        this.isNewBankAccount = isNewBankAccount;
    }

    fetchFlowData(): AddRefundMethodFlow {
        return {
            paymentItem: this.selectedPaymentMethod,
            emailAddress: this.emailAddress,
            isNewBankAccount: this.isNewBankAccount,
            apiCallIsSuccessful: this.apiCallSuccessful
        };
    }

    clear(): void {
        this.selectedPaymentMethod = undefined;
        this.addRefundMethodFormGroup = undefined;
        this.emailAddress = undefined;
        this.apiCallSuccessful = undefined;
        this.landingPageVisited = undefined;
        this.isNewBankAccount = undefined;
    }

    get flowInProgress(): boolean {
        return this.addRefundMethodFormGroup != null;
    }
}

export interface AddRefundMethodFlow {
    paymentItem: PaymentItem;
    emailAddress: string;
    isNewBankAccount: boolean;
    addRefundMethodFormGroup?: PaymentFormGroup;
    apiCallIsSuccessful?: boolean;
}
