import { SetupAutomaticPaymentsComponent } from './setup/setup-automatic-payments.component';
import { ManagePaymentPreferencesPageTitle } from '../shared/models/manage-payment-preferences-page-title.constant';
import { SetupAutomaticPaymentsLandingComponent } from './setup/landing/setup-automatic-payments-landing.component';
import { SetupAutomaticPaymentsReviewComponent } from './setup/review/setup-automatic-payments-review.component';
import { SetupAutomaticPaymentsConfirmationComponent } from './setup/confirmation/setup-automatic-payments-confirmation.component';
import { EditAutomaticPaymentsComponent } from './edit/edit-automatic-payments.component';
import { EditAutomaticPaymentsLandingComponent } from './edit/landing/edit-automatic-payments-landing.component';
import { EditAutomaticPaymentsReviewComponent } from './edit/review/edit-automatic-payments-review.component';
import { EditAutomaticPaymentsConfirmationComponent } from './edit/confirmation/edit-automatic-payments-confirmation.component';
import { CancelAutomaticPaymentsLandingComponent } from './cancel/landing/cancel-automatic-payments-landing.component';
import { CancelAutomaticPaymentsConfirmationComponent } from './cancel/confirmation/cancel-automatic-payments-confirmation.component';
import { ManagePaymentPreferencesGuard } from '../shared/guards/manage-payment-preferences.guard';
import { CancelAutomaticPaymentsBackFromConfirmationGuard } from './cancel/guards/cancel-automatic-payments-back-from-confirmation.guard';
import { CancelAutomaticPaymentsConfirmationGuard } from './cancel/guards/cancel-automatic-payments-confirmation.guard';
import { SetupAutomaticPaymentsConfirmationGuard } from '../automatic-payments/setup/guards/setup-automatic-payments-confirmation.guard';
import { SetupAutomaticPaymentsBackFromConfirmationGuard } from '../automatic-payments/setup/guards/setup-automatic-payments-back-from-confirmation.guard';
import { SuspendAutomaticPaymentsLandingComponent } from './suspend/landing/suspend-automatic-payments-landing.component';
import { SuspendAutomaticPaymentsConfirmationComponent } from './suspend/confirmation/suspend-automatic-payments-confirmation.component';
import { SuspendAutomaticPaymentsBackFromConfirmationGuard } from './suspend/guards/suspend-automatic-payments-back-from-confirmation.guard';
import { SuspendAutomaticPaymentsConfirmationGuard } from './suspend/guards/suspend-automatic-payments-confirmation.guard';
import { EditAutomaticPaymentsConfirmationGuard } from './edit/guards/edit-automatic-payments-confirmation-guard';
import { EditAutomaticPaymentsBackFromConfirmationGuard } from './edit/guards/edit-automatic-payments-back-from-confirmation-guard';
import { StateClearingGuard } from '../../../../shared/service/guards/state-clearing-guard/state-clearing.guard';

export const AutomaticPaymentsRoutes = [
    {
        path: 'automatic-payments/setup',
        component: SetupAutomaticPaymentsComponent,
        canActivate: [ManagePaymentPreferencesGuard],
        children: [
            {
                path: 'landing',
                component: SetupAutomaticPaymentsLandingComponent,
                data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.setup.landing }
            },
            {
                path: 'review',
                component: SetupAutomaticPaymentsReviewComponent,
                data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.setup.review },
                canActivate: [SetupAutomaticPaymentsBackFromConfirmationGuard]
            },
            {
                path: 'confirmation',
                component: SetupAutomaticPaymentsConfirmationComponent,
                data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.setup.confirmation },
                canActivate: [SetupAutomaticPaymentsConfirmationGuard],
                canDeactivate: [StateClearingGuard]
            }
        ]
    },
    {
        path: 'automatic-payments/edit',
        component: EditAutomaticPaymentsComponent,
        canActivate: [ManagePaymentPreferencesGuard],
        children: [
            {
                path: 'landing',
                component: EditAutomaticPaymentsLandingComponent,
                data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.edit.landing }

            },
            {
                path: 'review',
                component: EditAutomaticPaymentsReviewComponent,
                data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.edit.review },
                canActivate: [EditAutomaticPaymentsBackFromConfirmationGuard]
            },
            {
                path: 'confirmation',
                component: EditAutomaticPaymentsConfirmationComponent,
                data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.edit.confirmation },
                canActivate: [EditAutomaticPaymentsConfirmationGuard],
                canDeactivate: [StateClearingGuard]
            }
        ]
    },
    {
        path: 'automatic-payments/cancel/landing',
        component: CancelAutomaticPaymentsLandingComponent,
        canActivate: [ManagePaymentPreferencesGuard, CancelAutomaticPaymentsBackFromConfirmationGuard],
        data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.cancel.landing }
    },
    {
        path: 'automatic-payments/cancel/confirmation',
        component: CancelAutomaticPaymentsConfirmationComponent,
        canActivate: [ManagePaymentPreferencesGuard, CancelAutomaticPaymentsConfirmationGuard],
        data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.cancel.confirmation },
        canDeactivate: [StateClearingGuard]
    },
    {
        path: 'automatic-payments/suspend/landing',
        component: SuspendAutomaticPaymentsLandingComponent,
        canActivate: [ManagePaymentPreferencesGuard, SuspendAutomaticPaymentsBackFromConfirmationGuard],
        data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.suspend.landing }
    },
    {
        path: 'automatic-payments/suspend/confirmation',
        component: SuspendAutomaticPaymentsConfirmationComponent,
        canActivate: [ManagePaymentPreferencesGuard, SuspendAutomaticPaymentsConfirmationGuard],
        canDeactivate: [StateClearingGuard],
        data: { title: ManagePaymentPreferencesPageTitle.automaticPayments.suspend.confirmation }
    }
];
