import { Component } from '@angular/core';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';

@Component({
    selector: 'app-edit-refund-method-confirmation',
    templateUrl: './edit-refund-method-confirmation.component.html',
    styleUrls: ['./edit-refund-method-confirmation.component.scss']
})
export class EditRefundMethodConfirmationComponent {
    constructor(
        private paymentPreferencesState: PaymentPreferencesStateService
    ) { }

    onContinue(): void {
        this.paymentPreferencesState.resetState();
    }
}
