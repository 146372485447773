import { Injectable } from '@angular/core';
import {
    AgencyInformationResponse,
    AgentAddress,
    AgentName
} from '@nationwide/dgs-angular-billing-common';
import { Address } from '../../../../model/address.model';
import { AgencyInformationResponseModel } from '../model/agency-information-response.model';

@Injectable()
export class AgentInformationAdapter {
    mapAgentInformation(agencyInformationResponse: AgencyInformationResponse): AgencyInformationResponseModel {
        return {
            fullName: agencyInformationResponse.agencyInformation.agentName ?
                this.mapFullName(agencyInformationResponse.agencyInformation.agentName) : '',
            phoneNumber: agencyInformationResponse.agencyInformation.officePhoneNumber,
            emailAddress: agencyInformationResponse.agencyInformation.emailAddress,
            address: agencyInformationResponse.agencyInformation.agentAddress ?
                this.mapAddress(agencyInformationResponse.agencyInformation.agentAddress) : null
        };
    }

    mapAddress(agentAddress: AgentAddress): Address {
        return {
            addressLine1: agentAddress.addressLine1,
            addressLine2: agentAddress.addressLine2,
            addressLine3: agentAddress.addressLine3,
            city: agentAddress.city,
            state: agentAddress.state,
            zip: agentAddress.zip
        };
    }

    mapFullName(agentName: AgentName): string {
        const agentFullName = agentName.middleName ?
            `${agentName.firstName} ${agentName.middleName} ${agentName.lastName}` :
            `${agentName.firstName} ${agentName.lastName}`;
        return agentFullName || agentName.mailingName;
    }
}
