import { Component, Input, Inject, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SessionService } from '../../session/session.service';
import { documentOpenerConfigToken, DocumentOpenerConfig } from './doc-opener.config';
import { BillingEventService } from '../../service/ebi-service/billing-events.service';
import { EBIEventType } from '../../service/ebi-service/ebi-model';
import { BillingApiCommonService } from '../../service/billing-api-common.service';
import { EbiEventProperty, EBI_EVENT_PROPERTIES } from '../../service/ebi-service/event-properties';
import { BillingHistoryItem } from '../../../billing/bills-and-payments/billing-history/billing-history-item.model';

@Component({
    selector: 'app-doc-opener',
    templateUrl: './doc-opener.component.html',
    styleUrls: ['./doc-opener.component.scss']
})
export class DocOpenerComponent implements OnInit {
    @Input() document: BillingHistoryItem;
    viewPdfEBIPayload: { key: string; value: any }[];
    private _transactionId: string;

    // eslint-disable-next-line max-params
    constructor(
        private session: SessionService,
        @Inject(documentOpenerConfigToken) private documentOpenerConfig: DocumentOpenerConfig,
        private eventService: BillingEventService,
        private apiCommonService: BillingApiCommonService
    ) { }

    ngOnInit(): void {
        this.setEBIPayload();
    }

    onSubmit(): void {
        this.eventService.handleEvent(EBIEventType.LOAD_PDF_STATEMENT, {
            documentType: this.document.documentType
        });
    }

    private setEBIPayload(): void {
        const viewPdfEbiEvent: EbiEventProperty = EBI_EVENT_PROPERTIES.eventMap.get(EBIEventType.VIEW_PDF_STATEMENT);

        const viewPdfEbiPayload = this.apiCommonService.setBillingEbiData(
            {
                additionalFields: viewPdfEbiEvent.additionalEbiFields, eventDescription: viewPdfEbiEvent.ebiEventDescription, eventId: viewPdfEbiEvent.ebiEventId, options: {
                    documentType: this.document.documentType,
                    paymentMethod: []
                }
            });

        this.viewPdfEBIPayload = Object.keys(viewPdfEbiPayload).map((key) => (
            {
                key,
                value: viewPdfEbiPayload[key]
            })
        );
    }

    get endpoint(): string {
        return this.documentOpenerConfig.endpoint;
    }

    get accessToken(): string {
        return this.session.accessToken;
    }

    get clientId(): string {
        return environment.CLIENT_ID;
    }

    get urlifiedDocId(): string {
        let urlifiedDocId = decodeURIComponent(this.documentId);
        if (urlifiedDocId.endsWith(':')) {
            urlifiedDocId = urlifiedDocId.slice(0, Math.max(0, urlifiedDocId.length - 1));
        }
        urlifiedDocId = encodeURIComponent(urlifiedDocId);

        return urlifiedDocId;
    }

    get messageId(): string {
        return this.apiCommonService.generateTransactionId();
    }

    get documentId(): string {
        return this.document.statementId;
    }

    get origin(): string {
        return this.document.origin;
    }

    get sectionId(): string {
        return this.document.sectionId;
    }

    get reportId(): string {
        return this.document.reportId;
    }

    get statementId(): string {
        return this.document.statementId;
    }

    get version(): string {
        return this.document.version;
    }

    get sessionId(): string {
        return this.session.a2aSessionId;
    }
}
