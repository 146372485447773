<fieldset class="bolt-row name-fieldset" [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <div class="bolt-col-sm-12">
        <app-payment-sub-header>{{ header }}</app-payment-sub-header>
    </div>
    <div class="bolt-col-xl-4 bolt-col-lg-6 bolt-col-md-6 bolt-col-sm-12 bottom-space-sm">
        <bolt-textfield type="text" ngDefaultControl id="first-name" formControlName="firstName" label="First name" required="true" maxlength="64" (blur)="capitalizeFirstName()" ariaLabel="First name"
            [attr.invalid]="firstNameErrorsDisplayable ? true : null" ariadescribedby="first-name-errors">
        </bolt-textfield>
        <app-form-control-error id="first-name-errors" *ngIf="firstNameErrorsDisplayable">
            <ul class="error-list">
                <li *ngIf="firstName.errors['required']">First name is required.</li>
                <li *ngIf="(firstName.errors['validNameStarter'] || firstName.errors['containsNameCharacters']) && !firstName.errors['required']">
                    First name is invalid.
                </li>
            </ul>
        </app-form-control-error>
    </div>
    <div class="bolt-col-xl-3 bolt-col-lg-4 bolt-col-md-5 bolt-col-sm-12 bottom-space-sm">
        <bolt-textfield type="text" ngDefaultControl id="middle-initial" formControlName="middleInitial" label="Middle name" required="false" (blur)="capitalizeMiddleInitial()" maxlength="1"
            ariaLabel="Middle name" [attr.invalid]="middleInitialErrorsDisplayable ? true : null" ariadescribedby="middle-initial-errors">
        </bolt-textfield>
        <app-form-control-error id="middle-initial-errors" *ngIf="middleInitialErrorsDisplayable">
            <ul class="error-list">
                <li *ngIf="middleInitial.errors['startsWithAlphabetCharacter']">
                    MI is invalid.
                </li>
            </ul>
        </app-form-control-error>
    </div>
    <div class="bolt-col-xl-4 bolt-col-lg-6 bolt-col-md-6 bolt-col-sm-12" [ngClass]="{'bolt-space-bottom-sm' : nameAsOnCard}">
        <bolt-textfield type="text" ngDefaultControl id="last-name" formControlName="lastName" label="Last name" required="true" maxlength="64" (blur)="capitalizeLastName()" ariaLabel="Last name"
            [attr.invalid]="lastNameErrorsDisplayable ? true : null" ariadescribedby="last-name-errors">
        </bolt-textfield>
        <app-form-control-error id="last-name-errors" *ngIf="lastNameErrorsDisplayable">
            <ul class="error-list">
                <li *ngIf="lastName.errors['required']">Last name is required.</li>
                <li *ngIf="(lastName.errors['minlength'] || lastName.errors['validNameStarter'] || lastName.errors['containsNameCharacters']) && !lastName.errors['required']">
                    Last name is invalid.
                </li>
            </ul>
        </app-form-control-error>
    </div>
</fieldset>
<div *ngIf="!!nameAsOnCard">
    <p class="bolt-text-sm">{{nameAsOnCard}}</p>
</div>