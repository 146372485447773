import { BankCardPaymentItem } from './bankcard-item.model';
import { CardType } from '../../../../shared/payments/card-utils/card-type.enum';

export class NewDebitCardMethod extends BankCardPaymentItem {
    constructor() {
        super(
            '',
            '',
            '',
            '',
            '',
            CardType.INVALID,
            '',
            new Date(),
            '',
            'Debit'
        );
    }

    get expirationMonth(): string {
        return '';
    }

    get expirationYear(): string {
        return '';
    }
}
