import { Component, Inject, OnInit } from '@angular/core';
import { RemovePaymentMethodFlowService } from '../services/remove-payment-method-flow.service';
import { environment } from '../../../../../../../environments/environment';
import { Router } from '@angular/router';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SessionService } from '../../../../../../shared/session/session.service';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

@Component({
    selector: 'app-remove-payment-method-confirmation',
    templateUrl: './remove-payment-method-confirmation.component.html',
    styleUrls: [
        '../../../../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../../../../../../shared/payments/stylesheets/form-formatting.scss',
        '../../../shared/stylesheets/confirmation-page-base.scss',
        './remove-payment-method-confirmation.component.scss'
    ]
})
export class RemovePaymentMethodConfirmationComponent extends BasePageComponent implements OnInit {
    pageName = ManagePaymentPreferencesPageTitle.savedPaymentMethods.remove.confirmation;
    confirmationMessage: string;
    loadComplete = false;
    failureMessageHTML: string;

    // eslint-disable-next-line max-params
    constructor(
        private removePaymentMethodFlow: RemovePaymentMethodFlowService,
        private easternTimeDatePipe: EasternTimeDatePipe,
        @Inject('window') private window: Window,
        private eventService: BillingEventService,
        router: Router,
        session: SessionService,
        private readonly contentfulMapperservice: ContentfulMapperService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setConfirmationMessage();
        this.sendCtmAndEbiEvents();
        const contentResponse = this.contentfulMapperservice.getContentfulBillingContent();
        this.nextSub = contentResponse.subscribe((response) => {
            this.failureMessageHTML = response.apiFailure.content;
            this.loadComplete = true;
        });
    }

    sendCtmAndEbiEvents(): void {
        if (this.removePaymentMethodFlow.apiCallsSuccessful) {
            if (!this.removePaymentMethodFlow.paymentMethodToRemove.isDefault) {
                this.eventService.handleEvent(EBIEventType.PP_REMOVE_NON_DEFAULT_PAYMENT_METHOD_CONFIRMATION, {
                    paymentMethod: this.ebiPaymentMethodData
                });
            } else if (this.removePaymentMethodFlow.paymentMethodToRemove.isDefault) {
                this.eventService.handleEvent(EBIEventType.PP_REMOVE_DEFAULT_PAYMENT_METHOD_CONFIRMATION, {
                    paymentMethod: this.ebiPaymentMethodData
                });
            }

            this.eventService.handleCtmEvent(CtmEventType.PP_REMOVE_PAYMENT_METHOD, {
                productType: CtmProductType.BILLING
            });
        }

        this.eventService.handleEvent(EBIEventType.PP_REMOVE_PAYMENT_METHOD_CONFIRMATION, {
            responseCode: `${this.removePaymentMethodFlow.apiResponseCode}`,
            responseStatus: this.removePaymentMethodFlow.apiCallsSuccessful ? 'SUCCESS' : 'ERROR'
        });
    }

    get ebiPaymentMethodData(): PaymentMethodsData[] {
        const data = [];
        const payMethod = this.removePaymentMethodFlow.paymentMethodToRemove;

        const accountType = payMethod.isBankAccount ? payMethod.methodType : '';
        const bankName = payMethod.bankName;
        const creditCardVendor = payMethod.isBankCard ? payMethod.methodType : '';
        const paymentType = payMethod.isBankCard ? 'CC' : 'EFT';

        data.push({
            accountType,
            bankName,
            creditCardVendor,
            paymentType
        });

        return data;
    }

    get apiCallsSuccessful(): boolean {
        return this.removePaymentMethodFlow.apiCallsSuccessful;
    }

    setConfirmationMessage(): void {
        const paymentMethod = this.removePaymentMethodFlow.paymentMethodToRemove.managePaymentPrefsDisplayName;
        const effectiveTime = `${this.easternTimeDatePipe.transform(new Date(), 'MM/dd/yyyy, hh:mm aa')} ET`;
        this.confirmationMessage = ` ${paymentMethod}, Effective ${effectiveTime}.`;
    }

    get upNextText(): string {
        return this.apiCallsSuccessful ? 'Payment Preferences' : '';
    }

    get isDefaultPaymentMethod(): boolean {
        return this.removePaymentMethodFlow?.paymentMethodToRemove?.isDefault;
    }

    showPrintDialog(): void {
        this.window.print();
    }

    onContinue(): void {
        this.removePaymentMethodFlow.reset();
        this.navigateToPaymentPreferences();
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences({
                accountNumber: this.session.billingAccountNumber,
                sessionId: this.session.a2aSessionId
            })
        );
    }
}
