import { Component, Inject, OnInit } from '@angular/core';
import { SetAsDefaultFlow } from '../services/set-as-default-flow.service';
import { SessionService } from '../../../../../../shared/session/session.service';
import { environment } from '../../../../../../../environments/environment';
import { Router } from '@angular/router';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

@Component({
    selector: 'app-set-as-default-confirmation',
    templateUrl: './set-as-default-confirmation.component.html',
    styleUrls: [
        '../../../../../../shared/payments/stylesheets/form-formatting.scss',
        '../../../shared/stylesheets/confirmation-page-base.scss',
        './set-as-default-confirmation.component.scss'
    ]
})
export class SetAsDefaultConfirmationComponent extends BasePageComponent implements OnInit {
    pageName = ManagePaymentPreferencesPageTitle.savedPaymentMethods.setAsDefault.confirmation;
    formattedCurrentDate: string;
    failureMessageHTML: string;
    loadComplete = false;

    // eslint-disable-next-line max-params
    constructor(
        private setAsDefaultFlow: SetAsDefaultFlow,
        session: SessionService,
        router: Router,
        private easternTimeDatePipe: EasternTimeDatePipe,
        @Inject('window') private window: Window,
        private urlUtil: UrlUtil,
        readonly contentfulService: ContentfulMapperService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setFormattedCurrentDate();
        const contentfulResponse = this.contentfulService.getContentfulBillingContent();
        this.nextSub = contentfulResponse.subscribe((res) => {
            this.failureMessageHTML = res.apiFailure.content;
            this.loadComplete = true;
        });
    }

    onContinue(): void {
        this.setAsDefaultFlow.reset();
        this.goToPaymentPreferences();
    }

    showPrintDialog(): void {
        this.window.print();
    }

    goToAccountSummary(): void {
        this.session.clear();
        this.window.location.href = environment.CAM_HOME;
    }

    goToPaymentPreferences(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences({
                accountNumber: this.session.billingAccountNumber,
                sessionId: this.session.a2aSessionId
            })
        );
    }

    get successfullyUpdated(): boolean {
        return this.setAsDefaultFlow.apiCallSuccessful;
    }

    get displayName(): string {
        return this.setAsDefaultFlow.mappedPaymentMethod &&
            this.setAsDefaultFlow.mappedPaymentMethod.managePaymentPrefsDisplayName || '';
    }

    setFormattedCurrentDate(): void {
        const dateFormat = 'MM/dd/yyyy, hh:mm aa ';
        this.formattedCurrentDate = `${this.easternTimeDatePipe.transform(new Date(), dateFormat)}ET`;
    }

    get upNextText(): string {
        return this.successfullyUpdated ? 'Payment Preferences' : '';
    }
}
