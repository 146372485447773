import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { PaymentItem } from '../../../billing/payment-preferences/shared/models/payment-item.model';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { SessionService } from '../../session/session.service';

@Component({
    selector: 'app-payment-method-select',
    templateUrl: './payment-method-select.component.html',
    styleUrls: [
        './payment-method-select.component.scss',
        '../../../shared/payments/stylesheets/form-formatting.scss'
    ]
})
export class PaymentMethodSelectComponent implements OnInit {
    @Input() paymentMethods: PaymentItem[];
    @Input() selectedPaymentMethod: PaymentItem;
    @Input() isManagePaymentPreferencesFlow: boolean;
    @Input() labelText = 'Select a payment method';
    @Input() optionalText = 'hide';
    @Input() placeholderText = 'Select';
    @Output() selectedPaymentMethodChange: EventEmitter<PaymentItem>;
    @Input() updatePaymentMethodOnFile: boolean;
    @Output() updatePaymentMethodOnFileChange: EventEmitter<boolean>;
    @Input() reducedFees: boolean;
    @Input() displayErrors: boolean;
    @Input() isFullPay: boolean;
    @Input() isQuickPay: boolean;
    @Input() increaseReftAutoPayFlag: boolean;
    @Input() isLifeOnlyAccount: boolean;

    waysToSaveMessage: string;
    DEFAULT_STRING = '(default)';
    increaseReftAutoSavingsContent: string;
    increaseReftWarningIcon: string;

    imagesToPreload = [
        '/assets/images/ReftAutoPayTestWarningIcon.svg'
    ];

    private alreadyPlaceholder = false;

    constructor(
        readonly contentfulMapperService: ContentfulMapperService,
        private session: SessionService
    ) {
        this.selectedPaymentMethodChange = new EventEmitter<PaymentItem>();
        this.updatePaymentMethodOnFileChange = new EventEmitter<boolean>();
    }

    ngOnInit(): void {
        const contentfulResponse = this.contentfulMapperService.getContentfulBillingContent();
        contentfulResponse.subscribe((res) => {
            if (this.increaseReftAutoPayFlag) {
                if (!this.session.referringPageUrl.includes('review')) {
                    this.selectedPaymentMethodChange.emit(this.selectedPaymentMethod);
                }
                this.increaseReftAutoSavingsContent = res.ReftAutoPayTestPhase3.content;
                this.increaseReftWarningIcon = this.imagesToPreload[0];
            }
            this.waysToSaveMessage = res.waysToSave.content;
        });
    }
    get updateMethodOnFileButtonText(): string {
        return this.updatePaymentMethodOnFile ?
            'Cancel payment details update' :
            'Update payment details';
    }

    get selectedValue(): string {
        let value = '';
        if (this.selectedPaymentMethod) {
            if (!this.selectedPaymentMethod.isPlaceholder) {
                value = this.selectedPaymentMethod.displayName;
                if (this.selectedPaymentMethod.isDefault) {
                    value = `${value} ${this.DEFAULT_STRING}`;
                }
            }
        }

        return value;
    }

    updateSelectedPaymentMethod($evt: any): void {
        let newVal = <string>$evt?.target?.value;
        if (typeof newVal === 'string' && newVal.length > 0) {
            // remove ' (default)' if it has.
            if (newVal.endsWith(this.DEFAULT_STRING)) {
                newVal = newVal.slice(0, -this.DEFAULT_STRING.length).trim();
            }
            // change event of bolt-select fires select string value only not selected object value.
            if (this.selectedPaymentMethod?.displayName === newVal && !this.alreadyPlaceholder) {
                // which means the placeholder selected
                this.alreadyPlaceholder = true;
                this.selectedPaymentMethod = this.paymentMethods.find((method) => method.isPlaceholder);
                this.selectedPaymentMethodChange.emit(this.selectedPaymentMethod);
            } else {
                this.alreadyPlaceholder = false;
                const outMethod = this.paymentMethods.find((method) => method.displayName === newVal);
                if (outMethod) {
                    this.selectedPaymentMethod = outMethod;
                    this.selectedPaymentMethodChange.emit(this.selectedPaymentMethod);
                }
            }
        } else {
            this.alreadyPlaceholder = true;
            this.selectedPaymentMethod = this.paymentMethods.find((method) => method.isPlaceholder);
            this.selectedPaymentMethodChange.emit(this.selectedPaymentMethod);
        }

        this.updateDisplayErrors();
    }

    updateDisplayErrors(): void {
        this.displayErrors = this.selectedPaymentMethod ? this.selectedPaymentMethod?.isPlaceholder : true;
    }
}
