import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { DistributionPartnerRoleInfoAdapter } from '../../../../../../billing/shared/distribution-partner-role-info/distribution-partner-role-info.adapter';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { EditPaymentMethodFlowService } from '../edit-payment-method-flow.service';
import { EditPaymentMethodReview } from './edit-payment-review.model';
import { EditPaymentMethodReviewService } from './edit-payment-review.service';
@Component({
    selector: 'app-edit-payment-method-review',
    templateUrl: './edit-payment-method-review.component.html',
    styleUrls: ['./edit-payment-method-review.component.scss']
})
export class EditPaymentMethodReviewComponent extends BasePageComponent implements OnInit {
    @ViewChild(FormGroupDirective) editPaymentFormDirective: FormGroupDirective;

    pageName = ManagePaymentPreferencesPageTitle.savedPaymentMethods.edit.review;
    loadComplete = false;
    editPaymentDetailsReviewFields: RecapFormField[] | BankcardRecapFormField[] = [];
    considerThisReviewFields: RecapFormField[] = [];

    // eslint-disable-next-line max-params
    constructor(
        private editPaymentMethodFlowService: EditPaymentMethodFlowService,
        private editPaymentMethodReview: EditPaymentMethodReview,
        @Inject('window') private window: Window,
        protected router: Router,
        private urlUtil: UrlUtil,
        private formUtils: FormUtil,
        @Inject('logger') private logger: LoggerService,
        session: SessionService,
        private eventService: BillingEventService,
        private editPaymentMethodReviewService: EditPaymentMethodReviewService,
        private distributionPartnerRoleInfoAdapter: DistributionPartnerRoleInfoAdapter
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        this.window.scroll(0, 0);
        this.editPaymentMethodReview.initialize();
        this.editPaymentDetailsReviewFields = this.editPaymentMethodReview.editPaymentDetailsReviewFields;
        this.considerThisReviewFields = this.editPaymentMethodReview.considerThisReviewFields;
        this.session.currentPage = this.pageName;
        this.loadComplete = true;
        this.sendOnPageLoadEbiEvent();
        if (this.editPaymentMethodFlowService.isPaperlessEligible) {
            this.logger.info('Edit Payment Method Review - Page load',
                this.editPaymentMethodReviewService.logPageLoadForPaperless(this.editPaymentMethodForm.enrollInPaperless)
            );
        }
    }

    onSubmit(): void {
        this.formUtils.touchAllFormControls(this.editPaymentMethodForm.group);
        if (this.editPaymentMethodForm.group.valid) {
            this.sendOnSubmitEbiEvent();
            this.loadComplete = false;
            this.editPaymentMethodReviewService.updatePaymentMethod().subscribe((responses) => {
                this.navigateToConfirmation();
            },
                (error) => {
                    this.logger.error('API ERROR: EDIT PAYMENT METHOD SUBMISSION', error);
                    this.navigateToConfirmation();
                });
        } else {
            this.window.scroll(0, 0);
        }
    }

    cancelEditPayment(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString));
    }

    navigateToEditPaymentMethodLanding(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.EDIT.LANDING(this.urlUtil.hashParamsString));
    }

    navigateToConfirmation(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.EDIT.CONFIRMATION(this.urlUtil.hashParamsString));
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_LOAD_OF_EDIT_PAYMENT_METHOD_REVIEW_PAGE);
    }

    sendOnSubmitEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_SUBMIT_EDIT_PAYMENT_METHOD_REVIEW_PAGE, {
            paymentMethod: this.ebiPaymentMethodData
        });
    }

    get paperlessSelection(): boolean {
        return this.editPaymentMethodReview?.enrollingInPaperless !== 'Yes';
    }

    get isReviewPageReloaded(): boolean {
        return !this.editPaymentMethodFlowService.flowInPlay;
    }

    get editPaymentMethodForm(): PaymentFormGroup {
        return this.editPaymentMethodFlowService.editPaymentMethodForm;
    }

    get considerThisSectionDisplayable(): boolean {
        return this.editPaymentMethodFlowService.considerThisDisplayable;
    }

    get submitted(): boolean {
        return !!this.editPaymentFormDirective && this.editPaymentFormDirective.submitted;
    }

    get globalErrorDisplayable(): boolean {
        return !this.editPaymentMethodForm.group.valid && this.submitted;
    }

    get ebiPaymentMethodData(): PaymentMethodsData[] {
        const data = [];
        const payMethod = this.editPaymentMethodReview;
        const accountType = payMethod.isBankAccount ? this.editPaymentMethodForm.bankAccountType : 'NA';
        const bankName = payMethod.isBankAccount ? this.editPaymentMethodForm.bankName : 'NA';
        const creditCardVendor = payMethod.isBankCard ? this.editPaymentMethodForm.cardType : 'NA';
        const paymentType = payMethod.isBankCard ? 'CC' : 'EFT';
        const defaultMethod = this.editPaymentMethodForm.makeDefaultPaymentMethod ? 'Yes' : 'No';
        const firstName = this.editPaymentMethodForm.firstName;
        const lastName = this.editPaymentMethodForm.lastName;
        const paperless = this.editPaymentMethodForm.enrollInPaperless === 'yes';

        data.push({
            accountType,
            bankName,
            creditCardVendor,
            paymentType,
            firstName,
            lastName,
            paperless,
            defaultMethod
        });
        return data;
    }
}
