<div id="global-container" class="global-container">
    <authenticated-header *ngIf="!this.isQuickPay" [headerData]="header" (linkClick)="onLinkClick($event)">
    </authenticated-header>

    <unauthenticated-header *ngIf="this.isQuickPay" [headerData]="unauthenticatedHeader" (linkClick)="onLinkClick($event)">
    </unauthenticated-header>

    <app-ways-to-pay (selectedPaymentMethod)="handleWaysToPayEmitter($event)" (iframeHeight)="iframeHeight = $event" class="ways-to-pay-widget">
    </app-ways-to-pay>

    <ng-container *ngIf="canShowNavigationBar">
        <bolt-button-bar>
            <bolt-button slot="forward" type="primary" slot="forward" id="continue-button" class="jm-visible" [class]="'jm-visible'" (click)="closeModalWithPaymentMethod()"
                [disabled]="shouldDisableContinueButton">
                {{getWaysToPayButtonLabel()}}
            </bolt-button>
            <bolt-button slot="back" aria-label="back" id="back-button" class="jm-visible" [class]="'jm-visible'" (click)="closeModal()">
                Back
            </bolt-button>
        </bolt-button-bar>
    </ng-container>
    <is-footer [footerData]="footer"></is-footer>
</div>