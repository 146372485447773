/* eslint-disable no-negated-condition */
/* eslint-disable no-unneeded-ternary */
import { Injectable } from '@angular/core';
import { EftAuthorizationFormElements } from './eft-authorization-form/form-elements/eft-authorization-form-elements';
import { AuthConfirmationDetailsElements } from './eft-authorization-form/form-elements/auth-confirmation-details.elements';
import { ReceiptDetailsElements } from './eft-authorization-form/form-elements/receipt-details-elements';
import { AccountPolicyElements } from './eft-authorization-form/form-elements/account-policy-elements';
import { AcctountPolicyHolderNameElements } from './eft-authorization-form/form-elements/acctount-policy-holder-name-elements';
import { AgencyInformationResponseModel } from './eft-authorization-form/model/agency-information-response.model';
import { DistributedDocumentResponse } from '@nationwide/dgs-angular-billing-common';
import { AccountPolicyHolderAddressElements } from './eft-authorization-form/form-elements/account-policy-holder-address-elements';
import { EftAuthorizationFlowService } from './eft-authorization-flow.service';
import { EFT_AUTH_FORM_CONSTANT } from '../../../../app/shared/constants/common.constants';
import { EftAuthorizationFormPolicyEbiData } from '../../service/billing-api-common.service';
import { EftAuthorizationFormHelperService } from './eft-authorization-form-helper.service';

@Injectable()
export class EftAuthorizationFormValidationService {
    constructor(
        private eftAuthorizationFlowService: EftAuthorizationFlowService,
        private eftAuthorizationFormHelperService: EftAuthorizationFormHelperService) {
    }

    validateEftAuthorizationFormElementsNewlyEnrolled(eftAuthorizationFormElements: EftAuthorizationFormElements): boolean {
        return this.validateEftAuthorizationFormElements(eftAuthorizationFormElements);
    }

    validateEftAuthorizationFormElementsAlreadyEnrolled(eftAuthorizationFormElements: EftAuthorizationFormElements,
        agencyInformationResponseModel: AgencyInformationResponseModel): boolean {
        return this.validateAgentDetails(agencyInformationResponseModel) &&
            this.validateEftAuthorizationFormElements(eftAuthorizationFormElements);
    }
    validateEftAuthorizationFormElements(eftAuthorizationFormElements: EftAuthorizationFormElements): boolean {
        let eftAuthFormElementsObject: object = {};
        let accountPolHolderObject: object = {};
        if (!eftAuthorizationFormElements.AccountPolHolder) {
            accountPolHolderObject = this.eftAuthorizationFormHelperService.getAllAccountPolHolderElementsMissing();
        } else {
            accountPolHolderObject = {
                ...this.validateAccountPolicyHolderNameElements(eftAuthorizationFormElements.AccountPolHolder.AcctPolHolderName),
                ...this.validateAccountPolicyHolderAddressElements(eftAuthorizationFormElements.AccountPolHolder.AcctPolHolderAddress)
            };
        }
        eftAuthFormElementsObject = {
            ...this.validateBillingAccount(eftAuthorizationFormElements.AccountPol),
            ...accountPolHolderObject,
            ...this.validateBankDetails(eftAuthorizationFormElements.AuthConfirmationDetails),
            ...this.validateReceiptDetails(eftAuthorizationFormElements.ReceiptDetails)
        };
        const eftAuthorizationFormPolicyEbiDataArray: EftAuthorizationFormPolicyEbiData[] = this.setPolicyDetails(eftAuthorizationFormElements.AuthConfirmationDetails);

        if (this.eftAuthorizationFlowService.eftAuthorizationFormEbiData.eftAuthorizationFormElementsObject) {
            const eftAuthFormElementsObjectMergeWithAgentInfo = {
                ...eftAuthFormElementsObject,
                ...this.eftAuthorizationFlowService.eftAuthorizationFormEbiData.eftAuthorizationFormElementsObject
            };
            this.eftAuthorizationFlowService.eftAuthorizationFormEbiData = {
                eftAuthorizationFormPolicyEbiData: eftAuthorizationFormPolicyEbiDataArray,
                eftAuthorizationFormElementsObject: eftAuthFormElementsObjectMergeWithAgentInfo
            };
        }
        return this.arePolicyDetailElementsValid(eftAuthorizationFormPolicyEbiDataArray) &&
            this.areEftAuthorizationFormElementsValid(eftAuthFormElementsObject);
    }

    validateBillingAccount(accountPolicyElements: AccountPolicyElements): object {
        return accountPolicyElements?.AccountPolNumber ?
            { FULLPOLICYNB: accountPolicyElements.AccountPolNumber } :
            { FULLPOLICYNB: EFT_AUTH_FORM_CONSTANT.MISSING };
    }

    validateReceiptDetails(receiptDetailsElements: ReceiptDetailsElements): object {
        let receiptDetailsElementsObject: object = {};
        let receiptDetailsChildElementsObject: object = {};
        let finalReceiptDetailsElementsObject: object = {};
        const checkMinimumAmount = 0;
        if (receiptDetailsElements.ReceiptDetail && receiptDetailsElements.ReceiptDetail.length > 0) {
            receiptDetailsElementsObject = this.eftAuthorizationFormHelperService.getReceiptDetailsObject(
                receiptDetailsElements.PaidDateTime || EFT_AUTH_FORM_CONSTANT.MISSING,
                receiptDetailsElements.TotalAmtPaidOrVoided >= checkMinimumAmount &&
                receiptDetailsElements.TotalAmtPaidOrVoided.toString() || EFT_AUTH_FORM_CONSTANT.MISSING);
            const receiptDetail = receiptDetailsElements.ReceiptDetail[0];
            if (receiptDetail) {
                receiptDetailsChildElementsObject = this.eftAuthorizationFormHelperService.getReceiptDetailsChildObject(
                    receiptDetail.BankName || EFT_AUTH_FORM_CONSTANT.MISSING,
                    receiptDetail.BankAccountType || EFT_AUTH_FORM_CONSTANT.MISSING,
                    receiptDetail.PaymentMethod || EFT_AUTH_FORM_CONSTANT.MISSING);
            } else {
                receiptDetailsChildElementsObject = this.eftAuthorizationFormHelperService.getAllReceiptDetailsChildElementsMissing();
            }
            finalReceiptDetailsElementsObject = {
                ...receiptDetailsElementsObject,
                ...receiptDetailsChildElementsObject
            };
        } else {
            finalReceiptDetailsElementsObject = this.eftAuthorizationFormHelperService.getAllReceiptDetailElementsMissing();
        }
        return finalReceiptDetailsElementsObject;
    }

    validateBankDetails(authConfirmationDetails: AuthConfirmationDetailsElements): object {
        let eftAuthFormElementsObject: object = {};
        if (!authConfirmationDetails) {
            eftAuthFormElementsObject = this.eftAuthorizationFormHelperService.getAllBankDetailElementsMissing();
        } else {
            const neededNumberOfChar = 3;
            eftAuthFormElementsObject = this.eftAuthorizationFormHelperService.getBankDetailObject(
                authConfirmationDetails.FinInstAccountNumber &&
                this.getLastGivenNumberOfCharacters(authConfirmationDetails.FinInstAccountNumber, neededNumberOfChar) ||
                EFT_AUTH_FORM_CONSTANT.MISSING,
                authConfirmationDetails.FinInstName || EFT_AUTH_FORM_CONSTANT.MISSING,
                authConfirmationDetails.FinInstRoutingNumber || EFT_AUTH_FORM_CONSTANT.MISSING);
        }
        return eftAuthFormElementsObject;
    }

    getLastGivenNumberOfCharacters(value: string, numberofCharacter: number): string {
        return value.slice(value.length - numberofCharacter, value.length);
    }

    validateAccountPolicyHolderNameElements(acctountPolicyHolderNameElements: AcctountPolicyHolderNameElements): object {
        let eftAuthFormElementsObject: object = {};
        if (!acctountPolicyHolderNameElements) {
            eftAuthFormElementsObject = this.eftAuthorizationFormHelperService.getAllNameElementsMissing();
        } else {
            eftAuthFormElementsObject = this.eftAuthorizationFormHelperService.getNameElementsObject(
                acctountPolicyHolderNameElements.AcctPolHolderFirstName || EFT_AUTH_FORM_CONSTANT.MISSING,
                acctountPolicyHolderNameElements.AcctPolHolderLastName || EFT_AUTH_FORM_CONSTANT.MISSING);
        }
        return eftAuthFormElementsObject;
    }

    validateAccountPolicyHolderAddressElements(acctPolHolderAddress: AccountPolicyHolderAddressElements): object {
        let eftAuthFormElementsObject: object = {};
        if (!acctPolHolderAddress) {
            eftAuthFormElementsObject = this.eftAuthorizationFormHelperService.getAllAddressElementsMissing();
        } else {
            eftAuthFormElementsObject = this.getAccountPolicyHolderAddressElements(acctPolHolderAddress);
        }
        return eftAuthFormElementsObject;
    }

    validateAgentDetails(agencyInformationResponseModel: AgencyInformationResponseModel): boolean {
        const eftAuthorizationFormElementsObject = this.eftAuthorizationFormHelperService.getAgentDetailObject(
            agencyInformationResponseModel.fullName || EFT_AUTH_FORM_CONSTANT.MISSING,
            this.validateAgentPhoneNumber(agencyInformationResponseModel.phoneNumber) ?
                agencyInformationResponseModel.phoneNumber :
                EFT_AUTH_FORM_CONSTANT.MISSING);
        this.eftAuthorizationFlowService.eftAuthorizationFormEbiData = {
            eftAuthorizationFormElementsObject
        };
        return this.areEftAuthorizationFormElementsValid(eftAuthorizationFormElementsObject);
    }

    validateAgentPhoneNumber(phoneNumber: string): boolean {
        return phoneNumber?.trim().split('').some((c) => isNaN(<any>c) === false);
    }

    validateDistributedDocumentResponse(distributedDocumentResponse: DistributedDocumentResponse): boolean {
        return distributedDocumentResponse.returnCode &&
            distributedDocumentResponse.returnCode === EFT_AUTH_FORM_CONSTANT.HP_EXTREME_SERVICE_SUCCEESS_RESPONSE_CODE;
    }

    private areEftAuthorizationFormElementsValid(eftAuthorizationFormElementsObject: object): boolean {
        let areAllEftAuthorizationFormElementsValidFlag = true;
        if (eftAuthorizationFormElementsObject) {
            const mapKeys = Object.keys(eftAuthorizationFormElementsObject);
            for (const mapKey of mapKeys) {
                if (eftAuthorizationFormElementsObject[mapKey] === EFT_AUTH_FORM_CONSTANT.MISSING) {
                    areAllEftAuthorizationFormElementsValidFlag = false;
                    break;
                }
            }
        }
        return areAllEftAuthorizationFormElementsValidFlag;
    }

    private arePolicyDetailElementsValid(eftAuthorizationFormPolicyEbiDataArray: EftAuthorizationFormPolicyEbiData[]): boolean {
        const validPolicyFlag = eftAuthorizationFormPolicyEbiDataArray.some((eftAuthorizationFormPolicyEbiData) => eftAuthorizationFormPolicyEbiData &&
            (eftAuthorizationFormPolicyEbiData.companyName === EFT_AUTH_FORM_CONSTANT.MISSING ||
                eftAuthorizationFormPolicyEbiData.policyNumber === EFT_AUTH_FORM_CONSTANT.MISSING ||
                eftAuthorizationFormPolicyEbiData.policyType === EFT_AUTH_FORM_CONSTANT.MISSING));

        return validPolicyFlag ? false : true;
    }

    private setPolicyDetails(authConfirmationDetailsElements: AuthConfirmationDetailsElements): EftAuthorizationFormPolicyEbiData[] {
        const eftAuthorizationFormPolicyEbiDataArray: EftAuthorizationFormPolicyEbiData[] = [];
        if (authConfirmationDetailsElements.AuthConfirmationDetail) {
            authConfirmationDetailsElements.AuthConfirmationDetail.forEach((authConfirmationDetail) => {
                const eftAuthorizationFormPolicyEbiData: EftAuthorizationFormPolicyEbiData = {
                    policyNumber: authConfirmationDetail.PolicyNumber || EFT_AUTH_FORM_CONSTANT.MISSING,
                    companyName: authConfirmationDetail.CompanyName || EFT_AUTH_FORM_CONSTANT.MISSING,
                    policyType: authConfirmationDetail.PolicyType || EFT_AUTH_FORM_CONSTANT.MISSING
                };
                eftAuthorizationFormPolicyEbiDataArray.push(eftAuthorizationFormPolicyEbiData);
            });
        }
        return eftAuthorizationFormPolicyEbiDataArray;
    }

    private getAccountPolicyHolderAddressElements(acctPolHolderAddress: AccountPolicyHolderAddressElements): object {
        return this.eftAuthorizationFormHelperService.getAddressElementsObject(
            { streetAddress: acctPolHolderAddress.AcctPolHolderAddr1 || EFT_AUTH_FORM_CONSTANT.MISSING, city: acctPolHolderAddress.AcctPolHolderCity || EFT_AUTH_FORM_CONSTANT.MISSING, state: acctPolHolderAddress.AcctPolHolderState || EFT_AUTH_FORM_CONSTANT.MISSING, zip: acctPolHolderAddress.AcctPolHolderZip || EFT_AUTH_FORM_CONSTANT.MISSING });
    }
}
