import { PaymentPrefsSectionPlaceholder } from '../../shared/models/placeholder.model';
import { PaymentItem } from '../../shared/models/payment-item.model';
import { PaymentPreferencesAccordion } from '../../shared/models/acordion.model';

export class SavedPaymentMethods {
    // eslint-disable-next-line max-params
    constructor(
        readonly placeholder: PaymentPrefsSectionPlaceholder,
        readonly canAddPayMethod: boolean,
        readonly warnings: string[],
        readonly payMethodAccordions: PaymentMethodAccordion[]
    ) { }

    static create(params: CreateSavedPaymentMethodsParams): SavedPaymentMethods {
        return new SavedPaymentMethods(
            params.placeholder || null,
            params.canAddPayMethod || false,
            params.warnings || [],
            params.payMethodAccordions || []
        );
    }
}

export interface CreateSavedPaymentMethodsParams {
    placeholder?: PaymentPrefsSectionPlaceholder;
    canAddPayMethod?: boolean;
    warnings?: string[];
    payMethodAccordions?: PaymentMethodAccordion[];
}

export class PaymentMethodAccordion extends PaymentPreferencesAccordion {
    constructor(
        header: string,
        readonly payMethod: PaymentItem,
        readonly infoMessage: string
    ) {
        super(header, payMethod);
    }

    static create(params: CreatePayMethodAccordionParams): PaymentMethodAccordion {
        return new PaymentMethodAccordion(
            params.header || '',
            params.payMethod || null,
            params.infoMessage || ''
        );
    }

    get containsBankAccount(): boolean {
        return this.isBankAccount;
    }

    get containsBankCard(): boolean {
        return this.isBankCard;
    }
}

export interface CreatePayMethodAccordionParams {
    header?: string;
    payMethod?: PaymentItem;
    infoMessage?: string;
}
