import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentFlowType } from '../../../pay-bill/shared/payment-flow-type.service';

@Component({
    selector: 'app-personal-policy-timeout-modal',
    styleUrls: [],
    templateUrl: './time-out.component.html'
})
export class TimeoutModalComponent {
    tridionHelpText;
    secondaryButtonLabel: string;
    timeoutModalComponent;

    constructor(
        public activeModal: NgbActiveModal,
        private paymentFlowType: PaymentFlowType
    ) {
        this.timeoutModalComponent = this;
        if (this.paymentFlowType.isQuickpay) {
            this.secondaryButtonLabel = 'End Session';
        } else {
            this.secondaryButtonLabel = 'Log out';
        }
    }
}
