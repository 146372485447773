import { AddRefundMethodComponent } from './add/add-refund-method.component';
import { ManagePaymentPreferencesPageTitle } from '../shared/models/manage-payment-preferences-page-title.constant';
import { AddRefundMethodLandingComponent } from './add/landing/add-refund-method-landing.component';
import { AddRefundMethodReviewComponent } from './add/review/add-refund-method-review.component';
import { AddRefundMethodConfirmationComponent } from './add/confirmation/add-refund-method-confirmation.component';
import { EditRefundMethodComponent } from './edit/edit-refund-method.component';
import { ManagePaymentPreferencesGuard } from '../shared/guards/manage-payment-preferences.guard';
import { EditRefundMethodLandingComponent } from './edit/landing/edit-refund-method-landing.component';
import { EditRefundMethodReviewComponent } from './edit/review/edit-refund-method-review.component';
import { EditRefundMethodConfirmationComponent } from './edit/confirmation/edit-refund-method-confirmation.component';
import { RemoveRefundMethodComponent } from './remove/remove-refund-method.component';
import { RemoveRefundMethodLandingComponent } from './remove/landing/remove-refund-method-landing.component';
import { RemoveRefundMethodConfirmationComponent } from './remove/confirmation/remove-refund-method-confirmation.component';
import { RemoveRefundMethodBackFromConfirmationGuard } from './remove/guards/remove-refund-method-back-from-confirmation.guard';
import { RemoveRefundMethodConfirmationGuard } from './remove/guards/remove-refund-method-confirmation.guard';
import { StateClearingGuard } from '../../../../shared/service/guards/state-clearing-guard/state-clearing.guard';

export const RefundMethodRoutes = [
    {
        path: 'refund-method/add',
        component: AddRefundMethodComponent,
        canActivate: [ManagePaymentPreferencesGuard],
        data: { title: ManagePaymentPreferencesPageTitle.refundMethod.add },
        children: [
            {
                path: 'landing',
                component: AddRefundMethodLandingComponent
            },
            {
                path: 'review',
                component: AddRefundMethodReviewComponent
            },
            {
                path: 'confirmation',
                component: AddRefundMethodConfirmationComponent,
                canDeactivate: [StateClearingGuard]
            }
        ]
    },
    {
        path: 'refund-method/edit',
        component: EditRefundMethodComponent,
        canActivate: [ManagePaymentPreferencesGuard],
        data: { title: ManagePaymentPreferencesPageTitle.refundMethod.edit },
        children: [
            {
                path: 'landing',
                component: EditRefundMethodLandingComponent
            },
            {
                path: 'review',
                component: EditRefundMethodReviewComponent
            },
            {
                path: 'confirmation',
                component: EditRefundMethodConfirmationComponent,
                canDeactivate: [StateClearingGuard]
            }
        ]
    },
    {
        path: 'refund-method/remove',
        component: RemoveRefundMethodComponent,
        children: [
            {
                path: 'landing',
                component: RemoveRefundMethodLandingComponent,
                data: { title: ManagePaymentPreferencesPageTitle.refundMethod.remove.landing },
                canActivate: [ManagePaymentPreferencesGuard, RemoveRefundMethodBackFromConfirmationGuard]

            },
            {
                path: 'confirmation',
                component: RemoveRefundMethodConfirmationComponent,
                data: { title: ManagePaymentPreferencesPageTitle.refundMethod.remove.confirmation },
                canActivate: [ManagePaymentPreferencesGuard, RemoveRefundMethodConfirmationGuard],
                canDeactivate: [StateClearingGuard]
            }
        ]
    }
];
