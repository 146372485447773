export const MasterpassConstants = {
    CHECKOUT_ID: '22041324aae244f48d32aa4350c7182c', // Merchant checkout identifier received when merchant onboarded for masterpass
    ALLOWED_TYPES: 'master,amex,discover,visa', // Card types accepted by merchant
    CURRENCY: 'USD', // Currency code for cart
    SHIPPING_LOCATION_PROFILE: '', // Shipping locations supported by merchant - configured in merchant portal
    SUPPRESS_3DS: false, // Set true when 3DS not mandatory for the spcecific country
    SUPPRESS_SHIPPING_ADDRESS: true, // Set true when cart items has digital goods only
    cryptoOptions: [{ cardType: 'master', format: ['UCAF'] }, { cardType: 'visa', format: ['TAVV'] }] // defines crypto options used by DSRP
};

export const CART_ID_LENGTH = 14;
export const CART_ID_INDEX_START = 2;
export const CART_ID_INDEX_END = 9;
