<body>
    <div class="global-container" autoscroll="true">
        <header>
            <h1>
                <a id="nationwide-link" href="https://nationwide.com" class="logo-container">
                    <img src="/tridion/static/nw-logo.png" alt="Nationwide" />
                </a>
            </h1>
        </header>
        <main>
            <div *ngIf="showEFT">
                <bw-html-interpolate content="{{autoPayBankAuthAgreement}}">
                </bw-html-interpolate>
            </div>
            <div *ngIf="showCC">
                <bw-html-interpolate content="{{autoPayCreditCardAgreement}}">
                </bw-html-interpolate>
            </div>
            <section class="details">
                <h4 id="billing-account-info">Billing Account Information</h4>
                <p class="no-margin">
                    Billing account holder's name:
                    {{this.authValue.billingAccountHolderName}}
                </p>
                <p>Billing account number: {{this.authValue.billingAccountNumber}}</p>
                <h3>Payment details:</h3>
                <p *ngIf="showEFT">Payments will be deducted from {{this.authValue.billingAccountHolderName}},
                    {{this.authValue.bankName}}, {{this.authValue.methodType}},
                    {{this.authValue.routingNumber}}, *********{{this.authValue.maskedAccountNum}}
                </p>
                <p *ngIf="showCC">Payments will be deducted from {{this.authValue.payorName}},
                    {{this.authValue.cardType}}, {{this.authValue.cardZip}},
                    ************{{this.authValue.maskedCardNum}}, Exp. {{this.authValue.expirationDate}}
                </p>
                <h3>Transaction details:</h3>
                <p>Transaction authorized by {{this.authValue.billingAccountHolderName}} on
                    {{this.authValue.enrolledDate}}</p>
            </section>
            <footer>
                <button id="print-agreement-button" class="btn btn-default" onclick="window.print()">
                    Print Agreement
                </button>

                <p>G 1000 06 18</p>
            </footer>
        </main>
    </div>
</body>