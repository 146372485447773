import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';
import { CardType } from '../card-utils/card-type.enum';

export type ControlNames = 'nameGroup' | 'bankCardFormGroup' | 'cardExpirationGroup' | 'zipGroup' | 'makeDefaultPaymentMethod'
    | 'savePaymentMethod' | 'cvvGroup';

@Component({
    selector: 'app-bank-card-form-controls',
    templateUrl: './bank-card-form-controls.component.html',
    styleUrls: [
        './bank-card-form-controls.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class BankCardFormControlsComponent extends BaseFormGroupComponent<ControlNames> implements OnInit {
    @Input() canSaveAsDefault: boolean;
    @Input() isManagePaymentPreferencesFlow: boolean;
    @Input() readonly: boolean;
    @Input() isEasyPayEligible: boolean;
    @Input() isPaperlessEligible: boolean;

    selectedCardType: CardType = CardType.INVALID;

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    get isValidCardType(): boolean {
        return this.selectedCardType !== CardType.INVALID;
    }

    ngOnInit(): void {
        this.controllerValueDefault();
        this.controllerValueSave();
    }

    controllerValueDefault(): void {
        this.formGroup.get('makeDefaultPaymentMethod').valueChanges.subscribe((valueDefault) => {
            const valueSave = this.formGroup.get('savePaymentMethod').value;
            if (!valueSave && valueDefault) {
                this.formGroup.get('savePaymentMethod').setValue(true);
            }
        });
    }

    controllerValueSave(): void {
        this.formGroup.get('savePaymentMethod').valueChanges.subscribe((valueSave) => {
            const valueDefault = this.formGroup.get('makeDefaultPaymentMethod').value;
            if (!valueSave && valueDefault) {
                this.formGroup.get('makeDefaultPaymentMethod').setValue(false);
            }
        });
    }
}
