export class SuspendAutomaticPaymentsLanding {
    // eslint-disable-next-line max-params
    constructor(
        readonly billingAccountNumber: string,
        readonly amountDue: string,
        readonly amountDueDate: Date,
        readonly nextDueDate: Date,
        readonly canPayNow: boolean,
        readonly recurringDraftDay: string,
        readonly paymentPlan: string,
        readonly email: string
    ) { }
}
