import { Injectable } from '@angular/core';
import { QuickPayFlowService } from '../../../quick-pay/shared/services/quick-pay-flow/quick-pay-flow.service';
import { PageName } from '../../page-name.enum';
import { PaymentFlowType } from '../../../pay-bill/shared/payment-flow-type.service';

@Injectable()
export class ProgressBarHelperService {
    private basePageSteps = new Map<PageName, number>()
        .set(PageName.QUICK_PAY_PICKER, 2)
        .set(PageName.PAY_BILL_LANDING, 1)
        .set(PageName.PAY_BILL_REVIEW, 2)
        // eslint-disable-next-line no-magic-numbers
        .set(PageName.PAY_BILL_CONFIRMATION, 3);

    constructor(
        private quickPayFlow: QuickPayFlowService,
        private paymentFlowType: PaymentFlowType
    ) { }

    getSteps(currentPageName: PageName): ProgressBarSteps {
        const multiplePayableAccounts = this.quickPayFlow.payableAccountSize > 1;
        let totalSteps = 3;
        let currentStep = this.basePageSteps.get(currentPageName);

        if (this.paymentFlowType.isQuickpay) {
            currentStep = currentPageName === PageName.QUICK_PAY_PICKER ? currentStep : currentStep + 1;
            totalSteps++;
        }

        if (multiplePayableAccounts) {
            currentStep = currentPageName === PageName.QUICK_PAY_PICKER ? currentStep : currentStep + 1;
            totalSteps++;
        }

        return { currentStep, totalSteps };
    }
}

export interface ProgressBarSteps {
    currentStep: number;
    totalSteps: number;
}
