<fieldset id="bank-account-number-form-controls" [formGroup]="formGroup" *ngIf="formGroup">
    <legend class="fields-only"></legend>
    <div class="bolt-row">
        <div class="bolt-col-md-5 bolt-space-bottom-xl">
            <div *ngIf="readonly">
                Bank routing number<br />
                <input type="text" id="routing-number" formControlName="routingNumber" class="form-control-plaintext" [attr.readonly]="readonly" aria-required="true" (blur)="fetchBankName()"
                    maxlength="9" aria-describedby="routing-number-errors" aria-label="Bank Routing Number" [attr.disabled]="(plaidResponse && prod) ? '' : null">
            </div>
            <ng-container *ngIf="!readonly">
                <div class="bolt-row">
                    <div class="bolt-col">
                        <span class="bolt-heading">Bank routing number</span>
                        <bolt-contextual-help>
                            <p>The first set of 9 numbers on the bottom left of your check is your
                                bank routing number.</p>
                            <div class="bolt-row">
                                <img alt="bank check sample" src="../../../../assets/images/check-help-image.jpg" class="bolt-col-sm-12">
                            </div>
                        </bolt-contextual-help>
                    </div>
                </div>
                <bolt-textfield type="text" ngDefaultControl id="routing-number" label="" formControlName="routingNumber" [ngClass]="{ 'form-control-plaintext': readonly }"
                    [attr.readonly]="readonly ? '' : null" required="true" (blur)="fetchBankName()" maxlength="9" ariadescribedby="routing-number-errors"
                    [attr.invalid]="routingNumberErrorDisplayable ? true : null" [attr.disabled]="(plaidResponse && prod) ? '' : null">
                </bolt-textfield>
            </ng-container>
            <p [ngClass]="routingNumberErrorDisplayable ? 'bolt-space-bottom-sm' : 'mb-0'
				" id="bank-name" *ngIf="bankName.value">{{ bankName.value }}</p>
            <app-form-control-error id="routing-number-errors" *ngIf="routingNumberErrorDisplayable">
                <ul class='error-list'>
                    <li *ngIf="displayRequiredError">Bank routing number is required.</li>
                    <li *ngIf="displayInvalidError">Bank routing number is invalid.</li>
                </ul>
            </app-form-control-error>
        </div>
    </div>
    <div *ngIf="readonly" class="bolt-row">
        <div class="bolt-col">
            <p class="update-disclaimer">
                <em>To update the routing number, remove the payment method then add it
                    again.</em>
            </p>
        </div>
    </div>
    <div class="bolt-row" *ngIf="!plaidResponse">
        <div class="bolt-col-md-12 bolt-space-bottom-xl">
            <div *ngIf="readonly">
                <div>
                    <label>Account number</label>
                </div>
                <span>********</span>
                <span>
                    <input type="text" id="account-number" formControlName="accountNumber" class="form-control-plaintext max-width-50percents nwpii" [attr.readonly]="readonly" aria-required="true"
                        maxlength="17" aria-describedby="account-number-errors" aria-label="Account number">
                </span>
            </div>
            <ng-container *ngIf="!readonly">

                <div class="bolt-row">
                    <div class="bolt-col-md-5">
                        <span class="bolt-heading">Account number</span>
                        <bolt-contextual-help>
                            <p>Your account number is a set of numbers that appear to the right of
                                your bank routing number and may start with zero.</p>
                            <div class="bolt-row">
                                <img alt="bank check sample" src="../../../../assets/images/check-help-image.jpg" class="bolt-col-sm-12">
                            </div>
                        </bolt-contextual-help>
                    </div>
                </div>
                <div class="bolt-row">
                    <div class="bolt-col-md-5">
                        <bolt-textfield type="text" ngDefaultControl id="account-number" label="" formControlName="accountNumber" [ngClass]="{ 'form-control-plaintext': readonly }"
                            [attr.readonly]="readonly ? '' : null" required="true" maxlength="17" ariadescribedby="account-number-errors" [attr.invalid]="accountNumberErrorDisplayable ? true : null"
                            [attr.disabled]="(plaidResponse && prod) ? '' : null" class="nwpii">
                        </bolt-textfield>
                    </div>
                </div>
            </ng-container>
            <app-form-control-error id="account-number-errors" *ngIf="accountNumberErrorDisplayable">
                <ul class="error-list">
                    <li *ngIf="accountNumber.errors['required']">Account number is required.</li>
                    <li *ngIf="(accountNumber.errors['minlength'] ||
						accountNumber.errors['containsOnlyDigits']) &&
						!accountNumber.errors['required']">
                        Account number is invalid.
                    </li>
                </ul>
            </app-form-control-error>
        </div>
    </div>

    <div class="bolt-row" *ngIf="plaidResponse">
        <div class="bolt-col-md-12 bolt-space-bottom-xl">
            <div *ngIf="readonly">
                <div>
                    <label>Account number</label>
                </div>
                <span>********</span>
                <span>
                    <input type="text" id="plaid-account-number" class="form-control-plaintext max-width-50percents nwpii" [attr.readonly]="readonly" aria-required="true" maxlength="17"
                        aria-describedby="plaid-account-number-errors" aria-label="Account number" [attr.disabled]="plaidResponse" [(ngModel)]="maskedAccountNumber"
                        [ngModelOptions]="{standalone: true}">
                </span>
            </div>
            <input type="hidden" formControlName="accountNumber">
            <ng-container *ngIf="!readonly">
                <div class="bolt-row">
                    <div class="bolt-col-md-5">
                        <span class="bolt-heading">Account number</span>
                        <bolt-contextual-help>
                            <p>Your account number is a set of numbers that appear to the right of
                                your bank routing number and may start with zero.</p>
                            <div class="bolt-row">
                                <img alt="bank check sample" src="../../../../assets/images/check-help-image.jpg" class="bolt-col-sm-12">
                            </div>
                        </bolt-contextual-help>
                    </div>
                </div>
                <div class="bolt-row">
                    <div class="bolt-col-md-5">
                        <bolt-textfield type="text" ngDefaultControl id="plaid-account-number" label="" [ngClass]="{ 'form-control-plaintext': readonly }" [attr.readonly]="readonly ? '' : null"
                            required="true" maxlength="17" ariadescribedby="plaid-account-number-errors" [attr.disabled]="plaidResponse" [attr.value]="maskedAccountNumber" class="nwpii">
                        </bolt-textfield>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="readonly" class="bolt-row">
        <div class="bolt-col">
            <p class="update-disclaimer">
                <em>To update the account number, remove the payment method then add it
                    again.</em>
            </p>
        </div>
    </div>
    <div *ngIf="!readonly && !plaidResponse" class="bolt-row">
        <div class="bolt-col-md-5">
            <bolt-textfield type="text" ngDefaultControl appDisablePaste id="confirm-account-number" label="Confirm account number" formControlName="confirmAccountNumber" required="true"
                maxlength="17" ariaLabel="Confirm Account Number" [attr.invalid]="confirmAccountNumberErrorDisplayable ? true : null" ariadescribedby="confirm-account-number-errors"
                class="nwpii"></bolt-textfield>
            <app-form-control-error id="confirm-account-number-errors" *ngIf="confirmAccountNumberErrorDisplayable">
                <ul class="error-list">
                    <li *ngIf="confirmAccountNumber.errors['required']">
                        Confirm your account number.
                    </li>
                    <li *ngIf="!confirmAccountNumber.errors['required'] &&
						(confirmAccountNumber.errors['containsOnlyDigits'] ||
						confirmAccountNumber.errors['minlength'])">
                        Confirm account number is invalid.
                    </li>
                    <li *ngIf="confirmAccountNumber.errors['confirmationValueMatches'] &&
						!confirmAccountNumber.errors['required']
						&& !confirmAccountNumber.errors['containsOnlyDigits'] &&
						!confirmAccountNumber.errors['minlength']">
                        Account number and confirm account number must match.
                    </li>
                </ul>
            </app-form-control-error>
        </div>
    </div>
</fieldset>