<fieldset class="account-type-selection-fieldset bolt-space-bottom-xl" [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <ng-container *ngIf="!readonly">
        <span class="bolt-heading">{{ accountTypeLabel }}</span>
        <app-form-control-error id="account-type-errors" *ngIf="accountTypeErrorDisplayable">
            <ul class="error-list">
                <li *ngIf="accountType?.errors['required']">Select an account type.</li>
            </ul>
        </app-form-control-error>
        <bolt-radio-group [attr.label]="" [attr.horizontal]="isHorizontal" required ngDefaultControl id="checking-radio" name="checking-radio" formControlName="accountType"
            aria-describedby="account-type-errors" aria-required="true" [attr.disabled]="plaidResponse">
            <bolt-radio value="Checking">Checking</bolt-radio>
            <bolt-radio value="Savings">Savings</bolt-radio>
        </bolt-radio-group>
    </ng-container>

    <ng-container *ngIf="readonly">
        <p id="account-type">Account type</p>
        <input type="text" id="bank-account-type" class="form-control-plaintext" formControlName="accountType" readonly aria-describedby="account-type" aria-required="true"
            [attr.disabled]="plaidResponse">
    </ng-container>
</fieldset>