/* eslint-disable no-negated-condition */
import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { OAuthEmitterService, OAuthEvent } from '@nationwide/angular-oauth-module';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TimeOutConfig, timeOutConfigToken } from './time-out.config';
import { TimeoutModalComponent } from './time-out.component';
import { SessionNavigation } from '../navigation/navigation.service';
import { PaymentFlowType } from '../../../pay-bill/shared/payment-flow-type.service';
import { environment } from '../../../../environments/environment';
import { ISBRouteParams } from '../../routing/isb-route.model';
import { QuickPayFlowService } from '../../../quick-pay/shared/services/quick-pay-flow/quick-pay-flow.service';
import { SessionService } from '../session.service';
import { LoggerService } from '../../logger/logger.service';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';

@Injectable()
export class TimeoutModalService {
    private lastSessionActivityTime: number;
    private isOpen: boolean;
    private checkTimeOutInterval: any;
    private tridionHelpText: any;

    // eslint-disable-next-line max-params
    constructor(
        protected router: Router,
        private oauthEmitterService: OAuthEmitterService,
        private modalService: NgbModal,
        private sessionNavigation: SessionNavigation,
        @Inject(timeOutConfigToken) private config: TimeOutConfig,
        private paymentFlowType: PaymentFlowType,
        private quickPayFlowService: QuickPayFlowService,
        private sessionService: SessionService,
        @Inject('logger') private readonly logger: LoggerService,
        private readonly contentfulMapperService: ContentfulMapperService
    ) {
        this.lastSessionActivityTime = this.currentTime;
        this.isOpen = false;
    }

    pollTimeOut(): void {
        this.fetchHelpText();
        clearInterval(this.checkTimeOutInterval);
        this.checkTimeOutInterval = setInterval(() => this.onTimeoutPoll(), this.config.pollIntervalMilliseconds);
    }

    onTimeoutPoll(): void {
        if (this.shouldTimeoutUser) {
            this.logWhereUserTimesOut();
            if (this.paymentFlowType.isQuickpay) {
                this.clearTimeOutSession();
                this.modalService.dismissAll();
                this.navigateQuickPaySearchPage(true);
            } else {
                this.sessionNavigation.sessionLogOutUser();
            }
        } if (!this.isOpen) {
            if (this.shouldWarnUser) {
                this.showTimeOutModal();
            } else if (this.shouldRefreshSessionForActiveUser) {
                this.refreshSession();
            }
        }
    }

    recordSessionActivity(): void {
        if (!this.onExcludedRoute && !this.isOpen) {
            this.lastSessionActivityTime = this.currentTime;
        }
    }

    navigateQuickPaySearchPage(isSessionTimeOut: boolean): void {
        const iSBRouteParams: ISBRouteParams = this.quickPayUrlParams;
        let quickPaySearchEndPoint = environment.ISB.ENDPOINTS.QUICK_PAY.quickPaySearch(this.quickPayUrlParams);
        let specialCharacter = !iSBRouteParams.referringPage ? '?' : '&';
        if (this.sessionService.isLifeQuickPayFlagFromSaved) {
            quickPaySearchEndPoint = `${quickPaySearchEndPoint}${specialCharacter}lob=life`;
            specialCharacter = '&';
        }
        if (isSessionTimeOut) {
            this.sessionService.isSessionTimeOut = isSessionTimeOut;
        }
        this.router.navigateByUrl(quickPaySearchEndPoint);
    }

    endSessionInQuickPay(): void {
        this.clearTimeOutSession();
        this.navigateQuickPaySearchPage(false);
    }

    logWhereUserTimesOut(): void {
        const payBillLanding = 'pay bill landing';
        const payBillReview = 'pay bill review';
        if (this.paymentFlowType.isQuickpay || this.paymentFlowType.cyberLifePolicyQuickPayFlow) {
            if (this.sessionService.currentPageFromSessionStorage === payBillLanding) {
                this.logger.info('QUICK PAY FLOW: USER TIMES OUT ON MAP LANDING PAGE');
            } else if (this.sessionService.currentPageFromSessionStorage === payBillReview) {
                this.logger.info('QUICK PAY FLOW: USER TIMES OUT ON MAP REVIEW PAGE');
            }
        } else if (this.sessionService.currentPageFromSessionStorage === payBillLanding) {
            this.logger.info('AUTHENTICATED FLOW: USER TIMES OUT ON MAP LANDING PAGE');
        } else if (this.sessionService.currentPageFromSessionStorage === payBillReview) {
            this.logger.info('AUTHENTICATED FLOW: USER TIMES OUT ON MAP REVIEW PAGE');
        }
    }

    private fetchHelpText(): void {
        const contentfulResponse = this.contentfulMapperService.getContentfulSpinnerMessage();
        contentfulResponse.subscribe(({ waitSpinnerMessages }) => {
            const output = {
                Components: {}
            };
            (<any>waitSpinnerMessages.components).forEach(({ fields }) => {
                const { id: ID, Name, Content } = fields;
                output.Components[fields.id] = {
                    ID,
                    Name,
                    Content
                };
            });
            this.tridionHelpText = output;
        });
    }

    private get shouldTimeoutUser(): boolean {
        return !this.onExcludedRoute && !this.userIsActive && this.currentTime > this.tokenExpiration;
    }

    private get shouldRefreshSessionForActiveUser(): boolean {
        return this.nearSessionExpiration && this.userIsActive;
    }

    private refreshSession(): void {
        this.oauthEmitterService.emitEvent(new OAuthEvent('OAuthPending'));
        const emitterSub = this.oauthEmitterService.oauthEmitter.subscribe((event) => {
            if (
                event.type === 'OAuthFailure' ||
                event.type === 'OAuthSuccess' ||
                event.type === 'OAuthNotNecessary'
            ) {
                this.isOpen = false;
                emitterSub.unsubscribe();
            }
        });
    }

    private get shouldWarnUser(): boolean {
        return this.tridionHelpText && this.nearSessionExpiration && !this.userIsActive;
    }

    private showTimeOutModal(): Promise<void> {
        const modalRef = this.openModal();
        modalRef.componentInstance.tridionHelpText = this.tridionHelpText.Components;
        const onKeepWorking = (): void => this.refreshSession();
        // eslint-disable-next-line no-confusing-arrow
        const onLogOutOrEndSession = (): void => this.paymentFlowType.isQuickpay ? this.endSessionInQuickPay() : this.sessionNavigation.logOutUser();
        return modalRef.result.then(onKeepWorking, onLogOutOrEndSession);
    }

    private openModal(): NgbModalRef {
        this.isOpen = true;
        return this.modalService.open(TimeoutModalComponent, {
            backdrop: 'static', // So overlay click doesn't dismiss modal
            keyboard: false, // So ESC key doesn't dismiss modal
            windowClass: 'wait-overlay'
        });
    }

    private get userIsActive(): boolean {
        return this.currentTime - this.config.inactivityThresholdMilliseconds < this.lastSessionActivityTime;
    }

    private get nearSessionExpiration(): boolean {
        return this.currentTime > this.tokenExpiration - this.config.timeToTimeoutWarningMilliseconds;
    }

    private get currentTime(): number {
        return Date.now();
    }

    private get tokenExpiration(): number {
        const concatenatedDetailsString = window.sessionStorage.getItem(this.config.accessTokenKey);
        if (concatenatedDetailsString) {
            const details = concatenatedDetailsString.split(',');
            const lifetime = +details[1];
            return lifetime;
        }
        return NaN;
    }

    private get onExcludedRoute(): boolean {
        return this.config.excludedPaths.includes(this.router.url);
    }

    private get quickPayUrlParams(): ISBRouteParams {
        return {
            referringPage: ''
        };
    }

    private clearTimeOutSession(): void {
        const isSessionTimeOut = this.sessionService.isSessionTimeOut;
        const isLifeQuickPayFlagFromSaved = this.sessionService.isLifeQuickPayFlagFromSaved;
        this.sessionNavigation.clearTimeOutSession();
        this.quickPayFlowService.reset();
        this.sessionService.isSessionTimeOut = isSessionTimeOut;
        this.sessionService.isLifeQuickPayFlagFromSaved = isLifeQuickPayFlagFromSaved;
    }
}
