<fieldset [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <div class="bolt-row bolt-space-bottom-xl">
        <div class="bolt-col-md-7 bolt-col-sm-12 bottom-space-sm">
            <bolt-textfield type="text" ngDefaultControl id="street" formControlName="street" label="Street" required="true" maxlength="128" ariaLabel="Street"
                [attr.invalid]="streetErrorsDisplayable ? true : null" ariadescribedby="street-errors"></bolt-textfield>
            <app-form-control-error id="street-errors" *ngIf="streetErrorsDisplayable">
                <ul class="error-list">
                    <li *ngIf="street.errors['required'] || street.errors['containsNonSpaceCharacters']">Street is
                        required.</li>
                    <li *ngIf="!street.errors['required'] && !street.errors['containsNonSpaceCharacters'] && street.errors['onlyStreetCharacters']">
                        Street is invalid.
                    </li>
                </ul>
            </app-form-control-error>
        </div>
        <div class="bolt-col-lg-3 bolt-col-md-4 bolt-col-sm-12 bottom-space-sm">
            <bolt-textfield type="text" ngDefaultControl id="apartment-number" formControlName="apartmentNumber" label="Apt/Unit" required="false" maxlength="8" ariaLabel="apartment number"
                [attr.invalid]="aptNumberErrorsDisplayable ? true : null" ariadescribedby="apt-number-errors"></bolt-textfield>
            <app-form-control-error id="apt-number-errors" *ngIf="aptNumberErrorsDisplayable">
                <ul class="error-list">
                    <li *ngIf="aptNumber.errors['containsDigitsAndNameCharacters']">Apt/Unit is invalid.</li>
                </ul>
            </app-form-control-error>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-md-4 bolt-col-sm-12 bottom-space-sm">
            <bolt-textfield type="text" ngDefaultControl id="city" formControlName="city" label="City" required="true" maxlength="32" ariaLabel="city"
                [attr.invalid]="cityErrorsDisplayable ? true : null" ariadescribedby="city-errors"></bolt-textfield>
            <app-form-control-error id="city-errors" *ngIf="cityErrorsDisplayable">
                <ul class="error-list">
                    <li *ngIf="city.errors['required'] || city.errors['containsNonSpaceCharacters']">
                        City is required.
                    </li>
                    <li *ngIf="!city.errors['required'] && !city.errors['containsNonSpaceCharacters'] && city.errors['containsNameCharacters']">
                        City is invalid.
                    </li>
                </ul>
            </app-form-control-error>
        </div>
        <div class="bolt-col-md-3 bolt-col-sm-12 bottom-space-sm">
            <bw-bolt-select [label]='"State"' [invalid]="isStateError || stateErrorsDisplayable ? true : null" [placeholder]='"Select"' [optionalText]='"hide"' [required]="true" id="state"
                formControlName="state" ngDefaultControl (change)="onStateChange($event)" [ngModelOptions]="{standalone: true}">
                <option *ngFor="let stateName of states" [ngValue]="stateName" [selected]="state.value===stateName">
                    {{ stateName | uppercase }}
                </option>
            </bw-bolt-select>
            <app-form-control-error id="state-errors" *ngIf="isStateError || stateErrorsDisplayable">
                <ul class="error-list">
                    <li *ngIf="state.errors['required']">Select a state.</li>
                </ul>
            </app-form-control-error>
        </div>

        <div class="bolt-col-md-3 bolt-col-sm-12">
            <app-zip-code-form-control formGroupName="zipGroup" [isInline]='true'>
            </app-zip-code-form-control>
        </div>
    </div>
</fieldset>