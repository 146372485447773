import { Injectable, Inject } from '@angular/core';
import { SessionService } from '../../../../../../shared/session/session.service';
import { isbExperienceServiceToken, IsbExperienceService, IsbExperience } from '@nationwide/dgs-angular-billing-common';
import { Observable, map } from 'rxjs';
import { SetAsDefaultFlow } from './set-as-default-flow.service';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { DatePipe } from '@angular/common';
import { updatePaymentCardTypeMap } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { PaymentMethodsAdapter } from '../../../../shared/adapters/payment-methods.adapter';
import { PaymentItem } from '../../../../shared/models/payment-item.model';

@Injectable()
export class SetAsDefaultApiService {
    // eslint-disable-next-line max-params
    constructor(
        private session: SessionService,
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private setAsDefaultFlow: SetAsDefaultFlow,
        private paymentPreferencesState: PaymentPreferencesStateService,
        private datePipe: DatePipe,
        private paymentPreferencesApi: PaymentPreferencesApiService,
        private savedPaymentMethodAdapter: PaymentMethodsAdapter
    ) { }

    fetchPaymentMethod(): Observable<PaymentItem> {
        return this.paymentPreferencesApi.getSingleSavedPaymentMethod().pipe(
            map((res) => {
                const mappedPaymentMethod = this.savedPaymentMethodAdapter.mapSinglePaymentMethod(res);
                this.setAsDefaultFlow.save({
                    mappedPaymentMethod,
                    unmappedPaymentMethod: res
                });
                return mappedPaymentMethod;
            })
        );
    }

    updateSavedPaymentMethod(): Observable<IsbExperience.SavedPaymentMethod> {
        const paymentItem = this.setAsDefaultFlow.mappedPaymentMethod;
        const unmappedItem = this.setAsDefaultFlow.unmappedPaymentMethod;

        return this.isbExperience.putSavedPaymentMethod({
            billingSystem: this.session.billingSystem,
            accessToken: this.session.accessToken,
            methodID: this.paymentPreferencesState.currentPaymentMethodId,
            relatedPaymentMethodIDs: paymentItem.relatedPaymentMethodIDs,

            payload: {
                billingPayload: {
                    billingAccountNumber: this.session.billingAccountNumber,
                    description: '',
                    enterpriseCustomerNumber: this.session.ecn,
                    preferredMethod: true,
                    recurringMethod: paymentItem.recurringMethod,
                    payorInfo: {
                        ...unmappedItem.payorInfo,
                        displayName: `${unmappedItem.payorInfo.firstName} ${unmappedItem.payorInfo.lastName}`,
                        emailAddress: this.session.emailId
                    },

                    paymentMethodType: paymentItem.isBankCard ?
                        'BankCard.SavedPaymentMethod' : 'ElectronicFundsTransfer.SavedPaymentMethod',

                    bankCard: paymentItem.assertIsBankCard(paymentItem.item) ? {
                        expirationDate: this.datePipe.transform(paymentItem.item.cardExpiration, 'yyyyMM'),
                        ccLastFour: paymentItem.item.cardNumberLastFour,
                        cardBrand: updatePaymentCardTypeMap.get(paymentItem.item.cardType)
                    } : undefined,

                    electronicFundsTransfer: paymentItem.assertIsBankAccount(paymentItem.item) ? {
                        bankAccountType: paymentItem.item.accountType.toUpperCase(),
                        bankName: paymentItem.item.bankName,
                        bankRoutingNumber: paymentItem.item.routingNumber,
                        maskedBankAccountNumber: paymentItem.item.maskedBankAccountNumber,
                        bankAccountNumber: paymentItem.item.encryptedAccountNumber,
                        encryptedBankAccountNumber: paymentItem.item.encryptedAccountNumber
                    } : undefined
                }
            }
        });
    }
}
