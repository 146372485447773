import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ISBRouteParams } from '../../shared/routing/isb-route.model';
import { SessionService } from '../../shared/session/session.service';
import { PageName } from '../../shared/page-name.enum';

@Injectable()
export class BillingDueDateConfirmationGuard implements CanActivate {
    constructor(
        private session: SessionService,
        private router: Router
    ) { }

    canActivate(): boolean {
        let canActivate = true;

        if (this.session.currentPage === PageName.CHANGE_DUE_DATE_CONFIRMATION) {
            canActivate = false;
            this.cancelMakePayment();
        }

        return canActivate;
    }

    private cancelMakePayment(): void {
        const url: string = environment.ISB.ENDPOINTS.RELATIVE.billsAndPayments(this.urlParams);
        this.router.navigateByUrl(url);
    }

    private get urlParams(): ISBRouteParams {
        return {
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId
        };
    }
}
