export const QUICK_PAY_CONSTANTS = {
    GLOBAL_FORM_VALIDATION_ERROR_MESSAGE: 'Please check the information below and try again.',
    INCORRECT_FIELDS_SERVER_ERROR_MESSAGE: 'We cannot locate an account associated with this information. Please check the information below and try again.',
    CODE_407_ERROR_MESSAGE: 'You are currently signed up for automatic payments.  Please Log in/Sign up to manage your payment preferences.',
    CODE_408_ERROR_MESSAGE: 'You have no payment due at this time.  Please log in or Sign up to view your account information.',
    CANCELLED_OR_INELIGIBLE_STATUS_ERROR_MESSAGE: 'Unfortunately, not all Accounts and Policies are eligible for Payment without login at this time. Please Login/Sign up for an online account to make your payment.',
    BACKEND_SERVICE_ISSUE_ERROR_MESSAGE: 'An error occurred while accessing your policy information. Please try again later.',
    MM_DD_YYYY: 'MM/DD/YYYY',
    SEARCH_BY_AGREEMENT_NUMBER: 'AgreementSearch',
    SEARCH_BY_PHONE_NUMBER: 'PhoneNumberSearch',
    CONFIRMATION: 'confirmation',
    DATE_FORMAT_FOR_AUTH: 'YYYY-MM-DD',
    DATE_FORMAT_FOR_QUICK_PAY_SERVICE: 'MMDDYYYY',
    DELETE_KEY_CODE: 46,
    BACKSPACE_KEY_CODE: 8,
    RETURN_FROM_ICP_ERROR_QUERY_PARAM: 'error',
    CYBER_LIFE: 'CYBERLIFE',
    NATION_WIDE_COM_BUTTON: 'NationwideComButton',
    BILLING: 'Billing',
    POLICY: 'Policy',
    SESSION_TIMEOUT_ERROR_MESSAGE: 'Your Session has expired. Please try again.',
    MESSAGE_LIFE_SEARCH_PAGE_PHONE_NUMBER_TAB: `For Life policies that begin with 8 and issued after Sept. 1, 2018, or begin with letter L, search by billing account or policy number might produce better results.`,
    PERSONAL: 'Personal',
    COMMERCIAL: 'Commercial',
    LIFE: 'Life',
    EBI_SOURCE_SYSTEM_CHARACTER_LIMIT: 5,
    SAP_BILLING: 'SAP Billing',
    COMMERCIAL_POLICIES: ['CL PolicyCenter', 'GLO', 'CAS', 'PES', 'PAK', 'BOP', 'WINS', 'FMP', 'WCS', 'CPP'],
    HARLEYSVILLE_POLICIES: ['HV Duck Creek', 'HV IADB Billing ACcount', 'HV IADB Insured Account', 'AQS'],
    LIFE_POLICIES: ['Vantage', 'ALS', 'CFO', 'TPP', 'CLI'],
    RETIREMENT_POLICIES: ['KYNA', 'DCDA', 'PALLM'],
    PET_POLICIES: ['VPI', 'Pet - Guidewire Insurance Now'],
    PRIVATE_CLIENT: ['NW Private Client - Policy'],
    ONLY_ALLOW_POLICY_NUMBER_SYSTEM_POST_PAYMENT_SERVICE: ['CYBERLIFE', 'SAP Billing'],
    ADOBE_COOKIE_EXPIRATION: 30,
    // If you're enrolled in automatic payments, please log in to make a payment.
    NEW_AUTOMATIC_PAYMENT_CONTENT_FIRST_PART: `If you're enrolled in automatic payments, please`,
    NEW_AUTOMATIC_PAYMENT_CONTENT_SECOND_PART: ` to make a payment.`,
    PREFILL_DASHBOARD_AGREEMENT_NUMBER: 'PrefillDashBoardAgreementNumber',
    PREFILL_DASHBOARD_AGREEMENT_AND_QP_INPUT_VALUES: 'PrefillDashBoardAgreementAndQPInputValues',
    PREFILL_DASHBOARD_MAKE_PAYMENT_COMPLETED_VALUES: 'PrefillDashBoardMakePaymentCompletedValues',
    PICKER_PAGE_TILE_ELIGIBLE_SYSTEM_LIST: ['CYBERLIFE', 'SAP BILLING', 'NBP'],
    IMAGE_NAME_LIST: ['pastDue', 'paid', 'cancelled', 'autoPay', 'due', 'warning'],
    ACTION_BUTTONS_OR_LINKS: {
        PAY_THIS_BILL: 'Pay this bill',
        PAY_THIS_BILL_AUTO_PAY_ENROLLED_PAYABLE: 'Pay this bill - auto pay enrolled payable',
        PAY_THIS_BILL_AUTO_PAY_ENROLLED_NON_PAYABLE: 'Pay this bill - auto pay enrolled non payable',
        PAY_ADDITIONAL_AMOUNTS: 'Pay additional amount',
        PAID_IN_FULL_NO_FURTHER_PAYMENTS: 'Paid in full, no further payments needed',
        LOG_IN_TO_MAKE_AUTO_PAY_CHNAGES: 'Log in to make auto pay changes',
        SEARCH_AGENT_DIRECTORY: 'Search the agent directory',
        SEARCH_AGENT_DIRECTORY_CYBERLIFE: 'Search the agent directory - Cyberlife',
        THIRD_PARTY_BILLED: 'Third Party Billed'
    },
    DIRECT_BILL_PAYMENT_METHODS: ['CPPDB', 'CPP3P']
};
