import { ManagePaymentPreferencesPageTitle } from '../shared/models/manage-payment-preferences-page-title.constant';
import { EditPaymentPlanComponent } from './edit/edit-payment-plan.component';
import { EditPaymentPlanLandingComponent } from './edit/landing/edit-payment-plan-landing.component';
import { EditPaymentPlanConfirmationComponent } from './edit/confirmation/edit-payment-plan-confirmation.component';
import { EditPayplanBackFromConfirmationGuard } from './edit/guards/edit-pay-plan-back-from-confirmation-guard';
import { StateClearingGuard } from '../../../../shared/service/guards/state-clearing-guard/state-clearing.guard';

export const PaymentPlanRoutes = [
    {
        path: 'payment-plan/edit',
        component: EditPaymentPlanComponent,
        children: [
            {
                path: 'landing',
                component: EditPaymentPlanLandingComponent,
                data: { title: ManagePaymentPreferencesPageTitle.paymentPlan.edit.payplan },
                canActivate: [EditPayplanBackFromConfirmationGuard]
            },
            {
                path: 'confirmation',
                component: EditPaymentPlanConfirmationComponent,
                data: { title: ManagePaymentPreferencesPageTitle.paymentPlan.edit.confirmation },
                canDeactivate: [StateClearingGuard]
            }
        ]
    }
];
