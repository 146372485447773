export class BankAccountPaymentItem {
    // eslint-disable-next-line max-params
    constructor(
        readonly firstName: string,
        readonly lastName: string,
        readonly middleInitial: string,
        readonly street: string,
        readonly apartmentNumber: string,
        readonly city: string,
        readonly state: string,
        readonly zip: string,
        readonly bankName: string,
        readonly accountType: BankAccountType,
        readonly routingNumber: string,
        readonly maskedBankAccountNumber: string,
        readonly encryptedAccountNumber = ''
    ) { }

    get displayNumbers(): string {
        return this.maskedBankAccountNumber;
    }
}

export type BankAccountType = 'Checking' | 'Savings' | '';
