import { UntypedFormGroup } from '@angular/forms';
import { CardType } from './card-utils/card-type.enum';
import { cardUtils } from './card-utils/card-utils';

export class PaymentFormGroup {
    constructor(
        public group: UntypedFormGroup
    ) { }

    get bankName(): string {
        const plaidBankName = this.group.get('paymentMethodGroup.bankAccountInfoGroup.accountNumberGroup.bankName');
        const payPrefsBankName = this.group.get('paymentMethodGroup.accountNumberGroup.bankName');
        return plaidBankName?.value || payPrefsBankName?.value || '';
    }

    get bankAccountType(): string {
        const plaidBankAccountType = this.group.get('paymentMethodGroup.bankAccountInfoGroup.accountTypeGroup.accountType');
        const payPrefsBankAccountType = this.group.get('paymentMethodGroup.accountTypeGroup.accountType');
        return plaidBankAccountType?.value || payPrefsBankAccountType?.value || '';
    }

    get bankRoutingNumber(): string {
        const plaidBankRoutingNumber = this.group.get('paymentMethodGroup.bankAccountInfoGroup.accountNumberGroup.routingNumber');
        const payPrefsBankRoutingNumber = this.group.get('paymentMethodGroup.accountNumberGroup.routingNumber');
        return plaidBankRoutingNumber?.value || payPrefsBankRoutingNumber?.value || '';
    }

    get bankAccountNumber(): string {
        const plaidBankAccountNumber = this.group.get('paymentMethodGroup.bankAccountInfoGroup.accountNumberGroup.accountNumber');
        const payPrefsBankAccountNumber = this.group.get('paymentMethodGroup.accountNumberGroup.accountNumber');
        return plaidBankAccountNumber?.value || payPrefsBankAccountNumber?.value || '';
    }

    get confirmBankAccountNumber(): string {
        const plaidConfirmBankAccountNumber = this.group.get('paymentMethodGroup.bankAccountInfoGroup.accountNumberGroup.confirmAccountNumber');
        const payPrefsConfirmBankAccountNumber = this.group.get('paymentMethodGroup.accountNumberGroup.confirmAccountNumber');
        return plaidConfirmBankAccountNumber?.value || payPrefsConfirmBankAccountNumber?.value || '';
    }

    get cardType(): CardType {
        const cardNumber = this.bankCardNumber;
        return cardUtils.parseType(cardNumber);
    }

    get bankCardNumber(): string {
        const control = this.group.get('paymentMethodGroup.bankCardFormGroup.bankCardNumber');
        return control?.value || '';
    }

    get bankCardCvv(): string {
        const control = this.group.get('paymentMethodGroup.cvvGroup.cvv');
        return control?.value || '';
    }

    get cardExpirationMonth(): string {
        const control = this.group.get('paymentMethodGroup.cardExpirationGroup.cardExpirationMonth');
        return control?.value || '';
    }

    get cardExpirationYear(): string {
        const control = this.group.get('paymentMethodGroup.cardExpirationGroup.cardExpirationYear');
        return control?.value || '';
    }

    get savePaymentMethod(): string {
        const control = this.group.get('considerThisGroup.savePaymentMethod');
        return control?.value || '';
    }

    get makeDefaultPaymentMethod(): boolean {
        const control = this.group.get('paymentMethodGroup.makeDefaultPaymentMethod');
        return control?.value || false;
    }

    get firstName(): string {
        const control = this.group.get('paymentMethodGroup.nameGroup.firstName');
        return control?.value || '';
    }

    get middleInitial(): string {
        const control = this.group.get('paymentMethodGroup.nameGroup.middleInitial');
        return control?.value || '';
    }

    get lastName(): string {
        const control = this.group.get('paymentMethodGroup.nameGroup.lastName');
        return control?.value || '';
    }

    get street(): string {
        const control = this.group.get('paymentMethodGroup.addressGroup.street');
        return control?.value || '';
    }

    get apartmentNumber(): string {
        const control = this.group.get('paymentMethodGroup.addressGroup.apartmentNumber');
        return control?.value || '';
    }

    get city(): string {
        const control = this.group.get('paymentMethodGroup.addressGroup.city');
        return control?.value || '';
    }

    get state(): string {
        const control = this.group.get('paymentMethodGroup.addressGroup.state');
        return control?.value || '';
    }

    get zip(): string {
        const control =
            this.group.get('paymentMethodGroup.addressGroup.zipGroup.zip') ||
            this.group.get('paymentMethodGroup.zipGroup.zip');

        return control?.value || '';
    }

    get emailAddress(): string {
        const control = this.group.get('emailAddressGroup.emailAddress');
        return control?.value || '';
    }

    get enrollInFutureAutoPay(): string {
        const control = this.group.get('considerThisGroup.futureAutoPayment');
        return control?.value || '';
    }

    get enrollInPaperless(): string {
        const control = this.group.get('considerThisGroup.enrollInPaperless');
        return control?.value || '';
    }

    get enrollInEasyPay(): string {
        const control = this.group.get('considerThisGroup.enrollInEasyPay');
        return control?.value || '';
    }

    get enrollInChangeDueDate(): string {
        const control = this.group.get('considerThisGroup.enrollInChangeDueDate');
        return control?.value || '';
    }

    get saveAsRefundMethodSelected(): string {
        const control = this.group.get('considerThisGroup.saveAsRefundMethodGroup.selection');
        return control?.value || '';
    }

    get saveAsRefundMethodAccounts(): string[] {
        const control = this.group.get('considerThisGroup.saveAsRefundMethodGroup.selectedAgreements');
        return control?.value || [];
    }

    get bankAccountInfo(): string {
        const control = this.group.get('paymentMethodGroup.bankAccountInfoGroup.bankAccountInfo');
        return control?.value || '';
    }
}
