<ng-container [formGroup]="formGroup">
    <div class="section-content">
        <div class="bolt-row">
            <div class="bolt-col-md-12">
                <p>
                    Check that you've read and agree to the terms of the automatic payments.
                </p>
            </div>
            <div class="bolt-col-md-12">
                <p>
                    <bolt-button type="tertiary" (click)="openAuthForm()" class="bolt-button-link-tertiary">
                        Automatic payments authorization agreement
                    </bolt-button>
                </p>
            </div>

            <div class="bolt-col-md-12">
                <div class="bolt-row">
                    <div class="bolt-col-12">
                        <bolt-checkbox ngDefaultControl formControlName="acknowledged" id="ez-pay-acknowledgement" required>I have read and agree to the terms of the agreement.
                        </bolt-checkbox>
                        <app-form-control-error id="acknowledgement-error-messages" *ngIf="acknowledgementErrorsDisplayable">
                            <ul class="error-list">
                                <li *ngIf="acknowledged.invalid || acknowledgementDisabled">
                                    Accepting the automatic payments authorization agreement is required.
                                </li>
                            </ul>
                        </app-form-control-error>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>