import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';

export type ControlNames = 'accountTypeGroup' | 'nameGroup' | 'addressGroup' | 'accountNumberGroup' | 'makeDefaultPaymentMethod'
    | 'savePaymentMethod';

@Component({
    selector: 'app-bank-account-form-controls',
    templateUrl: './bank-account-form-controls.component.html',
    styleUrls: [
        './bank-account-form-controls.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class BankAccountFormControlsComponent extends BaseFormGroupComponent<ControlNames> implements OnInit {
    @Input() canSaveAsDefault: boolean;
    @Input() isManagePaymentPreferencesFlow: boolean;
    @Input() routingNumberServiceFailureMessaging: 'validRoutingNumber' | 'required' = 'validRoutingNumber';
    @Input() accountTypeLabel: string | undefined;
    @Input() isHorizontal: boolean | undefined;

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    ngOnInit(): void {
        this.controllerValueDefault();
        this.controllerValueSave();
    }

    controllerValueDefault(): void {
        this.formGroup.get('makeDefaultPaymentMethod').valueChanges.subscribe((valueDefault) => {
            const valueSave = this.formGroup.get('savePaymentMethod').value;
            if (!valueSave && valueDefault) {
                this.formGroup.get('savePaymentMethod').setValue(true);
            }
        });
    }

    controllerValueSave(): void {
        this.formGroup.get('savePaymentMethod').valueChanges.subscribe((valueSave) => {
            const valueDefault = this.formGroup.get('makeDefaultPaymentMethod').value;
            if (!valueSave && valueDefault) {
                this.formGroup.get('makeDefaultPaymentMethod').setValue(false);
            }
        });
    }
}
