import { Injectable } from '@angular/core';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaymentItem } from '../../../../../../billing/payment-preferences/shared/models/payment-item.model';
import { CtmEventCardTypeMap, CardType, CTMPaymentTypes } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { PaperlessEnrollmentBaseService } from '../../../../shared/services/paperless-enrollment-error.service';
import { SessionService } from '../../../../../../shared/session/session.service';

@Injectable()
export class EditAutomaticPaymentConfirmationService extends PaperlessEnrollmentBaseService {
    private readonly UP_NEXT_TAB_LABELS = {
        PAPERLESS_ENROLLMENT: 'Paperless enrollment',
        PAYMENT_PREFERENCES: 'Payment preferences',
        MAKE_A_PAYMENT: 'Make a payment'
    };
    private readonly MASKED_NUMBERS = {
        BANK_ACCOUNT: 3,
        BANK_CARD: 4
    };
    private _upNextLabel: string;
    constructor(private readonly editPaymentFlow: EditAutomaticPaymentFlowService, protected readonly session: SessionService) {
        super(session);
    }

    fetchCtmPaymentMethod(paymentMethod: PaymentItem, paymentMethodForm: PaymentFormGroup): CTMPaymentTypes {
        let output;
        if (paymentMethod.isMasterPass) {
            output = 'Masterpass';
        } else if (paymentMethod.isBankAccount) {
            output = 'Bank Account';
        } else if (paymentMethod.isBankCard) {
            if (paymentMethod.isNewMethod) {
                output = CtmEventCardTypeMap.get(paymentMethodForm.cardType);
            } else {
                output = CtmEventCardTypeMap.get(<CardType>paymentMethod.methodType);
            }
        } else {
            output = '';
        }

        return output;
    }

    get amountDue(): number {
        return Number(this.editPaymentFlow.amountDue);
    }

    get amountDueDate(): Date {
        return this.editPaymentFlow.currentBillDueDate;
    }

    get automaticPaymentSuspended(): boolean {
        return this.editPaymentFlow.automaticPaymentSuspended;
    }

    get upNextLabel(): string {
        this._upNextLabel = '';
        if (this.editPaymentFlow.successfullyUpdatedEnrollment) {
            if (this.amountDue > 0 && (this.automaticPaymentSuspended || this.editPaymentFlow.canPayNow)) {
                this._upNextLabel = this.UP_NEXT_TAB_LABELS.MAKE_A_PAYMENT;
            }
        }
        return this._upNextLabel;
    }

    get navigateToPaperless(): boolean {
        return this.editPaymentForm.enrollInPaperless === 'yes';
    }

    get editPaymentForm(): PaymentFormGroup {
        return this.editPaymentFlow.editAutomaticPaymentForm;
    }

    get paymentType(): string {
        let paymentType;
        if (this.editPaymentFlow.selectedPayMethod.isBankAccount) {
            paymentType = this.editPaymentFlow.editAutomaticPaymentForm.bankAccountType;
        } else if (this.editPaymentFlow.selectedPayMethod.isBankCard) {
            paymentType = this.editPaymentFlow.editAutomaticPaymentForm.cardType;
        }
        return paymentType;
    }

    get maskedNumber(): string {
        let bankNumber;
        if (this.editPaymentFlow.selectedPayMethod.isBankAccount) {
            bankNumber = this.editPaymentFlow.editAutomaticPaymentForm.bankAccountNumber;
            bankNumber = `*********${bankNumber.slice(bankNumber.length - this.MASKED_NUMBERS.BANK_ACCOUNT)}`;
        } else if (this.editPaymentFlow.selectedPayMethod.isBankCard) {
            bankNumber = this.editPaymentFlow.editAutomaticPaymentForm.bankCardNumber;
            bankNumber = `************${bankNumber.slice(bankNumber.length - this.MASKED_NUMBERS.BANK_CARD)}`;
        }
        return bankNumber;
    }
}
