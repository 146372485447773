import { Injectable, Inject } from '@angular/core';
import {
    PBSRequestParams,
    PersonalBillingRequestParams,
    PbsService,
    PersonalBillingService,
    PersonalBillingServiceToken,
    PBS,
    PersonalBilling as Unmapped,
    ECIM,
    enterpriseCIMServiceToken,
    EnterpriseCIMService,
    EnterpriseCIMRequestParams
} from '@nationwide/dgs-angular-billing-common';
import { Observable, of, zip, map, catchError } from 'rxjs';

@Injectable()
export class BillingAccountsService {

    constructor(
        private pbs: PbsService,
        @Inject(PersonalBillingServiceToken) private personalBillingService: PersonalBillingService,
        @Inject(enterpriseCIMServiceToken) private ecimService: EnterpriseCIMService
    ) { }

    fetchBillingAccounts(pbsRequestParams: PBSRequestParams):
        Observable<{ accounts: PBS.Agreement[] }> {
        return this.invokingServices(pbsRequestParams);
    }

    getAgreementFromResponse(response: any): PBS.Agreement[] {
        const agreements: PBS.Agreement[] = response.retrieveCustomerPortfolioResponse.return.profile.agreementGroup.agreement;
        return agreements instanceof Array ? agreements : [agreements];
    }

    getInternetRegistrations(internetRegistrationParams: EnterpriseCIMRequestParams): Observable<ECIM.EnterpriseCustomerInformationManagementResponse> {
        return this.ecimService.getInternetRegistrations(internetRegistrationParams).pipe(
            catchError(() => of(null))
        );
    }

    getPoliciesForAgreement(personalBillingRequestParams: PersonalBillingRequestParams): Observable<{ relatedPolicies: Unmapped.Policy[] }> {
        return this.personalBillingService.retrieveRelatedPolicies(personalBillingRequestParams);
    }

    private invokingServices(pbsRequestParams: PBSRequestParams): Observable<{ accounts: PBS.Agreement[] }> {
        return zip(
            this.pbs.retrieveCustomerProfile(pbsRequestParams)
        ).pipe(
            map((responses: any[]) => ({
                accounts: this.getAgreementFromResponse(responses[0])
            }))
        );
    }
}
