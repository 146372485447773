import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { SavedPaymentMethod } from '@nationwide/dgs-angular-billing-common/api-response-types/money-processing';
import { finalize, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { ManageAccountValidationService } from '../../../../../../account-validation/manage-account-validation.service';
import { BillingCommonComponent } from '../../../../../../billing/billing-common/billing-common.component';
import { PaymentItem } from '../../../../../../billing/payment-preferences/shared/models/payment-item.model';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { BillingAccountItem } from '../../../../../../shared/payments/consider-this/save-as-refund-method/models/billing-account-item.model';
import { EftAuthorizationFlowService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-flow.service';
import { EftAuthorizationFormHelperService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form-helper.service';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormControls } from '../../../../../../shared/payments/payment-form-controls';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PaymentMethodsAdapter } from '../../../../shared/adapters/payment-methods.adapter';
import { AddSavedPaymentMethodLanding } from '../../../../shared/services/payment-preferences-api/payment-preferences-api-calls.model';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SavedPaymentPrefs } from '../../saved-payment-prefs.model';
import { AddPaymentMethodFlowService } from '../services/add-payment-method-flow.service';
import { AccountPolicyHolderAddressElements } from './../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/form-elements/account-policy-holder-address-elements';

@Component({
    selector: 'app-add-payment-method-landing',
    templateUrl: './add-payment-method-landing.component.html',
    styleUrls: ['./add-payment-method-landing.component.scss']
})
export class AddPaymentMethodLandingComponent extends BillingCommonComponent implements OnInit {
    @ViewChild(FormGroupDirective) addPaymentFormDirective: FormGroupDirective;
    pageName = ManagePaymentPreferencesPageTitle.savedPaymentMethods.add;
    accountTypeGroup: AbstractControl;
    savedPaymentPrefs: SavedPaymentPrefs;
    considerThisGroup: AbstractControl;
    addPaymentForm: PaymentFormGroup;
    chosenPaymentMethod: PaymentItem;
    emailAddress: string;
    isPaperlessEligible: boolean;
    isEasyPayEligible: boolean;
    savedPaymentMethods: SavedPaymentMethod[] = [];
    billingAccountsForRefund: BillingAccountItem[];
    esddaCheckBoxChecked = false;
    paperlessEsddaValidationError = false;

    loadComplete = false;
    newPaymentMethodOptions: PaymentItem[];
    apiCallInProgress = true;

    paymentOptionsIndex = {
        bankAccount: 0,
        bankCard: 1
    };
    isManagePaymentPrefsFlow = true;
    formValidated = false;
    tridionErrorMessage: Observable<string>;

    // eslint-disable-next-line max-params
    constructor(
        router: Router,
        session: SessionService,
        private addPaymentFormBuilder: ManagePaymentPrefsFormBuilder,
        private paymentFormControls: PaymentFormControls,
        private paymentPrefsApiService: PaymentPreferencesApiService,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private addPaymentFlow: AddPaymentMethodFlowService,
        private formUtils: FormUtil,
        @Inject('logger') private logger: LoggerService,
        @Inject('window') private window: Window,
        eventService: BillingEventService,
        private manageAccountValidationService: ManageAccountValidationService,
        eftAuthorizationFormHelperService: EftAuthorizationFormHelperService,
        eftAuthorizationFlowService: EftAuthorizationFlowService,
        private urlUtil: UrlUtil,
        private readonly paymentMethodsAdapter: PaymentMethodsAdapter,
        private readonly contentfulMapperservice: ContentfulMapperService
    ) {
        super(session, router, eftAuthorizationFormHelperService, eventService, eftAuthorizationFlowService);
    }

    ngOnInit(): void {
        this.initialize();
    }

    initialize(): void {
        this.tridionErrorMessage = this.paymentPrefsApiService.createAddSavedPaymentMethodLanding().pipe(
            tap((response: AddSavedPaymentMethodLanding) => {
                this.setPropertiesFromResponse(response);
                this.initSelectedPayMethod();
                this.setupAddPaymentForm();
            }),
            switchMap(() => this.fetchErrorMessage()),
            take(1),
            finalize(() => {
                this.loadComplete = true;
                this.apiCallInProgress = false;
            })
        );
    }

    setPropertiesFromResponse(response: AddSavedPaymentMethodLanding): void {
        const { retrieveCustomerAgreement, internetRegistrationResponse, savedPaymentMethods } = response;
        const hasVULProductPolicy = this.managePaymentPrefsAdapter.hasVULProductPolicy(retrieveCustomerAgreement, this.session.billingAccountNumber);
        this.newPaymentMethodOptions = this.fetchNewPaymentMethods(hasVULProductPolicy);
        this.isEasyPayEligible = this.managePaymentPrefsAdapter.isEligibleForEasyPay(retrieveCustomerAgreement);
        this.isPaperlessEligible = this.managePaymentPrefsAdapter.isEligibleForPaperless(internetRegistrationResponse);
        this.savedPaymentMethods = savedPaymentMethods;
        this.emailAddress = this.managePaymentPrefsAdapter.fetchMostCurrentEmail(retrieveCustomerAgreement, internetRegistrationResponse);
        const address: AccountPolicyHolderAddressElements = this.managePaymentPrefsAdapter.addressFromInternetRegistrationResponse(internetRegistrationResponse);
        const billingStreet1 = address?.AcctPolHolderAddr1;
        const billingCity = address?.AcctPolHolderCity;
        const billingState = address?.AcctPolHolderState;
        this.savedPaymentPrefs = new SavedPaymentPrefs(this.isPaperlessEligible, this.isEasyPayEligible, this.emailAddress, this.newPaymentMethodOptions, billingStreet1, billingCity, billingState);
    }

    initSelectedPayMethod(): void {
        if (this.addPaymentFlow.selectedPaymentMethod && this.addPaymentFlow.selectedPaymentMethod.isBankCard) {
            this.chosenPaymentMethod = this.newPaymentMethodOptions[this.paymentOptionsIndex.bankCard];
        } else {
            this.chosenPaymentMethod = this.newPaymentMethodOptions[this.paymentOptionsIndex.bankAccount];
        }
    }

    setupAddPaymentForm(): void {
        if (this.addPaymentFlow.flowInPlay) {
            this.addPaymentForm = this.addPaymentFormBuilder.reconstructFormGroup({
                selectedPayMethod: this.selectedPaymentMethod,
                oldForm: this.addPaymentFlow.addPaymentForm.group,
                hasSavedPayments: this.hasSavedPaymentMethods
            });
        } else {
            this.addPaymentForm = this.addPaymentFormBuilder.newPaymentFormGroup({
                selectedPayMethod: this.selectedPaymentMethod,
                hasSavedPayments: this.hasSavedPaymentMethods
            });
        }

        this.listenToFormStatusChanges();
    }

    listenToFormStatusChanges(): void {
        this.addPaymentForm.group.statusChanges.subscribe((status) => {
            if (status === 'VALID') {
                this.formValidated = true;
            }
        });
    }

    replacePaymentMethodGroup(paymentMethod: PaymentItem): void {
        this.addPaymentForm.group.removeControl('paymentMethodGroup');
        this.addPaymentForm.group.addControl(
            'paymentMethodGroup',
            paymentMethod.isNewMethod ? this.paymentFormControls.newPaymentPreferencesPaymentMethodGroup(paymentMethod, {}, this.hasSavedPaymentMethods) :
                this.paymentFormControls.savedPaymentPreferencesPaymentMethodGroup(paymentMethod)
        );
    }

    setVariablesFromApiResponse(): void {
        this.nextSub = this.paymentPrefsApiService.createAddSavedPaymentMethodLanding().subscribe((response: AddSavedPaymentMethodLanding) => {
            this.setPropertiesFromResponse(response);
            this.initSelectedPayMethod();
            this.setupAddPaymentForm();
            this.loadComplete = true;
            this.apiCallInProgress = false;
        },
            (error) => {
                this.logger.error('API ERROR: ADD PAYMENT METHOD LANDING', { error });
            }
        );
    }

    currentRouteParams(): string {
        return this.router.url.split('?')[1];
    }

    onContinue(): void {
        this.formUtils.touchAllFormControls(this.addPaymentForm.group);
        this.sendOnContinueEbiEvent();
        this.formValidated = false;
        if (this.isEsddaValidationFailure()) {
            this.paperlessEsddaValidationError = this.isEsddaValidationFailure();
            if (this.paperlessEsddaValidationError) {
                this.logger.info('Add Payment Method Page - Failed page validation due to ESDDA after continue button is clicked', {
                    accountNumber: this.session.billingAccountNumber,
                    enterpriseCustomerNumber: this.session.ecn,
                    paperlessBillingDeliveryResponse: this.addPaymentForm.enrollInPaperless
                });
            }
        }
        if (this.addPaymentForm.group.valid && !this.paperlessEsddaValidationError) {
            this.saveFormToFlow();
            this.sendSaveAsRefundMethodEbiEvent();
            this.validateBankAccount();
        } else {
            this.window.scrollTo(0, 0);
        }
    }

    saveFormToFlow(): void {
        this.addPaymentFlow.save({
            addPaymentForm: this.addPaymentForm,
            considerThisDisplayable: this.considerThisDisplayable,
            selectedPaymentMethod: this.selectedPaymentMethod,
            isEasyPayEligible: this.isEasyPayEligible,
            isPaperlessEligible: this.isPaperlessEligible,
            isEligibleToSaveAsRefundMethod: this.canEnrollInRefund,
            selectedEasyPay: this.addPaymentForm.enrollInEasyPay,
            selectedPaperless: this.addPaymentForm.enrollInPaperless,
            emailAddress: this.savedPaymentPrefs.emailAddress,
            billingStreet1: this.savedPaymentPrefs.billingStreet1,
            billingCity: this.savedPaymentPrefs.billingCity,
            billingState: this.savedPaymentPrefs.billingState,
            billingAccountsForRefund: this.billingAccountsForRefund
        });
    }

    onEsddaCheckboxClick(value): void {
        this.esddaCheckBoxChecked = value;
        if (this.esddaCheckBoxChecked) {
            this.paperlessEsddaValidationError = false;
        }
    }

    isEsddaValidationFailure(): boolean {
        return this.addPaymentForm.enrollInPaperless === 'yes' && !this.esddaCheckBoxChecked;
    }

    sendSaveAsRefundMethodEbiEvent(): void {
        if (this.addPaymentForm.saveAsRefundMethodSelected === 'yes') {
            this.addPaymentForm.saveAsRefundMethodAccounts.forEach((billingAccountNumber) => {
                this.eventService.handleEvent(EBIEventType.ON_CLICK_CONTINUE_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD_LANDING_PAGE, {
                    IDENTIFIER: billingAccountNumber
                });
            });
        }
    }

    navigateToReview(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_PAYMENT_METHODS.ADD.REVIEW(
                this.urlUtil.hashParamsString
            )
        );
    }

    cancelAddPayment(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString)
        );
    }

    sendOnContinueEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.PP_ADD_PAYMENT_METHOD_LANDING_ON_CONTINUE, {
            paymentMethod: this.ebiPaymentMethodData
        });
    }

    fetchNewPaymentMethods(hasVul: boolean): PaymentItem[] {
        return this.paymentMethodsAdapter.fetchNewPaymentMethodOptions(
            {
                includeNewDebitCard: hasVul,
                includeNewBankCard: !hasVul,
                includeNewBankAccount: true,
                includePlaceholder: false,
                includeMasterpass: false
            });
    }

    fetchErrorMessage(): Observable<string> {
        let message = of(' ');
        if (this.displayCreditCardVulPolicyError) {
            message = this.fetchCreditCardVulErrorMessage();
        } else if (this.showAccountValidationMessage) {
            message = of(this.accountValidationMessage);
        }
        return message;
    }

    fetchCreditCardVulErrorMessage(): Observable<string> {
        const contentResponse = this.contentfulMapperservice.getContentfulBillingContent();
        return contentResponse.pipe(map((response) => response.creditCardVulPolicyError.content,
            (error) => this.throwError(error)
        ));
    }

    private throwError(error: any): void {
        this.logger.error('Billing : Add payment contentful Error', { error });
        this.router.navigateByUrl('/personal/error/system-down');
    }

    get ebiPaymentMethodData(): PaymentMethodsData[] {
        const data = [];
        const payMethod = this.selectedPaymentMethod;

        const accountType = payMethod.isBankAccount ? this.addPaymentForm.bankAccountType : 'NA';
        const bankName = payMethod.isBankAccount ? this.addPaymentForm.bankName : 'NA';
        const creditCardVendor = payMethod.isBankCard ? this.addPaymentForm.cardType : 'NA';
        const paymentType = payMethod.isBankCard ? 'CC' : 'EFT';
        const firstName = this.addPaymentForm.firstName || 'NA';
        const lastName = this.addPaymentForm.lastName || 'NA';
        const defaultMethod = this.addPaymentForm.makeDefaultPaymentMethod ? 'Yes' : 'No';
        const clientType = 'authenticated';
        const paperless = this.addPaymentForm.enrollInPaperless === 'yes';

        data.push({
            accountType,
            bankName,
            creditCardVendor,
            paymentType,
            firstName,
            lastName,
            defaultMethod,
            clientType,
            paperless
        });

        return data;
    }

    validateBankAccount(): void {
        if (this.selectedPaymentMethod?.isBankAccount && this.manageAccountValidationService.isEnabled) {
            this.apiCallInProgress = true;
            this.nextSub = this.manageAccountValidationService.invokeAccountValidationService({
                firstName: this.addPaymentForm.firstName,
                lastName: this.addPaymentForm.lastName,
                bankAccountNumber: this.addPaymentForm.bankAccountNumber,
                bankRoutingNumber: this.addPaymentForm.bankRoutingNumber,
                bankAccountType: <any> this.addPaymentForm.bankAccountType,
                isPlaidEnabled: false,
                isAuthenticated: true
            }).subscribe({
                next: (response) => {
                    this.apiCallInProgress = false;
                    if (response.accepted) {
                        this.navigateToReview();
                    } else {
                        if (response.failureLimitReached) {
                            this.newPaymentMethodOptions = this.newPaymentMethodOptions.filter((method) => !method.isNewBankAccount);
                            this.selectedPaymentMethod = this.newPaymentMethodOptions.find((method) => method.isNewBankCard);
                        }

                        if (!response.wrongAccountType) {
                            this.removeBankInfoFromForm();
                        }

                        this.accountValidationMessage = response.errorMessage;
                        this.window.scroll(0, 0);
                    }
                },
                error: (error) => {
                    this.logger.error('API ERROR: ADD PAYMENT METHOD VALIDATE BANK ACCT', error);
                }
            });
        } else {
            this.accountValidationMessage = null;
            this.navigateToReview();
        }
    }

    removeBankInfoFromForm(): void {
        [
            'paymentMethodGroup.accountNumberGroup.routingNumber',
            'paymentMethodGroup.accountNumberGroup.accountNumber',
            'paymentMethodGroup.accountNumberGroup.confirmAccountNumber',
            'paymentMethodGroup.accountNumberGroup.bankName'
        ].forEach((formControlPath) => {
            const control = this.addPaymentForm.group.get(formControlPath);
            control?.setValue('');
        });
    }

    getEmailAddress(): string {
        if (this.addPaymentForm.enrollInPaperless === 'no') {
            this.addPaymentFlow.paperlessEmail = this.emailAddress;
        }
        return this.addPaymentFlow.paperlessEmail || this.emailAddress;
    }

    get considerThisDisplayable(): boolean {
        return this.savedPaymentPrefs.isEasyPayEligible || this.savedPaymentPrefs.canSelectPaperlessBilling;
    }

    get hasSavedPaymentMethods(): boolean {
        return this.savedPaymentMethods && this.savedPaymentMethods.length > 0;
    }

    get selectedPaymentMethod(): PaymentItem {
        return this.chosenPaymentMethod;
    }

    set selectedPaymentMethod(paymentMethod: PaymentItem) {
        this.chosenPaymentMethod = paymentMethod;
        this.replacePaymentMethodGroup(paymentMethod);
    }

    get submitted(): boolean {
        return !!this.addPaymentFormDirective && this.addPaymentFormDirective.submitted;
    }

    get globalErrorDisplayable(): boolean {
        return !this.formValidated &&
            !this.addPaymentForm.group.valid &&
            this.submitted;
    }

    get canEnrollInRefund(): boolean {
        return this.selectedPaymentMethod?.isNewBankAccount && this.session.isNBP;
    }

    get displayCreditCardVulPolicyError(): boolean {
        return this.addPaymentFlow.hasCreditCardVulPolicyError;
    }
}
