import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { SessionService } from '../../../../../../shared/session/session.service';
import { Router } from '@angular/router';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { MAKE_A_PAYMENT } from '../../../../../../shared/constants/common.constants';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { SafeCurrencyPipe } from '../../../../../../pay-bill/shared/safe-currency-pipe';
import { environment } from '../../../../../../../environments/environment';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { ISBRouteParams } from '../../../../../../shared/routing/isb-route.model';
import { PLACEHOLDER_MESSAGES } from '../../../../shared/constants/cancel-automatic-payments-messages';
import { CancelAutomaticPaymentsFlowService } from '../shared/cancel-automatic-payments-flow.service';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { BillingCommonNavigationsService } from '../../../../../shared/billing-common-navigations.service';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

@Component({
    selector: 'app-cancel-automatic-payments-confirmation',
    templateUrl: './cancel-automatic-payments-confirmation.component.html',
    styleUrls: ['./cancel-automatic-payments-confirmation.component.scss']
})

export class CancelAutomaticPaymentsConfirmationComponent extends BasePageComponent implements OnInit, OnDestroy {
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.cancel.confirmation;
    amountDue: number;
    amountDueDate: string;
    billDueDate: Date;
    billDueAmount: number;
    isLifeOnlyAccount: boolean;
    formattedCurrentDate: string;
    apiFailureMessage: string;
    // eslint-disable-next-line max-params
    constructor(
        protected session: SessionService,
        protected router: Router,
        private easternTimeDatePipe: EasternTimeDatePipe,
        private flowService: CancelAutomaticPaymentsFlowService,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private safeCurrencyPipe: SafeCurrencyPipe,
        private eventService: BillingEventService,
        private urlUtil: UrlUtil,
        readonly contentfulService: ContentfulMapperService,
        @Inject('window') private window: Window,
        private navigationService: BillingCommonNavigationsService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        const rcaResponse = this.flowService.rcaResponse;
        this.billDueDate = this.managePaymentPrefsAdapter.fetchBillingAccountAmountDueDate(rcaResponse);
        this.billDueAmount = Number(this.managePaymentPrefsAdapter.fetchBillingAccountAmountDue(rcaResponse));
        this.isLifeOnlyAccount = this.managePaymentPrefsAdapter.checkLifeOnlyAccount(rcaResponse);
        this.setFormattedCurrentDate();
        this.setVariablesFromApiResponse();
        this.sendCancelEasyPaySuccessCtmEvent();
        this.sendOnPageLoadEbiEvent();
        this.fetchApiFailureMessage();
    }

    ngOnDestroy(): void {
        this.flowService.clear();
    }

    setVariablesFromApiResponse(): void {
        const dateFormat = 'MM/dd/yyyy';
        this.amountDue = this.safeCurrencyPipe.transformToCurrency(this.billDueAmount);
        this.amountDueDate = this.easternTimeDatePipe.transform(this.billDueDate, dateFormat);
    }

    sendCancelEasyPaySuccessCtmEvent(): void {
        this.eventService.handleCtmEvent(
            CtmEventType.CANCEL_EASY_PAY_SUCCESSFUL, {
            productType: CtmProductType.BILLING
        });
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_CONFIRMATION_PAGE, {
            responseCode: this.flowService.apiResponseCode,
            responseStatus: this.flowService.apiCallSuccessful ? 'SUCCESS' : 'ERROR',
            paymentPlan: this.flowService.paymentPlan
        });
    }

    private fetchApiFailureMessage(): void {
        const contentfulResponse = this.contentfulService.getContentfulBillingContent();
        contentfulResponse.subscribe((res) => {
            this.apiFailureMessage = res.apiFailure.content;
        });
    }

    get apiCallSuccessful(): boolean {
        return this.flowService.apiCallSuccessful;
    }

    get billingAccountNumber(): string {
        return this.session.billingAccountNumber;
    }

    get confirmationMessage(): string {
        return PLACEHOLDER_MESSAGES.CONFIRMATION_SUCESS_MESSAGE;
    }

    get enrollMessage(): string {
        return PLACEHOLDER_MESSAGES.ENROLL_MESSAGE;
    }

    get confirmationDetail(): string {
        return `${PLACEHOLDER_MESSAGES.CONFIRMATION_DETAIL[0]} ${this.formattedCurrentDate} ${PLACEHOLDER_MESSAGES.CONFIRMATION_DETAIL[1]} ${this.billingAccountNumber}.`;
    }

    get outstandingdueMessage(): string {
        return `${PLACEHOLDER_MESSAGES.OUTSTANDING_PAYMENT_DUE[0]} ${this.amountDue} ${PLACEHOLDER_MESSAGES.OUTSTANDING_PAYMENT_DUE[1]} ${this.amountDueDate}. ${PLACEHOLDER_MESSAGES.OUTSTANDING_PAYMENT_DUE[2]}`;
    }

    get dueAmount(): number {
        return this.amountDue;
    }

    get amountDueByDate(): string {
        return this.amountDueDate;
    }

    get upNextLabel(): string {
        return this.showNextLabel ? MAKE_A_PAYMENT : '';
    }

    get showNextLabel(): boolean {
        return this.amountDueExists && this.apiCallSuccessful;
    }

    setFormattedCurrentDate(): void {
        const dateFormat = 'MM/dd/yyyy';
        const timeFormat = 'hh:mm aa';
        const date = this.easternTimeDatePipe.transform(new Date(), dateFormat);
        const time = this.easternTimeDatePipe.transform(new Date(), timeFormat);
        this.formattedCurrentDate = `${date}, ${time} ET`;
    }

    get secondaryButton(): ButtonMetaData {
        const buttonMetaData: ButtonMetaData = {
            label: 'Payment preferences',
            navigation: environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString)
        };
        return buttonMetaData;
    }

    get amountDueExists(): boolean {
        return this.billDueAmount > 0;
    }

    showPrintDialog(): void {
        this.window.print();
        this.sendPrintButtonEbiEvent();
    }

    secondayNavigation(metaData: ButtonMetaData): void {
        this.router.navigateByUrl(metaData.navigation);
    }

    onContinue(): void {
        if (this.amountDueExists) {
            this.navigateToMakeAPayment();
        } else {
            this.navigateToPaymentPreferences();
        }
    }

    navigateToMakeAPayment(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.RELATIVE.payBillLanding(this.urlParams));
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString));
    }

    navigateToAddRefundMethod(): void {
        this.navigationService.navigateToAddRefundMethodLanding();
    }

    sendPrintButtonEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_PRINT_BTN_CANCEL_EASY_PAY_CONFIRMATION_PAGE, {
            paymentPlan: this.flowService.paymentPlan
        });
    }

    get urlParams(): ISBRouteParams {
        return {
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId,
            policyNumber: this.session.policyNumber,
            referringPage: 'review'
        };
    }
}

export interface ButtonMetaData {
    label: string;
    navigation: string;
}
