<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<div class="manage-payment-form-container">
    <div class="bolt-container bolt-space-bottom-2xl">

        <bw-bolt-progress-bar appFocusOnInit arialabel="Edit payment method progress value is 100%" progressBarValue="100" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Edit payment method:
            confirmation</h1>

        <ng-container *ngIf="apiCallSuccessful">
            <bw-bolt-notification class="to-container bolt-space-bottom-2xl" type="success" id="error-notification">
                Your request has been processed.
            </bw-bolt-notification>
            <app-payment-sub-header>Confirmation details</app-payment-sub-header>
            <app-saved-payment-confimation-details [saveVerb]="saveVerb" [paymentMethod]="paymentMethodConfirmationDetail" [wasEsddaEnrollmentSuccessful]="wasEsddaEnrollmentSuccessful">
            </app-saved-payment-confimation-details>

            <ng-container *ngIf="wasEsddaEnrollmentSuccessful">
                <div class="bolt-row" [ngClass]="{'bolt-space-bottom-xl': emailAddressUpdateAttempted}">

                    <div class="bolt-col">
                        <strong>Paperless enrollment change effective date:</strong>
                    </div>
                    <div class="bolt-col">
                        {{formattedCurrentDate}}
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!apiCallSuccessful">
            <bw-bolt-notification class="to-container bolt-space-bottom-2xl" type="error">
                {{ apiServiceDownMessage }}
            </bw-bolt-notification>
        </ng-container>
        <ng-container *ngIf="isPaperlessEnrollmentUnsuccessful">
            <bw-bolt-notification type="error" class="to-container bolt-space-bottom-2xl">
                {{ errorMessage }}
            </bw-bolt-notification>
        </ng-container>
    </div>
    <div *ngIf='upNextLabel'>
        <bolt-button-bar>
            <bolt-button id="back-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
            <bolt-button id="submit-button" slot="forward" (click)="onContinue()" type="primary">
                {{ upNextLabel }}
            </bolt-button>
        </bolt-button-bar>
    </div>
</div>