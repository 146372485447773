
/* eslint-disable no-negated-condition */
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BankAccountPaymentItem } from '../../billing/payment-preferences/shared/models/bankaccount-item.model';
import { BankCardPaymentItem } from '../../billing/payment-preferences/shared/models/bankcard-item.model';
import { WalletPayPaymentItem } from '../../billing/payment-preferences/shared/models/masterpass-item.model';
import { PaymentItem } from '../../billing/payment-preferences/shared/models/payment-item.model';
import { PaymentPrefencesBankCardType } from '../../billing/payment-preferences/shared/models/payment-prefences-bank-card';
import { PayBill } from '../../pay-bill/pay-bill-landing/pay-bill-landing-adpater.model';
import {
    ControlNames as PayAmountControls,
    OptionalControlNames as PayAmountOptionalControls,
    PaymentAmountOption
} from '../../pay-bill/shared/payment-amount-form-controls/payment-amount.component';
import { ControlNames as PayDateControls } from '../../pay-bill/shared/payment-date-form-controls/payment-date-form-controls.component';
import { QuickPayValidators as DobValidators } from '../../pay-bill/shared/validations/quick-pay-validators';
import { ControlNames as WalletPayControls } from '../../pay-bill/shared/wallet-pay-form-controls/wallet-pay-form-controls.component';
import { ControlNames as AddressControls } from './address-form-control/address-form-control.component';
import { ControlNames as BankAccountInfoControls } from './bank-account-info-form-controls/bank-account-info-form-controls.component';
import { ControlNames as AccountNumberControls } from './bank-account-number-form-controls/bank-account-number-form-controls.component';
import { ControlNames as BankAccountControls } from './bank-account-plaid-form-controls/bank-account-plaid-form-controls.component';
import { ControlNames as AccountTypeControls } from './bank-account-type-form-control/bank-account-type-form-control.component';
import { ControlNames as CardExpControls } from './bank-card-expiration-form-control/bank-card-expiration-form-control.component';
import { ControlNames as BankCardControls } from './bank-card-form-controls/bank-card-form-controls.component';
import { ControlNames as CardNumberControls } from './bank-card-number-input/bank-card-number-input.component';
import { ControlNames as ConsiderThisControls } from './consider-this/consider-this.component';
import { ControlNames as SaveAsRefundMethodControls } from './consider-this/save-as-refund-method/save-as-refund-method.component';
import { ControlNames as CvvControls } from './cvv-form-control/cvv-form-control.component';
import { ControlNames as EmailControls } from './email-form-control/email-form-control.component';
import { ControlNames as EzPayAuthControls } from './ez-pay-authorization/ez-pay-authorization.component';
import { ControlNames as NameControls } from './name-field-set/name-field-set.component';
import { PayBillValidators } from './validations/payment-validations';
import { ControlNames as ZipControls } from './zip-code-form-control/zip-code-form-control.component';

@Injectable()
export class PaymentFormControls {
    constructor(
        private formBuilder: UntypedFormBuilder,
        private datePipe: DatePipe
    ) { }

    newPaymentMethodGroup(
        selectedPayMethod: PaymentItem,
        oldValues: ControlNames<BankAccountControls> | ControlNames<BankCardControls> | ControlNames<WalletPayControls> = {},
        hasSavedpayments: boolean
    ): AbstractControl {
        if (selectedPayMethod.isBankAccount) {
            return this.newPlaidBankAccountFormGroup(<BankAccountPaymentItem>selectedPayMethod.item, oldValues, hasSavedpayments);
        } else if (selectedPayMethod.isBankCard) {
            return this.newBankCardFormGroup(<BankCardPaymentItem>selectedPayMethod.item, oldValues, hasSavedpayments);
        } else if (selectedPayMethod.isMasterPass) {
            return this.newMasterPassFormGroup(<WalletPayPaymentItem>selectedPayMethod.item);
        }
    }

    // eslint-disable-next-line max-params
    newPaymentPreferencesPaymentMethodGroup(
        selectedPayMethod: PaymentItem,
        oldValues: ControlNames<BankAccountControls> | ControlNames<BankCardControls> | ControlNames<WalletPayControls> = {},
        hasSavedPayments: boolean,
        isEasyPay?: boolean
    ): AbstractControl {
        if (selectedPayMethod.isBankAccount && isEasyPay) {
            return this.newEasyPayBankAccountFormGroup(<BankAccountPaymentItem>selectedPayMethod.item, oldValues, hasSavedPayments);
        } else if (selectedPayMethod.isBankAccount) {
            return this.newBankAccountFormGroup(<BankAccountPaymentItem>selectedPayMethod.item, oldValues, hasSavedPayments);
        } else if (selectedPayMethod.isBankCard) {
            return this.newPaymentPrefencesBankCardFormGroup({ paymentMethod: <BankCardPaymentItem>selectedPayMethod.item, oldValues, hasSavedPayments, isNewMethod: selectedPayMethod.isNewMethod });
        }
    }

    savedPaymentPreferencesPaymentMethodGroup(
        selectedPayMethod: PaymentItem,
        oldValues: ControlNames<BankAccountControls> | ControlNames<BankCardControls> = {}
    ): AbstractControl {
        return selectedPayMethod.isBankAccount ?
            this.savedBankAccountFormGroup(<BankAccountPaymentItem>selectedPayMethod.item, oldValues, selectedPayMethod.isDefault) :
            this.savedPaymentPreferencesBankCardFormGroup(<BankCardPaymentItem>selectedPayMethod.item, oldValues, selectedPayMethod.isDefault);
    }

    savedPaymentMethodGroup(
        selectedPayMethod: PaymentItem,
        oldValues: ControlNames<BankAccountControls> | ControlNames<BankCardControls> = {}
    ): AbstractControl {
        return selectedPayMethod.isBankAccount ?
            this.savedBankAccountFormGroup(<BankAccountPaymentItem>selectedPayMethod.item, oldValues, selectedPayMethod.isDefault) :
            this.savedBankCardFormGroup(<BankCardPaymentItem>selectedPayMethod.item, oldValues, selectedPayMethod.isDefault);
    }

    newEzPayAuthGroup(): AbstractControl {
        const groupParams: ControlNames<EzPayAuthControls> = {
            acknowledged: this.formBuilder.control('', [Validators.requiredTrue])
        };

        return this.formBuilder.group(groupParams);
    }

    newEmailFormGroup(emailAddress: string): AbstractControl {
        const groupParams: ControlNames<EmailControls> = {
            emailAddress: this.formBuilder.control(emailAddress, [
                Validators.required,
                PayBillValidators.isValidEmail
            ])
        };

        return this.formBuilder.group(groupParams);
    }

    newPaymentAmountFormGroup(
        amountOptions: PaymentAmountOption[],
        defaultPaymentAmount: PaymentAmountOption,
        oldValues: ControlNames<PayAmountControls, PayAmountOptionalControls> = {}
    ): AbstractControl {
        const groupParams: ControlNames<PayAmountControls, PayAmountOptionalControls> = {
            paymentType: this.formBuilder.control(oldValues.paymentType || defaultPaymentAmount?.id, [
            ]),
            paymentAmount: this.formBuilder.control(oldValues.paymentAmount || defaultPaymentAmount?.amount, [
                Validators.required
            ])
        };
        const maxPayOption = amountOptions.filter((option) => option.id === 'full-payment').length ?
            amountOptions.filter((option) => option.id === 'full-payment')[0] : undefined ||
            amountOptions.filter((option) => option.id === 'minimum-payment')[0] ||
            amountOptions.filter((option) => option.id === 'policy-change-payment')[0];
        let maxPayOptionAmount = PayBill.ZERO;
        if (maxPayOption) {
            maxPayOptionAmount = Number(maxPayOption.amount);
            const partialOptions = (amountOptions || []).filter((option: PaymentAmountOption) => option.id === 'partial-payment');
            if (partialOptions.length > 0) {
                groupParams.partialPaymentAmount = this.formBuilder.control(oldValues.partialPaymentAmount ||
                    (defaultPaymentAmount.id === 'partial-payment' ? defaultPaymentAmount.amount : ''), [
                    Validators.required,
                    PayBillValidators.isNumberWithTwoDecimalPlaces,
                    PayBillValidators.isBetweenMinAndMax(maxPayOptionAmount, Number(partialOptions[0].amount) || 1)
                ]);

                groupParams.overPayAllocationType = this.formBuilder.control(oldValues.overPayAllocationType !== null ?
                    oldValues.overPayAllocationType : '', [

                ]);
            }
            return this.formBuilder.group(groupParams, {
                validator: (group: UntypedFormGroup) => {
                    const paymentType = group.get('paymentType');
                    if (paymentType.value !== 'partial-payment') {
                        const partialPaymentAmount = group.get('partialPaymentAmount');
                        if (partialPaymentAmount) {
                            partialPaymentAmount.setErrors(null);
                        }
                        const overpayAllocationType = group.get('overPayAllocationType');
                        if (overpayAllocationType) {
                            overpayAllocationType.setErrors(null);
                        }
                    }
                }
            });
        }
    }

    newPaymentDateFormGroup(oldValues: ControlNames<PayDateControls> = {}): AbstractControl {
        const groupParams: ControlNames<PayDateControls> = {
            paymentDate: this.formBuilder.control(
                oldValues.paymentDate || this.currentDate,
                [
                    Validators.required,
                    DobValidators.checkDobValidity,
                    DobValidators.checkDOBFormat
                ]
            )
        };

        return this.formBuilder.group(groupParams);
    }

    newConsiderThisFormGroup(oldValues: ControlNames<ConsiderThisControls> = {}): AbstractControl {
        const groupParams = {
            enrollInPaperless: this.formBuilder.control(oldValues.enrollInPaperless || ''),
            enrollInEasyPay: this.formBuilder.control(oldValues.enrollInEasyPay || ''),
            enrollInChangeDueDate: this.formBuilder.control(oldValues.enrollInChangeDueDate || 'no'),
            saveAsRefundMethodGroup: this.newSaveAsRefundMethodFormGroup(oldValues.saveAsRefundMethodGroup),
            emailAddressGroup: this.considerThisEmailFormGroup(oldValues.emailAddressGroup || '')
        };
        return this.formBuilder.group(groupParams);
    }

    newMAPConsiderThisFormGroup(oldValues: ControlNames<ConsiderThisControls> = {}): AbstractControl {
        const groupParams = {
            enrollInPaperless: this.formBuilder.control(oldValues.enrollInPaperless || ''),
            enrollInEasyPay: this.formBuilder.control(oldValues.enrollInEasyPay || ''), // Validators set in consider-this.component if necessary
            enrollInChangeDueDate: this.formBuilder.control(oldValues.enrollInChangeDueDate || 'no'),
            savePaymentMethod: this.formBuilder.control(oldValues.savePaymentMethod || '', [Validators.required]),
            saveAsRefundMethodGroup: this.newSaveAsRefundMethodFormGroup(oldValues.saveAsRefundMethodGroup),
            futureAutoPayment: this.formBuilder.control(oldValues.futureAutoPayment || ''),
            emailAddressGroup: this.considerThisEmailFormGroup(oldValues.emailAddressGroup || '')
        };
        return this.formBuilder.group(groupParams);
    }

    newAreYouSureFormGroup(): AbstractControl {
        return this.formBuilder.group({
            selection: this.formBuilder.control('', Validators.required)
        });
    }

    newMasterPassFormGroup(
        paymentMethod: WalletPayPaymentItem
    ): AbstractControl {
        return this.formBuilder.group({
            TokenGroup: this.formBuilder.control(paymentMethod.oAuthToken, Validators.required)
        });
    }

    newBankCardFormGroup(
        paymentMethod: BankCardPaymentItem,
        oldValues: ControlNames<BankCardControls> = {},
        hasSavedpayments: boolean
    ): AbstractControl {
        return this.formBuilder.group({
            nameGroup: this.newNameFormGroup(
                { first: paymentMethod.firstName, middle: paymentMethod.middleInitial, last: paymentMethod.lastName, oldValues: oldValues.nameGroup }),
            cvvGroup: this.newCvvGroup(),
            cardExpirationGroup: this.newCardExpirationGroup(paymentMethod, oldValues.cardExpirationGroup),
            bankCardFormGroup: this.newCardNumberFormGroup(paymentMethod),
            zipGroup: this.newZipFormGroup(paymentMethod.zipCode, oldValues.zipGroup),
            savePaymentMethod: oldValues.savePaymentMethod ? this.formBuilder.control(true) : this.formBuilder.control(!hasSavedpayments),
            makeDefaultPaymentMethod: oldValues.makeDefaultPaymentMethod ? this.formBuilder.control(true) :
                this.formBuilder.control(false)
        });
    }

    newPaymentPrefencesBankCardFormGroup(
        paymentPrefencesBankCardType: PaymentPrefencesBankCardType
    ): AbstractControl {
        return this.formBuilder.group({
            nameGroup: this.newNameFormGroup(
                { first: paymentPrefencesBankCardType.paymentMethod.firstName, middle: paymentPrefencesBankCardType.paymentMethod.middleInitial, last: paymentPrefencesBankCardType.paymentMethod.lastName, oldValues: paymentPrefencesBankCardType.oldValues.nameGroup }),
            cardExpirationGroup: this.newCardExpirationGroup(paymentPrefencesBankCardType.paymentMethod, paymentPrefencesBankCardType.oldValues.cardExpirationGroup),
            bankCardFormGroup: paymentPrefencesBankCardType.isNewMethod ? this.newCardNumberFormGroup(paymentPrefencesBankCardType.paymentMethod) : this.savedCardNumberFormGroup(paymentPrefencesBankCardType.paymentMethod),
            zipGroup: this.newZipFormGroup(paymentPrefencesBankCardType.paymentMethod.zipCode, paymentPrefencesBankCardType.oldValues.zipGroup),
            savePaymentMethod: paymentPrefencesBankCardType.oldValues.savePaymentMethod ? this.formBuilder.control(true) : this.formBuilder.control(!paymentPrefencesBankCardType.hasSavedPayments),
            makeDefaultPaymentMethod: paymentPrefencesBankCardType.oldValues.makeDefaultPaymentMethod ? this.formBuilder.control(true) :
                paymentPrefencesBankCardType.hasSavedPayments ? this.formBuilder.control(false) : this.formBuilder.control(true)
        });
    }

    savedPaymentPreferencesBankCardFormGroup(
        paymentMethod: BankCardPaymentItem,
        oldValues: ControlNames<BankCardControls> = {},
        isDefaultPaymentMethod: boolean
    ): AbstractControl {
        return this.formBuilder.group({
            nameGroup: this.newNameFormGroup(
                { first: paymentMethod.firstName, middle: paymentMethod.middleInitial, last: paymentMethod.lastName, oldValues: oldValues.nameGroup }),
            cardExpirationGroup: this.newCardExpirationGroup(paymentMethod, oldValues.cardExpirationGroup),
            bankCardFormGroup: this.savedCardNumberFormGroup(paymentMethod),
            zipGroup: this.newZipFormGroup(paymentMethod.zipCode, oldValues.zipGroup),
            makeDefaultPaymentMethod: oldValues.makeDefaultPaymentMethod ? this.formBuilder.control(true) :
                isDefaultPaymentMethod ? this.formBuilder.control(true) : this.formBuilder.control(false)
        });
    }

    savedBankCardFormGroup(
        paymentMethod: BankCardPaymentItem,
        oldValues: ControlNames<BankCardControls> = {},
        isDefaultPaymentMethod: boolean
    ): AbstractControl {
        return this.formBuilder.group({
            nameGroup: this.newNameFormGroup(
                { first: paymentMethod.firstName, middle: paymentMethod.middleInitial, last: paymentMethod.lastName, oldValues: oldValues.nameGroup }),
            cvvGroup: this.newCvvGroup(),
            cardExpirationGroup: this.newCardExpirationGroup(paymentMethod, oldValues.cardExpirationGroup),
            bankCardFormGroup: this.savedCardNumberFormGroup(paymentMethod),
            zipGroup: this.newZipFormGroup(paymentMethod.zipCode, oldValues.zipGroup),
            makeDefaultPaymentMethod: oldValues.makeDefaultPaymentMethod ? this.formBuilder.control(true) :
                isDefaultPaymentMethod ? this.formBuilder.control(true) : this.formBuilder.control(false)
        });
    }

    newCardNumberFormGroup(payMethod: BankCardPaymentItem): AbstractControl {
        const groupParams: ControlNames<CardNumberControls> = {
            bankCardNumber: this.formBuilder.control(
                payMethod.maskedCardNumber, [
                Validators.required,
                PayBillValidators.digitsOnly,
                PayBillValidators.hasIdentifiableCardType
            ])
        };

        return this.formBuilder.group(groupParams);
    }

    savedCardNumberFormGroup(payMethod: BankCardPaymentItem): AbstractControl {
        const groupParams: ControlNames<CardNumberControls> = {
            bankCardNumber: this.formBuilder.control(payMethod.maskedCardNumber)
        };

        return this.formBuilder.group(groupParams);
    }

    newCvvGroup(): AbstractControl {
        const groupParams: ControlNames<CvvControls> = {
            cvv: this.formBuilder.control('', [
                Validators.required,
                PayBillValidators.digitsOnly
            ])
        };

        return this.formBuilder.group(groupParams);
    }

    newCardExpirationGroup(payMethod: BankCardPaymentItem, oldVals: ControlNames<CardExpControls> = {}): AbstractControl {
        const groupParams: ControlNames<CardExpControls> = {
            cardExpirationMonth: this.formBuilder.control(
                oldVals.cardExpirationMonth || payMethod.expirationMonth,
                [Validators.required]
            ),
            cardExpirationYear: this.formBuilder.control(
                oldVals.cardExpirationYear || payMethod.expirationYear,
                [Validators.required]
            )
        };

        return this.formBuilder.group(groupParams, {
            validator: (group: UntypedFormGroup) => {
                const monthControl = group.get('cardExpirationMonth');
                const yearControl = group.get('cardExpirationYear');
                if (monthControl.value !== '' && yearControl.value !== '') {
                    const exMonth = monthControl.value;
                    const exYear = yearControl.value;
                    const today = new Date();
                    const someday = new Date();
                    someday.setFullYear(exYear, exMonth - 1);

                    if (someday < today) {
                        monthControl.setErrors({
                            monthInPast: true
                        });
                        yearControl.setErrors({
                            yearInPast: true
                        });
                    } else {
                        monthControl.setErrors(null);
                        yearControl.setErrors(null);
                    }
                }
            }
        });
    }

    newEasyPayBankAccountFormGroup(
        paymentMethod: BankAccountPaymentItem,
        oldValues: ControlNames<BankAccountControls> = {},
        hasSavedpayments: boolean
    ): AbstractControl {
        return this.formBuilder.group({
            accountTypeGroup: this.newAccountTypeGroup(paymentMethod, oldValues.accountTypeGroup),
            accountNumberGroup: this.newAccountNumberGroup(paymentMethod, oldValues.accountNumberGroup),
            savePaymentMethod: oldValues.savePaymentMethod ? this.formBuilder.control(true) : this.formBuilder.control(!hasSavedpayments),
            makeDefaultPaymentMethod: oldValues.makeDefaultPaymentMethod ? this.formBuilder.control(true) :
                hasSavedpayments ? this.formBuilder.control(false) : this.formBuilder.control(true)
        });
    }

    newBankAccountFormGroup(
        paymentMethod: BankAccountPaymentItem,
        oldValues: ControlNames<BankAccountControls> = {},
        hasSavedpayments: boolean
    ): AbstractControl {
        return this.formBuilder.group({
            accountTypeGroup: this.newAccountTypeGroup(paymentMethod, oldValues.accountTypeGroup),
            nameGroup: this.newNameFormGroup(
                { first: paymentMethod.firstName, middle: paymentMethod.middleInitial, last: paymentMethod.lastName, oldValues: oldValues.nameGroup }),

            addressGroup: this.newAddressFormGroup(paymentMethod, oldValues.addressGroup),

            accountNumberGroup: this.newAccountNumberGroup(paymentMethod, oldValues.accountNumberGroup),
            savePaymentMethod: oldValues.savePaymentMethod ? this.formBuilder.control(true) : this.formBuilder.control(!hasSavedpayments),
            makeDefaultPaymentMethod: oldValues.makeDefaultPaymentMethod ? this.formBuilder.control(true) :
                this.formBuilder.control(false)
        });
    }

    newPlaidBankAccountFormGroup(
        paymentMethod: BankAccountPaymentItem,
        oldValues: ControlNames<BankAccountControls> = {},
        hasSavedpayments: boolean
    ): AbstractControl {
        return this.formBuilder.group({

            nameGroup: this.newNameFormGroup(
                { first: paymentMethod.firstName, middle: paymentMethod.middleInitial, last: paymentMethod.lastName, oldValues: oldValues.nameGroup }),

            addressGroup: this.newAddressFormGroup(paymentMethod, oldValues.addressGroup),

            bankAccountInfoGroup: this.newPlaidBankAccountTypeFormGroup(paymentMethod, oldValues.bankAccountInfoGroup),

            savePaymentMethod: oldValues.savePaymentMethod ? this.formBuilder.control(true) : this.formBuilder.control(!hasSavedpayments),
            makeDefaultPaymentMethod: oldValues.makeDefaultPaymentMethod ? this.formBuilder.control(true) :
                this.formBuilder.control(false)
        });
    }

    savedBankAccountFormGroup(
        paymentMethod: BankAccountPaymentItem,
        oldValues: ControlNames<BankAccountControls> = {},
        isDefaultPaymentMethod: boolean
    ): AbstractControl {
        return this.formBuilder.group({
            accountTypeGroup: this.newAccountTypeGroup(paymentMethod, oldValues.accountTypeGroup),

            nameGroup: this.newNameFormGroup(
                { first: paymentMethod.firstName, middle: paymentMethod.middleInitial, last: paymentMethod.lastName, oldValues: oldValues.nameGroup }),

            addressGroup: this.newAddressFormGroup(paymentMethod, oldValues.addressGroup),

            accountNumberGroup: this.newAccountNumberGroup(paymentMethod, oldValues.accountNumberGroup),
            makeDefaultPaymentMethod: oldValues.makeDefaultPaymentMethod ? this.formBuilder.control(true) :
                isDefaultPaymentMethod ? this.formBuilder.control(true) : this.formBuilder.control(false)
        });
    }

    newAccountNumberGroup(
        payMethod: BankAccountPaymentItem,
        oldValues: ControlNames<AccountNumberControls> = {}
    ): AbstractControl {
        const MIN_ROUTING_LENGTH = 9;
        const MIN_ACCOUNT_LENGTH = 3;
        const groupParams: ControlNames<AccountNumberControls> = {
            bankName: this.formBuilder.control(payMethod.bankName || oldValues.bankName),
            routingNumber: this.formBuilder.control(oldValues.routingNumber || payMethod.routingNumber, [
                Validators.required,
                Validators.minLength(MIN_ROUTING_LENGTH),
                PayBillValidators.digitsOnly
            ]),
            accountNumber: this.formBuilder.control(payMethod.maskedBankAccountNumber, [
                Validators.required,
                Validators.minLength(MIN_ACCOUNT_LENGTH),
                PayBillValidators.digitsOnly
            ]),
            confirmAccountNumber: this.formBuilder.control(payMethod.maskedBankAccountNumber, [
                Validators.required,
                Validators.minLength(MIN_ACCOUNT_LENGTH),
                PayBillValidators.digitsOnly
            ])
        };

        return this.formBuilder.group(groupParams, {
            validator: PayBillValidators.valuesMatch('accountNumber', 'confirmAccountNumber')
        });
    }

    newAccountTypeGroup(
        paymentMethod: BankAccountPaymentItem,
        oldValues: ControlNames<AccountTypeControls> = {}
    ): AbstractControl {
        return this.formBuilder.group({
            accountType: this.formBuilder.control(
                oldValues.accountType || paymentMethod.accountType,
                [Validators.required]
            )
        });
    }

    newAddressFormGroup(paymentMethod: BankAccountPaymentItem, oldValues: ControlNames<AddressControls> = {}): AbstractControl {
        const groupParams: ControlNames<AddressControls> = {
            street: this.formBuilder.control(oldValues.street || paymentMethod.street, [
                Validators.required,
                PayBillValidators.containsNonSpaceCharacters,
                PayBillValidators.onlyStreetCharacters
            ]),
            apartmentNumber: this.formBuilder.control(oldValues.apartmentNumber || paymentMethod.apartmentNumber, [
                PayBillValidators.onlyDigitsAndNameCharacters
            ]),
            city: this.formBuilder.control(oldValues.city || paymentMethod.city, [
                Validators.required,
                PayBillValidators.containsNonSpaceCharacters,
                PayBillValidators.hasNameCharacters
            ]),
            state: this.formBuilder.control(oldValues.state || paymentMethod.state, [
                Validators.required
            ]),
            zipGroup: this.newZipFormGroup(paymentMethod.zip, oldValues.zipGroup)
        };

        return this.formBuilder.group(groupParams);
    }

    newPlaidBankAccountTypeFormGroup(paymentMethod: BankAccountPaymentItem,
        oldValues: ControlNames<BankAccountInfoControls> = {}
    ): AbstractControl {
        return this.formBuilder.group({
            bankAccountInfo: this.formBuilder.control(
                oldValues.bankAccountInfo,
                [Validators.required]
            ),
            accountNumberGroup: this.newAccountNumberGroup(paymentMethod, oldValues.accountNumberGroup),
            accountTypeGroup: this.newAccountTypeGroup(paymentMethod, oldValues.accountTypeGroup)
        });
    }

    newNameFormGroup({ first, middle, last, oldValues = {} }: { first: string; middle: string; last: string; oldValues?: ControlNames<NameControls> }): AbstractControl {
        const groupParams: ControlNames<NameControls> = {
            firstName: this.formBuilder.control(oldValues.firstName || first, [
                Validators.required,
                PayBillValidators.startsWithNameCharacter,
                PayBillValidators.hasNameCharacters
            ]),
            middleInitial: this.formBuilder.control(oldValues.middleInitial || middle, [
                Validators.maxLength(1),
                PayBillValidators.startsWithAlphabetCharacter
            ]),
            lastName: this.formBuilder.control(oldValues.lastName || last, [
                Validators.required,
                Validators.minLength(2),
                PayBillValidators.startsWithNameCharacter,
                PayBillValidators.hasNameCharacters
            ])
        };

        return this.formBuilder.group(groupParams);
    }

    newZipFormGroup(zip: string, oldValues: ControlNames<ZipControls> = {}): AbstractControl {
        const MIN_ZIP_LENGTH = 5;
        const groupParams: ControlNames<ZipControls> = {
            zip: this.formBuilder.control(oldValues.zip || zip, [
                Validators.required,
                Validators.minLength(MIN_ZIP_LENGTH),
                PayBillValidators.digitsOnly
            ])
        };

        return this.formBuilder.group(groupParams);
    }

    get currentDate(): string {
        const currentDate = new Date();
        const dateFormatter = this.datePipe.transform(currentDate, 'MM/dd/yyy');
        return dateFormatter;
    }

    private newSaveAsRefundMethodFormGroup(oldValues: ControlNames<SaveAsRefundMethodControls> = {}): AbstractControl {
        return this.formBuilder.group({
            selection: this.formBuilder.control(oldValues.selection || ''),
            selectedAgreements: this.formBuilder.array(oldValues.selectedAgreements || [])
        }, { validators: [PayBillValidators.saveAsRefundMethodFormGroupValidation] });
    }

    private considerThisEmailFormGroup(oldValues: ControlNames<EmailControls> = {}): AbstractControl {
        return this.formBuilder.group({ emailAddress: this.formBuilder.control(oldValues.emailAddress || '') });
    }
}

export type ControlNames<Names extends string, OptionalNames extends string = null> =
    { [name in Names]?: any } & { [name in OptionalNames]?: any };
