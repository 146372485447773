import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { PaymentFlowType } from '../../pay-bill/shared/payment-flow-type.service';
import { SessionService } from '../session/session.service';
declare const QSI: any;

@Injectable()
export class AbandonmentSurvey {
    constructor(
        private logger: LoggerService,
        private paymentFlowType: PaymentFlowType,
        private session: SessionService
    ) { }

    triggerSurvey(): void {
        try {
            if (this.paymentFlowType.isQuickpay) {
                this.session.surveyKey = 'Quick Pay Survey';
            } else {
                this.session.surveyKey = 'Post Auth Payment Survey';
            }
            if (QSI?.API) {
                QSI.API.unload();
                QSI.API.load();
                QSI.API.run();
                this.logger.info('Abandonment survey triggered');
                const valueToKeep = this.session.surveyKey;
                if (this.paymentFlowType.isQuickpay) {
                    this.session.clear();
                } else {
                    sessionStorage.removeItem('QSI_History');
                    sessionStorage.removeItem('QSI_ActionSetHistory');
                    sessionStorage.removeItem('QSI_HistorySession');
                }
                if (valueToKeep != null) {
                    this.session.surveyKey = valueToKeep;
                    sessionStorage.setItem('W2_SurveyFlow', valueToKeep);
                }
            }
        } catch (error) {
            this.logger.error('Error triggering abandonment survey', error);
        }
    }
}
