<ng-container [formGroup]="formGroup" *ngIf="formGroup">
    <div class="bolt-space-bottom-2xl">
        <app-bank-card-number-input formGroupName="bankCardFormGroup" (cardTypeSelection)="selectedCardType = $event">
        </app-bank-card-number-input>

        <app-bank-card-expiration-form-control formGroupName="cardExpirationGroup">
        </app-bank-card-expiration-form-control>

        <div class="width-48">
            <div class="bolt-space-bottom-xl">
                <app-cvv-form-control *ngIf="isValidCardType && !isManagePaymentPreferencesFlow" [cardType]="selectedCardType" formGroupName="cvvGroup">
                </app-cvv-form-control>
            </div>

            <app-zip-code-form-control formGroupName="zipGroup">
            </app-zip-code-form-control>
        </div>
    </div>

    <div [ngClass]="{'bolt-space-bottom-xl' : canSaveAsDefault }">
        <app-name-field-set header="Card holder's name" nameAsOnCard="Enter the name exactly as it appears on the card." formGroupName="nameGroup">
        </app-name-field-set>
    </div>

    <div id="save-as-default-payment" *ngIf="canSaveAsDefault" class="bolt-row">
        <div class="bolt-col-md-12">
            <bolt-checkbox type="checkbox" ngDefaultControl name="makeDefaultPaymentMethod" formControlName="makeDefaultPaymentMethod" value="isDefaultPaymentMethod"
                arialabel="Make this your default payment method" class="jm-visible">
                Make this your default payment method
                <bolt-contextual-help>
                    <p>This will be your preferred method of payment, even though you can add up to 10 credit card accounts and up to 10 bank accounts. You can also delete payment methods online
                        anytime.</p>
                </bolt-contextual-help>
            </bolt-checkbox>
        </div>
    </div>
</ng-container>