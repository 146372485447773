import { Injectable } from '@angular/core';
import { PaymentItem } from '../../../../shared/models/payment-item.model';
import { IsbExperience } from '@nationwide/dgs-angular-billing-common';

@Injectable()
export class SetAsDefaultFlow {
    mappedPaymentMethod: PaymentItem;
    unmappedPaymentMethod: IsbExperience.SavedPaymentMethod;
    apiCallSuccessful: boolean;
    flowInPlay: boolean;

    save(params: Partial<SetAsDefaultFlow>): void {
        this.flowInPlay = true;
        this.mappedPaymentMethod = params.mappedPaymentMethod || this.mappedPaymentMethod;
        this.unmappedPaymentMethod = params.unmappedPaymentMethod || this.unmappedPaymentMethod;
        this.apiCallSuccessful = params.apiCallSuccessful || this.apiCallSuccessful;
    }

    reset(): void {
        this.mappedPaymentMethod = null;
        this.unmappedPaymentMethod = null;
        this.apiCallSuccessful = null;
        this.flowInPlay = null;
    }
}
