export enum PaymentHistoryMethod {
    RECURRING_EFT = 'Payment - Electronic Funds Transfer (EFT)',
    CHECK = 'Payment - Cash, Check or Money Order',
    EFT = 'Payment - Electronic Funds Transfer (EFT)',
    BANKCARD = 'Payment - Debit / Credit Card',
    APPLE_PAY = 'Payment - Apple Pay',
    CASH = 'Payment - Cash, Check or Money Order',
    MONEY_ORDER = 'Payment - Cash, Check or Money Order',
    PAY_PAL = 'Payment - PayPal',
    MASTERPASS = 'Payment - Click to pay'
}
