import { Injectable } from '@angular/core';
import { PaymentItem } from '../../../../shared/models/payment-item.model';

@Injectable()
export class RemovePaymentMethodFlowService {
    paymentMethodToRemove: PaymentItem;
    replacementMethod: PaymentItem;
    flowInPlay: boolean;
    apiCallsSuccessful: boolean;
    apiResponseCode: number;
    paymentMethodDeleted: boolean;
    defaultPaymentMethodUpdateAttempted: boolean;

    save(params: Partial<RemovePaymentMethodFlowService>): void {
        this.flowInPlay = true;
        this.paymentMethodToRemove = params.paymentMethodToRemove || this.paymentMethodToRemove;
        this.replacementMethod = params.replacementMethod || this.replacementMethod;
        this.apiCallsSuccessful = params.apiCallsSuccessful || this.apiCallsSuccessful;
        this.apiResponseCode = params.apiResponseCode || this.apiResponseCode;
        this.paymentMethodDeleted = params.paymentMethodDeleted || this.paymentMethodDeleted;
    }

    reset(): void {
        this.paymentMethodToRemove = null;
        this.replacementMethod = null;
        this.flowInPlay = null;
        this.apiCallsSuccessful = null;
        this.apiResponseCode = null;
        this.paymentMethodDeleted = null;
    }
}
