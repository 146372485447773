import { Injectable, Inject } from '@angular/core';
import { EbiService } from './ebi.service';
import { EBI_EVENT_PROPERTIES, EbiEventProperty, GLOBAL_NAV_EVENT_PROPERTIES } from './event-properties';
import { EBIEventType } from './ebi-model';
import { BillingEBIOptions } from '../billing-api-common.service';
import { CtmEventType, CtmEventBody, CtmEventData } from '../ctm-service/ctm-model';
import { CtmService } from '../ctm-service/ctm.service';
import { LoggerService } from '../../logger/logger.service';

@Injectable()
export class BillingEventService {
    constructor(
        private ebiService: EbiService,
        private ctmService: CtmService,
        @Inject('logger') private LOGGER: LoggerService
    ) { }

    handleEvent(id: EBIEventType, options?: BillingEBIOptions): void {
        const ebiEventMap = EBI_EVENT_PROPERTIES.eventMap;
        const event: EbiEventProperty = ebiEventMap.get(id);

        if (event.triggerEbi) {
            this.ebiService.registerEbiEvent({ eventId: event.ebiEventId, additionalFields: event.additionalEbiFields, eventDescription: event.ebiEventDescription, options });
        }
    }

    handleCtmEvent(ctmEvent: CtmEventType, eventData?: CtmEventData): void {
        const ctmEventData: CtmEventBody = this.ctmService.setBillingCtmData(ctmEvent, eventData);
        if (ctmEventData) {
            try {
                this.ctmService.callCtm(ctmEventData);
            } catch (error) {
                this.LOGGER.error('AN ERROR OCCURRED WHILE FIRING A CTM EVENT', error);
            }
            this.LOGGER.trace('BillingCtmService - registerCtmEvent - END');
        }
    }

    handleGlobalNavEvent(id: string, options?: BillingEBIOptions): void {
        const listOfEvents = GLOBAL_NAV_EVENT_PROPERTIES.listOfEvents;
        for (const ebiEvent of listOfEvents) {
            if (ebiEvent.event === id && ebiEvent.triggerEbi) {
                this.ebiService.registerEbiEvent({
                    eventId: ebiEvent.ebiEventId,
                    additionalFields: ebiEvent.additionalEbiFields,
                    eventDescription: `Billing-${ebiEvent.ebiEventDescription}`,
                    options
                });
            }
        }
    }
}
