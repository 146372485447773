<ng-container [formGroup]="formGroup">
    <div class="row">
        <div class="col-sm-12 col-md-5">
            <bolt-textfield type="text" ngDefaultControl id="card-verificatrion-value" class="nwpii" [label]="labelText" required="true" formControlName="cvv" aria-required="true"
                description="helpText" [maxlength]="cvvLength" [arialabel]="labelText" [invalid]="cvvErrorsDisplayable ? true : null" aria-describedby="cvv-errors"></bolt-textfield>
            <app-form-control-error id="cvv-errors" *ngIf="cvvErrorsDisplayable">
                <ul class="error-list">
                    <li *ngIf="cvv.errors['required']">
                        {{labelText}} is required.
                    </li>
                    <li *ngIf="(cvv.errors['minlength'] || cvv.errors['maxlength'] ||
						cvv.errors['containsOnlyDigits']) && !cvv.errors['required']">
                        {{labelText}} is invalid.
                    </li>
                </ul>
            </app-form-control-error>
        </div>
    </div>

</ng-container>