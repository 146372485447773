import { Injectable } from '@angular/core';
import { BillingAccountItem } from '../../../../../../shared/payments/consider-this/save-as-refund-method/models/billing-account-item.model';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaperlessFlow } from '../../../../shared/models/paperless-flow.model';
import { PaymentItem } from '../../../../shared/models/payment-item.model';
import { AddPaymentMethodServiceResponses } from '../../add/review/add-payment-method-review.service';

@Injectable()
export class AddPaymentMethodFlowService implements PaperlessFlow {
    addPaymentForm: PaymentFormGroup;
    considerThisDisplayable = false;
    emailAddress: string;
    selectedPaymentMethod: PaymentItem;
    selectedEasyPay: string;
    selectedPaperless: string;
    isEasyPayEligible: boolean;
    isPaperlessEligible: boolean;
    isEligibleToSaveAsRefundMethod: boolean;
    paymentMethodSaveAttempted = false;
    paymentMethodSaved = false;
    addPaymentMethodServiceResponses: AddPaymentMethodServiceResponses;
    apiCallFailed = false;
    apiResponseCode: number;
    billingStreet1: string;
    billingCity: string;
    billingState: string;
    billingAccountsForRefund: BillingAccountItem[];
    paperlessEnrollmentAttempted = false;
    savedPaperlessEsddaPrefs = false;
    enrolledInPaperless = false;
    paperlessEmail: string;
    hasCreditCardVulPolicyError: boolean;

    save(params: Partial<AddPaymentMethodFlowService>): void {
        this.addPaymentForm = params.addPaymentForm || this.addPaymentForm;

        this.emailAddress = params.emailAddress || this.emailAddress;
        this.selectedPaymentMethod = params.selectedPaymentMethod || this.selectedPaymentMethod;

        this.isEasyPayEligible = params.isEasyPayEligible || this.isEasyPayEligible;
        this.isPaperlessEligible = params.isPaperlessEligible || this.isPaperlessEligible;
        this.isEligibleToSaveAsRefundMethod = params.isEligibleToSaveAsRefundMethod || this.isEligibleToSaveAsRefundMethod;

        this.selectedEasyPay = params.selectedEasyPay || this.selectedEasyPay;
        this.selectedPaperless = params.selectedPaperless || this.selectedPaperless;

        this.considerThisDisplayable = params.isEasyPayEligible || params.isPaperlessEligible || this.isEligibleToSaveAsRefundMethod;

        this.paymentMethodSaveAttempted = params.paymentMethodSaveAttempted || this.paymentMethodSaveAttempted;
        this.paymentMethodSaved = params.paymentMethodSaved || this.paymentMethodSaved;

        this.addPaymentMethodServiceResponses = params.addPaymentMethodServiceResponses || this.addPaymentMethodServiceResponses;

        this.apiCallFailed = params.apiCallFailed || this.apiCallFailed;

        this.billingStreet1 = params.billingStreet1 || this.billingStreet1;
        this.billingCity = params.billingCity || this.billingCity;
        this.billingState = params.billingState || this.billingState;

        this.apiResponseCode = params.apiResponseCode || this.apiResponseCode;
        this.billingAccountsForRefund = params.billingAccountsForRefund || this.billingAccountsForRefund;
        this.paperlessEnrollmentAttempted = params.paperlessEnrollmentAttempted || this.paperlessEnrollmentAttempted;
        this.savedPaperlessEsddaPrefs = params.savedPaperlessEsddaPrefs || this.savedPaperlessEsddaPrefs;
        this.enrolledInPaperless = params.enrolledInPaperless || this.enrolledInPaperless;
        this.paperlessEmail = params.paperlessEmail || this.paperlessEmail;

        this.hasCreditCardVulPolicyError = params.hasCreditCardVulPolicyError || this.hasCreditCardVulPolicyError;
    }

    clear(): void {
        this.addPaymentForm = undefined;
        this.emailAddress = undefined;
        this.selectedPaymentMethod = undefined;

        this.isEasyPayEligible = undefined;
        this.isPaperlessEligible = undefined;
        this.isEligibleToSaveAsRefundMethod = undefined;

        this.selectedEasyPay = undefined;
        this.selectedPaperless = undefined;

        this.considerThisDisplayable = undefined;

        this.paymentMethodSaveAttempted = false;
        this.paymentMethodSaved = false;

        this.addPaymentMethodServiceResponses = undefined;

        this.apiCallFailed = undefined;

        this.billingStreet1 = undefined;
        this.billingCity = undefined;
        this.billingState = undefined;

        this.apiResponseCode = null;
        this.billingAccountsForRefund = undefined;
        this.paperlessEnrollmentAttempted = false;
        this.savedPaperlessEsddaPrefs = false;
        this.enrolledInPaperless = false;
        this.emailAddress = undefined;
        this.paperlessEmail = undefined;

        this.hasCreditCardVulPolicyError = undefined;
    }

    get flowInPlay(): boolean {
        return this.addPaymentForm !== undefined;
    }

    get savePayMethodResponse(): any {
        return this.addPaymentMethodServiceResponses && this.addPaymentMethodServiceResponses.savedPaymentMethodResponse;
    }
}
