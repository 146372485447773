import { Component, Input } from '@angular/core';
@Component({
    selector: 'app-save-as-refund-confimation',
    templateUrl: './save-as-refund-confirmation-details.component.html',
    styleUrls: ['./save-as-refund-confirmation-details.component.scss']
})
export class SaveAsRefundConfimationDetailsComponent {
    @Input() successfulRefundMethodEnrollments: string[] = [];
    @Input() unsuccessfulRefundMethodEnrollments: string[] = [];
}
