import { Component, Input } from '@angular/core';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';
import { AbstractControl, ControlContainer } from '@angular/forms';

export type ControlNames = 'bankAccountInfo' | 'accountNumberGroup' | 'accountTypeGroup';

@Component({
    selector: 'app-bank-account-info-form-controls',
    templateUrl: './bank-account-info-form-controls.component.html',
    styleUrls: ['./bank-account-info-form-controls.component.scss']
})
export class BankAccountInfoFormControlsComponent extends BaseFormGroupComponent<ControlNames> {
    @Input() plaidEnabled?: boolean;
    @Input() manualAccountDisabled?: boolean;

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    get bankAccountInfo(): AbstractControl {
        return this.getControl('bankAccountInfo');
    }

    get bankAccountInfoErrorDisplayable(): boolean {
        return this.errorsDisplayable('bankAccountInfo');
    }

    onChangeBankAccountInfoSelect($evt): void {
        this.bankAccountInfo.setValue($evt.target.value);
    }
}
