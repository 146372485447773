import { SavedPaymentMethods, PaymentMethodAccordion } from './saved-payment-methods.model';
import { PaymentPrefsSectionPlaceholder } from '../../shared/models/placeholder.model';
import { PaymentItem } from '../../shared/models/payment-item.model';
import { BankAccountPaymentItem } from '../../shared/models/bankaccount-item.model';
import { BankCardPaymentItem } from '../../shared/models/bankcard-item.model';
import { CardType } from '../../../../shared/payments/card-utils/card-type.enum';

export const PAY_METHODS_STUB_UI = {
    NO_METHODS_ON_FILE: SavedPaymentMethods.create({
        placeholder: new PaymentPrefsSectionPlaceholder(
            'You do not have any saved payment methods.',
            [`
        Saving your payment methods is a convenient
        and safe way to manage the bank and credit
        card accounts used to make your insurance
        payments. Your account information is saved
        securely, and you'll enjoy the time you save
        online. To get started, click the
        "Add payment method" button below.
      `]
        ),
        canAddPayMethod: true
    }),
    PAY_METHODS_UNAVAILABLE: SavedPaymentMethods.create({
        warnings: [`
      This feature is temporarily unavailable. Please
      try again later. For immediate assistance concerning
      your policy or bill payments, please contact
      your local Nationwide agent or call 1-877-669-6877.
      Representatives are available Monday through
      Friday from 8AM to 9PM ET.
    `]
    }),

    BANK_ACCOUNT_PAY_METHODS: SavedPaymentMethods.create({
        payMethodAccordions: [
            PaymentMethodAccordion.create({
                header: 'Bank Account ...358',
                payMethod: new PaymentItem(
                    new BankAccountPaymentItem(
                        'Bob',
                        'Savings',
                        '',
                        '1 main st',
                        '',
                        'Columbus',
                        'OH',
                        '54321',
                        'New bank added by Aoa<Aoa',
                        'Savings',
                        '123123123',
                        '358',
                        ''
                    )
                )
            }),
            PaymentMethodAccordion.create({
                header: 'Bank Account ...358',
                payMethod: new PaymentItem(
                    new BankAccountPaymentItem(
                        'Bob',
                        'Savings',
                        '',
                        '1 main st',
                        '',
                        'Columbus',
                        'OH',
                        '54321',
                        'New bank added by Aoa<Aoa',
                        'Savings',
                        '123123123',
                        '358',
                        ''
                    )
                )
            })
        ]
    }),

    BANK_CARD_PAY_METHODS: SavedPaymentMethods.create({
        payMethodAccordions: [
            PaymentMethodAccordion.create({
                header: 'Master Card ...5454',
                payMethod: new PaymentItem(
                    new BankCardPaymentItem(
                        'Test',
                        'User',
                        '',
                        '',
                        '43015',
                        CardType.MASTERCARD,
                        '...5454',
                        new Date('Jan 06 2023'),
                        '5454'
                    ),
                    false,
                    ''
                )
            }),
            PaymentMethodAccordion.create({
                header: 'Master Card ...5454',
                payMethod: new PaymentItem(
                    new BankCardPaymentItem(
                        'Test',
                        'User',
                        '',
                        '',
                        '43015',
                        CardType.MASTERCARD,
                        '...5454',
                        new Date('Jan 06 2023'),
                        '5454'
                    ),
                    false,
                    ''
                )
            })
        ]
    }),
    DEFAULT_PAY_METHOD: SavedPaymentMethods.create({
        payMethodAccordions: [
            PaymentMethodAccordion.create({
                header: 'Master Card ...5454',
                payMethod: new PaymentItem(
                    new BankCardPaymentItem(
                        'Test',
                        'User',
                        '',
                        '',
                        '43015',
                        CardType.MASTERCARD,
                        '...5454',
                        new Date('Jan 06 2023'),
                        '5454'
                    ),
                    true,
                    ''
                )
            })
        ]
    })
};
