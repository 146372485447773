<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>

<form *ngIf="editPaymentMethodForm" [formGroup]="editPaymentMethodForm.group" class="manage-payment-form-container">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="Edit payment method progress value is 20%" progressBarValue="20" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Edit payment method</h1>

        <div class="bolt-row">
            <div class="bolt-col-12">
                <bw-bolt-notification type="error" [id]="'global-error-message'" *ngIf="globalErrorDisplayable || paperlessEsddaValidationError" class="bolt-space-bottom-xl to-container">
                    Please check the information below and try again.
                </bw-bolt-notification>

                <section id="payment-method-section" class="fluid-container" [ngClass]="{'bolt-space-bottom-lg': (paymentItem.isBankCard)}">
                    <div formGroupName="paymentMethodGroup">

                        <div id="bank-card-form" *ngIf="paymentItem.isBankCard">
                            <div class="bolt-space-bottom-2xl">
                                <app-name-field-set header="Card holder's name" nameAsOnCard="Enter the name exactly as it appears on the card." formGroupName="nameGroup">
                                </app-name-field-set>
                            </div>
                            <app-payment-sub-header>Payment method</app-payment-sub-header>

                            <app-bankcard-recap-form-field [field]="bankCardRecap"></app-bankcard-recap-form-field>

                            <div class="sub-row bolt-space-bottom-xl">
                                <em>To update the card number, remove the payment method then add it again.</em>
                            </div>

                            <app-bank-card-expiration-form-control formGroupName="cardExpirationGroup">
                            </app-bank-card-expiration-form-control>

                            <div class="bolt-row bolt-space-bottom-xl">
                                <app-zip-code-form-control formGroupName="zipGroup" class="bolt-col-md-2 bolt-col-sm-12">
                                </app-zip-code-form-control>
                            </div>
                        </div>
                        <div id="bank-account-form" *ngIf="paymentItem.isBankAccount">
                            <div class="bolt-space-bottom-xl">
                                <app-name-field-set header="Account holder's name and address" nameAsOnCard="" formGroupName="nameGroup">
                                </app-name-field-set>
                            </div>

                            <div class="bolt-space-bottom-2xl">
                                <app-address-form-control formGroupName="addressGroup">
                                </app-address-form-control>
                            </div>

                            <app-payment-sub-header>Account information</app-payment-sub-header>

                            <div class="bolt-space-bottom-xl">
                                <div><strong>Account type</strong></div>
                                <div>{{paymentItem.methodType}}</div>
                            </div>

                            <div class="bolt-space-bottom-xl">
                                <div><strong>Bank routing number</strong></div>
                                <div>{{paymentItem.bankAccountRoutingNumber}}</div>
                                <div><em>To update the routing number, remove the payment method then add it again.</em>
                                </div>
                            </div>

                            <div class="bolt-space-bottom-xl">
                                <div><strong>Bank name</strong></div>
                                <div>{{paymentItem.bankName}}</div>
                            </div>

                            <div [ngClass]="(isEasyPayEligible || !paymentItem.isDefault)?'bolt-space-bottom-xl' : 'bolt-space-bottom-2xl' ">
                                <div><strong>Account number</strong></div>
                                <div>******** {{paymentItem.displayNumbers}}</div>
                                <div><em>To update the account number, remove the payment method then add it again.</em>
                                </div>
                            </div>
                        </div>
                        <app-save-as-checkbox *ngIf="!paymentItem.isDefault"
                            [helpTextHtml]="'This will be your preferred method of payment, even though you can add up to 10 credit card accounts and up to 10 bank accounts. You can also delete payment methods online anytime.'"
                            [labelFor]="'save-as-default-pay-method'" [bolt]="true" class="to-container" [ngClass]="!isEasyPayEligible? 'bolt-space-bottom-2xl' : 'bolt-space-bottom-xl' ">
                            <bolt-checkbox ngDefaultControl (change)="changeMakeDefaultPayment($event)" id="save-as-default-pay-method" formControlName="makeDefaultPaymentMethod"
                                [attr.checked]="isDefaultPayment() ? true : null">Make this your default payment method
                            </bolt-checkbox>
                        </app-save-as-checkbox>
                    </div>

                    <app-consider-this formGroupName="considerThisGroup" [canSelectPaperlessBilling]="isPaperlessEligible" [isEligibleForEasyPay]="isEasyPayEligible"
                        (esddaCheckBoxChecked)="onEsddaCheckboxClick($event)" [paperlessEsddaValidationError]="paperlessEsddaValidationError" [email]="getEmailAddress()"
                        [paperlessEnrollmentOption]="editPaymentMethodForm.enrollInPaperless">
                    </app-consider-this>

                </section>
            </div>
        </div>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" id="cancel-button" (click)="cancelEditPayment()">Cancel</bolt-button>
        <bolt-button submit slot="forward" id="continue-button" type="primary" (click)="onContinue()">Continue</bolt-button>
    </bolt-button-bar>
</form>