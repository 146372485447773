import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';
import { CardType } from '../card-utils/card-type.enum';
import { BankCardPaymentItem } from '../../../billing/payment-preferences/shared/models/bankcard-item.model';

export type ControlNames = 'nameGroup' | 'bankCardFormGroup' | 'cardExpirationGroup' | 'cvvGroup' | 'zipGroup';

@Component({
    selector: 'app-pmof-bank-card-form-controls',
    templateUrl: './pmof-bank-card-form-controls.component.html',
    styleUrls: [
        './pmof-bank-card-form-controls.component.scss',
        '../../../pay-bill/pay-bill-landing/pay-bill-landing.component.scss',
        '../../../shared/payments/stylesheets/form-formatting.scss'
    ]
})
export class PmofBankCardFormControlsComponent extends BaseFormGroupComponent<ControlNames> {
    @Input() updatingPaymentMethod: boolean;
    @Input() defaultPaymentMethodIsSelected: boolean;

    cardType: CardType = CardType.INVALID;

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    @Input()
    set bankCardMethod(bankCardMethod: BankCardPaymentItem) {
        this.cardType = bankCardMethod.cardType;
    }
}
