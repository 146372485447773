import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of, catchError } from 'rxjs';
import { LoggerService } from '../../logger/logger.service';
import { SessionService } from '../../session/session.service';
import { BillingApiCommonService } from '../billing-api-common.service';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EbiApiService {
    // eslint-disable-next-line max-params
    constructor(
        private http: HttpClient,
        private apiCommon: BillingApiCommonService,
        private session: SessionService,
        @Inject('logger') private LOGGER: LoggerService
    ) { }

    sendEbiEvent(eventId: string, eventPayload: object, callback: () => void): void {
        const url = `${this.apiCommon.getConstantValue('ebiEndPoint')}?client_id=${this.apiCommon.getConstantValue('client_id')}`; // EBI end point
        const authToken = this.session.accessToken;

        const headers = this.apiCommon.buildHeader({ appClientId: '', authToken, nwAppId: this.apiCommon.getConstantValue('nw_APP_ID'), contentType: this.apiCommon.getConstantValue('content_Type'), headerType: 'X-Nw-Transaction-Id' });

        const data = {
            appCookie: this.apiCommon.getConstantValue('appCookie'),
            eventId,
            sessionId: this.apiCommon.getConstantValue('sessionId'),
            url,
            data: eventPayload,
            splunkToken: environment.LOGGING.splunkToken,
            splunkApp: environment.LOGGING.appName
        };

        const identifier = {
            sessionId: this.apiCommon.getConstantValue('sessionId'),
            data: eventPayload
        };

        this.http.post(url, data, { headers }).pipe(
            catchError((error) => {
                const SUCCESSFUL_PUT = 202;
                if (error.status === SUCCESSFUL_PUT) {
                    this.LOGGER.info('eventAPIService - callApi: sendEbiEvent', error.response);
                    return of(error.response);
                } else {
                    this.LOGGER.error(`eventAPIService - callApi: sendEbiEvent errored for ebi ${eventId} ${identifier}`, error);
                    return of({ error });
                }
            })).subscribe((res) => {
                if (!res?.error) {
                    this.LOGGER.event(`Ebi Event ${eventId} was sent with following data: `, data);
                }
                callback();
            });
    }
}
