import { Subscription } from 'rxjs';
import { OnDestroy, Component } from '@angular/core';

@Component({ template: '' })
export abstract class SubscribingComponent implements OnDestroy {
    protected subscriptions: Subscription[] = [];

    set nextSub(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
