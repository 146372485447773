<fieldset class="bank-account-info-plaid-form" [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <div class="bolt-container-ml38">
        <div class="bolt-row bolt-space-bottom-md">
            <div class="bolt-col-12">
                Nationwide partners with a third party vendor that uses end-to-end encryption and private sessions
                to allow you to select your bank account from your financial institution for purpose of this payment.
                You also can manually add your bank account any time.
            </div>
        </div>
        <div class="bolt-row">
            <div class="bolt-col-12">
                <bolt-button type="primary" id="plaid-continue" (click)="onPlaidClick($event)">
                    Link account
                </bolt-button>
            </div>
        </div>
        <div class="bolt-row">
            <div class="bolt-col-12">
                <app-bank-account-type-form-control *ngIf="plaidResponse" [plaidAccountType]="accountType" [plaidResponse]="plaidResponse" formGroupName="accountTypeGroup">
                </app-bank-account-type-form-control>
                <app-bank-account-number-form-controls *ngIf="plaidResponse" [plaidRoutingNumber]="routingNumber" [plaidAccountNumber]="accountNumber" [plaidResponse]="plaidResponse"
                    formGroupName="accountNumberGroup">
                </app-bank-account-number-form-controls>
            </div>
        </div>
        <div class="bolt-row">
            <div class="bolt-col-12">
                <app-form-control-error id="account-info-errors" *ngIf="accountNumberGroupErrorDisplayable">
                    Please select Link account button to obtain your bank account details from our vendor,
                    or enter account information manually.
                </app-form-control-error>
            </div>
        </div>
    </div>
</fieldset>