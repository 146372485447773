import { Injectable } from '@angular/core';
import { RemoveDuplicatesPipe } from '@nationwide/internet-servicing-angular-pipes';
import { DPIM, AgencyInformationResponse, PBS as UnmappedRCA, PBS as Unmapped, PersonalBilling as PersonalBillingUnmapped, ECIM } from '@nationwide/dgs-angular-billing-common';
import { SharedRCAAdapter } from '../../../../../../app/shared/shared-base.adapter';
import { EFTAuthorizationAgreementParams } from '../../easy-pay-authorization-agreement.service';
import { IsbSharedCommonHelperService } from '../../../../../../app/shared/isb-shared-common-helper.service';
import { AuthConfirmationPolicyDetailsElements } from '../form-elements/auth-confirmation-policy-details-elements';
import { AuthConfirmationDetailsElements } from '../form-elements/auth-confirmation-details.elements';
import { ReceiptDetailsElements } from '../form-elements/receipt-details-elements';
import { SessionService } from '../../../../../shared/session/session.service';
import { AcctountPolicyHolderNameElements } from '../form-elements/acctount-policy-holder-name-elements';
import { AccountPolicyHolderAddressElements } from '../form-elements/account-policy-holder-address-elements';
import { AccountPolicyHolderElements } from '../form-elements/account-policy-holder-elements';
import { EftAuthorizationFormHelperService } from '../../eft-authorization-form-helper.service';
import { BamLtrFlagsElements } from '../form-elements/bam-ltr-flags-elements';
import { AgencyInformationResponseModel } from '../model/agency-information-response.model';
import { DpimAgentInformationAdapter } from './dpim-agent-information.adapter';
import { AgentInformationAdapter } from './agent-information.adapter';
import { EftAuthorizationFormElements } from '../form-elements/eft-authorization-form-elements';

@Injectable()
export class EftAuthorizationAdapter extends SharedRCAAdapter {
    // eslint-disable-next-line max-params
    constructor(
        removeDuplicatesPipe: RemoveDuplicatesPipe,
        private isbSharedCommonHelperService: IsbSharedCommonHelperService,
        session: SessionService,
        private eftAuthorizationFormHelperService: EftAuthorizationFormHelperService,
        private dpimAgentInformationAdapter: DpimAgentInformationAdapter,
        private agentInformationAdapter: AgentInformationAdapter,
        protected sharedService: IsbSharedCommonHelperService
    ) {
        super(
            removeDuplicatesPipe,
            session,
            sharedService
        );
    }

    mapEftAuthorizationFormElements(parameters: {
        rcaResponse: Unmapped.RetriveCustomerAgreementResponse;
        agencyInformationResponseModel: AgencyInformationResponseModel;
        relatedPolicies: PersonalBillingUnmapped.Policy[];
        authorizationAgreementParams: EFTAuthorizationAgreementParams;
        internetRegistrationResponse: ECIM.EnterpriseCustomerInformationManagementResponse;
    }): EftAuthorizationFormElements {
        const accountPolicyHolderElements: AccountPolicyHolderElements = this.mapAccountPolicyHolderElements(parameters.internetRegistrationResponse, parameters.authorizationAgreementParams.billingAccountHolderName);
        const authConfirmationDetailsElements: AuthConfirmationDetailsElements = this.mapAuthConfirmationDetailsElements(parameters.rcaResponse, parameters.relatedPolicies, parameters.authorizationAgreementParams);
        const receiptDetailsElements: ReceiptDetailsElements = this.mapReceiptDetailsElements(parameters.rcaResponse, parameters.authorizationAgreementParams);
        const bamLtrFlagsElements: BamLtrFlagsElements = this.mapBamLtrFlagsElements(parameters.rcaResponse);
        return {
            Documents: this.eftAuthorizationFormHelperService.buildDocumentElements(),
            AccountPol: this.eftAuthorizationFormHelperService.buildAccountPolicyElements(parameters.authorizationAgreementParams.billingAccountUserName),
            AccountPolHolder: accountPolicyHolderElements,
            AuthConfirmationDetails: authConfirmationDetailsElements,
            ReceiptDetails: receiptDetailsElements,
            Agent: parameters.agencyInformationResponseModel ?
                this.eftAuthorizationFormHelperService.buildAgentInformation(parameters.agencyInformationResponseModel) :
                null,
            BamLtrFlags: bamLtrFlagsElements
        };
    }

    mapAuthConfirmationPolicyDetailsElements(policyAgreements: UnmappedRCA.Agreement[], policies: PersonalBillingUnmapped.Policy[]): AuthConfirmationPolicyDetailsElements[] {
        const authConfirmationPolicyDetailsElementsArray: AuthConfirmationPolicyDetailsElements[] = [];
        policyAgreements.forEach((policyAgreement) => {
            if (policyAgreement.roleInAgreement && policyAgreement.roleInAgreement.permission &&
                this.canDisplay(policyAgreement.roleInAgreement.permission)) {
                const paymentAmountString = this.getPolicyPaymentAmount(policyAgreement);
                const policyCompanyCodeDescription = this.getPolicyCompanyCodeDescription(policyAgreement, policies);
                const paymentAmount = paymentAmountString ? parseInt(paymentAmountString, 10) : 0.0;
                const authConfirmationPolicyDetailsElements: AuthConfirmationPolicyDetailsElements = {
                    PolicyNumber: policyAgreement.displayAgreementNumber,
                    CompanyName: policyCompanyCodeDescription,
                    PolicyType: this.isbSharedCommonHelperService.getProductName(policyAgreement),
                    EstTermPremium: paymentAmount,
                    EstBillAmount: paymentAmount
                };
                authConfirmationPolicyDetailsElementsArray.push(authConfirmationPolicyDetailsElements);
            }
        });
        return authConfirmationPolicyDetailsElementsArray;
    }

    mapAccountPolicyHolderElements(internetRegistrationResponse: ECIM.EnterpriseCustomerInformationManagementResponse, billingAccountHolderName: string): AccountPolicyHolderElements {
        const firstAndLastName: { firstName: string; lastName: string } = this.eftAuthorizationFormHelperService.getFirstAndLastName(billingAccountHolderName);
        const acctountPolicyHolderNameElements: AcctountPolicyHolderNameElements = {
            AcctPolHolderFirstName: firstAndLastName.firstName,
            AcctPolHolderLastName: firstAndLastName.lastName
        };
        const accountPolicyHolderAddressElements: AccountPolicyHolderAddressElements = this.addressFromInternetRegistrationResponse(internetRegistrationResponse);
        const phone = this.getPhoneFromInternetRegistrationResponse(internetRegistrationResponse);
        return {
            AcctPolHolderName: acctountPolicyHolderNameElements,
            AcctPolHolderAddress: accountPolicyHolderAddressElements,
            AcctPolHolderPhone: phone
        };
    }

    mapAuthConfirmationDetailsElements(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, policies: PersonalBillingUnmapped.Policy[], authorizationAgreementParams: EFTAuthorizationAgreementParams): AuthConfirmationDetailsElements {
        const policyAgreements: UnmappedRCA.Agreement[] = this.getPolicies(rcaResponse);
        let authConfirmationPolicyDetailsElements: AuthConfirmationPolicyDetailsElements[];
        let authConfirmationDetailsElements: AuthConfirmationDetailsElements;
        if (policyAgreements && policyAgreements.length > 0) {
            authConfirmationPolicyDetailsElements = this.mapAuthConfirmationPolicyDetailsElements(policyAgreements, policies);
            if (authorizationAgreementParams && authConfirmationPolicyDetailsElements) {
                authConfirmationDetailsElements = this.eftAuthorizationFormHelperService.buildAuthConfirmationDetailsElements(
                    authorizationAgreementParams,
                    authConfirmationPolicyDetailsElements);
            }
        }
        return authConfirmationDetailsElements;
    }

    mapReceiptDetailsElements(rcaResponse: Unmapped.RetriveCustomerAgreementResponse, authorizationAgreementParams: EFTAuthorizationAgreementParams): ReceiptDetailsElements {
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const dueDate = this.getDueDateAccountLevel(rcaResponse) || this.getNextDueDate(billingAgreement);
        const dayOfMonthInDueDate = dueDate ? this.eftAuthorizationFormHelperService.getDayOfMonthInEnrolledDate(dueDate) : '';
        const receiptDetailsElements: ReceiptDetailsElements = this.eftAuthorizationFormHelperService.buildReceiptDetailsElements(authorizationAgreementParams);
        if (receiptDetailsElements) {
            receiptDetailsElements.PaidDateTime = dayOfMonthInDueDate;
            receiptDetailsElements.TotalAmtPaidOrVoided = this.getTotalAmtPaidOrVoided(billingAgreement);
        }

        return receiptDetailsElements;
    }

    mapBamLtrFlagsElements(rcaResponse: Unmapped.RetriveCustomerAgreementResponse): BamLtrFlagsElements {
        const billingAgreement = this.getBillingAgreementFromRCA(this.getAgreementsFromRCA(rcaResponse));
        const placeOfIssue = this.getPlaceOfIssue(billingAgreement);
        const bamLtrFlagsElements: BamLtrFlagsElements = {
            PaymentApplicationMsgIndMD: placeOfIssue && placeOfIssue === 'MD' ? 'Y' : ''
        };
        return bamLtrFlagsElements.PaymentApplicationMsgIndMD ? bamLtrFlagsElements : null;
    }

    buildAgencyInformationResponseModelFuture(agencyInformationResponse: any): AgencyInformationResponseModel {
        let agencyInformationResponseModel: AgencyInformationResponseModel;
        if (this.session.isNBP) {
            agencyInformationResponseModel = this.agentInformationAdapter.mapAgentInformation(<AgencyInformationResponse>agencyInformationResponse);
        } else if (this.session.isSAP) {
            agencyInformationResponseModel = this.dpimAgentInformationAdapter.mapDpimAgentInformation(<DPIM.DpimAgencyInformationResponse>agencyInformationResponse);
        }
        return agencyInformationResponseModel;
    }

    buildAgencyInformationResponseModel(agencyInformationResponse: AgencyInformationResponse): AgencyInformationResponseModel {
        return this.agentInformationAdapter.mapAgentInformation(agencyInformationResponse);
    }

    private getPolicyCompanyCodeDescription(policyAgreement: UnmappedRCA.Agreement, policies: PersonalBillingUnmapped.Policy[]): string {
        const filteredPolicy: PersonalBillingUnmapped.Policy[] = policies.filter((policy) => this.isbSharedCommonHelperService
            .removeSpecialCharacter(policy.policyNumber) === this.isbSharedCommonHelperService
                .removeSpecialCharacter(policyAgreement.displayAgreementNumber));
        return filteredPolicy && filteredPolicy.length > 0 ? filteredPolicy[0].issuingCompany : policyAgreement.issuingCompany;
    }

    getPhoneFromInternetRegistrationResponse(internetRegistrationResponse: ECIM.EnterpriseCustomerInformationManagementResponse): string {
        let phone = '';
        if (internetRegistrationResponse?.customers?.contactPoints) {
            const contactPoints: ECIM.ContactPoint[] = internetRegistrationResponse.customers.contactPoints;
            for (const contactPoint of contactPoints) {
                if (contactPoint?.contactPointType === 'Telephone Number' && contactPoint?.preferredIndicator === 'Y') {
                    phone = contactPoint?.phone?.referenceNumber;
                    break;
                }
            }
        }
        return phone;
    }

    private getTotalAmtPaidOrVoided(billingAgreement: UnmappedRCA.Agreement): number {
        const parameters = this.getParameters(billingAgreement);
        let paymentAmountString = '0.0';
        if (parameters) {
            paymentAmountString = this.getLastPaymentAmountAccountLevel(parameters);
        } else {
            paymentAmountString = this.getStatementAmount(billingAgreement) ||
                this.getNextPaymentAmount(billingAgreement);
        }
        return parseFloat(paymentAmountString);
    }
}
