<div class="confirmation-details">
    <div class="main-contents">
        <div *ngIf="!addPaymentMethodError">
            <div class="bolt-row bolt-space-bottom-xl">
                <div class="bolt-col">
                    <strong>{{ saveVerb }} payment method:</strong>
                </div>
                <div class="bolt-col">
                    {{paymentMethod.paymentType}} {{paymentMethod.maskedNumber}}
                </div>
            </div>

            <div class="bolt-row"
                [ngClass]="{'bolt-space-bottom-xl': (wasPreferenceEsddaEnrollmentSuccessful || wasEsddaEnrollmentSuccessful || (successfulRefundMethodEnrollments && successfulRefundMethodEnrollments.length > 0) || (unsuccessfulRefundMethodEnrollments && unsuccessfulRefundMethodEnrollments.length > 0)),
				'bolt-space-bottom-2xl': !(wasPreferenceEsddaEnrollmentSuccessful || wasEsddaEnrollmentSuccessful || (successfulRefundMethodEnrollments && successfulRefundMethodEnrollments.length > 0) || (unsuccessfulRefundMethodEnrollments && unsuccessfulRefundMethodEnrollments.length > 0))}">
                <div class="bolt-col">
                    <strong>{{ saveVerb }} payment method effective date:</strong>
                </div>
                <div class="bolt-col">
                    {{formattedCurrentDate}}
                </div>
            </div>
        </div>
        <div *ngIf="!addPaymentMethodError && wasPreferenceEsddaEnrollmentSuccessful">
            <div class="bolt-row"
                [ngClass]="{'bolt-space-bottom-2xl': !((successfulRefundMethodEnrollments && successfulRefundMethodEnrollments.length > 0) || (unsuccessfulRefundMethodEnrollments && unsuccessfulRefundMethodEnrollments.length > 0)),
					'bolt-space-bottom-xl': ((successfulRefundMethodEnrollments && successfulRefundMethodEnrollments.length > 0) || (unsuccessfulRefundMethodEnrollments && unsuccessfulRefundMethodEnrollments.length > 0))}">
                <div class="bolt-col">
                    <strong>Paperless enrollment change effective date:</strong>
                </div>
                <div class="bolt-col">
                    {{formattedCurrentDate}}
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="isPaperlessEnrollmentUnsuccessful">
        <bw-bolt-notification type="error">
            {{ errorMessage }}
        </bw-bolt-notification>
    </ng-container>

    <app-save-as-refund-confimation *ngIf="(successfulRefundMethodEnrollments || unsuccessfulRefundMethodEnrollments)" [successfulRefundMethodEnrollments]="successfulRefundMethodEnrollments"
        [unsuccessfulRefundMethodEnrollments]="unsuccessfulRefundMethodEnrollments">
    </app-save-as-refund-confimation>
</div>