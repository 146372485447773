<is-wait-message [loadComplete]="pageLoadComplete" [waitMessage]="'Please wait while we process your request.'" [useBolt]="false" *ngIf="!pageLoadComplete">
</is-wait-message>

<form [formGroup]="formGroup.group" *ngIf="formGroup && pageLoadComplete" class="manage-payment-form-container" (ngSubmit)="onSubmit()">
    <div class="bolt-container">

        <bw-bolt-progress-bar appFocusOnInit arialabel="Cancel scheduled payment progress value is 50%" progressBarValue="50" class="progress-bar-container to-container"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl page-title">Cancel scheduled payment</h1>

        <bw-bolt-notification type="error" aria-labelledby="global-error-message" [id]="'global-error-message'" *ngIf="globalErrorDisplayable" class="bolt-space-bottom-xl to-container">
            <span class="error-notification">Please check the information below and try again.</span>
        </bw-bolt-notification>

        <section class="fluid-container">
            <app-payment-sub-header>
                Cancel a scheduled payment
            </app-payment-sub-header>

            <app-are-you-sure-radio-buttons formGroupName="areYouSureFormGroup" [submitted]="submitted" [displayMessage]="displayMessageForForm"></app-are-you-sure-radio-buttons>
        </section>
    </div>
    <bolt-button-bar>
        <bolt-button slot="cancel" aria-label="cancel" id="cancel-button" (click)="onCancelButton()">Cancel</bolt-button>
        <bolt-button submit type="primary" slot="forward" id="submit-button">Submit</bolt-button>
    </bolt-button-bar>
</form>