import { ECIM as UnmappedECIM } from '@nationwide/dgs-angular-billing-common';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CtmAdapter {
    getProducerNumbers(agreement: UnmappedECIM.Agreement): string[] {
        const producerNumbers: string[] = [];

        if (agreement?.relatedDistributionPartners) {
            for (const relatedDistributionPartner of agreement.relatedDistributionPartners) {
                if (relatedDistributionPartner.distributionPartnerType.includes('Producer')) {
                    producerNumbers.push(relatedDistributionPartner.externalIdentifier);
                }
            }
        }

        return producerNumbers;
    }
}
