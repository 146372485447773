/* eslint-disable no-magic-numbers */
export enum QuickPayServerSideErrorMessageCode {

    UNAUTHORIZED = 401,
    ZIP_INCORRECT = 402,
    DOB_INCORRECT = 403,
    NO_RECORDS_FOUND = 404,
    CODE_407 = 407,
    CODE_408 = 408,
    CANCELLED = 406,
    INELIGIBLE = 409,
    SERVER_ERROR = 500,
    ECIF_ERROR = 501,
    SEARCH_PARTY_ERROR = 502,
    RETRIEVE_PARTY_ERROR = 503,
    RAD_SERVICE_ERROR = 504,
    PARSE_ERROR = 505,
    SUCCESS = 200,
    REDIRECT_TO_ICP_PICKER_PAGE = 550
}
