import { Injectable, Inject } from '@angular/core';
import { Observable, of, tap, catchError, map } from 'rxjs';
import {
    PBS,
    PersonalBilling,
    ECIM,
    enterpriseCIMServiceToken,
    EnterpriseCIMService,
    isbExperienceServiceToken,
    IsbExperienceService,
    IsbExperience,
    PersonalBillingRequestParams,
    PersonalBillingHttpClient,
    IsbCalculateDownPaymentRequestParams
} from '@nationwide/dgs-angular-billing-common';
import { SessionService } from '../../../../../shared/session/session.service';
import { PaymentPreferencesStateService } from '../payment-preferences-state/payment-preferences-state.service';
import { DateUtil } from '@nationwide/angular-timeline';
import { HttpCachingService } from '../../../../../shared/service/http-caching-service/http-caching.service';

@Injectable()
export class PaymentPreferencesApiHttpService {
    // eslint-disable-next-line max-params
    constructor(
        private session: SessionService,
        private paymentPreferencesState: PaymentPreferencesStateService,
        @Inject(enterpriseCIMServiceToken) private ecimService: EnterpriseCIMService,
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private httpCachingService: HttpCachingService,
        private dateUtil: DateUtil,
        private personalBilling: PersonalBillingHttpClient
    ) { }

    fetchRetrieveCustomerAgreementResponse(): Observable<PBS.RetriveCustomerAgreementResponse> {
        return this.httpCachingService.fetchRetrieveCustomerAgreementResponse();
    }

    fetchSavedPaymentMethods(): Observable<IsbExperience.SavedPaymentMethod[]> {
        return this.isbExperience.getSavedPaymentMethods({
            billingSystem: this.session.billingSystem,
            accessToken: this.session.accessToken,
            ecn: this.session.ecn,
            billingAccountNumber: this.session.billingAccountNumber
        }).pipe(
            tap((response) => this.paymentPreferencesState.savedPaymentMethods = response),
            catchError(() => of(null))
        );
    }

    fetchSingleSavedPaymentMethod(methodID: string): Observable<IsbExperience.SavedPaymentMethod> {
        return this.isbExperience.getSingleSavedPaymentMethod({
            billingSystem: this.session.billingSystem,
            methodID,
            accessToken: this.session.accessToken,
            ecn: this.session.ecn,
            billingAccountNumber: this.session.billingAccountNumber
        }).pipe(
            tap((result) => this.paymentPreferencesState.updateSingleSavedPaymentMethod(result)),
            catchError(() => of(null))
        );
    }

    fetchAutomaticPayments(): Observable<IsbExperience.GetPayPlanResponse> {
        return this.isbExperience.getPayPlan({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            agreementNumber: this.session.billingAccountNumber,
            requestType: 'billingMethod'
        }).pipe(
            tap((response) => this.paymentPreferencesState.automaticPayments = response),
            catchError(() => of(null))
        );
    }

    fetchPaymentHistory(startDate: Date, endDate: Date): Observable<IsbExperience.RetrievePaymentsResponse> {
        return this.isbExperience.getPayments({
            accessToken: this.session.accessToken,
            accountNumber: this.session.billingAccountNumber,
            billingSystem: this.session.billingSystem,
            sessionId: this.session.a2aSessionId,
            startDate: this.dateUtil.formatDateToString(startDate, '-'),
            endDate: this.dateUtil.formatDateToString(endDate, '-'),
            policyNumbers: this.session.policyNumbers
        }).pipe(
            tap((response) => this.paymentPreferencesState.paymentsHistory = response),
            catchError(() => of(null))
        );
    }

    fetchSavedRefundMethod(): Observable<PersonalBilling.RefundMethod> {
        return this.isbExperience.getPayPlan({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            agreementNumber: this.session.billingAccountNumber,
            requestType: 'refundMethod'
        }).pipe(
            map((response) => response.refundMethod),
            tap((response) => this.paymentPreferencesState.refundMethod = <any>response),
            catchError(() => of(null))
        );
    }

    fetchInternetRegistrationResponse(): Observable<ECIM.EnterpriseCustomerInformationManagementResponse> {
        return this.ecimService.getInternetRegistrations({
            accessToken: this.session.accessToken,
            guid: this.session.guid
        }).pipe(
            tap((response) => this.paymentPreferencesState.internetRegistrationResponse = response),
            catchError(() => of(null))
        );
    }

    getAgreement(): Observable<ECIM.Agreement> {
        return this.ecimService.getAgreement({
            accessToken: this.session.accessToken,
            guid: this.session.guid,
            accountNumber: this.session.billingAccountNumber,
            sourceSystem: this.session.sourceSystem
        }).pipe(
            tap((response) => this.paymentPreferencesState.agreementResponse = <any>response),
            catchError(() => of(null))
        );
    }

    retrieveEligiblePayPlan(): Observable<IsbExperience.PayPlanEligibleResponse> {
        return this.isbExperience.retrieveEligiblePayPlan({
            accessToken: this.session.accessToken,
            agreementNumber: this.session.billingAccountNumber,
            billingSystem: this.session.billingSystem
        }).pipe(
            tap((response) => this.paymentPreferencesState.payPlanEligible = response),
            catchError(() => of(null))
        );
    }

    calculateDownPayment(calculateDownPaymentRequestParams: IsbCalculateDownPaymentRequestParams): Observable<IsbExperience.CalculateDownPaymentResponse> {
        return this.isbExperience.calculateDownPayment(calculateDownPaymentRequestParams)
            .pipe(
                tap((response) => this.paymentPreferencesState.calculateDownPayment = response),
                catchError(() => of(null))
            );
    }

    fetchBills(): Observable<IsbExperience.Bill[]> {
        return this.isbExperience.getBills({
            agreementNumber: this.session.billingAccountNumber,
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            query: {
                nbrNextBills: 1
            }
        }).pipe(
            map((billsResponse: IsbExperience.GetBillsResponse) => {
                const bills = billsResponse?.[0]?.bills;
                this.paymentPreferencesState.bills = bills;
                return bills;
            }),
            catchError(() => of(null))
        );
    }

    fetchRelatedPolicies(personalBillingRequestParams: PersonalBillingRequestParams): Observable<PersonalBilling.Policy[]> {
        return this.personalBilling.getRelatedPolicies(personalBillingRequestParams).pipe(
            map((policies) => {
                this.paymentPreferencesState.relatedPolicies = policies.relatedPolicies;
                return policies.relatedPolicies;
            }),
            catchError(() => of(null))
        );
    }
}
