import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BankAccountPaymentItem } from '../../../../../../billing/payment-preferences/shared/models/bankaccount-item.model';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { CardType } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { cardUtils } from '../../../../../../shared/payments/card-utils/card-utils';
import { PaymentFormControls } from '../../../../../../shared/payments/payment-form-controls';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { UnitedStatesNameService } from '../../../../../../shared/payments/united-state-name.service';
import { EditPaymentMethodFlowService } from '../edit-payment-method-flow.service';

@Injectable()
export class EditPaymentMethodReview {
    // eslint-disable-next-line max-params
    constructor(
        private editPaymentMethodFlow: EditPaymentMethodFlowService,
        private titlecasePipe: TitleCasePipe,
        private stateNames: UnitedStatesNameService,
        private paymentFormControls: PaymentFormControls
    ) { }

    initialize(): void {
        if (this.editPaymentMethodFlow.flowInPlay) {
            this.addEmailControlToForm();
        }
    }

    addEmailControlToForm(): void {
        this.editPaymentMethodForm.group.addControl(
            'emailAddressGroup',
            this.paymentFormControls.newEmailFormGroup(this.editPaymentMethodFlow.emailAddress)
        );
    }

    save(params: Partial<EditPaymentMethodFlowService>): void {
        this.editPaymentMethodFlow.save(params);
    }

    get editPaymentDetailsReviewFields(): RecapFormField[] | BankcardRecapFormField[] {
        const fields = [];
        if (this.isBankCard) {
            fields.push({
                id: 'card-holder-name-recap',
                label: `Card holder's name`,
                value: this.displayName
            });

            fields.push({
                id: 'card-type',
                label: 'Card type',
                value: this.savedCardType
            });

            fields.push({
                id: 'card-number-recap',
                label: 'Card number',
                value: `************${this.cardNumberLastFour}`,
                cardType: this.savedCardType
            });

            fields.push({
                id: 'expiration-date-recap',
                label: 'Expiration date',
                value: this.cardExpirationDate
            });

            fields.push({
                id: 'zip-recap',
                label: 'ZIP',
                value: this.zip
            });

            fields.push({
                id: 'default-payment-method-recap',
                label: 'Make this your default payment method',
                value: this.defaultPaymentMethod
            });
        } else if (this.isBankAccount) {
            fields.push({
                id: 'account-type-recap',
                label: 'Account type',
                value: this.titlecasePipe.transform(this.accountType)
            });

            fields.push({
                id: 'account-holders-name-recap',
                label: `Account holder's name`,
                value: this.displayName
            });

            fields.push({
                id: 'account-holders-address-recap',
                label: `Account holder's address`,
                value: `${this.street} ${this.apartmentNumber}`
            });

            fields.push({
                id: 'city-state-zip-recap',
                label: 'City, state and ZIP',
                value: `${this.city}, ${this.stateName} ${this.zip}`
            });

            fields.push({
                id: 'bank-routing-number-recap',
                label: 'Bank routing number',
                value: this.routingNumber
            });

            fields.push({
                id: 'bank-name-recap',
                label: 'Bank name',
                value: this.bankName
            });

            fields.push({
                id: 'account-number-recap',
                label: 'Account number',
                value: `*********${this.accountNumberLastThree}`
            });

            fields.push({
                id: 'default-payment-method-recap',
                label: 'Make this your default payment method',
                value: this.defaultPaymentMethod
            });
        }
        return fields;
    }

    get considerThisReviewFields(): RecapFormField[] {
        const fieldsArray = [];
        const easyPay = {
            id: 'enroll-in-ez-pay-recap',
            label: 'Would you like to enroll in Automatic payments?',
            value: this.enrollingInEasyPay
        };
        const paperLess = {
            id: 'enroll-in-paperless-recap',
            label: 'Would you like to enroll in paperless for all of your accounts and policies?',
            value: this.enrollingInPaperless
        };
        if (this.editPaymentMethodFlow.isEasyPayEligible && this.editPaymentMethodFlow.isPaperlessEligible) {
            fieldsArray.push(easyPay, paperLess);
        } else if (this.editPaymentMethodFlow.isEasyPayEligible &&
            !this.editPaymentMethodFlow.isPaperlessEligible) {
            fieldsArray.push(easyPay);
        } else if (this.editPaymentMethodFlow.isPaperlessEligible) {
            fieldsArray.push(paperLess);
        }
        if (this.enrollingInPaperless === 'Yes') {
            fieldsArray.push({
                id: 'enroll-in-paperless',
                label: 'Payment confirmation and paperless email',
                value: this.editPaymentMethodFlow.paperlessEmail || this.editPaymentMethodFlow.emailAddress
            });
        }
        return fieldsArray;
    }

    get considerThisDisplayable(): boolean {
        return this.editPaymentMethodFlow.considerThisDisplayable;
    }

    get editPaymentMethodForm(): PaymentFormGroup {
        return this.editPaymentMethodFlow.editPaymentMethodForm;
    }

    get savePayMethodAsDefault(): boolean {
        return this.editPaymentMethodForm.makeDefaultPaymentMethod;
    }

    get isSelectedPayMethodAlreadyDefault(): boolean {
        return this.editPaymentMethodFlow.selectedPaymentMethod.isDefault;
    }

    get isRecurringMethod(): boolean {
        return this.editPaymentMethodFlow.selectedPaymentMethod.recurringMethod;
    }

    get firstName(): string {
        return this.editPaymentMethodForm.firstName;
    }

    get middleInitial(): string {
        return this.editPaymentMethodForm.middleInitial;
    }

    get lastName(): string {
        return this.editPaymentMethodForm.lastName;
    }

    get emailAddress(): string {
        return this.enrollingInPaperless === 'Yes' && this.editPaymentMethodFlow.paperlessEmail ? this.editPaymentMethodFlow.paperlessEmail : this.editPaymentMethodForm.emailAddress;
    }

    get displayName(): string {
        return this.middleInitial ?
            `${this.firstName} ${this.middleInitial} ${this.lastName}` :
            `${this.firstName} ${this.lastName}`;
    }

    get accountType(): string {
        return this.editPaymentMethodForm.bankAccountType;
    }

    get street(): string {
        return this.editPaymentMethodForm.street;
    }

    get apartmentNumber(): string {
        return this.editPaymentMethodForm.apartmentNumber;
    }

    get city(): string {
        return this.editPaymentMethodForm.city;
    }

    get stateName(): string {
        const abbreviation = this.editPaymentMethodForm.state;
        return this.stateNames.convertAbbreviationToName(abbreviation);
    }

    get stateAbbreviated(): string {
        let abbreviated: string;
        if (this.isBankAccount) {
            abbreviated = this.editPaymentMethodForm.state.toUpperCase();
        }
        return abbreviated || '';
    }

    get zip(): string {
        return this.editPaymentMethodForm.zip;
    }

    get isBankCard(): boolean {
        return this.editPaymentMethodFlow.selectedPaymentMethod.isBankCard;
    }

    get paymentMethodId(): string {
        return this.editPaymentMethodFlow.selectedPaymentMethod.id || '';
    }

    get cardNumber(): string {
        return this.editPaymentMethodForm.bankCardNumber;
    }

    get cardNumberLastFour(): string {
        const cardNumber = this.editPaymentMethodForm.bankCardNumber;
        const CARD_LAST_FOUR = 4;
        return cardNumber.slice(cardNumber.length - CARD_LAST_FOUR);
    }

    get savedCardType(): CardType {
        return <CardType> this.editPaymentMethodFlow.selectedPaymentMethod.methodType;
    }

    get cardExpirationMonth(): string {
        return this.editPaymentMethodForm.cardExpirationMonth;
    }

    get cardExpirationYear(): string {
        return this.editPaymentMethodForm.cardExpirationYear;
    }

    get cardExpirationDate(): string {
        return `${this.cardExpirationMonth}/${this.cardExpirationYear}`;
    }

    get cvvLabel(): string {
        return this.isAmexCard ? 'CID' : 'CVV';
    }

    get maskedCvv(): string {
        return this.isAmexCard ? '****' : '***';
    }

    get cvv(): string {
        return this.editPaymentMethodForm.bankCardCvv;
    }

    get isAmexCard(): boolean {
        const cardNumber = this.editPaymentMethodForm.bankCardNumber;
        const cardType = cardUtils.parseType(cardNumber);
        return cardType === CardType.AMERICAN_EXPRESS;
    }

    get defaultPaymentMethod(): string {
        let isDefaultPaymentMethod = 'No';
        if (this.editPaymentMethodForm.makeDefaultPaymentMethod) {
            isDefaultPaymentMethod = 'Yes';
        }
        return isDefaultPaymentMethod;
    }

    get isBankAccount(): boolean {
        return this.editPaymentMethodFlow.selectedPaymentMethod.isBankAccount;
    }

    get bankName(): string {
        return this.editPaymentMethodForm.bankName;
    }

    get accountNumberLastThree(): string {
        const bankAccountNumber = this.editPaymentMethodForm.bankAccountNumber;
        const ACCOUNT_LAST_THREE = 3;
        return bankAccountNumber.slice(bankAccountNumber.length - ACCOUNT_LAST_THREE);
    }

    get routingNumber(): string {
        return this.editPaymentMethodForm.bankRoutingNumber;
    }

    get accountNumber(): string {
        return this.editPaymentMethodForm.bankAccountNumber;
    }

    get encryptedBankAccountNumber(): string {
        return this.editPaymentMethodFlow.selectedPaymentMethod.item as unknown as BankAccountPaymentItem['encryptedAccountNumber'];
    }

    get enrollingInEasyPay(): string {
        const enrollInEasyPay = this.editPaymentMethodFlow.editPaymentMethodForm.enrollInEasyPay;
        return this.titlecasePipe.transform(enrollInEasyPay) || 'No';
    }

    get enrollingInPaperless(): string {
        const enrollInPaperless = this.editPaymentMethodFlow.editPaymentMethodForm.enrollInPaperless;
        return this.titlecasePipe.transform(enrollInPaperless);
    }

    get payorInfo(): any {
        return {
            firstName: this.firstName,
            middleName: this.middleInitial,
            lastName: this.lastName,
            displayName: this.displayName,
            addressLine1: this.isBankAccount ? this.street : '',
            addressLine2: this.isBankAccount ? this.apartmentNumber : '',
            city: this.isBankAccount ? this.city : '',
            state: this.isBankAccount ? this.stateAbbreviated : '',
            postalCode: this.zip,
            emailAddress: this.emailAddress,
            phone: ''
        };
    }
}
