import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APICommonService } from '@nationwide/internet-servicing-angular-services';
import {
    PBS,
    PbsService
} from '@nationwide/dgs-angular-billing-common';
import {
    catchError, map, Observable, of, shareReplay,
    tap, timeout
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PaymentFlowType } from '../../../pay-bill/shared/payment-flow-type.service';
import { LoggerService } from '../../logger/logger.service';
import { SessionService } from '../../session/session.service';

@Injectable({
    providedIn: 'root'
})
export class HttpCachingService {
    private retrieveCustomerAgreement$: Observable<PBS.RetriveCustomerAgreementResponse>;
    private rcaResponse: PBS.RetriveCustomerAgreementResponse;

    private DISALLOWED_URLS = [
        '/personal/billing/landing',
        '/personal/billing/picker'
    ];

    // eslint-disable-next-line max-params
    constructor(
        private sessionService: SessionService,
        private paymentFlowType: PaymentFlowType,
        private pbsService: PbsService,
        private apiCommon: APICommonService,
        @Inject('logger') private logger: LoggerService,
        private router: Router
    ) {
        if (this.isPBSCallAllowed()) {
            this.retrieveCustomerAgreement$ = this.setupRetrieveCustomerAgreement();
        }
    }

    clearCache(): void {
        if (this.sessionService.billingAccountNumberForRCA !== 'NA') {
            this.retrieveCustomerAgreement$ = this.refreshRCAData();
        }
    }

    fetchRetrieveCustomerAgreementResponse(): Observable<PBS.RetriveCustomerAgreementResponse> {
        if (!this.retrieveCustomerAgreement$ && this.isPBSCallAllowed()) {
            this.retrieveCustomerAgreement$ = this.setupRetrieveCustomerAgreement();
        }
        return this.retrieveCustomerAgreement$;
    }

    private setupRetrieveCustomerAgreement(): Observable<PBS.RetriveCustomerAgreementResponse> {
        const pbsCall = this.paymentFlowType?.isSplitPartyUser ? this.pbsService.retrieveSplitPartyCustomerAgreement(this.pbsRequestParams()) :
            this.pbsService.retrieveCustomerAgreement(this.pbsRequestParams());
        return pbsCall.pipe(
            timeout(environment.TIMEOUT.apiCallDuration.pbs),
            tap((rcaResponse) => {
                this.sessionService.intitialize({ rcaResponse });
                this.rcaResponse = rcaResponse;
            }),
            catchError((err) => {
                this.logger.error('API ERROR: RETRIEVE CUSTOMER AGREEMENT', err);
                return of(null);
            }),
            shareReplay(1)
        );
    }

    private pbsRequestParams(): any {
        return {
            isFiredOnMAPLanding: true,
            agreementNumber: this.sessionService.billingAccountNumberForRCA,
            sessionId: this.sessionService.a2aSessionId,
            accessToken: this.sessionService.accessToken,
            transactionId: this.apiCommon.generateTransactionId()
        };
    }

    private refreshRCAData(): Observable<PBS.RetriveCustomerAgreementResponse> {
        return this.pbsService.retrieveCustomerAgreementWithRefresh({
            isFiredOnMAPLanding: false,
            agreementNumber: this.sessionService.billingAccountNumberForRCA,
            sessionId: this.sessionService.a2aSessionId,
            accessToken: this.sessionService.accessToken,
            transactionId: this.apiCommon.generateTransactionId()
        })
            .pipe(
                map((rcawrResponse) => {
                    rcawrResponse = this.mapRcawrToRcaResponse(this.rcaResponse, rcawrResponse);
                    return rcawrResponse;
                }),
                tap((rcaResponse) => {
                    this.sessionService.intitialize({ rcaResponse });
                }),
                timeout(environment.TIMEOUT.apiCallDuration.pbs),
                catchError((err) => {
                    this.logger.error('API ERROR: RETRIEVE CUSTOMER AGREEMENT WITH REFRESH', err);
                    return of(null);
                }),
                shareReplay(1)
            );
    }

    // RCAWR only returns the agreement info for the agreement passed in, so we have to update the original RCA response to keep the other agreement info intact
    private mapRcawrToRcaResponse(rcaResponse: PBS.RetriveCustomerAgreementResponse, rcawrResponse: PBS.RetriveCustomerAgreementResponse): PBS.RetriveCustomerAgreementResponse {
        const mappedRcaResponse = rcaResponse;
        let rcaAgreementIndex: number;

        if (rcawrResponse != null && rcaResponse != null) {
            const rcawrAgreements = this.getAgreements(rcawrResponse);
            const rcaAgreements = this.getAgreements(mappedRcaResponse);

            rcawrAgreements.forEach((rcawrAgreement) => {
                rcaAgreementIndex = rcaAgreements.findIndex((rcaAgreement) => rcaAgreement.refId === rcawrAgreement.refId);

                if (rcaAgreementIndex > -1) {
                    rcaAgreements[rcaAgreementIndex] = rcawrAgreement;
                } else {
                    rcaAgreements.push(rcawrAgreement);
                }
            });

            mappedRcaResponse.retrieveCustomerAgreementResponse.return.profile.agreementGroup.agreement = rcaAgreements;
        }

        return mappedRcaResponse;
    }

    private getAgreements(rcaResponse): PBS.Agreement[] {
        const agreements = rcaResponse.retrieveCustomerAgreementResponse.return.profile
            .agreementGroup.agreement;
        return agreements instanceof Array ? agreements : [agreements];
    }

    private isPBSCallAllowed(): boolean {
        return this.sessionService.billingAccountNumberForRCA !== 'NA' && this.isUrlAllowed();
    }

    private isUrlAllowed(): boolean {
        return !this.DISALLOWED_URLS.find((url) => this.router.url.includes(url));
    }

    getRetrieveCustomerAgreement(): Observable<PBS.RetriveCustomerAgreementResponse> {
        return this.retrieveCustomerAgreement$;
    }

    setRetrieveCustomerAgreement(observable: Observable<PBS.RetriveCustomerAgreementResponse>): void {
        this.retrieveCustomerAgreement$ = observable;
    }
}
