import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    CardEncryptionHelperService, EncryptBankCardResponse, IsbExperienceService, isbExperienceServiceToken, MoneyProcessingService,
    PayorInfo
} from '@nationwide/dgs-angular-billing-common';
import { BankCard } from '@nationwide/dgs-angular-billing-common/services/internet-servicing-billing-experience/isb-personal-lines-billing-request-params';
import { catchError, flatMap, forkJoin, map, Observable, of, tap } from 'rxjs';
import { DistributionPartnerRoleInfoAdapter } from '../../../../../../billing/shared/distribution-partner-role-info/distribution-partner-role-info.adapter';
import { DistributionPartnerRoleInfoService } from '../../../../../../billing/shared/distribution-partner-role-info/distribution-partner-role-info.service';
import { PreferenceCenterPaperlesService } from '../../../../../../pay-bill/shared/preference-center-paperless.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { encryptionCardTypeMap, submitPaymetCardTypeMap } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { SessionService } from '../../../../../../shared/session/session.service';
import { PaperlessEnrollmentBaseService } from '../../../../shared/services/paperless-enrollment-error.service';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { EditAutomaticPaymentsReview } from './edit-automatic-payments-review.model';
@Injectable()
export class EditAutomaticPaymentsReviewService extends PaperlessEnrollmentBaseService {
    // eslint-disable-next-line max-params
    constructor(
        private editPaymentFlowService: EditAutomaticPaymentFlowService,
        protected sessionService: SessionService,
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private review: EditAutomaticPaymentsReview,
        private datePipe: DatePipe,
        private cardEncryption: CardEncryptionHelperService,
        private moneyProcessing: MoneyProcessingService,
        @Inject('logger') private logger: LoggerService,
        @Inject(PreferenceCenterPaperlesService) private preferencePaperlesService: PreferenceCenterPaperlesService,
        // @Inject(PreferenceCenterAdapter) private preferenceCenterAdapter: PreferenceCenterAdapter,
        private distributionPartnerRoleInfoService: DistributionPartnerRoleInfoService,
        private distributionPartnerRoleInfoAdapter: DistributionPartnerRoleInfoAdapter
    ) {
        super(sessionService);
    }

    submit(): Observable<EditAutomaticPaymentResponse> {
        const encryptCardCall = this.review.isBankCardFlow ? this.encryptCard() : this.requestPlaceHolder();
        return encryptCardCall.pipe(
            flatMap((encryptCardResponse) =>
                forkJoin({
                    updateAutomaticPaymentMethod: this.updateAutomaticPaymentMethod(encryptCardResponse),
                    distributionPartnerRoleInfoResponse: this.distributionPartnerRoleInfoService.fetchDistributionPartnerRoleInfo()
                })
            ),
            tap((responses) => {
                this.distributionPartnerRoleInfoAdapter.mapDistributionPartnerRoleResponse(responses.distributionPartnerRoleInfoResponse);
            }),
            flatMap((previousResponses) =>
                forkJoin({
                    updateAutomaticPaymentMethod: of(previousResponses.updateAutomaticPaymentMethod),
                    esddaPreferences: this.review.enrollingInPaperless === 'Yes' || this.review.enrollingInPaperless === 'No' ?
                        this.enrollInPaperless() : this.requestPlaceHolder(),
                    nbpEmailSentSuccessfully: this.sessionService.billingSystem === 'NBP' ? this.sendNbpEmail() : this.requestPlaceHolder()

                })
            )
        );
    }

    enrollInPaperless(): Observable<boolean> {
        this.review.save({ paperlessEnrollmentAttempted: true });
        return this.preferencePaperlesService.saveEsddaPreferences(this.review.emailAddress, this.review.enrollingInPaperless).pipe(
            tap((response) => {
                if (response) {
                    this.review.save({
                        savedPaperlessEsddaPrefs: true, enrolledInPaperless: true,
                        editAutomaticPaymentResponse: {
                            esddaPreferences: true
                        }
                    });
                } else {
                    this.review.save({
                        savedPaperlessEsddaPrefs: false, enrolledInPaperless: false,
                        editAutomaticPaymentResponse: {
                            esddaPreferences: false
                        }
                    });
                }
            }),
            catchError(() => of(false))
        );
    }

    sendNbpEmail(): Observable<boolean> {
        let response: Observable<boolean>;

        if (this.editPaymentFlowService.successfullyUpdatedEnrollment) {
            response = this.isbExperience.sendEditAutomaticPaymentsEmail({
                accessToken: this.sessionService.accessToken,
                billingSystem: this.sessionService.billingSystem,
                omsData: {
                    omsServiceTransformationStrategyData: {
                        servicePlusIndicator: this.sessionService.dpimServicePlusIndicator,
                        agentPhoneNumber: this.sessionService.dpimAgentPhoneNumber
                    }
                }
            }).pipe(
                map(() => true),
                catchError(() => of(false))
            );
        } else {
            response = this.requestPlaceHolder();
        }

        return response;
    }

    private encryptCard(): Observable<EncryptBankCardResponse | HttpErrorResponse> {
        return this.cardEncryption.encryptBankCard({
            accessToken: this.sessionService.accessToken,
            billingSystem: this.sessionService.billingSystem,
            payload: {
                cardType: encryptionCardTypeMap.get(this.review.cardType),
                cardNumber: this.review.cardNumber,
                cardholderName: `${this.review.firstName} ${this.review.lastName}`,
                billingAddress1: this.review.street,
                billingAddress2: '',
                billingCity: this.review.city,
                billingState: this.review.stateName,
                billingZIP: this.review.zip,
                billingCountryCode: 'US',
                expirationDate: `${this.review.cardExpirationYear}${this.review.cardExpirationMonth}`
            }
        }).pipe(
            catchError((err) => of(err))
        );
    }

    private updateAutomaticPaymentMethod(encryptCardResponse: EncryptBankCardResponse | HttpErrorResponse): Observable<any> {
        let response: Observable<any>;

        if (encryptCardResponse instanceof HttpErrorResponse) {
            response = of(encryptCardResponse);
            this.editPaymentFlowService.successfullyUpdatedEnrollment = false;
        } else {
            response = this.billingService.patchPayPlan({
                agreementNumber: this.sessionService.billingAccountNumber,
                accessToken: this.sessionService.accessToken,
                billingSystem: this.sessionService.billingSystem,
                payload: {
                    billingPayload: {
                        enterpriseCustomerNumber: this.sessionService.ecn,
                        requestType: 'BillingMethodChange.PayPlanUpdate',
                        action: 'update',
                        currentBillingMethodType: this.editPaymentFlowService.currentBillingMethodType,
                        newBillingMethodType: this.review.isBankCardFlow ? 'Recurring Bankcard' : 'Recurring EFT',
                        accountDueDate: this.datePipe.transform(this.editPaymentFlowService.nextBillDueDate, 'MM-dd-yyyy', 'UTC'),
                        paymentMethod: {
                            payorInfo: this.sessionService.billingSystem === 'SAP Billing' || this.review.isBankAccountFlow ? undefined : this.payorInfo,
                            paymentMethodType: this.review.isBankCardFlow ?
                                'BankCard.PaymentMethod' : 'ElectronicFundsTransfer.PaymentMethod',
                            description: 'UNSET',
                            electronicFundsTransfer: this.review.isBankAccountFlow ? {
                                bankName: this.review.bankName,
                                bankRoutingNumber: this.review.routingNumber,
                                bankAccountNumber: this.review.bankAccountNumber,
                                bankAccountType: this.review.accountType,
                                encryptedBankAccountNumber: this.review.bankAccountNumber,
                                maskedBankAccountNumber: this.review.accountNumberLastThree
                            } : undefined,
                            bankCard: this.review.isBankCardFlow ? this.getBankCardDetails(encryptCardResponse) : undefined
                        }
                    }
                }
            }).pipe(
                tap(() => this.editPaymentFlowService.successfullyUpdatedEnrollment = true),
                catchError((err) => {
                    this.logger.error('API ERROR: EDIT AUTOMATIC PAYMENTS SUBMISSION', err);
                    this.editPaymentFlowService.save({
                        successfullyUpdatedEnrollment: false, errorDetails: {
                            errorMessage: err.error?.developerMessage,
                            errorCode: err.status
                        }
                    });
                    return of(err);
                })
            );
        }

        return response;
    }

    private getBankCardDetails(encryptResponse): BankCard {
        const bankCardParams: BankCard = {
            cardBrand: <any>submitPaymetCardTypeMap.get(this.review.cardType),
            expirationDate: this.fetchExpirationDate(this.review.cardExpirationYear, this.review.cardExpirationMonth),
            encryptedPan: encryptResponse.encryptedNumber,
            encryptedPanKey: encryptResponse.sessionKey,
            encryptedPanPubKeyFingerPrint: encryptResponse.recipientCertificateFingerprint,
            ccLastFour: encryptResponse.last4Digits
        };
        if (this.sessionService.billingSystem === 'NBP') {
            bankCardParams.encryptedPan = '';
            bankCardParams.profileId = encryptResponse.encryptedNumber;
        }
        return bankCardParams;
    }

    private requestPlaceHolder(): Observable<null> {
        return of(null);
    }

    get payorInfo(): PayorInfo {
        return {
            firstName: this.review.firstName,
            middleName: this.review.middleInitial,
            lastName: this.review.lastName,
            fullName: this.review.payorName,
            addressLine1: this.review.street,
            addressLine2: this.review.apartmentNumber,
            city: this.review.city,
            state: this.review.stateName,
            postalCode: this.review.zip
        };
    }

    private get billingService(): IsbExperienceService | MoneyProcessingService {
        const service: IsbExperienceService | MoneyProcessingService = this.sessionService.billingSystem === 'NBP' ? this.moneyProcessing : this.isbExperience;
        return service;
    }

    private fetchExpirationDate(cardExpirationYear: string, cardExpirationMonth: string): string {
        return this.sessionService.isSAP ?
            `${cardExpirationYear}${cardExpirationMonth}` : `${cardExpirationYear}-${cardExpirationMonth}`;
    }
}

export interface EditAutomaticPaymentResponse {
    updateAutomaticPaymentMethod?: boolean | HttpErrorResponse | null;
    updateEmailAddress?: boolean | null;
    nbpEmailSentSuccessfully?: boolean | null;
    esddaPreferences?: boolean | null;
}
