<div class="confirmation-details">
    <div *ngIf="successfulRefundMethodEnrollments.length > 0" id="successful-refund-confirmation-details" class="bolt-row bolt-space-bottom-2xl">
        <div class="bolt-col">
            <strong>Refund method applies to billing account(s):</strong>
        </div>
        <div class="bolt-col">
            {{successfulRefundMethodEnrollments.join(', ')}}
        </div>
    </div>

    <div *ngIf="unsuccessfulRefundMethodEnrollments.length > 0" class="bolt-space-bottom-2xl" id="unsuccessful-refund-confirmation-details">
        <bw-bolt-notification type="error">
            We are unable to process your refund method for billing account(s)
            {{unsuccessfulRefundMethodEnrollments.join(', ')}}.
            Please try choosing your refund method again at a later time.
        </bw-bolt-notification>
    </div>
</div>