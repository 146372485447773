import { environment as baseEnvironment } from './environment.base';

const loggingOverride = {
    ...baseEnvironment.LOGGING,
    ...{
        clientLoggingEnabled: true,
        envName: 'st'
    }
};

const BAMNROverride = 'assets/js/bam-nr/sb-st-bamnr.js';

const contentfulStConfiguration = {
    space: 'nqy1g7ce3sxy',
    environment: 'test',
    accessToken: '5d80RdneQMwypIVJtYaQHwGOK9CMpfMxHjDUt74NIGQ',
    contentfulDeliveryAccessToken: '5d80RdneQMwypIVJtYaQHwGOK9CMpfMxHjDUt74NIGQ',
    contentfulPreviewAccessToken: 'oI1UTuGQfqj9bjpRIler8V86EPLMtMw2mjCUgFxVOAE'
};

const overrides = {
    LOGGING: loggingOverride,
    BAMNR: BAMNROverride,
    contentful: contentfulStConfiguration
};

export const environment = { ...baseEnvironment, ...overrides };
