import { Injectable } from '@angular/core';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { EditPaymentMethodFlowService } from '../edit-payment-method-flow.service';
import { PaperlessEnrollmentBaseService } from '../../../../shared/services/paperless-enrollment-error.service';
import { SessionService } from '../../../../../../shared/session/session.service';

@Injectable()
export class EditPaymentMethodConfirmationService extends PaperlessEnrollmentBaseService {
    private readonly UP_NEXT_TAB_LABELS = {
        PAPERLESS_ENROLLMENT: 'Paperless enrollment',
        AUTOMATIC_PAYMENTS: 'Automatic payments',
        PAYMENT_PREFERENCES: 'Payment preferences'
    };
    private readonly MASKED_NUMBERS = {
        BANK_ACCOUNT: 3,
        BANK_CARD: 4
    };
    private _upNextLabel: string;
    constructor(private readonly editPaymentMethodFlow: EditPaymentMethodFlowService, protected session: SessionService
    ) {
        super(session);
    }

    setUpNextLabel(): void {
        if (this.navigateToEasyPay) {
            this._upNextLabel = this.UP_NEXT_TAB_LABELS.AUTOMATIC_PAYMENTS;
        } else {
            this._upNextLabel = this.UP_NEXT_TAB_LABELS.PAYMENT_PREFERENCES;
        }
    }

    get upNextLabel(): string {
        this._upNextLabel = '';
        if (this.editPaymentMethodFlow.paymentMethodSaved === true) {
            this.setUpNextLabel();
        }
        return this._upNextLabel;
    }

    get navigateToPaperless(): boolean {
        return this.editPaymentMethodForm.enrollInPaperless === 'yes';
    }

    get navigateToEasyPay(): boolean {
        return this.editPaymentMethodForm.enrollInEasyPay === 'yes';
    }

    get editPaymentMethodForm(): PaymentFormGroup {
        return this.editPaymentMethodFlow.editPaymentMethodForm;
    }

    get maskedNumber(): string {
        let bankNumber;
        if (this.editPaymentMethodFlow.selectedPaymentMethod.isBankAccount) {
            bankNumber = this.editPaymentMethodFlow.editPaymentMethodForm.bankAccountNumber;
            bankNumber = `*********${bankNumber.slice(bankNumber.length - this.MASKED_NUMBERS.BANK_ACCOUNT)}`;
        } else if (this.editPaymentMethodFlow.selectedPaymentMethod.isBankCard) {
            bankNumber = this.editPaymentMethodFlow.editPaymentMethodForm.bankCardNumber;
            bankNumber = `************${bankNumber.slice(bankNumber.length - this.MASKED_NUMBERS.BANK_CARD)}`;
        }
        return bankNumber;
    }
}
