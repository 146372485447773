<fieldset class="bolt-row zip-code-fieldset" [formGroup]="formGroup">
    <legend class="fields-only"></legend>
    <div class="bolt-col-sm-12 bolt-col-md-12">
        <bolt-textfield type="text" ngDefaultControl id="zip-code" formControlName="zip" label="ZIP" required="true" maxlength="5" inputsize="8" minlength="5" ariaLabel="ZIP"
            [attr.invalid]="zipErrorsDisplayable ? true : null" ariadescribedby="zip-code-errors"></bolt-textfield>
        <app-form-control-error id="zip-code-errors" *ngIf="zipErrorsDisplayable">
            <ul class="error-list">
                <li *ngIf="zip.errors['required']">ZIP code is required.</li>
                <li *ngIf="(zip.errors['containsOnlyDigits'] || zip.errors['minlength']) && !zip.errors['required']">ZIP code is
                    invalid.</li>
            </ul>
        </app-form-control-error>
    </div>
</fieldset>