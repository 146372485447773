import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Injectable()
export class SafeCurrencyPipe {
    constructor(
        private currencyPipe: CurrencyPipe
    ) { }

    transformToCurrency(value: any): any {
        value = value?.trim ? value.trim() : value;
        let currency = value;
        const numberCheck = /^[\d]+\.?[\d]*$/;
        if (value && numberCheck.test(value)) {
            currency = this.currencyPipe.transform(value, 'USD', 'symbol-narrow', '1.2-2');
        }
        return currency;
    }
}
