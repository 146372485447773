import { BankAccountPaymentItem } from './bankaccount-item.model';

export class NewBankAccountMethod extends BankAccountPaymentItem {
    constructor() {
        super(
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        );
    }
}
