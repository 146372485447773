import { UntypedFormGroup, AbstractControl, UntypedFormArray } from '@angular/forms';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';

@Injectable()
export class FormUtil {
    constructor(
        @Inject('logger') private logger: LoggerService
    ) { }
    touchAllFormControls(formGroup: UntypedFormGroup, isMAPLanding?: boolean): void {

        (<any>Object)
            .keys(formGroup.controls)
            .map((controlName) => formGroup.controls[controlName])
            .forEach((control) => {
                control.markAsTouched();

                if (control['controls']) {
                    this.touchAllFormControls(control);
                    if (isMAPLanding) {
                        this.logErrorMsgForRequiredFields(control['controls']);
                    }
                }
            });
    }

    setFieldForTests(
        formGroup: UntypedFormGroup | AbstractControl,
        fieldName: string,
        value?: string | number
    ): void {
        if (formGroup?.get(fieldName)) {
            if (value !== null && value !== '' && value !== undefined) {
                formGroup.get(fieldName).setValue(value);
            }
            formGroup.get(fieldName).markAsDirty();
            formGroup.get(fieldName).markAsTouched();
        }
    }

    emptyFormArray(formArray: UntypedFormArray): void {
        if (formArray?.controls) {
            for (let i = formArray.controls.length - 1; i >= 0; i--) {
                formArray.removeAt(i);
            }
        }
    }

    logErrorMsgForRequiredFields(control: AbstractControl): void {
        for (const controlName of Object.entries(control)) {
            if (controlName[1].controls) {
                for (const [childControlName, childControl] of <any>Object.entries(controlName[1].controls)) {
                    if (childControl?.controls) {
                        const childControlInside = childControl.controls;
                        for (const [childControlName] of <any>Object.entries(childControlInside)) {
                            if (childControlInside.status === 'INVALID') {
                                this.logger.info(`REQUIRED FIELD ON MAP LANDING PAGE IS INVALID - ${childControlName}`);
                            }
                        }
                    } else if (childControl.status === 'INVALID') {
                        this.logger.info(`REQUIRED FIELD ON MAP LANDING PAGE IS INVALID - ${childControlName}`);
                    }
                }
            }
            if (controlName[1].status === 'INVALID') {
                this.logger.info(`REQUIRED FIELD ON MAP LANDING PAGE IS INVALID - ${controlName[0]}`);
            }
        }
    }
}
