import { BillingHistoryItemType } from './billing-history-item-type.enum';
import { DocOrigin } from './billing-history.adapter';

export class BillingHistoryItem {
    // eslint-disable-next-line max-params
    constructor(
        readonly statementId: string,
        readonly documentType: BillingHistoryItemType,
        readonly mimeType: string,
        readonly policyNumber: number,
        readonly billingAccount: number,
        readonly origin: DocOrigin,
        readonly effectiveDate?: Date,
        readonly amountDue?: number,
        readonly version?: string,
        readonly reportId?: string,
        readonly sectionId?: string
    ) { }

    get dueDate(): Date {
        return this.documentType ===
            BillingHistoryItemType.BILL || this.documentType === BillingHistoryItemType.ADJ ? this.effectiveDate : undefined;
    }
}
