import { ISBRouteParams } from '../routing/isb-route.model';

export enum BillingTabIcons {
    GEARS,
    QUESTION_MARK,
    DOLLAR_SIGN,
    BILL
}

export class BillingTab {
    // eslint-disable-next-line max-params
    constructor(
        readonly text: string,
        readonly urlWithParam: (parameters: ISBRouteParams) => string,
        readonly isinternalUrl: boolean,
        readonly iconType: BillingTabIcons,
        public active = false
    ) { }
}
